import { FilePreviewType } from "./types";
import { TDocument } from "@common/FilePreview/types";

export const fileFormatter = (file: FilePreviewType): TDocument => {
  return {
    id: file.id,
    name: file.fileName,
    URL: file.fileURL,
    type: file.fileType,
    tag: file.tag,
  };
};

export const getThreshold = (scale = 1) => {
  if (scale > 1.4) {
    return 0.2;
  } else if (scale > 1) {
    return 0.4;
  } else if (scale < 0.8) {
    return 0.95;
  } else {
    return 0.8;
  }
};
