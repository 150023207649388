import {
  CREATE_FUNDRAISER_MODAL,
  CREATE_PRE_BUILD_FORM_MODAL,
} from "../../modals/modal_names";
import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";

import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const NewFundraisersBanner = ({ backgroundUrl }: { backgroundUrl: string }) => {
  const { t } = useTranslation(namespaces.pages.fundraisers);
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();
  return (
    <NewProductBanner
      campaignType="fundraiser"
      title={t("Fundraisers")}
      createModal={
        isPayBuilderEnabled
          ? CREATE_PRE_BUILD_FORM_MODAL
          : CREATE_FUNDRAISER_MODAL
      }
      analyticsType="fundraisers"
      background={backgroundUrl}
      createCampaignTitle={t("createFundraiser")}
    />
  );
};

export default NewFundraisersBanner;
