import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { AxiosError } from "axios";
import { useQuery } from "react-query";

const useGetCheckoutForms = (id: string) => {
  const getCheckoutFormsData = (id: string) => {
    return customInstance({
      url: buildMerchantEndpoints(`products/${id}/checkout-forms/`),
      method: "GET",
    });
  };

  return useQuery({
    queryKey: ["get-payment-form-checkout", id],
    queryFn: async () => {
      const data = await getCheckoutFormsData(id);
      return data;
    },
    enabled: !!id,
    retry: (_, error) => {
      return (error as AxiosError).status !== 404;
    },
  });
};

export default useGetCheckoutForms;
