import { useGetCurrentMerchantId } from "@hooks/common";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { MemberData } from "@customTypes/team.member";

const useCheckSponsor = () => {
  const { merchantId } = useGetCurrentMerchantId();
  const { userAccID } = useUserReducer();

  const { data, isLoading } = useQuery(
    ["check-sponsor-member-admin", merchantId],
    async () => {
      return await customInstance({
        url: `/accounts/${merchantId}/members?filter=(roleName:"sponsor",roleName:"admin",roleName:"owner")`,
        method: "GET",
      });
    },
    {
      staleTime: 5 * 60 * 1000, // 5 mins
      refetchOnWindowFocus: false,
      enabled: Boolean(merchantId),
    },
  );

  const roles = data?.data || [];

  const hasSponsorAccess = roles.some(
    (role: MemberData) => role?.user?.accID === userAccID,
  );

  return { isLoading, hasSponsorAccess };
};

export const useCheckSponsorRole = () => {
  const { merchantId } = useGetCurrentMerchantId();
  const { userAccID } = useUserReducer();

  const { data, isLoading } = useQuery(
    ["check-sponsor-member", merchantId],
    async () => {
      return await customInstance({
        url: `/accounts/${merchantId}/members?filter=(roleName:"sponsor")`,
        method: "GET",
      });
    },
    {
      staleTime: 5 * 60 * 1000, // 5 mins
      refetchOnWindowFocus: false,
      enabled: Boolean(merchantId),
    },
  );

  const roles = data?.data || [];

  const isSponsor =
    !isLoading &&
    roles.some((role: MemberData) => role?.user?.accID === userAccID);


  return { isLoading, isSponsor };
};

export default useCheckSponsor;
