import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Box } from "@mui/material";
import { customInstance } from "@services/api";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import placeholder from "assets/images/Placeholder.png";
import { useEffect } from "react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const useGetInfos = (id?: string) => {
  return useQuery<any, AxiosError>(
    "find-product-by-id",
    async () => {
      const product = await customInstance({
        url: `products/${id}`,
        method: "GET",
      });
      return product;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id && !isNaN(Number(id)),
    },
  );
};

const RefundPolicy = () => {
  const { isMobileView } = useCustomThemeV2();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetInfos(id!);

  useEffect(() => {
    if (!id || isNaN(Number(id)) || error?.response?.status === 404) {
      navigate("/");
      return;
    }
  }, [error, id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return data ? (
    <MainContainer isMobileView={isMobileView}>
      <RefundPrivacyContainer>
        <GiveAvatar
          imageUrl={data.merchantImageURL ? data.merchantImageURL : placeholder}
          shape="square"
          size="56px"
        />

        <Box sx={{ mt: "64px" }}>
          <GiveText variant="bodyS" color="secondary">
            {data.merchantName}
          </GiveText>
          <GiveText variant="h2" sx={{ mt: "14px" }}>
            Refund Policy
          </GiveText>

          <GiveText
            variant="bodyS"
            color="secondary"
            sx={{ mt: "32px", whiteSpace: "pre-line" }}
          >
            {data.msaRefundPolicy}
          </GiveText>
        </Box>
      </RefundPrivacyContainer>
    </MainContainer>
  ) : null;
};

export default RefundPolicy;

const RefundPrivacyContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignItems: "start",
  alignContent: "center",
  maxWidth: "650px",
  padding: "20px",
}));

const MainContainer = styled(Box)<{ isMobileView?: boolean }>(
  ({ isMobileView }) => ({
    width: "100%",
    display: "flex",
    justifyContent: isMobileView ? "start" : "center",
  }),
);
