import React from "react";
import {
  CREATE_EVENT_MODAL,
  CREATE_PRE_BUILD_FORM_MODAL,
} from "../../modals/modal_names";

import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";
import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const NewEventsBanner = ({ backgroundUrl }: { backgroundUrl: string }) => {
  const { t } = useTranslation(namespaces.pages.events);
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();
  return (
    <NewProductBanner
      title={t("Events")}
      campaignType="event"
      createModal={
        isPayBuilderEnabled ? CREATE_PRE_BUILD_FORM_MODAL : CREATE_EVENT_MODAL
      }
      background={backgroundUrl}
      createCampaignTitle={t("createEvent")}
      analyticsType="events"
    />
  );
};

export default NewEventsBanner;
