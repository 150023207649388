import Skeleton from "@components/animation/Skeleton";
import { Box, Stack } from "@mui/material";
import React from "react";

function LoadingComponent() {
  return (
    <Box p="20px">
      <Stack mb="40px" justifyContent="space-between" flexDirection="row">
        {Array(5)
          .fill(null)
          ?.map((i, idx) => (
            <Skeleton
              height="45px"
              sx={{
                borderRadius: "8px",
              }}
              width="90px"
              variant="rectangular"
              key={idx}
            />
          ))}
      </Stack>
      <Box>
        <Skeleton
          height="34px"
          sx={{
            borderRadius: "8px",
          }}
          width="40%"
          variant="rounded"
        />

        <Skeleton
          height="34px"
          sx={{
            borderRadius: "8px",
            marginTop: "12px",
          }}
          width="60%"
          variant="rounded"
        />
      </Box>
      <Box mt="40px">
        <Skeleton
          height="34px"
          sx={{
            borderRadius: "8px",
          }}
          width="20%"
          variant="rounded"
        />

        <Skeleton
          height="64px"
          sx={{
            borderRadius: "8px",
            marginTop: "20px",
          }}
          width="100%"
          variant="rounded"
        />
      </Box>
      <Box mt="40px">
        <Skeleton
          height="34px"
          sx={{
            borderRadius: "8px",
          }}
          width="20%"
          variant="rounded"
        />

        <Skeleton
          height="300px"
          sx={{
            borderRadius: "8px",
            marginTop: "20px",
          }}
          width="100%"
          variant="rounded"
        />
      </Box>
      <Box mt="40px">
        <Stack justifyContent="space-between" flexDirection="row">
          <Skeleton
            height="34px"
            sx={{
              borderRadius: "8px",
            }}
            width="40%"
            variant="rounded"
          />

          <Skeleton
            height="34px"
            sx={{
              borderRadius: "8px",
            }}
            width="40%"
            variant="rounded"
          />
        </Stack>
      </Box>

      <Box mt="40px">
        <Skeleton
          height="34px"
          sx={{
            borderRadius: "8px",
          }}
          width="20%"
          variant="rounded"
        />

        <Skeleton
          height="64px"
          sx={{
            borderRadius: "8px",
            marginTop: "20px",
          }}
          width="100%"
          variant="rounded"
        />
      </Box>
    </Box>
  );
}

export default LoadingComponent;
