import { Image } from "@common/StyledImage/Image";
import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { KeyboardEvent, useCallback, useEffect, useRef } from "react";
import {
  MentionsInput,
  Mention,
  MentionsInputProps,
  MentionProps,
  SuggestionDataItem,
  DataFunc,
} from "react-mentions";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { palette } from "@palette";
import { useGetCurrentMerchantId } from "@hooks/common";
import { getAllTeamMembers } from "@services/api/manage-team";
import { getComputedMentions } from "../utils/functions";
import { useDebouncedCallback } from "use-debounce";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";

const ConversationsMentionsInput: React.FC<
  Partial<MentionsInputProps> &
    Partial<MentionProps> & {
      handleKeyDown?: (event: any) => void;
      handleKeyUp?: (event: any) => void;
      shouldAutoFocus?: boolean;
    }
> = ({
  value,
  onChange,
  onAdd,
  inputRef,
  style,
  handleKeyDown,
  handleKeyUp,
  shouldAutoFocus,
}) => {
  const { userAccID } = useAppSelector(selectUser);

  const isAdded = useRef(false);

  const applyStyles = useCallback(() => {
    const elements = document.querySelectorAll<HTMLElement>(".custom-mentions");
    if (elements) {
      elements.forEach((element) => {
        if (element && element.textContent?.startsWith('"')) {
          element.style.backgroundColor = "#f8f8f6";
          element.style.color = palette.givebox[600];
        } else {
          element.style.backgroundColor = palette.info.light;
          element.style.color = palette.filled.blue;
        }
      });
    }
  }, [value]);

  useEffect(applyStyles, [value]);

  const { merchantId } = useGetCurrentMerchantId();

  const fetchTeamMembers: DataFunc = useCallback((query, callback) => {
    const params = { memberStatus: "joined", searchQuery: query, page: 1 };
    getAllTeamMembers({ id: merchantId, ...params })
      .then((data) => {
        return getComputedMentions(data, userAccID);
      })
      .then((mentions) => {
        if (mentions) return callback(mentions);
        return [];
      });
  }, []);

  const debouncedFetch = useDebouncedCallback(
    (
      query: string,
      callback: (data: SuggestionDataItem[]) => void,
      fetchFunction: DataFunc,
    ) => {
      fetchFunction(query, callback);
    },
    400,
  );

  const debouncedFetchTeamMembers: DataFunc = useCallback((query, callback) => {
    if (!query) {
      fetchTeamMembers(query, callback);
    } else {
      debouncedFetch(query, callback, fetchTeamMembers);
    }
  }, []);

  return (
    <MentionsInput
      value={value}
      id="mention-input"
      autoFocus={shouldAutoFocus}
      onChange={onChange}
      inputRef={inputRef}
      style={{ ...defaultStyle, ...style }}
      a11ySuggestionsListLabel={"Suggested mentions"}
      forceSuggestionsAboveCursor
      onKeyDown={(event: KeyboardEvent) => {
        isAdded.current = false;
        handleKeyDown && handleKeyDown(event);
      }}
      onKeyUp={(event: KeyboardEvent) => {
        !isAdded.current && handleKeyUp && handleKeyUp(event);
      }}
      onFocus={(e) => {
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length,
        );
      }}
      placeholder="Add a comment..."
      allowSuggestionsAboveCursor
      allowSpaceInQuery={false}
      customSuggestionsContainer={(children) => {
        return <Box>{children}</Box>;
      }}
    >
      <Mention
        markup="@[____display____](user:____id____)"
        trigger="@"
        displayTransform={(id, display) =>
          `${display.startsWith('"') ? "" : "@"}${display}`
        }
        data={debouncedFetchTeamMembers}
        renderSuggestion={(
          suggestion: any,
          search,
          highlightedDisplay,
          index,
          focused,
        ) => {
          return (
            <FadeUpWrapper delay={20 * index}>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                className={`user ${focused ? "focused" : ""}`}
              >
                <Image
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    flexShrink: 0,
                  }}
                  src={suggestion?.image}
                />
                <Text
                  fontSize={14}
                  sx={{
                    fontWeight: 350,
                  }}
                >
                  {highlightedDisplay}
                </Text>
              </Stack>
            </FadeUpWrapper>
          );
        }}
        onAdd={(...args) => {
          isAdded.current = true;
          onAdd && onAdd(...args);
          applyStyles();
        }}
        appendSpaceOnAdd
        className="custom-mentions"
        style={{
          position: "relative",
          borderRadius: "100px",
          zIndex: 1,
          boxSizing: "border-box",
        }}
      />
      <Mention
        markup="#[____display____](hashtag:____id____)"
        trigger="#"
        displayTransform={(id, display) => `#${display}`}
        data={[] as SuggestionDataItem[] | DataFunc}
        renderSuggestion={(suggestion, search, highlightedDisplay) => {
          return (
            <Box>
              <Text fontSize={14}>{highlightedDisplay}</Text>
            </Box>
          );
        }}
        onAdd={onAdd}
        appendSpaceOnAdd
        style={{
          position: "relative",
          color: "#FF8124",
          borderRadius: "100px",
          zIndex: 1,
          boxSizing: "border-box",
        }}
      />
    </MentionsInput>
  );
};

const defaultStyle = {
  flex: 1,
  padding: 0,
  wordBreak: "break-word",
  control: {
    fontSize: 14,
    fontWeight: "normal",
    outline: 0,
  },

  "&multiLine": {
    control: {
      fontFamily: "Give Whyte,sans-serif",
      minWidth: "100%",
      maxWidth: "100%",
      outline: 0,
      border: "none",
      flex: 1,
    },
    highlighter: {
      padding: 8,
      border: "none",
    },
    input: {
      padding: 8,
      border: "none",
      fontWeight: "350",

      lineHeight: "unset",
      wordBreak: "break-word",
      outline: 0,
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      border: "none",
      padding: 198,
    },
    input: {
      fontWeight: "350",
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "#FAFAFA",
      border: "none",
      borderRadius: "8px",
      width: "293px",
      overflowY: "scroll",
      maxHeight: "216px",
      padding: "4px",
      fontSize: 14,
      boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.15)",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "none",
      "&focused": {
        backgroundColor: "#ECECE9",
        borderRadius: "4px",
      },
    },
  },
};

export default ConversationsMentionsInput;
