import { BaseModal } from "@common/Modal";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { PeekModeModalType } from "./types";
import { useNavigate } from "react-router-dom";
import { Box, Stack, styled, tooltipClasses } from "@mui/material";
import { ExpandIcon } from "@assets/icons";
import { CloseIcon } from "@assets/rebrandIcons";
import { Tooltip } from "@common/Tooltip";
import { palette } from "@palette";
import ProductsCustomerView from "@sections/PayBuilder/components/ProductsCustomerView";
import PeekPreviewWrapper from "./PeekPreviewWrapper";
import { useAppDispatch } from "@redux/hooks";
import { setSelectedPaymentFormID } from "@redux/slices/products";

const peekModeTooltipTitles: { [key: string]: string } = {
  fundraisers: "Fundraiser",
  sweepstakes: "Sweepstake",
  events: "Event",
  memberships: "Membership",
  invoices: "Invoice",
  "payment-forms": "Payment Form",
};

const NewPeekModeModal = NiceModal.create(
  ({ previewLink, state }: PeekModeModalType) => {
    const modal = useModal();

    const [campaign, id] = previewLink.split("/");
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const handleClose = () => {
      modal.hide();
      dispatch(setSelectedPaymentFormID(null));
    };
    const handleNavigate = () => {
      navigate(`/${id}`, { state: state });

      handleClose();
    };

    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
        }}
        data-testid="peek-mode-modal"
      >
        <BaseModal
          isPeekMode
          {...muiDialogV5(modal)}
          sx={ModalStyle}
          scroll="body"
          onClose={handleClose}
        >
          <StyledButtons gap={2}>
            <Tooltip
              title="Close"
              placement="left-end"
              slotProps={{
                tooltip: {
                  sx: {
                    [`&.${tooltipClasses.tooltip}`]: {
                      left: "-10px",
                    },
                  },
                },
              }}
            >
              <button
                style={ButtonStyle}
                onClick={handleClose}
                data-testid="peek-mode-modal-close-button"
              >
                <CloseIcon height={32} width={32} color={palette.neutral[70]} />
              </button>
            </Tooltip>

            <Tooltip
              title={`Open ${peekModeTooltipTitles[campaign]}`}
              placement="left-end"
              titleSx={{ textTransform: "none" }}
              slotProps={{
                tooltip: {
                  sx: {
                    [`&.${tooltipClasses.tooltip}`]: {
                      left: "-10px",
                    },
                  },
                },
              }}
            >
              <button
                style={ButtonStyle}
                onClick={handleNavigate}
                data-testid="peek-mode-modal-expand-button"
              >
                <ExpandIcon color={palette.neutral[70]} />
              </button>
            </Tooltip>
          </StyledButtons>
          <Box
            sx={{ pointerEvents: "none", height: "100%" }}
            data-testid="public-form-container"
          >
            <PeekPreviewWrapper>
              <ProductsCustomerView isPeekMode />
            </PeekPreviewWrapper>
          </Box>
        </BaseModal>
      </Box>
    );
  },
);

const StyledButtons = styled(Stack)(() => ({
  position: "fixed",
  top: "5vw",
  left: "calc(calc(100% - 70vw) / 2 + 70.2vw)",
  zIndex: 99999,
  "& button:hover svg:not(:has(mask)) path, & button:hover #expand-icon-path": {
    fill: `${palette.neutral[80]} !important`,
  },
}));

const ButtonStyle = {
  width: "40px",
  height: "40px",
  backgroundColor: "#F8F8F6",
  padding: "4px",
  borderRadius: "50%",
  border: "none",
  cursor: "pointer",
};

const ModalStyle = {
  height: "auto",
  "& .MuiDialog-container": {
    overscrollBehavior: "contain",
  },
  "*": {
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      border: "2px solid #F9FCFF",
      backgroundColor: palette.neutral["200"],
    },
    "&::-webkit-scrollbar-thumb:focus": {
      backgroundColor: palette.neutral["300"],
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: palette.neutral["300"],
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: palette.neutral["300"],
    },
  },
  "& .MuiDialog-paper": {
    height: "100%",
    maxWidth: "100%",
    width: "70vw",
    position: "relative",
    borderRadius: "16px",
    zIndex: 2,
    maxHeight: "initial",
    top: 0,
    margin: "50px",
    overflowX: "hidden",
  },
};

export default NewPeekModeModal;
