import NiceModal from "@ebay/nice-modal-react";
import { AgreementFormWrapper } from ".";
import MerchantAgreementSkeleton from "../components/MerchantAgreementSkeleton";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { useAddMerchantAgreement } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { FormProvider } from "react-hook-form";
import MerchantAgreementHeader from "./MerchantAgreementHeader";
import { Disclaimer } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Disclaimer";
import moment from "moment";
import { useEffect, useMemo, useRef } from "react";
import { TOSWrapper } from "@components/ProfilePage/EnterpriseAgreement/SignTOS";
import { TermsOfService } from "@pages/TermsOfService";
import { BankDisclosure } from "@components/Merchants/CreateMerchantPanel/modals/components/BankDisclosure";
import { MerchantAgreementForm } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Form";
import { ModalActions } from "@common/Modal/ModalFactory/atoms";
import { useMerchantDataPreview } from "../hooks/useMerchantDataPreview";
import { useQueryClient } from "react-query";
import { IParsedData } from "../data.types";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useAsyncAction } from "@hooks/customReactCore";
import { useScrollCheck } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useScrollCheck";
import MerchantAgreementSignSection from "@components/Merchants/MerchantPreview/MerchantAgreement/MerchantAgreementSignSection";
import ScrollBottomButton from "@components/AcquirerEnterprises/EnterprisePreview/components/ScrollBottomButton";
import { LastUpdatedOn_merchant_agreement } from "features/Merchants/MerchantSidePanel/constants";

type Props = {
  id: number;
  isEnterprise?: boolean;
};

const MerchantAgreementPanel = NiceModal.create(
  ({ id, isEnterprise = false }: Props) => {
    const { open, SlideProps, onClose, TransitionProps } = useNiceModal();
    const queryClient = useQueryClient();
    const { role } = useAppSelector(selectUser);

    const { data, isLoading: isLoadingDataPreview } = useMerchantDataPreview({
      id,
      isEnterprise: isEnterprise,
    });
    const [isLoading, triggerAction] = useAsyncAction();

    const merchantAgreement = data?.merchantAgreement;
    const { methods, handleSubmit, getDefaultValuesFromData } =
      useAddMerchantAgreement(undefined, merchantAgreement, id);

    const { watch } = methods;
    const values = watch();
    const msaLastAcceptedAt = merchantAgreement?.msaLastAcceptedAt;

    const status = useMemo(() => {
      if (!merchantAgreement) return "not_signed";
      const { msaLastAcceptedVersion, tcVersion, msaHadAgreed } =
        merchantAgreement;
      const isCorrectVersion = msaLastAcceptedVersion === tcVersion;

      if (msaHadAgreed && isCorrectVersion) return "signed";
      if ((!msaHadAgreed && isCorrectVersion) || !msaLastAcceptedVersion)
        return "not_signed";

      return "pending";
    }, [merchantAgreement, data?.merchantInfo?.merchantID]);

    const isSigned = status === "not_signed";
    const isEditForm = status === "signed";

    const addSignature = (file: File) => {
      if (!isEnterprise) methods.setValue("msaHadAgreed", true);
      methods.setValue("signatureFile", file);
    };

    const onSubmit = async () => {
      await handleSubmit();
      queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, id]);
    };

    useEffect(() => {
      if (!isLoadingDataPreview && merchantAgreement) {
        methods.reset(getDefaultValuesFromData(merchantAgreement));
      }
    }, [isLoadingDataPreview]);

    return (
      <ModalFactory
        variant="sidepanel"
        modalProps={{
          open,
          onClose,
          SlideProps,
          SidePanelProps: {
            width: CAMPAIGN_PANEL_WIDTH,
            PaperProps: {
              TransitionProps,
            },
          },
          DrawerProps: {
            PaperProps: {
              TransitionProps,
            },
          },
        }}
      >
        {isLoadingDataPreview ? (
          <MerchantAgreementSkeleton />
        ) : (
          <FormProvider {...methods}>
            <AgreementFormWrapper data-testid="merchant-agreement-container">
              <MerchantAgreementHeader
                isEnterPrise={isEnterprise}
                onClose={onClose}
              />
              <Disclaimer
                status={status}
                isEnterPrise={isEnterprise}
                accountHolderNameFullName={`${data?.primaryAccountHolder?.firstName} ${data?.primaryAccountHolder?.lastName}`}
                dateTime={
                  msaLastAcceptedAt
                    ? moment
                        .unix(msaLastAcceptedAt)
                        .format("MM-DD-YYYY HH:mm:ss")
                    : ""
                }
                lastVersion={`${merchantAgreement?.msaLastAcceptedVersion}`}
                version={`${merchantAgreement?.tcVersion}`}
                merchantId={id}
                signatureURL={merchantAgreement?.signatureURL}
              />
              <ModalMainContent
                isEnterprise={isEnterprise}
                isEditForm={isEditForm}
                isSigned={isSigned}
                data={data}
              />

              <MerchantAgreementSignSection
                data={{
                  merchantInfo: data.merchantInfo,
                  merchantAgreement: data.merchantAgreement,
                  primaryAccountHolder: data.primaryAccountHolder,
                  status: {
                    statusName: data.primaryAccountHolder.statusName || "",
                  },
                }}
                addSignature={addSignature}
                merchantId={id}
                isSettings
                status={status}
                isEnterprise={isEnterprise}
                parentCheckBoxFieldKey={
                  isEnterprise ? "msaHadAgreed" : "msaPCICompliant"
                }
              />
              <ModalActions
                containerProps={{
                  marginTop: "8px",
                }}
                secondaryAction={{
                  label: "Cancel",
                  onClick: onClose,
                }}
                primaryAction={{
                  disabled:
                    !values.msaHadAgreed || !values.signatureFile || isLoading,
                  type: "button",
                  dataTestId: "save-agreement-panel",
                  onClick: () => triggerAction(onSubmit),
                }}
              />
            </AgreementFormWrapper>
          </FormProvider>
        )}
      </ModalFactory>
    );
  },
);

const ModalMainContent = ({
  isEnterprise,
  isSigned,
  data,
  isEditForm,
}: {
  isEnterprise: boolean;
  isSigned: boolean;
  isEditForm: boolean;
  data?: IParsedData;
}) => {
  const defaultHasAgreed =
    data?.merchantAgreement.tcVersion ===
      data?.merchantAgreement.msaLastAcceptedVersion &&
    !!data?.merchantAgreement?.msaHadAgreed;
  const { CheckboxWithTooltip, handleScroll } =
    useScrollCheck(defaultHasAgreed);
  const containerRef = useRef<HTMLDivElement | null>(null);

  if (!isEnterprise) {
    const agreementDetails = [
      {
        title: "Name of Previous Processor",
        value: data?.merchantAgreement?.msaPreviousTerminationProcessorName,
        size: 6,
      },
      {
        title: "Date of Termination",
        value: data?.merchantAgreement?.msaPreviousTerminationAt
          ? moment
              .unix(data?.merchantAgreement?.msaPreviousTerminationAt as number)
              .format("MM/DD/YYYY")
          : "",
        size: 6,
      },
      {
        title: "Reason for Termination",
        value: data?.merchantAgreement?.msaPreviousTerminationReason,
        size: 12,
      },
    ];
    return (
      <>
        <BankDisclosure />
        <MerchantAgreementForm
          isEditForm={isEditForm}
          items={agreementDetails}
          data={data}
          isSettings={isSigned}
        />
      </>
    );
  }

  return (
    <>
      <TOSWrapper onScroll={handleScroll} ref={containerRef}>
        <TermsOfService
          merchantAgreementVersion={{
            version: data?.merchantAgreement?.tcVersion || "",
            lastUpdated: LastUpdatedOn_merchant_agreement,
          }}
          isEnterPrise={isEnterprise}
        />
        <ScrollBottomButton containerRef={containerRef} />
      </TOSWrapper>
      <CheckboxWithTooltip name="msaHadAgreed" />
    </>
  );
};

export default MerchantAgreementPanel;
