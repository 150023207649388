import { showMessage } from "@common/Toast";
import { useUpdateMerchantsLists } from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
} from "@constants/queryKeys";
import { customInstance } from "@services/api";
import { checkPortals } from "@utils/routing";
import { useQueryClient } from "react-query";

const useWatchlist = (entreprise?: boolean, queryKey?: string) => {
  const { updateData, revertChange } = useUpdateMerchantsLists(queryKey);
  const queryClient = useQueryClient();
  const { isEnterpriseTable } = checkPortals()

  const editMerchantWatchlist = async (
    id: number,
    name: string,
    add = true,
  ) => {
    try {
      await queryClient.cancelQueries(queryKey);
      updateData(id, { watchlist: add }, { updateMerchantPreview: true });
      await customInstance({
        url: `/merchants/${id}/underwriting`,
        method: "PATCH",
        data: { watchlist: add },
      });
      queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_STATS);
      if (!isEnterpriseTable) {
        queryClient.invalidateQueries(QKEY_LIST_MERCHANTS);
        queryClient.invalidateQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
      }
    } catch (error) {
      revertChange();
      showMessage("Error", "Whoops.. an error occured. Please try again");
    }
  };

  const addMerchantToWatchlist = (merchantId: number, name: string) => {
    editMerchantWatchlist(merchantId, name);
  };

  const removeMerchantFromWatchlist = (merchantId: number, name?: string) => {
    editMerchantWatchlist(merchantId, name || "", false);
  };

  return { addMerchantToWatchlist, removeMerchantFromWatchlist };
};

export default useWatchlist;
