import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { isFunction } from "lodash";
import GivePopup from "@shared/Popup/GivePopup";
import {
  PopupIconTypeProps,
  PopupTypeProps,
} from "@shared/Popup/GivePopupTypes";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { SxProps } from "@mui/material";

type TModalType =
  | "approved"
  | "declined"
  | "delete"
  | "suspend"
  | "change-mcc"
  | "warning-red"
  | "notification"
  | "success";

type THandler = {
  onClick: (data?: any) => void;
  disable?: boolean;
};

type TValuesObject = {
  icon: PopupIconTypeProps;
  submitButtonText: string;
  submitButtonColor?: "primary" | "destructive";
  type: PopupTypeProps;
};

type Props = {
  modalType: TModalType;
  title: string;
  description: string | React.ReactNode;
  customSubmitBtnText?: string;
  showCancelBtn?: boolean;
  actions?: {
    handleSuccess?: THandler;
    handleCancel?: THandler;
  };
  inputNodes?: {
    element: React.ReactElement;
  }[];
  inputSchema?: any;
  inputDefaults?: any;
  desktopPaperSX?: SxProps;
  hideCancel?: boolean;
};

const GiveConfirmationPopUp = NiceModal.create(
  ({
    modalType,
    title,
    description,
    actions,
    customSubmitBtnText,
    showCancelBtn,
    inputNodes,
    inputSchema,
    inputDefaults,
    desktopPaperSX,
    hideCancel,
  }: Props) => {
    const { open, onClose } = useNiceModal();
    const { isMobileView } = useCustomThemeV2();

    const { handleSuccess, handleCancel } = actions || {};

    const onSuccess = (data?: any) => {
      if (isFunction(handleSuccess?.onClick)) {
        handleSuccess?.onClick(data);
      }
      onClose();
    };

    const onCancel = () => {
      if (isFunction(handleCancel?.onClick)) handleCancel?.onClick();
      onClose();
    };

    const { icon, submitButtonText, submitButtonColor, type } =
      getModalType(modalType);

    return (
      <GivePopup
        type={type}
        iconType={icon}
        title={title}
        description={description}
        open={open}
        onClose={onCancel}
        isMobile={isMobileView}
        {...(isMobileView ? { paperSx } : { paperSx: desktopPaperSX })}
        showCancelBtn={showCancelBtn}
        buttons={[
          {
            label: "Cancel",
            onClick: onCancel,
            variant: "ghost",
            hidden: hideCancel,
          },
          {
            label: customSubmitBtnText ?? `Yes, ${submitButtonText}`,
            onClick: onSuccess,
            variant: "filled",
            color: submitButtonColor,
            sxButton: { border: "none" },
            submitType: "submit",
          },
        ]}
        sx={{
          "& .MuiDialogTitle-root+.MuiDialogContent-root": {
            paddingTop: "0 !important",
          },
        }}
        inputDefaults={inputDefaults}
        inputNodes={inputNodes}
        inputSchema={inputSchema}
      />
    );
  },
);

export default GiveConfirmationPopUp;

const getModalType = (modalType: TModalType) => {
  const modalTypeObject: Record<TModalType, TValuesObject> = {
    approved: {
      icon: "success-regular",
      submitButtonText: "Approve",
      submitButtonColor: "primary",
      type: "success",
    },
    declined: {
      icon: "decline",
      submitButtonText: "Decline",
      submitButtonColor: "destructive",
      type: "destructive",
    },
    delete: {
      icon: "destructive",
      submitButtonText: "Delete",
      submitButtonColor: "destructive",
      type: "destructive",
    },
    suspend: {
      icon: "destructive-v2",
      submitButtonText: "Suspend",
      submitButtonColor: "primary",
      type: "success",
    },
    "change-mcc": {
      icon: "warning",
      submitButtonText: "Change",
      submitButtonColor: "primary",
      type: "success",
    },
    "warning-red": {
      icon: "warning",
      submitButtonText: "Submit",
      submitButtonColor: "destructive",
      type: "destructive",
    },
    success: {
      icon: "success",
      submitButtonText: "Complete",
      submitButtonColor: "primary",
      type: "success",
    },
    notification: {
      icon: "notification",
      submitButtonText: "Send",
      submitButtonColor: "primary",
      type: "success",
    },
  };

  return modalTypeObject[modalType];
};

const paperSx = {
  height: "auto !important",
  "& .MuiDialogActions-root": {
    flexDirection: "row",
    justifyContent: "flex-end",
    "& button": {
      width: "fit-content !important",
    },
  },
};
