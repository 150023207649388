import { Button } from "@common/Button";
import { TDocument } from "@common/FilePreview/types";
import { Text } from "@common/Text";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useDocumentPreview } from "@hooks/common/documents";
import { downloadDocument } from "@hooks/common/documents/utils";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Eye } from "@phosphor-icons/react";

type Props = {
  document: TMerchantDocument;
};

const EvidenceDocument = ({ document }: Props) => {
  const { handlePreview } = useDocumentPreview({
    list: [document],
    merchantID: 0,
    handlers: { handleDownload },
  });

  return (
    <Stack spacing={0.5}>
      <DocumentContainer>
        <Box display="flex" justifyContent="space-between">
          <Text
            fontWeight="book"
            color={palette.neutral[80]}
            lineHeight="16.8px"
          >
            {document?.fileName}
          </Text>
          <StyledButton
            data-testid="preview-icon"
            onClick={() => handlePreview(document)}
          >
            <Eye height={18} width={18} fill={palette.neutral[70]} />
          </StyledButton>
        </Box>
        <Text
          variant="caption"
          fontWeight="book"
          color={palette.neutral[70]}
          lineHeight="14.4px"
        >
          {document?.fileSize}
        </Text>
      </DocumentContainer>
    </Stack>
  );
};

const handleDownload = (document: TDocument | null) => {
  if (!document) return;

  downloadDocument({
    fileName: document?.name || "undefined",
    fileURL: document?.URL,
  });
};

const DocumentContainer = styled(Box)(() => ({
  padding: "8px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  background: palette.neutral.white,
}));

const StyledButton = styled(Button)(() => ({
  padding: "4px 8px",
  borderRadius: "8px",
  background: palette.neutral.white,
  boxShadow: "0px 4px 8px 0px #00000014",
  height: "26px",
  width: "34px",
  minWidth: "unset",
  "&:hover": {
    background: palette.neutral[10],
    boxShadow: "0px 4px 8px 0px #00000014",
  },
}));

export default EvidenceDocument;
