import { SidePanelHeaderBase } from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeader";
import {
  SidePanelBodyWrapper,
  SidePanelContainerWrapper,
} from "@shared/SidePanel/SidePanelAtoms";
import { WithRepositoryProvider } from "../WithRepositoryContext";
import {
  useConfigureChallenges,
  useGetSpecificChallengeData,
} from "./useChallengesRepository";
import { ChallengeActions, ChallengeTitle } from "./ChallengeHeader";
import ChallengeBody from "./ChallangeBody";
import GiveText from "@shared/Text/GiveText";
import PanelLoadingSkeleton from "../../components/PanelLoadingSkeleton";
import { useAppDispatch } from "@redux/hooks";
import { setSearchQueryByKey } from "@redux/slices/search";
import { TChallengeStatus } from "./types";
import { useEffect, useMemo, useState } from "react";
import { useModal } from "@ebay/nice-modal-react";
import {
  GIVE_CONFIRMATION_POP_UP,
  MERCHANT_PREVIEW_PANEL_MODAL,
} from "modals/modal_names";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { isArray } from "lodash";
import { useQueryClient } from "react-query";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_MERCHANT_STATS,
} from "@constants/queryKeys";
import { useGetCurrentMerchantId } from "@hooks/common";

interface IComponentProps {
  challengeIdentifier: string;
  challengeDisplayName: string;
  handleClose: () => void;
  handleBack: () => void;
}

export const ChallengesSecondPanelWrapper = (props: IComponentProps) => {
  const { configs, repositories } = useConfigureChallenges();
  return (
    <SidePanelContainerWrapper>
      <WithRepositoryProvider configs={configs} repositories={repositories}>
        <ChallengesSecondPanel {...props} />
      </WithRepositoryProvider>
    </SidePanelContainerWrapper>
  );
};

const ChallengesSecondPanel = ({
  handleClose,
  challengeIdentifier,
  challengeDisplayName,
  handleBack,
}: IComponentProps) => {
  const {
    isOnboardingView,
    isOnboardingCompleted,
    completeOnboarding,
    data: merchantData,
  } = useMerchantSidePanelContext();
  const [challengeStatusFilter, setChallengeStatusFilter] = useState<
    TChallengeStatus[]
  >([]);
  const [userTaggedInFilter, setUserTaggedInFilter] = useState("");
  const [hasUnreadMessagesFilter, setHasUnreadMessagesFilter] = useState("");
  const { data, isLoading, isLoadingList, error } = useGetSpecificChallengeData(
    challengeIdentifier,
    challengeStatusFilter,
    userTaggedInFilter,
    hasUnreadMessagesFilter,
  );
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const { show, remove } = useModal(GIVE_CONFIRMATION_POP_UP);
  const { show: showMerchant, args } = useModal(MERCHANT_PREVIEW_PANEL_MODAL);

  const handleResetFilters = () => {
    setChallengeStatusFilter([]); //TODO: reset others too
    setUserTaggedInFilter("");
    setHasUnreadMessagesFilter("");
  };

  const onClose = () => {
    dispatch(
      setSearchQueryByKey({
        queryKey: "items_all_challenges",
        params: {
          value: "",
        },
      }),
    );
    handleResetFilters();
    handleClose();
  };

  const handleChallengeFilter = (filter: TChallengeStatus) => {
    if (challengeStatusFilter.includes(filter)) {
      setChallengeStatusFilter((prev) => prev?.filter((i) => i !== filter));
    } else {
      setChallengeStatusFilter((prev) => [...prev, filter]);
    }
  };

  const handleUnreadFilter = (value: string) => {
    if (hasUnreadMessagesFilter) setHasUnreadMessagesFilter("");
    else setHasUnreadMessagesFilter(value);
  };

  const handleUserTaggedFilter = (value: string) => {
    if (userTaggedInFilter) setUserTaggedInFilter("");
    else setUserTaggedInFilter(value);
  };

  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();
  const { merchant_underwriting } = useEnterprisePermissions();

  const usedData = useMemo(() => {
    return {
      ...data,
      category: data?.categories?.data?.find(
        (cat) => cat.name === challengeIdentifier,
      ),
    };
  }, [data]);

  useEffect(() => {
    if (
      isOnboardingView &&
      usedData?.category &&
      !isOnboardingCompleted &&
      isArray(usedData?.challengesList?.data)
    ) {
      const { totalChallenges, totalClosed } = usedData.category;
      const openChallenge = usedData?.challengesList?.data?.find(
        (challenge) => challenge.statusName === "open",
      );
      if (
        totalChallenges &&
        (totalChallenges === totalClosed ||
          (totalChallenges - 1 === totalClosed &&
            openChallenge?.name === "bank_account_connected_with_plaid"))
      ) {
        completeOnboarding();
        queryClient.invalidateQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
        queryClient.invalidateQueries([QKEY_LIST_MERCHANT_STATS, merchantId]);
        show({
          modalType: "success",
          customSubmitBtnText: "Complete",
          hideCancel: true,
          title: "Complete Onboarding",
          description: (
            <>
              All required information for
              <strong> ‘{merchantData.merchantInfo.merchantName}’</strong> has
              now been provided. Confirming changes will move the merchant to
              underwriting.
            </>
          ),
          actions: {
            handleSuccess: {
              onClick: () => {
                if (merchant_underwriting || isViewUnderwritingAllowed) {
                  showMerchant({
                    ...args,
                    showUnderwritingView: true,
                    showOnboardingView: false,
                  });
                  remove();
                  handleBack();
                } else {
                  remove();
                }
              },
            },
          },
        });
      }
    }
  }, [usedData?.category, usedData?.challengesList]);

  return (
    <>
      <SidePanelHeaderBase
        leftItems={
          <ChallengeTitle name={challengeDisplayName} handleBack={handleBack} />
        }
        rightItems={<ChallengeActions onClose={onClose} />}
      />
      {error ? (
        <GiveText>Something went wrong</GiveText>
      ) : (
        <SidePanelBodyWrapper sx={{ height: "100%" }}>
          {isLoading ? (
            <PanelLoadingSkeleton variant="underwriting" />
          ) : (
            <ChallengeBody
              data={usedData}
              isListLoading={isLoadingList}
              setChallengeStatusFilter={handleChallengeFilter}
              challengeStatusFilter={challengeStatusFilter}
              handleResetFilters={handleResetFilters}
              handleUnreadFilter={handleUnreadFilter}
              hasUnreadMessagesFilter={hasUnreadMessagesFilter}
              handleUserTaggedFilter={handleUserTaggedFilter}
              userTaggedInFilter={userTaggedInFilter}
            />
          )}
        </SidePanelBodyWrapper>
      )}
    </>
  );
};

export default ChallengesSecondPanel;
