import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack } from "@mui/material";
import { CREATE_PAYMENT_FORM_MODAL } from "modals/modal_names";
import {
  CampaignMapperValues,
  useCreateCampaignFn,
} from "../PaymentFormMinibuilder/useCreateCampaignFn";
import GiveSidePanel from "@shared/SidePanel/GiveSidePanel";
import GiveText from "@shared/Text/GiveText";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { SidePanelHeaderBase } from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeader";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import {
  CalendarPlus,
  Package,
  SealCheck,
  Ticket,
  X,
} from "@phosphor-icons/react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import DonationBox from "@assets/iconsV2/DonationBox";
import FileDollar from "@assets/iconsV2/FileDollar";
import useGenerateTypeList from "./hooks/useGenerateTypeListV2";
import { CampaignModal } from "./utils";

const PaymentFormSelectionPanel = () => {
  const { open, onClose } = useNiceModal();
  const { palette } = useAppTheme();
  const { createCampaignFn } = useCreateCampaignFn();
  const { typeList } = useGenerateTypeList();

  const Icons = {
    Products: <Package color={palette.icon?.["icon-primary"]} size={24} />,
    Fundraiser: (
      <DonationBox fill={palette.icon?.["icon-primary"] || ""} size={24} />
    ),
    Event: <CalendarPlus color={palette.icon?.["icon-primary"]} size={24} />,
    Invoice: (
      <FileDollar fill={palette.icon?.["icon-primary"] || ""} size={24} />
    ),
    Membership: <SealCheck color={palette.icon?.["icon-primary"]} size={24} />,
    Sweepstake: <Ticket color={palette.icon?.["icon-primary"]} size={24} />,
  };

  const fn = (name: CampaignMapperValues) => {
    onClose();
    if (CampaignModal[name])
      return createCampaignFn<CampaignMapperValues>(CampaignModal[name], name);
    else return NiceModal.show(CREATE_PAYMENT_FORM_MODAL);
  };

  return (
    <GiveSidePanel onClose={onClose} open={open}>
      <Box component="form" id="create-payment-form">
        <SidePanelHeaderBase
          leftItems={
            <GiveText variant="bodyM" color="primary">
              Create a Payment Form
            </GiveText>
          }
          rightItems={
            <GiveIconButton
              Icon={X}
              variant="ghost"
              onClick={onClose}
              size="small"
            />
          }
        />
        <Stack padding="30px 20px 20px 20px">
          <GiveText variant="h3" color="primary" fontWeight={300}>
            Choose a Payment Form Type
          </GiveText>
          <Container>
            {typeList.map((type, index) => {
              return (
                <Item direction="row" key={index} onClick={() => fn(type.key)}>
                  <IconContainer>{(Icons as any)[type.name]}</IconContainer>
                  <Stack direction="column" gap="4px">
                    <GiveText variant="bodyS">{type.name}</GiveText>
                    <GiveText variant="bodyS" color="secondary">
                      {type.description}
                    </GiveText>
                  </Stack>
                </Item>
              );
            })}
          </Container>
        </Stack>
      </Box>
    </GiveSidePanel>
  );
};

export default PaymentFormSelectionPanel;

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.surface?.primary,
  borderRadius: theme.customs?.radius.medium,
  padding: "20px",
  gap: "20px",
  flex: 1,
  marginTop: "16px",
  maxHeight: "calc(100vh - 175px)",
  overflow: "auto"
}));

const Item = styled(Stack)(({ theme }) => ({
  width: "100%",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.border?.primary}`,
  padding: "16px",
  gap: "20px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.primitive?.transparent["darken-2"],
    border: `1px solid ${theme.palette.border?.secondary}`,
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  borderRadius: "40px",
  backgroundColor: theme.palette.primitive?.transparent["darken-5"],
  padding: "12px",
  justifyContent: "center",
  alignItems: "center",
}));
