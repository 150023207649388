import { Stack } from "@mui/material";
import { styled } from "@theme/v2/Provider";
import { isArray } from "lodash";
import ChallengeBodyTop from "./ChallengeBodyTop";
import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import { useState } from "react";
import GiveButton from "@shared/Button/GiveButton";
import NiceModal from "@ebay/nice-modal-react";
import { EDD_CHALLENGE_MODAL } from "modals/modal_names";
import ChallengeItemMenu from "./ChallengeItemMenu";
import ChallengeItem from "./ChallengeItem";
import useChallengeActions, { getCanDone } from "./hooks/useChallangeActions";
import {
  EDDChallengeModalProps,
  TChallange,
  TChallengeStatus,
  TUnderwritingSectionData,
} from "./types";
import PanelLoadingSkeleton from "../../components/PanelLoadingSkeleton";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import GiveEmptyState from "@shared/EmptyState/GiveEmptyState";
import {
  useGetChallengeThread,
  useGetGlobalTopicByActivity,
} from "./hooks/useNotifications";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";

interface Props {
  data: TUnderwritingSectionData;
  isListLoading?: boolean;
  setChallengeStatusFilter: (v: TChallengeStatus) => void;
  challengeStatusFilter: TChallengeStatus[];
  handleResetFilters: () => void;
  handleUnreadFilter: (val: string) => void;
  hasUnreadMessagesFilter: string;
  userTaggedInFilter: string;
  handleUserTaggedFilter: (val: string) => void;
}

export default function ChallengeBody({
  data,
  isListLoading,
  challengeStatusFilter,
  setChallengeStatusFilter,
  handleResetFilters,
  handleUnreadFilter,
  hasUnreadMessagesFilter,
  userTaggedInFilter,
  handleUserTaggedFilter,
}: Props) {
  const challengeList = data?.challengesList?.data;
  const { openConversationHandler, updateThreads } = useConversationsModal();
  const {
    handleOpenChallenge,
    handleDeleteChallenge,
    handleEDDChallenge,
    handleEdit,
    isPAHAdded,
    handleApprove,
  } = useChallengeActions({ challengeList });
  const [currentChallenge, setCurrentChallenge] = useState<
    TChallange | undefined
  >(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const name = data?.category?.displayName || "";
  const identifier = data?.category?.name || "";
  const total =
    (data?.category?.totalChallenges || 0) - (data?.category?.totalEmpty || 0);
  const status =
    total === data?.category?.totalClosed ? "Completed" : "In Progress";
  const isMerchantOnboarding = identifier === "merchant_onboarding";
  const isEDD = identifier === "enhanced_due_diligence";

  const { id: merchantID } = useMerchantSidePanelContext();
  const { topicID } = useGetGlobalTopicByActivity(
    undefined,
    identifier,
    "internal",
  );
  const { data: threads } = useGetChallengeThread(merchantID, topicID);

  const handleOpenConversation = ({
    convTag,
    challengeID,
  }: {
    convTag: string;
    challengeID: number;
  }) => {
    const challenge = challengeList?.find((c) => c.id === challengeID);
    const isRejected = challenge?.statusName === "rejected";
    if (threads?.data) updateThreads(threads?.data);
    const tag = convTag.replaceAll(" ", "");
    const existingThread = threads?.data?.find(
      (thread: any) => thread?.challenge?.id === challengeID,
    );
    const {
      messages,
      authorAvatarImageURL,
      authorFirstName,
      authorLastName,
      id,
    } = existingThread || {};
    openConversationHandler({
      id: topicID,
      name: name,
      paths: existingThread
        ? [
            {
              avatars: [authorAvatarImageURL],
              pathName: `${authorFirstName} ${authorLastName}`,
            },
          ]
        : [],
      defaultMessage: existingThread || !tag ? "" : `#${tag} `,
      challengeId: existingThread ? undefined : challengeID,
      challengeID: challenge?.challengeID,
      challengeTypeName: challenge?.challengeTypeName,
      ...(!!existingThread && {
        openThread: {
          isRepliesOpen: true,
          threadId: id,
          commentId: messages?.[0]?.id,
          index: 0,
        },
      }),
      onMessageSubmit: isRejected ? handleApprove : undefined,
    });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);

    setTimeout(() => setCurrentChallenge(undefined), 100);
  };

  const handleOpenMenu = (e: HTMLElement, challenge: TChallange) => {
    setCurrentChallenge(challenge);
    setAnchorEl(e);
  };

  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, "items_all_challenges"),
  );
  const isEmpty =
    (!isArray(challengeList) || !challengeList || challengeList?.length < 1) &&
    !isListLoading;
  const isEmptySearchData = searchQuery !== "" && isEmpty;
  const isEmptyReplyData = hasUnreadMessagesFilter !== "" && isEmpty;
  const isEmptyUserTagged = userTaggedInFilter !== "" && isEmpty;
  const showEmptyState =
    isEmpty && (isEmptySearchData || isEmptyReplyData || userTaggedInFilter);

  const getEmptyText = () => {
    if (
      isEmptySearchData &&
      (isEmptyReplyData ||
        isEmptyUserTagged ||
        challengeStatusFilter.length > 0)
    )
      return EMPTY_TEXTS.searchAndFilter;
    if (isEmptySearchData) return EMPTY_TEXTS.search;
    if (isEmptyReplyData && isEmptyUserTagged) return EMPTY_TEXTS.both;
    if (isEmptyReplyData) return EMPTY_TEXTS.merchantReply;
    if (isEmptyUserTagged) return EMPTY_TEXTS.tagged;
  };

  return (
    <Container>
      <ChallengeBodyTop
        identifier={identifier}
        name={name}
        completed={data?.category?.totalClosed || 0}
        incomplete={data?.category?.totalReadyForVerification || 0}
        disabled={total}
        status={status}
        isEmptyChallengeList={isEmpty && !showEmptyState}
        setChallengeStatusFilter={setChallengeStatusFilter}
        challengeStatusFilter={challengeStatusFilter}
        handleResetFilters={handleResetFilters}
        handleUnreadFilter={handleUnreadFilter}
        hasUnreadMessagesFilter={hasUnreadMessagesFilter}
        handleUserTaggedFilter={handleUserTaggedFilter}
        userTaggedInFilter={userTaggedInFilter}
      />
      {isListLoading && <PanelLoadingSkeleton variant="underwriting" />}
      {showEmptyState && (
        <GiveEmptyState
          Icon={<MagnifyingGlass size={28} />}
          title={{
            main: searchQuery
              ? `No results for "${searchQuery}"`
              : `No results`,
            secondary: getEmptyText(),
          }}
          sx={{ justifyContent: "flex-start", paddingTop: "64px" }}
        />
      )}
      <Stack padding="0px 12px 0px 12px">
        {!isListLoading &&
          challengeList &&
          isArray(challengeList) &&
          challengeList?.map((challenge, index) => {
            return (
              <ChallengeItem
                challenge={challenge}
                key={index}
                isMerchantOnboarding={isMerchantOnboarding}
                handleOpenMenu={handleOpenMenu}
                handleOpenConversation={handleOpenConversation}
                handleOpenChallenge={handleOpenChallenge}
                isPAHAdded={isPAHAdded}
              />
            );
          })}
      </Stack>
      {isEDD && (
        <AddEDDChallengeButton
          modalProps={{ handleSubmit: handleEDDChallenge }}
        />
      )}
      <ChallengeItemMenu
        handleClose={handleCloseMenu}
        anchorEl={anchorEl}
        currentChallenge={currentChallenge}
        isEDD={isEDD}
        openConversation={handleOpenConversation}
        doneDisabled={Boolean(
          currentChallenge && !getCanDone(currentChallenge, challengeList),
        )}
        handleDeleteChallenge={handleDeleteChallenge}
        handleEdit={handleEdit}
        handleOpenChallenge={handleOpenChallenge}
        isPAHAdded={isPAHAdded}
      />
    </Container>
  );
}

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.surface?.primary,
  borderRadius: theme.customs?.radius.medium,
  margin: "0px 20px 40px 20px",
  padding: "20px 12px 12px 12px",
  gap: "20px",
  flex: 1,
  marginTop: "16px",
}));

const AddEDDChallengeButton = ({
  modalProps,
}: {
  modalProps: Omit<EDDChallengeModalProps, "type">;
}) => {
  return (
    <GiveButton
      label="Add New Challenge"
      startIcon={<Plus size={20} />}
      fullWidth
      color="light"
      variant="ghost"
      sx={{ justifyContent: "flex-start", borderRadius: "8px" }}
      size="large"
      onClick={() =>
        NiceModal.show(EDD_CHALLENGE_MODAL, {
          type: "create",
          ...modalProps,
        })
      }
    />
  );
};

const EMPTY_TEXTS = {
  search: "Please try a different search term",
  searchAndFilter: "Please try a different search term or filter criteria",
  tagged: "You are not tagged in any conversations",
  merchantReply: "You have no replies from merchants",
  both: `You’re not tagged in any conversations and have no merchant replies`,
};
