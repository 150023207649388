import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import MainHeader from "./components/MainHeader/MainHeader";
import PanelLoadingSkeleton from "./components/PanelLoadingSkeleton";
import useGetPanelContent from "./hooks/useGetPanelContent";
import { useMerchantSidePanelContext } from "./Provider/MerchantSidePanelProvider";
import SecondPanelOptions from "./SecondPanelOptions";
import {
  SidePanelBodyWrapper,
  SidePanelContainerWrapper,
} from "@shared/SidePanel/SidePanelAtoms";
import { useGetGlobalTopics } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import Conversations from "./WithRepository/Challenges/Conversations";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";
import CollapseNavigation from "./components/Collapse1stLevel/CollapseNavigation";
import useGetContentDetails from "./hooks/useGetContentDetails";
import { SxProps } from "@mui/material";
import GiveSidePanel from "@shared/SidePanel/GiveSidePanel";

const NewPanel = () => {
  useGetGlobalTopics();
  const {
    open,
    onClose,
    renderButton,
    status,
    contentHistory,
    firstPanelContent,
    secondPanelContent,
    isLoading,
    isLoadingUnderwriting,
    merchant,
    isSinglePanelSize,
    data: {
      status: { sponsorStatusName },
    },
  } = useMerchantSidePanelContext();
  const { isMobileView } = useCustomThemeV2();
  const { panelBody, skeletonVariant } = useGetPanelContent(status);
  const { contentDetails } = useGetContentDetails();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();

  // We are hiding the buttons if merchant_underwriting is false and we are in enterprise portal
  const isHide = !merchant_underwriting && isEnterprisePortal;

  const sidePanelBodyWrapperSx = {
    padding: "40px 20px",
    paddingTop: isMobileView ? "20px" : "40px",
    gap: "32px",
  };
  const showCollapseNavigation =
    isMobileView ||
    (isSinglePanelSize && !!secondPanelContent) ||
    contentHistory !== "";

  return (
    <>
      <GiveSidePanel
        open={open}
        onClose={onClose}
        secondPanel={<SecondPanelOptions />}
        isSecondPanelOpen={!!secondPanelContent}
        isFirstPannelHidden={contentHistory !== "" && firstPanelContent === ""}
        floatingContent={
          <>
            {showCollapseNavigation && (
              <CollapseNavigation
                show={showCollapseNavigation}
                image={`${merchant?.imageURL}/thumb`}
              />
            )}
            {!isHide && (
              <>
                <GiveSidePanel.Button
                  renderButton={renderButton}
                  el="conversations"
                />
                <GiveSidePanel.Button
                  renderButton={renderButton}
                  el="changelog"
                />
              </>
            )}
          </>
        }
      >
        <SidePanelContainerWrapper>
          {firstPanelContent !== "" ? (
            contentDetails
          ) : (
            <>
              <MainHeader />
              <PanelBody
                isLoading={isLoading}
                isLoadingUnderwriting={isLoadingUnderwriting}
                skeletonVariant={skeletonVariant}
                panelBody={panelBody}
                sidePanelBodyWrapperSx={sidePanelBodyWrapperSx}
              />
            </>
          )}
        </SidePanelContainerWrapper>
      </GiveSidePanel>
      <Conversations
        merchant={merchant}
        isLoading={isLoading}
        // handleOpenManageUnderwriting={handlers?.handleOpenManageUnderwriting}
        isLoadingChangelog={isLoading}
        // shouldShowChangelog={isChangelogEnabled && merchant && showChangelog}
      />
    </>
  );
};

export default NewPanel;

interface PanelBodyProps {
  isLoading: boolean;
  isLoadingUnderwriting: boolean;
  skeletonVariant?:
    | "default"
    | "underwriting"
    | "invite_sent"
    | "merchant_file";
  panelBody: React.ReactNode;
  sidePanelBodyWrapperSx: SxProps;
}

const PanelBody = ({
  isLoading,
  skeletonVariant,
  panelBody,
  sidePanelBodyWrapperSx,
  isLoadingUnderwriting,
}: PanelBodyProps) => {
  return (
    <>
      {(isLoading || isLoadingUnderwriting) && (
        <SidePanelBodyWrapper sx={sidePanelBodyWrapperSx}>
          <PanelLoadingSkeleton variant={skeletonVariant} />
        </SidePanelBodyWrapper>
      )}
      {!isLoading && (
        <SidePanelBodyWrapper
          sx={{
            ...sidePanelBodyWrapperSx,
            ...(isLoadingUnderwriting && { display: "none" }),
          }}
        >
          {panelBody}
        </SidePanelBodyWrapper>
      )}
    </>
  );
};
