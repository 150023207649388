import { Box } from "@mui/material";
import { customInstance } from "@services/api";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useState } from "react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveLink from "@shared/Link/GiveLink";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import GiveButton from "@shared/Button/GiveButton";
import { Grid } from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import { PRIVACY_POLICY_MODAL, REFUND_POLICY_MODAL } from "modals/modal_names";

//TODO: will be updated when BE is ready
const useGetInfos = (token?: string) => {
  return useQuery<any, AxiosError>(
    "find-order-by-token",
    async () => {
      const order = await customInstance({
        url: `orders/${token}`,
        method: "GET",
      });
      return order;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!token,
    },
  );
};

const tempData = {
  product: "Mixed Scone Box",
  account: "johndoe@gmail.com",
  nextPayment: {
    date: "25/07/2025",
    frequency: "Recurring Monthly",
    amount: "$15.00",
    card: "VISA **** 4958",
  },
};

const CancelMembership = () => {
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const { isMobileView } = useCustomThemeV2();
  const membershipEndDate = "July 25, 2025.";

  const handleCancelMembership = () => {
    setIsCanceled(true);
  };

  const handleOpenRefundPolicy = () => {
    NiceModal.show(REFUND_POLICY_MODAL);
  };

  const handleOpenPrivacyPolicy = () => {
    NiceModal.show(PRIVACY_POLICY_MODAL);
  };

  return (
    <MainContainer isMobileView={isMobileView}>
      <MembershipContainer>
        <GiveText variant="bodyL">Wayne&apos;s Bakery</GiveText>
        <Header>
          <GiveText variant="h3" fontWeight={300} fontSize="28px">
            {isCanceled
              ? "Your payments were canceled"
              : "We're sorry to see you go!"}
          </GiveText>
          <GiveText variant="bodyS" color="secondary" sx={{ mt: "20px" }}>
            {isCanceled ? (
              <>
                You will no longer receive purchased products after your
                membership ends on{" "}
                <GiveText variant="bodyS" fontWeight={600} component="span">
                  {membershipEndDate}
                </GiveText>
              </>
            ) : (
              <>
                Once you cancel your payments, you will retain access until{" "}
                <GiveText variant="bodyS" fontWeight={600} component="span">
                  {membershipEndDate}
                </GiveText>{" "}
                After that, you will no longer receive purchased products.
              </>
            )}
          </GiveText>
        </Header>

        {!isCanceled && (
          <GiveButton
            label="Yes, Cancel Payments"
            size="large"
            variant="filled"
            onClick={handleCancelMembership}
            sx={{ mb: "20px" }}
          />
        )}

        <OrderDetails />

        <Footer>
          <GiveLink onClick={handleOpenRefundPolicy}>Refund Policy</GiveLink>
          <GiveLink onClick={handleOpenPrivacyPolicy}>Privacy Policy</GiveLink>
        </Footer>
      </MembershipContainer>
    </MainContainer>
  );
};

export default CancelMembership;

const OrderDetails = () => (
  <>
    <GiveDivider />
    <Box sx={{ width: "100%", my: "32px" }}>
      <GiveText variant="bodyL" color="primary" sx={{ mb: "20px" }}>
        Order Details
      </GiveText>
      <Grid container spacing={3}>
        {[
          {
            label: "Product",
            value: tempData.product,
            variant: "bodyXS" as const,
          },
          {
            label: "Account",
            value: tempData.account,
            variant: "bodyS" as const,
          },
          {
            label: "Next Payment",
            value: (
              <>
                <GiveText variant="bodyS">{tempData.nextPayment.date}</GiveText>
                <GiveText variant="bodyS">
                  {tempData.nextPayment.frequency}
                </GiveText>
                <GiveText variant="bodyS">
                  {tempData.nextPayment.amount}
                </GiveText>
                <GiveText variant="bodyS">{tempData.nextPayment.card}</GiveText>
              </>
            ),
            variant: "bodyS" as const,
          },
        ].map(({ label, value, variant }, index) => (
          <Grid item xs={12} sm={6} key={index} spacing={2}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <GiveText variant="bodyS" fontWeight={600}>
                {label}
              </GiveText>
              <GiveText variant={variant}>{value}</GiveText>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    <GiveDivider />
  </>
);

const MembershipContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignItems: "center",
  alignContent: "center",
  maxWidth: "650px",
  padding: "20px",
  paddingTop: "56px",
}));

const MainContainer = styled(Box)<{ isMobileView?: boolean }>(
  ({ isMobileView }) => ({
    width: "100%",
    display: "flex",
    justifyContent: isMobileView ? "start" : "center",
  }),
);

const Header = styled(Box)(() => ({
  marginTop: "48px",
  marginBottom: "20px",
  textAlign: "center",
}));

const Footer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "24px",
  marginTop: "8px",
  width: "100%",
}));
