import { useAppDispatch } from "@redux/hooks";
import {
  resetConversationTopic,
  setConversationTopic,
  setModalOpenConversation,
} from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { getGlobalTopic } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { TGlobalTopic } from "features/Minibuilders/Conversations/types";
import { useQuery } from "react-query";

export type NotifyModalProps = {
  challengeID?: number;
  challengeTypeName?: "enhanced_due_diligence" | "customer_due_diligence";
  hideInputs?: string[];
  defaultMessage?: string;
  topicName?: string;
};

export function useNotifications() {
  const { data } = useMerchantSidePanelContext();
  const openRiskModul = !["approved", "suspended"].includes(
    data?.status?.statusName,
  );
  const { topicID } = useGetGlobalTopicByActivity(
    data?.merchantInfo?.merchantID,
    openRiskModul ? "underwriting" : "risk_monitor",
  );
  const { data: threads } = useGetChallengeThread(
    data?.merchantInfo?.merchantID,
    topicID,
  );
  const dispatch = useAppDispatch();
  const closeNotifyMerchantModal = () => {
    dispatch(setModalOpenConversation(false));
    dispatch(resetConversationTopic());
  };

  const openNotifyMerchantModal = async ({
    challengeID,
    challengeTypeName,
    hideInputs = [],
    defaultMessage,
    topicName = "",
  }: NotifyModalProps) => {
    const existingThread = threads?.data?.find(
      (thread: any) =>
        thread.challenge?.id && thread.challenge?.id === challengeID,
    );

    if (!topicID) return;

    dispatch(setModalOpenConversation(true));
    dispatch(
      setConversationTopic({
        isOpen: true,
        isOpenedFromSidePanel: false,
        numberOfUnreadMessages: 0,
        threadId: existingThread?.id,
        queryObject: {
          id: topicID,
          name: `Notify Merchant`, //later update for provider too
          tabs: "Activity",
          challengeTypeName: challengeTypeName,
          challengeId: challengeID,
          defaultMessage: defaultMessage,
          merchantId: data.merchantInfo.merchantID,
          paths: !existingThread
            ? [
                {
                  isConversation: true,
                  pathName: `Notify Merchant`,
                  pathID: "new",
                  avatars: [],
                  hideInputs: hideInputs,
                  topicName: topicName,
                },
              ]
            : [
                { avatars: [], pathName: "Underwriting" },
                {
                  avatars: [],
                  pathName: topicName,
                  pathID: existingThread.id,
                  isConversation: true,
                },
              ],
          ...(existingThread && {
            openThread: {
              commentId: existingThread.messages[0]?.id,
              index: 0,
              isRepliesOpen: true,
              threadId: existingThread.id,
            },
          }),
        },
      }),
    );
  };

  return { openNotifyMerchantModal, closeNotifyMerchantModal };
}

export const useGetGlobalTopicByActivity = (
  merchantId?: number,
  topicName = "underwriting",
  type = "activity",
) => {
  const { isLoading, data } = useQuery<{
    total: number;
    data: TGlobalTopic[];
  }>(
    ["topic", merchantId, topicName],
    async () =>
      await getGlobalTopic({
        topicName: topicName,
        merchantId,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  return {
    isLoading,
    topicID:
      data?.total &&
      data?.data?.find(
        (item: any) => item.Name === topicName && item.Type === type,
      )?.ID,
  };
};

export const useGetChallengeThread = (merchantID: number, topicID?: number) => {
  const { isLoading, data } = useQuery(
    ["challenge_thread", merchantID, topicID],
    async () => {
      const data = await customInstance({
        url: `/merchants/${merchantID}/topics/${topicID}/threads?sort=createdAt`,
        method: "GET",
      });
      return { data: data?.data };
    },
    { enabled: !!topicID && !!merchantID },
  );
  return { data, isLoading };
};
