import { PayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";
import PayBuilderFormProvider from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { ThemeWrapper } from "@theme/v2/Provider";

interface PeekPreviewWrapperProps {
  children: React.ReactNode;
}

function PeekPreviewWrapper({ children }: PeekPreviewWrapperProps) {
  return (
    <ThemeWrapper isNewThemeOnly>
      <PayBuilderContext displayStatus="public">
        <PayBuilderFormProvider>{children}</PayBuilderFormProvider>
      </PayBuilderContext>
    </ThemeWrapper>
  );
}

export default PeekPreviewWrapper;
