import useCheckSponsor from "@hooks/common/useCheckSponsor";
import { IChipProps } from "@shared/Chip/GiveChip";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import useUpdateMerchant from "features/Merchants/MerchantSidePanel/hooks/useUpdateMerchant";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useMemo } from "react";
import ChipMenu from "../../ChipMenu";
import { capitalizeFirstLetter } from "@utils/index";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  APPROVE_MERCHANT_MODAL_REBRAND,
  GIVE_CONFIRMATION_POP_UP,
} from "modals/modal_names";

const colorMap = {
  approved: "success",
  declined: "error",
  suspended: "warning",
};

type PopupStatusTypes = "Suspend" | "Approve" | "Resume" | "";

const AccountStatusDropdown = () => {
  const { data, id } = useMerchantSidePanelContext();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const statusData = data.status;
  const status = statusData.statusName as keyof typeof colorMap;
  const sponsorStatusDisplayName = data?.status?.sponsorStatusName || "";
  const {
    handleSuspendMutation,
    handleResumeMutation,
    isApproveSuspendLoading,
  } = useUpdateMerchant();
  const { hasSponsorAccess } = useCheckSponsor();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();
  const { show, remove } = useModal(GIVE_CONFIRMATION_POP_UP);
  const options = useMemo(() => {
    switch (status) {
      case "approved":
        return [
          {
            text: "Suspend Merchant",
            onClick: () => {
              show({
                modalType: "suspend",
                title: `Suspend Merchant`,
                description: (
                  <PopupDescriptionText
                    status="Suspend"
                    name={data?.merchantInfo.merchantName || ""}
                  />
                ),
                actions: {
                  handleSuccess: {
                    onClick: handleSuspendMutation,
                    text: "Suspend",
                  },
                  handleCancel: {
                    onClick: remove,
                  },
                },
              });
            },
          },
        ];
      case "declined":
        return [
          ...(capitalizeFirstLetter(sponsorStatusDisplayName) !== "Declined"
            ? [
                {
                  text: "Resume Underwriting",
                  onClick: handleResumeMutation,
                },
              ]
            : []),
          ...((statusData.underwritingScorePercentage &&
            statusData.underwritingScorePercentage >= 80) ||
            capitalizeFirstLetter(sponsorStatusDisplayName) === "Declined"
            ? [
                {
                  text: "Approve Merchant",
                  onClick: () =>
                    NiceModal.show(APPROVE_MERCHANT_MODAL_REBRAND, {
                      merchantId: id,
                    }),
                },
              ]
            : []),
        ];
      case "suspended":
        return [
          {
            text: "Approve Merchant",
            onClick: () =>
              NiceModal.show(APPROVE_MERCHANT_MODAL_REBRAND, {
                merchantId: id,
              }),
          },
        ];
      default:
        return [];
    }
  }, [status]);

  const sponsorStatusDidUpdate =
    statusData?.sponsorStatusName &&
    ["pending", "declined", "approved"].includes(statusData?.sponsorStatusName);

  const canModify = sponsorStatusDidUpdate
    ? hasSponsorAccess
    : isUpdateUnderwriterAllowed;

  const canOpen =
    isEnterprisePortal && !merchant_underwriting
      ? false
      : canModify && !isApproveSuspendLoading;

  return (
    <ChipMenu
      options={options}
      canOpen={canOpen}
      chipProps={{
        variant: "outline",
        color: colorMap[status] as IChipProps["color"],
      }}
      label={capitalizeFirstLetter(status)}
    />
  );
};

const PopupDescriptionText = ({
  status,
  name,
}: {
  status: PopupStatusTypes;
  name: string;
}) => {
  const { palette } = useAppTheme();

  const otherDescriptions = {
    Suspend: `Suspending the account will temporarily stop the merchant from processing transactions or sending money transfers.`,
    Approve: `This action will finalize the approval process.`,
  };

  return (
    <GiveText variant="bodyS" color="secondary">
      Are you sure you want to {status.toLowerCase()}{" "}
      <span style={{ color: palette.text.primary }}>{name}</span>?{" "}
      {otherDescriptions[status as keyof typeof otherDescriptions]}
    </GiveText>
  );
};

export default AccountStatusDropdown;
