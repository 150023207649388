import { useCallback, useEffect, useReducer, useState } from "react";
import { IFileWithMeta, IMeta, StatusValue } from "react-dropzone-uploader";
import { useQueryClient } from "react-query";
import {
  UploaderEvent,
  UploaderState,
  machine,
} from "../components/PrimaryAccountHolder/PAHUploaderMachine";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { TFileAttachmentType } from "@components/UploadFile/hooks/useUploadFiles";
import { PAHMapper } from "../components/PrimaryAccountHolder/PAHMapper";
import { TOwnerFile } from "../components/PrimaryAccountHolder/types";
import { FileUploadStatus, SnackbarFile } from "@components/UploadFile/types";
import { deleteDocument } from "@hooks/common/documents/utils";
import {
  AcceptAllowedImagesTypes,
  useUploadFiles,
} from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";

export const usePAHUploader = ({
  docUrl,
  merchantId,
  type,
  fileId,
}: {
  docUrl: string;
  merchantId: number;
  type: TOwnerFile;
  fileId: number | undefined;
}) => {
  // Hooks
  const queryClient = useQueryClient();
  const { handleUpload } = useUploadFiles();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const [fileUrl, setFileUrl] = useState<string>(docUrl);
  const [fileName, setFileName] = useState<string>("");
  const { populateSnackbarFiles, setSnackbarFiles } = useFileUploadContext();
  const [state, dispatch] = useReducer(
    machine,
    docUrl ? UploaderState.ON_UPLOADED : UploaderState.ON_INITIAL,
  );
  const { isValidFile } = useIsValidFile();

  useEffect(() => {
    if (!docUrl && state === UploaderState.ON_UPLOADED) {
      dispatch(UploaderEvent.RESET);
    }
  }, [docUrl]);

  const handleFileNotSupportedMessage = ({ meta }: IFileWithMeta) => {
    const { id, name, size } = meta;
    setSnackbarFiles([
      {
        id,
        name,
        size,
        status: FileUploadStatus.FILE_NOT_SUPPORTED,
        uploadProgress: 0,
        canBeDeleted: true,
      },
    ]);
  };

  const uploadFileOld = async ({
    fileWithMeta,
    allFiles,
    status,
    meta,
  }: {
    fileWithMeta: IFileWithMeta;
    allFiles: IFileWithMeta[];
    status: StatusValue;
    meta: IMeta;
  }) => {
    const lastFileInArray = allFiles[allFiles.length - 1];
    // Snackbar logic
    const res = await populateSnackbarFiles({
      fileWithMeta,
      allFiles: lastFileInArray ? [lastFileInArray] : [],
      status,
      attachmentType: PAHMapper[type].attachmentType as TFileAttachmentType,
      label: "",
      tag: "Acquirer document",
      merchantId,
      refetcherKeyOnDelete: "get-merchant-preview",
      resourceID: merchantId,
      customFinish: ({ identifiers }: { identifiers: (number | string)[] }) => {
        dispatch(UploaderEvent.SUCCESS);
        setSnackbarFiles((prevState) => {
          const newState = prevState.map((file: SnackbarFile) => {
            const status =
              file.identifier && identifiers.includes(file.identifier)
                ? FileUploadStatus.UPLOADED
                : file.status;
            return {
              ...file,
              status,
            };
          });
          return newState;
        });
      },
      onSuccess: () => {
        if (meta.previewUrl) setFileUrl(meta.previewUrl);
        queryClient.invalidateQueries("get-merchant-msp-status");
        queryClient.refetchQueries("get-merchant-preview");
      },
    });
    if (res === "upload_failed") {
      dispatch(UploaderEvent.FAIL);
    }
  };
  const uploadFileNew = async ({
    allFiles,
    meta,
  }: {
    fileWithMeta: IFileWithMeta;
    allFiles: IFileWithMeta[];
    status: StatusValue;
    meta: IMeta;
  }) => {
    if (meta.status !== "done") {
      return;
    }
    const files = allFiles.map((file) => ({ file: file.file }));
    await handleUpload({
      list: [files[files.length - 1]], // this is done to not upload multiple files simulataneously as previously uploaded is still saved temporarily
      merchantId,
      resourceID: merchantId,
      attachmentType: PAHMapper[type].attachmentType as TFileAttachmentType,
      label: "",
      tag: "Acquirer document",
    });
    dispatch(UploaderEvent.SUCCESS);
    queryClient.invalidateQueries("get-merchant-msp-status");
    queryClient.refetchQueries("get-merchant-preview");
    if (meta.previewUrl) setFileUrl(meta.previewUrl);
  };

  const isUploadable = [
    UploaderState.ON_INITIAL,
    UploaderState.ON_FAULTED,
  ].includes(state);

  // Upload logic
  const handleChangeStatus = useCallback(
    (
      fileWithMeta: IFileWithMeta,
      status: StatusValue,
      allFiles: IFileWithMeta[],
    ) => {
      const { meta, file, remove } = fileWithMeta;
      const allowedTypes = Object.keys(AcceptAllowedImagesTypes);
      const isNotValidFile =
        isFileUploadRefactorEnabled &&
        !["preparing", "removed"].includes(status) &&
        !isValidFile({ file, allowedTypes });

      // Early return if the file is removed
      if (status === "removed") return;

      // Handle non-uploadable files
      if (!isUploadable || isNotValidFile) {
        remove && remove();
        return;
      }

      // Handle rejected file types in version one
      if (status === "rejected_file_type" && !isFileUploadRefactorEnabled) {
        handleFileNotSupportedMessage(fileWithMeta);
        return;
      }

      // Handle successful upload
      if (status === "done") {
        setFileName(file.name);
        dispatch(UploaderEvent.UPLOAD);
      }

      const uploadFunction = isFileUploadRefactorEnabled
        ? uploadFileNew
        : uploadFileOld;
      uploadFunction({ status, fileWithMeta, meta, allFiles });
    },
    [PAHMapper[type].attachmentType, state, isUploadable],
  );

  // Reset
  const reset = async () => {
    await queryClient.refetchQueries("get-merchant-preview");
    setFileUrl("");
    setFileName("");
    dispatch(UploaderEvent.RESET);
  };

  // Delete
  const _deleteDocument = () => {
    deleteDocument(
      merchantId,
      {
        fileName: PAHMapper[type].deleteMessage,
        id: fileId!,
      },
      reset,
    );
  };

  return {
    state,
    fileName,
    // We are using fileUrl to give instant background image to the upload zone
    // The main problem is that when docUrl change we are not changing the fileUrl to not give flicker/glitch effect
    // So this check removes the image if the image was deleted in other parts of the application (e.g. document section)
    // and the useEffect with docUrl dep resets the component to initial state if that's the case
    fileUrl: docUrl ? fileUrl : "",
    handleChangeStatus,
    _deleteDocument,
    isUploadable,
  };
};
