import {
  CREATE_EVENT_MODAL,
  CREATE_FUNDRAISER_MODAL,
  CREATE_INVOICE_MODAL,
  CREATE_MEMBERSHIP_MODAL,
  CREATE_PAYMENT_FORM_MODAL,
  CREATE_SWEEPSTAKES_MODAL,
} from "modals/modal_names";

export const CampaignModal = {
  event: CREATE_EVENT_MODAL,
  fundraiser: CREATE_FUNDRAISER_MODAL,
  membership: CREATE_MEMBERSHIP_MODAL,
  sweepstake: CREATE_SWEEPSTAKES_MODAL,
  invoice: CREATE_INVOICE_MODAL,
  standard: CREATE_PAYMENT_FORM_MODAL,
};
