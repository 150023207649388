import { Box, Stack } from "@mui/material";
import SectionHeader from "./SectionHeader";
import { HandHeart } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme, styled } from "@theme/v2/Provider";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import HFGiveSelect from "@shared/HFInputs/HFGiveSelect/HFGiveSelect";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { compact, delay, isEmpty, uniq } from "lodash";
import HFGiveCustomAmount from "@shared/HFInputs/HFGiveCustomAmount/HFGiveCustomAmount";
import { useEffect, useState } from "react";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import { useLocation, useParams } from "react-router-dom";
import { parsePriceToInteger } from "@utils/helpers";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import { useGetFundraiserVariants } from "@sections/PayBuilder/provider/useManageApi";
import { useAddToCart } from "@hooks/merchant-api/cart";
import { useDebouncedCallback } from "use-debounce";

const mapValueToLabel: any = {
  one_time: "One Off Donation",
  monthly: "Monthly",
  quarterly: "Quarterly",
  yearly: "Yearly",
};

const YourDonationPreview = () => {
  const { state } = useLocation();

  const initPrice = state?.initPrice;
  const { methods } = usePayBuilderForm();
  const [isInputOpen, setIsInputOpen] = useState(false);
  const { setValue } = methods;
  const isCustomEnabled = methods.watch("Donations.allowCustomAmount");
  const isRecurringDonationEnabled = methods.watch(
    "Donations.enableRecurringDonations",
  );
  const isSuggestedAmountEnabled = methods.watch(
    "Donations.enableSuggestedAmounts",
  );
  const suggestedAmount = methods.watch("Donations.suggestedAmount");

  const optionalAmounts = methods.watch("Donations.optionalAmounts");
  const recurringIntervalOptions = methods.watch(
    "Donations.recurringIntervals",
  );
  const selectedAmount = methods.watch("Donations.selectedAmount");
  const isAnonymous = methods.watch("Donations.isAnonymous");
  const items = uniq(compact(optionalAmounts || []));

  const { cartItems, addToCart, clearCart } = useCart();
  const { id: productId } = useParams();
  const { isFundraiser } = useCheckFormType();
  const { data } = useGetFundraiserVariants(productId || "");
  const { addToCartHandler } = useAddToCart({
    disableFetchCart: true,
    newCartItems: cartItems,
    showModal: false,
  });

  useEffect(() => {
    if (!isEmpty(initPrice)) {
      console.log({ initPrice });
      delay(() => {
        methods.setValue("Donations.selectedAmount", initPrice, {
          shouldDirty: true,
        });
      }, 0);
    }
  }, [initPrice]);
  const handleAddToCart = async (item: any) => {
    if (isEmpty(data) || isEmpty(data.data)) {
      return;
    }
    clearCart();
    const variant = data.data[0];

    const cartItem = {
      id: String(variant.id),
      productVariantName: variant.name,
      productVariantID: variant.id,
      productVariantPrice: parsePriceToInteger(item, false),
      quantity: 1,
      unitPrice: item,
      productVariantImageURL: variant.imageURL || "",
      recurringIntervalName: variant.defaultRecurringIntervalName || "once",
      in_stock: 1,
      price: parsePriceToInteger(item, false),
    };

    addToCart(cartItem, 1, undefined, true, isFundraiser);
  };

  const debouncedAddToCartHandler = useDebouncedCallback(async () => {
    await addToCartHandler();
  }, 500);

  const debouncedAddDonationToCart = async (item: any) => {
    await handleAddToCart(item);
    debouncedAddToCartHandler();
  };

  const handleAmountClick = async (item: any) => {
    setIsInputOpen(false);
    setValue("Donations.selectedAmount", item);
    debouncedAddDonationToCart(item);
  };

  const formatOnBlur = () => {
    const value = methods.getValues("Donations.selectedAmount") || "";
    const numericValue = parseFloat(value.replace(/,/g, "")) || 0;
    const formattedValue = numericValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
    });
    methods.setValue("Donations.selectedAmount", formattedValue);
    debouncedAddDonationToCart(formattedValue);
  };

  return (
    <Stack
      sx={{
        overflowX: "hidden",
      }}
      maxWidth="100%"
      spacing="20px"
    >
      <SectionHeader title="Your Donation" icon={<HandHeart size={24} />} />

      {isRecurringDonationEnabled && (
        <HFGiveSelect
          name="recurringInterval"
          options={["one_time", ...recurringIntervalOptions.slice(1)].map(
            (item: any) => {
              return {
                label: mapValueToLabel[item],
                value: item,
              };
            },
          )}
        />
      )}
      {isCustomEnabled && (
        <Stack
          maxWidth="100%"
          gap="8px"
          direction="row"
          justifyContent="space-between"
          sx={{
            flexGrow: 1,
          }}
        >
          {items
            ?.filter((item) => parseFloat(item))
            ?.map((item, index) => {
              return (
                <DonationAmountItem
                  item={`$${item}`}
                  key={index}
                  onClick={() => handleAmountClick(item)}
                  isSelected={!isEmpty(item) && selectedAmount === item}
                  isSuggested={
                    isSuggestedAmountEnabled && suggestedAmount === item
                  }
                />
              );
            })}

          <DonationAmountItem
            onClick={() => {
              setValue("Donations.selectedAmount", "");
              setIsInputOpen(true);
            }}
            item={`Custom`}
            isSelected={isInputOpen}
          />
        </Stack>
      )}
      {(!isCustomEnabled || isInputOpen) && (
        <HFGiveCustomAmount
          name="Donations.selectedAmount"
          currency="usd"
          label="Custom Donation"
          height="74px"
          placeholder="Custom Donation"
          value={methods.watch("Donations.selectedAmount")}
          onHandleBlur={formatOnBlur}
          onChange={(value) => {
            methods.setValue(
              "Donations.selectedAmount",
              value.replace(/,/g, ""),
            );
          }}
        />
      )}

      <Stack direction="row" alignItems="center" spacing={1}>
        <GiveCheckbox
          name="Donations.isAnonymous"
          value={isAnonymous}
          checked={isAnonymous}
          onChange={() => setValue("Donations.isAnonymous", !isAnonymous)}
        />

        <GiveText color="primary" fontSize="14px">
          Make my donation anonymous
        </GiveText>
      </Stack>
    </Stack>
  );
};

export default YourDonationPreview;

const DonationAmountItem = ({
  item,
  onClick,
  isSuggested,
  isSelected,
}: {
  item: string;
  onClick?: () => void;
  isSuggested?: boolean;
  isSelected?: boolean;
}) => {
  const { palette } = useAppTheme();
  const { parsedValues } = usePayBuilderForm();

  return (
    <Container
      justifyContent="center"
      alignItems="center"
      flex={1}
      height="64px"
      isSelected={isSelected}
      accentColor={parsedValues?.accentColor}
      onClick={() => onClick?.()}
    >
      {isSuggested && (
        <SuggestedBox>
          <GiveText variant="bodyXS" color="primary">
            Suggested
          </GiveText>
        </SuggestedBox>
      )}
      <GiveText
        color={"primary"}
        fontSize="14px"
        sx={{
          ...(isSelected && {
            color: palette.primitive?.neutral[0],
          }),
        }}
      >
        {item}
      </GiveText>
    </Container>
  );
};

type ContainerProps = {
  isSelected?: boolean;
  accentColor?: string;
};

const SuggestedBox = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    bottom: "-10px",
    margin: "0 auto",
    padding: "4px 8px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette?.border?.secondary}`,
    backgroundColor: theme.palette?.surface?.primary,
  };
});

const Container = styled(Stack)<ContainerProps>(
  ({ theme, isSelected, accentColor }) => ({
    borderRadius: "8px",
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `${isSelected ? 0 : "1px"} solid ${
      theme?.palette?.border?.secondary
    }`,
    "&:hover": {
      cursor: "pointer",
    },
    position: "relative",
    ...(isSelected && {
      backgroundColor: accentColor,
    }),
  }),
);
