import { Stack } from "@mui/material";
import GiveTabs from "@shared/Tabs/GiveTabs";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";

interface TabSectionProps {
  title: string;
  selectedTab: any;
  tabItems: any[];
  onClick: (e: any) => void;
}

const TabSection = ({
  selectedTab,
  tabItems,
  onClick,
  title,
}: TabSectionProps) => {
  const { isMobileView } = useCustomThemeV2();
  return (
    <TabSectionContainer isMobileView={isMobileView}>
      <TabSectionTitle variant="bodyL" color="primary">
        {title}
      </TabSectionTitle>
      <GiveTabs
        selected={selectedTab}
        containerSx={{
          minWidth: isMobileView ? "100%" : "60%",
          justifyContent: "space-around",
          padding: "4px",
          flex: 1,
        }}
        tabSx={{ flex: 1, justifyContent: "center" }}
        items={tabItems}
        onClick={onClick}
        type="segmented"
      />
    </TabSectionContainer>
  );
};

export default TabSection;

const TabSectionTitle = styled(GiveText)(({ theme }) => {
  return {
    flex: 1,
  };
});
const TabSectionContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop != "isMobileView",
})<{ isMobileView: boolean }>(({ isMobileView }) => {
  return {
    flexDirection: isMobileView ? "column" : "row",
    gap: "24px",
    alignItems: isMobileView ? "flex-start" : "center",
  };
});
