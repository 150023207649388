import GiveText from "@shared/Text/GiveText";
import GiveBusinessOwnerItem from "../businessOwners/GiveBusinessOwnerItem";
import { adjustAddressType } from "../hooks/helpers";
import {
  TBusinessOwner,
  TMerchantDocument,
} from "@components/Merchants/MerchantPreview/data.types";
import { Stack } from "@mui/material";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { checkPortals } from "@utils/routing";
import { useMemo } from "react";
import useBusinessOwner from "@components/Settings/ManageTeam/BusinessOwners/useBusinessOwner";
import { ChatsCircle, Plus } from "@phosphor-icons/react";
import { HeaderAction } from "./BankAccountSection";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import GiveLink from "@shared/Link/GiveLink";

export const getIDFile = (ownerID: any, documents?: TMerchantDocument[]) => {
  return documents && documents.find((doc) => ownerID === doc.legalPrincipalID);
};

const BusinessOwnersSection = ({
  businessOwners,
  merchantId,
}: {
  businessOwners: TBusinessOwner[];
  merchantId: number;
}) => {
  const {
    id,
    data: { documents, businessProfile, businessOwnersList, header },
    isEnterprise,
  } = useMerchantSidePanelContext();
  const { isEnterprisePortal, isAcquirerPortal } = checkPortals();
  const { merchant_underwriting } = useEnterprisePermissions();

  const businessOwnersLength = businessOwners.length;

  const totalOwnerships = useMemo(() => {
    return (
      businessOwnersList?.reduce((acc, owner) => {
        const _ownership = owner.ownership ? +owner.ownership : 0;
        return acc + _ownership;
      }, 0) || 0
    );
  }, [businessOwnersList]);
  const isApprovedLE =
    header?.businessProfileStatus?.status?.toLowerCase() === "approved";
  const isIndividualSoleProprietorship =
    businessProfile?.businessType === "individual_sole_proprietorship";

  const { addOwnerHandler, editOwnerHandler } = useBusinessOwner({
    stakeSum: totalOwnerships,
    isApprovedLE,
    hasMaxOwners:
      (isIndividualSoleProprietorship && businessOwnersLength >= 1) || false,
    legalEntityID: businessProfile?.id,
    isController: businessProfile?.isController || false,
    showTag: true,
    merchantId,
    isSoleProprietorship: isIndividualSoleProprietorship,
    isBPLinked: businessProfile?.isBPLinked,
    businessType: businessProfile?.businessType,
    documents,
    isMerchant: true,
  });

  const limitCreation =
    isIndividualSoleProprietorship && (businessOwners?.length || 0) >= 1;

  const isAddAllowed = useAccessControl({
    resource: composePermission(
      isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
    ),
    operation: OPERATIONS.CREATE,
  });

  const { openConversationHandler } = useConversationsModal();
  const handleBOConv = () =>
    openConversationHandler({ id: 0, name: "Business Owners", paths: [] });

  const headerActions: HeaderAction[] = [
    {
      Icon: Plus,
      onClick: addOwnerHandler,
      hidden: false,
      iconText: "Add",
      size: "extraSmall",
      disabled: limitCreation || !isAddAllowed,
      tooltipTitle: limitCreation
        ? "The sole proprietorship legal entities cannot have more than one business owner"
        : CREATE_DENY_MESSAGE,
    },
    {
      Icon: ChatsCircle,
      onClick: handleBOConv,
      hidden: !merchant_underwriting && !isAcquirerPortal,
    },
  ];
  return (
    <Stack>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        width="100%"
        marginBottom="20px"
      >
        <GiveText>Business Owners</GiveText>
        <Stack gap="10px" flexDirection="row" alignItems="center">
          {headerActions?.map(
            (
              { Icon, onClick, hidden, iconText, tooltipTitle, disabled },
              idx,
            ) => {
              if (hidden) return null;
              return (
                <GiveTooltip
                  width="compact"
                  alignment="left"
                  title={tooltipTitle || CREATE_DENY_MESSAGE}
                  color="default"
                  disableHoverListener={!disabled}
                  key={idx}
                >
                  <GiveLink
                    component="button"
                    color="primary"
                    onClick={onClick}
                    disabled={disabled}
                    Icon={Icon}
                  >
                    {iconText}
                  </GiveLink>
                </GiveTooltip>
              );
            },
          )}
        </Stack>
      </Stack>
      {businessOwners?.map((owner, idx) => (
        <GiveBusinessOwnerItem
          key={owner.id}
          owner={adjustAddressType(owner)}
          disabled={false}
          onEdit={editOwnerHandler}
          legalEntityID={businessProfile.id}
          isEnterprise={isEnterprisePortal}
          withPepStatus
          showViewPep={true}
          idFile={getIDFile(owner.id, documents)}
          merchantId={id}
          isFirst={idx === 0}
          isLast={idx === businessOwnersLength - 1}
        />
      ))}
    </Stack>
  );
};

export default BusinessOwnersSection;
