import NiceModal from "@ebay/nice-modal-react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { Box, Stack } from "@mui/material";
import ChallengeMessage from "./components/ChallengeMessage";
import { TChallengeTypeName } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { ReasonType } from "./components/types";
import ChallengeDocuments from "features/Merchants/MerchantSidePanel/GiveMerchantFile/components/DocumentsSection";
import ChallengeButtons from "./components/ChallengeButtons";
import IdentityVerification from "features/Merchants/MerchantSidePanel/components/PrimaryAccountHolderSection/IdentityVerification";
import ChallengeDiscussion from "./components/ChallengeDiscussion";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import {
  TBusinessOwner,
  TMerchantDocument,
} from "@components/Merchants/MerchantPreview/data.types";
import { BOIDDisplay } from "features/Merchants/MerchantSidePanel/GiveMerchantFile/businessOwners/BOIDDisplay";
import { getIDFile } from "features/Merchants/MerchantSidePanel/GiveMerchantFile/components/BusinessOwnersSection";
import { adjustAddressType } from "@components/Merchants/MerchantPreview/components/BusinessOwners/MerchantBusinessOwners";
import { useReadChallengeFiles } from "@components/Merchants/MerchantPreview/hooks/useReadChallangeFiles";
import { Check } from "@phosphor-icons/react";
import GiveAlert from "@shared/GiveAlert/GiveAlert";

type Props = {
  doneDisabled?: boolean;
  hasRejectButton?: boolean;
  actions: {
    onReject: (
      id: number,
      reason: string,
      type: TChallengeTypeName,
      reject?: boolean,
    ) => void;
    onApprove: (id: number, reason: string, type: TChallengeTypeName) => void;
    handleOpenConversation: () => void;
  };
  discussionData?: {
    discussionTitle?: string;
    messageImages?: string[];
    commentsCount?: number;
    lastComment?: string;
  };
  notification?: string;
  challengeData: {
    title: string;
    name: string;
    message: string;
    threadId: number;
    challengeTypeName: TChallengeTypeName;
    isPAHChallenge?: boolean;
    statusName?: string;
    isBOVerification?: boolean;
    id: number;
    challengeID: number;
  };
  merchantData: {
    documents: TMerchantDocument[];
    merchantId: number;
    isEnterprise: boolean;
    businessOwners: TBusinessOwner[];
  };
};

const schema = Yup.object().shape({
  reason: Yup.string().min(1, ""),
});

const rejectedSchema = Yup.object().shape({
  reason: Yup.string().min(0, ""),
});

const BaseChallengeModal = NiceModal.create(
  ({
    hasRejectButton = true,
    actions,
    discussionData,
    challengeData,
    merchantData,
    notification,
    doneDisabled,
  }: Props) => {
    const { open, onClose } = useNiceModal();
    const { onReject, onApprove, handleOpenConversation } = actions || {};
    const { discussionTitle, messageImages, commentsCount, lastComment } =
      discussionData || {};
    const {
      title,
      message,
      threadId,
      challengeTypeName,
      statusName,
      isPAHChallenge,
      isBOVerification,
      id,
      challengeID,
    } = challengeData || {};
    const isChallengeDone = statusName === "closed";
    const isChallengeRejected = statusName === "rejected";

    const { documents, merchantId, isEnterprise, businessOwners } =
      merchantData || {};

    const { data } = useReadChallengeFiles({
      merchantId: merchantId,
      challengeId: id,
    });

    const methods = useForm<ReasonType>({
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        reason: "",
      },
      resolver: yupResolver(isChallengeRejected ? rejectedSchema : schema),
    });

    const handleClose = () => {
      methods.reset();
      onClose();
    };

    const sendMessage = (
      id: number,
      reason: string,
      type: TChallengeTypeName,
    ) => {
      if (isChallengeRejected) {
        handleOpenConversation();
      } else {
        onApprove(id, reason, type);
        handleClose();
      }
    };

    return (
      <FormProvider {...methods}>
        <GiveBaseModal
          open={open}
          title={title}
          width="724px"
          height="100%"
          onClose={handleClose}
          buttons={
            <ChallengeButtons
              doneDisabled={doneDisabled}
              isChallengeDone={isChallengeDone}
              hasRejectButton={hasRejectButton}
              isChallengeRejected={isChallengeRejected}
              handleClose={handleClose}
              methods={methods}
              onReject={(challengeID, reason, type) => {
                const reject =
                  challengeData.name === "volume_assessment" ||
                  challengeData.name === "negative_news_screening";

                onReject(challengeID, reason, type, reject);
              }}
              onApprove={sendMessage}
              id={
                challengeTypeName === "enhanced_due_diligence"
                  ? id
                  : challengeID
              }
              challengeType={challengeTypeName}
            />
          }
          closeIconProps={{
            bgColor: "solidWhite",
          }}
        >
          {notification && (
            <Box marginBottom="16px">
              <GiveAlert type="success" label={notification} Icon={<Check />} />
            </Box>
          )}
          <Stack gap="16px">
            <ChallengeMessage
              message={message}
              threadId={threadId}
              challengeTypeName={challengeTypeName}
              merchantID={merchantId}
            />
            {isChallengeDone ||
              (isChallengeRejected && (
                <ChallengeDiscussion
                  title={discussionTitle || ""}
                  messageImages={messageImages || []}
                  commentsCount={commentsCount || 0}
                  lastComment={lastComment || ""}
                  onClick={handleOpenConversation}
                />
              ))}
            {!isChallengeDone && !isChallengeRejected && (
              <HFGiveInput
                label="Reason"
                name="reason"
                placeholder="Enter your reason"
                multiline
                rows={8}
              />
            )}
            <ChallengeDocuments
              id={merchantId}
              challengeID={id}
              data={data?.data?.filter(
                (doc: TMerchantDocument) =>
                  !["account_owner", "account_owner_selfie"].includes(
                    doc.attTypeName,
                  ),
              )}
              isEnterprise={isEnterprise}
              customUploadStackStyles={{
                width: "100%",
              }}
              isHideDelete={true}
            />
            {isBOVerification &&
              businessOwners?.map((bo) => {
                const file = getIDFile(bo.id, documents);
                if (file)
                  return (
                    <BOIDDisplay
                      key={bo.id}
                      merchantId={merchantId}
                      idFile={file}
                      owner={adjustAddressType(bo)}
                    />
                  );
              })}
            {isPAHChallenge && (
              <IdentityVerification
                documents={documents}
                merchantId={merchantId}
                isOptionalEnterprise={isEnterprise}
                isDeleteHidden={true}
              />
            )}
          </Stack>
        </GiveBaseModal>
      </FormProvider>
    );
  },
);

export default BaseChallengeModal;
