import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
} from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { CaretDown } from "@phosphor-icons/react";
import { DisputeCaseType, TCaseEvidence } from "../data.types";
import NiceModal from "@ebay/nice-modal-react";
import { EVIDENCE_PROVIDED_MODAL } from "modals/modal_names";
import { useState } from "react";
import DisputeCaseHistoryCard from "./DisputeCaseHistoryCard";

interface Props {
  data: DisputeCaseType;
  issuingBankName: string;
  mspCaseType: string;
  isLast?: boolean;
  isFirst?: boolean;
}

const DisputeCaseHistoryCards = ({
  data,
  isLast,
  isFirst,
  issuingBankName,
  mspCaseType,
}: Props) => {
  const [expanded, setExpanded] = useState(isFirst);
  const submittedEvidences = data?.evidences?.filter(
    (evidence) => evidence?.isSubmitted,
  );
  const evidencesCount = submittedEvidences?.length || 0;

  const handleViewEvidence = (evidence: TCaseEvidence) => {
    NiceModal.show(EVIDENCE_PROVIDED_MODAL, {
      caseAction: data?.statusActionDisplayName,
      data: evidence,
    });
  };

  return (
    <>
      <StyledAccordion
        onChange={() => setExpanded((curr) => !curr)}
        disableGutters
        expanded={expanded}
      >
        <StyledAccordionSummary
          expandIcon={<CaretDown size={16} stroke={palette.neutral[80]} />}
        >
          <Text color={palette.neutral[80]}>
            {mspCaseType} ({evidencesCount} Evidence Submitted)
          </Text>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {submittedEvidences?.map((evidence) => (
            <DisputeCaseHistoryCard
              key={evidence?.id}
              handleViewEvidence={() => handleViewEvidence(evidence)}
              data={{
                createdAt: evidence?.createdAt,
                title: "Merchant (You)",
                notes: evidence?.notes,
                subNotes: evidence?.fileName,
                fileEvidence: data?.fileEvidence,
              }}
              isLast={false}
            />
          ))}
          <DisputeCaseHistoryCard
            data={{
              createdAt: data?.createdAt,
              title: issuingBankName || "Issuing Bank",
              notes: data?.notes,
              subNotes: data?.reason,
            }}
            isLast
          />
        </StyledAccordionDetails>
      </StyledAccordion>
      {!isLast && <StyledVerticalLine />}
    </>
  );
};

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  borderRadius: "8px !important",
  backgroundColor: palette.background.newWhite,
  border: `1px solid ${palette.neutral[20]}`,
  marginBottom: "16px",
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: "16px",
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  borderRadius: "8px !important",
  backgroundColor: palette.neutral[10],
}));

const StyledVerticalLine = styled(Box)(({ theme }) => ({
  height: 28,
  width: 1,
  backgroundColor: palette.gray[100],
  marginLeft: theme.spacing(2),
}));

export default DisputeCaseHistoryCards;
