import { Stack } from "@mui/material";

import GiveText from "@shared/Text/GiveText";
import QuantityInput from "../products/QuantityInput";
import { Trash } from "@phosphor-icons/react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { ICartItem, useCart } from "@sections/PayBuilder/provider/CartContext";
import { deleteCardItem } from "@services/api/checkout/cart";
import { addSizeToImage } from "@utils/image.helpers";
import { parseAmount } from "@utils/index";

interface CartItemProps {
  item: ICartItem;
  addToCart: (
    item: ICartItem,
    addedQuantity?: number,
    quantityChangeType?: string,
  ) => void;
  removeFromCart: (ProductItemTypeId: string) => void;
  isCheckout?: boolean;
  isSuccessDisplayed?: boolean;
}

const CartItem = ({
  item,
  removeFromCart,
  addToCart,
  isCheckout = false,
  isSuccessDisplayed = false,
}: CartItemProps) => {
  const {
    productVariantID: id,
    productVariantImageURL,
    productVariantName,
    productVariantPrice,
    in_stock,
    recurringIntervalName,
    quantity,
  } = item;
  const { palette } = useAppTheme();
  const { outOfStockItemIds } = useCart();

  const handleIncrement = () => {
    if (
      typeof in_stock === "number" &&
      (in_stock === 0 || quantity >= in_stock)
    )
      return;
    addToCart(item, 1, "increment");
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      addToCart(item, 1, "decrement");
    } else {
      removeFromCart(id);
    }
  };
  const smallThumbnail = addSizeToImage(productVariantImageURL || "", "small");
  const isOutOfStock = outOfStockItemIds.includes(id);

  return (
    <StyledWrapper>
      {productVariantImageURL && (
        <StyledImage
          src={smallThumbnail as string}
          alt={productVariantName}
          width="80px"
          height="80px"
        />
      )}
      <StyledSummary>
        <Stack gap={isCheckout ? "4px" : "12px"}>
          <GiveText variant="bodyXS">{productVariantName}</GiveText>
          {isOutOfStock ? (
            <Stack spacing={1}>
              <GiveText variant="bodyXS" color="error">
                Out of stock
              </GiveText>
              <GiveIconButton
                onClick={() => {
                  removeFromCart(String(id));
                }}
                Icon={Trash}
                iconText="Delete"
                sx={{
                  padding: "7px 12px",
                  borderRadius: "8px",
                  backgroundColor: palette.common.white,
                  border: `1.5px solid ${palette.primitive?.error[100]} `,
                  color: palette.primitive?.error[100],
                }}
              />
            </Stack>
          ) : (
            <Stack
              gap="16px"
              direction={isCheckout ? "column" : "row"}
              alignItems={isCheckout ? "start" : "center"}
            >
              {!isCheckout ? (
                <>
                  <QuantityInput
                    sx={{
                      borderRadius: "8px",
                      padding: "4px 6px",
                      "& > div": { height: "22px" },
                    }}
                    quantity={quantity}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    disableAddition={
                      (in_stock && quantity >= in_stock) || false
                    }
                  />
                </>
              ) : (
                <GiveText variant="bodyXS" color="secondary">
                  Quantity: {quantity}
                </GiveText>
              )}
              {!isSuccessDisplayed && (
                <GiveIconButton
                  Icon={Trash}
                  color={palette.primitive?.error[50]}
                  variant="ghost"
                  onClick={async () => {
                    if (isCheckout) {
                      await deleteCardItem(Number(item.id));
                    }
                    removeFromCart(id);
                  }}
                  sx={{ padding: isCheckout ? "6px 0" : "6px" }}
                />
              )}
            </Stack>
          )}
        </Stack>
        <GiveText variant="bodyXS">
          ${parseAmount(productVariantPrice)}{" "}
          <StyledSpan>
            {recurringIntervalName === "once" ? "" : recurringIntervalName}
          </StyledSpan>
        </GiveText>
      </StyledSummary>
    </StyledWrapper>
  );
};

export default CartItem;

const StyledWrapper = styled(Stack)({
  padding: "16px 0",
  height: "112px",
  gap: "16px",
  borderBottom: "1px solid #0000001A",
  flexDirection: "row",
  alignItems: "center",
});
const StyledSummary = styled(Stack)({
  flexDirection: "row",
  alignItems: "flex-start",
  flex: 1,
  gap: "16px",
  justifyContent: "space-between",
});
const StyledImage = styled("img")({
  objectFit: "cover",
  borderRadius: "8px",
});
const StyledSpan = styled("span")({
  textTransform: "capitalize",
});
