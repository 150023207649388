import { customInstance } from "@services/api";
import { isFinite, toNumber } from "lodash";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export const useGetProductFeePayment = () => {
  const { pathname } = useLocation();
  const id = pathname?.substring(pathname.lastIndexOf("/") + 1);
  const productId = id || "0";

  const getProduct = (id: number | string) => {
    return customInstance({
      url: `products/${id}`,
      method: "GET",
    });
  };

  const { data } = useQuery(
    ["find-product-payment-by-id", productId],
    async () => {
      const product = await getProduct(productId);
      return product;
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(productId) && checkIfNumber(productId),
    },
  );

  const needsTax = data?.needsTax || false;
  const optional = data?.needsTax ? data?.allowFeeChoice : false;
  return {
    needsTax,
    optional,
    passFees: !needsTax,
  };
};

function checkIfNumber(value: string | number) {
  const num = toNumber(value); // Convert the value to a number
  return isFinite(num); // Check if it's a finite number
}
