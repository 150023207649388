import ImagePlaceholder from "@assets/svgIcons/storefront-placeholder.svg";
import NiceModal from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import { Item_Layout } from "@sections/PayBuilder/provider/provider.type";
import {
  PaymentRecurringType,
  ProductItemType,
} from "@sections/PayBuilder/types";
import { parseDescription } from "@shared/HFInputs/HFRichInput/RichTextUtils";
import GiveText from "@shared/Text/GiveText";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import { styled } from "@theme/v2/Provider";
import { addSizeToImage } from "@utils/image.helpers";
import { parseAmount } from "@utils/index";
import { PUBLIC_PRODUCT_ITEM_MODAL } from "modals/modal_names";
import { TFormStatus } from "./types";
import { isNumber } from "lodash";
import useCheckFormType from "../hooks/useCheckFormType";

const OUT_STOCK_LABEL = "Out of stock";
type Props = {
  product: ProductItemType;
  displayStatus: TFormStatus;
  itemLayout: Item_Layout;
  paymentType: PaymentRecurringType;
  isMobileView?: boolean;
  accentColor: string;
  isFromADB?: boolean;
};

const ProductCard: React.FC<Props> = ({
  product,
  displayStatus,
  itemLayout,
  paymentType,
  isMobileView,
  accentColor,
  isFromADB,
}) => {
  const { id, thumbnail, amount, title, in_stock, description } = product;
  const parsedDescription = parseDescription(description);

  const isCardMode = itemLayout === ("card" as Item_Layout);
  const height = isCardMode ? (isMobileView ? "132px" : "240px") : "80px";

  const { addToCart, getItemInCart } = useCart();
  const cartProduct = getItemInCart(id);
  const quantity = cartProduct?.quantity || 1;
  const { isEvent } = useCheckFormType();

  const smallThumbnail = addSizeToImage(
    thumbnail || "",
    "small",
    ImagePlaceholder,
  );

  const handleProductClick = (productId: string) => {
    NiceModal.show(PUBLIC_PRODUCT_ITEM_MODAL, {
      product,
      addToCart,
      accentColor,
      productQuantity: quantity,
      displayStatus,
    });
  };
  const isOutOfStock = in_stock === 0;
  return (
    <StyledContainer
      data-testid="single-product"
      onClick={() => handleProductClick(id)}
      direction={isCardMode ? "column" : "row-reverse"}
      itemLayout={itemLayout}
      displayStatus={displayStatus}
      existingProduct={Boolean(cartProduct)}
      quantity={quantity}
      isFromADB={isFromADB}
      isCardMode={isCardMode}
      accentColor={accentColor}
      sx={{
        cursor: "pointer",
        ...(isOutOfStock && {
          pointerEvents: "none",
          "& img": {
            filter: "grayscale(1)",
          },
        }),
      }}
    >
      {Boolean(thumbnail) && (
        <StyledImage
          alt={title}
          src={smallThumbnail}
          isCardMode={isCardMode}
          height={height}
        />
      )}
      <ContentWrapper>
        <GiveTruncateText
          lineClamp={1}
          variant="bodyS"
          color="primary"
          fontWeight="573"
          {...(isFromADB && {
            sx: {
              color: "#292928",
            },
          })}
        >
          {title}
        </GiveTruncateText>
        <GiveTruncateText
          variant="bodyS"
          color="secondary"
          {...(isFromADB && {
            sx: {
              color: "#666665",
            },
          })}
          lineClamp={1}
        >
          {parsedDescription}
        </GiveTruncateText>
        <Stack gap="12px" direction="row">
          <GiveText
            variant="bodyS"
            color="primary"
            {...(isFromADB && {
              sx: {
                color: "#292928",
              },
            })}
          >
            {isFromADB ? `$${parseAmount(amount)}` : `$${parseAmount(amount)} `}
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {isFromADB || paymentType === "once" ? "" : paymentType}
            </span>
          </GiveText>
          {isNumber(in_stock) && (
            <GiveText
              variant="bodyS"
              color="error"
              sx={!isOutOfStock ? { color: accentColor } : {}}
            >
              {isOutOfStock
                ? OUT_STOCK_LABEL
                : isEvent
                ? `${in_stock} available`
                : ""}
            </GiveText>
          )}
        </Stack>
      </ContentWrapper>
    </StyledContainer>
  );
};

export default ProductCard;

const StyledImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isCardMode",
})<{ isCardMode: boolean }>(({ isCardMode }) => ({
  borderRadius: "8px",
  width: isCardMode ? "100%" : "80px",
  objectFit: "cover",
  itemLayout: "block",
  flexShrink: 0,
}));

const StyledContainer = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "itemLayout" &&
    prop != "existingProduct" &&
    prop !== "quantity" &&
    prop !== "displayStatus" &&
    prop !== "isCardMode" &&
    prop !== "isFromADB",
})<{
  itemLayout: string;
  existingProduct: boolean;
  quantity: number;
  displayStatus: TFormStatus;
  isCardMode: boolean;
  accentColor: string;
  isFromADB?: boolean;
}>(
  ({
    itemLayout,
    existingProduct,
    quantity,
    displayStatus,
    isCardMode,
    theme,
    isFromADB,
    accentColor,
  }) => ({
    borderRadius: "12px",
    padding: isCardMode ? "16px 16px 24px 16px" : "16px",
    border: `1px solid ${
      !isFromADB ? theme.palette.border?.primary : "#0000001A"
    }`,
    width: "100%",
    gap: "16px",
    position: "relative",
    ...(displayStatus === "draft" && {
      pointerEvents: "none",
    }),
    ...(existingProduct && {
      border: `1px solid ${accentColor}`,
      "&:before": {
        content: `'${quantity}'`,
        position: "absolute",
        width: "22px",
        height: "22px",
        borderRadius: "30px",
        background: accentColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        color: "white",
        top: "-11px",
        right: "-11px",
      },
    }),
  }),
);

const ContentWrapper = styled(Stack)({
  gap: "6px",
  flex: 1,
});
