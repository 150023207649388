import SidePanelHeaderVariant, {
  SidePanelHeaderVariantProps,
} from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeaderVariant";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import {
  BookmarkSimple,
  FileText,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { NEW_ACTION_DENY_MESSAGE } from "@constants/permissions";
import ControlMode from "@assets/iconsV2/ControlMode";

const MainHeader = () => {
  const {
    onClose,
    isLoading,
    isFirst = true,
    isLast = true,
    handlers,
    data,
  } = useMerchantSidePanelContext();
  const { isMobileView } = useCustomThemeV2();
  const { control_mode } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();

  const VariantProps: SidePanelHeaderVariantProps = {
    actions: [
      {
        text: "Agreement",
        hidden: !isMobileView,
        onClick: handlers.handleOpenAgreementSnapshot,
        IconRight: PencilSimpleLine,
      },
      {
        text: "Merchant File",
        hidden: !isMobileView,
        onClick: handlers.handleOpenMerchantFile,
        IconRight: FileText,
      },
      {
        text: "Control Mode",
        hidden: !control_mode && isEnterprisePortal,
        onClick: handlers.handleControlMode,
        IconRight: ControlMode,
      },
      {
        text: `${data?.header?.watchlist ? "Remove from" : "Add to"} Watchlist`,
        hidden: false,
        onClick: handlers.handleAddToWatchList,
        IconRight: BookmarkSimple,
        disabled: !isUpdateUnderwriterAllowed,
        disableTooltip: isUpdateUnderwriterAllowed,
        tooltipTitle: NEW_ACTION_DENY_MESSAGE,
      },
    ],
    nextPrevState: { isFirst, isLast },
    isControlModeAllowed: true,
    handlers: {
      displayRowHandlers: true,
      handleClose: onClose,
      ...handlers,
    } as any,
    hasUnreadMessages: false,
    hasAlert: true,
    isRiskMonitorAllowed: false,
    withMerchantFile: true,
    withRisk: true,
    withAgreement: true,
    isLoading,
  };

  return (
    <SidePanelHeaderVariant
      variant="merchant_&_provider"
      HeaderProps={VariantProps}
    />
  );
};

export default MainHeader;
