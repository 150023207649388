import DatePicker, { validatedDate } from "@common/DatePickers/DatePicker";
import { Grid } from "@mui/material";
import { DatePickerToolbar } from "@mui/x-date-pickers/DatePicker/DatePickerToolbar";
import { CalendarBlank } from "@phosphor-icons/react";
import { Picker } from "@shared/DatePicker/Picker";
import { GiveTextField } from "@shared/GiveInputs/GiveInput";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  name: string;
  label?: string;
  onChangeCustom?: any;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: Date;
};

export const ControlledDatePicker = ({
  name,
  label,
  onChangeCustom,
  disableFuture,
  disablePast,
  minDate,
}: Props) => {
  const { isMobileView } = useCustomThemeV2();
  const { control, trigger } = useFormContext();
  const _minDate =
    minDate ||
    (disablePast ? new Date().setDate(new Date().getDate() + 1) : null);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, onChange, ...rest },
        fieldState: { error, invalid },
      }) =>
        isMobileView ? (
          <Picker
            label={label}
            minDate={_minDate}
            field={{
              ref,
              onChange: (value: any) => {
                onChange(validatedDate(value));
                if (onChangeCustom) {
                  onChangeCustom(value);
                }
              },
              ...rest,
            }}
            error={error}
            disablePast={disablePast}
            disableFuture={disableFuture}
            name={name}
            ToolbarComponent={(props: any) => (
              <DatePickerToolbar {...props} toolbarTitle="" />
            )}
            handleClickAccept={(data: any) => {
              console.log(data, invalid);
            }}
          />
        ) : (
          <DatePicker
            {...rest}
            onChange={(value: any) => {
              onChange(value);
              trigger(name);
              if (onChangeCustom) {
                onChangeCustom(value);
              }
            }}
            minDate={_minDate}
            disablePast={disablePast}
            disableFuture={disableFuture}
            inputRef={ref}
            inputFormat="MM/dd/yyyy"
            adornmentPosition="start"
            popperPlacement="bottom-start"
            renderInput={({ inputProps, ...params }) => (
              <Grid container gap={1}>
                <GiveTextField
                  {...params}
                  inputRef={ref}
                  fullWidth
                  name={name}
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={inputProps}
                  label={label}
                  leftContent={<CalendarBlank size={20} />}
                />
              </Grid>
            )}
          />
        )
      }
    />
  );
};
