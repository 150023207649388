import { Stack } from "@mui/material";
import KotoStepper from "@common/SignUp/KotoStepper";
import BusinessDetailsStep from "./BusinessDetailsStep";
import BusinessAddressStep from "./BusinessAddressStep";
import BusinessOwner from "./BusinessOwner";
import MerchantInfo from "./MerchantInfo";
import { HiddenComponent } from "@containers/HiddenComponent";
import useBusinessProfileSteps from "./hooks/useBusinessProfileSteps";
import { checkPortals } from "@utils/routing";
import EnterpriseInfo from "./EnterpriseInfo";
import { generateNameLabelPlaceholder } from "@components/Signup/Forms/SignupOrganizationDetails";
import { useMemo } from "react";
import { refineData } from "./helpers/refineData";
import useBusinessDetailsData, {
  TSetFormValues,
} from "./hooks/useBusinessDetailsData";

type TData = {
  merchant: any;
  legalEntity: any;
};

type Props = {
  backLink: () => void;
  data?: TData;
};

export type TBusinessStepsCommons = {
  submitHandler: TSetFormValues;
  handleBack: () => void;
  statusBar: number;
  updateStatusBar: (v: number) => void;
  isSubmitting: boolean;
};

const BusinessProfileSetup = ({ backLink, data }: Props) => {
  const { isEnterprisePortal } = checkPortals();

  const legalEntityId = data?.merchant?.legalEntityID;

  const canEdit = data?.legalEntity
    ? typeof data.legalEntity?.canEdit === "boolean"
      ? data.legalEntity?.canEdit
      : true
    : true;

  const allSteps = getAllSteps(data?.merchant?.class?.name, isEnterprisePortal);
  const steps = getDefaultSteps(allSteps, isEnterprisePortal);

  const defaultStep = allSteps.BUSINESS_DETAILS;

  const parsedData = useMemo(() => refineData(data), [data]);
  const providerPrimaryAccoundHolderData = isEnterprisePortal
    ? parsedData.enterpriseInfo.primaryAccountHolder
    : undefined;
  const {
    stepperConfig,
    handleUpdateStatusValue,
    statusBar,
    step,
    isLoaded,
    handleBack,
    handleNext,
  } = useBusinessProfileSteps(steps, defaultStep);

  const { setFormValues, isLoading: isSubmitting } = useBusinessDetailsData({
    legalEntityId,
  });

  const submitHandler: TSetFormValues = (section, values, options) => {
    const nextHandler =
      section === "enterpriseInfo" || section === "merchantInfo"
        ? backLink
        : handleNext;

    if (!options?.makeApiCall) {
      nextHandler();
      return;
    }
    setFormValues(section, values, {
      ...options,
      handleNext: nextHandler,
    });
  };

  const commonProps: Record<string, TBusinessStepsCommons> = {
    [allSteps.BUSINESS_DETAILS]: {
      handleBack: backLink,
      submitHandler,
      statusBar: statusBar[0]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.BUSINESS_DETAILS),
      isSubmitting,
    },
    [allSteps.BUSINESS_ADDRESS]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[1]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.BUSINESS_ADDRESS),
      isSubmitting,
    },
    [allSteps.ADD_BUSINESS_OWNER]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[2]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.ADD_BUSINESS_OWNER),
      isSubmitting,
    },
    [allSteps.MERCHANT_INFO]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[3]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.MERCHANT_INFO),
      isSubmitting,
    },
    [allSteps.ENTERPRISE_INFO]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[3]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.ENTERPRISE_INFO),
      isSubmitting,
    },
  };

  const components = [
    {
      node: (
        <BusinessDetailsStep
          {...commonProps[allSteps.BUSINESS_DETAILS]}
          canEdit={canEdit}
          legalEntityId={legalEntityId}
          data={parsedData.businessDetails}
        />
      ),
      visible: step === allSteps.BUSINESS_DETAILS,
    },
    {
      node: (
        <BusinessAddressStep
          {...commonProps[allSteps.BUSINESS_ADDRESS]}
          canEdit={canEdit}
          legalEntityId={legalEntityId}
          data={parsedData.businessAddress}
        />
      ),
      visible: step === allSteps.BUSINESS_ADDRESS,
    },
    {
      node: (
        <BusinessOwner
          {...commonProps[allSteps.ADD_BUSINESS_OWNER]}
          canEdit={
            typeof data?.legalEntity?.canEdit === "boolean"
              ? data?.legalEntity?.canEdit
              : true
          }
          data={parsedData.businessOwners}
          businessType={data?.legalEntity?.type?.name}
          primaryAccountHolder={providerPrimaryAccoundHolderData}
        />
      ),
      visible: step === allSteps.ADD_BUSINESS_OWNER,
    },
    {
      node: (
        <MerchantInfo
          {...commonProps[allSteps.MERCHANT_INFO]}
          data={parsedData.merchantInfo}
        />
      ),
      visible: step === allSteps.MERCHANT_INFO && !isEnterprisePortal,
    },
    {
      node: (
        <EnterpriseInfo
          {...commonProps[allSteps.ENTERPRISE_INFO]}
          data={parsedData.enterpriseInfo}
        />
      ),
      visible: step === allSteps.ENTERPRISE_INFO && isEnterprisePortal,
    },
  ];

  return (
    <Stack direction="column" justifyContent="stretch" height="inherit">
      <Stack direction="row" alignItems="center" gap={2}>
        <KotoStepper sx={{ flexGrow: 1 }} {...stepperConfig} />
      </Stack>
      {components.map(({ node, visible }, index) => (
        <HiddenComponent key={index} hidden={!visible || !isLoaded}>
          {node}
        </HiddenComponent>
      ))}
    </Stack>
  );
};

export default BusinessProfileSetup;

const getDefaultSteps = (
  allSteps: Record<string, string>,
  isEnterprisePortal: boolean,
) => {
  const businessSteps = [
    allSteps.BUSINESS_DETAILS,
    allSteps.BUSINESS_ADDRESS,
    allSteps.ADD_BUSINESS_OWNER,
  ];
  const merchantStep = [allSteps.MERCHANT_INFO];
  const enterpriseStep = [allSteps.ENTERPRISE_INFO];

  const steps = [
    ...businessSteps,
    ...(isEnterprisePortal ? enterpriseStep : merchantStep),
  ];

  return steps.map((name) => ({
    label: name,
    barValue: 0,
  }));
};

const getAllSteps = (
  merchantDisplayInfo: string,
  isEnterprisePortal: boolean,
) => {
  const isProviderDefault = !merchantDisplayInfo && isEnterprisePortal;

  const displayName = isProviderDefault
    ? "Provider"
    : generateNameLabelPlaceholder(merchantDisplayInfo);

  const allSteps: Record<string, string> = {
    BUSINESS_DETAILS: "Business details",
    BUSINESS_ADDRESS: "Business address",
    ADD_BUSINESS_OWNER: "Add business owner",
    MERCHANT_INFO: `${displayName} Info`,
    ENTERPRISE_INFO: `${displayName} Info`,
  };
  return allSteps;
};
