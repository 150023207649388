import GiveSelect from "@shared/GiveInputs/GiveSelect";
import  { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import useUpdateMerchant from "../../hooks/useUpdateMerchant";
import GivePopup from "@shared/Popup/GivePopup";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { CustomToolTip as PermissionTooltip } from "componentsV2/Table/CustomTooltip";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";
import {
  ItemDescription,
} from "@common/BusinessProfileInputs/CategoriSelectorWithSearch";
import useGetEnterpriseCategoryCodes from "@hooks/enterprise-api/merchants/useGetEnterpriseCategoryCodes";
import { TRiskLevelName } from "@common/Tag/RiskStatusChip";
import { TCategory } from "@common/BusinessProfileInputs/types";
import { debounce } from "lodash";

type TOptions = {
  label: string;
  value: number;
  code: string;
  isSelected: boolean;
  checkedIconType: "Check";
  riskStatus: TRiskLevelName;
  description: JSX.Element | string; 
};

const defaultState = {
  open: false,
  newId: null,
};

function MCCInput({
  setLocalRiskValue,
}: {
  setLocalRiskValue: Dispatch<SetStateAction<string>>;
}) {
  const { data } = useMerchantSidePanelContext();
  const [searchValue, setSearchValue] = useState<string>("");
  const [{ open, newId }, setOpen] = useState<{
    open: boolean;
    newId: null | number | string;
  }>(defaultState);
  const { updateMerchantMutation } = useUpdateMerchant();
  const { mutate, isLoading } = updateMerchantMutation;
  const { enterpriseID, categoryCodeID } = data?.merchantInfo || {};
  const { data: categories, isLoading: isLoadingGetCategories } =
    useGetEnterpriseCategoryCodes({ id: enterpriseID, searchKey:searchValue });
  const { isMobileView } = useCustomThemeV2();
  const { modify_merchant } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();
  const [localValue, setLocalValue] = useState(categoryCodeID);

  const handleClose = () => {
    setOpen(defaultState);
  };
  const  categoriesOptions = useMemo(() => {
    return categories?.data?.map((category: TCategory) => ({
      label: `${category?.categoryCodes.code} - ${category?.categoryCodes.title}`,
      value: category.categoryCodes.id,
      code: category.categoryCodes.code,
      isSelected: Number(categoryCodeID) === Number(category.categoryCodes.id),
      checkedIconType: "Check",
      riskStatus: category?.categoryCodes.merchantRiskStatus,
      description: (
        <ItemDescription
          riskStatus={
            category?.categoryCodes.merchantRiskStatus as TRiskLevelName
          }
        />
      ),
    }));
  }, [categories, searchValue, categoryCodeID]);

  const debouncedSetSearchValue = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 300),
    [],
  );

  const contextualMenuProps = {
    searchBarProps: {
      handleChange: (val: string) => debouncedSetSearchValue(val),
      value: searchValue,
    },
    onCloseEmptyState: () => setSearchValue(""),
    emptySection: "empty-search",
    ...(!isMobileView
      ? {
          color: "transparent",
          texture: "blurred",
          anchorOrigin: {
            vertical: -20,
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      : {}),
  };

  const oldMCC = categoriesOptions?.find(
    (item: TOptions) => Number(item?.value) === Number(categoryCodeID),
  );
  const newMCC = categoriesOptions?.find(
    (item: TOptions) => Number(item?.value) === Number(newId),
  );

  const hasEditMerchantPermission = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const allowedEditMerchant = isEnterprisePortal
    ? hasEditMerchantPermission && modify_merchant
    : hasEditMerchantPermission;

  return (
    <PermissionTooltip
      showToolTip={!allowedEditMerchant}
      message={ACTION_DENY_MESSAGE}
      placement="top"
      alignment="end"
    >
      <GiveSelect
        label="Merchant Category Code (MCC)"
        options={categoriesOptions}
        useContextualMenu
        disabled={isLoading || isLoadingGetCategories || !allowedEditMerchant}
        value={localValue}
        contextualMenuProps={contextualMenuProps as any}
        withIconOffset={false}
        onChange={(e) => {
          const newMCC = e?.target?.value;

          if (newMCC === oldMCC?.value) return;

          e?.target?.value &&
            setOpen({
              open: true,
              newId: newMCC,
            });
        }}
      />
      <GivePopup
        type="success"
        open={open}
        actionButtons={{
          cancel: {
            label: "Cancel",
            onClick: handleClose,
          },
          success: {
            label: "Yes, Change",
            onClick: () => {
              setLocalValue(Number(newId));
              newMCC?.riskStatus && setLocalRiskValue(newMCC.riskStatus);
              mutate({
                categoryCodeID: Number(newId),
                merchantRiskStatus: newMCC?.riskStatus,
              });
              handleClose();
            },
          },
        }}
        onClose={handleClose}
        description={
          <>
            {oldMCC && newMCC ? (
              <>
                Are you sure you want to change the MCC code from{" "}
                <strong>{oldMCC?.label}</strong> to{" "}
                <strong>{newMCC?.label}</strong>?{" "}
              </>
            ) : (
              <>Are you sure you want to select {newMCC?.label} as your MCC </>
            )}
          </>
        }
        title="Change MCC"
      />
    </PermissionTooltip>
  );
}

export default MCCInput;
