type Props = { fill: string; size?: number };

const ChartBarIcon = ({ fill, size = 20 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4 7.19995C22.903 7.19995 22.5 7.60289 22.5 8.09995V27.8999C22.5 28.397 22.903 28.7999 23.4 28.7999C23.8971 28.7999 24.3 28.397 24.3 27.8999V8.09995C24.3 7.60289 23.8971 7.19995 23.4 7.19995ZM11.7 15.66C11.7 15.1629 12.103 14.76 12.6 14.76C13.0971 14.76 13.5 15.1629 13.5 15.66V27.9C13.5 28.397 13.0971 28.7999 12.6 28.7999C12.103 28.7999 11.7 28.397 11.7 27.9V15.66ZM6.30005 23.2199C6.30005 22.7228 6.70299 22.3199 7.20005 22.3199C7.69711 22.3199 8.10005 22.7228 8.10005 23.2199V27.8999C8.10005 28.3969 7.69711 28.7999 7.20005 28.7999C6.70299 28.7999 6.30005 28.3969 6.30005 27.8999V23.2199ZM18 20.16C17.503 20.16 17.1 20.5629 17.1 21.06V27.9C17.1 28.397 17.503 28.7999 18 28.7999C18.4971 28.7999 18.9 28.397 18.9 27.9V21.06C18.9 20.5629 18.4971 20.16 18 20.16ZM27.9 17.8199C27.9 17.3229 28.303 16.9199 28.8 16.9199C29.2971 16.9199 29.7 17.3229 29.7 17.8199V27.8999C29.7 28.3969 29.2971 28.7999 28.8 28.7999C28.303 28.7999 27.9 28.3969 27.9 27.8999V17.8199Z"
        fill={fill || "#2860B2"}
      />
    </svg>
  );
};

export default ChartBarIcon;
