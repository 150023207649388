// import React from "react";
import { palette } from "@palette";
import { useLocation, useNavigate } from "react-router-dom";
// mui
import { Box, Stack } from "@mui/material";
// components
import { Text } from "@common/Text";
import { showMessage } from "@common/Toast/ShowToast";
// redux
import { useAppDispatch } from "@redux/hooks";
import { setMasqueradeMode } from "@redux/slices/app";
// hooks
import { useMasquerade } from "@hooks/enterprise-api/merchants";

import { LogoutIconV3 } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { checkPortals } from "@utils/routing";
import GiveButton from "@shared/Button/GiveButton";
import { MerchantInfoIcon } from "@assets/Merchanticons";
import { useQueryClient } from "react-query";
import { useUser } from "@hooks/common/useUser";
import { formTypeToUrlMap } from "sections/PayBuilder/utils";
import { FormType } from "sections/PayBuilder/components/hooks/useCheckFormType";

export const MASQUERADE_SNACKBAR_HEIGHT = 36;

const MasqueradeSnackbar = ({ show = false, formId = "", formType = "" }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const { isMerchant } = useUser();

  const { deleteMasqueradeMutation } = useMasquerade();

  const {
    isMasqueradeMode,
    origin,
    name: merchantName,
  } = useMasqueradeReducer();
  const { isMerchantPortal, isEnterprisePortal } = checkPortals();

  const exitHandler = async () => {
    try {
      const historyControlMode: any[] | undefined =
        await deleteMasqueradeMutation.mutateAsync({});

      if (!historyControlMode?.[0]) return;
      const origin = historyControlMode[0].origin;
      dispatch(
        setMasqueradeMode({
          id: "",
          name: "",
          origin,
          imgSrc: "",
          masqueradeUserRole: historyControlMode[0].role,
          historyControlMode: [],
        }),
      );
      navigate(origin);
    } catch (error: any) {
      const message = error.response?.data?.message;
      const errorCode = error.response?.data?.code;

      showMessage("Error", `${message}`);
      if (
        (errorCode === "resource_not_found" &&
          message === "Masquerade not in session.") ||
        (errorCode === "not_authorized" && message === "access not allowed")
      ) {
        dispatch(
          setMasqueradeMode({
            id: "",
            name: "",
            origin,
            imgSrc: "",
            masqueradeUserRole: "",
            historyControlMode: [],
          }),
        );
      }
    }
  };

  const conditionToShowBackButton =
    show && formId !== "" && (isMasqueradeMode || isMerchant);

  const handleClick = () => {
    navigate(`/merchant/${formTypeToUrlMap[formType as FormType]}`);
    queryClient.removeQueries("public-form-id");
  };

  return (
    <>
      {(isMasqueradeMode || conditionToShowBackButton) &&
        (isMerchantPortal || isEnterprisePortal || state?.preview || show) && (
          <Box sx={containerStyle} gap={5}>
            <Box sx={{ flex: 1, display: { xs: "none", sm: "block" } }}>
              <Box id="masquerade-portal" sx={{ marginLeft: "72px" }}></Box>
            </Box>

            {conditionToShowBackButton && (
              <GiveButton
                sx={{
                  padding: "4px 28px !important",
                  whiteSpace: "nowrap",
                }}
                size="large"
                startIcon={<MerchantInfoIcon stroke={palette.neutral.white} />}
                onClick={handleClick}
                variant="filled"
                label="Back to portal"
              />
            )}
            {isMasqueradeMode ? (
              <Stack
                direction="row"
                gap={{ xs: 1, sm: 3 }}
                alignItems="center"
                width="100%"
                sx={{
                  margin: "0 auto",
                  paddingX: { xs: 2.5, sm: 0 },
                  justifyContent: { xs: "space-between", sm: "center" },
                }}
              >
                <Text sx={textStyle}>
                  You are in control mode of <span>{merchantName}</span>
                </Text>

                <StyledIconButton
                  onClick={exitHandler}
                  disabled={deleteMasqueradeMutation.isLoading}
                  startIcon={
                    <LogoutIconV3
                      height={20}
                      width={20}
                      fill={palette.neutral["70"]}
                    />
                  }
                >
                  <Text mt="1px" color={palette.neutral["70"]}>
                    Exit
                  </Text>
                </StyledIconButton>
              </Stack>
            ) : (
              <Stack width="100%" />
            )}
          </Box>
        )}
    </>
  );
};

const containerStyle = {
  position: "fixed",
  left: "50%",
  height: `${MASQUERADE_SNACKBAR_HEIGHT}px`,
  transform: "translate(-50%, 0)",
  backgroundColor: palette.common.white,
  top: 0,
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  width: "100%",
  "&::after": {
    content: '""',
    flex: 1,
  },
};

const textStyle = {
  fontSize: "14px",
  color: palette.neutral["80"],
  lineHeight: "21.6px",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",

  "& span": {
    fontWeight: "600",
  },

  "@media (max-width: 600px)": {
    fontSize: 16,
    lineHeight: "22.4px",

    "& span": {
      fontWeight: "600",
    },
  },
};

export default MasqueradeSnackbar;
