export const FeatureFlagKeys = {
  ADB_FEATURE_FLAG_KEY: "both_advanced_builder",
  CHANGELOG_FEATURE_FLAG_KEY: "both_changelog",
  RISK_MONITOR_TRANSACTIONS_FLAG: "fe_risk_monitor_transactions",
  TRANSFERS_PAGE_FLAG_KEY: "fe_transfers_page",
  DISPUTES_PAGE_FLAG_KEY: "both_disputes",
  TRANSFERS_STATS_FLAG_KEY: "fe_transfers_page_stats",
  VIEW_ACH_FILE_FLAG_KEY: "fe_transfers_page_view_ach_file",
  CONVERSATION_ACTIVITY_FLAG_KEY: "fe_conversation_activity",
  NEW_MCC_SELECTION_IMPLEMENTATION_FLAG_KEY:
    "fe_temp_mcc_selection_with_virtualized_list",
  FILE_UPLOAD_TECH_REFACTOR_KEY: "fe_temp_file_upload_refactor",
  NEW_COLOR_PICKER_IMPLEMENTATION_FLAG_KEY:
    "fe_temp_color_picker_new_implementation",
  NEW_OFAC_TABS_FLAG_KEY: "both_new_ofac",
  SPONSOR_APPROVAL_FLAG_KEY: "fe_sponsor_approval",
  BULK_SPONSOR_APPROVAL_FLAG_KEY: "both_bulk_sponsor_approval",
  HIDE_AVAILABLE_BALANCE_FLAG_KEY: "fe_hide_available_balance",
  HIDE_MONTHLY_REPORT: "both_processing_monthly_report",
  DISPUTE_SIDE_PANEL: "fe_disputes_side_panel",
  SETTLEMENT_EXPORT: "fe_settlement_export",
  MERCHANT_INVITES: "fe_hide_mmm001",
  LOGIN_OTP_ENABLED_FLAG: "fe_login_with_otp_enabled",
  ONBOARDING_BUSINESS_DETAILS: "fe_new_onboarding_business_details",
  RECAPTCHA: "both_recaptcha_enabled",
  REBRANDING: "fe_rebranding",
  REBRANDING_SIDEPANEL: "fe_rebranding_sidepanel",
  REBRANDING_TABLE: "fe_rebranding_table",
  PAY_BUILDER: "both_new_payment_form",
  MCC_IMPROVEMENT: "fe_mcc_improvements",
  ONBOARDING_VIEW: "both_onboarding_view",
  TABLES_OPTIMIZATIONS: "fe_tables_optimizations_enabled",
};
