import { Stack } from "@mui/system";
import Portal from "@mui/material/Portal";
import GiveButton from "@shared/Button/GiveButton";
import React from "react";
import GiveText from "@shared/Text/GiveText";
import ReportStatusSelector, { MatchStatusEnum } from "./ReportStatusSelector";
import { FormProvider, useForm } from "react-hook-form";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import { styled } from "@theme/v2/Provider";
import { useModal } from "@ebay/nice-modal-react";
import ReportEvidenceUpload from "./ReportEvidenceUpload";
import SubmitButton from "./SubmitButton";
import { ContentViewTypes } from "../MatchReportModal";

type FilesFieldType = {
  file: File;
  id: string;
  updatedAt: number;
  fileName: string;
  fileURL: string;
  fileType: string;
};

export type ReportFormFields = {
  findings: string;
  files: FilesFieldType[];
  status: MatchStatusEnum;
};

const REPORT_FORM_DEFAULT_VALUES = {
  findings: "",
  files: [],
  status: MatchStatusEnum.Clear,
};

interface IComponentProps {
  merchantID: number;
  handleChangeView: (value: ContentViewTypes) => void;
}

const AddNewReportView = ({
  merchantID,
  handleChangeView,
}: IComponentProps) => {
  const methods = useForm<ReportFormFields>({
    defaultValues: REPORT_FORM_DEFAULT_VALUES,
  });
  const { remove } = useModal();

  const changeToBaseView = () => handleChangeView(ContentViewTypes.MATCH);

  return (
    <Stack gap={2.5}>
      <GiveText variant="bodyS" color="secondary">
        Please review each field thoroughly to ensure all information is
        accurate and current before submitting.
      </GiveText>
      <FormProvider {...methods}>
        <StyledStack>
          <ReportStatusSelector />
          <Stack gap={1}>
            <GiveText variant="bodyS">Findings</GiveText>
            <HFGiveInput
              name="findings"
              multiline
              rows={11}
              placeholder="Enter your findings..."
            />
          </Stack>
          <ReportEvidenceUpload />
        </StyledStack>
        <Portal
          container={() => document.getElementById("match-report-modal-footer")}
        >
          <GiveButton
            label="Cancel"
            size="large"
            variant="ghost"
            onClick={remove}
          />
          <SubmitButton
            merchantID={merchantID}
            onSubmitSuccess={changeToBaseView}
          />
        </Portal>
      </FormProvider>
    </Stack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border?.primary}`,
  paddingBlock: theme.spacing(2.5),
  gap: theme.spacing(2.5),
}));


export default AddNewReportView;
