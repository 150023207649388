import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { ReactNode } from "react";
import EmbedModal from "./EmbedForm/EmbedModal";

export const ThreadsShareButton = ({
  url,
  children,
}: {
  url: string;
  children: ReactNode;
}) => {
  const onClick = () => {
    window.open(
      `https://threads.net/intent/post?text=${url}`,
      "_blank",
      "noopener",
    );
  };
  return <Box onClick={onClick}>{children}</Box>;
};

export const EmbedShareButton = ({
  url,
  children,
  productId,
  handleClose,
}: {
  url: string;
  children: ReactNode;
  productId: string;
  handleClose?: () => void;
}) => {
  const onClick = () => {
    // onClick
    NiceModal.show(EmbedModal, {
      open: true,
      productId: productId,
      handleClose: handleClose,
    });
  };
  return <Box onClick={onClick}>{children}</Box>;
};
