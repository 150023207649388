import NiceModal from "@ebay/nice-modal-react";
import {
  ADD_TEAM_MEMBER_MODAL,
  CONNECTION_ERROR_MODAL,
  BULK_IMPORT_MODAL,
  DOWNLOAD_REPORT_MODAL_MOBILE,
  EXPORT_VIEW_PANEL,
  FILE_PREVIEW_MODAL,
  MERCHANT_BULK_INVITE,
  MISSING_PERMISSION_POP_UP,
  TRANSACTION_INFO_MODAL,
  RESOURCE_NOT_FOUND,
  EDIT_INVITE_MODAL,
  SEND_INVITATIONS_MODAL,
  ADD_INVITE_MODAL,
  REDIRECT_EXTERNAL_WEBSITE_MODAL,
  SAVE_MINI_BUILDER_DRAFT,
  FILE_PREVIEW_MODAL_V2,
} from "./modal_names";
import { TransactionModal } from "@components/ManageMoney/TransactionTable/TransactionInfoModal";
import { MissingPermissionPopUp } from "features/Permissions/PopUp";
import ConnectionErrorModal from "@common/EmptyState/ConnectionErrorModal";
import { DownloadReportActionModalMobile } from "@common/BottomMobileDrawer/BottomConfirmationMobile";
import FilePreviewModal from "@common/FilePreview/FilePreview";
import AddMemberModal from "@components/Settings/ManageTeam/modal/AddMemberModal";
import ExportViewPanel from "@common/ExportView/ExportViewPanel";
import ResourceNotFoundPopUp from "@components/ManageMoney/TransactionTable/ResourceNotFoundPopUp";
import BulkInvitePanel from "@components/Merchants/BulkInvite/BulkInvitePanel";
import BulkImportModal from "@components/Merchants/BulkInvite/BulkImportModal/BulkImportModal";
import EditInviteModal from "@components/Merchants/BulkInvite/modals/EditInviteModal";
import SendInvitationsModal from "@components/Merchants/BulkInvite/modals/SendInvitationsModal";
import AddInviteModal from "@components/Merchants/BulkInvite/modals/AddInviteModal";
import { AlertLinkPopUp } from "@common/LinkModalAlert/LinkModalAlert";
import { SaveDraftModal } from "@sections/PayBuilder/components/SaveDraftModal";
import GiveFilePreviewModal from "@shared/FilePreview/GiveFilePreviewModal";

NiceModal.register(TRANSACTION_INFO_MODAL, TransactionModal);
NiceModal.register(MISSING_PERMISSION_POP_UP, MissingPermissionPopUp);
NiceModal.register(CONNECTION_ERROR_MODAL, ConnectionErrorModal);
NiceModal.register(
  DOWNLOAD_REPORT_MODAL_MOBILE,
  DownloadReportActionModalMobile,
);
NiceModal.register(FILE_PREVIEW_MODAL, FilePreviewModal);
NiceModal.register(FILE_PREVIEW_MODAL_V2, GiveFilePreviewModal);
NiceModal.register(ADD_TEAM_MEMBER_MODAL, AddMemberModal);
NiceModal.register(EXPORT_VIEW_PANEL, ExportViewPanel);

NiceModal.register(RESOURCE_NOT_FOUND, ResourceNotFoundPopUp);
NiceModal.register(MERCHANT_BULK_INVITE, BulkInvitePanel);
NiceModal.register(BULK_IMPORT_MODAL, BulkImportModal);
NiceModal.register(EDIT_INVITE_MODAL, EditInviteModal);
NiceModal.register(SEND_INVITATIONS_MODAL, SendInvitationsModal);
NiceModal.register(ADD_INVITE_MODAL, AddInviteModal);
NiceModal.register(REDIRECT_EXTERNAL_WEBSITE_MODAL, AlertLinkPopUp);
NiceModal.register(SAVE_MINI_BUILDER_DRAFT, SaveDraftModal);
