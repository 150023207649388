import { createContext, useContext } from "react";
import { TFormStatus } from "@sections/PayBuilder/components/products/types";
import usePayBuilder from "@sections/PayBuilder/components/hooks/usePayBuilder";

type PayBuilderContextTypes = ReturnType<typeof usePayBuilder> & {
  displayStatus?: TFormStatus;
};

export const PayContext = createContext<PayBuilderContextTypes>({} as any);
export function PayBuilderContext({
  children,
  isLaunchVisible,
  displayStatus,
}: {
  children: React.ReactNode;
  isLaunchVisible?: boolean;
  displayStatus?: TFormStatus;
}) {
  const values = usePayBuilder({ isLaunchVisible });
  return (
    <PayContext.Provider value={{ ...values, displayStatus }}>
      {children}
    </PayContext.Provider>
  );
}

export const usePayBuilderContext = () => useContext(PayContext);
