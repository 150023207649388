import { QKEY_LIST_CART } from "@constants/queryKeys";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";

// POST /cart/items => add an item to the cart
// DELETE /cart/items/:item_id => remove an item from the cart
// DELETE /cart/items => remove all items from the cart  (clear the cart)
// PATCH /cart/items/:item_id => update an item in the cart
// GET /cart => read cart content (list all items in the cart)

// list cart items
export function useCartItems(enabled: boolean, onSuccess?: any) {
  const data = useQuery(
    [QKEY_LIST_CART],
    async () => {
      const data = await customInstance({
        url: `/cart`,
        method: "GET",
      });

      return {
        ...data,
        items: data?.items?.map((item: any) => ({
          ...item,
          productVariantPrice: item.productVariantPrice / 100,
          unitPrice: item.unitPrice / 100,
        })),
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      onSuccess,
    },
  );

  return { ...data };
}
