import DotIcon from "@assets/icons/DotIcon";
import { Stack } from "@mui/material";
import { LinkSimple } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import { useState } from "react";
import PublicUrlShare from "./LaunchStep/PublicURLShare/PublicURLShare";
import TextWithIcon from "./TextWithIcon";
import { TFormSourceEnum } from "./components/products/types";
import { usePayBuilderContext } from "./provider/PayBuilderContext";

const IS_UNPUBLISHED = true; // TODO: to change when API for Publish is integrated

const RightSideHeaderPart = ({
  renderViewActions,
  shouldRenderShareButton,
  source = TFormSourceEnum.PREVIEW,
}: {
  renderViewActions: () => JSX.Element[];
  shouldRenderShareButton: boolean;
  source?: TFormSourceEnum;
}) => {
  const { palette } = useAppTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isMobileView } = useCustomThemeV2();
  const { displayStatus } = usePayBuilderContext();

  return (
    <>
      <Stack gap="14px" flexDirection="row" alignItems="center">
        <TextWithIcon
          Icon={<DotIcon isPublished={!IS_UNPUBLISHED} />}
          text={IS_UNPUBLISHED ? "unpublished" : "published"}
          textSx={{
            color: palette.primitive?.neutral[60],
          }}
        />
        {source !== TFormSourceEnum.PUBLIC && renderViewActions()}
        {(shouldRenderShareButton || source === TFormSourceEnum.PUBLIC) && (
          <GiveButton
            label="Share"
            sx={{
              width: "fit-content",
              borderRadius: "8px",
              backgroundColor: palette.primitive?.transparent["darken-10"],
              border: "none",
              color: palette.primitive?.neutral[90],
              "&:hover": {
                color: palette.primitive?.neutral[0],
              },
            }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={<LinkSimple stroke={palette.primitive?.neutral[90]} />}
          />
        )}
      </Stack>
      <PublicUrlShare
        variant="strict"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default RightSideHeaderPart;
