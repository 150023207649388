import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import PublicUrlShare from "./PublicURLShare";

const PublicURLShareModal = NiceModal.create(
  ({ idFromProps }: { idFromProps?: string }) => {
    const { isMobileView } = useCustomThemeV2();
    const { onClose, SlideProps, TransitionProps, open } = useNiceModal();
    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose,
          DrawerProps: {
            SlideProps,
            PaperProps: {
              sx: {
                "&.MuiPaper-root": {
                  padding: "unset",
                },
              },
            },
          },
          DialogProps: {
            TransitionProps,
            width: "560px",
            PaperProps: {
              "& .MuiPaper-root": {},
            },
            scroll: "paper",
          },
        }}
      >
        <ModalTitle
          title="Share Product"
          padding="16px"
          onClose={onClose}
          textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
          showDivider
        />
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            padding:  "0px 16px 16px",
          }}
        >
          <PublicUrlShare
            variant="styless"
            handleClose={onClose}
            idFromProps={idFromProps}
          />
        </Box>
      </ModalFactory>
    );
  },
);

export default PublicURLShareModal;
