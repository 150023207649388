import { Stack } from "@mui/material";
import GiveUnderwritingProgressBar from "@shared/ProgressBar/GiveUnderwritingProgressBar";
import GiveText from "@shared/Text/GiveText";
import { ModifiedGiveChip } from "./UnderwritingCard.style";
import { IChipProps } from "@shared/Chip/GiveChip";
import { useEffect, useMemo } from "react";
import { useGetChallengesStats } from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/hooks/useGetChallengesStats";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";

type TUnderwritingCardHeader = {
  underwritingScorePercentage: number | string;
  status: IChipProps;
  merchantId: number;
};

const UnderwritingCardHeader = ({
  underwritingScorePercentage,
  status,
  merchantId,
}: TUnderwritingCardHeader) => {
  const { label, color } = status || {};
  const {
    setIsLoadingUnderwriting,
    isLoadingUnderwriting,
    isUnderwritingView,
    isOnboardingView,
    data,
  } = useMerchantSidePanelContext();

  const { data: challengesStatsData, isLoading } = useGetChallengesStats({
    merchantId,
  });

  //set underwriting load to false if we don't have to wait for category fetch
  useEffect(() => {
    if (
      !isLoading &&
      isLoadingUnderwriting &&
      !isUnderwritingView &&
      !isOnboardingView
    )
      setIsLoadingUnderwriting(false);
  }, [isLoading, isLoadingUnderwriting]);

  const { completed, incomplete, disabled } = useMemo(
    () => ({
      completed:
        (isOnboardingView
          ? challengesStatsData?.closedMerchantOnboardingChallengesCount
          : challengesStatsData?.closedChallengesCount) || 0,
      incomplete:
        (isOnboardingView
          ? 0
          : challengesStatsData?.readyForVerificationChallengesCount) || 0,
      disabled:
        (isOnboardingView
          ? challengesStatsData?.merchantOnboardingChallengesCount -
            challengesStatsData?.closedMerchantOnboardingChallengesCount
          : challengesStatsData?.openChallengesCount) || 0,
    }),
    [challengesStatsData, isOnboardingView],
  );

  const hiddenStatus = data?.status?.sponsorStatusName === "ready_for_review";

  return (
    <Stack gap="12px">
      <Stack direction="row" justifyContent="space-between">
        <GiveText
          sx={{
            fontSize: "24px",
            fontWeight: 350,
            lineHeight: "28px",
          }}
        >
          {underwritingScorePercentage}%
        </GiveText>
        {!hiddenStatus && (
          <ModifiedGiveChip label={label} color={color} variant="light" />
        )}
      </Stack>
      <GiveUnderwritingProgressBar
        completed={completed}
        incomplete={incomplete}
        disabled={disabled}
      />
    </Stack>
  );
};

export default UnderwritingCardHeader;
