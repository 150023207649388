import {
  Bank,
  Briefcase,
  FileSearch,
  ListChecks,
  User,
} from "@phosphor-icons/react";
import { ReactNode } from "react";

export const iconMapper = (name: string, size = 20) => {
  const obj: Record<string, ReactNode> = {
    merchant_onboarding: <ListChecks size={size} />,
    primary_account_holder: <User size={size} />,
    business_profile: <Briefcase size={size} />,
    bank_account: <Bank size={size} />,
    enhanced_due_diligence: <FileSearch size={size} />,
  };

  return obj[name];
};

export const SyncApprovedNotification = {
  primary_account_holder: "The PAH was already approved for another entity",
  bank_account: "The Bank Account was already approved for another entity",
  business_profile:
    "The Business Profile was already approved for another entity",
};

export const AUTOCOMPLETE_CHALLENGES = [
  "bank_account",
  "primary_account_holder",
  "business_profile",
];

export const AUTOCOMPLETE_SLUG_MAP = {
  business_profile1: "business_profile",
  business_owner1: "business_profile",
  business_owner2: "business_profile",
  business_owner3: "business_profile",
  business_owner4: "business_profile",
  bank_account1: "business_profile",
  bank_account3: "business_profile",
  primary1: "primary_account_holder",
  primary2: "primary_account_holder",
  primary3: "primary_account_holder",
  merchant_info3: "business_profile",
};
