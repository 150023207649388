import { QKEY_LIST_PAYMENT_FORMS } from "@constants/queryKeys";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useCachedList } from "@hooks/common/useCachedList";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { useStateEffect } from "@hooks/customReactCore";
import { QFORM_QUERY_KEY } from "@pages/AcquirerPortal/Enterprises/Modal/constants";
import { useAppSelector } from "@redux/hooks";
import {
  selectQueryFilters,
  sorting as sortingReducer,
} from "@redux/slices/fundraisers";
import { selectQueryString } from "@redux/slices/search";
import useGetCheckoutForms from "@sections/PayBuilder/Checkout/hooks/useGetCheckout";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import { customInstance } from "@services/api";
import { getPaymentFormVariants } from "@services/api/products/invoice";
import { useQueryFactory } from "@services/api/products/queryFactory";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { encodedQueryFilterMap } from "@services/filtering";
import { detectMobile } from "@utils/index";
import { checkPortals } from "@utils/routing";
import { useMemo, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";

export const useGetPaymentForms = useQueryFactory("payment-form");

const useListPaymentForms = () => {
  const queryFilters = useAppSelector(selectQueryFilters);
  const sorting = useAppSelector(sortingReducer);
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_PAYMENT_FORMS),
  );

  const { allData, invalidateCache } = useCachedList(QKEY_LIST_PAYMENT_FORMS);

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  const loadingRef = useRef<boolean>(false);

  const { page, setPage } = usePagination(0, queryFilter.products);
  const queryString = queryFilter.products ? `%3B${queryFilter.products}` : "";

  const { data, isError, isLoading, error } = useGetPaymentForms(
    {
      queryString,
      page,
      sorting,
      searchQuery,
    },
    {
      refetchOnWindowFocus: false,

      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useStateEffect(() => {
    if (detectMobile()) invalidateCache();
    setPage(1);
  }, [sorting, searchQuery, queryString]);

  const usedData = detectMobile() ? allData : data?.data ?? [];

  return {
    isError,
    page,
    rowsPerPage: ROWS_PER_PAGE,
    currentPageRows: usedData,
    handlePageChange,
    totalRows: data?.total ?? 0,
    setPage: () => setPage((current) => current + 1),
    setPageDispatcher: setPage,
    allRows: usedData,
    loadingRef,
    isLoading,
    error,
    state: {
      isEmpty: !queryString && !searchQuery && data?.total === 0,
      isError,
    },
  };
};

export const getPaymentFormsById = (
  id: string,
  options?: { shouldWrap: boolean },
) => {
  const { shouldWrap = false } = options || {};
  const url = shouldWrap
    ? buildMerchantEndpoints(`products/${id}?filter=typeName:"standard"`)
    : `products/${id}?filter=typeName:"standard"`;

  return customInstance({
    url, // Dynamically constructed URL
    method: "GET", // HTTP method
  });
};

export const useGetPaymentFormById = (id: string) => {
  const { isPayBuilder, isMerchantPortal } = checkPortals();

  const queryClient = useQueryClient();
  return useQuery(
    ["get-payment-form-by-id", id],
    async () => {
      const data = await getPaymentFormsById(id, {
        shouldWrap: isPayBuilder || isMerchantPortal,
      });
      return data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
      onSuccess: (data) => {
        queryClient.setQueryData(QFORM_QUERY_KEY, { product: data });
      },
    },
  );
};

export const useGetPaymentFormVariants = (id: string) => {
  const { isMerchantPortal, isPayBuilder } = checkPortals();

  return useQuery({
    queryKey: ["get-payment-form-variants", id],
    queryFn: async () => {
      const data = await getPaymentFormVariants(id, {
        shouldWrap: isPayBuilder || isMerchantPortal,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useGetPaymentFormInfos = (id: string) => {
  const { merchantId } = useGetCurrentMerchantId();
  const paymentFormResponse = useGetPaymentFormById(id);
  const paymentFormVariantsResponse = useGetPaymentFormVariants(id);
  // disable request for non-signed in users since we will need to read from paymentFormResponse
  const paymentFormCheckoutResponse = useGetCheckoutForms(merchantId ? id : "");

  // If user is not logged in we should read data from paymentFormResponse, otherwise from paymentFormCheckoutResponse
  const checkoutData = paymentFormCheckoutResponse?.data
    ? paymentFormCheckoutResponse?.data
    : paymentFormResponse?.data?.uiCheckoutForm;

  return {
    data: {
      ...paymentFormResponse.data,
      variants: paymentFormVariantsResponse.data?.data
        ? ([...(paymentFormVariantsResponse.data?.data as any)] as any)
        : ([] as any),
      checkout: checkoutData ? checkoutData : {},
    },
    isFetched:
      paymentFormResponse?.isFetched &&
      paymentFormVariantsResponse?.isFetched &&
      (merchantId ? paymentFormCheckoutResponse?.isFetched : true),
    isLoading:
      paymentFormResponse?.isLoading ||
      paymentFormResponse?.isLoading ||
      paymentFormCheckoutResponse?.isLoading,
  };
};

export default useListPaymentForms;
