import useCheckFormType from "../components/hooks/useCheckFormType";
import FundraiserCheckout from "../View/fundraiser/FundraiserCheckout";
import ProductCheckout from "./ProductCheckout";

function CheckoutSelector({
  isMobileView,
  isDisabledFields = false,
}: {
  isMobileView: boolean;
  isDisabledFields?: boolean;
}) {
  const { isFundraiser } = useCheckFormType();

  if (isFundraiser) {
    return <FundraiserCheckout />;
  }

  return (
    <ProductCheckout
      isMobileView={isMobileView}
      isDisabledFields={isDisabledFields}
    />
  );
}

export default CheckoutSelector;
