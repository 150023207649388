import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { isEmpty, isEqual } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { generateStepsArray, isEventNextDisabled } from "./utils";
import useCheckFormType, { FormType } from "../useCheckFormType";

type TStepsArray = Array<{
  label: string;
  id: string;
}>;
function findIdx(stepsArray: TStepsArray, lastStepCompleted: any) {
  const item = stepsArray.find((x) => x.id === lastStepCompleted);
  const index = stepsArray.findIndex((element) => element.id === item?.id);

  return index;
}

export default function usePayBuilder({
  isLaunchVisible,
}: {
  isLaunchVisible?: boolean;
}) {
  const { formType } = useCheckFormType();

  const {
    steps: stepsArray,
    formStep,
    previewStep,
  } = generateStepsArray(isLaunchVisible, formType);
  const screenSizes = useCustomThemeV2();
  const [lastStepCompleted, setLastStepCompleted] =
    useState("product_creation");

  const [activeStepIndex, setActiveStepIndex] = useState(() => {
    return Math.max(findIdx(stepsArray, lastStepCompleted), 0);
  });

  const screenSizesRef = useRef(screenSizes);

  const [screenStates, setScreenStates] = useState(screenSizes);

  useEffect(() => {
    if (isEqual(screenSizes, screenSizesRef.current)) return;
    screenSizesRef.current = screenSizes;
    setScreenStates(screenSizes);
  }, [screenSizes]);

  const isCompleted = useMemo(
    () => findIdx(stepsArray, lastStepCompleted),
    [lastStepCompleted],
  );

  useEffect(() => {
    goToStep({ index: isCompleted });
  }, [lastStepCompleted]);

  useEffect(() => {
    return () => {
      setLastStepCompleted("product_creation");
    };
  }, []);

  const goToStep = ({
    index,
    offSet,
    free,
  }: {
    index?: number;
    offSet?: 1 | -1;
    free?: boolean;
  }) => {
    setActiveStepIndex((current) => {
      //yuo cannot jump between step if the about step is not completed by adding a heading
      //isCompleted represents the index of the last step that was completed
      //a step is considered completed if the user pressed Next button
      //if the user jumps back to an already completed step and do changes, and press next, do not set it as last step completed.
      if (index !== undefined && free) return index;
      if (index !== undefined && index < isCompleted) return index;
      if (offSet !== undefined) {
        if (offSet === 1 && activeStepIndex >= isCompleted) {
          setLastStepCompleted(stepsArray[current + offSet]?.id);
        }
        return current + offSet;
      }

      return current;
    });
  };

  const manageScreenActions = {
    setDeskTopView: () =>
      setScreenStates({
        isMobileView: false,
        isTabletView: false,
        isDesktopView: true,
        isMobileDevice: false,
        isSmallDesktop: true,
        isNarrowView: false,
        isWideView: false,
      }),
    setTabletView: () =>
      setScreenStates({
        isMobileView: false,
        isTabletView: true,
        isDesktopView: false,
        isMobileDevice: false,
        isSmallDesktop: false,
        isNarrowView: false,
        isWideView: false,
      }),
    setMobileView: () =>
      setScreenStates({
        isMobileView: true,
        isTabletView: false,
        isDesktopView: false,
        isMobileDevice: true,
        isSmallDesktop: false,
        isNarrowView: false,
        isWideView: false,
      }),
  };

  const isNextDisabled = ({
    values,
    errors,
    isValid,
    isLoading,
  }: {
    values: any;
    errors: any;
    isValid: boolean;
    isLoading: boolean;
  }) => {
    if (formType === FormType.EVENTS) {
      if (activeStepIndex === 0) {
        return !values.About?.heading || !!errors.About?.heading;
      } else if (activeStepIndex === 1) {
        return isEventNextDisabled({ values, errors });
      }
    } else if (formType === FormType.FUNDRAISERS) {
      return !isValid || isLoading || !isEmpty(errors);
    }
    return isLoading || !isValid;
  };

  return {
    formStep,
    previewStep: previewStep[activeStepIndex],
    stepsArray,
    activeStepIndex,
    activeItem: stepsArray[activeStepIndex],
    goToStep,
    isLastStep: activeStepIndex === stepsArray.length - 1,
    lastStepCompletedIndex: isCompleted,
    screenStates,
    manageScreenActions,
    setLastStepCompleted: (index: number) => {
      setLastStepCompleted(stepsArray[index]?.id);
    },
    lastStepCompleted,
    isNextDisabled,
  };
}
