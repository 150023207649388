import { Stack } from "@mui/material";
import { CheckCircle } from "@phosphor-icons/react";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import { ICartItem, useCart } from "@sections/PayBuilder/provider/CartContext";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { parseAmount } from "@utils/index";
import { format } from "date-fns";

function getUpcomingCharge(items: ICartItem[]): number {
  let filteredItems: ICartItem[] = [];

  /**
   * In order to calculate the upcoming charge, we need to find the closest payment occurance and calculate
   * sum of the items' with respective occurance.
   */
  if (items.some((item) => item.recurringIntervalName === "monthly")) {
    filteredItems = items.filter(
      (item) => item.recurringIntervalName === "monthly",
    );
  } else if (items.some((item) => item.recurringIntervalName === "quarterly")) {
    filteredItems = items.filter(
      (item) => item.recurringIntervalName === "quarterly",
    );
  } else if (items.some((item) => item.recurringIntervalName === "yearly")) {
    filteredItems = items.filter(
      (item) => item.recurringIntervalName === "yearly",
    );
  }

  // Calculate total upcoming charge
  return filteredItems.reduce(
    (total, item) => total + Number(item.productVariantPrice) * item.quantity,
    0,
  );
}
const CartTotalSection = ({
  cartItems,
  fees,
}: {
  cartItems: ICartItem[];
  fees: null | number;
}) => {
  const { subTotal, totalAmount, displayFees } = useCart();
  const hasUpocomingCharge = cartItems.some(
    (item) => item.recurringIntervalName !== "once",
  );
  const { palette } = useAppTheme();

  const upcomingCharge = [
    `Upcoming charge $${getUpcomingCharge(cartItems)}`,
    `Paid subscription starts on ${format(new Date(), "MM/dd/yyyy")}`,
    `Cancel anytime`,
  ];

  const { isFundraiser } = useCheckFormType();
  return (
    <Stack
      spacing={2}
      mt={2}
      sx={{
        borderRadius: "12px",
        padding: "16px",
        backgroundColor: palette.primitive?.transparent["darken-5"],
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        {isFundraiser ? (
          <GiveText variant="bodyXS" color="primary">
            Donation
          </GiveText>
        ) : (
          <GiveText variant="bodyXS" color="primary">
            Subtotal ({cartItems.length}{" "}
            {cartItems.length === 1 ? "item" : "items"})
          </GiveText>
        )}
        <GiveText variant="bodyXS" color="primary">
          ${subTotal}
        </GiveText>
      </Stack>
      {Boolean(fees) && displayFees && (
        <Stack direction="row" justifyContent="space-between">
          <GiveText variant="bodyXS" color="primary">
            Credit Card Fees
          </GiveText>
          <GiveText variant="bodyXS" color="primary">
            ${parseAmount(fees)}
          </GiveText>
        </Stack>
      )}

      <Stack
        sx={{
          borderTop: `1px solid ${palette.primitive?.transparent["darken-10"]}`,
          paddingTop: "16px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <GiveText variant="bodyL">
            {hasUpocomingCharge ? "Due Today" : "Total"}
          </GiveText>
          <GiveText variant="bodyL">${totalAmount}</GiveText>
        </Stack>
        {hasUpocomingCharge && (
          <Stack mt={2} gap="12px">
            {upcomingCharge.map((item, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <CheckCircle size={18} />
                  <GiveText variant="bodyXS" color="secondary">
                    {item}
                  </GiveText>
                </Stack>
              );
            })}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CartTotalSection;
