import CheckIcon from "@assets/icons/RebrandedIcons/CheckIcon";
import { SearchIcon } from "@assets/rebrandIcons";
import { Input } from "@common/Input";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { Text, TruncateText } from "@common/Text";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { ChangeEvent } from "react";
import { TPermissionsData, TPermissionGroups } from "../../types";

type GroupStackProps = {
  groupName: string;
  isLast: boolean;
  uniqueGroups: TPermissionGroups;
  hashes: TPermissionsData;
  onClick: (key: string, value: TPermissionsData[string]) => void;
};

export const PermissionItem = ({
  permissionName,
  description,
  assigned,
  onClick,
}: {
  permissionName: string;
  description: string;
  assigned: boolean;
  onClick: () => void;
}) => {
  return (
    <PermissionWrapper onClick={onClick}>
      <Stack direction="row" alignItems="center" gap="4px">
        <TruncateText
          lineClamp={1}
          color={palette.black[100]}
          lineHeight="16.8px"
          fontWeight="book"
          flexGrow={1}
        >
          {permissionName}
        </TruncateText>
        <Box width="20px" height="20px">
          {assigned && (
            <CheckIcon width={20} height={20} fill={palette.black[300]} />
          )}
        </Box>
      </Stack>
      <TruncateText
        lineClamp={1}
        color={palette.gray[300]}
        lineHeight="16.8px"
        fontWeight="light"
        minHeight="16.8px"
      >
        {description}
      </TruncateText>
    </PermissionWrapper>
  );
};

const PermissionWrapper = styled(Stack)(() => ({
  padding: "4px 8px",
  flexDirection: "column",
  alignItems: "stretch",
  gap: "2px",
  borderRadius: "4px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

export const PermissionSearchBar = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const reset = () => onChange("");
  return (
    <StyledInput
      fullWidth
      placeholder="Search"
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value)
      }
      startIcon={
        <SearchIcon width={20} height={20} stroke={palette.gray[300]} />
      }
      endIcon={value ? <EndIcon onClick={reset}>Clear</EndIcon> : undefined}
    />
  );
};

const EndIcon = styled(Text)(() => ({
  color: "#6D9CF8",
  fontWeight: 350,
  lineHeight: "16.8px",
  userSelect: "none",
  cursor: "pointer",
}));

const StyledInput = styled(Input)(() => ({
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    border: "none",
    borderBottom: `1px solid ${palette.liftedWhite[100]}`,
    padding: "14px 12px",
    borderRadius: 0,
    background: "#FBFBFB",
  },
}));
