import {
  CalendarIcon,
  FileLightIcon,
  HeartIcon,
  HourGlassIcon,
  LockIcon,
  SheetIcon,
} from "@assets/rebrandIcons";
import { EmptyState } from "@common/EmptyState";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import GradientSpan from "@common/Text/GradientSpan";
import { EventsList, NewEventsBanner } from "@components/Events";
import { FundraisersList, NewFundraisersBanner } from "@components/Fundraisers";
import { InvoicesList, NewInvoicesBanner } from "@components/Invoices";
import { PortalRootContainer } from "@components/Layout/Layout";
import { MembershipsList } from "@components/Memberships";
import NewMembershipsBanner from "@components/Memberships/NewMembershipsBanner";
import { ProductListNotAuthorizedWrapper } from "@components/Product/commons/ProductListNotAuthorizedWrapper";
import { ProductScreenNotAuthorizedWrapper } from "@components/Product/commons/ProductScreenNotAuthorizedWrapper";
import { SweepstakesList } from "@components/Sweepstakes";
import NewSweepstakesBanner from "@components/Sweepstakes/NewSweepstakesBanner";
import { Ticker } from "@components/Ticker";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import useEvents from "@hooks/merchant-api/events/useListEvents";
import useFundraisers from "@hooks/merchant-api/fundraisers/useListFundraisers";
import useInvoices from "@hooks/merchant-api/invoices/useListInvoices";
import useMembership from "@hooks/merchant-api/memberships/useListMembership";
import useSweepstakes from "@hooks/merchant-api/sweepstakes/useListSweepstakes";
import useListPaymentForms from "@hooks/payment-forms/useListPaymentForms";
import { Box, Skeleton } from "@mui/material";
import NewPaymentFormsBanner from "@pages/PaymentForms/PaymentFormsBanner";
import PaymentFormsList from "@pages/PaymentForms/PaymentFormsList";
import ProductBanner from "@pages/Products/ProductBanner";
import useProducts from "@pages/Products/useProducts";
import { palette } from "@palette";
import { usePaymentFormNavigator } from "@sections/PayBuilder/utils";
import { buildCompaignAndBannerVideo } from "@utils/assets";
import { getRandomNumber } from "@utils/helpers";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";
import {
  CREATE_EVENT_MODAL,
  CREATE_FUNDRAISER_MODAL,
  CREATE_INVOICE_MODAL,
  CREATE_MEMBERSHIP_MODAL,
  CREATE_PAYMENT_FORM_MODAL,
  CREATE_PRE_BUILD_FORM_MODAL,
  CREATE_SWEEPSTAKES_MODAL,
} from "modals/modal_names";
import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

type TCampaignType =
  | "fundraiser"
  | "event"
  | "invoice"
  | "membership"
  | "payment-form"
  | "sweepstake"
  | "standard";

type TObjectValues = {
  emptyStateIcon: JSX.Element;
  Banner: any;
  List: any;
  fetcher: () => any;
  modal: string;
  LoadingSkeleton?: React.ComponentType;
  backgroundSrc?: string;
};

export default function WithEmptyStatesCampaigns({
  type,
}: {
  type: TCampaignType;
}) {
  const {
    Banner,
    List,
    fetcher,
    modal,
    emptyStateIcon,
    backgroundSrc,
    LoadingSkeleton,
  } = components[type];
  const {
    state: { isEmpty, isError },
    ...rest
  } = fetcher();

  const { state } = useLocation();
  const isNewProduct = state?.newProduct || false;
  const { isAddProductAllowed } = useProductPermission();

  const dynamicType = type === "payment-form" ? "Payment Form" : type;

  const paymentBuilderNavigation = usePaymentFormNavigator();

  useEffect(() => {
    if (isNewProduct && type === "payment-form")
      NiceModal.show(CREATE_PRE_BUILD_FORM_MODAL);
  }, [isNewProduct]);

  if (isEmpty) {
    return (
      <EmptyState
        Icon={emptyStateIcon}
        backgroundSrc={backgroundSrc}
        title={{
          main: (
            <>
              Create your first{" "}
              <GradientSpan gradient={palette.gradient[10]}>
                {dynamicType}
              </GradientSpan>
            </>
          ),
          secondary: `Ready to get started? Create your ${dynamicType} now.`,
        }}
        action={{
          label: `Create ${capitalizeFirstLetter(dynamicType)}`,
          onClick: () =>
            dynamicType === "Payment Form"
              ? NiceModal.show(CREATE_PRE_BUILD_FORM_MODAL)
              : NiceModal.show(modal),
          disabled: !isAddProductAllowed,
          tooltipProps: {
            message: ACTION_DENY_MESSAGE,
            show: !isAddProductAllowed,
          },
          sx: {
            pointerEvents: "auto !important",
            "&:hover": { background: `${palette.neutral[30]} !important` },
          },
        }}
        size="large"
      />
    );
  }

  return (
    <ProductScreenNotAuthorizedWrapper type={type}>
      <PortalRootContainer style={{ gap: 0 }}>
        <Ticker />
        <Banner backgroundUrl={backgroundSrc} />
        <ProductListNotAuthorizedWrapper
          type={type}
          isError={isError}
          isLoading={rest.isLoading}
        >
          <List LoadingSkeleton={LoadingSkeleton} {...rest} />
        </ProductListNotAuthorizedWrapper>
      </PortalRootContainer>
    </ProductScreenNotAuthorizedWrapper>
  );
}

export const LoadingTableSkeleton = memo(() => {
  return (
    <Box display="flex" mb="20px" gap={2} alignItems="center">
      <Skeleton
        sx={{
          borderRadius: "5px",
        }}
        height="64px"
        width="64px"
        variant="rounded"
      />
      <Skeleton
        sx={{
          borderRadius: "22.5px",
        }}
        height="15.75px"
        width={`${getRandomNumber(40, 140)}px`}
        variant="rounded"
      />
    </Box>
  );
});
LoadingTableSkeleton.displayName = "LoadingSkeleton";
const components: Record<TCampaignType, TObjectValues> = {
  "payment-form": {
    emptyStateIcon: <SheetIcon height={100} width={100} gradient />,
    Banner: NewPaymentFormsBanner,
    List: PaymentFormsList,
    fetcher: useListPaymentForms,
    modal: CREATE_PAYMENT_FORM_MODAL,
    backgroundSrc: buildCompaignAndBannerVideo(
      "payment-form-placeholder-video",
    ),
    LoadingSkeleton: LoadingTableSkeleton,
  },
  fundraiser: {
    emptyStateIcon: <HeartIcon height={100} width={100} gradient />,
    Banner: NewFundraisersBanner,
    List: FundraisersList,
    fetcher: useFundraisers,
    modal: CREATE_FUNDRAISER_MODAL,
    backgroundSrc: buildCompaignAndBannerVideo("fundraiser-placeholder-video"),
    LoadingSkeleton: LoadingTableSkeleton,
  },
  event: {
    emptyStateIcon: <CalendarIcon height={100} width={100} gradient />,
    Banner: NewEventsBanner,
    List: EventsList,
    fetcher: useEvents,
    modal: CREATE_EVENT_MODAL,
    backgroundSrc: buildCompaignAndBannerVideo("events-placeholder"),
    LoadingSkeleton: LoadingTableSkeleton,
  },
  invoice: {
    emptyStateIcon: <FileLightIcon height={100} width={100} gradient />,
    Banner: NewInvoicesBanner,
    List: InvoicesList,
    fetcher: useInvoices,
    modal: CREATE_INVOICE_MODAL,
    backgroundSrc: buildCompaignAndBannerVideo(
      "invoices-sweepstake-placeholder",
    ),
    LoadingSkeleton: LoadingTableSkeleton,
  },
  membership: {
    emptyStateIcon: <LockIcon height={100} width={100} gradient />,
    Banner: NewMembershipsBanner,
    List: MembershipsList,
    fetcher: useMembership,
    modal: CREATE_MEMBERSHIP_MODAL,
    backgroundSrc: buildCompaignAndBannerVideo("membership-placeholder-video"),
    LoadingSkeleton: LoadingTableSkeleton,
  },
  sweepstake: {
    emptyStateIcon: <HourGlassIcon height={100} width={100} gradient />,
    Banner: NewSweepstakesBanner,
    List: SweepstakesList,
    fetcher: useSweepstakes,
    modal: CREATE_SWEEPSTAKES_MODAL,
    backgroundSrc: buildCompaignAndBannerVideo(
      "invoices-sweepstake-placeholder",
    ),
    LoadingSkeleton: LoadingTableSkeleton,
  },
  standard: {
    emptyStateIcon: <></>,
    Banner: ProductBanner,
    List: PaymentFormsList,
    fetcher: useProducts,
    modal: CREATE_EVENT_MODAL,
    backgroundSrc: buildCompaignAndBannerVideo("events-placeholder"),
    LoadingSkeleton: LoadingTableSkeleton,
  },
};
