import NiceModal from "@ebay/nice-modal-react";
import { X } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import {
  SAVE_AND_CLOSE_MODAL,
  SAVE_MINI_BUILDER_DRAFT,
} from "modals/modal_names";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckFormType from "./hooks/useCheckFormType";
import { formTypeToUrlMap } from "../utils";

export const SaveAndCloseBtn = ({
  modalId = SAVE_MINI_BUILDER_DRAFT,
}: {
  modalId?: string;
  mutate?: ReturnType<typeof usePayBuilderForm>["mutate"];
}) => {
  const { mutate, methods } = usePayBuilderForm();
  const navigate = useNavigate();
  const location = useLocation();
  const prevPath = location.state?.prevUrl;
  const editingProductId = location.state?.id;
  const { formType } = useCheckFormType();

  const isValid = methods.formState.isValid;

  return (
    <GiveIconButton
      Icon={X}
      size="large"
      variant="ghost"
      iconText="Save & Close"
      onClick={() => {
        NiceModal.show(modalId, {
          ...(modalId === SAVE_AND_CLOSE_MODAL && {
            mutate: ({ handleSuccessCB }: { handleSuccessCB?: () => void }) => {
              mutate({
                handleSuccessCB,
                isSaveDb: true,
              });
            },
          }),
          ...(modalId === SAVE_MINI_BUILDER_DRAFT && {
            formType,
            modalType: "saveDraft",
            onAccept: (cb?: () => void) =>
              mutate({
                handleSuccessCB: () => cb && cb(),
                isSaveDb: true,
              }),

            onCancel: (cb?: () => void) => {
              navigate(prevPath || `/merchant/${formTypeToUrlMap[formType]}`);
              cb?.();
            },
          }),
          isFormValid: isValid,
        });
      }}
      sx={{
        display: "flex",
        gap: 1,
      }}
    />
  );
};
