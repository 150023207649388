import TableRowSkeleton from "@common/Skeleton/TableRowSkeleton";
import { styled } from "@theme/v2/Provider";
import { animated } from "react-spring";

type Props = {
  columns: any[];
};

const GiveListLoadingSkeletons = ({ columns }: Props) => {
  return (
    <>
      {Array(20)
        .fill(0)
        .map((_, idx) => (
          <TableRowSkeleton
            key={idx}
            rowHeight={72}
            columns={columns}
            Wrapper={Wrapper}
          />
        ))}
    </>
  );
};

export default GiveListLoadingSkeletons;

const Wrapper = styled(animated.tr)(({ theme }) => ({
  borderBottom: theme.palette.border?.primary,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
}));
