import { useLocation, useParams } from "react-router-dom";
import { TPublicForm } from "./types";
import { useQueryClient } from "react-query";
import { useAppSelector } from "@redux/hooks";
import { selectPaymentFormID } from "@redux/slices/products";
import { CampaignMapperValues } from "features/Minibuilders/PaymentFormMinibuilder/useCreateCampaignFn";

export enum FormType {
  FUNDRAISERS = "fundraiser",
  EVENTS = "event",
  STANDARDS = "standard", // this is coming from BE so i added it here
}

// this hook aims to get form data of the current form in view
const useGetFormData = () => {
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const { id } = useParams();
  const peekedFormId = useAppSelector(selectPaymentFormID);
  const formId = id || state?.id || peekedFormId;
  // when user is not logged in we have this form data
  const publicData = queryClient.getQueryData<TPublicForm>(["public-form-id"]);
  const loggedUserFormData = queryClient.getQueryData<TPublicForm>([
    "get-payment-form-by-id",
    formId,
  ]);

  return { data: publicData || loggedUserFormData };
};

export default function useCheckFormType() {
  const { search, state } = useLocation();
  const searchParams = new URLSearchParams(search);

  // tot get data from API and when data is from API we use it instead of the useLocation values
  const { data } = useGetFormData();
  const type: CampaignMapperValues = data?.typeName
    ? `${data?.typeName}`
    : searchParams.get("type") || state?.campaignName || state?.campaign;

  const isFormType = (formType: FormType) => type === formType;

  const isFundraiser = isFormType(FormType.FUNDRAISERS);
  const isProduct = isFormType(FormType.STANDARDS);
  const isEvent = isFormType(FormType.EVENTS);

  const isNewCustomerViewForm = isFundraiser || isProduct || isEvent;
  return {
    isFundraiser,
    isProduct,
    isEvent,
    formType: type,
    isNewCustomerViewForm,
  };
}
