import React, { memo } from "react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { Stack } from "@mui/material";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import GiveText from "@shared/Text/GiveText";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { replaceKeywords } from "@components/Merchants/MerchantPreview/RiskProfile/helpers/parsers";
import { startCase } from "lodash";
import GiveChip from "@shared/Chip/GiveChip";
import { TRiskLevel } from "@components/Merchants/MerchantPreview/RiskProfile/types";
import { Check, Detective, Siren } from "@phosphor-icons/react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const RiskLevelTag = ({ row }: { row: MerchantData }) => {
  const { palette } = useAppTheme();
  const { isWideView } = useCustomThemeV2();

  const {
    riskLevelLabel,
    riskStatusDisplayName,
    lastRiskActivityDescription,
    lastRiskActivityName,
  } = row || {};

  const riskStatusName = riskStatusDisplayName || "routine_monitoring";
  const risk = riskLevelLabel || "low";

  const Icon = config[riskStatusName].icon;

  const showActivityTip = lastRiskActivityName || lastRiskActivityDescription;

  return (
    <Container>
      <GiveTooltip
        title={config[riskStatusName].tip}
        placement="top"
        color="default"
      >
        <Icon size={18} color={palette.text.tertiary} />
      </GiveTooltip>

      <GiveTooltip
        title={
          <Stack>
            <GiveText variant="bodyS" fontWeight="medium" color="default">
              {replaceKeywords(startCase(lastRiskActivityName))}
            </GiveText>
            <GiveText variant="bodyS" color="default">
              {lastRiskActivityDescription}
            </GiveText>
          </Stack>
        }
        disableFocusListener={!showActivityTip}
        disableHoverListener={!showActivityTip}
        disableTouchListener={!showActivityTip}
        placement={isWideView ? "top-end" : "top"}
        color="default"
      >
        <GiveChip
          variant="light"
          size="large"
          label={startCase(risk)}
          color={colors[risk]}
          sx={{ width: "86px", height: "26px", fontSize: 12 }}
        />
      </GiveTooltip>
    </Container>
  );
};

export default memo(RiskLevelTag);

const Container = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  [theme.breakpoints.down("v2_sm")]: {
    width: "106px",
    marginLeft: "16px",
  },
}));

const config = {
  routine_monitoring: { tip: "Routine monitoring", icon: Check },
  alerted: { tip: "Alerted", icon: Siren },
  active_monitoring: { tip: "Active monitoring", icon: Detective },
};

const colors: Record<TRiskLevel, "warning2" | "success" | "warning" | "error"> =
  {
    low: "success",
    medium: "warning",
    high: "warning2",
    critical: "error",
  };
