import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import React from "react";
import FundraiserRightCard from "../../../View/fundraiser/FundraiserRightCard";
import {
  ContentComponent,
  ImageComponents,
} from "../../../View/fundraiser/components/Atom.component";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";

function FundraiserHero({
  isPreview,
  ADBContent,
}: {
  isPreview?: boolean;
  ADBContent?: React.ReactElement;
}) {
  const { parsedValues, methods } = usePayBuilderForm();
  const showDonationTarget = methods.watch("About.addTarget");
  const donationTarget = methods.watch("About.targetValue");
  const showDonationHistory = methods.watch("About.showHistory");
  const totalContribution = methods.watch("About.totalContribution");
  const checkoutText = methods.watch("Style.checkoutContent");
  const isApprovedNonProfit = methods.watch("About.isApprovedNonProfit");
  const isExistingForm = Boolean(methods.watch("productId"));
  const {
    screenStates: { isMobileView, isTabletView, isMobileDevice },
  } = usePayBuilderContext();
  const isTabletOrMobile = isTabletView || isMobileView;
  const url = parsedValues.selectedImage?.URL;
  const position = parsedValues.assetPosition;
  const isRight = position === "right";
  const image = (
    <ImageComponents fullWidth isMobileView={isTabletOrMobile} url={url} />
  );
  const content = (
    <ContentComponent
      description={parsedValues.description}
      heading={parsedValues.heading}
      isMobileView={isMobileView}
      fullWidth
    />
  );
  const desktopPosition = isRight ? "row" : "row-reverse";
  const mobilePosition = isRight ? "column-reverse" : "column";
  return (
    <Stack
      p={isMobileView ? 0 : "64px 0"}
      alignItems="flex-start"
      flexDirection={isTabletOrMobile ? mobilePosition : desktopPosition}
      gap="16px"
      marginTop={isMobileView ? "20px" : 0}
      width="100%"
    >
      <Box
        flexDirection="column"
        display="flex"
        width={isTabletOrMobile ? "100%" : "758px"}
        gap="32px"
      >
        {ADBContent ? (
          <>{ADBContent}</>
        ) : (
          <>
            {image}

            {content}
          </>
        )}
      </Box>
      <Box width={isTabletOrMobile ? "100%" : "316px"}>
        <FundraiserRightCard
          isPreview={isPreview}
          accentColor={parsedValues.accentColor}
          formValues={{
            showDonationTarget,
            donationTarget,
            showDonationHistory,
            totalContribution,
            isExistingForm,
            checkoutText,
            isApprovedNonProfit,
          }}
          isTabletContextView={isTabletView}
          isMobileContextView={isMobileDevice}
        />
      </Box>
    </Stack>
  );
}

export default FundraiserHero;
