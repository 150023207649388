import NiceModal from "@ebay/nice-modal-react";
import {
  TMerchantDoublePanel,
  useMerchantPreview,
} from "./hooks/useMerchantPreview";
import { Box, Collapse, useMediaQuery, useTheme } from "@mui/material";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { useMerchantDataPreview } from "./hooks/useMerchantDataPreview";
import PreviewModalBody from "./PreviewModalBody";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { palette } from "@palette";
import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { useMobileDrawerActions } from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import MerchantPreviewLoadingSkeleton from "./MerchantPreviewLoadingSkeleton";
import MerchantAgreement from "./MerchantAgreement";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import Conversations from "features/Minibuilders/Conversations/Conversations";
import RiskProfile from "./RiskProfile";
import AgreementSnapshot from "./MerchantAgreement/AgreementSnapshot";
import PoliticallyExposed from "./PEP";
import ManageUnderwritingChallenges from "./manageUnderwriting/ManageUnderwritingChallenges";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { checkPortals } from "@utils/routing";
import { OFACPanel as OFAC } from "./OFAC";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Pane1Minimised } from "./Panel1Minimised/Panel1Minimised";
import Changelog from "features/ChangeLog/Changelog";
import { useEffect, useRef } from "react";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import MATCHPanel from "./MATCH/MATCHPanel";

export type TMerchantInitialSection = Extract<
  TMerchantDoublePanel,
  "riskProfile" | "ofac"
>;

export type MerchantPreviewModalProps = {
  id: number;
  isFirst?: boolean;
  isLast?: boolean;
  setSelectedRow?: (newIdx: string | number) => void;
  initialSection?: TMerchantInitialSection | VoidFunction;
  setReopening: (v: boolean) => void;
};

const MerchantPreviewModal = NiceModal.create(
  (props: MerchantPreviewModalProps) => {
    return <MerchantPreviewModalBody {...props} />;
  },
);

export const MerchantPreviewModalBody = ({
  id,
  isFirst = false,
  isLast = false,
  setSelectedRow,
  initialSection,
}: MerchantPreviewModalProps) => {
  const { isMobileView } = useCustomTheme();
  const { BottomActions } = useMobileDrawerActions();
  const { role } = useUserReducer();
  const isAcquirer = role === "acquirer";
  const theme = useTheme();
  const hasEnoughWidth = useMediaQuery(
    theme.breakpoints.up(CAMPAIGN_PANEL_WIDTH * 2),
  );
  // On control mode role === "acquirer"
  const { isAcquirerPortal } = checkPortals();
  const { open, onClose, SlideProps } = useNiceModal();

  const showChangelog = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.CHANGELOG,
    ),
    operation: OPERATIONS.LIST,
  });

  const {
    setDoublePanel,
    handlers,
    actions,
    doublePanel,
    isControlModeAllowed,
    pepOwnerID,
    panel1Collapsed,
    setPanel1Collapsed,
    closeFullPanel,
    OFACTab,
  } = useMerchantPreview({
    id,
    setSelectedRow,
    onClose,
  });

  const hidePanel1 = (doublePanel && !hasEnoughWidth) || panel1Collapsed;
  const { isChangelogEnabled } = useGetFeatureFlagValues();

  const { data, isLoading, merchant } = useMerchantDataPreview({
    id,
    doublePanel,
  });

  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    if (initialSection && data && !isLoading && !isMounted.current) {
      typeof initialSection === "function"
        ? initialSection()
        : setDoublePanel(initialSection);
      isMounted.current = true;
    }

    return () => {
      isMounted.current = false;
    };
  }, [data, isLoading]);
  const pendingEmail = merchant?.owner?.pendingEmail;
  const mobileActions = actions.map(({ onClick, hidden, title, disabled }) => ({
    onSelect: onClick,
    hidden,
    disabled: disabled || false,
    label: title,
    labelProps:
      title === "Delete merchant"
        ? {
            sx: {
              color: palette.error.hover,
            },
          }
        : undefined,
  }));

  const sidePanelContent: Record<Exclude<TMerchantDoublePanel, "">, any> = {
    manageUnderwriting: (
      <ManageUnderwritingChallenges
        merchantData={data}
        isAcquirer={isAcquirerPortal}
        handleClosePanel={() => handlers.handleManageUnderwriting(false)}
        headerData={{
          name: data?.header?.name,
          imageURL: data?.header?.imageURL,
        }}
        id={id}
      />
    ),
    MerchantAgreement: (
      <MerchantAgreement
        data={data}
        canSign
        merchantAgreement={data.merchantAgreement}
        handleClosePanel={() => handlers.handleManageMerchantAgreement(false)}
        handleOpenAgreementSnapshot={() =>
          handlers.handleOpenAgreementSnapshot(true)
        }
      />
    ),
    riskProfile: (
      <RiskProfile
        profileId={data.riskProfile.profileID}
        handleClosePanel={() => handlers.handleOpenRiskProfile(false)}
        merchantId={data?.merchantInfo?.merchantID}
        merchantStatus={data?.status}
      />
    ),
    agreementSnapshot: (
      <AgreementSnapshot
        onClose={() => handlers.handleOpenAgreementSnapshot(false)}
        handleManageMerchantAgreement={() =>
          handlers.handleManageMerchantAgreement(true)
        }
        merchantId={data?.merchantInfo?.merchantID}
        data={data}
      />
    ),
    pep: (
      <PoliticallyExposed
        selectedOwner={pepOwnerID}
        merchantId={data.merchantInfo.merchantID}
        legalEntityID={data.businessProfile.id}
        onClose={() => handlers.handleOpenPep(false)}
        isAcquirer={isAcquirer}
      />
    ),
    ofac: (
      <OFAC
        headerData={{
          name: data?.header?.name,
          imageURL: data?.header?.imageURL,
        }}
        isAcquirer={isAcquirer}
        toggleSecondModal={() => handlers.handleOpenOFACPanel(false)}
        merchantId={id}
        businessProfile={{
          name: data?.businessProfile?.legalName,
          lastCheck: data?.businessProfile?.ofac,
          legalEntityId: data?.businessProfile?.id,
        }}
        initialActiveTab={OFACTab}
        PAH={data?.primaryAccountHolder}
        businessOwners={data?.businessOwnersList}
      />
    ),
    match: (
      <MATCHPanel
        headerData={{
          name: data?.header?.name,
          imageURL: data?.header?.imageURL,
        }}
        isAcquirer={isAcquirer}
        toggleSecondModal={() => handlers.handleOpenMATCH(false)}
        merchantID={data.merchantInfo.merchantID}
      />
    ),
  };

  return (
    <ModalFactory
      variant="sidepanel"
      modalProps={{
        open,
        onClose: closeFullPanel,
        SlideProps,
        SidePanelProps: {
          width:
            hasEnoughWidth && doublePanel && !panel1Collapsed
              ? CAMPAIGN_PANEL_WIDTH * 2
              : CAMPAIGN_PANEL_WIDTH,
        },
      }}
    >
      {isMobileView ? (
        isLoading ? (
          <MerchantPreviewLoadingSkeleton />
        ) : (
          <Box position="relative" data-testid="merchant-info-container-mobile">
            <PreviewModalBody
              data={data}
              id={id}
              pendingEmail={pendingEmail}
              isFirst={isFirst}
              isLast={isLast}
              actions={actions}
              handlers={handlers}
              isControlModeAllowed={isControlModeAllowed}
              openedSecondPanel={doublePanel}
              selectedPEPOwner={pepOwnerID}
            />

            <BottomActions
              sx={{
                "& .MuiPaper-root": {
                  padding: 0,
                  bottom: "100px",
                },
              }}
              label={
                <HorizontalMoreIcon
                  height={41}
                  width={41}
                  fill={palette.background.dimmedWhite}
                />
              }
              items={mobileActions}
              buttonSx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />

            <Box height={70} width="100%" />
          </Box>
        )
      ) : (
        <Box width="100%" height="100%" display="flex">
          <Box
            height="100%"
            flex={1}
            data-testid="merchant-info-container"
            sx={{
              display: hidePanel1 ? "none" : "block",
            }}
          >
            {isLoading ? (
              <MerchantPreviewLoadingSkeleton />
            ) : (
              <PreviewModalBody
                data={data}
                id={id}
                isFirst={isFirst}
                isLast={isLast}
                actions={actions}
                handlers={handlers}
                isControlModeAllowed={isControlModeAllowed}
                openedSecondPanel={doublePanel}
                selectedPEPOwner={pepOwnerID}
              />
            )}
          </Box>

          <Collapse in={!!doublePanel} orientation="horizontal">
            <Box
              bgcolor="#F8F8F6"
              padding="12px 16px"
              flex={1}
              height="100vh"
              maxWidth={CAMPAIGN_PANEL_WIDTH}
            >
              <ErrorCatcher errorID="merchant-double-panel">
                {doublePanel && sidePanelContent[doublePanel]}
              </ErrorCatcher>
            </Box>
          </Collapse>
        </Box>
      )}
      {isChangelogEnabled && merchant && showChangelog && (
        <Changelog
          isLoading={isLoading}
          isLoadingConversation={isLoading}
          isMerchantApproved={merchant?.statusName === "approved"}
          merchant={merchant}
        />
      )}
      {isAcquirerPortal && (
        <Conversations
          merchant={merchant}
          isLoading={isLoading}
          handleOpenManageUnderwriting={handlers?.handleOpenManageUnderwriting}
          isLoadingChangelog={isLoading}
          shouldShowChangelog={isChangelogEnabled && merchant && showChangelog}
        />
      )}
      {doublePanel && panel1Collapsed && (
        <Pane1Minimised
          image={merchant?.imageURL}
          onBack={() => {
            setPanel1Collapsed(false);
          }}
        />
      )}
    </ModalFactory>
  );
};

export default MerchantPreviewModal;
