import { TAG_STATUSES_ENUM } from "@common/TextTag/types";
import GiveChip, { TChipColors } from "@shared/Chip/GiveChip";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { styled } from "@theme/v2/Provider";
import moment from "moment";

type Props = {
  disableTooltip?: boolean;
  statusDisplayName: string;
  updatedAt?: number | null;
  updatedBy?: string;
};

const StatusTag = ({
  statusDisplayName,
  updatedAt = null,
  updatedBy = "",
  disableTooltip,
}: Props) => {
  const statusTag = colorsMap[statusDisplayName];
  const color = statusTag || colorsMap[TAG_STATUSES_ENUM.APPROVED];
  const label = statusTag ? statusDisplayName : TAG_STATUSES_ENUM.APPROVED;
  const isInviteStatus = statusDisplayName === TAG_STATUSES_ENUM.INVITE;

  const isTooltipEnabled = isInviteStatus
    ? !!updatedAt
    : !!updatedAt && !!updatedBy?.trim();

  const tooltipContent = isInviteStatus
    ? "Invitation sent"
    : `${statusDisplayName} by: ${updatedBy?.trim()}`;

  return (
    <GiveTooltip
      title={`${tooltipContent} ${dateParser(updatedAt)}`}
      placement="top"
      disableHoverListener={!isTooltipEnabled || disableTooltip}
      color="default"
      alignment="start"
    >
      <StyledChip color={color} label={label} size="large" variant="light" />
    </GiveTooltip>
  );
};
export default StatusTag;

const dateParser = (date?: number | null) =>
  date ? ` on ${moment.unix(date).format("LL")}` : "";

const colorsMap: Record<string, TChipColors> = {
  [TAG_STATUSES_ENUM.APPROVED]: "success",
  [TAG_STATUSES_ENUM.UNDERWRITING]: "blue",
  [TAG_STATUSES_ENUM.SUSPENDED]: "warning",
  [TAG_STATUSES_ENUM.DECLINED]: "error",
  [TAG_STATUSES_ENUM.INVITE]: "default",
  [TAG_STATUSES_ENUM.PENDING]: "default",
  [TAG_STATUSES_ENUM.READY_FOR_REVIEW]: "warning",
  [TAG_STATUSES_ENUM.IN_PROGRESS]: "warning",
  [TAG_STATUSES_ENUM.DELIVERED]: "default",
  [TAG_STATUSES_ENUM.UNDELIVERABLE]: "error",
};

const StyledChip = styled(GiveChip)(({ theme }) => ({
  width: "135px",
  height: "26px",
  fontSize: "12px",

  [theme.breakpoints.down("v2_sm")]: {
    width: "106px",
    marginLeft: "8px",
  },
}));
