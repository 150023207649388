import { StyledIconButton } from "@common/IconButton";
import { SIDE_PANEL_BUTTON_OFFSET } from "@constants/constants";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Badge, BadgeProps, Box, Theme, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChatsCircle } from "@phosphor-icons/react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { CustomPalette } from "@theme/v2/palette.interface";
import { useAppTheme } from "@theme/v2/Provider";
import { animated, useTransition } from "react-spring";
import { NEW_BASE_PANEL_WIDTH } from "@shared/SidePanel/GiveSidePanel";

type Props = {
  isLoading: boolean;
  handleOpenConversationsModal: (open?: boolean | undefined) => void;
  isOpen?: boolean;
  isOpenChangeLog?: boolean;
  totalUnread: number;
  isReplyFromMerchant?: boolean;
  isDoublePanel?: boolean;
};

const ConversationsButton = ({
  isLoading,
  handleOpenConversationsModal,
  isOpen = false,
  isOpenChangeLog = false,
  isReplyFromMerchant = false,
  isDoublePanel = false,
  ...rest
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const { palette } = useAppTheme();
  const styles = useStyles({
    isMobileView,
    isOpen: isOpen || isOpenChangeLog,
    isDoublePanel,
  });

  const transitions = useTransition(Boolean(isOpen && !isMobileView), {
    from: { transform: "translateY(100px)", opacity: 0 },
    enter: { transform: "translateY(0px)", opacity: 1 },
    leave: { transform: "translateY(100px)", opacity: 0 },
    config: { duration: 300, easing: (t: any) => t * (2 - t) },
  });

  const AnimatedButton = animated(StyledIconButton);

  return transitions((style, isModalVisible) => (
    <HiddenComponent hidden={isModalVisible}>
      <AnimatedButton
        style={style}
        id="conversationButton"
        className={styles.buttonStyles}
        onClick={() =>
          handleOpenConversationsModal(!isMobileView ? true : !isOpen)
        }
        disabled={isLoading}
        sx={{
          position: "relative",
          borderRadius: "50%",
        }}
        data-testid="conversation-button"
      >
        <Box className={styles.containerStyles}>
          <CustomBadge
            color="primary"
            badgeContent={rest.totalUnread || undefined}
            isReplyFromMerchant={isReplyFromMerchant}
            data-testid="unread-conversation-badge"
            palette={palette}
          >
            <ChatsCircle width="30px" height="30px" />
          </CustomBadge>
        </Box>
      </AnimatedButton>
    </HiddenComponent>
  ));
};

export default ConversationsButton;

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyles: ({
    isMobileView,
    isOpen,
    isDoublePanel,
  }: {
    isMobileView: boolean;
    isOpen: boolean;
    isDoublePanel: boolean;
  }) => {
    return {
      position: isMobileView ? (isOpen ? "relative" : "fixed") : "fixed",
      bottom: isMobileView ? (isOpen ? "initial" : "30px") : "24px",
      left: isMobileView
        ? isOpen
          ? "0px"
          : "initial"
        : `calc(100% - ${
            (isDoublePanel ? NEW_BASE_PANEL_WIDTH * 2 : NEW_BASE_PANEL_WIDTH) +
            SIDE_PANEL_BUTTON_OFFSET
          }px)`,
      right: isMobileView ? "20px" : "70px",
      ...(isMobileView && { top: isOpen ? "0px" : "initial" }),
      boxShadow: isMobileView
        ? "0px 2.625px 13.125px 0px rgba(2, 2, 2, 0.15)"
        : "initial",
      zIndex: 3,
    };
  },

  containerStyles: {
    borderRadius: "50%",
    padding: "11px",
    backgroundColor: theme.palette.neutral.white,
  },
}));

interface CustomBadgeProps extends BadgeProps {
  isReplyFromMerchant: boolean;
}

const CustomBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "isReplyFromMerchant",
})<CustomBadgeProps & { palette: CustomPalette }>(
  ({ isReplyFromMerchant, palette }) => ({
    "& .MuiBadge-badge": {
      right: "-3px",
      top: "-3px",

      backgroundColor: palette.primitive?.blue[50],
      ...(isReplyFromMerchant && {
        color: palette.primitive?.neutral[0],
        backgroundColor: palette.primitive?.warning[50],
      }),
    },
  }),
);
