import { Link, LinkProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@phosphor-icons/react";
import { DEFAULT_ICON_SIZE } from "../constants";

interface RebrandedLinkProps extends LinkProps {
  link?: string;
  replace?: boolean;
  Icon?: Icon;
  iconPosition?: "left" | "right";
  disabled?: boolean;
}

const GiveLink = ({
  children,
  link = "",
  replace,
  disabled = false,
  Icon,
  iconPosition = "left",
  component = RouterLink,
  color,
  onClick,
  ...rest
}: RebrandedLinkProps) => {
  //MUI does not handle colors as we handle them in our theme,
  //passing down `destructive` direclty causes an error.To overcome this,
  //we can use a color variant, like `error` under the hood, while still using`destructive` as a styling value
  //when we use this component, to follow our figma design system.

  const isIconLeft = iconPosition === "left" && Icon;
  const isIconRight = iconPosition === "right" && Icon;

  return (
    <Link
      component={disabled ? "p" : component}
      to={link}
      replace={replace}
      disabled={disabled}
      version="two"
      color={color === "destructive" ? "error" : color}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {isIconLeft && <Icon size={DEFAULT_ICON_SIZE} />}
      <span>{children}</span>
      {isIconRight && <Icon size={DEFAULT_ICON_SIZE} />}
    </Link>
  );
};

export default GiveLink;
