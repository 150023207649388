import {
  Box,
  Menu,
  MenuProps,
  Stack,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from "@mui/material";
import GiveEmptyStateWrapper from "@shared/EmptyState/GiveEmptyStateWrapper";
import GiveSearchBar from "@shared/SearchBar/GiveSearchBar";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { CustomPalette } from "@theme/v2/palette.interface";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { isEmpty } from "lodash";
import { ContextualMenuProps } from "./ContextualMenu.types";
import ContextualMenuOption from "./ContextualMenuOption";
import { Virtuoso } from "react-virtuoso";

const ContextualMenu = ({
  color = "primary",
  texture = "solid",
  anchorEl,
  options,
  menuWidth = 300,
  searchBarProps,
  handleClose = () => null,
  horizontalOrigin = "right",
  onCloseEmptyState,
  emptySection,
  isLoading,
  Header,
  customPaperStyle,
  customContent,
  useVirtualList = false,
  ...props
}: ContextualMenuProps &
  Omit<MenuProps, "open" | "onClose" | "onOpen"> &
  Omit<SwipeableDrawerProps, "open" | "onClose" | "onOpen">) => {
  const { palette, customs } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const isOpen = Boolean(anchorEl);
  const paperStyle = getPaperStyle(palette);

  const filteredOptions = options?.filter((option) => !option?.hidden);
  const isNoOptions = isEmpty(filteredOptions) && emptySection && !isLoading;
  const content = customContent || (
    <Stack padding="8px" width={isMobileView ? "100%" : menuWidth}>
      {Header}
      {!!searchBarProps && (
        <StyledGiveSearchBar
          placeholder="Search"
          iconSize={20}
          {...searchBarProps}
        />
      )}
      {isNoOptions && searchBarProps?.value ? (
        <Box mt="20px">
          <GiveEmptyStateWrapper
            searchValue={searchBarProps?.value}
            isEmpty
            section={emptySection}
            action={{
              handleAction: onCloseEmptyState,
            }}
          />
        </Box>
      ) : (
        <OptionsContainer>
          {useVirtualList ? (
            // We can use Virtuoso to render large lists of options in a virtual list to improve performance
            <Virtuoso
              style={{ height: "312px" }}
              totalCount={filteredOptions?.length || 0}
              itemContent={(index) => {
                const option = filteredOptions?.[index];
                return (
                  <ContextualMenuOption
                    handleClose={handleClose}
                    key={index}
                    {...option}
                  />
                );
              }}
            />
          ) : (
            filteredOptions?.map((option, index) => (
              <ContextualMenuOption
                handleClose={handleClose}
                key={index}
                {...option}
              />
            ))
          )}
        </OptionsContainer>
      )}
    </Stack>
  );

  if (isMobileView) {
    return (
      <SwipeableDrawer
        onOpen={() => null}
        onClose={handleClose}
        anchor="bottom"
        open={isOpen}
        sx={{ zIndex: 12000 }}
        PaperProps={{
          sx: {
            borderTopLeftRadius: customs?.radius.medium,
            borderTopRightRadius: customs?.radius.medium,
            padding: "12px 0 20px",
            ...paperStyle[color][texture],
          },
        }}
        slotProps={{
          backdrop: {
            sx: {
              opacity: 0.5,
              backgroundColor: palette.surface?.overlay,
            },
          },
        }}
        {...props}
      >
        <>
          <DragIndicator bgcolor={palette.surface?.tertiary} />
          {content}
        </>
      </SwipeableDrawer>
    );
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: horizontalOrigin,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: horizontalOrigin,
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            boxShadow: "none !important",
            overflowY: "hidden",
            ...paperStyle[color][texture],
            borderRadius: "16px !important",
            "& ul": {
              py: 0,
            },
            padding: "0px !important",
            ".MuiBox-root": {
              padding: 0,
            },
            ...customPaperStyle,
            ...(props.slotProps?.paper as any)?.sx,
          },
        },
      }}
      {...props}
    >
      {content}
    </Menu>
  );
};

export default ContextualMenu;

const DragIndicator = styled(Box)({
  height: "4px",
  width: "64px",
  borderRadius: "10px",
  margin: "0 auto",
});

const OptionsContainer = styled(Box)(({ theme }) => ({
  maxHeight: "312px",
  overflowY: "auto",
  scrollbarColor: `${theme.palette.text.tertiary} transparent`,
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.text.tertiary,
    borderRadius: "6px",
  },
  padding: "0 4px",
}));

const StyledGiveSearchBar = styled(GiveSearchBar)(({ theme }) => ({
  "& .MuiInputBase-root": {
    border: "none !important",
    backgroundImage: "none !important",
    background: "transparent !important",
    padding: "8px 12px 12px",
    "&.Mui-focused .MuiInputAdornment-root.MuiInputAdornment-positionStart svg path":
      {
        fill: theme.palette.text.secondary,
      },
    "&:hover:not(.Mui-focused) svg path": {
      fill: theme.palette.text.secondary,
    },
    "& input": {
      margin: 0,
      height: "20px",
    },
    "& fieldset": {
      border: "none",
    },
  },
  "& .Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
    border: "none",
    background: "transparent",
  },
}));

const getPaperStyle = (palette: CustomPalette) => ({
  primary: {
    solid: {
      background: palette.surface?.primary,
      border: `1px solid ${palette.border?.primary}`,
    },
    blurred: {
      background: palette.surface?.["primary-transparent"],
      backdropFilter: "blur(15px)",
      border: `1px solid ${palette.border?.primary}`,
    },
  },
  secondary: {
    solid: {
      background: palette.surface?.secondary,
      border: `1px solid ${palette.border?.primary}`,
    },
    blurred: {
      background: `${palette.surface?.["secondary-transparent"]} !important`,
      backdropFilter: "blur(25px) !important",
      border: `1px solid ${palette.border?.primary} !important`,
    },
  },
  tertiary: {
    solid: {
      background: palette.surface?.tertiary,
      border: `1px solid ${palette.border?.secondary}`,
    },
    blurred: {
      background: `${palette.surface?.["tertiary-transparent"]} !important`,
      backdropFilter: "blur(8px)",
      border: `1px solid ${palette.border?.secondary}`,
    },
  },
  transparent: {
    solid: {
      background: `${palette.surface?.["tertiary-transparent"]}`,
      backdropFilter: "blur(15px)",
      border: `1px solid ${palette.border?.primary}`,
    },
    blurred: {
      background: `${palette.surface?.["tertiary-transparent"]} !important`,
      backdropFilter: "blur(5px)",
      border: `1px solid ${palette.border?.primary}`,
    },
  },
});
