import { useAppDispatch } from "@redux/hooks";
import {
  disableTableSortingOrder,
  disableTableSortingOrderProperty,
  setTableSortingOrder,
  setTableSortingOrderProperty,
} from "@redux/slices/app";
import { updateSorting } from "@redux/slices/fundraisers";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TableColumnType, TableData, TableOrder } from "../Table.types";

type Props = {
  small?: boolean;
  setSorting?: Dispatch<SetStateAction<string>>;
  initialSortingProperty?: string;
  defaultSort?: { key: string; isDesc?: boolean };
};

export const useTableSort = ({
  small,
  setSorting,
  defaultSort = { key: "createdAt", isDesc: true },
}: Props) => {
  const dispatch = useAppDispatch();

  const { key: defaultKey, isDesc: isDefaultDesc } = defaultSort || {};

  const [sortKey, setSortKey] = useState<null | keyof TableData>(null);
  const [order, setOrder] = useState<TableOrder>("desc");

  const getNewOrder = (columnKey: keyof TableData) => {
    let newOrder: TableOrder = "asc";
    if (!sortKey || sortKey !== columnKey) {
      newOrder = "asc";

      if (defaultKey === columnKey && isDefaultDesc) {
        newOrder = "desc";
      }
    } else {
      newOrder = order === "asc" ? "desc" : "asc";
    }

    return newOrder;
  };

  useEffect(() => {
    setSortKey("createdAt");
    setOrder("desc");
  }, []);

  useEffect(() => {
    if (sortKey !== defaultKey) {
      setSortingData(defaultKey);
    }
  }, [defaultKey, isDefaultDesc]);

  const handleClick =
    (column: TableColumnType) => (event: React.MouseEvent<HTMLElement>) => {
      setSortingData(column.key);
    };

  const setSortingData = (key: string) => {
    setSortKey(key);

    const newOrder = getNewOrder(key);
    setOrder(newOrder);

    const newSorting = (newOrder === "asc" ? key : `-${key}`) as TableOrder;
    if (setSorting) {
      setSorting(newSorting);
      return;
    }
    if (small) {
      dispatch(setTableSortingOrderProperty(key));
      dispatch(setTableSortingOrder(newSorting));
      return;
    }
    dispatch(updateSorting(newSorting));
  };

  useEffect(() => {
    return () => {
      dispatch(disableTableSortingOrder());
      dispatch(disableTableSortingOrderProperty());
      dispatch(updateSorting("-name")); //set back to initial value
    };
  }, []);

  return { handleClick, sortKey, order };
};
