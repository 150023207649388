import React, { useEffect } from "react";
import { useBulkMarkMerchants } from "@components/AcquirerMerchants/hooks/useBulkMarkMerchants";
import { useAppDispatch } from "@redux/hooks";
import { resetMarkedMerchants } from "@redux/slices/enterprise/merchants";
import useCheckSponsor from "@hooks/common/useCheckSponsor";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { useGetBackgroundTaskStatus } from "@components/AcquirerMerchants/hooks/useGetBackgroundTaskStatus";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { CustomToolTip } from "./CustomTooltip";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const MarkAllCheckBox = () => {
  const { isMarkAllChecked, toggleBulkMark, stats } = useBulkMarkMerchants();
  const { isMobileView } = useCustomThemeV2();
  const dispatch = useAppDispatch();
  const { hasSponsorAccess } = useCheckSponsor();
  useGetBackgroundTaskStatus({ enabled: true });

  useEffect(() => {
    // reset marked merchants on page exit
    return () => {
      dispatch(resetMarkedMerchants());
    };
  }, []);

  const isDisabled =
    !hasSponsorAccess || stats?.merchantSponsorReadyForReviewCount === 0;

  return (
    <CustomToolTip
      message={ACTION_DENY_MESSAGE}
      showToolTip={!hasSponsorAccess}
      onClick={(e) => e.stopPropagation()}
      placement={isMobileView ? "bottom" : "bottom-end"}
      customTooltipStyles={{
        "&.MuiStack-root": {
          zIndex: 10,
          width: "auto",
          cursor: isDisabled ? "default" : "pointer",
        },
      }}
    >
      <GiveCheckbox
        size="medium"
        sx={{
          cursor: "pointer",
          "& .MuiCheckbox-root": {
            pointerEvents: "auto",
          },
          marginRight: "8px",
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => toggleBulkMark && toggleBulkMark(e.target.checked)}
        disabled={isDisabled}
        aria-disabled={false}
        checked={isMarkAllChecked}
        data-testid="mark-all-checkbox-v2"
      />
    </CustomToolTip>
  );
};

export default React.memo(MarkAllCheckBox);
