import { styled } from "@theme/v2/Provider";
import { Box, Stack } from "@mui/system";
import GiveButton from "@shared/Button/GiveButton";

const StyledAddNewReportStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2.5),
  [theme.breakpoints.down("v2_sm")]: {
    flexDirection: "column",
  },
}));

const StyledAddNewReportButton = styled(GiveButton)(({ theme }) => ({
  height: "fit-content",
  width: "75%",
  [theme.breakpoints.down("v2_sm")]: {
    width: "100%",
  },
}));

const StyledMatchStatusBoxRoot = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette?.surface?.secondary,
  borderRadius: theme.customs?.radius.medium,
  gap: theme.spacing(4),
}));

const StyledMatchStatusStackContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette?.surface?.primary,
  borderRadius: theme.customs?.radius.medium,
  cursor: "pointer",
}));

const StyledLatestCheckStack = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border?.primary}`,
  marginTop: theme.spacing(1.5),
  paddingTop: theme.spacing(1.5),
  flexDirection: "row",
  justifyContent: "space-between",
}));

const StyledPreviousCheckStack = styled(StyledMatchStatusStackContainer)({
  cursor: "default",
});

const StyledPreviousCheckRowContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  cursor: "pointer",
  padding: theme.spacing(1.5),
}));

export {
  StyledPreviousCheckRowContainer,
  StyledPreviousCheckStack,
  StyledLatestCheckStack,
  StyledMatchStatusStackContainer,
  StyledMatchStatusBoxRoot,
  StyledAddNewReportButton,
  StyledAddNewReportStack,
};
