type FacebookIconProps = {
    width?: number | string;
    height?: number | string;
  };
  
  const YoutubeIcon = ({ height="40", width="40" }: FacebookIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.80563 13.4069C2.9663 10.9334 4.95493 8.99493 7.43058 8.87228C11.0155 8.69467 16.1393 8.48047 20 8.48047C23.8607 8.48047 28.9845 8.69467 32.5694 8.87228C35.0451 8.99493 37.0337 10.9334 37.1944 13.4069C37.3483 15.7766 37.5 18.6925 37.5 20.9805C37.5 23.2684 37.3483 26.1843 37.1944 28.5541C37.0337 31.0276 35.0451 32.966 32.5694 33.0887C28.9845 33.2663 23.8607 33.4805 20 33.4805C16.1393 33.4805 11.0155 33.2663 7.43058 33.0887C4.95494 32.966 2.9663 31.0276 2.80563 28.5541C2.6517 26.1843 2.5 23.2684 2.5 20.9805C2.5 18.6925 2.6517 15.7766 2.80563 13.4069Z" fill="#FC0D1B"/>
        <path d="M16.25 15.9805V25.9805L26.25 20.9805L16.25 15.9805Z" fill="white"/>
        </svg>   
    );
  };
  
  export default YoutubeIcon;
  