import React from "react";
import { Link, SxProps } from "@mui/material";
import { followLink } from "@hooks/common/documents/utils";

const LoginExternalLink = ({
  children,
  href,
  underline,
  sx,
  isDisabled,
}: {
  children: React.ReactNode;
  href: string;
  underline: string;
  sx?: SxProps;
  isDisabled?: boolean | undefined;
}) => {
  return (
    <Link
      underline="none"
      onClick={(e: any) => {
        e.preventDefault();
        if (!isDisabled) followLink(href, { target: "__blank" });
      }}
      color="inherit"
      href={!isDisabled ? href : undefined}
      target={!isDisabled ? "_blank" : ""}
      width="fit-content"
      sx={{
        ...(underline && {
          "&:hover": {
            textDecoration: isDisabled ? "none" : "underline",
            color: isDisabled ? undefined : underline,
            cursor: isDisabled ? "text" : "pointer",
          },
        }),
        ...sx,
      }}
    >
      {children}
    </Link>
  );
};

export default LoginExternalLink;
