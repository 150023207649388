import { Box, Tooltip as MUITooltip, Stack, TooltipProps } from "@mui/material";
import { Text_color_types } from "@shared/Text/giveText.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled, useAppTheme } from "@theme/v2/Provider";
import React, { useMemo, useState } from "react";
import GiveText from "../Text/GiveText";
import { GiveTooltipProps } from "./GiveTooltip.types";

const TOOLTIP_BOTTOM_MARGIN = 20;
const TOOLTIP_MAX_WIDTH = 320;

const GiveTooltip = ({
  title,
  children,
  color,
  iconLeft,
  iconRight,
  heading,
  isArrow = false,
  arrowPlacement,
  leaveTouchDelay = 3000,
  disableHoverListener = false,
  width = "compact",
  alignment = "center",
  customTooltipStyles,
  onClick,
  ...rest
}: GiveTooltipProps) => {
  const [open, setOpen] = useState(false);
  const [tooltipDisabled, setTooltipDisabled] = useState(false);

  const { isWideView } = useCustomThemeV2();
  const { palette } = useAppTheme();

  const textColorData = useMemo(() => {
    const colorMap: Record<string, { color?: string; type: Text_color_types }> =
      {
        default: { color: palette.text.invert, type: "default" },
        warning: {
          color: palette.primitive?.warning?.["100"],
          type: "warning",
        },
        success: {
          color: palette.primitive?.success?.["100"],
          type: "success1",
        },
        light: {
          color: palette.text.invert,
          type: "light",
        },
      };

    return colorMap?.[color] || colorMap.default;
  }, [color]);

  const textColor = textColorData.color;
  const textColorType = textColorData.type;

  const handleTooltipOpen = (
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (disableHoverListener) return;
    e?.stopPropagation();
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const hoverHandler = () => {
    if (open) return;
    if (tooltipDisabled) return;
    handleTooltipOpen();
  };

  return (
    <StyledTooltip
      color={color}
      open={open}
      leaveTouchDelay={leaveTouchDelay}
      onClose={handleTooltipClose}
      version="two"
      width={width === "compact" ? "100%" : TOOLTIP_MAX_WIDTH}
      PopperProps={
        // On mobile / tablet view tooltip should be at the bottom of the screen
        !isWideView
          ? {
              anchorEl: {
                getBoundingClientRect: () =>
                  ({
                    top: window.innerHeight - TOOLTIP_BOTTOM_MARGIN,
                    left: window.innerWidth / 2,
                    width: 0,
                    height: 0,
                  } as any),
              },
            }
          : {
              sx: {
                width: "fit-content",
              },
            }
      }
      title={
        <Stack direction="row" gap="12px">
          {iconLeft && (
            <Box minWidth="18px" minHeight="18px">
              {React.cloneElement(iconLeft as React.ReactElement, {
                color: textColor,
                width: 18,
                height: 18,
              })}
            </Box>
          )}
          <Stack gap="4px" flexGrow={1}>
            {heading && (
              <GiveText
                variant="bodyS"
                color={textColorType}
                sx={{
                  fontWeight: "500",
                }}
              >
                {heading}
              </GiveText>
            )}
            {typeof title === "string" ? (
              <GiveText variant="bodyS" color={textColorType}>
                {title}
              </GiveText>
            ) : (
              title
            )}
          </Stack>
          {iconRight && (
            <Box minWidth="18px" minHeight="18px">
              {React.cloneElement(iconRight as React.ReactElement, {
                color: textColor,
                width: 18,
                height: 18,
              })}
            </Box>
          )}
        </Stack>
      }
      arrow={isArrow}
      placement={arrowPlacement}
      sx={customTooltipStyles}
      {...rest}
    >
      <Stack
        onClick={(e) => {
          if (!isWideView) {
            handleTooltipOpen(e);
          }
          onClick?.(e);
        }}
        onMouseOver={
          !isWideView || disableHoverListener ? undefined : () => hoverHandler()
        }
        onMouseLeave={
          !isWideView || disableHoverListener
            ? undefined
            : () => handleTooltipClose()
        }
        alignItems={alignment}
      >
        {React.isValidElement(children) && !!children.props?.onOpening
          ? React.cloneElement(
              children as React.ReactElement<{ onOpening?: any }>,
              {
                onOpening: (event: "toggle_tooltip", data?: any) => {
                  if (event === "toggle_tooltip") {
                    if (data) {
                      setOpen(false);
                      setTooltipDisabled(true);
                    } else {
                      setTooltipDisabled(false);
                    }
                  }
                },
              },
            )
          : children}
      </Stack>
    </StyledTooltip>
  );
};

type StyledTooltipProps = {
  width?: string | number;
} & TooltipProps;

const StyledTooltip = styled(({ ...props }: StyledTooltipProps) => (
  <MUITooltip {...props} classes={{ popper: props.className }} />
))(({ width }) => ({
  // [`& .${tooltipClasses.tooltip}`]: {
  //   width,
  //   maxWidth: TOOLTIP_MAX_WIDTH,
  // },
}));

export default GiveTooltip;
