import { showMessage } from "@common/Toast";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { FileUploadStatus } from "@components/UploadFile/types";
import { IMAGE_UPLOAD_SIZE_LIMIT } from "@constants/constants";
import NiceModal from "@ebay/nice-modal-react";
import { EDIT_PROFILE_IMAGE_MODAL } from "modals/modal_names";
import GiveThumbnail from "@shared/Thumbnail/GiveThumbnail";

type TProfileImageProps = {
  imageUrl?: string;
  title: string;
  onUpload?: (file: File) => void;
  onDelete?: () => void;
  disabled?: boolean;
};

const ProfileImage = ({
  imageUrl,
  title,
  onUpload,
  onDelete,
  disabled = false,
}: TProfileImageProps) => {
  const { setSnackbarFiles } = useFileUploadContext();

  const actions = {
    onUpload,
    onDelete,
    canUpload: !disabled,
    canDelete: !disabled && !!imageUrl,
  };

  const checkMaxSize = (file: File) => {
    const exceedsSize = file.size > IMAGE_UPLOAD_SIZE_LIMIT;

    const exceedsTitleLength = file.name.length > 254;
    if (exceedsSize) {
      setSnackbarFiles([
        {
          id: `${Date.now()}-${file.name}`,
          name: file.name,
          size: file.size,
          status: FileUploadStatus.FILE_TOO_LARGE,
          uploadProgress: 0,
          canBeDeleted: false,
        },
      ]);
    }
    if (exceedsTitleLength) showMessage("Error", "File name is too long");
    return exceedsSize || exceedsTitleLength;
  };

  const handleClick = () => {
    NiceModal.show(EDIT_PROFILE_IMAGE_MODAL, {
      defaultImageURL: imageUrl,
      rounded: false,
      subTitle: title,
      actions,
      onLocalUpload: checkMaxSize,
      thumbnailType: "merchant",
    });
  };

  return (
    <GiveThumbnail
      size="large"
      type="merchant"
      imageUrl={imageUrl}
      disabled={disabled}
      onClick={handleClick}
    />
  );
};

export default ProfileImage;
