import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { Grid, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { useEffect, useState } from "react";
import {
  Product,
  TFormStatus,
  TFormStatusEnum,
} from "../components/products/types";
import { styled } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { showMessage } from "@common/Toast";
import { LinkSimple } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import QuantityInput from "../components/products/QuantityInput";
import { ICartItem } from "../provider/CartContext";
import { addSizeToImage } from "@utils/image.helpers";
import PublicUrlShare from "../LaunchStep/PublicURLShare/PublicURLShare";
import { parsePriceToInteger } from "@utils/helpers";
import { parseDescription } from "@shared/HFInputs/HFRichInput/RichTextUtils";
import { useIsEnabled } from "../components/hooks/useHelpers";
import useCheckFormType from "../components/hooks/useCheckFormType";

type Props = {
  product: Product;
  addToCart: (
    item: ICartItem,
    addedQuantity?: number,
    quantityChangeType?: string,
    replace?: boolean,
  ) => void;
  accentColor: string;
  productQuantity: number;
  displayStatus: TFormStatus;
  formType?: string;
};

// TODO ADD QUANTITY INPUT COMPONENT, AS IT HAS A SPECIFIC STYLING

const PUBLIC_PRODUCT_ITEM_MODAL = NiceModal.create(
  ({
    product,
    addToCart,
    accentColor,
    productQuantity,
    displayStatus,
    formType,
  }: Props) => {
    const {
      thumbnail,
      amount,
      title,
      description,
      in_stock,
      paymentType,
      variantID,
    } = product;

    const [quantity, setQuantity] = useState<number>(productQuantity);
    const { open, onClose } = useNiceModal();
    const { isMobileView, isDesktopView } = useCustomThemeV2();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const parsedDescription = parseDescription(description);
    const { isEvent } = useCheckFormType();

    useEffect(() => {
      setQuantity(productQuantity);
    }, [productQuantity, product]);

    const isEnabled = useIsEnabled();

    const onSubmit = async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        addToCart(
          {
            id: String(product.id) || "",
            productVariantName: product.title,
            productVariantID: product.variantID ?? String(product.id),
            productVariantPrice: parsePriceToInteger(product.amount, false),
            quantity,
            unitPrice: product.amount,
            productVariantImageURL: product.thumbnail,
            recurringIntervalName: product.paymentType,
            in_stock: product.in_stock,
          },
          quantity,
          undefined,
          true,
        );
        isEnabled &&
          showMessage("Success", "Product Successfully added to cart");
      } catch (error) {
        isEnabled &&
          showMessage("Error", "Something went wrong, please try again");
      } finally {
        closeModal();
      }
    };

    const handleIncrease = () => {
      if (quantity < (in_stock || Number.MAX_SAFE_INTEGER)) {
        setQuantity(quantity + 1);
      }
    };
    const handleDecrease = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };

    const closeModal = () => {
      onClose();
    };
    const modalWidth = thumbnail ? "980px" : "520px";

    const smallThumbnail = addSizeToImage(thumbnail || "", "large");

    const isIncreaseDisabled = (in_stock && quantity >= in_stock) || false;

    return (
      <GiveBaseModal
        open={open}
        title={title}
        width={isDesktopView ? modalWidth : "100%"}
        height="auto"
        onClose={closeModal}
        showFooter={false}
        headerRightContent={
          displayStatus === TFormStatusEnum.PUBLIC ? (
            <>
              <GiveIconButton
                bgColor="darken"
                variant="ghost"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                Icon={LinkSimple}
              />
              <PublicUrlShare
                variant="strict"
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                query={variantID}
              />
            </>
          ) : null
        }
      >
        <form data-testid="public_product_modal_container" onSubmit={onSubmit}>
          <Grid
            container
            gap="40px"
            sx={{
              gridTemplateColumns: isMobileView
                ? "repeat(1, 1fr)"
                : "repeat(2, 1fr)",
              flexWrap: isMobileView ? "wrap" : "nowrap",
            }}
            onSubmit={onSubmit}
          >
            {thumbnail && (
              <ProductImage
                src={smallThumbnail}
                alt={title}
                isMobile={isMobileView}
              />
            )}

            <ProductDetail>
              <GiveText variant="h3" color="primary">
                {title}
              </GiveText>
              <GiveText variant="bodyS" color="secondary">
                {parsedDescription}
              </GiveText>
              <GiveText variant="bodyS" color="primary">
                ${amount}{" "}
                {paymentType !== "once" && (
                  <StyledSpan>{paymentType}</StyledSpan>
                )}
              </GiveText>
              {isEvent && in_stock && (
                <GiveText variant="bodyS" sx={{ color: accentColor }}>
                  {in_stock} available
                </GiveText>
              )}
              <ProductActions>
                <Stack direction="row" gap="12px">
                  <QuantityInput
                    quantity={quantity}
                    handleDecrement={handleDecrease}
                    handleIncrement={handleIncrease}
                    disableAddition={isIncreaseDisabled}
                  />
                  <GiveButton
                    onClick={onSubmit}
                    size="large"
                    label={`Add to cart - $${amount}`}
                    sx={{
                      minWidth: isMobileView ? "230px" : "340px",
                      height: "42px",
                      background: accentColor,
                      borderColor: accentColor,
                    }}
                  />
                </Stack>
              </ProductActions>
            </ProductDetail>
          </Grid>
        </form>
      </GiveBaseModal>
    );
  },
);

export default PUBLIC_PRODUCT_ITEM_MODAL;

const ProductDetail = styled(Stack)({
  gap: "20px",
});
const ProductActions = styled(Stack)({
  gap: "12px",
  paddingTop: "24px",
  alignItems: "flex-start",
});

const ProductImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ isMobile }) => ({
  objectFit: "cover",
  width: isMobile ? "min(350px,100%)" : "400px",
  height: isMobile ? "350px" : "400px",
  borderRadius: "8px",
  flexShrink: 0,
}));

const StyledIcon = styled(GiveIconButton)({
  "&:hover": {
    background: "transparent !important",
  },
});

const StyledSpan = styled("span")({
  textTransform: "capitalize",
});
