import { TableColumnType } from "@common/Table";
import { ColumnBaseType } from "@common/Table/helpers";
import { useIterator } from "@common/Table/hooks";
import { TransactionTableTag } from "@common/Tag/TransactionTableTag";
import { Text, TruncateText } from "@common/Text";
import { getStatus } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { queryFunctionBuilder } from "@components/VirtualList/api";
import { items } from "@components/VirtualList/components/VirtualizedList";
import { DEFAULT_QUERY_CONFIG } from "@components/VirtualList/hooks/queries";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectSelectedMerchant,
  setSelectedDate,
  setSelectedMerchant,
} from "@redux/slices/merchantFilters";
import { selectQueryString } from "@redux/slices/search";
import { parseAmount } from "@utils/index";
import { format } from "date-fns";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { TRANSACTION_INFO_MODAL } from "modals/modal_names";
import { useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { TransferTableRow } from "../utils";
import { useTransferQueryString } from "./useTransferQueryString";

export const TRANSFERS_QUERY_KEY = "get-transfers-list";
export const prefetchOffset = 5;

type Page = {
  data: TransferTableRow[];
  nextCursor: number | null;
  total: number | null;
};

export const useTransfersTable = () => {
  const { isTablesOptimizationsEnabled } = useGetFeatureFlagValues();
  const scrollToTop =
    useRef<(index?: number, align?: "center" | "start" | "end") => void>(null);
  const page = useRef(1);
  const [sort, setSort] = useState<string>("-createdAt");
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const modal = useModal(TRANSACTION_INFO_MODAL);

  const columns = useMemo(
    () => [
      {
        key: "createdAt",
        columnWidth: 1 / 7,
        sortable: true,
        title: "Date",
        renderColumn: ({ row }: { row: TransferTableRow }) => {
          return (
            <Text color={palette.neutral[90]}>
              {row?.createdAt &&
                format(new Date(row?.createdAt * 1000), "MMM d, yyyy HH:mm")}
            </Text>
          );
        },
      },
      {
        key: "sender",
        columnWidth: 1 / 4.5,
        title: "Sender",
        renderColumn: ({ row }: { row: TransferTableRow }) => (
          <Stack spacing={0.5} alignItems="flex-start">
            <TruncateText color={palette.neutral[90]} lineClamp={1}>
              {row?.merchAccName}
            </TruncateText>
            <TruncateText
              lineClamp={1}
              fontWeight="book"
              fontSize={12}
              color={palette.neutral[70]}
            >
              {row?.parentAccName}
            </TruncateText>
          </Stack>
        ),
      },
      {
        key: "recipient",
        columnWidth: 1 / 3,
        title: "Recipient",
        renderColumn: ({ row }: { row: TransferTableRow }) => (
          <Stack spacing={0.5}>
            <Text color={palette.neutral[90]}>
              {row?.recipientAccountBankName}
            </Text>
            <Text fontWeight="book" color={palette.neutral[90]}>
              {row?.recipientAccountName}, ••••{" "}
              {row?.recipientAccountNumberLast4}
            </Text>
          </Stack>
        ),
      },
      {
        key: "cost",
        columnWidth: 1 / 6,
        sortable: true,
        title: "Amount (USD)",
        headerPosition: "right" as TableColumnType["headerPosition"],
        renderColumn: ({ row }: { row: TransferTableRow }) => (
          <Text color={palette.neutral[90]} width="100%" textAlign="end">
            {parseAmount((row?.cost || 0) / 100)}
          </Text>
        ),
      },
      {
        key: "sortOrderPriority",
        sortable: true,
        title: "Status",
        columnWidth: 1 / 8,
        headerPosition: "center" as TableColumnType["headerPosition"],
        columnType: "status" as ColumnBaseType,
        renderColumn: ({ row }: any) => (
          <Box
            width="100%"
            sx={{
              marginLeft: "8px",
              padding: "0 8px",
              "& div": { borderRadius: "50px" },
            }}
          >
            <TransactionTableTag
              type={getStatus(row).displayStatus?.toLowerCase() as any}
            />
          </Box>
        ),
      },
    ],
    [],
  );

  const { merchantId } = useGetCurrentMerchantId();
  const [loading, setLoading] = useState(false);
  const selectedMerchant = useAppSelector((state) =>
    selectSelectedMerchant(state, "transfer-merchants"),
  );
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, TRANSFERS_QUERY_KEY),
  );

  const { queryString } = useTransferQueryString();

  const selectedMerchantID = selectedMerchant?.accID || merchantId;

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading, refetch } =
    useInfiniteQuery(
      [TRANSFERS_QUERY_KEY, selectedMerchantID, queryString, searchQuery, sort],
      queryFunctionBuilder({
        queryString,
        rowsPerPage: 20,
        path: "transfers",
        sorting: sort,
        merchantId: selectedMerchantID,
        searchQuery,
      }),
      {
        ...(isTablesOptimizationsEnabled && {
          ...DEFAULT_QUERY_CONFIG,
        }),
        getNextPageParam: (lastPage: Page) => {
          return lastPage.nextCursor;
        },
      },
    );

  const uiData = useMemo(
    () =>
      data?.pages?.[0].data
        ? data?.pages?.map((row: any) => row?.data).flat() ?? []
        : [],
    [data],
  );


  const handleOnSort = (newSort: any) => {
    if (scrollToTop.current) {
      scrollToTop.current();
    }
    items.clear();

    setSort(newSort);
    page.current = 1;
  };

  const onEndReached = async () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage({
        pageParam: ++page.current,
      }).then(() => {
        if (scrollToTop.current) {
          scrollToTop.current((data?.pages?.length ?? 1) * 20);
        }
      });
    }
  };

  const { selectedRowIdx, onChangeSelectedRowItem, setSelectedRowIdx } =
    useIterator({
      dataLen: uiData.length,
    });

  useEffect(() => {
    // we are checking sooner than reaching last page, as fetching can take time,
    // and it is possible to click next on the modal fast without data being fetched
    if (
      uiData[selectedRowIdx + prefetchOffset] === undefined &&
      hasNextPage &&
      modal.visible
    )
      onEndReached();
  }, [selectedRowIdx, uiData]);

  const clickRow = ({
    index,
    row,
  }: {
    index: number;
    row: TransferTableRow;
  }) => {
    NiceModal.show(TRANSACTION_INFO_MODAL, {
      data: { ...row, id: row?.objID },
      isFirst: index === 0,
      isLast: uiData[index + 1] === undefined && !hasNextPage,
      setSelectedRow: onChangeSelectedRowItem,
    });
    setSelectedRowIdx(index);
  };

  useEffect(() => {
    if (selectedRowIdx > -1) {
      const trData = uiData[selectedRowIdx];
      NiceModal.show(TRANSACTION_INFO_MODAL, {
        data: { ...trData, id: trData?.objID },
        setSelectedRow: onChangeSelectedRowItem,
        isFirst: selectedRowIdx === 0,
        isLast: uiData[selectedRowIdx + 1] === undefined && !hasNextPage,
      });
    }
  }, [selectedRowIdx]);

  useEffect(() => {
    page.current = 1;
  }, [selectedMerchantID, queryString]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDate({ queryKey: "transfer-date", value: "" }));
      dispatch(
        setSelectedMerchant({ queryKey: "transfer-merchants", value: null }),
      );
    };
  }, []);

  return {
    columns,
    handleOnSort,
    uiData,
    scrollToTop,
    selectedRowIdx,
    onEndReached,
    clickRow,
    isFetchingNextPage,
    loading,
    isLoading,
  };
};
