import { ReactNode } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { CountryFlag } from "@common/CountryFlag";
import { ISectionItem } from "./hooks/useGetSectionItems";
import GiveText from "@shared/Text/GiveText";
import CopyButton from "./components/CopyButton";
import { parseAmount } from "@utils/index";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import { followLink } from "@hooks/common/documents/utils";

export const GiveSectionItem = ({ item }: { item: ISectionItem }) => {
  const linkHandler = () => {
    if (item.isLink) followLink(item.value as string, { target: "__blank" }) 
  };

  if (item.hidden) return <></>
  
  return (
    <Grid container item xs={12} spacing={2}>
      <CustomGridItem>
        <GiveText variant="bodyS" color="secondary">
          {item.title}
        </GiveText>
      </CustomGridItem>
      <CustomGridItem>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="flex-start"
            maxWidth={item.canBeCopied ? "85%" : "100%"}
          >
            {item.isPhoneNumber && typeof item.value === "string" && (
              <CountryFlag phoneNumber={item.value} />
            )}
            <Box
              component="span"
              display="flex"
              alignItems="center"
              onClick={linkHandler}
              width="100%"
              sx={{
                zIndex: 2,
                cursor: item.isLink ? "pointer" : "default",
              }}
            >
              <GiveTruncateText
                variant="bodyS"
                color="primary"
                lineClamp={item.hideOverflow ? 1 : 10}
              >
                {item.value ? (
                  <>
                    {item.isAmount ? parseAmount(+item.value) : item.value}{" "}
                    {item.suf && (
                      <span style={{ marginLeft: "3px" }}>{item.suf}</span>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </GiveTruncateText>
            </Box>
          </Stack>
          {item.canBeCopied && item.isLink && (
            <CopyButton text={item.value as string} />
          )}
        </Stack>
      </CustomGridItem>
    </Grid>
  );
};

const CustomGridItem = ({ children }: { children: ReactNode }) => (
  <Grid
    xs={6}
    sx={{
      padding: "8px 16px",
      alignContent: "center",
    }}
  >
    {children}
  </Grid>
);
