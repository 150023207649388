import Stack from "@mui/material/Stack";
import HFGiveIntegerInput from "@shared/HFInputs/HFGiveInput/HFGiveIntegerInput";
import HFGiveSelect from "@shared/HFInputs/HFGiveSelect/HFGiveSelect";
import { ControlledDatePicker } from "@sections/PayBuilder/Forms/DateAndLocation/components/ControlledDatePicker";
import { useFormContext } from "react-hook-form";

type Props = {
  value: string;
};

const FilterDateRenderer = ({ value }: Props) => {
  const { watch } = useFormContext();
  const values = watch();

  const minEndDate = new Date(values?.startDate);

  if (value === "is between") {
    return (
      <Stack mt={1} alignItems="center" spacing={1.5}>
        <ControlledDatePicker name="startDate" disableFuture />
        <ControlledDatePicker
          name="endDate"
          disableFuture
          minDate={minEndDate}
        />
      </Stack>
    );
  } else {
    return (
      <Stack mt={1} gap={1.5} direction="row" alignItems="stretch">
        <HFGiveIntegerInput name="count" placeholder="7" width="100%" />
        <HFGiveSelect
          fullWidth
          name="dayMonthYear"
          options={[
            { value: "days", label: "Days" },
            { value: "months", label: "Months" },
          ]}
        />
      </Stack>
    );
  }
};

export default FilterDateRenderer;
