import AlertIcon from "@assets/icons/AlertIcon";
import { Box, Stack, SxProps } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { ReactNode } from "react";

const defaultDescription =
  "The style of the form is editable from Advanced Builder only";

const StyleWarningMessage = ({
  title = "Attention",
  description = defaultDescription,
  renderAction,
  sx,
  strokeIconColor,
}: {
  title?: string;
  description?: string;
  renderAction?: () => ReactNode;
  sx?: SxProps;
  strokeIconColor?: string;
}) => {
  const { palette } = useAppTheme();

  return (
    <Stack
      direction="row"
      gap="20px"
      // alignItems="center"
      sx={{
        padding: "20px",
        marginX: "12px",
        borderRadius: "12px",
        backgroundColor: palette.primitive?.transparent["darken-5"],
        alignItems: "center",
        ...sx,
      }}
    >
      <Box>
        <AlertIcon
          {...(strokeIconColor && {
            stroke: strokeIconColor,
          })}
        />
      </Box>
      <Stack gap={1}>
        <GiveText
          fontSize="14px"
          fontWeight={573}
          sx={{ color: palette.primitive?.neutral[90] }}
        >
          {title}
        </GiveText>
        <GiveText fontSize="14px">{description}</GiveText>
        {renderAction?.()}
      </Stack>
    </Stack>
  );
};

export default StyleWarningMessage;
