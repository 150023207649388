import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import ProductPublicForm from "@containers/ProductPublicForm";
import { useGetPublicForm } from "./components/hooks/useGetPublicForm";
import PayBuilder from "./PayBuilder";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useCheckFormType from "./components/hooks/useCheckFormType";

const PublicProductWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isFetched, notFound } = useGetPublicForm();
  const { isNewCustomerViewForm } = useCheckFormType();

  useEffect(() => {
    if (isNaN(Number(id))) {
      navigate("/");
    }
  }, []);

  if (!isFetched) return <LoadingSpinner />;

  if (isNewCustomerViewForm || notFound) {
    return <PayBuilder />;
  }

  return <ProductPublicForm />;
};

export default PublicProductWrapper;
