import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import { Location_position } from "@sections/PayBuilder/provider/provider.type";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { GoogleMaps } from "@services/google-api";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import useLoadGoogleScript from "@hooks/google-api/useLoadGoogleScript";
import { Stack } from "@mui/material";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import GiveMapsAutocomplete from "@shared/GoogleMapsAutocomplete/GiveMapsAutocomplete";
import { HFCheckbox } from "@shared/HFInputs/HFGiveCheckbox/HFGiveCheckbox";
import HFGiveSelect from "@shared/HFInputs/HFGiveSelect/HFGiveSelect";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { getUSNames } from "@utils/country_dial_codes";

interface LocationSectionProps {
  locationPosition: Location_position;
}

const LocationSection = ({ locationPosition }: LocationSectionProps) => {
  const { methods } = usePayBuilderForm();
  const { setValue, watch, trigger } = methods;
  const values = watch();

  const { isLoaded } = useLoadGoogleScript();
  const handleLocationChange = (value: string) => {
    setValue("DateLocation.locationShortAddress", value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const { selectCountriesList } = useCountryCodes();
  const showStateFieldCountry = "US";

  const isManualAddress = values.DateLocation.isManualAddress;

  return (
    <>
      {locationPosition === "online" && (
        <HFGiveInput
          name="DateLocation.locationURL"
          placeholder="Enter URL"
          onChangeCustom={() => {
            trigger("DateLocation.locationURL");
          }}
        />
      )}
      {locationPosition === "onsite" && (
        <ErrorCatcher errorID="GooglePlacesAutocomplete">
          <GiveMapsAutocomplete
            disabled={isManualAddress}
            initialValue={values.DateLocation?.locationShortAddress}
            onValueChange={handleLocationChange}
            isLoaded={isLoaded}
          />
        </ErrorCatcher>
      )}
      {locationPosition === "onsite" &&
        !isManualAddress &&
        values.DateLocation.locationShortAddress && (
          <Stack direction="column" gap={2}>
            {!isLoaded ? (
              <LoadingSpinner />
            ) : (
              <ErrorCatcher errorID="GoogleMaps">
                <GoogleMaps
                  address={values.DateLocation.locationShortAddress as string}
                  isLoaded={isLoaded}
                />
              </ErrorCatcher>
            )}
          </Stack>
        )}

      {locationPosition === "onsite" && (
        <HFCheckbox
          name="DateLocation.isManualAddress"
          label="Enter address manually"
        />
      )}

      {isManualAddress && locationPosition === "onsite" && (
        <Stack py="16px" gap="16px">
          <HFGiveSelect
            name="DateLocation.locationAddress.country"
            label="Country/Region"
            placeholder="Select a country"
            options={selectCountriesList}
            contextualMenuProps={{
              useVirtualList: true,
            }}
          />
          <HFGiveInput
            name="DateLocation.locationAddress.line1"
            label="Address"
          />

          <HFGiveInput
            name="DateLocation.locationAddress.line2"
            label="Apartment, Suite etc (Optional)"
          />
          <Stack direction="row" gap="16px">
            <HFGiveInput
              name="DateLocation.locationAddress.city"
              label="City"
            />
            {values.DateLocation.locationAddress?.country ===
            showStateFieldCountry ? (
              <HFGiveSelect
                name="DateLocation.locationAddress.state"
                label="State"
                options={getUSNames().map((state) => ({
                  value: state,
                  label: state,
                }))}
              />
            ) : (
              <HFGiveInput
                name="DateLocation.locationAddress.state"
                label="Province"
              />
            )}
            <HFGiveInput
              name="DateLocation.locationAddress.zip"
              label="Zip code"
              type="number"
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default LocationSection;
