import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_ENVIRONMENT,
  debug: false,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration(), //Needed to enable replay for canvas elements
  ],

  // Tracing
  tracesSampleRate: 1.0,

  //Profiling
  profilesSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event) => {
    //Discard the event if its coming from local.development or local.staging
    if (event.request && event.request?.url?.includes("local.")) {
      return null; // Discard the event
    }
    return event;
  },
});
