import { Stack } from "@mui/material";
import { ArrowUpRight } from "@phosphor-icons/react";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { useLocation, useNavigate } from "react-router-dom";

const ADBStylerLink = () => {
  const { palette } = useAppTheme();
  const { methods } = usePayBuilderForm();
  const navigate = useNavigate();
  const values = methods.watch();
  const location = useLocation();
  const productId = location.state?.id;

  const handleOpenADB = () => {
    navigate("/merchant/new-advanced-builder", {
      state: {
        productId,
        productTitle: values.About.heading,
        description: values.About.description,
        campaign: "standard",
        imageURL: values.About.selectedImage,
        position: values.About.assetPosition,
        paymentLogoURL: values.Style.logo,
      },
    });
  };
  return (
    <Stack gap={2} py={2}>
      <GiveText
        fontSize="18px"
        sx={{
          fontWeight: 400,
          color: palette.primitive?.neutral[90],
        }}
      >
        Edit in the Advanced Builder
      </GiveText>
      <GiveText
        fontSize="14px"
        sx={{
          color: palette.primitive?.neutral[60],
        }}
      >
        You can make further customizations and add more content to your page
        with our Pro Toolbox
      </GiveText>
      <GiveButton
        label="Open Advanced Builder"
        endIcon={<ArrowUpRight />}
        onClick={handleOpenADB}
        sx={{
          backgroundColor: palette.primitive?.transparent["darken-5"],
          borderRadius: "40px",
          border: "none",
          color: palette.primitive?.neutral[90],
          "&:hover": {
            color: "initial",
          },
        }}
      />
    </Stack>
  );
};

export default ADBStylerLink;
