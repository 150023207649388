import { QKEY_LIST_FUNDRAISERS } from "@constants/queryKeys";
import { useCachedList } from "@hooks/common/useCachedList";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { useStateEffect } from "@hooks/customReactCore";
import { useAppSelector } from "@redux/hooks";
import { selectQueryFilters, sorting as sortingReducer } from "@redux/slices/fundraisers";
import { selectQueryString } from "@redux/slices/search";
import { useGetFundraisers } from "@services/api/products/fundraisers";
import { encodedQueryFilterMap } from "@services/filtering";
import { useDynamicTheme } from "@theme/hooks/useDynamicTheme";
import React, { useEffect, useMemo, useRef } from "react";
import { QueryClient } from "react-query";

const useFundraisers = () => {
  const queryFilters = useAppSelector(selectQueryFilters);
  const { isMobileView } = useDynamicTheme();
  const sorting = useAppSelector(sortingReducer);
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_FUNDRAISERS),
  );

  const { allData, invalidateCache } = useCachedList(QKEY_LIST_FUNDRAISERS);

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  const loadingRef = useRef<boolean>(false);


  const newSorting = useMemo(() => {
    let _s = sorting;

    if (_s && _s === "-name") {
      return (_s += ",-accID");
    }
    return _s;
  }, [sorting]);

  const { page, setPage } = usePagination(0, queryFilter.products);
  const queryString = queryFilter.products ? `%3B${queryFilter.products}` : "";

  const { data, isError, isLoading, error } = useGetFundraisers(
    {
      queryString,
      page,
      sorting: newSorting,
      searchQuery,
    },
    {
      refetchOnWindowFocus: false,

      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (isMobileView) invalidateCache();
  }, [ searchQuery, queryString])

  useStateEffect(() => {
    setPage(1);
  }, [newSorting, searchQuery, queryString]);

  const usedData = isMobileView ? allData : data?.data ?? [];

  return {
    isError,
    page,
    rowsPerPage: ROWS_PER_PAGE,
    currentPageRows: usedData,
    handlePageChange,
    totalRows: data?.total ?? 0,
    setPage: () => setPage((current) => current + 1),
    setPageDispatcher: setPage,
    allRows: usedData,
    loadingRef,
    isLoading,
    error,
    state: {
      isEmpty: !queryString && !searchQuery && data?.total === 0,
      isError,
    },
  };
};

export const refetchListFundraisers = (queryClient: QueryClient) => {
  queryClient.refetchQueries("list-all-fundraisers");
};

export default useFundraisers;
