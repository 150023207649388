import { useCachedList } from "@hooks/common/useCachedList";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { useStateEffect } from "@hooks/customReactCore";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  disableDateFilter,
  selectQueryFilters,
  sorting as sortingReducer,
} from "@redux/slices/fundraisers";
import { selectQueryString, setSearchQueryByKey } from "@redux/slices/search";
import { useGetProductsByType } from "@services/api/products/events";
import { mapTypeToQueryKey } from "@services/api/products/queryFactory";
import { encodedQueryFilterMap } from "@services/filtering";
import { useCallback, useMemo, useRef } from "react";
import { ProductData, TCampaignType } from "../types";

type Props = {
  type: TCampaignType;
};

export const useGetProductList = ({ type }: Props) => {
  const dispatch = useAppDispatch();
  const queryFilters = useAppSelector(selectQueryFilters);
  const sorting = useAppSelector(sortingReducer);
  const queryKey = mapTypeToQueryKey[type];

  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, queryKey),
  );
  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  const { allData, invalidateCache } = useCachedList(queryKey, true);
  const { page, setPage } = usePagination(0, queryFilter?.products);
  const loadingRef = useRef<boolean>(false);
  const queryString = queryFilter.products ? `%3B${queryFilter.products}` : "";

  const { data, isLoading, isError } = useGetProductsByType(type)(
    {
      queryString,
      page,
      sorting,
      searchQuery,
    },
    {
      refetchOnWindowFocus: false,

      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleResetFilters = () => {
    dispatch(disableDateFilter());
  };

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(
        setSearchQueryByKey({
          queryKey: queryKey || "",
          params: {
            value,
          },
        }),
      );
    },
    [queryKey],
  );

  useStateEffect(() => {
    invalidateCache();
    setPage(1);
  }, [sorting, searchQuery, queryString, type]);

  const usedData = allData ?? [];

  return {
    data,
    isLoading,
    isError,
    page,
    setPage: () => setPage((current) => current + 1),
    setPageDispatcher: setPage,
    handlePageChange,
    totalRows: data?.total ?? 0,
    loadingRef,
    rowsPerPage: ROWS_PER_PAGE,
    allRows: usedData as ProductData[],
    queryKey,
    searchQuery,
    hasFilters: Boolean(queryString),
    handleResetFilters,
    handleSearch,
  };
};
