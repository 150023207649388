import { Container, styled } from "@mui/material";
import { TFormStatus } from "./types";

const PageContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "displayStatus",
})<{ displayStatus?: TFormStatus }>(({ displayStatus }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  minHeight: displayStatus === "public" ? "100dvh" : "80dvh",
  "& .MuiContainer-root": {
    ...(displayStatus && displayStatus == "draft"
      ? {
          paddingLeft: "20px",
          paddingRight: "20px",
          maxWidth: "100%",
        }
      : {
          paddingLeft: "40px",
          paddingRight: "40px",
          maxWidth: "1090px",
          paddingBottom: "24px",
        }),
  },
}));

export default PageContainer;
