import { Box, Stack, BoxProps } from "@mui/material";
import { ChatsCircle, DotsThree, PencilSimple } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { isEmpty, isFunction } from "lodash";
import { ReactNode } from "react";

interface Props {
  leftTitle?: string;
  tag?: ReactNode;
  children?: ReactNode;
  height?: string;
  actions?: {
    handleOpenConversation?: (e: React.MouseEvent) => void;
    handleEdit?: () => void;
    handleDotsThree?: (e: React.MouseEvent<HTMLElement>) => void;
  };
  disabeledActions?: {
    handleOpenConversation?: {
      hidden?: boolean;
      disabled?: boolean;
      tooltipTitle?: string;
      disableTooltip?: boolean;
    };
    handleEdit?: {
      hidden?: boolean;
      disabled?: boolean;
      tooltipTitle?: string;
      disableTooltip?: boolean;
    };
    handleDotsThree?: {
      hidden?: boolean;
      disabled?: boolean;
      tooltipTitle?: string;
      disableTooltip?: boolean;
    };
  };
  sx?: BoxProps;
  childrenContainerSx?: BoxProps;
}

function SectionCardBase({
  leftTitle,
  tag,
  children,
  actions,
  height = "auto",
  sx,
  childrenContainerSx,
  disabeledActions,
}: Props) {
  const { isMobileView } = useCustomThemeV2();
  const rightHeadingItems = [
    {
      Icon: ChatsCircle,
      onClick: actions?.handleOpenConversation,
      hidden:
        disabeledActions?.handleOpenConversation?.hidden ??
        !isFunction(actions?.handleOpenConversation),
      disabled: disabeledActions?.handleOpenConversation?.disabled || false,
      tooltipTitle:
        disabeledActions?.handleOpenConversation?.tooltipTitle || "",
      disableTooltip:
        disabeledActions?.handleOpenConversation?.disableTooltip ?? true,
    },
    {
      Icon: PencilSimple,
      onClick: actions?.handleEdit,
      hidden:
        disabeledActions?.handleEdit?.hidden ??
        !isFunction(actions?.handleEdit),
      disabled: disabeledActions?.handleEdit?.disabled || false,
      tooltipTitle: disabeledActions?.handleEdit?.tooltipTitle || "",
      disableTooltip: disabeledActions?.handleEdit?.disableTooltip ?? true,
    },
    {
      Icon: DotsThree,
      onClick: actions?.handleDotsThree,
      hidden:
        disabeledActions?.handleDotsThree?.hidden ??
        !isFunction(actions?.handleDotsThree),
      disabled: disabeledActions?.handleDotsThree?.disabled || false,
      tooltipTitle: disabeledActions?.handleDotsThree?.tooltipTitle || "",
      disableTooltip: disabeledActions?.handleDotsThree?.disableTooltip ?? true,
    },
  ];

  return (
    <Stack sx={sx} height={height} width="100%" gap={2}>
      {leftTitle && (
        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          width="100%"
        >
          <Stack direction="row" gap="10px">
            <GiveText>{leftTitle}</GiveText>
            {tag && tag}
          </Stack>
          {!isEmpty(actions) && (
            <Stack gap="10px" flexDirection="row" alignItems="center">
              {rightHeadingItems?.map(
                (
                  {
                    Icon,
                    onClick,
                    hidden,
                    disabled,
                    tooltipTitle,
                    disableTooltip,
                  },
                  idx,
                ) => {
                  if (hidden) return null;
                  return (
                    <GiveTooltip
                      width="compact"
                      alignment="left"
                      title={tooltipTitle}
                      color="default"
                      disableHoverListener={disableTooltip}
                      key={idx}
                    >
                      <GiveIconButton
                        sx={{
                          cursor: "pointer",
                        }}
                        variant="ghost"
                        onClick={onClick}
                        Icon={Icon}
                        disabled={disabled}
                      />
                    </GiveTooltip>
                  );
                },
              )}
            </Stack>
          )}
        </Stack>
      )}

      {children && (
        <ChildContainer sx={childrenContainerSx}>{children}</ChildContainer>
      )}
    </Stack>
  );
}

export default SectionCardBase;

const ChildContainer = styled(Box)(({ theme }) => {
  return {
    borderRadius: `${theme?.customs?.radius?.medium}px`,
    width: "100%",
    padding: "20px",
    backgroundColor: theme.palette.background.paper,

    "& > .MuiGrid-root": {
      paddingTop: "8px",
    },
  };
});
