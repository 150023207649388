import { customInstance } from "@services/api";
import { UseQueryOptions, useQuery } from "react-query";
import { useGetCurrentMerchantId } from "@hooks/common";
import { ProductParams } from "./products/queryFactory";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { checkPortals } from "@utils/routing";
import { AssigneesType } from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";

export const getAllTeamMembers = ({
  id,
  queryString,
  page,
  sorting,
  searchQuery,
  memberStatus,
}: ProductParams & { id: number }) => {
  let baseURL = `/accounts/${id}/members?sort=${sorting || "-user.accID"}`;
  if (searchQuery) baseURL += `&q="${searchQuery}"`;
  if (page) baseURL += `&page=${page}&max=${ROWS_PER_PAGE}`;
  const filters = [];
  if (memberStatus && memberStatus === "joined")
    filters.push(`memberStatus:"joined"`);
  if (filters.length > 0) baseURL += `&filter=${filters.join("%3B")}`;

  return customInstance({
    url: baseURL,
    method: "GET",
  });
};
function correctURL(url: string) {
  const parts = url.split("?");

  if (parts.length <= 2) {
    return url;
  }

  return parts[0] + "?" + parts.slice(1).join("&");
}

type GetUnderwritingProps = {
  id: number;
  searchQuery?: string;
  isEnterprisePortal?: boolean;
  MID?: string | number;
  type?: AssigneesType;
  query?: string;
};

const getUnderwriters = ({
  id,
  searchQuery,
  isEnterprisePortal,
  MID,
  type,
  query,
}: GetUnderwritingProps) => {
  let baseURL = `/accounts/${id}/underwriters`;
  if (isEnterprisePortal) {
    baseURL = `/accounts/${MID}/members?sort=-createdAt&page=1&max=20`;
  }
  if (type === "risk") {
    baseURL = `/accounts/${MID}/members?filter=(roleName:"risk_manager")`;
  }
  if (type === "sales") {
    baseURL = `/accounts/${MID}/members?filter=(roleName:"sales_associate",roleName:"sales_executive")`;
  }
  if (type === "members") {
    baseURL = `/accounts/${MID}/members?sort=-user.accID`;
  }
  if (query) {
    baseURL = `/accounts/${MID}/${query}`;
  }
  if (searchQuery) baseURL += `?q="${searchQuery}"`;

  return customInstance({
    url: correctURL(baseURL),
    method: "GET",
  });
};

export const useGetAllTeamMembers = (
  params: ProductParams,
  id: number,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  const dispatch = useAppDispatch();

  const { data, error, isLoading, isFetched, isFetching } = useQuery(
    [
      "get-all-team-members",
      id,
      params.sorting,
      params.searchQuery,
      params.page,
    ],
    async () => {
      const data = await getAllTeamMembers({ id, ...params });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 2,
      ...options,
      onError(err) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              "get-all-team-members": true,
            }),
          );
          if (options?.onError) {
            options.onError(err);
          }
        }
      },
    },
  );

  return { data, error, isLoading, isFetched, isFetching };
};

export const useGetAccUnderwriters = (
  params: {
    searchQuery?: string;
  },
  merchantId: number,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
  type?: AssigneesType,
  query?: string,
) => {
  const dispatch = useAppDispatch();
  const { isEnterprisePortal } = checkPortals();
  const { merchantId: MID } = useGetCurrentMerchantId();
  const { data, error, isLoading, isFetched, isFetching } = useQuery(
    [
      "get-underwriters",
      merchantId,
      params.searchQuery,
      isEnterprisePortal,
      MID,
      type,
      query,
    ],
    async () => {
      const data = await getUnderwriters({
        id: merchantId,
        searchQuery: params?.searchQuery,
        isEnterprisePortal,
        MID,
        type,
        query,
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 2,
      ...options,
      onError(err) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              "get-underwriters": true,
            }),
          );
          if (options?.onError) {
            options.onError(err);
          }
        }
      },
    },
  );

  return { data, error, isLoading, isFetched, isFetching };
};
export const useGetTeamMemberById = (id: string) => {
  const { merchantId } = useGetCurrentMerchantId();

  const getMember = (accID: number, id: string) => {
    return customInstance({
      url: `/accounts/${accID}/members/${id}`,
      method: "GET",
    });
  };

  const getTeamMemberById = (id: string) => {
    return useQuery(
      "get-event-by-id",
      async () => {
        const memberData = await getMember(merchantId, id);
        return { memberData };
      },
      { refetchOnWindowFocus: false },
    );
  };

  const { data: teamMemberData, error, isLoading } = getTeamMemberById(id);

  return { teamMemberData, error, isLoading };
};
