import { Stack, StackProps } from "@mui/material";
import LazyLoad from "react-lazy-load";
import { styled } from "@mui/material/styles";
import { TruncateText } from "@common/Text";
import { useImageSizeHandler } from "@hooks/common/useImageHandler";

export const CARD_CONTENT_HEIGHT = 80;

interface BaseCampaignCardProps {
  name?: string;
  imageURL?: string;
  width?: string;
  isDraft?: boolean;
}

export const BaseCampaignCard = ({
  imageURL,
  name,
  width = "30%",
  isDraft,
}: BaseCampaignCardProps) => {
  const { mainImage, handleImageError } = useImageSizeHandler({
    imageURL,
    size: "small",
  });

  return (
    <StyledNameImgContainer width={width}>
      <LazyLoad
        width={80}
        height={CARD_CONTENT_HEIGHT}
        offset={1}
        threshold={0.95}
      >
        <Image
          alt={name}
          src={mainImage}
          onError={handleImageError}
          width={80}
          height={CARD_CONTENT_HEIGHT}
          decoding="async"
        />
      </LazyLoad>

      <Stack gap="4px">
        <StyledName variant={"headline"} lineClamp={4}>
          {name}
        </StyledName>
        {isDraft && <DraftText lineClamp={1}>Draft</DraftText>}
      </Stack>
    </StyledNameImgContainer>
  );
};

const StyledNameImgContainer = styled(Stack)<StackProps & { width: string }>(
  ({ theme, width }) => ({
    width,
    gap: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },
  }),
);

const Image = styled("img")(({ theme }) => ({
  borderRadius: "4px",
  height: CARD_CONTENT_HEIGHT,
  width: 80,
  display: "block",
  objectFit: "cover",
  [theme.breakpoints.down("sm")]: {
    height: 64,
    width: 64,
  },
}));

const StyledName = styled(TruncateText)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "19.2px",
});

const DraftText = styled(TruncateText)({
  fontSize: "12px",
  fontWeight: 357,
  color: "#D80D0D",
});
