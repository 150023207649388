import moment from "moment";

export function generateTimeOptions(inputDate?: any) {
  const now = moment();
  const times = [];

  const startOfDay = moment().startOf("day");
  const endOfDay = moment().endOf("day");

  // If no inputDate is provided, generate the entire day
  if (!inputDate) {
    const currentTime = startOfDay;
    while (currentTime <= endOfDay) {
      times.push(currentTime.format("hh:mm A"));
      currentTime.add(15, "minutes");
    }
    return times;
  }

  const isToday = moment(inputDate).isSame(now, "day");
  let currentTime = startOfDay;

  if (isToday) {
    // If the date is today, start from the next 15-minute divisible interval
    const nextDivisibleMinute = Math.ceil(now.minute() / 15) * 15;
    currentTime = now.clone().minute(nextDivisibleMinute).second(0);

    // Ensure the time is in the future
    if (currentTime.isBefore(now)) {
      currentTime.add(15, "minutes");
    }
  }

  while (currentTime <= endOfDay) {
    times.push(currentTime.format("hh:mm A"));
    currentTime.add(15, "minutes");
  }

  return times;
}
