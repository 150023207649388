import { CheckedFilledCircleIcon } from "@assets/icons";
import { Box, Divider, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { CircleDashed } from "@phosphor-icons/react";
import { Button } from "@common/Button";
import { format } from "date-fns";

const DisputeCircleDashedIcon = () => {
  return (
    <Box
      width={24}
      height={24}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bgcolor={palette.filled.orange}
    >
      <CircleDashed size={12} color={palette.neutral.white} />
    </Box>
  );
};

interface Props {
  data: {
    createdAt: number;
    title: string;
    notes: string;
    subNotes: string;
    fileEvidence?: string;
  };
  handleViewEvidence?: () => void;
  isLast?: boolean;
}

const DisputeCaseHistoryCard = ({
  data,
  handleViewEvidence,
  isLast,
}: Props) => {
  const statusIcons = {
    done: <CheckedFilledCircleIcon width={24} height={24} />,
    ongoing: <DisputeCircleDashedIcon />,
  };

  return (
    <>
      <Stack gap={1} data-testid="dispute-history-card">
        <Text color={palette.neutral[70]} lineHeight="16.8px">
          {format(new Date(data?.createdAt * 1000), "MMM d, yyyy, HH:mm")}
        </Text>
        <StyledCardContainer>
          <Stack direction="row" gap={2}>
            {data?.fileEvidence ? statusIcons["done"] : statusIcons["ongoing"]}
            <Stack spacing={0.5}>
              <Text color={palette.neutral[70]} lineHeight="16.8px">
                {data?.title}
              </Text>
              {data?.notes && (
                <Text
                  fontSize={16}
                  color={palette.neutral[80]}
                  lineHeight="16px"
                >
                  {data?.notes}
                </Text>
              )}
              <Text color={palette.neutral[80]} lineHeight="16.8px">
                {data?.subNotes}
              </Text>
            </Stack>
          </Stack>
          {data?.fileEvidence && (
            <>
              <Divider color={palette.neutral[30]} />
              <StyledButton
                size="medium"
                background="tertiary"
                onClick={handleViewEvidence}
              >
                View Evidence
              </StyledButton>
            </>
          )}
        </StyledCardContainer>
      </Stack>
      {!isLast && <StyledVerticalLine />}
    </>
  );
};

const StyledCardContainer = styled(Stack)(() => ({
  borderRadius: "8px",
  padding: "16px",
  backgroundColor: palette.neutral[10],
  gap: "16px",
}));

const StyledButton = styled(Button)({
  width: "fit-content",
  height: "fit-content",
  padding: 0,
  color: palette.neutral[80],
  fontWeight: "350",
});

const StyledVerticalLine = styled(Box)(({ theme }) => ({
  height: 28,
  width: 1,
  backgroundColor: palette.gray[100],
  marginLeft: theme.spacing(2),
}));

export default DisputeCaseHistoryCard;
