import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { MerchantStatsType } from "@hooks/enterprise-api/account/useGetMerchants";
import { useAppDispatch } from "@redux/hooks";
import {
  setMerchantStatusName,
  setMerchantTab,
} from "@redux/slices/enterprise/merchants";
import { updateSorting } from "@redux/slices/fundraisers";
import { checkPortals } from "@utils/routing";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useEffect } from "react";

interface Props {
  stats: MerchantStatsType;
  isProvider?: boolean;
}

export const TabNames = {
  all: "-portfolio",
  underwriting: "-underwriting",
  invited: "-invited",
  onboarding: "-onboarding",
  risk: "-risk",
  sponsor: "-sponsor",
};

export const useTabs = ({ stats, isProvider = false }: Props) => {
  const {
    isSponsorApprovalEnabled,
    isMerchantInvitesEnabled,
    isOnboardingViewEnabled,
  } = useGetFeatureFlagValues();
  const dispatch = useAppDispatch();
  const { isEnterprisePortal, isMerchantsTable } = checkPortals();

  const {
    risk_monitoring,
    merchant_triggers,
    risk_activity,
    merchant_underwriting,
    merchant_underwriting_statuses,
  } = useEnterprisePermissions();

  useEffect(() => {
    return () => {
      dispatch(setMerchantStatusName(""));
      dispatch(setMerchantTab(TabNames.all));
    };
  }, []);

  const setSorting = (sort = "-createdAt") => {
    dispatch(updateSorting(sort));
  };

  const cleanFilter = () => dispatch(setMerchantStatusName(""));
  const tabs = [
    {
      label: `All (${stats?.countTotalAquirer || 0})`,
      value: TabNames.all,
      onClickItem: () => {
        cleanFilter();
        dispatch(setMerchantTab(TabNames.all));
        setSorting();
      },
      hidden: false,
    },
    {
      label: `Invited (${stats?.countInvited})`,
      value: TabNames.invited,
      onClickItem: () => {
        cleanFilter();
        dispatch(setMerchantTab(TabNames.invited));
        setSorting("-ownerInviteLastSentAt");
      },
      hidden: !isMerchantsTable || !isMerchantInvitesEnabled,
    },
    {
      label: `Onboarding (${stats?.countOnboarding})`,
      value: TabNames.onboarding,
      onClickItem: () => {
        cleanFilter();
        dispatch(setMerchantTab(TabNames.onboarding));
        setSorting();
      },
      hidden: !isMerchantsTable || !isOnboardingViewEnabled,
    },
    {
      label: `Underwriting (${stats?.countAquirerUnderwriting || 0})`,
      value: TabNames.underwriting,
      onClickItem: () => {
        cleanFilter();
        dispatch(setMerchantTab(TabNames.underwriting));
        setSorting();
      },
      hidden: !(merchant_underwriting || merchant_underwriting_statuses),
    },
    {
      label: `Sponsor (${stats?.sponsorCount || 0})`,
      value: TabNames.sponsor,
      onClickItem: () => {
        cleanFilter();
        dispatch(setMerchantTab(TabNames.sponsor));
        setSorting("-lastInternalApprovalAt");
      },
      hidden: isProvider || isEnterprisePortal || !isSponsorApprovalEnabled,
    },
    {
      label: `Risk (${stats?.countRisk || 0})`,
      value: TabNames.risk,
      onClickItem: () => {
        cleanFilter();
        dispatch(setMerchantTab(TabNames.risk));
        setSorting();
      },
      hidden:
        !(risk_monitoring || merchant_triggers || risk_activity) || isProvider,
    },
  ];

  const hasEntities = stats?.countTotalAquirer > 0;

  return {
    tabs,
    hasEntities,
  };
};
