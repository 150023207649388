import React from "react";
import { Box, BoxProps } from "@mui/material";
import {
  animationAssets,
  DEFAULT_ANIMATION,
  pageAnimations,
  PageAnimationsType,
} from "./animations";
import { VIDEO_BASE_URL } from "@constants/constants";
import { styled } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const BANNER_BREAKPOINT = 2050;

interface GiveAnimationWrapperProps extends BoxProps {
  videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
  page: PageAnimationsType;
  fullscreen?: boolean;
}
const GiveAnimationWrapper: React.FC<GiveAnimationWrapperProps> = ({
  children,
  videoProps,
  page,
  fullscreen,
  ...rest
}) => {
  const { isMobileView } = useCustomThemeV2();
  const device = isMobileView ? "mobile" : "desktop";

  function getAnimationForPage(page: PageAnimationsType) {
    const animationKey = pageAnimations[page];
    if (!animationKey) return DEFAULT_ANIMATION[device];

    const animationSrc = fullscreen
      ? animationAssets[animationKey]["fullscreen"]
      : animationAssets[animationKey][device];

    return `${VIDEO_BASE_URL}/${animationSrc}`;
  }

  return (
    <AnimationContainer {...rest}>
      <StyledVideo
        autoPlay
        muted
        loop
        playsInline
        preload="none"
        className="banner-video"
        {...videoProps}
      >
        <source src={getAnimationForPage(page)} type="video/mp4" />
      </StyledVideo>
      <ContentContainer>{children}</ContentContainer>
    </AnimationContainer>
  );
};

export default GiveAnimationWrapper;

const StyledVideo = styled("video")(() => ({
  overflow: "hidden",
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  top: 0,
  left: 0,
  zIndex: 0,
  borderRadius: "inherit",
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: "relative",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1920px",
  [theme.breakpoints.up(BANNER_BREAKPOINT)]: {
    padding: "64px 64px 0 64px",
  },
}));
const AnimationContainer = styled(Box)(() => ({
  minHeight: "300px",
  display: "flex",
  justifyContent: "center",
}));
