import { Box, CircularProgress, Stack } from "@mui/material";
import {
  DotsThree,
  DownloadSimple,
  Eye,
  Trash,
  WarningCircle,
  X,
} from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { GiveTagInput } from "@shared/Inputs/GiveEditableTag";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import React, { useState } from "react";
import { downloadDocument } from "@hooks/common/documents/utils";
import { useFilePermissions } from "@hooks/common/documents";
import useTagHandler from "features/Merchants/MerchantSidePanel/GiveMerchantFile/hooks/useTagHandler";
import { showMessage } from "@common/Toast";
import { checkPortals } from "@utils/routing";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import { GiveUploadItemProps, Size, State } from "./types";

const GiveUploadItem = ({
  merchantId,
  errorMessage,
  byMessage,
  dateMessage,
  state,
  size = Size.DEFAULT,
  isMobile,
  isNotice = false,
  noticeContent,
  value,
  setValue,
  fileData,
  isHideDelete = false,
  onDelete = () => null,
  tagType,
  setTagWithApi = false,
  handlePreview,
}: GiveUploadItemProps) => {
  const { palette } = useAppTheme();
  const { isEnterprisePortal } = checkPortals();
  const [hovered, setHovered] = useState(false);
  const [tagValue, setTagValue] = useState(value);
  const { isUpdateAllowed, isDeleteAllowed } =
    useFilePermissions(isEnterprisePortal);

  const handleDownloadClick = () => {
    if (fileData)
      downloadDocument({
        fileName: fileData.fileName,
        fileURL: fileData.fileURL,
      });
  };

  const { editTagHandler } = useTagHandler();

  const handlePreviewClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!fileData?.fileURL) return;
    handlePreview?.(fileData);
  };

  //if we want to set the tag of an already uploaded file we pass setTagWithApi
  const onSaveTag = (tag: string) => {
    if (!tag?.trim()) {
      showMessage("Error", "The uploaded file name can't be empty");
      setTagValue(value ?? "");
    } else {
      if (!setTagWithApi) return setValue(tag);
      editTagHandler({
        merchantID: merchantId,
        documentID: fileData?.id,
        data: { tag: tagValue },
      });
    }
  };

  return (
    <Stack
      gap="12px"
      padding="12px"
      width="100%"
      borderBottom={`1px solid ${palette.border?.primary}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="12px"
        alignItems={isMobile && size === Size.DEFAULT ? "flex-start" : "center"}
      >
        <Stack direction="column" gap="8px">
          <Stack direction="row" gap="12px" alignItems="center">
            {state === State.LOADING && (
              <CircularProgress
                size={14}
                sx={{ color: palette.text.primary }}
              />
            )}
            <GiveTruncateText
              lineClamp={1}
              variant={size === Size.DEFAULT ? "bodyS" : "bodyXS"}
              color="primary"
              sx={{ wordBreak: "break-all" }}
            >
              {fileData?.fileName}
            </GiveTruncateText>
            {Size.DEFAULT === size && state === State.UPLOADED && !isMobile && (
              <GiveTagInput
                value={tagValue}
                setValue={setTagValue}
                isMobile={isMobile}
                isUpdateAllowed={isUpdateAllowed}
                type={tagType}
                onSaveTag={onSaveTag}
              />
            )}
          </Stack>
          <Description
            state={state}
            errorMessage={errorMessage}
            byMessage={byMessage}
            dateMessage={dateMessage}
            size={size}
            isMobile={isMobile}
            value={tagValue}
            setValue={setTagValue}
            isUpdateAllowed={isUpdateAllowed}
            tagType={tagType}
            onSaveTag={onSaveTag}
          />
        </Stack>
        <Right
          state={state}
          hovered={hovered}
          isMobile={isMobile}
          handlePreviewClick={handlePreviewClick}
          handleDownloadClick={handleDownloadClick}
          handleDeleteClick={onDelete}
          isHideDelete={isHideDelete}
          isDeleteAllowed={isDeleteAllowed}
        />
      </Stack>
      {state === State.UPLOADED && isNotice && (
        <Box
          sx={{
            background: palette.surface?.secondary,
            padding: "8px",
            borderRadius: "4px",
            textAlign: "center",
          }}
        >
          <GiveText variant="bodyXS" color="secondary">
            {noticeContent}
          </GiveText>
        </Box>
      )}
    </Stack>
  );
};

export default GiveUploadItem;

const Right = ({
  hovered,
  state,
  isMobile,
  handlePreviewClick,
  handleDownloadClick,
  handleDeleteClick,
  isHideDelete = false,
  isDeleteAllowed,
}: {
  state: (typeof State)[keyof typeof State];
  hovered?: boolean;
  isMobile?: boolean;
  handlePreviewClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  handleDownloadClick: () => void;
  handleDeleteClick: () => void;
  isHideDelete?: boolean;
  isDeleteAllowed?: boolean;
}) => {
  const { palette } = useAppTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };
  const options: {
    text: string;
    hidden: boolean;
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    tooltipTitle?: string;
    disableTooltip?: boolean;
    type?: "default" | "destructive" | "label" | "divider";
  }[] = [
    {
      text: "Preview",
      hidden: false,
      disabled: false,
      onClick: handlePreviewClick,
    },
    {
      text: "Download",
      hidden: false,
      disabled: false,
      onClick: handleDownloadClick,
    },
    {
      text: "Delete",
      hidden: isHideDelete,
      disabled: !isDeleteAllowed,
      onClick: handleDeleteClick,
      tooltipTitle: DELETE_DENY_MESSAGE,
      disableTooltip: isDeleteAllowed,
      type: "destructive",
    },
  ];

  if (state === State.LOADING || state === State.FAILED) {
    return <GiveIconButton Icon={X} size="small" />;
  }

  if (isMobile) {
    return (
      <>
        <GiveIconButton
          Icon={DotsThree}
          size="small"
          variant="ghost"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(e.currentTarget)
          }
        />
        <ContextualMenu
          handleClose={onCloseMenu}
          anchorEl={anchorEl}
          options={options}
          color={"primary"}
          texture={"solid"}
        />
      </>
    );
  }
  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    actionCb: () => void,
  ) => {
    e.stopPropagation();
    actionCb();
  };
  return hovered ? (
    <Stack direction="row" gap="4px">
      <GiveIconButton Icon={Eye} size="small" onClick={handlePreviewClick} />
      <GiveIconButton
        Icon={DownloadSimple}
        size="small"
        onClick={(e) => handleButtonClick(e, handleDownloadClick)}
      />
      {!isHideDelete && (
        <GiveTooltip
          width="compact"
          alignment="left"
          title={DELETE_DENY_MESSAGE}
          color="default"
          disableHoverListener={isDeleteAllowed}
        >
          <GiveIconButton
            Icon={Trash}
            size="small"
            color={palette.primitive?.error[50]}
            onClick={(e) => handleButtonClick(e, handleDeleteClick)}
          />
        </GiveTooltip>
      )}
    </Stack>
  ) : null;
};
const Description = ({
  errorMessage,
  byMessage,
  dateMessage,
  state,
  size,
  isMobile,
  value,
  setValue,
  isUpdateAllowed,
  tagType,
  onSaveTag,
}: {
  errorMessage?: string;
  byMessage?: string;
  dateMessage?: string;
  state: (typeof State)[keyof typeof State];
  size: (typeof Size)[keyof typeof Size];
  isMobile?: boolean;
  value: string;
  setValue: (value: string) => void;
  isUpdateAllowed: boolean;
  tagType?: "editable" | "form-field";
  onSaveTag?: (value: string) => void;
}) => {
  const { palette } = useAppTheme();
  if (state === State.FAILED) {
    return (
      <Stack direction="row" gap="8px" alignItems="center">
        <WarningCircle size={16} color={palette.primitive?.error[50]} />
        <GiveText
          variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
          color="error"
        >
          {errorMessage}
        </GiveText>
      </Stack>
    );
  }
  if (state === State.LOADING) {
    return null;
  }
  if (isMobile && size === Size.DEFAULT) {
    return (
      <Stack direction="row" gap="8px" alignItems="center">
        <GiveTagInput
          value={value}
          setValue={setValue}
          isMobile={isMobile}
          isUpdateAllowed={isUpdateAllowed}
          type={tagType}
          onSaveTag={onSaveTag}
        />

        <GiveText
          variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
          color="secondary"
        >
          {dateMessage}
        </GiveText>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="8px">
      {byMessage && (
        <GiveText
          variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
          color="primary"
        >
          {byMessage}
        </GiveText>
      )}
      <GiveText
        variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
        color="secondary"
      >
        {dateMessage}
      </GiveText>
    </Stack>
  );
};
