import { GiveInput, InputProps } from "@shared/GiveInputs/GiveInput";
import { useFormContext } from "react-hook-form";

const ZipField = (props: InputProps) => {
  const { setValue } = useFormContext();

  const normalizeInput = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");

    return currentValue;
  };

  const handleChange = (value: string) => {
    setValue(`${props.name}`, normalizeInput(value));
  };

  return (
    <GiveInput
      {...props}
      onChange={(event) => handleChange(event.target.value)}
      maxLength={5}
      InputProps={{
        inputMode: "numeric",
      }}
    />
  );
};

export default ZipField;
