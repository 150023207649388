import { SxProps } from "@mui/material";
import { GiveTagInputProps } from "@shared/Inputs/GiveEditableTag";
import { FilePreviewType } from "@shared/FilePreview/types";
import { TDocument } from "@common/FilePreview/types";

export const State = {
  UPLOADED: "uploaded",
  LOADING: "loading",
  FAILED: "failed",
} as const;

export const Size = {
  DEFAULT: "default",
  MINI: "mini",
} as const;

export interface UploadStackProps {
  items: GiveUploadItemProps[];
  customStyles?: SxProps;
  isHideDelete?: boolean;
}

export type GiveUploadItemProps = {
  merchantId: number;
  state: (typeof State)[keyof typeof State];
  value: string;
  setValue: (value: string) => void;
  isNotice?: boolean;
  noticeContent?: string;
  errorMessage?: string;
  byMessage?: string;
  dateMessage?: string;
  size?: (typeof Size)[keyof typeof Size];
  isMobile?: boolean;
  fileData?: FilePreviewType;
  isHideDelete?: boolean;
  onDelete?: () => void;
  tagType?: GiveTagInputProps["type"];
  setTagWithApi?: boolean;
  handlePreview?: (fileData?: FilePreviewType) => void;
};
