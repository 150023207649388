import CheckoutStep from "@sections/PayBuilder/Checkout/CheckoutStep";
import About from "@sections/PayBuilder/Forms/About/About";
import { Styling } from "@sections/PayBuilder/Forms/Styling/Styling";
import PaymentFormItems from "@sections/PayBuilder/items/PaymentFormItems";
import { CampaignMapperValues } from "features/Minibuilders/PaymentFormMinibuilder/useCreateCampaignFn";
import LaunchStep from "@sections/PayBuilder/LaunchStep/LaunchStep";
import { views } from "./views";
import FundraiserAbout from "@sections/PayBuilder/Forms/About/FundraiserAbout";
import FundraiserCheckoutStep from "@sections/PayBuilder/Checkout/FundraisersCheckoutStep";
import { DateAndLocation } from "@sections/PayBuilder/Forms/DateAndLocation";
import {
  endsAtValidationSchema,
  locationURLValidationSchema,
  startsAtValidationSchema,
} from "@sections/PayBuilder/provider/useManagePayFormProvider";

const standardSteps = [
  { label: "About", id: "product_creation" },
  { label: "Items", id: "variants_creation" },
  { label: "Style", id: "style_configuration" },
  { label: "Checkout", id: "checkout_configuration" },
];

const fundraiserSteps = [
  { label: "About", id: "product_creation" },
  { label: "Style", id: "style_configuration" },
  { label: "Donation", id: "checkout_configuration" },
];

const eventsSteps = [
  { label: "About", id: "product_creation" },
  { label: "Date & Location", id: "date_location_configuration" },
  { label: "Tickets", id: "variants_creation" },
  { label: "Style", id: "style_configuration" },
  { label: "Checkout", id: "checkout_configuration" },
];

const standardFormStep = [About, PaymentFormItems, Styling, CheckoutStep];

const eventFormStep = [
  About,
  DateAndLocation,
  PaymentFormItems,
  Styling,
  CheckoutStep,
];

const fundraiserFormStep = [FundraiserAbout, Styling, FundraiserCheckoutStep];

const steps: Record<
  CampaignMapperValues | "standard",
  {
    steps: { label: string; id: string }[];
    formStep: any[];
  }
> = {
  standard: {
    steps: standardSteps,
    formStep: standardFormStep,
  },

  fundraiser: {
    steps: fundraiserSteps,
    formStep: fundraiserFormStep,
  },
  event: {
    steps: eventsSteps,
    formStep: eventFormStep,
  },
  invoice: {
    steps: [],
    formStep: [],
  },
  membership: {
    steps: [],
    formStep: [],
  },
  sweepstake: {
    steps: [],
    formStep: [],
  },
};

export const generateStepsArray = (
  isLaunchVisible = true,
  type: CampaignMapperValues = "standard",
) => {
  return {
    steps: [
      ...steps[type].steps,
      ...(isLaunchVisible ? [{ label: "Launch", id: "launch" }] : []),
    ],
    formStep: [...steps[type].formStep, LaunchStep],
    previewStep: (views as any)[type] ?? "standard", //for jest
  };
};

export const isEventNextDisabled = ({
  values,
  errors,
}: {
  values: any;
  errors: any;
}) => {
  let isNextDisabled = false;
  let isNextDisbaledBecauseOfLocation = false;

  // check timeActiveTab validations
  if (values.DateLocation.timeActiveTab === "range") {
    const isStartValidDate = startsAtValidationSchema.isValidSync(
      values.DateLocation?.startsAt,
    );
    const isEndValidDate = endsAtValidationSchema.isValidSync(
      values.DateLocation?.endsAt,
    );

    isNextDisabled =
      !isStartValidDate ||
      Boolean(errors.DateLocation?.startsAt) ||
      !isEndValidDate ||
      Boolean(errors.DateLocation?.endsAt);
  } else {
    const isValidDate = startsAtValidationSchema.isValidSync(
      values.DateLocation?.startsAt,
    );

    isNextDisabled = !isValidDate || !!errors.DateLocation?.startsAt;
  }

  if (values.DateLocation.locationPosition === "online") {
    const isValidLocationURL = locationURLValidationSchema.isValidSync(
      values.DateLocation.locationURL,
    );

    isNextDisbaledBecauseOfLocation =
      !isValidLocationURL ||
      !!errors.DateLocation?.locationURL ||
      !values.DateLocation?.locationURL?.length;
  } else if (values.DateLocation.locationPosition === "onsite") {
    if (values.DateLocation?.isManualAddress) {
      // const isValidLocation = locationAddressSchema.isValidSync(
      //   values.DateLocation?.locationAddress,
      //   { recursive: true },
      // );
      //using YUP doesn work
      const isValidLocation =
        values.DateLocation?.locationAddress.line1 &&
        values.DateLocation?.locationAddress.city &&
        values.DateLocation?.locationAddress.state &&
        values.DateLocation?.locationAddress.zip &&
        values.DateLocation?.locationAddress.country;

      isNextDisbaledBecauseOfLocation =
        !isValidLocation || !!errors.DateLocation?.locationAddress;
    } else {
      const isValidLocationShort = Boolean(
        values.DateLocation.locationShortAddress,
      );

      isNextDisbaledBecauseOfLocation =
        !isValidLocationShort || !!errors.DateLocation?.locationShortAddress;
    }
  }

  return isNextDisabled || isNextDisbaledBecauseOfLocation;
};
