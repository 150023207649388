import { Badge, Box } from "@mui/material";
import { ChatCircle } from "@phosphor-icons/react";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { GiveTooltipProps } from "@shared/Tooltip/GiveTooltip.types";
import { useAppTheme, styled } from "@theme/v2/Provider";
import GiveThumbnail, {
  GiveThumbnailProps,
} from "@shared/Thumbnail/GiveThumbnail";

type MainInfoImageProps = {
  src: string;
  unread?: boolean;
  thumbnailSize?: GiveThumbnailProps["size"];
  thumbnailType: GiveThumbnailProps["type"];
  isOrange?: boolean;
  badgeTooltipProps?: Omit<GiveTooltipProps, "children">;
};

interface BadgeContentProps {
  isOrange?: boolean;
}

const MainInfoImage = ({
  src,
  unread,
  isOrange,
  badgeTooltipProps,
  thumbnailType,
  thumbnailSize,
}: MainInfoImageProps) => {
  const theme = useAppTheme();

  const badgeContent = (
    <BadgeContainer isOrange={isOrange}>
      {/* TODO: will be updated once we got BE
      <GiveText title="2" color="default" fontSize="12px">
        2
      </GiveText> */}
      <ChatCircle size={12} color={theme.palette.text.invert} weight="fill" />
    </BadgeContainer>
  );

  return (
    <CustomBadge
      invisible={!unread}
      overlap="circular"
      badgeContent={
        badgeTooltipProps ? (
          <GiveTooltip {...badgeTooltipProps}>{badgeContent}</GiveTooltip>
        ) : (
          badgeContent
        )
      }
    >
      <GiveThumbnail imageUrl={src} size={thumbnailSize} type={thumbnailType} />
    </CustomBadge>
  );
};

const BadgeContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOrange",
})<BadgeContentProps>(({ isOrange, theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  height: "20px",
  borderRadius: "30px",
  backgroundColor: isOrange
    ? theme.palette.primitive?.warning[50]
    : theme.palette.primitive?.blue[50],

  //  TODO: delete once we got BE
  // width: "34px",
  // padding: "4px 4px 3px 6px",
  width: "20px",
  padding: 0,
}));

const CustomBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    borderRadius: "30px",
    zIndex: 1,
    padding: 0,
    position: "absolute",
    bottom: "0",
    right: "0",
    transform: "translate(35%, 70%)",
  },
});
export default MainInfoImage;
