import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";
import { AxiosError } from "axios";
import { showMessage } from "@common/Toast";
import { useMediaQuery } from "@mui/material";
import {
  QKEY_GET_ALL_MEDIA_ITEM,
  QKEY_GET_CAMPAGIN_STATS,
  QKEY_GET_ENTERPRISEID_BY_ID,
  QKEY_GET_MERCHANT_MSP_STATUS,
  QKEY_GET_MERCHANT_PREVIEW,
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ALL_BANK_ACCOUNTS,
  QKEY_LIST_BUSINESS_OWNERS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
  QKEY_LIST_MERCHANT_STATS,
  QKEY_LIST_TEAM_MEMBERS,
} from "@constants/queryKeys";
import { useNavigate } from "react-router-dom";
import {
  getProductRefetchQuery,
  parsedNavigationKey,
  productVarinatParser,
} from "./utils";
import { DeleteEnterpriseDescription } from "./DeleteEnterpriseDescription";
import { ReactElement } from "react";
import { CampaignType } from "@common/Campaigns/hooks/useReportMenuActions";

export type TModalVariant =
  | "document"
  | "bank_account"
  | "merchant"
  | "image"
  | "product"
  | "owner"
  | "team_member"
  | "provider"
  | "permissions"
  | "amount"
  | "merchantInvitation";

type TValuesObject = {
  title: string;
  description: string | ReactElement;
  button: { text: string };
  onSuccess?: () => void;
  onError?: (error: any) => void;
  refetchQuerieKeys: string[];
  invalidateQuerieKeys: string[];
};

const useDeleteConfirmationModal = ({
  variant,
  itemName,
  productVariant,
}: {
  variant: TModalVariant;
  itemName?: string;
  productVariant?: CampaignType;
}) => {
  const { t } = useTranslation(namespaces.common);
  const isDesktop = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();

  const parsedProductVariant =
    productVariant && productVarinatParser(productVariant);

  const navigationKey =
    productVariant === "standard"
      ? productVariant
      : parsedNavigationKey(productVariant);

  const productRefetchQuery = productVariant
    ? getProductRefetchQuery(productVariant)
    : "";

  const modalValues: Record<TModalVariant, TValuesObject> = {
    document: {
      title: "Delete Document Confirmation",
      description: (
        <>
          Are you sure you want to delete <span>{itemName}</span>? This document
          will be permanently deleted.
        </>
      ),
      button: {
        text: "Delete Document",
      },
      refetchQuerieKeys: [],
      invalidateQuerieKeys: [],
    },
    bank_account: {
      title: "Delete Bank Account Confirmation",
      description: (
        <>
          Are you sure you want to delete <span>{itemName}</span>? This bank
          account will be permanently deleted.
        </>
      ),
      button: {
        text: "Delete Account",
      },
      onSuccess: () => {
        showMessage(
          "Success",
          t("toast.Bank_Account_deleted_successfully", {
            ns: namespaces.common,
          }),
          isDesktop,
          t("toast.success", { ns: namespaces.common }),
        );
      },
      refetchQuerieKeys: [
        QKEY_GET_MERCHANT_PREVIEW,
        QKEY_LIST_ALL_BANK_ACCOUNTS,
      ],
      invalidateQuerieKeys: [QKEY_GET_MERCHANT_MSP_STATUS],
    },
    merchant: {
      title: "Delete Merchant Confirmation",
      description: (
        <>
          Are you sure you want to delete <span>{itemName}</span> merchant? This
          merchant will be permanently deleted.
        </>
      ),
      button: {
        text: "Delete Merchant",
      },
      onSuccess: () => {
        showMessage(
          "Info",
          <>
            Merchant <span style={{ fontWeight: "600" }}>“{itemName}”</span> has
            been deleted successfully
          </>,
          isDesktop,
          `Merchant Deleted`,
        );
      },
      refetchQuerieKeys: [
        QKEY_LIST_MERCHANTS,
        QKEY_LIST_ENTERPRISE_MERCHANTS,
        QKEY_LIST_ACQUIRER_MERCHANTS,
        QKEY_LIST_MERCHANT_STATS,
      ],
      invalidateQuerieKeys: [],
    },
    image: {
      title: "Delete Image Confirmation",
      description: `Are you sure you want to delete this image? This image will be permanently deleted`,
      button: {
        text: "Delete Image",
      },
      onSuccess: () => {
        showMessage("Success", "Image has been successfully deleted");
      },
      refetchQuerieKeys: [QKEY_GET_ALL_MEDIA_ITEM],
      invalidateQuerieKeys: [],
    },
    product: {
      title: `Delete ${parsedProductVariant} Confirmation`,
      description: (
        <>
          Are you sure you want to delete <span>{itemName}</span>? This{" "}
          {parsedProductVariant} will be permanently deleted.
        </>
      ),
      button: {
        text: `Delete ${parsedProductVariant}`,
      },
      onSuccess: () => {
        showMessage(
          "Success",
          <>
            ${parsedProductVariant}{" "}
            <span style={{ fontWeight: 600 }}>“{itemName}”</span> has been
            deleted successfully
          </>,
        );
        navigationKey && navigate(`/merchant/${navigationKey}`);
      },
      refetchQuerieKeys: [productRefetchQuery, QKEY_GET_CAMPAGIN_STATS],
      invalidateQuerieKeys: [],
    },

    owner: {
      title: "Delete Owner Confirmation",
      description: (
        <>
          Are you sure you want to delete <span>{itemName}</span> as Business
          Owner? This business owner willl be permanently deleted.
        </>
      ),
      button: {
        text: "Delete Owner",
      },
      onSuccess: () => {
        showMessage("Info", "Business Owner removed!", isDesktop);
      },
      refetchQuerieKeys: [QKEY_GET_MERCHANT_PREVIEW, QKEY_LIST_BUSINESS_OWNERS],
      invalidateQuerieKeys: [
        QKEY_GET_MERCHANT_MSP_STATUS,
        QKEY_LIST_BUSINESS_OWNERS,
      ],
    },
    team_member: {
      title: "Delete Team Member Confirmation",
      description: (
        <>
          Are you sure you want to delete <span>{itemName}</span>? This team
          member will be permanently deleted.
        </>
      ),
      button: {
        text: "Delete Member",
      },
      onSuccess: () => {
        const description = `The team member has been successfully removed from the members`;
        showMessage("Success", description, true, "Successfully removed");
      },
      refetchQuerieKeys: [QKEY_LIST_TEAM_MEMBERS],
      invalidateQuerieKeys: [],
    },
    provider: {
      title: "Delete Provider Confirmation",
      description: <DeleteEnterpriseDescription itemName={itemName} />,
      button: {
        text: "Delete Provider",
      },
      onSuccess: () => {
        showMessage(
          "Info",
          <>
            Provider <span style={{ fontWeight: "600" }}>“{itemName}”</span> has
            been deleted successfully
          </>,
          isDesktop,
          `Provider Deleted`,
        );
      },
      refetchQuerieKeys: [
        QKEY_LIST_ENTERPRISE_MERCHANTS,
        QKEY_LIST_ENTERPRISE_STATS,
        QKEY_GET_ENTERPRISEID_BY_ID,
      ],
      invalidateQuerieKeys: [],
    },
    permissions: {
      title: "Remove Permissions Confirmation",
      description: (
        <>
          Are you sure you want to remove <span>{itemName}</span>? This
          permission will be removed.
        </>
      ),
      button: {
        text: "Remove Permission",
      },
      refetchQuerieKeys: [],
      invalidateQuerieKeys: [],
    },
    amount: {
      title: "Delete Amount Confirmation",
      description: "Are you sure you want to remove this payment amount?",
      button: {
        text: "Delete Amount",
      },
      refetchQuerieKeys: [],
      invalidateQuerieKeys: [],
    },
    merchantInvitation: {
      title: `Delete Invitation${itemName ? "s" : ""}`,
      description: (function () {
        const changeableContent =
          itemName === "all"
            ? "all the "
            : itemName
            ? "the selected " + itemName + " "
            : "";
        return `Are you sure you want to delete ${changeableContent}invitation${
          itemName ? "s" : ""
        }? This action cannot be undone.`;
      })(),
      button: {
        text: "Delete",
      },
      refetchQuerieKeys: [],
      invalidateQuerieKeys: [],
    },
  };
  return { modalValues: modalValues[variant as TModalVariant] };
};

export default useDeleteConfirmationModal;
