import GiveText from "@shared/Text/GiveText";
import ChipMenu from "features/Merchants/MerchantSidePanel/components/ChipMenu";
import { Stack } from "@mui/system";
import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ReportFormFields } from "./AddNewReportView";

export enum MatchStatusEnum {
  Confirmed_match = "confirmed_match",
  Clear = "clear",
}

type StatusOption = {
  label: string;
  value: MatchStatusEnum;
  color: "success" | "destructive";
};

export const statusOptions: Record<MatchStatusEnum, StatusOption> = {
  [MatchStatusEnum.Confirmed_match]: {
    label: "Confirmed Match",
    value: MatchStatusEnum.Confirmed_match,
    color: "destructive",
  },
  [MatchStatusEnum.Clear]: {
    label: "Clear",
    value: MatchStatusEnum.Clear,
    color: "success",
  },
};

const getMenuOption = (status: MatchStatusEnum) => {
  return status === MatchStatusEnum.Clear
    ? statusOptions[MatchStatusEnum.Confirmed_match]
    : statusOptions[MatchStatusEnum.Clear];
};

const ReportStatusSelector = () => {
  const { setValue } = useFormContext<ReportFormFields>();
  const status: ReportFormFields["status"] = useWatch({
    name: "status",
  });

  const menuOption = useMemo(() => getMenuOption(status), [status]);

  const chipColor =
    status === MatchStatusEnum.Confirmed_match ? "error" : "success";

  return (
    <Stack direction="row" gap={1.5} alignItems="center">
      <GiveText variant="bodyS">
        Please set the most appropriate status:
      </GiveText>
      <ChipMenu
        options={[
          {
            text: `Change to ${menuOption.label}`,
            onClick: () => setValue("status", menuOption.value),
            type: menuOption.color,
          },
        ]}
        canOpen
        chipProps={{
          variant: "light",
          color: chipColor,
        }}
        label={statusOptions[status].label}
        menuWidth={240}
      />
    </Stack>
  );
};

export default ReportStatusSelector;
