import useNewProductBanner from "@components/Product/NewProductBanner/hooks";
import { CREATE_DENY_MESSAGE } from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import { Plus } from "@phosphor-icons/react";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";
import { CREATE_PRE_BUILD_FORM_MODAL } from "modals/modal_names";
import ProductBannerBase from "./ProductBannerBase";

type Props = {
  title: string;
};

const ProductBanner = ({ title }: Props) => {
  // TODO: to replace this hook preserving the logic
  const { mainStat, parseStats, onOpenAnalytics } = useNewProductBanner({
    hasEmptyData: false,
    analyticsType: "payment-forms",
  });
  const { isAddProductAllowed } = useProductPermission();

  const handleCreate = () => {
    NiceModal.show(CREATE_PRE_BUILD_FORM_MODAL);
  };

  const mainAction = {
    hidden: false,
    label: "Create Form",
    action: handleCreate,
    icon: Plus,
    tooltipProps: {
      title: CREATE_DENY_MESSAGE,
      disableHoverListener: isAddProductAllowed,
    },
    disabled: !isAddProductAllowed,
  };

  return (
    <ProductBannerBase
      mainStat={{ ...mainStat, title: "Total Amount" }}
      parseStats={parseStats}
      onOpenAnalytics={onOpenAnalytics}
      title={title}
      mainAction={mainAction}
    />
  );
};
export default ProductBanner;
