import { showMessage } from "@common/Toast";
import { useUpdateMerchantsLists } from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
} from "@constants/queryKeys";
import { customInstance } from "@services/api";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { checkPortals } from "@utils/routing";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useQueryClient } from "react-query";

const useWatchlist = (entreprise?: boolean, queryKey?: string) => {
  const { isDesktopView } = useCustomTheme();
  const { updateData, revertChange } = useUpdateMerchantsLists(queryKey);
  const queryClient = useQueryClient();
  const { isRebrandingEnabled } = useGetFeatureFlagValues();
  const { isEnterpriseTable } = checkPortals();

  const editMerchantWatchlist = async (
    id: number,
    name: string,
    add = true,
  ) => {
    const operation = add ? "add" : "remove";
    const { description, title } = getMessage(operation, name, entreprise);
    try {
      await queryClient.cancelQueries(queryKey);
      updateData(id, { watchlist: add }, { updateMerchantPreview: true });
      await customInstance({
        url: `/merchants/${id}/underwriting`,
        method: "PATCH",
        data: { watchlist: add },
      });

      // Here we can fetch the last query
      if (isEnterpriseTable) queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_STATS);
      if (!isEnterpriseTable) {
        queryClient.refetchQueries(QKEY_LIST_MERCHANTS);
        queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
      }
      if (!isRebrandingEnabled) {
        showMessage("Success", description, isDesktopView, title);
      }
    } catch (error) {
      revertChange();
      showMessage("Error", "Whoops.. an error occured. Please try again");
    }
  };

  const addMerchantToWatchlist = (merchantId: number, name: string) => {
    editMerchantWatchlist(merchantId, name);
  };

  const removeMerchantFromWatchlist = (merchantId: number, name?: string) => {
    editMerchantWatchlist(merchantId, name || "", false);
  };

  return { addMerchantToWatchlist, removeMerchantFromWatchlist };
};

type TOperation = "add" | "remove";

const getMessage = (
  operation: TOperation,
  name: string,
  enterprise?: boolean,
) => {
  if (operation === "add") {
    return {
      description: `${
        enterprise ? "Provider" : "Merchant"
      } “${name}” has been added to the watchlist`,
      title: "Added to Watchlist",
    };
  } else {
    return {
      description: `${
        enterprise ? "Provider" : "Merchant"
      } “${name}” has been removed from the watchlist`,
      title: "Removed from Watchlist",
    };
  }
};

export default useWatchlist;
