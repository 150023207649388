import { Box, Collapse, Stack } from "@mui/material";
import CartItem from "@sections/PayBuilder/components/cart/CartItem";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CartTotalSection from "./CartTotalSection";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useQueryClient } from "react-query";
import { QKEY_LIST_CART } from "@constants/queryKeys";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import GiveDivider from "@shared/GiveDivider/GiveDivider";

const CheckoutCart = ({
  isSuccessDisplayed = false,
}: {
  isSuccessDisplayed?: boolean;
}) => {
  const [isMobileCartViewExpanded, setiSMobileCartViewExpanded] =
    useState(false);
  const { isMobileView } = useCustomThemeV2();
  const { palette } = useAppTheme();
  const {
    cartItems,
    addToCart,
    removeFromCart,
    isCartEmpty,
    subTotal,
    fees,
    displayFees,
    totalAmount,
  } = useCart();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isFundraiser } = useCheckFormType();

  useEffect(() => {
    if (isCartEmpty && !isFundraiser) {
      queryClient.invalidateQueries(QKEY_LIST_CART);
      navigate(`/${id}`);
    }
  }, [isCartEmpty]);

  const content = (
    <>
      <GiveText variant="bodyL" color="primary">
        Summary
      </GiveText>
      {isFundraiser ? (
        <Stack spacing={2} marginTop="20px">
          <Stack direction="row" justifyContent="space-between">
            <GiveText variant="bodyXS">Donation</GiveText>
            <GiveText variant="bodyXS">${subTotal}</GiveText>
          </Stack>
          <GiveDivider />
        </Stack>
      ) : (
        cartItems.map((item, index) => (
          <CartItem
            key={index}
            item={item}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            isCheckout
            isSuccessDisplayed={isSuccessDisplayed}
          />
        ))
      )}
      <CartTotalSection cartItems={cartItems} fees={fees} />
    </>
  );

  const fundraiserContent = (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <GiveText variant="bodyXS">Donation</GiveText>
        <GiveText variant="bodyXS">${subTotal}</GiveText>
      </Stack>
      {Boolean(fees) && displayFees && (
        <Stack direction="row" justifyContent="space-between">
          <GiveText variant="bodyXS">Credit Card Fees</GiveText>
          <GiveText variant="bodyXS">${fees}</GiveText>
        </Stack>
      )}
      <Box
        sx={{
          height: "1px",
          backgroundColor: palette.primitive?.transparent["darken-10"],
        }}
      />
      <Stack direction="row" justifyContent="space-between">
        <GiveText variant="bodyL">Due Today</GiveText>
        <GiveText variant="bodyL">${totalAmount}</GiveText>
      </Stack>
    </Stack>
  );

  return (
    <Stack
      sx={{
        backgroundColor: palette.primitive?.transparent["darken-5"],
        borderRadius: "16px",
        padding: isMobileView ? "16px 20px" : "24px",
        marginTop: isMobileView ? "20px" : "56px",
        width: "100%",
      }}
    >
      {isMobileView ? (
        isFundraiser ? (
          fundraiserContent
        ) : (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <GiveText>
                  {isMobileCartViewExpanded ? "Hide Order" : "View Order"}
                </GiveText>
                <Box
                  onClick={() => setiSMobileCartViewExpanded((prev) => !prev)}
                >
                  {isMobileCartViewExpanded ? <CaretUp /> : <CaretDown />}
                </Box>
              </Stack>
              <GiveText>${subTotal}</GiveText>
            </Stack>
            <Collapse
              in={isMobileCartViewExpanded}
              sx={{ marginTop: isMobileCartViewExpanded ? "12px" : "0px" }}
            >
              {content}
            </Collapse>
          </>
        )
      ) : (
        <>{content}</>
      )}
    </Stack>
  );
};

export default CheckoutCart;
