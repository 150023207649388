import { Stack, SxProps } from "@mui/material";
import React from "react";
import {
  TEmptyStateWrapperSection,
  TEmptyStateAction,
  GiveEmptyStateType,
} from "./types";
import GiveEmptyState from "./GiveEmptyState";
import {
  Storefront,
  Briefcase,
  XCircle,
  MagnifyingGlass,
  GridFour,
  ArrowsLeftRight,
} from "@phosphor-icons/react";
import { styled } from "@theme/v2/Provider";

type Props = {
  children?: React.ReactNode;
  isEmpty?: boolean;
  section?: TEmptyStateWrapperSection;
  sx?: SxProps;
  action?: Omit<TEmptyStateAction, "label">;
  searchValue?: string;
};

const GiveEmptyStateWrapper = ({
  isEmpty = false,
  children,
  section,
  sx,
  action,
  searchValue,
}: Props) => {
  const currentData = section ? generateData({ searchValue })[section] : null;

  if (isEmpty && section && currentData) {
    return (
      <GiveEmptyState
        {...currentData}
        action={
          currentData?.action
            ? {
                label: currentData?.action?.label,
                handleAction: action?.handleAction,
                disabled: action?.disabled,
              }
            : undefined
        }
      />
    );
  }

  return <Container sx={sx}>{children}</Container>;
};

const Container = styled(Stack)({
  width: "100%",
  height: "100%",
  flexGrow: 1,
  alignItems: "center",
});

const generateData = ({
  searchValue,
}: {
  searchValue?: string;
}): Record<TEmptyStateWrapperSection, GiveEmptyStateType> => {
  return {
    merchant: {
      Icon: <Storefront size={28} />,
      title: {
        main: "No merchants",
        secondary: "Create your first merchant",
      },
      action: {
        label: "Create Merchant",
      },
    },
    emptyTab: {
      Icon: <Storefront size={28} />,
      title: {
        main: "No merchants available",
        secondary: "There are currently no merchants in this step",
      },
    },
    provider: {
      Icon: <Briefcase size={28} />,
      title: {
        main: "No providers",
        secondary: "Create your first provider",
      },
      action: {
        label: "Create Provider",
      },
    },
    "no-filter-results": {
      Icon: <XCircle size={28} />,
      title: {
        main: "No results",
        secondary: "Please try removing the filters",
      },
      action: {
        label: "Clear Filters",
      },
    },
    "empty-search": {
      Icon: <MagnifyingGlass size={28} />,
      title: {
        main: `No results for "${searchValue}"`,
        secondary: "Please try a different search term",
      },
      action: {
        label: "Clear Search",
      },
    },
    "empty-search-and-filter": {
      Icon: <MagnifyingGlass size={28} />,
      title: {
        main: `No results for "${searchValue}"`,
        secondary: "Please try a different search term or filter criteria",
      },
      action: {
        label: "Clear Search",
      },
    },
    products: {
      Icon: <GridFour size={28} />,
      title: {
        main: ``,
        secondary:
          "There’s currently nothing to see here. Please check back soon for updates!",
      },
    },
    events: {
      Icon: <GridFour size={28} />,
      title: {
        main: ``,
        secondary: "This event has ended, tickets are no longer available.",
      },
    },
    "manage-money": {
      Icon: <ArrowsLeftRight size={28} />,
      title: {
        main: `No transactions`,
      },
    },
  };
};

export default GiveEmptyStateWrapper;

export const mapTypeToEmptyState: Record<string, TEmptyStateWrapperSection> = {
  event: "events",
  standard: "products",
  // ... add other types when needed
};
