type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const TwitterXIcon = ({
  width = 49,
  height = 48,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="24" fill="black" />
      <path
        d="M30.901 13.1533H34.581L26.541 22.3433L36 34.8463H28.594L22.794 27.2623L16.156 34.8463H12.474L21.074 25.0163L12 13.1543H19.594L24.837 20.0863L30.901 13.1533ZM29.61 32.6443H31.649L18.486 15.2403H16.298L29.61 32.6443Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterXIcon;
