import { TableColumnType } from "@common/Table";
import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import GiveThumbnail, { ThumbnailType } from "@shared/Thumbnail/GiveThumbnail";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { format } from "date-fns";
import { ProductData, TCampaignType } from "../types";
import { useAppTheme } from "@theme/v2/Provider";
import { parseAmount } from "@utils/index";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type Props = {
  type: TCampaignType;
};

export const useGetProductTableColumns = ({ type }: Props) => {
  const { isDesktopView } = useCustomThemeV2();
  const { palette } = useAppTheme();
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();
  const productThumbnail = ["payment-form", "standard"].includes(type)
    ? "product"
    : type;

  const titleColumn = {
    key: "name",
    sortable: true,
    columnType: "product-title" as const,
    title: "Title",
    columnWidth: 2.5,
    renderColumn: (row: ProductData) => {
      const isDraft =
        row?.publishedStatus === "draft" &&
        isPayBuilderEnabled &&
        row?.typeName &&
        ["fundraiser", "standard", "event"]?.includes(row?.typeName);

      return (
        <Stack direction="row" spacing={2} alignItems="center" width="100%">
          <GiveThumbnail
            type={productThumbnail as ThumbnailType}
            size="small"
            imageUrl={row?.imageURL ? row?.imageURL + "/small" : ""}
            name={row?.name}
          />
          <Stack spacing={0.5} padding={isDesktopView ? "10px" : 0}>
            <GiveTruncateText
              variant="bodyS"
              lineClamp={1}
              sx={{ wordBreak: "break-all" }}
            >
              {isDraft && (
                <span
                  style={{
                    color: palette?.primitive?.error?.[50],
                    marginRight: 4,
                  }}
                >
                  Draft
                </span>
              )}
              {row?.name}
            </GiveTruncateText>
            {!isDesktopView && (
              <GiveText variant="bodyS" color="secondary">
                {parseAmount(row?.sumTransactions / 100)}
              </GiveText>
            )}
          </Stack>
        </Stack>
      );
    },
  };

  const desktopColumns = [
    titleColumn,
    {
      key: "createdAt,-accID",
      sortable: true,
      title: "Created",
      columnWidth: 1,
      renderColumn: (row: ProductData) => {
        return (
          <GiveText variant="bodyS">
            {row?.createdAt ? format(row.createdAt * 1000, "MMM dd, yyyy") : ""}
          </GiveText>
        );
      },
    },
    {
      key: "location,-accID",
      columnType: "half-text" as const,
      title: "Location",
      columnWidth: 1,
      hideColumn: type !== "event",
      renderColumn: (row: ProductData) => {
        const location = row?.locationURL
          ? row?.locationURL
          : (row?.locationAddress &&
              !row?.locationShortAddress &&
              `${row?.locationAddress.line1} ${row?.locationAddress.city}
              ${row?.locationAddress.zip} ${row?.locationAddress.state}
              ${row?.locationAddress.country}`) ||
            (!row?.locationURL && row?.locationShortAddress);

        return (
          <GiveTooltip color="default" title={location} alignment="start">
            <GiveTruncateText
              variant="bodyS"
              lineClamp={2}
              sx={{ wordBreak: "break-all" }}
            >
              {location}
            </GiveTruncateText>
          </GiveTooltip>
        );
      },
    },
    {
      key: "conversionRate,-accID",
      columnType: "half-text" as const,
      sortable: true,
      title: "Conversion",
      columnWidth: 1,
      renderColumn: (row: ProductData) => {
        return <GiveText variant="bodyS">{row?.conversionRate}%</GiveText>;
      },
    },
    {
      key: "visitors,-accID",
      columnType: "half-text" as const,
      sortable: true,
      title: "Visitors",
      columnWidth: 1,
      renderColumn: (row: ProductData) => {
        return <GiveText variant="bodyS">{row?.visitors}</GiveText>;
      },
    },
    {
      key: "totalTransactions,-accID",
      columnType: "half-text" as const,
      sortable: true,
      title: "Transactions",
      columnWidth: 1,
      renderColumn: (row: ProductData) => {
        return <GiveText variant="bodyS">{row?.totalTransactions}</GiveText>;
      },
    },
    {
      key: "averageTransactions,-accID",
      columnType: "half-text" as const,
      sortable: true,
      title: "Average (USD)",
      columnWidth: 1,
      renderColumn: (row: ProductData) => {
        return (
          <GiveText variant="bodyS">
            {parseAmount(row?.averageTransactions / 100)}
          </GiveText>
        );
      },
    },
    {
      key: "sumTransactions,-accID",
      columnType: "half-text" as const,
      sortable: true,
      title: "Amount (USD)",
      columnWidth: 1,
      renderColumn: (row: ProductData) => {
        return (
          <GiveText variant="bodyS">
            {parseAmount(row?.sumTransactions / 100)}
          </GiveText>
        );
      },
    },
  ];

  const mobileColumns = [titleColumn];

  const columns = (
    isDesktopView ? desktopColumns : mobileColumns
  ) as TableColumnType[];

  return { columns };
};
