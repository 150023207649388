import { Grid, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { capitalizeFirstLetter } from "@utils/index";
import PublicInputShare from "./PublicInputShare";
import type { TButtonsMap } from "./PublicShare.types";

const StylessVariant = ({
  ButtonsMap,
  url,
}: {
  ButtonsMap: TButtonsMap;
  url: string;
}) => {
  return (
    <Stack
      gap={2}
      sx={{
        // background: "rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
      }}
    >
      <PublicInputShare url={url} label="URL" />
      <Grid container spacing={2} padding="16px 0px 16px 0px">
        {ButtonsMap.map((Button, index) => (
          <Grid item key={index} xs={6} sm={6} md={6}>
            <Stack
              sx={{ cursor: "pointer" }}
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Button.Component
                id={`${Button.id}-share-button`}
                url={url}
                appId=""
              >
                <Button.Icon />
              </Button.Component>
              <GiveText fontSize="12px" textAlign="center">
                {capitalizeFirstLetter(Button.id)}
              </GiveText>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default StylessVariant;
