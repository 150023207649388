import React, { useEffect, useMemo, useRef } from "react";
import { useGetCustomers } from "@services/api/customer";
// utils
import { detectMobile } from "@utils/index";
// redux
import { useAppSelector } from "@redux/hooks";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { useCachedList } from "@hooks/common/useCachedList";
import { selectQueryFilters } from "@redux/slices/customers";
import { encodedQueryFilterMap } from "@services/filtering";
import { sorting as sortingReducer } from "@redux/slices/fundraisers";
import { QKEY_LIST_CUSTOMERS } from "@constants/queryKeys";
import { selectQueryString } from "@redux/slices/search";

const useListCustomers = () => {
  const queryFilters = useAppSelector(selectQueryFilters);
  const sorting = useAppSelector(sortingReducer);
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_CUSTOMERS),
  );

  const loadingRef = useRef<boolean>(false);
  const { page, setPage } = usePagination(0, "");

  const { allData, invalidateCache } = useCachedList(QKEY_LIST_CUSTOMERS);

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );
  const queryString = queryFilter.customers || "";

  const { data, isLoading, isError } = useGetCustomers(
    {
      page,
      queryString,
      searchQuery,
      sorting,
    },
    {
      refetchOnWindowFocus: false,

      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  useEffect(() => {
    if (detectMobile()) invalidateCache();
    setPage(1);
  }, [sorting, searchQuery, queryString]);

  const usedData = detectMobile() ? allData : data?.data ?? [];

  const formatedRows = useMemo(
    () => (usedData.length ? usedData : []),
    [usedData],
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return {
    isError,
    page,
    rowsPerPage: ROWS_PER_PAGE,
    currentPageRows: formatedRows,
    handlePageChange,
    totalRows: data?.total ?? 0,
    setPage: (_e: any, v: number) => {
      detectMobile() ? setPage((current) => current + 1) : setPage(Number(v));
    },
    allRows: formatedRows,
    loadingRef,
    isLoading,
    state: {
      isEmpty: !isLoading && !queryString && !searchQuery && data?.total === 0,
      isError,
    },
  };
};

export default useListCustomers;
