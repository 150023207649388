import CheckoutButtonWithSpinner from "@sections/PayBuilder/items/CheckoutButtonWithSpinner";
import GiveButton from "@shared/Button/GiveButton";
import { styled } from "@theme/v2/Provider";

interface CheckoutButtonProps {
  isPreviewMode: boolean;
  isLoading: boolean;
  handleSubmit: (data: any) => void;
  accentColor: string;
}

const CheckoutButton = ({
  isPreviewMode = true,
  isLoading = false,
  handleSubmit,
  accentColor,
}: CheckoutButtonProps) => {
  return (
    <CheckoutButtonWithSpinner isLoading={isLoading}>
      <StyledGiveButton
        label="Pay Now"
        variant="filled"
        disabled={isPreviewMode}
        form="checkout-form"
        onClick={handleSubmit}
        accentColor={accentColor}
      />
    </CheckoutButtonWithSpinner>
  );
};

export default CheckoutButton;

const StyledGiveButton = styled(GiveButton)<{ accentColor: string }>(
  ({ theme, accentColor }) => ({
    width: "100%",
    borderRadius: "40px",
    padding: "16px",
    border: 0,
    backgroundColor: accentColor,
    "&.MuiButtonBase-root:hover": {
      bgcolor: `${accentColor}1A`,
    },
    color: theme.palette.text?.primary,
    "&.Mui-disabled": {
      color: `${theme.palette.text?.primary} !important`,
    },
  }),
);
