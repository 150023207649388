import { Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { ReasonType } from "../components/types";
import { TChallengeTypeName } from "../../types";
import { ArrowPlacement } from "@shared/Tooltip/GiveTooltip.types";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { BPVAA_DISABLED_TEXT } from "features/Merchants/MerchantSidePanel/constants";

type ChallengeButtonsProps = {
  isChallengeDone: boolean;
  hasRejectButton: boolean;
  methods: UseFormReturn<ReasonType>;
  id: number;
  challengeType: TChallengeTypeName;
  handleClose: () => void;
  onReject: (id: number, reason: string, type: TChallengeTypeName) => void;
  onApprove: (id: number, reason: string, type: TChallengeTypeName) => void;
  doneDisabled?: boolean;
  isChallengeRejected?: boolean;
};

const ChallengeButtons = ({
  doneDisabled,
  isChallengeDone,
  hasRejectButton,
  methods,
  id,
  challengeType,
  handleClose,
  onReject,
  onApprove,
  isChallengeRejected,
}: ChallengeButtonsProps) => {
  const onDone: SubmitHandler<ReasonType> = async (data) => {
    onApprove(id, data.reason, challengeType);
  };

  const onClickReject: SubmitHandler<ReasonType> = async (data) => {
    onReject(id, data.reason, challengeType);
  };

  return (
    <Stack gap="12px" flexDirection="row">
      {(isChallengeDone || isChallengeRejected) && (
        <GiveButton
          label="Close"
          variant="filled"
          size="large"
          onClick={handleClose}
        />
      )}
      {hasRejectButton && !isChallengeRejected && !isChallengeDone && (
        <GiveButton
          onClick={methods.handleSubmit(onClickReject)}
          label="Reject"
          variant="ghost"
          size="large"
          color="destructive"
        />
      )}
      {(!isChallengeDone || isChallengeRejected) && (
        <GiveTooltip
          disableHoverListener={!doneDisabled}
          color="default"
          title={BPVAA_DISABLED_TEXT}
          placement="top"
        >
          <GiveButton
            label="Done"
            variant="filled"
            size="large"
            onClick={methods.handleSubmit(onDone)}
            disabled={doneDisabled}
          />
        </GiveTooltip>
      )}
    </Stack>
  );
};

export default ChallengeButtons;
