import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { bytesToSize } from "@utils/index";
import EvidenceDocument from "./EvidenceDocument";
import { TCaseEvidence } from "../../data.types";

type Props = {
  data: TCaseEvidence;
  caseAction?: string;
};

const EvidenceProvidedBody = ({ data, caseAction }: Props) => {
  const document = {
    fileName: data?.fileName,
    fileURL: data?.fileURL,
    fileType: data?.fileType,
    fileSize: bytesToSize(data?.fileSize || 0)?.sizeString,
    notes: data?.notes,
  };

  const sectionsList = [
    { title: "Case Action", value: caseAction },
    {
      title: "Notes",
      value: data?.notes,
    },
    {
      title: "Document",
      value: <EvidenceDocument document={document as TMerchantDocument} />,
    },
  ];

  return (
    <FadeUpWrapper delay={50}>
      <Stack px={3} pb={3} spacing={1.5}>
        {sectionsList.map((section) => (
          <Section
            key={section.title}
            title={section.title}
            value={section.value}
          />
        ))}
      </Stack>
    </FadeUpWrapper>
  );
};

type SectionProps = {
  title: string;
  value: string | React.ReactNode;
};

const Section = ({ title, value }: SectionProps) => {
  return (
    <Stack spacing={0.5}>
      <Text color={palette.neutral[70]} fontWeight="light" lineHeight="16.8px">
        {title}
      </Text>
      {typeof value === "string" ? (
        <Text color={palette.neutral[90]} lineHeight="16.8px">
          {value}
        </Text>
      ) : (
        value
      )}
    </Stack>
  );
};

export default EvidenceProvidedBody;
