import { customInstance } from "@services/api";
import { checkPortals } from "@utils/routing";
import { useQuery, useQueryClient } from "react-query";

const Q_KEY = "check-list-enterprises"
export const useCheckEnterprises = () => {
  const { isAcquirerPortal } = checkPortals();
  const queryClient = useQueryClient();

  const getEnterprises = () => {
    return customInstance({
      url: `/enterprises?&max=1`,
      method: "GET",
    });
  };

  const { data, isLoading, ...rest } = useQuery(
    [Q_KEY],
    async () => {
      const providers = await getEnterprises();
      return providers;
    },
    {
      refetchOnWindowFocus: false,
      enabled: isAcquirerPortal && !queryClient.getQueryData([Q_KEY]),
      refetchOnMount: false,
      // @MohamedAliBousba said - "This is called to check to disable create merchant if a acquirer does not have enterprises"
      // ----
      // so it can be cached as long as the page is mounted
      staleTime: Infinity,
      initialData: () => {
        return queryClient.getQueryData([Q_KEY])
      },
    },
  );

  return {
    total: data?.total || 0,
    isLoading,
    ...rest,
  };
};
