import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import SupportSection from "./components/SupportSection";
import AmountsSection from "./components/AmountsSection";
import CheckoutDetailsSectionItem from "./components/CheckoutDetailsSectionItem";
import { Controller, useFormContext } from "react-hook-form";
import { FormDataType } from "../utils";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import ContactSection from "./components/ContactSection";
import { CheckCircle } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";

const FundraiserCheckoutStep = () => {
  const methods = useFormContext();
  const { methods: leftSidepanelMethods } = usePayBuilderForm();
  const checkoutValues = leftSidepanelMethods.watch().Checkout;
  const isThankYouEnabled = checkoutValues.thankYouMessage.render;
  const isCustomReceiptMessageEnabled = checkoutValues.receiptMessage.render;
  const theme = useAppTheme();
  return (
    <Stack gap="32px">
      <GiveText variant="h3" fontWeight={300}>
        Checkout Details
      </GiveText>
      <ContactSection />
      <AmountsSection />
      <SupportSection />

      <Stack gap="8px" alignItems="center" flexDirection="row">
        <CheckCircle fill={theme.palette.icon?.["icon-primary"]} size="20px" />
        <GiveText>Configuration</GiveText>
      </Stack>
      <CheckoutDetailsSectionItem
        label={"Custom Thank You Message"}
        name={"thankYouMessage" as keyof FormDataType["Checkout"]}
        optionalInput={
          isThankYouEnabled ? (
            <Stack paddingLeft="28px">
              <Controller
                control={methods.control}
                name={`Checkout.thankYouMessage.optionalMessage`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <GiveInput
                      name={`thankYouMessage.optionalMessage`}
                      value={value}
                      onChange={onChange}
                      maxLength={500}
                      displayCounter
                      multiline
                      rows={6}
                    />
                  );
                }}
              />
            </Stack>
          ) : (
            <></>
          )
        }
      />
      <CheckoutDetailsSectionItem
        label={"Custom Message for Receipt"}
        name={"receiptMessage" as keyof FormDataType["Checkout"]}
        optionalInput={
          isCustomReceiptMessageEnabled ? (
            <Stack paddingLeft="28px">
              <Controller
                control={methods.control}
                name={`Checkout.receiptMessage.optionalMessage`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <GiveInput
                      name={`receiptMessage.optionalMessage`}
                      value={value}
                      onChange={onChange}
                      maxLength={500}
                      displayCounter
                      multiline
                      rows={6}
                    />
                  );
                }}
              />
            </Stack>
          ) : (
            <></>
          )
        }
      />
    </Stack>
  );
};

export default FundraiserCheckoutStep;
