import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useUpdateAssignee } from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import { NEW_ACTION_DENY_MESSAGE } from "@constants/permissions";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
} from "@constants/queryKeys";
import { Box, ButtonBase, Stack } from "@mui/material";
import { CaretDown, Plus } from "@phosphor-icons/react";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import GiveButton from "@shared/Button/GiveButton";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { CustomTheme } from "@theme/v2/palette.interface";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { checkPortals } from "@utils/routing";
import placeholder from "assets/images/profile-placeholder.png";
import AssignmentSelect from "componentsV2/Table/components/AssignmentSelect";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "features/Merchants/MerchantSidePanel/constants";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { memo, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { AssignButtonProps } from "./GiveAssignButton.types";

const GiveAssignButton = ({
  title = "Assign",
  disabled = false,
  handleClick,
  data,
  onOpening,
  isOnlyName,
  anchorOrigin,
  showCaretIcon = true,
  type,
  customContextualMenuPaperStyle,
  query,
}: AssignButtonProps) => {
  const theme = useAppTheme();
  const {
    accID: merchantID,
    underwriterID,
    underwriterName,
    underwriterImageURL,
    underwriterEmail,
    underwriterSetByAcquirer,
  } = data || {};
  const displayName = isOnlyName
    ? underwriterName
    : underwriterName || underwriterEmail;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const queryClient = useQueryClient();
  const { handleChangeAssignee } = useUpdateAssignee(merchantID);
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isEnterprisePortal, isAcquirerPortal, isEnterpriseTable } =
    checkPortals();
  const hasUpdatePermission = isEnterprisePortal
    ? merchant_underwriting && isUpdateUnderwriterAllowed
    : isUpdateUnderwriterAllowed;

  const cannotChangeAssignee = Boolean(
    isEnterprisePortal && underwriterID && underwriterSetByAcquirer,
  );

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      if (cannotChangeAssignee) return;
      setAnchorEl(event.currentTarget);
      onOpening && onOpening("toggle_tooltip", true);
    },
    [],
  );

  const closeMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    onOpening && onOpening("toggle_tooltip", false);

    event?.stopPropagation();
  };

  const handleChange = (value: any) => {
    const newAssignee = value === "unassigned" ? null : value;
    const onSuccess = () => {
      // This is unnecessary. Called in `useGetEnterprisesTableColumns`.
      // Why the same refetch when it is on line below?
      // if (handleClick) handleClick(newAssignee);


      if (isEnterpriseTable) queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_STATS);

      // Why the same refetch twice? This is duplicated and also should be done when
      // on the merchant table.
      if (!isEnterpriseTable) {
        queryClient.refetchQueries(QKEY_LIST_MERCHANTS);
        queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
        // ---
        // This as well, should be done if merchant side panel is opened
        queryClient.refetchQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET);
      }
    };
    handleChangeAssignee(underwriterID, newAssignee, onSuccess);
  };

  if (!isAcquirerPortal && data?.underwriterSetByAcquirer) {
    return (
      <StyledManagedByAcquirerText
        variant="bodyS"
        color="secondary"
        lineClamp={2}
      >
        Managed by the Acquirer
      </StyledManagedByAcquirerText>
    );
  }

  return (
    <>
      <GiveTooltip
        title={NEW_ACTION_DENY_MESSAGE}
        color="default"
        disableHoverListener={hasUpdatePermission}
        customTooltipStyles={{ width: "fit-content" }}
      >
        {underwriterName || underwriterEmail ? (
          <StyledContainer
            disabled={disabled || !hasUpdatePermission || cannotChangeAssignee}
            onClick={handleOpenMenu}
          >
            <Stack direction="row" alignItems="center">
              <GiveAvatar
                size="24px"
                imageUrl={
                  underwriterImageURL
                    ? `${underwriterImageURL}/thumb`
                    : placeholder
                }
              />
              <GiveTruncateText
                variant="bodyS"
                lineClamp={1}
                sx={{ wordBreak: "break-all", margin: "0 4px 0 8px" }}
              >
                {displayName}
              </GiveTruncateText>
              {showCaretIcon && !cannotChangeAssignee ? (
                <Box
                  minWidth={16}
                  minHeight={16}
                  display="inline-flex"
                  className="caret-down-icon"
                >
                  <CaretDown
                    size={16}
                    fill={theme?.palette?.icon?.["icon-primary"]}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Stack>
          </StyledContainer>
        ) : (
          <GiveButton
            label={title}
            disabled={disabled || !isUpdateUnderwriterAllowed}
            onClick={handleOpenMenu}
            startIcon={
              <IconFrame>
                <Plus height={16} width={16} />
              </IconFrame>
            }
            sx={buttonStyles({ theme })}
          />
        )}
      </GiveTooltip>
      <AssignmentSelect
        anchorEl={anchorEl}
        handleClose={closeMenu}
        handleChange={handleChange}
        merchantId={merchantID}
        underwriterID={data?.underwriterID}
        anchorOrigin={anchorOrigin}
        type={type}
        customContextualMenuPaperStyle={customContextualMenuPaperStyle}
        query={query}
      />
    </>
  );
};
export default memo(GiveAssignButton);

const buttonStyles = ({ theme }: { theme: CustomTheme }) => ({
  padding: 0,
  border: "none",
  color: theme.palette.text.primary,
  backgroundColor: "transparent",
  "&:hover": {
    textDecoration: "underline",
    background: "transparent",
  },
  "&.Mui-disabled": {
    textDecoration: "none",
    color: `${theme.palette.text.disabled} !important`,
  },
  "& .MuiButton-startIcon": {
    marginRight: 0,
    marginLeft: 0,
  },
  "&:hover p, &:hover svg": {
    opacity: 0.5,
  },
});

const IconFrame = styled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.surface?.tertiary,
    borderRadius: "50%",
    height: 24,
    width: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "4px",
  };
});

const StyledContainer = styled(ButtonBase)(({ disabled }) => ({
  all: "unset",
  display: "flex",
  gap: "8px",
  cursor: "pointer",
  ...(disabled && {
    cursor: "default",
  }),
  "& .caret-down-icon": {
    visibility: "hidden",
  },
  "&:hover p, &:hover .caret-down-icon": {
    visibility: "visible",
    opacity: 0.5,
  },
}));

const StyledManagedByAcquirerText = styled(GiveTruncateText)({
  wordBreak: "break-word",
  alignSelf: "center",
});
