import { Box, Stack } from "@mui/material";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveSidePanel from "@shared/SidePanel/GiveSidePanel";
import { SidePanelContainerWrapper } from "@shared/SidePanel/SidePanelAtoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { styled } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { X } from "@phosphor-icons/react";
import { ICartItem } from "../provider/CartContext";
import CartItem from "../components/cart/CartItem";
import { CheckoutNavButton } from "../components/products/CheckoutNavButton";

interface Props {
  cartItems: ICartItem[];
  addToCart: (
    item: ICartItem,
    addedQuantity?: number,
    quantityChangeType?: string,
  ) => void;
  removeFromCart: (ProductItemTypeId: string) => void;
  totalAmount: number;
}
const CART_PANEL_WIDTH = 400;
const CartPreviewModal = NiceModal.create(
  ({ addToCart, removeFromCart, cartItems, totalAmount }: Props) => {
    const { open, onClose } = useNiceModal();
    const { isMobileView } = useCustomThemeV2();

    return (
      <GiveSidePanel open={open} onClose={onClose} width={CART_PANEL_WIDTH}>
        <StyledHeader>
          <GiveText variant="bodyS">Your Cart</GiveText>
          <GiveIconButton Icon={X} variant="ghost" onClick={onClose} />
        </StyledHeader>
        <SidePanelContainerWrapper position="relative">
          <StyledStack>
            <Box
              padding="0 10px"
              sx={{ overflowY: "auto", paddingBottom: "80px" }}
            >
              {cartItems.length == 0 && (
                <GiveText> Your cart is empty</GiveText>
              )}
              {cartItems?.map((item) => (
                <CartItem
                  key={item.id}
                  item={item}
                  removeFromCart={removeFromCart}
                  addToCart={addToCart}
                />
              ))}
            </Box>
            <StyledFooter isMobile={isMobileView}>
              <StyledSummary>
                <Stack gap="6px">
                  <GiveText variant="bodyM">Subtotal</GiveText>
                </Stack>
                <GiveText variant="bodyM">${totalAmount}</GiveText>
              </StyledSummary>
              <CheckoutNavButton fullWidth />
            </StyledFooter>
          </StyledStack>
        </SidePanelContainerWrapper>
      </GiveSidePanel>
    );
  },
);

export default CartPreviewModal;

const StyledHeader = styled(Stack)({
  padding: "12px 16px",
  height: "56px",
  gap: "20px",
  borderBottom: "1px solid #E5E5E3",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const StyledFooter = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ isMobile }) => ({
  padding: "12px 20px 20px 20px",
  gap: "20px",
  borderTop: "1px solid #E5E5E3",
  ...(isMobile && {
    position: "fixed",
    background: "inherit",
    width: "100%",
    bottom: 0,
    backdropFilter: "blur(15px)",
  }),
}));

const StyledSummary = styled(Stack)({
  flexDirection: "row",
  alignItems: "flex-start",
  flex: 1,
  gap: "16px",
  justifyContent: "space-between",
});
const StyledStack = styled(Stack)({
  height: "calc(100% - 56px)",
  justifyContent: "space-between",
});
