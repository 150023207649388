import {
  alpha,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import React from "react";
import GiveButton from "shared/Button/GiveButton";
import GiveText from "shared/Text/GiveText";
import { Icon } from "./GivePopupIcons";
import { ButtonConfig, Props } from "./GivePopupTypes";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GiveIconButton from "@shared/IconButton/GiveIconButton";

const GivePopup: React.FC<Props> = ({
  type,
  title,
  description,
  open,
  onClose,
  isMobile,
  iconType,
  buttons,
  actionButtons: actions,
  sx,
  paperSx = {},
  showCancelBtn,
  inputNodes,
  inputSchema,
  inputDefaults,
}) => {
  const { palette } = useAppTheme();

  const methods = useForm({
    resolver: inputSchema ? yupResolver(inputSchema) : undefined,
    defaultValues: inputDefaults || {},
  });

  const defaultButtons: ButtonConfig[] = [
    {
      label: actions?.cancel?.label || "Discard Changes",
      onClick: actions?.cancel?.onClick || onClose,
      variant: "ghost",
    },
    {
      label: actions?.success?.label || "Save",
      onClick: actions?.success?.onClick || onClose,
      variant: "filled",
      color: type === "success" ? "primary" : "destructive",
      disabled: type === "destructive",
    },
  ];

  const actionButtons = buttons || defaultButtons;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        elevation: 0,
        sx: {
          maxWidth: isMobile ? "100%" : "600px",
          width: isMobile ? "100%" : "440px",
          borderRadius: "20px !important",
          maxHeight: isMobile
            ? "calc(100% - 48px) !important"
            : "calc(100% - 96px) !important",
          ...(isMobile && {
            margin: "48px 0 0 !important",
            borderRadius: "16px 16px 0 0 !important",
          }),
          ...paperSx,
        },
      }}
      sx={{
        background:
          palette?.surface?.primary &&
          alpha(palette?.surface?.primary || "#FFFFF", 0.4),
        "& .MuiDialogContent-root": {
          border: "unset",
          padding: "0 20px",
        },
        "& .MuiDialogTitle-root, .MuiDialogActions-root": {
          padding: "20px",
        },
        "& .MuiDialog-container": {
          display: "flex",
          alignItems: isMobile ? "flex-end" : "center",
          justifyContent: "center",
        },
        ...sx,
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Icon type={iconType || type} />
          {(!isMobile || showCancelBtn) && (
            <GiveIconButton
              aria-label="close"
              onClick={onClose}
              Icon={X}
              bgColor="solidWhite"
            />
          )}
        </Stack>
      </DialogTitle>
      <FormProvider {...methods}>
        <DialogContent dividers>
          <GiveText variant="h4" paddingBottom="10px">
            {title}
          </GiveText>
          <GiveText gutterBottom variant="bodyS">
            {description}
          </GiveText>
          {inputNodes && (
            <Stack gap="20px" paddingTop="10px" component="form">
              {inputNodes.map((item, i) => (
                <React.Fragment key={i}>{item.element}</React.Fragment>
              ))}
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          {actionButtons.map(
            (
              {
                label,
                onClick,
                variant,
                color,
                disabled,
                sxButton,
                submitType,
                hidden,
              },
              index,
            ) => {
              if (hidden) return null;
              return (
                <GiveButton
                  key={index}
                  onClick={
                    inputNodes && submitType === "submit"
                      ? methods.handleSubmit(onClick)
                      : onClick
                  }
                  label={label}
                  variant={variant}
                  color={color}
                  size="large"
                  disabled={
                    disabled ||
                    (inputSchema &&
                      !methods.formState.isValid &&
                      submitType === "submit")
                  }
                  sx={sxButton}
                />
              );
            },
          )}
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default GivePopup;
