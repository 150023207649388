import { Text } from "@common/Text";
import { palette } from "@palette";
import { Stack } from "@mui/material";
import { DisputesTableTag } from "@common/Tag/DisputesTableTag";
import { Button } from "@common/Button";
import NiceModal from "@ebay/nice-modal-react";
import { RESPOND_TO_DISPUTE_MODAL } from "modals/modal_names";
import { Tooltip } from "@common/Tooltip";
import { WarningDiamond } from "@phosphor-icons/react";
import { DisputeCaseType, DisputeTagType, TDisputeData } from "../data.types";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { useMemo } from "react";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

type Props = {
  data: {
    dispute: TDisputeData;
    history: DisputeCaseType[];
  };
};

const TitleSection = ({ data }: Props) => {
  const lastCase = data?.history?.[0];
  const lastCaseDraftEvidences = lastCase?.evidences?.filter(
    (evidence) => !evidence?.isSubmitted,
  );
  const isDraft = (lastCaseDraftEvidences?.length || 0) > 0;

  const handleOpenModal = () => {
    NiceModal.show(RESPOND_TO_DISPUTE_MODAL, {
      disputeId: data?.dispute?.id,
      lastCaseId: lastCase?.id,
      merchantId: data?.dispute?.merchant?.accID,
      isDraft,
      caseAction: lastCase?.statusAction,
    });
  };

  const dueTime = useMemo(() => {
    const responseDueAt = data?.dispute?.lastCase?.responseDueAt;

    if (!responseDueAt) return "";

    const dueDate = fromUnixTime(responseDueAt);

    return formatDistanceToNow(dueDate, { addSuffix: true });
  }, [data?.dispute?.lastCase?.responseDueAt]);

  const hiddenRespondAction = ["closed", "won", "lost"].includes(
    data?.dispute?.status,
  );

  const isPassedDueTime = data?.dispute?.lastCase?.responseDueAt
    ? fromUnixTime(data?.dispute?.lastCase?.responseDueAt) < new Date()
    : true;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <Text
            fontSize={24}
            fontWeight="book"
            color={palette.neutral[80]}
            lineHeight="32.4px"
          >
            Dispute Case
          </Text>
          {data?.dispute?.markedAsFraud && (
            <Tooltip
              placement="top"
              title="Marked as fraud"
              bgColor={palette.neutral[80]}
              titleSx={{
                fontSize: 12,
                fontWeight: 350,
              }}
              toolTipSx={{
                padding: "4px 16px",
                borderRadius: "8px",
                top: 8,
              }}
            >
              <WarningDiamond
                size={20}
                weight="duotone"
                color={palette.tag.warning.hover}
                data-testid="marked-as-fraud-sign"
              />
            </Tooltip>
          )}
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <WithTooltipWrapper
            hasTooltip={data?.dispute?.statusDisplayName === "Unknown"}
            tooltipProps={{
              message: data?.dispute?.mspCaseStatus,
              show: data?.dispute?.statusDisplayName === "Unknown",
            }}
          >
            <DisputesTableTag
              type={
                data?.dispute?.statusDisplayName?.toLowerCase() as DisputeTagType
              }
              sx={{ minWidth: "auto !important" }}
            />
          </WithTooltipWrapper>
          {!hiddenRespondAction && dueTime && (
            <Text
              fontWeight="book"
              fontSize={12}
              color={palette.neutral[80]}
              lineHeight="14.4px"
            >
              Due {dueTime}
            </Text>
          )}
        </Stack>
      </Stack>
      {!hiddenRespondAction && !isPassedDueTime && (
        <Stack direction="row" spacing={1} alignItems="center">
          {isDraft && (
            <Text color={palette.neutral[70]} fontSize={12} lineHeight="14.4px">
              Draft saved
            </Text>
          )}
          <Button
            size="medium"
            sx={{ fontWeight: 400, lineHeight: "14px" }}
            onClick={handleOpenModal}
          >
            Respond to Dispute
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default TitleSection;
