import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { HandCoins } from "@phosphor-icons/react";
import HFGiveCustomAmount from "@shared/HFInputs/HFGiveCustomAmount/HFGiveCustomAmount";
import HFGiveSwitch from "@shared/HFInputs/HFGiveSwitch/HFGiveSwitch";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import HFGiveSelect from "@shared/HFInputs/HFGiveSelect/HFGiveSelect";
import { recurringOptions } from "@sections/PayBuilder/items/AddProductItemModal";
import { Controller } from "react-hook-form";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { compact, isEmpty, uniq } from "lodash";
import { useEffect } from "react";

const AmountsSection = () => {
  const { methods } = usePayBuilderForm();

  const { clearErrors, setError, watch, setValue } = methods;

  const optionalAmounts = watch("Donations.optionalAmounts");
  const isSuggestedAmountEnabled = watch("Donations.enableSuggestedAmounts");
  const isCustomAmountAllowed = watch("Donations.allowCustomAmount");
  const suggestedAmount = watch("Donations.suggestedAmount");
  const isRecurringDonationEnabled = watch(
    "Donations.enableRecurringDonations",
  );

  const selectedItems = watch("Donations.recurringIntervals");

  const onHandleBlur =
    (index: number) =>
    ({ name }: { name: string }) => {
      if (!optionalAmounts) return;

      const compactOptionalAmounts = compact(optionalAmounts);

      // When user removes item that is selected, we should automatically set it to first available value
      if (
        compactOptionalAmounts.length &&
        !compactOptionalAmounts.includes(suggestedAmount)
      ) {
        const firstItem = compactOptionalAmounts[0];
        setValue("Donations.suggestedAmount", firstItem);
      }

      const amountAtInputIndex = optionalAmounts?.[index];

      optionalAmounts.forEach((amount, idx, array) => {
        let isError = false;

        if (compact(array)?.length < 3) {
          const isValue = !!amountAtInputIndex;
          if (isValue) {
            isError = false;
            clearErrors(name as any);
          } else {
            isError = true;
            setError(name as any, {
              message: "This field is required",
            });
          }
        } else if (compact(array)?.length >= 3) {
          const isDuplicated =
            array.filter(
              (value) => convertToNumber(value) === convertToNumber(amount),
            ).length > 1;
          if (!isDuplicated) clearErrors(`Donations.optionalAmounts.${idx}`);
        }

        if (isError) return;

        const isDuplicated =
          array.filter(
            (value) =>
              convertToNumber(value) === convertToNumber(amountAtInputIndex),
          ).length > 1;

        if (isDuplicated && !!amountAtInputIndex) {
          setError(name as any, {
            message: "Value already exists",
          });
        } else {
          clearErrors(name as any);
        }
      });
    };

  useEffect(() => {
    if (!suggestedAmount) {
      setValue("Donations.suggestedAmount", optionalAmounts.filter(Boolean)[0]);
    }
  }, [suggestedAmount]);

  return (
    <Stack gap="12px">
      <Stack direction="row" spacing={2}>
        <HandCoins size={24} />
        <GiveText variant="bodyL">Amounts</GiveText>
      </Stack>
      <Stack paddingLeft="32px" gap="12px">
        <SwitchWithLabel
          name="Donations.allowCustomAmount"
          label="Enable Custom Amounts"
        />
        {isCustomAmountAllowed &&
          optionalAmounts.map((_, index) => {
            return (
              <HFGiveCustomAmount
                key={index}
                name={`Donations.optionalAmounts.${index}`}
                currency="usd"
                onHandleBlur={onHandleBlur(index)}
              />
            );
          })}

        {isCustomAmountAllowed && (
          <SwitchWithLabel
            name="Donations.enableSuggestedAmounts"
            label="Enable Suggested Amount"
            disabled={isEmpty(uniq(compact(optionalAmounts)))}
          />
        )}
        {/* AND all are mepty */}
        {isSuggestedAmountEnabled && isCustomAmountAllowed && (
          <HFGiveSelect
            name="Donations.suggestedAmount"
            disabled={isEmpty(uniq(compact(optionalAmounts)))}
            options={optionalAmounts.filter(Boolean).map((item: string) => ({
              label: item,
              value: item,
            }))}
          />
        )}

        <SwitchWithLabel
          name="Donations.enableRecurringDonations"
          label="Enable Recurring Donations"
        />
        {isRecurringDonationEnabled &&
          // remove one time option
          recurringOptions
            .slice(1)
            .map(({ value, label }: { value: string; label: string }) => {
              const checked = selectedItems.includes(value);
              return (
                <Stack key={value} direction="row" spacing={1}>
                  <Controller
                    control={methods.control}
                    name={`Donations.recurringIntervals` as any}
                    render={() => {
                      return (
                        <GiveCheckbox
                          checked={checked}
                          onChange={(event) => {
                            const newValue = event.target.checked
                              ? [...selectedItems, value]
                              : selectedItems.filter((item) => item !== value);

                            methods.setValue(
                              "Donations.recurringIntervals",
                              newValue,
                            );
                          }}
                          /**
                           * User should have at least one option selected and since the one_time option is included
                           * in the recurrence options's array but can't be removed, the minimal length is 2
                           */
                          disabled={checked && selectedItems.length === 2}
                        />
                      );
                    }}
                  />
                  <GiveText
                    color="secondary"
                    variant="bodyS"
                    alignItems="center"
                  >
                    {label}
                  </GiveText>
                </Stack>
              );
            })}
      </Stack>
    </Stack>
  );
};

const SwitchWithLabel = ({
  name,
  label,
  disabled = false,
}: {
  name: string;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <HFGiveSwitch disabled={disabled} name={name} />
      <GiveText color="primary" variant="bodyS">
        {label}
      </GiveText>
    </Stack>
  );
};

export default AmountsSection;

function convertToNumber(amount: string) {
  if (!amount) return amount;
  const sanitizedAmount = amount.replace(/,/g, "");
  const parsedAmount = parseFloat(sanitizedAmount);
  if (isNaN(parsedAmount)) return null;
  return parsedAmount;
}
