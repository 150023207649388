import { useRef } from "react";
import useInputFieldFocusListener from "@common/FilePreview/hooks/useInputFieldFocusListener";
import { Box } from "@mui/material";
import HFGiveIntegerInput from "@shared/HFInputs/HFGiveInput/HFGiveIntegerInput";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { PageInputPropsV2 } from "../types";

type IFormInputs = {
  page: string;
};

const PageInputV2 = ({
  page,
  onChange,
  numPages,
  onFocus,
}: PageInputPropsV2) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    defaultValues: { page: page.toString() },
  });

  useInputFieldFocusListener({ inputRef, onFocus });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const sanitizedInput = parseInt(data.page);

    if (!Number.isNaN(sanitizedInput)) {
      if (sanitizedInput <= numPages) {
        onChange(sanitizedInput);
      } else {
        onChange(numPages);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <HFGiveIntegerInput
          name="page"
          inputRef={inputRef}
          min={1}
          max={numPages}
          disabled={numPages === 1}
          sx={{
            "& .MuiInputBase-root": {
              width: "22px",
              height: "22px",
              padding: "4px",
              borderRadius: "4px",
              "& input": {
                fontSize: "12px",
                textAlign: "center",
              },
            },
          }}
          containerProps={{
            sx: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        />
      </Box>
    </FormProvider>
  );
};

export default PageInputV2;
