import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import {
  DonorComponent,
  DonorsSkeleton,
  TranslucentButton,
} from "./Atom.component";
import NiceModal from "@ebay/nice-modal-react";
import { DONORS_MODAL } from "modals/modal_names";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { useGetDonorsList } from "@sections/PayBuilder/provider/useManageApi";
import GiveDivider from "@shared/GiveDivider/GiveDivider";

function RecentDonors({ accentColor, isPreview }: any) {
  const { data } = usePayBuilderForm();
  const showDonorsModal = (type: string, accentColor: string) => {
    NiceModal.show(DONORS_MODAL, { type, accentColor, id: data?.id });
  };
  const { donors, isLoading, total } = useGetDonorsList({
    id: isPreview ? null : data?.id,
    max: 5,
  });
  const donorsData = isPreview ? donorsList : donors;

  if (!donorsData || donorsData.length === 0) {
    return null;
  }

  return (
    <Box>
      <GiveDivider />

      <GiveText fontSize="14px" color="primary">
        {isPreview ? donorsData?.length : total} donors
      </GiveText>
      {isLoading ? (
        <DonorsSkeleton count={5} />
      ) : (
        <>
          {donorsData?.map((donor) => {
            return (
              <DonorComponent
                key={donor.name}
                accentColor={accentColor}
                {...donor}
              />
            );
          })}
        </>
      )}

      <Stack mt="16px" gap="15px" flexDirection="row">
        <TranslucentButton
          label="See All"
          variant="filled"
          size="small"
          onClick={() => showDonorsModal("most_recent", accentColor)}
        />
        <TranslucentButton
          label="Top Donors"
          variant="filled"
          size="small"
          onClick={() => showDonorsModal("top_donors", accentColor)}
        />
      </Stack>
    </Box>
  );
}

export default RecentDonors;

const donorsList = [
  {
    amount: "20",
    name: "John Smith",
    isTopDonor: false,
    when: "1d ago",
  },
  {
    amount: "10",
    name: "Emma Johnson",
    isTopDonor: false,
    when: "1d ago",
  },
  {
    amount: "100",
    name: "Sarah Davis",
    isTopDonor: true,
    when: "1d ago",
  },
  {
    amount: "50",
    name: "Olivia Garcia",
    isTopDonor: false,
    when: "2d ago",
  },
  {
    amount: "20",
    name: "Sophia Harris",
    isTopDonor: false,
    when: "3d ago",
  },
];
