import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { Eye, PencilSimple, X } from "@phosphor-icons/react";
import { StackRow } from "./StackRow";
import { useMobileLayoutHandler } from "../provider/Switcher";
import { SaveAndCloseBtn } from "./SaveAndCloseBtn";

export const MobileLeftPanelMainHeader = () => {
  const { activeView, toggleView } = useMobileLayoutHandler();

  return (
    <StackRow
      sx={{
        display: "flex",
        justifyContent: "space-between",
        aligItems: "center",
        width: "100%",
        padding: "20px 6px",
      }}
    >
      <SaveAndCloseBtn />
      {activeView === "builder" ? (
        <GiveIconButton
          Icon={Eye}
          size="small"
          variant="ghost"
          iconText="Preview"
          onClick={() => toggleView()}
          sx={{
            display: "flex",
            gap: 1,
          }}
        />
      ) : (
        <GiveIconButton
          Icon={PencilSimple}
          size="small"
          variant="ghost"
          iconText="Edit Form"
          onClick={() => toggleView()}
          sx={{
            display: "flex",
            gap: 1,
          }}
        />
      )}
    </StackRow>
  );
};
