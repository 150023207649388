import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverlay,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useDraggable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";

import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import KotoLinearProgress from "@common/SignUp/LinearProgress";
import {
  ProgressItem,
  useGetUploadProgress,
  useUploadProgress,
} from "@redux/slices/uploadProgressSlice";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useDeleteFile } from "@hooks/upload-api/useDeleteFile";
import { useDebouncedCallback } from "use-debounce";
import { useDeleteAccountFile } from "@hooks/upload-api/useDeleteAccountFile";
import { useQueryClient } from "react-query";
import GiveSnackbar from "@shared/Snackbar/GiveSnackbar";
import GiveButton from "@shared/Button/GiveButton";
import { SnackbarIconsType } from "@shared/Snackbar/GiveSnackbarTypes";
import { useAppTheme } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const useMatchQueriesProgress = () => {
  const { mutateAsync: deleteFile, isLoading: isLoadingDelete } =
    useDeleteFile();
  const { mutateAsync: deleteAccountFile, isLoading: isLoadingAccountDelete } =
    useDeleteAccountFile();
  const { merchantId } = useGetCurrentMerchantId();
  const progress = useGetUploadProgress();
  const { deleteUploadProgress, hideUploadProgress, setUploadProgress } =
    useUploadProgress();
  return {
    items: progress,
    deleteUploadProgress,
    hideUploadProgress,
    setUploadProgress,
    merchantId,
    deleteFile,
    isLoadingDelete,
    deleteAccountFile,
    isLoadingAccountDelete,
  };
};
const bufferSize = 50;
const ItemHeightWithMargins = 70;

const GiveNotificationStack = () => {
  const queryClient = useQueryClient();
  const [exitAnimation, setExitAnimation] = useState(false);
  const {
    items,
    setUploadProgress,
    hideUploadProgress,
    merchantId,
    deleteFile,
    deleteAccountFile,
  } = useMatchQueriesProgress();

  //
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );
  const [dragX, setDragX] = useState<number>(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [isDragging, setIsDragging] = useState<string | undefined>(undefined);
  const containerRef = useRef<HTMLInputElement>(null);
  const isDraggingRef = useRef<string | undefined>(undefined);
  const currentDraggingItem = isDragging ? items[isDragging] : undefined;
  function handleDragStart(e: DragEndEvent) {
    const activeID = e.active.id as string;
    setIsDragging(activeID);
    isDraggingRef.current = activeID;
  }

  const handleDragEnd = (e: DragEndEvent) => {
    setIsDragging(undefined);
    isDraggingRef.current = undefined;
    setDragX(0);
    if (Math.abs(e.delta.x) > 100) {
      const id = e.active.id;
      hideUploadProgress(id as string);
    }
  };
  const handleDragMove = (event: DragMoveEvent) => {
    setDragX(event.delta.x);
  };
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (containerRef.current) {
        const childElements = containerRef.current.getElementsByTagName("*");
        let minLeft = Infinity,
          minTop = Infinity,
          maxRight = -Infinity,
          maxBottom = -Infinity;

        // Calculate the bounding box of all child elements
        Array.from(childElements).forEach((element) => {
          const rect = element.getBoundingClientRect();
          minLeft = Math.min(minLeft, rect.left);
          minTop = Math.min(minTop, rect.top);
          maxRight = Math.max(maxRight, rect.right);
          maxBottom = Math.max(maxBottom, rect.bottom);
        });

        // Add buffer to the bounding box
        minLeft -= bufferSize;
        minTop -= bufferSize;
        maxRight += bufferSize;
        maxBottom += bufferSize;

        // Check if mouse is inside the calculated bounding box (including buffer)
        const isInside =
          event.clientX >= minLeft &&
          event.clientX <= maxRight &&
          event.clientY >= minTop &&
          event.clientY <= maxBottom;

        !isDraggingRef.current && setExpanded(isInside);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const hideSnackbar = useDebouncedCallback(() => {
    if (expanded) return;
    setExitAnimation(true);
    setTimeout(() => {
      Object.keys(items).forEach((key) => {
        hideUploadProgress(key);
        setExitAnimation(false);
      });
    }, 500);
  }, 2500);

  useEffect(() => {
    const noCurrentUploading =
      Object.values(items).length >= 1 &&
      Object.values(items).every(
        (item) =>
          item.success ||
          item.failed ||
          item.canceled ||
          item.tooLarge ||
          item.unsuported ||
          item.tooManyFiles,
      );

    if (noCurrentUploading && !expanded) {
      hideSnackbar();
    } else {
      hideSnackbar.cancel();
    }
  }, [items, hideUploadProgress, expanded, items]);

  const handleCancel = (key: string) => {
    const item = items[key];
    item.abortController?.abort();
    setUploadProgress({
      key,
      data: {
        canceled: true,
      },
    });
    hideUploadProgress(key);
  };
  const handleDelete = async (key: string) => {
    const item = items[key];

    try {
      if (item.accountUpload && item.id) {
        await deleteAccountFile({
          fileID: item.id,
          id: item.accountID,
        });
        if (item.refetcherKey) {
          queryClient.invalidateQueries(item.refetcherKey);
        }
      } else {
        await deleteFile({
          accountToRequest: merchantId.toString(),
          identifier: item.id as string,
        });
      }

      setUploadProgress({
        key,
        data: {
          deleted: true,
        },
      });
    } catch {
      // failed to delete notification?
    }
  };

  function getHeight() {
    // totalnumber of items x their individual height + padding
    const height = Object.keys(items).length * ItemHeightWithMargins;

    return height;
  }

  const itemsToMap = Object.keys(items);
  const { isMobileView } = useCustomThemeV2();

  return (
    <DndContext
      collisionDetection={closestCorners}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      sensors={sensors}
    >
      <div
        ref={parentRef}
        style={{
          bottom: 0,
          paddingLeft: "18px",
          paddingBottom: "18px",
          transition: "all 0.25s",
          position: "fixed",
          overflowY: "auto",
          overflowX: "visible",
          width: itemsToMap && itemsToMap.length > 0 ? "380px" : "0px",
          display: "flex",
          flexDirection: "column-reverse",
          height:
            getHeight() > window.innerHeight
              ? window.innerHeight
              : getHeight() + 18,
          ...(!isMobileView && {
            left: window.innerWidth - 320 - 50,
          }),
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div
          ref={containerRef}
          style={{
            bottom: 0,
            transition: "all 0.25s",
            position: "relative",
            height: getHeight(),
            ...(isMobileView && {
              display: "flex",
              justifyContent: "center",
            }),
          }}
        >
          {itemsToMap.map((key, _, arr) => {
            return (
              <DraggableItem
                key={key}
                id={key}
                item={items[key]}
                i={items[key].index}
                expanded={expanded}
                totalLength={arr.length}
                handleCancel={() => handleCancel(key)}
                handleDelete={() => handleDelete(key)}
                isDragging={isDragging}
                exitAnimation={exitAnimation}
              />
            );
          })}
        </div>
      </div>
      <DragOverlay>
        {isDragging ? (
          <>
            <IndividualCard
              id="0"
              dragX={dragX}
              item={currentDraggingItem as ProgressItem}
              exitAnimation={exitAnimation}
            />
          </>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

const DraggableItem = React.memo(
  ({
    id,
    item,
    i,
    expanded,
    totalLength,
    handleCancel,
    handleDelete,
    isDragging,
    exitAnimation,
  }: {
    id: string;
    item: ProgressItem;
    i: number;
    expanded: boolean;
    totalLength: number;
    handleCancel: () => void;
    handleDelete: () => void;
    isDragging: string | undefined;
    exitAnimation: boolean;
  }) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const [hovered, setHovered] = useState(false);
    useEffect(() => {
      const handleButtonMouseOver = (): void => {
        setHovered(true);
      };
      const handleMouseOut = (): void => {
        setHovered(false);
      };
      const buttonElement = itemRef.current;

      if (buttonElement) {
        buttonElement.addEventListener("mouseenter", handleButtonMouseOver);
      }
      if (buttonElement) {
        buttonElement.addEventListener("mouseleave", handleMouseOut);
      }

      return () => {
        if (buttonElement) {
          buttonElement.removeEventListener(
            "mouseenter",
            handleButtonMouseOver,
          );
          buttonElement.removeEventListener("mouseleave", handleMouseOut);
        }
      };
    }, [itemRef, hovered]);
    //
    const { attributes, listeners, setNodeRef } = useDraggable({
      id: id,
    });
    const [props] = useSpring(() => ({
      from: { x: 100, opacity: 0 },
      to: { x: 0, opacity: 1 },
      config: {
        tension: 200,
        friction: 10,
        mass: 1,
      },
    }));
    function getScale(expanded: boolean, i: number) {
      if (expanded) {
        return 1;
        1;
      }
      return 1 / (1 + 0.2 * i ** 2);
    }
    function getTranslate(expanded: boolean, i: number) {
      if (expanded) {
        return i;
      }
      if (i === 0) {
        return 0;
      }
      if (i === 1) {
        return 0.4;
      }
      if (i === 2) {
        return 0.6;
      }
      return 0;
    }

    const currentDragging = isDragging === id;
    return (
      <div
        style={{
          position: "absolute",
          bottom: 0,
          transform: `translateY(-${
            getTranslate(expanded, i) * ItemHeightWithMargins
          }px) scale(${getScale(expanded, i)}) ${
            exitAnimation ? "translateX(100px)" : ""
          }`,
          transformOrigin: "center",
          zIndex: totalLength - i,
          transition: currentDragging ? "transform 0.25s" : "all 0.25s",
          opacity: currentDragging ? 0 : exitAnimation ? 0 : 1,
        }}
      >
        <animated.div
          style={{
            ...props,
          }}
          ref={itemRef}
        >
          <IndividualCard
            id={id}
            setNodeRef={setNodeRef}
            item={item}
            listeners={listeners}
            attributes={attributes}
            hovered={hovered}
            handleCancel={handleCancel}
            handleDelete={handleDelete}
            exitAnimation={exitAnimation}
          />
        </animated.div>
      </div>
    );
  },
);
DraggableItem.displayName = "DraggableItem";
const IndividualCard: React.FC<any> = React.memo(
  ({
    setNodeRef,
    listeners,
    attributes,
    transform,
    item,
    handleCancel,
    dragX,
  }: {
    setNodeRef?: (node: HTMLElement | null) => void;
    listeners?: any;
    attributes?: any;
    transform?: any;
    item: ProgressItem;
    id: string;
    hovered?: boolean;
    handleCancel?: () => void;
    handleDelete?: () => void;
    dragX?: number;
    exitAnimation: boolean;
  }) => {
    const opacity = dragX ? Math.max(0, 1 - Math.abs(dragX) / 100) : 1;
    const { palette } = useAppTheme();
    const { isMobileView } = useCustomThemeV2();
    return (
      <Box
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        sx={{
          display: "flex",
          justifyContent: isMobileView ? "center" : "flex-end !important",
          transform: CSS.Translate.toString(transform),
          height: "60px",
          width: "320px",
          opacity: opacity,
        }}
      >
        <GiveSnackbar
          description={
            ["uploading", "uploaded"].includes(getFileStatus(item))
              ? fileStatusLabel[getFileStatus(item)]
              : undefined
          }
          type={fileStatusToSnackbarLabelMap[getFileStatus(item)]}
          title={
            ["uploading", "uploaded"].includes(getFileStatus(item))
              ? item.fileName
              : fileStatusLabel[getFileStatus(item)]
          }
          isMobile={false}
          progress={
            fileStatusLabel[getFileStatus(item)] === "Uploading..."
              ? item.progress || 0
              : undefined
          }
          rightContent={
            getFileStatus(item) === "uploading" ? (
              <GiveButton
                label="Cancel"
                size="small"
                sx={{
                  backgroundColor: palette.text?.secondary,
                  borderRadius: "8px",
                }}
                onClick={handleCancel}
              />
            ) : undefined
          }
        />
      </Box>
    );
  },
);
IndividualCard.displayName = "IndividualCard";
export const UploadSnackbarLinearProgress = ({
  item,
}: {
  item: ProgressItem;
}) => (
  <KotoLinearProgress
    isActive
    sx={{
      height: "4px",
      position: "auto",
      ".MuiLinearProgress-bar": {
        backgroundColor: "neutral.black",
        borderRadius: "8px !important",
      },
      opacity: getFileStatus(item) === "uploading" ? 1 : 0,
      transition: "opacity 0.25s",
    }}
    value={item.progress || 0}
  />
);
export type FileStatusUnion =
  | "uploaded"
  | "uploading"
  | "failed"
  | "canceled"
  | "too-large"
  | "not-supported"
  | "deleted"
  | "too-many-files";

export const fileStatusLabel = {
  uploaded: "Uploaded!",
  uploading: "Uploading...",
  "not-supported": "File type not supported",
  failed: "Failed",
  "too-large": "File too large",
  deleted: " Deleted",
  canceled: "Canceled",
  "too-many-files": "Too many files",
};

export const getFileStatus: (item: ProgressItem) => FileStatusUnion = (
  item: ProgressItem,
) => {
  if (item?.canceled) {
    return "canceled";
  } else if (item?.failed) {
    return "failed";
  } else if (item?.tooLarge) {
    return "too-large";
  } else if (item?.tooManyFiles) {
    return "too-many-files";
  } else if (item?.unsuported) {
    return "not-supported";
  } else if (item?.deleted) {
    return "deleted";
  } else if (item?.success) {
    return "uploaded";
  } else if (item?.progress && item?.progress < 100) {
    return "uploading";
  }
  return "uploading";
};

export const fileStatusToSnackbarLabelMap: Record<
  FileStatusUnion,
  SnackbarIconsType
> = {
  canceled: "upload",
  failed: "error",
  "too-large": "warning",
  "too-many-files": "warning",
  deleted: "error",
  uploaded: "success",
  uploading: "upload",
  "not-supported": "warning",
};

export default GiveNotificationStack;
