export const brandingHeadings = {
  logo: {
    title: "Portal logo",
    description:
      "These images will be utilized when inviting merchants and will be featured in various sections on the platform, effectively extending your provider's branding to your merchant partners",
  },
  cards: {
    title: "Theme colors",
    description:
      "This color palette will be inherited by all merchants operating under your provider",
  },
  iframe: {
    title: "Embed Code for Signup",
    description:
      "A snippet of code that allows you to integrate a Sign Up form directly into your website.",
  },
};
