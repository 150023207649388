import Delivery from "./components/Delivery";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";

const ProductCheckout = ({
  isMobileView,
  isDisabledFields = false,
}: {
  isMobileView: boolean;
  isDisabledFields?: boolean;
}) => {
  const { methods: leftSidepanelMethods } = usePayBuilderForm();

  const { render: isDeliveryEnabled } =
    leftSidepanelMethods.watch().Checkout.delivery;

  return (
    <>
      {isDeliveryEnabled && (
        <Delivery isMobileView={isMobileView} isDisabledFields={isDisabledFields} />
      )}
    </>
  );
};

export default ProductCheckout;
