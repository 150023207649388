// mui
import Box from "@mui/material/Box";
// components
import Layout from "./Layout";
import { Drawer } from "@components/Drawer";
// data
import { Suspense, memo, useEffect, useState } from "react";
import PrivateRoutes from "routes/PrivateRoutes";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import BreadcrumbsPageContainer from "@components/CustomBreadcrumbsPage/BreadcrumbsPage";
import InternetStatusWrapper from "@components/Wrapper/InternetStatusWrapper";
import { FileUploadSnackbarContainer } from "@components/UploadFile/FileUploadSnackbarContainer";
import { useGetEnterpriseConfiguration } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useGetEnterpriseConfiguration";
import { checkPortals } from "@utils/routing";
import { useRedirectTransaction } from "./hooks/useRedirectTransaction";
import useManageQueryRedirect from "@hooks/useManageQueryRedirect";
import { useRedirectToMerchantPanel } from "./hooks/useRedirectToMerchantPanel";
import SideMenuDrawer from "componentsV2/SideMenu/SideMenuDrawer";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const Base = () => {
  const { isNoSidebar, isDashBoard } = checkPortals();
  const [winHeight, setWinHeight] = useState(window.innerHeight);
  const { isRebrandingEnabled } = useGetFeatureFlagValues();

  useGetEnterpriseConfiguration();
  useRedirectTransaction();
  useManageQueryRedirect();
  useRedirectToMerchantPanel();

  useEffect(() => {
    const updateHeight = () => {
      setWinHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateHeight);
    window.addEventListener("orientationchange", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("orientationchange", updateHeight);
    };
  }, []);

  if (isNoSidebar) {
    return (
      <Box component="main">
        <Suspense fallback={<LoadingSpinner />}>
          {PrivateRoutes()}
          <FileUploadSnackbarContainer />
        </Suspense>
      </Box>
    );
  }

  return (
    <Layout bgcolor={isDashBoard ? "#FFFFFF" : undefined} winHeight={winHeight}>
      {isRebrandingEnabled ? <SideMenuDrawer /> : <Drawer />}
      <BreadcrumbsPageContainer>
        <InternetStatusWrapper>
          <Suspense fallback={<LoadingSpinner />}>
            {PrivateRoutes()}
            <FileUploadSnackbarContainer />
          </Suspense>
        </InternetStatusWrapper>
      </BreadcrumbsPageContainer>
    </Layout>
  );
};

export default memo(Base);
