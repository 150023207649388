import { styled } from "@theme/v2/Provider";
import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveButton from "@shared/Button/GiveButton";
import { UploadSimple } from "@phosphor-icons/react";
import GiveThumbnail, {
  GiveThumbnailProps,
} from "@shared/Thumbnail/GiveThumbnail";

type TImagePreview = {
  imageUrl: string | undefined;
  title: string;
  hasPickedAFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete?: () => void;
  canUpload?: boolean;
  canDelete?: boolean;
  thumbnailType: GiveThumbnailProps["type"];
};

const ImagePreview = ({
  imageUrl,
  thumbnailType,
  title,
  hasPickedAFile,
  handleDelete,
  canUpload,
  canDelete,
}: TImagePreview) => {
  return (
    <>
      <ImageContainer>
        <GiveThumbnail
          type={thumbnailType}
          size="large"
          imageUrl={imageUrl}
          name={title}
        />

        <GiveText color="primary" variant="bodyM">
          {title}
        </GiveText>
      </ImageContainer>

      <Buttons>
        <GiveButton
          label="Remove image"
          color="destructive"
          size="large"
          variant="ghost"
          disabled={!canDelete || !handleDelete}
          onClick={handleDelete}
        />

        <label htmlFor="contained-button-file">
          <Input
            accept="image/jpeg, image/png"
            type="file"
            disabled={!canUpload || !hasPickedAFile}
            onChange={hasPickedAFile}
            id="contained-button-file"
          />
          <GiveButton
            component="span"
            label="Upload image"
            size="large"
            disabled={!canUpload || !hasPickedAFile}
            startIcon={<UploadSimple size={18} />}
          />
        </label>
      </Buttons>
    </>
  );
};

const ImageContainer = styled(Stack)(() => ({
  margin: "16px 0 40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
}));

const Buttons = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: "12px",
  flexDirection: "row",
  justifyContent: "flex-end",
}));

const Input = styled("input")({
  display: "none",
});

export default ImagePreview;
