import { TAG_STATUSES_ENUM } from "@common/TextTag/types";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { MerchantPreviewModalProps } from "@components/Merchants/MerchantPreview/MerchantPreviewModal";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import { getMerchantStatus } from "@hooks/enterprise-api/merchants/useGetColumns";
import { useAppSelector } from "@redux/hooks";
import {
  selectMerchantStatusName,
  selectMerchantTab,
} from "@redux/slices/enterprise/merchants";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useMerchantSidePanel from "../hooks/useMerchantSidePanel";
import { useModal } from "@ebay/nice-modal-react";
import { TabNames } from "features/Merchants/MerchantsTable/hooks/useTabs";

type OpenChallengeType = { identifier: string; displayName: string };

type SidepanelMerchantTypes = ReturnType<typeof useMerchantSidePanel> & {
  id: number;
  isEnterprise: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  status: TAG_STATUSES_ENUM | null;
  tableRowData?: MerchantData;
  isSponsorView: boolean;
  setOpenChallenge: React.Dispatch<
    React.SetStateAction<OpenChallengeType | undefined>
  >;
  openChallenge?: OpenChallengeType;
  setOpenOldPanel?: Dispatch<SetStateAction<boolean>>;
  isUnderwritingView: boolean;
  isOnboardingView: boolean;
  openUnderwritingView: () => void;
  isLoadingUnderwriting: boolean;
  setIsLoadingUnderwriting: Dispatch<SetStateAction<boolean>>;
  isAllTabView: boolean;
  isOnboardingCompleted: boolean;
  completeOnboarding: () => void;
};

const MerchantSidePanelContext = createContext<SidepanelMerchantTypes>(
  {} as any,
);

interface Props extends MerchantPreviewModalProps {
  children: React.ReactNode;
  tableRowData?: MerchantData;
  setOpenOldPanel?: Dispatch<SetStateAction<boolean>>;
  showUnderwritingView?: boolean;
  showOnboardingView?: boolean;
  setReopening: (v: boolean) => void;
}

function MerchantSidePanelProvider({
  children,
  isFirst,
  isLast,
  setSelectedRow,
  // status,
  tableRowData,
  setOpenOldPanel,
  showUnderwritingView = false,
  showOnboardingView = false,
  setReopening,
  ...rest
}: Props) {
  const [isLoadingUnderwriting, setIsLoadingUnderwriting] = useState(true);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const [openChallenge, setOpenChallenge] = useState<
    OpenChallengeType | undefined
  >(undefined);

  const status = useMemo(
    () =>
      tableRowData
        ? (getMerchantStatus(tableRowData) as TAG_STATUSES_ENUM)
        : null,
    [tableRowData],
  );

  const statusName = useAppSelector(selectMerchantStatusName);
  const tabName = useAppSelector(selectMerchantTab);

  // Data from this hook is being used across the entire panel so its better to have it
  // in the context then data specific to a section we can call it there
  const data = useMerchantSidePanel({
    id: rest?.id,
    setSelectedRow,
  });

  useEffect(() => {
    //reset this when merchant ID changes otherwise the loading will only work on first anel open
    if (!isLoadingUnderwriting) setIsLoadingUnderwriting(true);

    //reset when id changes to have correct onboarding flow
    if (isOnboardingCompleted) setIsOnboardingCompleted(false);
  }, [rest?.id]);

  //TODO: clean up once tabs endpoint refactor is fininished
  const isUnderwritingTab =
    statusName === StatusNames.enterpriseUnderwriting ||
    statusName === StatusNames.aquirerUnderwriting ||
    statusName === StatusNames.underwritingAsProvider ||
    tabName === TabNames.underwriting;

  const isOnboardingTab = tabName === TabNames.onboarding;

  const isUnderwritingView = useMemo(() => {
    return isUnderwritingTab || showUnderwritingView;
  }, [isUnderwritingTab, showUnderwritingView]);

  const isOnboardingView = useMemo(() => {
    return (isOnboardingTab || showOnboardingView) && !isUnderwritingView;
  }, [isOnboardingTab, showOnboardingView, isUnderwritingView]);
  const { hide, resolveHide, show, args } = useModal();

  const openUnderwritingView = () => {
    const openOnboarding = data?.data?.status?.statusName === "onboarding";
    setReopening(true);
    hide().then(() => {
      setTimeout(() => {
        show({
          ...args,
          showUnderwritingView: !openOnboarding,
          showOnboardingView: openOnboarding,
        });
        setIsLoadingUnderwriting(true);
        setReopening(false);
      }, 200);
    });
    resolveHide();
  };

  const completeOnboarding = () => setIsOnboardingCompleted(true);

  const isSponsorView = useMemo(() => {
    const statusDisplayName = data?.merchant?.sponsorStatusDisplayName;

    const sponsorStatusDisplayName =
      statusDisplayName || tableRowData?.sponsorStatusDisplayName || "";

    const status =
      !!sponsorStatusDisplayName &&
      !["Approved", "Declined", "Pending"].includes(sponsorStatusDisplayName)
        ? "Ready for Review"
        : sponsorStatusDisplayName;

    return (
      (statusName === StatusNames.aquirerSponsor ||
        isUnderwritingTab ||
        statusName === "") &&
      ["Ready for Review", "Pending"].includes(status)
    );
  }, [statusName, tableRowData, data, isUnderwritingTab]);

  const isAllTabView = !statusName;

  return (
    <MerchantSidePanelContext.Provider
      value={{
        ...data,
        id: rest.id,
        setOpenChallenge,
        isEnterprise: false,
        isFirst,
        status,
        isLast,
        isSponsorView,
        openChallenge,
        setOpenOldPanel,
        isUnderwritingView,
        isOnboardingView,
        openUnderwritingView,
        isLoadingUnderwriting,
        setIsLoadingUnderwriting,
        isAllTabView,
        isOnboardingCompleted,
        completeOnboarding,
      }}
    >
      {children}
    </MerchantSidePanelContext.Provider>
  );
}

export default MerchantSidePanelProvider;

export const useMerchantSidePanelContext = () =>
  useContext(MerchantSidePanelContext);
