import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { StatusInfo } from "../../agreements.types";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveText from "@shared/Text/GiveText";
import useUpdateMerchant from "features/Merchants/MerchantSidePanel/hooks/useUpdateMerchant";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";

interface Props {
  statusInfo?: StatusInfo;
}

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  msaRefundPolicy: Yup.string()
    .trim()
    .min(3, "Refund policy must be at least 3 characters")
    .required("Refund policy is required"),
});

function RefundPolicy({ statusInfo }: Props) {
  const { status } = statusInfo || {};
  const { data } = useMerchantSidePanelContext();
  const { updateMerchantMutation } = useUpdateMerchant();
  const { agreement_signing } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();

  const isInputDisabled =
    status === "signed" ||
    updateMerchantMutation.isLoading ||
    (!agreement_signing && isEnterprisePortal);

  // Initialize useForm with validation schema
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      msaRefundPolicy: data?.merchantAgreement?.msaRefundPolicy || "",
    },
  });

  // Handle blur event
  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = e.target.value;
    // Check validation and update if valid
    methods.clearErrors("msaRefundPolicy");
    if (value) {
      const trimmedValue = value.trim();
      try {
        const { error } = validationSchema.validateSync({
          msaRefundPolicy: trimmedValue,
        });
        if (!error) {
          updateMerchantMutation.mutate({ msaRefundPolicy: trimmedValue });
        }
      } catch (err: any) {
        methods.setError("msaRefundPolicy", err.message);
      }
    }
  };

  return (
    <SectionCardBase sx={{ mt: "20px" }} leftTitle="Refund Policy">
      <FormProvider {...methods}>
        <HFGiveInput
          name="msaRefundPolicy"
          onBlur={handleBlur}
          multiline
          rows={6}
          placeholder="30 Days of Purchase"
          disabled={isInputDisabled}
        />
      </FormProvider>

      <GiveText mt="8px" variant="bodyXS" color="secondary">
        Please provide a detailed description of your refund policy. This should
        include conditions for eligibility, the refund process, and any time
        constraints.
      </GiveText>
    </SectionCardBase>
  );
}

export default RefundPolicy;
