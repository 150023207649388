import { Box, Grid } from "@mui/material";
import GradientPickerPopoverNew from "@components/EnterpriseSettings/Branding/components/GradientPickerPopoverNew";
import { useState } from "react";
import { useAppTheme } from "@theme/v2/Provider";
import { PencilSimple } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { rgbaToHex } from "@utils/index";

export const ColorPickerInput = ({ defaultColor, onSave }: any) => {
  const { palette } = useAppTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSetColor = (color: any) => {
    setColor(color);
    onSave(color);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [color, setColor] = useState(defaultColor);

  return (
    <>
      <Grid
        item
        gap="12px"
        sx={{
          background: palette.primitive?.transparent["darken-5"],
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          if (e.currentTarget) {
            setAnchorEl(e.currentTarget as any);
          }
        }}
      >
        <div
          style={{
            background: color,
            width: "24px",
            height: "24px",
            borderRadius: "100%",
          }}
        />
        <GiveText fontSize="14px" fontWeight={400}>
          {rgbaToHex(color, true).toUpperCase()}
        </GiveText>
        <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
          <PencilSimple size="20px" />
        </Box>
      </Grid>

      <GradientPickerPopoverNew
        anchorEl={anchorEl}
        onChange={handleSetColor}
        handleClose={handleClose}
        value={color}
        hideOpacity
        hideColorTypeBtns
        hideEyeDrop
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            border: "1px solid #CCCCC9", //another color not in the theme
          },
        }}
      />
    </>
  );
};
