import { TPrimaryAccountHolder } from "../data.types";
import NiceModal from "@ebay/nice-modal-react";
import { BUSINESS_PROFILE_MISSING_FIELDS_MODAL } from "modals/modal_names";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { useMemo } from "react";
import { palette } from "@palette";
import { Box } from "@mui/material";
import { Button } from "@common/Button";
import { isFunction } from "lodash";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { checkPortals } from "@utils/routing";

type StatusType = "approved" | "declined";

export default function usePrimaryAccountHolder({
  primaryAccountHolder,
  id,
  editHandler,
}: {
  primaryAccountHolder: TPrimaryAccountHolder;
  id: number;
  editHandler?: (() => void) | undefined;
}) {
  const queryClient = useQueryClient();
  const { isAcquirerEnterprises } = checkPortals();
  const updateOwnerStatus = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${id}/owner/status`,
      method: "PATCH",
      data,
    });
  });
  const { merchant_underwriting, modify_merchant } = useEnterprisePermissions();

  const isAllowedStatusUpdate = useAccessControl({
    resource: composePermission(
      isAcquirerEnterprises ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.OWNER,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const isAllowedUpdate = useAccessControl({
    resource: composePermission(
      isAcquirerEnterprises ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.OWNER,
    ),
    operation: OPERATIONS.CHANGE,
  });

  const { actions, missingFields } = useMemo(() => {
    const statusName = primaryAccountHolder?.owner?.statusName;
    const requiredFiled = [
      {
        field: "First name",
        missing: !primaryAccountHolder.firstName,
      },
      {
        field: "Last name",
        missing: !primaryAccountHolder.lastName,
      },
      {
        field: "Email",
        missing: !primaryAccountHolder?.email,
      },
    ];
    const actions = [
      {
        title: "Edit",
        hidden: !modify_merchant,
        disabled: !isAllowedUpdate,
        tooltipProps: {
          show: !isAllowedUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
        onClick: editHandler,
      },
      {
        title: "Approve",
        hidden: statusName === "approved" || !merchant_underwriting,
        onClick: () => handleAction("approved"),
        disabled:
          requiredFiled.some((field) => field.missing) || !isAllowedStatusUpdate,
        tooltipProps: {
          show: !isAllowedStatusUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
      },

      {
        title: "Decline",
        hidden: statusName === "declined" || !merchant_underwriting,
        onClick: () => handleAction("declined"),
        sx: { color: palette.filled.red },
        disabled: !isAllowedStatusUpdate,
        tooltipProps: {
          show: !isAllowedStatusUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
      },
    ];
    const missingFields = [
      {
        field: "documents",
        missing: !primaryAccountHolder.owner?.IDProofUploaded,
      },
      {
        field: "selfie photo",
        missing: !primaryAccountHolder.owner?.selfieUploaded,
      },
    ];
    return { actions, missingFields };
  }, [id, primaryAccountHolder]);

  const handleUpdateStatus = (status: StatusType, onSuccess?: () => void) =>
    updateOwnerStatus.mutate(
      { status },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            ["get-merchant-preview", id],
            (data: any) => {
              return {
                ...data,
                merchant: {
                  ...data.merchant,
                  legalEntity: {
                    ...data.merchant.legalEntity,
                    statusName: status,
                  },
                },
              };
            },
          );
          queryClient.refetchQueries(["get-merchant-preview", id]);
          isFunction(onSuccess) && onSuccess();
        },
      },
    );

  const handleAction = (status: StatusType) => {
    const isMissingFileds = missingFields.some((field) => field.missing);
    if (status === "approved") {
      return NiceModal.show(BUSINESS_PROFILE_MISSING_FIELDS_MODAL, {
        fieldsList: missingFields,
        title: "Approve Primary Account Holder",
        description: isMissingFileds
          ? "Are you sure you want to approve PAH without attaching:"
          : "Are you sure you want to approve this PAH?",
        hasCustomPadding: false,
        width: 450,
        actions: (cb: () => void) => (
          <ActionsButtons
            onApprove={() => handleUpdateStatus(status, cb)}
            cb={cb}
          />
        ),
      });
    }

    handleUpdateStatus(status);
  };
  return { handleAction, actions };
}

const ActionsButtons = ({
  cb,
  onApprove,
}: {
  cb: () => void;
  onApprove?: () => void;
}) => (
  <Box gap="16px" alignItems="center" display="flex">
    <Button background="tertiary" variant="secondary" onClick={cb}>
      No
    </Button>
    <Button
      onClick={onApprove}
      sx={{
        background: palette.filled.success,
        "&:hover": { background: palette.filled.success },
      }}
    >
      Yes, approve
    </Button>
  </Box>
);
