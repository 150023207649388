import NiceModal from "@ebay/nice-modal-react";
import { useState } from "react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { isFunction } from "lodash";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import EditProfileImageHeader from "./components/EditProfileImageHeader";
import { AcceptAllowedImagesTypes } from "@hooks/upload-api/uploadHooks";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";
import ImagePreview from "./components/ImagePreview";
import EditUploadedImage from "./components/EditUploadedImage";
import { GIVE_CONFIRMATION_POP_UP } from "modals/modal_names";
import { GiveThumbnailProps } from "@shared/Thumbnail/GiveThumbnail";

const SUBTITLE =
  "Image file must not exceed a size of 5MB.";

export type TActions = {
  onUpload?: (file: File) => void;
  onDelete?: () => void;
  canUpload?: boolean;
  canDelete: boolean;
};

interface EditProfileImageModalProps {
  title?: string;
  defaultImageURL?: string;
  thumbnailType?: GiveThumbnailProps["type"];
  rounded?: boolean;
  subTitle: string;
  actions: TActions;
  onLocalUpload?: (file: File) => boolean;
}

export const EditProfileImageModal = NiceModal.create(
  (props: EditProfileImageModalProps) => {
    const {
      title = "Profile Image",
      defaultImageURL,
      thumbnailType = "image",
      subTitle,
      actions,
      onLocalUpload = () => false,
    } = props;

    const { onUpload, onDelete, canUpload, canDelete } = actions;

    const { open, onClose } = useNiceModal();
    const { isValidFile } = useIsValidFile();
    const [image, setImage] = useState(defaultImageURL);
    const [isEditorVisible, setIsEditorVisible] = useState(false);

    const handleCancel = () => {
      setImage(defaultImageURL);
      onClose();
      setIsEditorVisible(false);
    };

    const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

    const handleResetImage = () => {
      setImage(defaultImageURL);
      setIsEditorVisible(false);
    };

    const handleDelete = () => {
      if (onDelete) onDelete();
      onClose();
    };

    const hasPickedAFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = e.target.files?.[0];
      const allowedTypes = Object.keys(AcceptAllowedImagesTypes);
      if (!file) return;

      if (!isFileUploadRefactorEnabled) {
        const allowedExtensions = ["jpg", "jpeg", "png"];
        const fileExtension = file.name?.split(".")?.pop()?.toLowerCase() || "";
        if (!allowedExtensions.includes(fileExtension)) return;
        const stopUpload = onLocalUpload(file);
        if (stopUpload) return;
      } else if (!isValidFile({ file, allowedTypes })) {
        return;
      }
      setIsEditorVisible(true);
      setImage(URL.createObjectURL(file));
    };

    const handleUpdateImage = async (url: HTMLCanvasElement) => {
      setIsEditorVisible(false);
      setImage(url.toDataURL());

      url.toBlob(async (blob) => {
        if (blob === null) return;
        const fileName = "image.jpeg";
        const mimeType = "image/jpeg";
        const file = new File([blob], fileName, { type: mimeType });
        isFunction(onUpload) && onUpload(file);
        onClose();
      });
    };

    const handleDeleteImage = () => {
      NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
        modalType: "delete",
        title: "Remove Profile Image",
        description:
          "Are you sure you want to remove merchant’s profile image? A picture helps people recognize the merchant",
        customSubmitBtnText: "Yes, Remove",
        actions: {
          handleSuccess: {
            onClick: handleDelete,
          },
        },
      });
    };

    return (
      <GiveBaseModal
        open={open}
        width={isEditorVisible ? "732px" : "480px"}
        height="fit-content"
        onClose={handleCancel}
        closeIconProps={{
          bgColor: "solidWhite",
        }}
        customHeader={
          <EditProfileImageHeader
            title={isEditorVisible ? `Crop ${title}` : title}
            description={isEditorVisible ? undefined : SUBTITLE}
            onClick={handleCancel}
          />
        }
        showFooter={false}
        customDialogStyle={!isEditorVisible ? dialogStyle : undefined}
      >
        {isEditorVisible ? (
          <EditUploadedImage
            image={image}
            handleResetImage={handleResetImage}
            handleUpdateImage={handleUpdateImage}
            handleDelete={handleDeleteImage}
            canDelete={canDelete}
          />
        ) : (
          <ImagePreview
            imageUrl={defaultImageURL}
            thumbnailType={thumbnailType}
            title={subTitle}
            hasPickedAFile={hasPickedAFile}
            handleDelete={handleDeleteImage}
            canUpload={canUpload}
            canDelete={canDelete}
          />
        )}
      </GiveBaseModal>
    );
  },
);

const dialogStyle = {
  "& .MuiDialog-paper": {
    "& .MuiDialogTitle-root": {
      height: "68px",
      borderBottom: "none",
      padding: "20px 20px 0 ",
    },
  },
};
