import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Stack } from "@mui/material";
import useImageCropper from "./hooks/useImageCropper";
import GiveButton from "@shared/Button/GiveButton";
import ImageEditorToolbar from "./Editor/ImageEditorToolbar";

const MIN_ZOOM = 0;
const MAX_ZOOM = 100;
const STEP = 10;

type ImageSectionProps = {
  image?: string;
  handleResetImage: () => void;
  handleUpdateImage: (url: HTMLCanvasElement) => void;
  handleDelete: () => void;
  canDelete: boolean;
};

function EditUploadedImage({
  image,
  handleResetImage,
  handleUpdateImage,
  handleDelete,
  canDelete,
}: ImageSectionProps) {
  const { isMobileView } = useCustomThemeV2();
  const {
    cropperRef,
    rotation,
    zoomValue,
    rotate,
    handleUpdateSlider,
    handleZoom,
    getCropData,
  } = useImageCropper(
    { minZoom: MIN_ZOOM, maxZoom: MAX_ZOOM, step: STEP },
    handleUpdateImage,
  );

  return (
    <Stack spacing="24px">
      <Cropper
        src={image || ""}
        style={{ height: isMobileView ? 260 : 300 }}
        dragMode="move"
        toggleDragModeOnDblclick={false}
        ref={cropperRef}
        viewMode={2}
        guides={false}
        rotateTo={rotation}
        center={false}
        zoomOnWheel={false}
        autoCropArea={0.3}
        minCropBoxHeight={80}
        minCropBoxWidth={80}
        aspectRatio={1 / 1}
      />
      <ImageEditorToolbar
        zoom={zoomValue}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        handleRotate={rotate}
        handleZoom={handleZoom}
        handleChangeSlider={handleUpdateSlider}
        handleDelete={handleDelete}
        canDelete={canDelete}
      />
      <ActionButtonsContainer>
        <GiveButton
          label="Cancel"
          color="light"
          size="large"
          variant="ghost"
          onClick={handleResetImage}
        />

        <GiveButton label="Save" size="large" onClick={getCropData} />
      </ActionButtonsContainer>
    </Stack>
  );
}

export default EditUploadedImage;

const ActionButtonsContainer = styled(Stack)({
  alignItems: "center",
  gap: "12px",
  flexDirection: "row",
  justifyContent: "flex-end",
});
