import { checkPortals } from "@utils/routing";
import { getGlobalTopic } from "./useConversationsModal";
import { useQuery } from "react-query";
import { TGlobalTopic } from "../types";

const useGetGlobalTopic = (name: string, merchantId?: number) => {
  const { isAcquirerPortal } = checkPortals();
  const { isLoading, data } = useQuery<{
    total: number;
    data: TGlobalTopic[];
  }>(
    ["topic", name],
    async () =>
      await getGlobalTopic({
        topicName: name,
        merchantId,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isAcquirerPortal,
    },
  );
  return { isLoading, topic: data?.total && data.data[0] };
};

export default useGetGlobalTopic;
