import { Collapse, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import SectionHeader from "./SectionHeader";
import { CreditCard, Info } from "@phosphor-icons/react";
import { Grid } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveRadio from "@shared/Radio/GiveRadio";
import { getCardBrandIcon } from "@sections/Checkout/Payment/inputs/CardNumberInput";
import { GiveInput, InputProps } from "@shared/GiveInputs/GiveInput";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import GiveSelect, { GiveSelectProps } from "@shared/GiveInputs/GiveSelect";
import { getCountryNames, getUSNames } from "@utils/country_dial_codes";
import { useAppTheme } from "@theme/v2/Provider";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { HiddenComponent } from "@containers/HiddenComponent";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { CheckboxProps } from "@shared/GiveCheckbox/type";
import { normalizeInput } from "../helpers";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import { ProvinceField, ProvinceFieldProps } from "./ProvinceField";
import CVVField from "./CVVField";
import CardNumberField from "./CardNumberField";
import ZipField from "./ZipField";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";

const Payment = ({
  isMobileView,
  isDeclined,
  isDisabledFields,
}: {
  isMobileView?: boolean;
  isDeclined?: boolean;
  isDisabledFields?: boolean;
}) => {
  const { palette } = useAppTheme();
  const methods = useFormContext();
  const { parsedValues } = usePayBuilderForm();
  const { isFundraiser } = useCheckFormType();
  const [isPaymentOptionOpen, setIsPaymentOptionOpen] = useState(true);

  const { methods: leftSidepanelMethods } = usePayBuilderForm();
  const { render: isDeliveryEnabled } =
    leftSidepanelMethods.watch().Checkout.delivery;

  const isUseDeliveryAddress = useWatch({
    control: methods.control,
    name: "payment.useDeliveryAddress",
  });

  const hideBillingAddress =
    (isDeliveryEnabled && isUseDeliveryAddress) || isFundraiser;

  const { setValue, watch } = useFormContext();
  React.useEffect(() => {
    setValue(
      "payment.expirationDate",
      normalizeInput(watch("payment.expirationDate")),
    );
  }, [watch("payment.expirationDate")]);

  const formFileds = useMemo(() => {
    return [
      {
        Component: (props: InputProps) => <CardNumberField {...props} />,
        label: "Card Number",
        name: "payment.cardNumber",
        md: 12,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Expiration Date (MM/YY)",
        name: "payment.expirationDate",
        md: 6,
      },
      {
        Component: (props: InputProps) => <CVVField {...props} />,
        label: "Security Code",
        name: "payment.cvv",
        md: 6,
      },
      {
        Component: () => (
          <GiveText variant="bodyXS" color="error">
            Your card was declined. Try again or use different card.
          </GiveText>
        ),
        md: 12,
        hidden: !isDeclined,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Name on Card",
        name: "payment.nameOnCard",
        md: 12,
      },
      {
        Component: (props: CheckboxProps) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <GiveCheckbox checked={isUseDeliveryAddress} {...props} />
              <GiveText variant="bodyS">
                Use delivery address as billing address
              </GiveText>
            </Stack>
          );
        },
        name: "payment.useDeliveryAddress",
        md: 12,
        hidden: !isDeliveryEnabled,
      },
      {
        Component: () => <GiveDivider />,
        md: 12,
        hidden: hideBillingAddress,
      },
      {
        Component: () => <GiveText>Billing Address</GiveText>,
        md: 12,
        hidden: hideBillingAddress,
      },
      {
        Component: (props: GiveSelectProps) => <GiveSelect {...props} />,
        label: "Country/Region",
        name: "payment.billingAddress.country",
        options: getCountryNames().map((country) => ({
          value: country,
          label: country,
        })),
        hidden: hideBillingAddress,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "First Name",
        name: "payment.billingAddress.firstName",
        md: 6,
        hidden: hideBillingAddress,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Last Name",
        name: "payment.billingAddress.lastName",
        md: 6,
        hidden: hideBillingAddress,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Address",
        name: "payment.billingAddress.address",
        md: 12,
        hidden: hideBillingAddress,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Apartment, Suite etc",
        name: "payment.billingAddress.apartment",
        md: 12,
        hidden: hideBillingAddress,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "City",
        name: "payment.billingAddress.city",
        md: 4,
        hidden: hideBillingAddress,
      },
      {
        Component: (props: ProvinceFieldProps) => <ProvinceField {...props} />,
        name: "payment.billingAddress.province",
        options: getUSNames().map((state) => ({
          value: state,
          label: state,
        })),
        md: 4,
        hidden: hideBillingAddress,
      },
      {
        Component: (props: InputProps) => <ZipField {...props} />,
        label: "Zip Code",
        name: "payment.billingAddress.zipCode",
        md: 4,
        hidden: hideBillingAddress,
      },
    ];
  }, [isDeliveryEnabled, hideBillingAddress, isDeclined]);

  return (
    <Stack spacing="20px">
      <SectionHeader title="Payment" icon={<CreditCard size={24} />} />
      <Stack
        width="100%"
        height="100%"
        sx={{
          border: `1px solid ${parsedValues.accentColor}`,
          backgroundColor: `${parsedValues.accentColor}1A`,
          borderTopRightRadius: "12px",
          borderTopLeftRadius: "12px",
          ...(!isPaymentOptionOpen && {
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
          }),
        }}
        padding="16px"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1}>
            <GiveRadio
              onClick={() => {
                setIsPaymentOptionOpen((prev) => !prev);
              }}
              checked={isPaymentOptionOpen}
              disabled={isDisabledFields}
            />
            <GiveText variant="bodyS">Credit or Debit Card</GiveText>
          </Stack>
          <Stack direction="row">
            {getCardBrandIcon(
              "",
              true,
              { width: 40, height: 26 },
              isMobileView,
              palette,
            )}
          </Stack>
        </Stack>
      </Stack>
      <Collapse
        orientation="vertical"
        in={isPaymentOptionOpen}
        style={{
          marginTop: "0px",
          backgroundColor: palette.surface?.secondary,
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
          borderStyle: "solid",
          borderWidth: "0 1px 1px 1px",
          borderColor: palette.border?.secondary,
        }}
      >
        <Grid container rowSpacing="24px" columnSpacing={3} padding="16px">
          {formFileds.map(
            ({ Component, name, label, md, options, hidden }, index) => {
              return (
                <HiddenComponent key={index} hidden={hidden || false}>
                  <Grid item key={index} xs={12} md={isMobileView ? 12 : md}>
                    {/* if name or options don't exist it means it's not an input */}
                    {!name && !options ? (
                      <Component />
                    ) : (
                      <Controller
                        control={methods.control}
                        name={name as any}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          const baseProps = {
                            name,
                            value,
                            label,
                            onChange,
                            helperText: error?.message,
                          };

                          if (options) {
                            return (
                              <Component
                                {...baseProps}
                                isUS={
                                  methods.watch(
                                    "payment.billingAddress.country",
                                  ) === "United States"
                                }
                                options={options}
                                disabled={isDisabledFields}
                              />
                            );
                          }

                          return (
                            <Component
                              {...baseProps}
                              fullWidth
                              error={Boolean(error)}
                              disabled={isDisabledFields}
                            />
                          );
                        }}
                      />
                    )}
                  </Grid>
                </HiddenComponent>
              );
            },
          )}
        </Grid>
      </Collapse>
    </Stack>
  );
};

export default Payment;
