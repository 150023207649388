import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import { Text, TruncateText } from "@common/Text";
import { showMessage } from "@common/Toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetCurrentMerchantId } from "@hooks/common";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { customInstance } from "@services/api";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { AxiosError } from "axios";
import { EditInfo } from "features/Permissions/ModalV2/components/TeamPanelBody";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
const schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  email: Yup.string(),
  occupation: Yup.string().nullable(),
  employer: Yup.string().nullable(),
});
interface InfoDisplayProps {
  items: {
    Joined: string;
    "First name": string;
    "Last name": string;
    Email: string;
    "Job Title": string;
    Employer: string;
  };
  setCurrentData: (data: any) => void;
  id: number;
  setEdit: (v: boolean) => void;
}

type IFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  occupation: string;
  employer: string;
};
function InfoEdit({
  items,
  id,
  setCurrentData,
  setEdit,
  ...rest
}: InfoDisplayProps) {
  const { isMobileView } = useCustomTheme();
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();
  const updateTeamMember = useMutation(async (data: IFormValues) => {
    const updateData = {
      firstName: data.firstName,
      lastName: data.lastName,
      occupation: data.occupation,
      employer: data.employer,
    };

    return customInstance({
      url: `/accounts/${merchantId}/members/${id}`,
      method: "PATCH",
      data: updateData,
    });
  });
  function updateData(d: IFormValues) {
    setCurrentData((data: EditInfo) => ({
      ...data,
      "First name": d.firstName,
      "Last name": d.lastName,
      "Job Title": d.occupation,
      Employer: d.employer,
    }));
  }
  const methods = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: items["First name"],
      lastName: items["Last name"],
      email: items["Email"],
      occupation: items["Job Title"],
      employer: items["Employer"],
    },
  });

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const onSubmit = (data: IFormValues) => {
    if (!isDirty) {
      setEdit(false);
      reset();
    }

    updateTeamMember.mutate(data, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosError;
        showMessage("Error", axiosError.message, true);
      },
      onSuccess: (res: any) => {
        updateData(data);
        showMessage("Success", "Team member has been changed");
        queryClient.refetchQueries("get-all-team-members");
        queryClient.refetchQueries("check-owner-member");
        setEdit(false);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack {...rest} gap="8px" mb={isMobileView ? 0 : 4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                backgroundColor: "inherit",
                padding: "4px 8px",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StyledText lineClamp={1}>Joined</StyledText>
              <StyledText isValue lineClamp={1}>
                {items["Joined"]}
              </StyledText>
            </Box>
            <Button
              background="secondary"
              size="medium"
              sx={{
                width: "fit-content",
                paddingY: "2px",
                fontWeight: 400,
                fontSize: "14px",
              }}
              type="submit"
            >
              <Text fontWeight="book" color={palette.neutral[90]}>
                Save changes
              </Text>
            </Button>
          </Box>
          <RHFInput name="firstName" label="First Name" fullWidth />
          <RHFInput name="lastName" label="Last Name" fullWidth />
          <RHFInput name="email" label="Email" fullWidth disabled />
          <RHFInput name="occupation" label="Job Title" fullWidth />
          <RHFInput name="employer" label="Employer" fullWidth />
        </Stack>
      </form>
    </FormProvider>
  );
}

export default InfoEdit;

const StyledText = styled(TruncateText, {
  shouldForwardProp: (prop) => prop !== "isValue",
})<{ isValue?: boolean }>(({ theme, isValue }) => ({
  color: isValue ? theme.palette.neutral["600"] : theme.palette.neutral["400"],
  fontWeight: isValue ? 350 : 300,
  lineHeight: "16.8px",

  ...(isValue && {
    minHeight: "16.8px",
  }),
}));
