import { Drawer, SwipeableDrawer } from "@mui/material";
import { IGiveSidePanelProps } from "@shared/SidePanel/GiveSidePanel";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useAppTheme } from "@theme/v2/Provider";
import { getDrawerV2DefaultProps } from "@theme/v2/customStyles/customDrawerStyle";

interface PanelWrapperProps {
  isMobileView: boolean;
  isDoublePanelMode: boolean;
  isSinglePanelOnly: boolean;
  width: number;
  children?: ReactNode;
  drawerProps: Omit<
    IGiveSidePanelProps,
    | "width"
    | "isSecondPanelOpen"
    | "containerProps"
    | "children"
    | "secondPanel"
  >;
}

const PanelWrapper = ({
  children,
  isMobileView,
  isDoublePanelMode,
  width,
  drawerProps,
  isSinglePanelOnly,
}: PanelWrapperProps) => {
  const [isMainTransitionComplete, setMainTransitionStatus] = useState(false);
  const [isDesktopOpen, setDesktopOpen] = useState(false);

  const theme = useAppTheme();
  const { componentsProps = {}, PaperProps = {}, ...rest } = drawerProps || {};
  const {
    componentsProps: defaultComponentsProps,
    PaperProps: defaultPaperProps,
  } = getDrawerV2DefaultProps(theme.palette.mode);

  const delaySecondDispatch = (
    firstDispatch: Dispatch<SetStateAction<boolean>>,
    secondDispatch: Dispatch<SetStateAction<boolean>>,
    value: boolean,
  ) => {
    firstDispatch(value);
    setTimeout(() => {
      secondDispatch(value);
    }, 300);
  };

  const toggleDesktopDrawer = (open?: boolean) => {
    if (open) {
      delaySecondDispatch(setDesktopOpen, setMainTransitionStatus, true);
    } else {
      delaySecondDispatch(setMainTransitionStatus, setDesktopOpen, false);
    }
  };

  useEffect(() => {
    if (!isSinglePanelOnly) toggleDesktopDrawer(drawerProps.open);
  }, [drawerProps.open, isMobileView]);

  if (isMobileView) {
    return (
      <SwipeableDrawer
        disableDiscovery
        open={drawerProps.open}
        onClose={(e: any) =>
          drawerProps.onClose && drawerProps.onClose(e, "backdropClick")
        }
        onOpen={() => null}
        anchor="bottom"
        transitionDuration={300}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "96%",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.primitive?.neutral[110],
              borderRadius: "10px",
            },
          },
        }}
      >
        {children}
      </SwipeableDrawer>
    );
  }

  return (
    <Drawer
      open={isSinglePanelOnly ? drawerProps.open : isDesktopOpen}
      PaperProps={{
        sx: {
          flexDirection: "row",
          width: isDoublePanelMode ? width * 2 : width,
          ...(isMainTransitionComplete
            ? { transitionProperty: "transform, width !important" }
            : {}),
          ...defaultPaperProps.sx,
        },
        ...PaperProps,
      }}
      type="sidepanel"
      anchor="right"
      componentsProps={{
        ...defaultComponentsProps,
        ...componentsProps,
      }}
      SlideProps={{
        easing: "ease-in",
        timeout: 195,
      }}
      {...rest}
    >
      {children}
    </Drawer>
  );
};
export default PanelWrapper;
