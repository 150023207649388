import { yupResolver } from "@hookform/resolvers/yup";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  addressFields,
  DEFAULT_FUNDRAISER_THANK_YOU_MESSAGE,
  FIELD_REQUIRED_MESSAGE,
} from "../consts";
import { matchIsValidTel } from "mui-tel-input";
import { getCountryDialCode } from "@utils/country_dial_codes";
import { isNonValidExpirationDate, isValidCardNumber } from "../helpers";
import { useEffect, useState } from "react";
import { PaymentSuccessInfo } from "@pages/Checkout/components/PaymentSuccess";
import useCheckoutProcess from "./useCheckoutProcess";
import { ICheckoutInputs } from "../types";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";

const useCheckoutPreview = (
  onPaymentSuccess?: (data: PaymentSuccessInfo) => void,
) => {
  const { isFundraiser } = useCheckFormType();
  const { methods: checkoutSidepanelMethods } = usePayBuilderForm();
  const checkoutSidepanelValues = checkoutSidepanelMethods.watch();

  const { render: isPhoneNumberRendered, required: isPhoneNumberRequired } =
    checkoutSidepanelValues.Checkout.phoneNumber;

  const { render: isDeliveryEnabled } =
    checkoutSidepanelValues.Checkout.delivery;
  const [isDeclined, setIsDeclined] = useState(false);
  const donationDetails = checkoutSidepanelValues.Donations
    ? {
        selectedAmount: checkoutSidepanelValues.Donations.selectedAmount,
        isAnonymous: checkoutSidepanelValues.Donations.isAnonymous,
      }
    : { selectedAmount: "", isAnonymous: false };

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Not a valid format")
      .required(FIELD_REQUIRED_MESSAGE),
    ...(isPhoneNumberRendered && {
      phoneNumber: Yup.string().when({
        is: () => {
          return isPhoneNumberRequired;
        },
        then: (schema) =>
          schema
            .required(FIELD_REQUIRED_MESSAGE)
            .test(
              "is-valid-phone",
              "Please enter a valid phone number",
              function (value) {
                const phoneNumber = value as string;
                return matchIsValidTel(phoneNumber);
              },
            ),
      }),
    }),
    payment: Yup.object().shape({
      cardNumber: Yup.string()
        .required(FIELD_REQUIRED_MESSAGE)
        .when({
          is: (exists: string) => !!exists,
          then: (schema) =>
            schema.test(
              "is-valid-card-number",
              "Please enter a valid card number",
              function (value) {
                return isValidCardNumber(value || "");
              },
            ),
        }),
      expirationDate: Yup.string()
        .required(FIELD_REQUIRED_MESSAGE)
        .when({
          is: (exists: string) => !!exists,
          then: (schema) =>
            schema
              .min(5, "Please enter a valid expiration date")
              .test(
                "validator-expiration-date",
                "Please enter a valid expiration date",
                function (value) {
                  return !isNonValidExpirationDate(value || "");
                },
              ),
        }),
      cvv: Yup.string()
        .required(FIELD_REQUIRED_MESSAGE)
        .min(3, "Please enter a valid CVV"),
      nameOnCard: Yup.string()
        .trim()
        .required(FIELD_REQUIRED_MESSAGE)
        .matches(/^[a-zA-Z ]*$/, "Please enter a valid Name"),
      billingAddress: Yup.object().shape({
        country: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
        address: Yup.string(),
        apartment: Yup.string(),
        city: Yup.string(),
        province: Yup.string(),
        zipCode: Yup.string().when({
          is: (exists: string) => !!exists,
          then: Yup.string().matches(
            /^[0-9]{5}(?:-?[0-9]{4})?$/,
            "Invalid ZIP format",
          ),
        }),
      }),
    }),
    ...(isDeliveryEnabled && {
      deliveryAddress: Yup.object().shape({
        country: Yup.string(),
        firstName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
        lastName: Yup.string().required(FIELD_REQUIRED_MESSAGE),
        address: Yup.string().required(FIELD_REQUIRED_MESSAGE),
        apartment: Yup.string(),
        city: Yup.string().required(FIELD_REQUIRED_MESSAGE),
        province: Yup.string().required(FIELD_REQUIRED_MESSAGE),
        zipCode: Yup.string()
          .required(FIELD_REQUIRED_MESSAGE)
          .when({
            is: (exists: string) => !!exists,
            then: Yup.string().matches(
              /^[0-9]{5}(?:-?[0-9]{4})?$/,
              "Invalid ZIP format",
            ),
          }),
        phoneNumber: Yup.string()
          .required(FIELD_REQUIRED_MESSAGE)
          .when({
            is: (exists: string) => !!exists && exists !== dialCode,
            then: (schema) =>
              schema.test(
                "is-valid-phone",
                "Please enter a valid phone number",
                function (value) {
                  const phoneNumber = value as string;
                  return matchIsValidTel(phoneNumber);
                },
              ),
          }),
      }),
    }),
    customerCoversFees: Yup.boolean(),
    termsConditions: Yup.boolean()
      .oneOf([true], "You need to accept Terms & Conditions")
      .required("You need to accept Terms & Conditions"),
  });

  const methods = useForm<ICheckoutInputs>({
    defaultValues: {
      email: "",
      phoneNumber: "",
      payment: {
        cardNumber: "",
        expirationDate: "",
        cvv: "",
        nameOnCard: "",
        useDeliveryAddress: isDeliveryEnabled,
        billingAddress: { ...addressFields },
      },
      deliveryAddress: { ...addressFields, phoneNumber: "" },
      customerCoversFees: false,
      termsConditions: false,
      ...(isFundraiser && {
        recurringInterval: "one_time",
      }),
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { watch, clearErrors, resetField, setError, setValue } = methods;
  const values = watch();

  const onPaymentDeclined = () => {
    setIsDeclined(true);
    setError("payment.cardNumber", { message: "" });
    setError("payment.expirationDate", { message: "" });
    setError("payment.cvv", { message: "" });
  };

  const { handleCheckout, isLoading } = useCheckoutProcess(
    onPaymentSuccess,
    onPaymentDeclined,
  );

  const dialCode = getCountryDialCode(values.deliveryAddress.country) || "+1";

  const onSubmit = async (data: any) => {
    const finalData = {
      ...data,
      Donations: donationDetails,
    };
    await handleCheckout(finalData);
  };

  useEffect(() => {
    if (!isDeliveryEnabled) {
      resetField("deliveryAddress", {
        defaultValue: { ...addressFields, phoneNumber: "" },
      });
    } else {
      setValue("payment.useDeliveryAddress", true);
    }
  }, [isDeliveryEnabled]);

  useEffect(() => {
    if (values && values.payment && values.payment.cardNumber && isDeclined) {
      setIsDeclined(false);
      clearErrors("payment.cardNumber");
      clearErrors("payment.cvv");
      clearErrors("payment.expirationDate");
    }
  }, [
    values.payment.cardNumber,
    values.payment.cvv,
    values.payment.expirationDate,
  ]);

  return {
    methods,
    isDeclined,
    onSubmit,
    isLoading,
  };
};

export default useCheckoutPreview;
