import React from "react";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";
import {
  IParsedData,
  TFeeObject,
} from "@components/Merchants/MerchantPreview/data.types";
import {
  betterPhoneNumber,
  formatSSN,
  parsePhoneNumber,
  parseTaxID,
} from "../GiveMerchantFile/hooks/helpers";
import { formatAddress } from "@utils/helpers";
import { capitalizeFirstLetter } from "@utils/index";
import moment from "moment";
import { calculateFeePerTransaction } from "@components/Merchants/MerchantPreview/components/MerchantFees";

export default function useSnapShot() {
  const { data } = useMerchantSidePanelContext();

  const merchantInfo = getMerchantInfo(data);
  const businessProfile = getBusinessProfile(data);
  const primaryAccountHolder = getPrimaryAccountHolder(data);
  const businessOwners = getBusinessOwners(data);
  const bankAccounts = getBankAccounts(data);
  const fees = getFees(data);

  return {
    ...fees,
    merchantInfo,
    businessProfile,
    primaryAccountHolder,
    businessOwners,
    bankAccounts,
  };
}
const getBankAccounts = (data: IParsedData) => {
  const bankAccountList = data?.bankAccountList || [];
  return bankAccountList?.map((bank) => {
    return [
      { label: "Bank Name", value: bank?.bankName },
      { label: "Account Type", value: bank?.type },
      { label: "Account Name", value: bank?.name },
      { label: "Account Number", value: bank?.accountNumber },
      { label: "Routing Number", value: bank?.routingNumber },
    ];
  });
};
const getBusinessOwners = (data: IParsedData) => {
  const businessOwnersList = data?.businessOwnersList || [];
  return businessOwnersList?.map((owner) => {
    return [
      { label: "Full Name", value: `${owner?.firstName} ${owner?.lastName}` },
      { label: "Email", value: owner?.email || "" },
      { label: "Ownership", value: owner.ownership || 0 },
      { label: "SSN", value:  formatSSN(owner.ssn || "") },
      {
        label: "Date of Birth",
        value: owner?.dob ? moment(owner?.dob).format("MM/DD/YYYY") : "",
      },
    ];
  });
};
const getMerchantInfo = (data: IParsedData) => {
  const {
    merchantName,
    category,
    categoryCodeName,
    billingDescriptor,
    websiteURL,
    estimatedAnnualRevenue,
    servicePhoneNumber,
    averageTicketAmount,
    highTicketAmount,
    serviceCountriesOutUSCanada,
    countriesServicedOutside,
    businessPurpose,
    description,
  } = data?.merchantInfo || {};

  return [
    { label: "Full Name", value: merchantName },
    { label: "Merchant Category", value: `${category} - ${categoryCodeName}` },
    { label: "Billing Descriptor", value: billingDescriptor },
    { label: "Website URL", value: websiteURL },
    { label: "Estimated Annual Revenue (USD)", value: +estimatedAnnualRevenue },
    { label: "Average Ticket Amount (USD)", value: averageTicketAmount ?? 0 },
    { label: "High Ticket Amount (USD)", value: highTicketAmount },
    {
      label: "Merchant phone number",
      value: betterPhoneNumber(servicePhoneNumber),
      isPhoneNumber: true,
    },
    {
      label: "Countries Serviced Outside the USA/Canada",
      value: serviceCountriesOutUSCanada ? countriesServicedOutside : "None",
    },
    {
      label: "Purpose or Mission of Merchant",
      value: (
        <span style={{ wordBreak: "break-word" }}>
          {businessPurpose || description}
        </span>
      ),
    },
  ];
};

const getBusinessProfile = (data: IParsedData) => {
  const {
    legalName,
    dba,
    businessType,
    ownershipType,
    taxID,
    createdAt,
    businessOpenedAt,
    contactPhone,
  } = data.businessProfile || {};

  const pastDate = createdAt ? moment(createdAt, "MM/DD/YYYY HH:mm:ss") : null;

  return [
    { label: "Business Legal Name", value: legalName },
    { label: "DBA (Doing Business As)", value: dba },
    { label: "Business Type", value: capitalizeFirstLetter(businessType) },
    {
      label: "Business Ownership Type",
      value: capitalizeFirstLetter(ownershipType),
    },
    { label: "Federal Tax ID", value: parseTaxID(taxID) },
    {
      label: "Business Phone Number",
      value: parsePhoneNumber(contactPhone),
      isPhoneNumber: true,
    },
    { label: "Date Business Opened", value: businessOpenedAt },
    {
      label: "Age of Merchant",
      value: pastDate ? moment().diff(pastDate, "years") : "",
    },
    { label: "Business Address", value: formatAddress(data?.businessAddress) },
  ];
};

const getPrimaryAccountHolder = (data: IParsedData) => {
  const { lastName, firstName, email, dateOfBirth, phoneNumber } =
    data.primaryAccountHolder || {};
  const dob = dateOfBirth
    ? moment.unix(dateOfBirth).utc().format("MM/DD/YYYY")
    : "";
  return [
    { label: "Full Name", value: `${firstName} ${lastName}` },
    { label: "Email", value: email },
    { label: "Date of Birth", value: dob },
    {
      label: "Contact Number",
      isPhoneNumber: true,
      value: betterPhoneNumber(phoneNumber),
    },
  ];
};

const getFees = (data: IParsedData) => {
  return {
    transactionFees: [
      { label: "Credit Card Fee", value: formatFee(data?.fees?.creditCardFee) },
      { label: "Debit Card Fee", value: formatFee(data?.fees?.debitCardFee) },
      {
        label: "AMEX Card Fee",
        value: formatFee(data?.fees?.amexCreditCardFee),
      },
    ],
    monthlyAccountFees: [
      { label: "Monthly Account", value: "Monthly Account Fees" },
    ],
    gateAwayAccessFees: [
      { label: "Gateway Access Fees", value: "$0.00 per month" },
      { label: "Per Transaction Gateway", value: "$0.00 per transaction" },
    ],
    chargeBackFees: [
      { label: "Chargeback Fees", value: "$15.00 per chargeback" },
    ],
    misleneousFees: [
      { label: "Setup", value: "$0.00" },
      { label: "PCI Compliance", value: "$0.00 per year" },
      { label: "Early Termination", value: "$0.00" },
    ],
  };
};

const formatFee = (fee: TFeeObject) =>
  fee ? `${calculateFeePerTransaction(fee)} / fee per transaction` : "N/A";

//calculateFeePerTransaction
