import { Fragment } from "react";
import { Grid, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";

import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";

const MerchantInformationSection = () => {
  const { data } = useMerchantSidePanelContext();

  const stats = data?.stats;
  const gridData = {
    ["Lifetime (USD)"]: stats?.sumGenerated,
    Transactions: stats?.transactions,
    Refunds: stats?.refunds,
    Chargebacks: stats?.chargebacks,
  };

  return (
    <Stack gap="16px">
      <GiveText>Merchant Information</GiveText>
      <StyledAmountContainerStack gap={2}>
        <StyledAvailableBalanceStack gap={1}>
          <GiveText variant="h4">{stats?.balance}</GiveText>
          <GiveText variant="bodyS" color="secondary">
            Available Balance (USD)
          </GiveText>
        </StyledAvailableBalanceStack>
        <Grid container spacing={2}>
          {Object.entries(gridData).map(([title, value], index) => (
            <Fragment key={index}>
              <Grid item xs={6}>
                <GiveText variant="bodyS" color="secondary">
                  {title}
                </GiveText>
              </Grid>
              <Grid item xs={6}>
                <GiveText variant="bodyS">{value}</GiveText>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </StyledAmountContainerStack>
    </Stack>
  );
};

const StyledAmountContainerStack = styled(Stack)(({ theme }) => ({
  padding: "20px",
  borderRadius: "20px",
  backgroundColor: theme.palette?.surface?.primary,
}));

const StyledAvailableBalanceStack = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette?.border?.primary}`,
  paddingBottom: "16px",
}));

export default MerchantInformationSection;
