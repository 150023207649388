import { updateFile } from "@hooks/common/documents/utils";
import { useQueryClient } from "react-query";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "../../constants";

const useTagHandler = () => {
  const queryClient = useQueryClient();
  const editTagHandler = async ({
    merchantID,
    documentID,
    data,
  }: {
    merchantID: number;
    documentID?: number;
    data?: any;
  }) => {
    if (!documentID) return;
    await updateFile(merchantID, documentID, data);
    queryClient.refetchQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET);
  };
  return { editTagHandler };
};
export default useTagHandler;
