import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
import { useFeatureFlagContext } from "FeatureFlags/FeatureFlagProvider";

export const useGetFeatureFlagValues = () => {
  const { flags } = useFeatureFlagContext();

  const isADBEnabled = !!flags.get(FeatureFlagKeys.ADB_FEATURE_FLAG_KEY);
  const isChangelogEnabled = !!flags.get(
    FeatureFlagKeys.CHANGELOG_FEATURE_FLAG_KEY,
  );
  const isRiskMonitorTransactionsEnabled = !!flags.get(
    FeatureFlagKeys.RISK_MONITOR_TRANSACTIONS_FLAG,
  );
  const isTransfersPageEnabled = !!flags.get(
    FeatureFlagKeys.TRANSFERS_PAGE_FLAG_KEY,
  );
  const isDisputesPageEnabled = !!flags.get(
    FeatureFlagKeys.DISPUTES_PAGE_FLAG_KEY,
  );
  const isTransfersBannerStatsEnabled = !!flags.get(
    FeatureFlagKeys.TRANSFERS_STATS_FLAG_KEY,
  );
  const isViewAchFileEnabled = !!flags.get(
    FeatureFlagKeys.VIEW_ACH_FILE_FLAG_KEY,
  );
  const isEnabledConversationActivity = !!flags.get(
    FeatureFlagKeys.CONVERSATION_ACTIVITY_FLAG_KEY,
  );

  const isNewMCCSelectionImplementation = !!flags.get(
    FeatureFlagKeys.NEW_MCC_SELECTION_IMPLEMENTATION_FLAG_KEY,
  );

  const isFileUploadRefactorEnabled = !!flags.get(
    FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY,
  );

  const isNewColorPickerImplementation = !!flags.get(
    FeatureFlagKeys.NEW_COLOR_PICKER_IMPLEMENTATION_FLAG_KEY,
  );
  const isSponsorApprovalEnabled = !!flags.get(
    FeatureFlagKeys.SPONSOR_APPROVAL_FLAG_KEY,
  );

  const isSponsorBulkApprovalEnabled = !!flags.get(
    FeatureFlagKeys.BULK_SPONSOR_APPROVAL_FLAG_KEY,
  );

  const isAvailableBalanceHidden = !!flags.get(
    FeatureFlagKeys.HIDE_AVAILABLE_BALANCE_FLAG_KEY,
  );
  const isMonthlyReportEnabled = !!flags.get(
    FeatureFlagKeys.HIDE_MONTHLY_REPORT,
  );

  const isNewOfacTabsEnabled = !!flags.get(
    FeatureFlagKeys.NEW_OFAC_TABS_FLAG_KEY,
  );

  const isDisputeSidePanelEnabled = !!flags.get(
    FeatureFlagKeys.DISPUTE_SIDE_PANEL,
  );

  const isSettlementExportEnabled = !!flags.get(
    FeatureFlagKeys.SETTLEMENT_EXPORT,
  );

  const isMerchantInvitesEnabled = !!flags.get(
    FeatureFlagKeys.MERCHANT_INVITES,
  );

  const isNewOnboardingEnabled = !!flags.get(
    FeatureFlagKeys.ONBOARDING_BUSINESS_DETAILS,
  );

  const is2FAEnabled = !!flags.get(FeatureFlagKeys.LOGIN_OTP_ENABLED_FLAG);

  const isRebrandingEnabled = !!flags.get(FeatureFlagKeys.REBRANDING);

  const isSidePanelRebrandingEnabled = !!flags.get(
    FeatureFlagKeys.REBRANDING_SIDEPANEL,
  );

  const isRebrandingTableEnabled = !!flags.get(
    FeatureFlagKeys.REBRANDING_TABLE,
  );

  const isPayBuilderEnabled = !!flags.get(FeatureFlagKeys.PAY_BUILDER);

  const isEnabledNewMccFlow = !!flags.get(FeatureFlagKeys.MCC_IMPROVEMENT);

  const isOnboardingViewEnabled = !!flags.get(FeatureFlagKeys.ONBOARDING_VIEW);
  const isTablesOptimizationsEnabled = !!flags.get(
    FeatureFlagKeys.TABLES_OPTIMIZATIONS,
  )

  return {
    isADBEnabled,
    isChangelogEnabled,
    isRiskMonitorTransactionsEnabled,
    isTransfersPageEnabled,
    isDisputesPageEnabled,
    isTransfersBannerStatsEnabled,
    isViewAchFileEnabled,
    isEnabledConversationActivity,
    isNewMCCSelectionImplementation,
    isFileUploadRefactorEnabled,
    isNewColorPickerImplementation,
    isNewOfacTabsEnabled,
    isSponsorApprovalEnabled,
    isSponsorBulkApprovalEnabled,
    isAvailableBalanceHidden,
    isMonthlyReportEnabled,
    isDisputeSidePanelEnabled,
    isSettlementExportEnabled,
    isMerchantInvitesEnabled,
    isNewOnboardingEnabled,
    is2FAEnabled,
    isRebrandingEnabled,
    isSidePanelRebrandingEnabled,
    isRebrandingTableEnabled,
    isPayBuilderEnabled,
    isEnabledNewMccFlow,
    isOnboardingViewEnabled,
    isTablesOptimizationsEnabled
  };
};

export const getFeatureFlagByKey = (key: keyof typeof FeatureFlagKeys) => {
  const { flags } = useFeatureFlagContext();

  return !!flags.get(FeatureFlagKeys[key]);
};
