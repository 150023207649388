import GiveTableHeader from "@shared/Table/GiveTableHeader";
import { TableRow } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableColumnType, TableData, TableOrder } from "./Table.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import MarkAllCheckBox from "./MarkAllCheckBox";
import { memo } from "react";

type TableHeaderComponentProps = {
  columns: TableColumnType[];
  sortKey: keyof TableData | null;
  order: TableOrder;
  handleClick: (
    column: TableColumnType,
  ) => (event: React.MouseEvent<HTMLElement>) => void;
  getWidth: (index: number, col: TableColumnType) => string | undefined;
};

const TableHeaderComponent = ({
  columns,
  sortKey,
  order,
  handleClick,
  getWidth,
}: TableHeaderComponentProps) => {
  const { isMobileView } = useCustomThemeV2();
  const { palette } = useAppTheme();

  return (
    <TableHead>
      <TableRow
        sx={{
          verticalAlign: "bottom",
          "& th": {
            borderBottom: `1px solid ${palette.border?.primary}`,
          },
        }}
      >
        {columns.map((col, index) => {
          if (col?.hideColumn) return null;

          return (
            <GiveTableHeader
              key={index}
              state={sortKey === col.key ? order : "none"}
              onClick={col.sortable ? handleClick(col) : undefined}
              width={getWidth(index, col)}
              sxProps={{
                ...(!col.sortable && { pointerEvents: "none" }),
                ...(col.hasCheckbox &&
                  isMobileView && {
                    paddingLeft: "0",
                  }),
              }}
              sxContentProps={{
                justifySelf: justifyHeader[col?.headerPosition || "left"],
              }}
              checkBoxElement={col?.hasCheckbox && <MarkAllCheckBox />}
            >
              {col.title}
            </GiveTableHeader>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default memo(TableHeaderComponent);

const justifyHeader = {
  right: "flex-end",
  center: "center",
  left: "flex-start",
};
