import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { showMessage } from "@common/Toast";
import { API_KEY_SUCCESS_MODAL } from "modals/modal_names";
import { useEffect } from "react";
import { QKEY_MERCHANTS_API_KEYS } from "@constants/queryKeys";
import { validIpAddress } from "../utils";

type FormInputs = {
  name: string;
  description: string;
  origin: string;
  allowedOrigins: string[];
  environment: "sandbox" | "production";
};

const defaultValues = {
  name: "",
  description: "",
  origin: "",
  allowedOrigins: [],
  environment: "sandbox" as const,
};

const schema = Yup.object().shape({
  name: Yup.string().required("First Name is required"),
  description: Yup.string(),
  origin: Yup.string(),
  environment: Yup.string(),
});

export const useGenerateApiKey = () => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { reset } = methods;

  const handleCloseModal = () => {
    modal.hide();
  };

  const createApiKeyMutation = useMutation((data: any) => {
    return customInstance({
      url: buildMerchantEndpoints("api-keys"),
      method: "POST",
      data,
    });
  });

  const { isLoading } = createApiKeyMutation;

  const cleanOrigins = (origins: string[]): string[] => {
    if (origins?.length > 0) {
      const uniqueOrigins = new Set<string>();
      origins.forEach((origin) => {
        const isValidIpAdress = validIpAddress(origin);
        //if the input is not a valid URL we check if its a valid IP and add to the set
        try {
          const cleanUrl = new URL(origin);
          uniqueOrigins.add(cleanUrl.origin);
        } catch {
          if (isValidIpAdress) {
            uniqueOrigins.add(origin);
          }
        }
      });
      return Array.from(uniqueOrigins);
    } else {
      return ["*"];
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const customData = {
      name: data.name,
      description: data.description,
      allowedOrigins: cleanOrigins(data.allowedOrigins),
      isSandbox: data.environment === "sandbox",
    };

    createApiKeyMutation.mutate(customData, {
      onError: (error: any) => {
        showMessage(
          "Error",
          error?.response?.data?.input?.[0]?.message ||
            error?.response?.data?.message,
        );
      },
      onSuccess: async (res) => {
        NiceModal.show(API_KEY_SUCCESS_MODAL, {
          apiKey: res?.apiKey,
          isSandbox: res?.isSandbox,
        });
        queryClient.refetchQueries(QKEY_MERCHANTS_API_KEYS);
        handleCloseModal();
      },
    });
  };

  useEffect(() => {
    if (modal.visible) reset(defaultValues);
  }, [modal.visible]);

  return {
    open: modal.visible,
    handleCloseModal,
    methods,
    onSubmit,
    isLoading,
  };
};
