import { ModalHolder, useModal } from "@ebay/nice-modal-react";
import GiveButton from "@shared/Button/GiveButton";
import { useAppTheme } from "@theme/v2/Provider";
import { CART_PREVIEW_MODAL } from "modals/modal_names";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import GiveText from "@shared/Text/GiveText";
import { Stack, Container } from "@mui/material";
import { styled } from "@theme/v2/Provider";
import { CheckoutNavButton } from "./CheckoutNavButton";

export function CartCheckoutFooter() {
  const { palette } = useAppTheme();
  const { subTotal, totalItemsInCart, addToCart, removeFromCart, cartItems } =
    useCart();

  const cartModalHandler = useModal(CART_PREVIEW_MODAL);

  return (
    <Wrapper maxWidth="md">
      <GiveText>
        {`${totalItemsInCart} item${
          totalItemsInCart !== 1 ? "s" : ""
        } - $${subTotal}`}
      </GiveText>
      <Stack direction="row" gap="12px">
        <GiveButton
          size="large"
          color="light"
          label={"View Cart"}
          onClick={() => {
            cartModalHandler.show();
          }}
          sx={{
            borderColor: palette.primitive?.transparent["darken-5"],
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        />

        <CheckoutNavButton />

        <ModalHolder
          modal={CART_PREVIEW_MODAL}
          handler={cartModalHandler}
          cartItems={cartItems}
          totalAmount={subTotal}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled(Container)({
  maxWidth: "1090px !important",
  display: "flex",
  justifyContent: "space-between",
  padding: "0 !important",
  alignItems: "center",
});
