import React, { useMemo, useState } from "react";
import { useMerchantSidePanelContext } from "../../../Provider/MerchantSidePanelProvider";
import moment from "moment";
import { Stack } from "@mui/material";
import { AgreementState as Status, StatusInfo } from "../../agreements.types";
import { checkPortals } from "@utils/routing";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

// Define the props for the AgreementStatusWrapper
interface AgreementStatusWrapperProps {
  children: React.ReactNode;
}

// The AgreementStatusWrapper component
function AgreementStatusWrapper({ children }: AgreementStatusWrapperProps) {
  const { data } = useMerchantSidePanelContext();
  const merchantAgreement = data?.merchantAgreement;

  const statusInfo: StatusInfo = useMemo(() => {
    if (!merchantAgreement)
      return {
        msaLastAcceptedVersion: null,
        tcVersion: null,
        signedOn: null,
        status: "notSigned" as Status,
        signedBy: null,
        signatureURL: null,
        msaPreviousTerminationProcessorName: null,
        msaPreviousTerminationAt: null,
        msaPreviousTerminationReason: null,
        msaPreviousTermination: false,
      };

    const {
      msaLastAcceptedVersion,
      tcVersion,
      msaHadAgreed,
      signedBy,
      msaLastAcceptedAt,
      signatureURL,
      msaPreviousTerminationProcessorName,
      msaPreviousTerminationAt,
      msaPreviousTerminationReason,
      msaPreviousTermination,
    } = merchantAgreement;

    const signedOn = msaLastAcceptedAt
      ? moment.unix(msaLastAcceptedAt).format("MM/DD/YYYY")
      : null;

    const defaultPayload = {
      msaLastAcceptedVersion,
      tcVersion,
      signedBy,
      signedOn,
      signatureURL,
      msaPreviousTerminationProcessorName,
      msaPreviousTerminationAt,
      msaPreviousTerminationReason,
      msaPreviousTermination,
    };

    const isCorrectVersion = msaLastAcceptedVersion === tcVersion;

    if (msaHadAgreed && isCorrectVersion)
      return { ...defaultPayload, status: "signed" as Status };
    if ((!msaHadAgreed && isCorrectVersion) || !msaLastAcceptedVersion)
      return { ...defaultPayload, status: "notSigned" as Status };

    return { ...defaultPayload, status: "newVersion" as Status };
  }, [merchantAgreement]);
  const isNewVersion = statusInfo.status === "newVersion";
  const [radioState, setRadioButtons] = useState({
    isPciChecked: isNewVersion
      ? false
      : data?.merchantAgreement?.msaPCICompliant,
    isPreviousTerminationChecked:
      data?.merchantAgreement?.msaPreviousTermination,
  });

  const { agreement_signing } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();
  const isHide =
    statusInfo.status === "notSigned" &&
    !agreement_signing &&
    isEnterprisePortal;

  // Clone children and pass statusInfo as a prop
  const childrenWithStatusInfo = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // Some children can be hidden based on the permissions
      if (isHide && child.props.canBeHidden) {
        return null; // This child will not be rendered
      }
      // Clone the child and add statusInfo prop
      return React.cloneElement(child, {
        statusInfo,
        radioState,
        setRadioButtons,
      } as {
        statusInfo: StatusInfo;
        radioState: {
          isPciChecked: boolean;
          isPreviousTerminationChecked: boolean;
        };
        setRadioButtons: React.Dispatch<
          React.SetStateAction<{
            isPciChecked: boolean;
            isPreviousTerminationChecked: boolean;
          }>
        >;
      });
    }
    return child; // Return child as is if it's not a valid React element
  });

  return (
    <Stack p="20px" gap="20px">
      {childrenWithStatusInfo}
    </Stack>
  );
}

export default AgreementStatusWrapper;
