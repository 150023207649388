export const SUPPORT_DEFAULT_MESSAGE =
  "Your support makes a difference! Adding a little extra helps us keep delivering the best service possible.";
export const CONTACT_DEFAULT_MESSAGE =
  "In case there is an issue with delivery, we can contact you via phone";

// Product
export const DEFAULT_THANK_YOU_MESSAGE =
  "Your order has been received and currently is being processed. You will receive a confirmation email shortly.";
export const DEFAULT_RECEIPT_MESSAGE =
  "Thank you for your order. We're busy getting it ready for you.";

// Fundraiser
export const DEFAULT_FUNDRAISER_THANK_YOU_MESSAGE = `Thank you for your donation! Your generosity makes a difference, and we’re thrilled to have your support. You’ll receive a confirmation email with your donation details shortly.`;
export const DEFAULT_FUNDRAISER_RECEIPT_MESSAGE = `Thank you for your donation! Your generosity makes a difference, and we’re thrilled to have your support. `;
export const DEFAULT_FUNDRAISER_OPTIONAL_AMOUNTS = [
  "5.00",
  "20.00",
  "100.00",
  "500.00",
];
// Event

export const DEFAULT_EVENT_THANK_YOU_MESSAGE = `Thank you for your order. We're excited to have you join us at the event. You will receive a confirmation email shortly.`;
export const DEFAULT_EVENT_RECEIPT_MESSAGE = `Thank you for your order. We're excited to have you join us at the event.`;

export const addressFields = {
  country: "United States",
  firstName: "",
  lastName: "",
  address: "",
  apartment: "",
  city: "",
  province: "",
  zipCode: "",
};

export const FIELD_REQUIRED_MESSAGE = "This field is required";
