import DraggableModal from "@common/DraggableModal/DraggableModal";
import { MerchantType } from "@customTypes/merchant.preview.types";
import { DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationTopic,
  selectConversationsFilter,
  selectConversationsGeneralSearch,
  setActiveTabs,
  setConversationsFilter,
} from "@redux/slices/conversations";
import DragModalHeader from "features/ChangeLog/DragModalHeader";
import { RefObject, useCallback, useMemo, useRef, useState } from "react";
import { useConversationsHeader } from "../hooks/useConversationsHeader";
import { useConversationsModal } from "../hooks/useConversationsModal";
import ConversationTopicsModal from "../SearchModal/ConversationTopicsModal";
import Chat from "./Chat";
import ConversationsGeneralSearchInput from "./Sections/ConversationsGeneralSearchInput";
import ConversationsModalMainContent from "./Sections/ConversationsModalMainContent";
import ConversationsBreadcrumbs from "./Sections/ConverstaionsBreadcrumbs";
import CreateTopicButton from "./Sections/CreateTopicButton";
import Tabs from "./Tabs";

import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { Tabs_Types } from "../types";
import ActivityList from "./ActivityList";

const parseString = (str: string) => {
  const regex = /@\[__([^[\]]{1,100})__\]\(user:([^()]{1,50})\)/g;

  const match: RegExpMatchArray | null = str.match(regex);

  if (match) {
    const result: string = match[1];
    return result;
  }
  return str;
};

type Props = MerchantType & {
  isFetching: boolean;
  isLoading: boolean;
  data: any;
  totalUnread: number;
  isLoadingChangelog?: boolean;
  handleOpenManageUnderwriting?: () => void;
  hasUnreadActivity?: boolean;
  shouldShowChangelog?: boolean;
};

const ConversationModal = ({
  merchant,
  isLoading,
  isFetching,
  data,
  totalUnread,
  isLoadingChangelog,
  handleOpenManageUnderwriting,
  hasUnreadActivity,
  shouldShowChangelog,
}: Props) => {
  const {
    isOpen,
    isTopicModalOpen,
    isExpanded,
    handleOpenConversationsModal,
    handleOpenTopicModal,
    handleExpandModal,
    handleOpenConversationActivityThread,
  } = useConversationsModal();
  const dispatch = useAppDispatch();
  const merchantId = merchant?.accID;
  const conversationsBarRef = useRef<HTMLDivElement | HTMLTableSectionElement>(
    null,
  );
  const isEnterprise = merchant.type === "enterprise";
  const { queryString } = useAppSelector(selectConversationsGeneralSearch);
  const { filterString } = useAppSelector(selectConversationsFilter);
  const {
    isOpen: isTopicOpen,
    numberOfUnreadMessages,
    queryObject,
  } = useAppSelector(selectConversationTopic);
  const { tabs, paths, name, onMessageSubmit } = queryObject || {};
  const { isEnabledConversationActivity } = useGetFeatureFlagValues();
  const setTabs = (data: Tabs_Types) => {
    if (!isEnabledConversationActivity) return;
    dispatch(setActiveTabs({ tabs: data }));
    tabs !== data && dispatch(setConversationsFilter(""));
  };

  const parsedString = useMemo(
    () => parseString(queryString || filterString),
    [queryString, filterString],
  );

  const backButtonEnabled = paths.length === 0;

  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const { handleBreadCrumbClick, handleClick, isRepliesOpen, clearQuery } =
    useConversationsHeader({ queryObject, setShowSearchInput });

  const handleScroll = useCallback((e: any) => {
    const { scrollTop } = e.currentTarget;
    if (scrollTop > 0) {
      conversationsBarRef.current?.classList.add("conversationsBarActive");
    } else {
      conversationsBarRef.current?.classList.remove("conversationsBarActive");
    }
  }, []);
  const isInternalConversations = tabs === "Internal";

  const isChatThreadSection = isTopicOpen && name;
  const showSearch =
    (!isChatThreadSection && tabs === "Activity") || tabs === "Internal";

  return (
    <DraggableModal
      open={isOpen}
      draggableProps={{
        onDrag: isTopicModalOpen ? handleOpenTopicModal : undefined,
        cancel: `${isTopicOpen ? "#imageSection, " : ""}#actionSection`,
      }}
      headerActions={{
        handleOpenModal: handleOpenConversationsModal,
        onExpand: handleExpandModal,
      }}
      idBase="conversation"
      baseHeaderProps={{
        title: merchant?.name,
        headerTestId: "conversation-header-merchant-name",
        image: merchant?.imageURL,
        ref: isTopicOpen ? conversationsBarRef : undefined,
        ...(showSearch && {
          searchIconAction: () => {
            clearQuery();
            setShowSearchInput((v) => !v);
          },
        }),

        showImage: !isTopicOpen,
        handleBackClick: handleClick,
        backButtonEnabled: backButtonEnabled,
        hideTitle: isTopicOpen,
        Breadcrumbs: (
          <ConversationsBreadcrumbs
            backButtonEnabled={backButtonEnabled}
            queryObject={queryObject}
            handleBreadCrumbClick={handleBreadCrumbClick}
          />
        ),
        SearchElement: showSearchInput ? (
          <ConversationsGeneralSearchInput
            defaultValue={""}
            screen={
              isTopicOpen && !isRepliesOpen
                ? "Threads"
                : isRepliesOpen
                ? "Replies"
                : "Topics"
            }
          />
        ) : undefined,
        imageSx: {
          ...(isTopicOpen && {
            cursor: "pointer",
          }),
        },
      }}
      headerElements={
        <DragModalHeader
          isLoadingChangelog={isLoadingChangelog}
          isLoadingConversation={isLoading}
          totalUnread={totalUnread}
          shouldShowChangelog={shouldShowChangelog}
        />
      }
      Content={
        <>
          {!isChatThreadSection ? (
            <>
              <Tabs tabs={tabs || "Internal"} setTabs={setTabs} />
              <DialogContent
                sx={{
                  position: "relative",
                  "& .MuiDialogContent-root, &.MuiDialogContent-root": {
                    paddingLeft: "8px !important",
                    paddingRight: "8px !important",
                  },
                }}
              >
                <ConversationsModalMainContent
                  data={data}
                  hasUnreadActivity={hasUnreadActivity}
                  tabs={tabs}
                  isInternal={isInternalConversations}
                  isLoading={isLoading}
                  isFetching={isFetching}
                  parsedString={parsedString}
                  setShowSearchInput={setShowSearchInput}
                  handleScroll={handleScroll}
                  merchantName={merchant?.name}
                  conversationsBarRef={
                    conversationsBarRef as RefObject<HTMLTableSectionElement>
                  }
                />

                {isTopicModalOpen && (
                  <ConversationTopicsModal
                    handleOpenTopicModal={handleOpenTopicModal}
                    merchantId={merchantId}
                    isLoading={isLoading}
                    isEnterprise={isEnterprise}
                  />
                )}
              </DialogContent>
              <CreateTopicButton
                isEnterprise={isEnterprise}
                isInternalConversations={isInternalConversations}
                handleOpenTopicModal={
                  isInternalConversations
                    ? handleOpenTopicModal
                    : () =>
                        handleOpenConversationActivityThread({ isEnterprise })
                }
              />
            </>
          ) : (
            <>
              {isInternalConversations ? (
                <Chat
                  merchantId={merchantId}
                  queryObject={queryObject}
                  numberOfUnreadMessages={numberOfUnreadMessages}
                  handleScroll={handleScroll}
                  isRepliesOpen={isRepliesOpen}
                  isExpanded={isExpanded}
                  onMessageSubmit={onMessageSubmit}
                  handleOpenConversationsModal={handleOpenConversationsModal}
                />
              ) : (
                <>
                  <ActivityList
                    merchantName={merchant?.name}
                    isEnterprise={isEnterprise}
                    merchantId={merchantId}
                    topicData={data}
                    handleOpenManageUnderwriting={handleOpenManageUnderwriting}
                    handleOpenConversationActivityThread={() =>
                      handleOpenConversationActivityThread({ isEnterprise })
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default ConversationModal;
