import Logo from "@assets/icons/RebrandedIcons/Logo";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { SxProps } from "@mui/material";
import { Grid, Link, Stack, StackProps, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import useCheckEnvironment from "@hooks/common/useCheckEnvironment";

interface Props extends StackProps {
  color?: string;
  hideSupport?: boolean;
  hideLogo?: boolean;
  linkGoupeWrapperStyle?: StackProps["sx"];
}

const Footer = ({
  color = palette.black[100],
  linkGoupeWrapperStyle,
  hideSupport,
  hideLogo,
  ...rest
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const { platformBaseUrl } = useCheckEnvironment();

  return (
    <Stack
      width="100%"
      flexDirection="row"
      alignItems="end"
      justifyContent="space-between"
      display="flex"
      padding="25px 16px"
    >
      <StyledContainer {...rest}>
        <StyledText color={color}>
          All rights reserved ©Give Corporation {new Date().getFullYear()}
        </StyledText>
        {isMobileView || hideLogo ? null : (
          <Grid paddingLeft="110px">
            <Text textAlign="center">Powered by</Text>
            <Logo height={14} />
          </Grid>
        )}
        <Stack
          gap={{
            xs: "8px",
            md: 3,
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={linkGoupeWrapperStyle}
        >
          {!hideSupport && (
            <LoginLink
              underline={palette.black[100]}
              href="mailto:support@givecorporation.com"
            >
              <StyledText color={color}>Support</StyledText>
            </LoginLink>
          )}
          <LoginLink underline={palette.black[100]} href="/privacy">
            <StyledText color={color}>Privacy Policy</StyledText>
          </LoginLink>
          <LoginLink
            underline={palette.black[100]}
            href={`${platformBaseUrl}/terms_of_service`}
          >
            <StyledText color={color}>Terms &amp; Conditions</StyledText>
          </LoginLink>
        </Stack>
      </StyledContainer>
    </Stack>
  );
};

const StyledContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",

  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    gap: "10px",
    flexDirection: "column-reverse",
  },
}));

const StyledText = styled(Text)<ITextProps & { color?: string }>(
  ({ theme, color }) => ({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18.9px",
    color: color || palette.black[100],

    [theme.breakpoints.down("sm")]: {
      lineHeight: "16.8px",
      fontWeight: 350,
    },
  }),
);

export default Footer;

const LoginLink = ({
  children,
  href,
  underline,
  sx,
}: {
  children: React.ReactNode;
  href: string;
  underline: string;
  sx?: SxProps;
}) => {
  return (
    <Link
      underline="none"
      color="inherit"
      href={href}
      target="_blank"
      width="fit-content"
      sx={{
        ...(underline && {
          "&:hover": {
            textDecoration: "underline",
            color: underline,
            cursor: "pointer",
          },
        }),
        ...sx,
      }}
    >
      {children}
    </Link>
  );
};
