// components
import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { ShareProps } from "@sections/Actions/Share/share.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import EmbedModalContent from "./EmbedModalContent";

const EmbedModal = NiceModal.create(
  ({ title, productId, type, handleClose }: ShareProps) => {
    const { onClose, TransitionProps, SlideProps, open, modal } =
      useNiceModal();
    const { isMobileView } = useCustomThemeV2();
    const handleCancel = () => modal.hide();
    const close = () => {
      handleClose?.();
      onClose();
    }

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose: close,
          DrawerProps: {
            SlideProps,
            PaperProps: {
              sx: {
                "&.MuiPaper-root": {
                  padding: "unset",
                },
              },
            },
          },
          DialogProps: {
            TransitionProps,
            width: "720px",
            contentContainerSx: {
              // overflowY: "auto",
            },
            PaperProps: {
              "& .MuiPaper-root": {},
            },
            scroll: "paper",
          },
        }}
      >
        <ModalTitle
          title="Share Product"
          padding="16px"
          onClose={handleCancel}
          textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
          showDivider
        />
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            padding: isMobileView ? "16px" : "16px 6px 16px 16px",
          }}
        >
          <EmbedModalContent productId={productId} type={"product"} />
        </Box>
      </ModalFactory>
    );
  },
);

export default EmbedModal;
