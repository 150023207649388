import { Box, Stack, StackProps } from "@mui/material";
import { stopEventPropagation } from "@utils/helpers";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import useCheckSponsor from "@hooks/common/useCheckSponsor";
import { BookmarkSimple, Warning } from "@phosphor-icons/react";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { GiveTooltipProps } from "@shared/Tooltip/GiveTooltip.types";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { CustomToolTip } from "./CustomTooltip";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import MainInfoImage from "./MainInfoImage";
import { ActionsComponent } from "./ActionsComponent";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import { GiveThumbnailProps } from "@shared/Thumbnail/GiveThumbnail";
import { useBulkMarkMerchants } from "@components/AcquirerMerchants/hooks/useBulkMarkMerchants";
import CanManageMoney from "features/Merchants/MerchantsTable/components/CanManageMoney";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

interface MainInfoProps extends StackProps {
  row: MerchantData;
  thumbnailType: GiveThumbnailProps["type"];
  disabled: boolean;
  isSponsorTable: boolean;
  removeFromWatchlistHandler?: VoidFunction;
  addToWatchlistHandler: VoidFunction;
  isAcquirerPortal?: boolean;
  badgeTooltipProps?: Omit<GiveTooltipProps, "children">;
  isWarnedMerchant?: boolean;
  isEnterpriseTable?: boolean;
  showCanManageMoney?: boolean;
}

function MainInfo({
  row,
  disabled,
  isSponsorTable,
  removeFromWatchlistHandler,
  addToWatchlistHandler,
  isAcquirerPortal = true,
  badgeTooltipProps,
  isWarnedMerchant,
  isEnterpriseTable = false,
  thumbnailType,
  showCanManageMoney,
  ...rest
}: MainInfoProps) {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const { checkIfMerchantMarked, toggleMark, checkIfMerchantDisabled } =
    useBulkMarkMerchants();

  const { hasSponsorAccess } = useCheckSponsor();

  const handleWatchlist = (func?: VoidFunction) => {
    return stopEventPropagation(func);
  };

  const watchlistActionProps = row.watchlist
    ? {
        label: "",
        title: "Remove from watchlist",
        onClick: handleWatchlist(removeFromWatchlistHandler),
        icon: BookmarkSimple,
        disabled: disabled,
      }
    : {
        label: "",
        title: "Add to watchlist",
        onClick: handleWatchlist(addToWatchlistHandler),
        icon: BookmarkSimple,
        disabled: disabled,
      };

  const merchantImage = row?.imageURL ? `${row.imageURL}/thumb` : "";
  const isSponsorDisabled = checkIfMerchantDisabled(row?.sponsorStatus || "");

  return (
    <Wrapper {...rest}>
      <Left>
        {isSponsorTable ? (
          <CustomToolTip
            onClick={(e) => e.stopPropagation()}
            message={ACTION_DENY_MESSAGE}
            showToolTip={!hasSponsorAccess}
            placement={isMobileView ? "bottom" : "bottom-end"}
            customTooltipStyles={{
              "&.MuiStack-root": {
                width: "auto",
                marginRight: "8px",
                cursor: isSponsorDisabled ? "default" : "pointer",
              },
            }}
          >
            <GiveCheckbox
              size="medium"
              sx={{
                cursor: "pointer",
                "& .MuiCheckbox-root": {
                  pointerEvents: "auto",
                },
              }}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                toggleMark({ id: row.accID, mark: e.target.checked });
              }}
              disabled={isSponsorDisabled}
              checked={checkIfMerchantMarked(row)}
            />
          </CustomToolTip>
        ) : (
          <ActionsComponent {...watchlistActionProps} />
        )}
        <MainInfoImage
          src={merchantImage}
          unread={
            (row?.hasUnreadMessages || row?.hasUnrepliedActivityMessages) &&
            isAcquirerPortal
          }
          thumbnailType={thumbnailType}
          isOrange={row?.hasUnrepliedActivityMessages}
          badgeTooltipProps={badgeTooltipProps}
        />
        <Box sx={{ marginLeft: "10px", width: "100%" }}>
          <Stack
            direction="row"
            spacing={0.5}
            height="20px"
            width="100%"
            justifyContent={showCanManageMoney ? "space-between" : "flex-start"}
          >
            <GiveTruncateText
              variant="bodyS"
              lineClamp={1}
              sx={{ wordBreak: "break-all", marginRight: "4px" }}
            >
              {row.name}
            </GiveTruncateText>
            <Stack
              direction="row"
              spacing="16px"
              alignItems="center"
              height="20px"
            >
              {isWarnedMerchant && (
                <Warning size={20} color={palette.primitive?.warning[50]} />
              )}
              {showCanManageMoney && (
                <CanManageMoney
                  canProcess={row?.canProcessMoney}
                  canTransfer={row?.canTransferMoney}
                />
              )}
            </Stack>
          </Stack>
          {isAcquirerPortal && !isEnterpriseTable && (
            <GiveTruncateText
              variant="bodyXS"
              color="secondary"
              lineClamp={1}
              onHoverShowAll
              sx={{ marginTop: "4px" }}
            >
              {row.parentName}
            </GiveTruncateText>
          )}
        </Box>
      </Left>
    </Wrapper>
  );
}

const Wrapper = styled(Stack)(() => ({
  flexDirection: "row",
  gap: "12px",
  width: "100%",
}));

const Left = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  width: "100%",
  minWidth: "100px",
}));

export default MainInfo;
