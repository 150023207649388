type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const WhatsappIcon = ({
  width = 49,
  height = 48,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="24" fill="#4FC766" />
      <g clipPath="url(#clip0_2353_46775)">
        <path
          d="M29.4736 26.382C29.1766 26.233 27.7156 25.515 27.4436 25.415C27.1706 25.316 26.9726 25.267 26.7736 25.565C26.5766 25.862 26.0066 26.531 25.8336 26.729C25.6606 26.928 25.4866 26.952 25.1896 26.804C24.8926 26.654 23.9346 26.341 22.7996 25.329C21.9166 24.541 21.3196 23.568 21.1466 23.27C20.9736 22.973 21.1286 22.812 21.2766 22.664C21.4106 22.531 21.5746 22.317 21.7226 22.144C21.8716 21.97 21.9206 21.846 22.0206 21.647C22.1196 21.449 22.0706 21.276 21.9956 21.127C21.9206 20.978 21.3266 19.515 21.0796 18.92C20.8376 18.341 20.5926 18.42 20.4106 18.41C20.2376 18.402 20.0396 18.4 19.8406 18.4C19.6426 18.4 19.3206 18.474 19.0486 18.772C18.7766 19.069 18.0086 19.788 18.0086 21.251C18.0086 22.713 19.0736 24.126 19.2216 24.325C19.3706 24.523 21.3176 27.525 24.2986 28.812C25.0076 29.118 25.5606 29.301 25.9926 29.437C26.7046 29.664 27.3526 29.632 27.8636 29.555C28.4346 29.47 29.6216 28.836 29.8696 28.142C30.1176 27.448 30.1176 26.853 30.0426 26.729C29.9686 26.605 29.7716 26.531 29.4736 26.382ZM24.0516 33.785H24.0476C22.2774 33.7852 20.5397 33.3092 19.0166 32.407L18.6556 32.193L14.9146 33.175L15.9126 29.527L15.6776 29.153C14.688 27.5773 14.1645 25.7537 14.1676 23.893C14.1686 18.443 18.6036 14.009 24.0556 14.009C26.6956 14.009 29.1776 15.039 31.0436 16.907C31.9643 17.8237 32.694 18.9138 33.1906 20.1143C33.6872 21.3148 33.9407 22.6019 33.9366 23.901C33.9336 29.351 29.4996 33.785 24.0516 33.785ZM32.4646 15.488C31.3628 14.379 30.0518 13.4996 28.6077 12.9008C27.1635 12.3021 25.6149 11.9959 24.0516 12C17.4966 12 12.1616 17.335 12.1586 23.892C12.1586 25.988 12.7056 28.034 13.7466 29.837L12.0586 36L18.3636 34.346C20.1072 35.296 22.061 35.7938 24.0466 35.794H24.0516C30.6056 35.794 35.9416 30.459 35.9446 23.901C35.9494 22.3383 35.6444 20.7901 35.047 19.3461C34.4497 17.902 33.572 16.5907 32.4646 15.488Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2353_46775">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsappIcon;
