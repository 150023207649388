import { ProductType } from "@components/CreateCampaign/types";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { CampaignMapperValues } from "features/Minibuilders/PaymentFormMinibuilder/useCreateCampaignFn";

export default function useGenerateTypeList() {
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();

  const typeList: {
    name: ProductType;
    description: string;
    key: CampaignMapperValues;
  }[] = [
    ...(isPayBuilderEnabled
      ? [
          {
            name: "Products" as ProductType,
            description: "Create a listing to sell one or more items",
            key: "standard" as CampaignMapperValues,
          },
        ]
      : [
          {
            name: "Standard" as ProductType,
            description: "Use the Payment Forms for ease and security.",
            key: "standard" as CampaignMapperValues,
          },
        ]),
    {
      name: "Fundraiser",
      description: "Start accepting donations instantly and safely.",
      key: "fundraiser" as CampaignMapperValues,
    },

    {
      name: "Event",
      description: "Start accepting donations instantly and safely.",
      key: "event" as CampaignMapperValues,
    },
    {
      name: "Invoice",
      description: "Organizing your invoices has never been easier.",
      key: "invoice" as CampaignMapperValues,
    },
    {
      name: "Membership",
      description: "From recurring donations to automated memberships.",
      key: "membership" as CampaignMapperValues,
    },
    {
      name: "Sweepstake",
      description: "Set up, sell tickets and select winners, with total ease.",
      key: "sweepstake" as CampaignMapperValues,
    },
  ];
  return { typeList };
}
