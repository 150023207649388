import { Chip, ChipProps } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { styled } from "@theme/v2/Provider";

export type TChipColors =
  | "default"
  | "success"
  | "warning"
  | "blue"
  | "error"
  | "warning2";
export interface IChipProps extends Omit<ChipProps, "color"> {
  color?: TChipColors;
  elementRef?: React.RefObject<any>;
}

export default function GiveChip({
  color,
  size = "small",
  elementRef,
  ...rest
}: IChipProps) {
  //MUI does not handle colors as we handle them in our theme,
  //passing down `blue` direclty causes an error.To overcome this,
  //we can use a color variant, like`info` under the hood, while still using`blue` as a styling value
  //when we use this component, to follow our figma design system.

  return (
    <StyledChip
      version="two"
      size={size}
      ref={elementRef}
      color={
        color === "blue" ? "info" : color === "warning2" ? "warning" : color
      }
      customColor={color}
      deleteIcon={<X weight="bold" size={14} />}
      {...rest}
    />
  );
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "customColor",
})<{ customColor?: TChipColors }>(({ theme, customColor }) => ({
  "& span": { padding: 0, lineHeight: "20px" },
  "&.MuiChip-root": {
    gap: "12px",
    ...(customColor === "warning2" && {
      backgroundColor: theme?.palette?.primitive?.warning[25],
      color: theme?.palette?.primitive?.warning[100],
    }),
  },
}));
