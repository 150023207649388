import {
  BellRinging,
  Check,
  PencilSimple,
  Prohibit,
  Trash,
} from "@phosphor-icons/react";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { ContextualMenuOptionProps } from "@shared/ContextualMenu/ContextualMenu.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useNotifications } from "./hooks/useNotifications";
import useChallengeActions from "./hooks/useChallangeActions";
import { TChallange } from "./types";
import { BPVAA_DISABLED_TEXT } from "features/Merchants/MerchantSidePanel/constants";

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  isEDD?: boolean;
  currentChallenge?: TChallange;
  openConversation: ({
    convTag,
    challengeID,
  }: {
    convTag: string;
    challengeID: number;
  }) => void;
  doneDisabled: boolean;
  handleOpenChallenge: (
    challenge: TChallange,
    handleOpenConversation: () => void,
  ) => void;
  handleDeleteChallenge: (challenge: TChallange) => void;
  handleEdit: (challenge: TChallange) => void;
  isPAHAdded: number | "";
}

export default function ChallengeItemMenu({
  anchorEl,
  handleClose,
  isEDD,
  currentChallenge,
  openConversation,
  doneDisabled,
  handleOpenChallenge,
  handleDeleteChallenge,
  handleEdit,
  isPAHAdded,
}: Props) {
  const { openNotifyMerchantModal } = useNotifications();

  const { isMobileView } = useCustomThemeV2();

  const openConv = () => {
    currentChallenge &&
      openConversation({
        convTag: "",
        challengeID: currentChallenge?.id,
      });
  };

  const menuOptions: Omit<ContextualMenuOptionProps, "children">[] = [
    {
      text: "Notify Merchant",
      onClick: () => {
        return openNotifyMerchantModal({
          challengeTypeName: currentChallenge?.challengeTypeName,
          challengeID: currentChallenge?.id,
          topicName: isEDD
            ? currentChallenge?.name
            : currentChallenge?.merchantDisplayTitle,
          hideInputs: ["module"],
          defaultMessage: currentChallenge?.predefinedReason || undefined,
        });
      },
      IconRight: BellRinging,
      disabled: !isPAHAdded,
      tooltipTitle: "This merhcant doesn't have a Primary Account Holder",
      disableTooltip: !!isPAHAdded,
    },
    {
      text: "Edit",
      onClick: currentChallenge
        ? () => handleEdit(currentChallenge)
        : undefined,
      IconRight: PencilSimple,
      hidden: !isEDD,
    },
    {
      text: "Done",
      onClick: currentChallenge
        ? () => handleOpenChallenge(currentChallenge, openConv)
        : undefined,
      IconRight: Check,
      hidden: currentChallenge?.statusName === "closed",
      disabled: doneDisabled,
      tooltipTitle: BPVAA_DISABLED_TEXT,
      disableTooltip: !doneDisabled,
    },
    {
      text: "Reject",
      onClick: currentChallenge
        ? () => handleOpenChallenge(currentChallenge, openConv)
        : undefined,
      IconRight: Prohibit,
      type: "destructive",
      hidden:
        currentChallenge?.statusName === "closed" ||
        currentChallenge?.statusName === "rejected",
    },
    {
      text: "Delete",
      onClick: currentChallenge
        ? () => handleDeleteChallenge(currentChallenge)
        : undefined,
      IconRight: Trash,
      type: "destructive",
      hidden: !isEDD,
    },
  ];

  return (
    <ContextualMenu
      anchorEl={anchorEl}
      color={isMobileView ? "secondary" : "tertiary"}
      texture={isMobileView ? "solid" : "blurred"}
      options={menuOptions}
      handleClose={handleClose}
    />
  );
}
