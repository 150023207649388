import React, { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import GiveCustomAmount, {
  GiveCustomAmountProps,
} from "@shared/GiveInputs/GiveCustomAmount";
import { isEmpty } from "lodash";

type HFCustomAmountProps = GiveCustomAmountProps & {
  name: string;
  width?: string | number;
  helper?: string | React.ReactNode;
  focusViewColor?: string;
  isDirty?: boolean;
  value?: string;
  height?: string;
  onHandleBlur?: (data: { name: string }) => void;
};

const HFGiveCustomAmount = ({
  name,
  helper,
  label,
  onHandleBlur,
  ...props
}: HFCustomAmountProps) => {
  const { control, watch } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ...rest }, fieldState: { error } }) => {
        return (
          <GiveCustomAmount
            label={label || ""}
            error={!!error}
            helperText={helper ? helper : (error?.message as string)}
            {...rest}
            {...props}
            onBlur={() => onHandleBlur?.({ name })}
          />
        );
      }}
    />
  );
};

export default memo(HFGiveCustomAmount);
