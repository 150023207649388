import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import {
  BASE_CHALLENGE_MODAL,
  EDD_CHALLENGE_MODAL,
  GIVE_CONFIRMATION_POP_UP,
} from "modals/modal_names";
import moment from "moment";
import { EDDData, useEDDChallenge, useUpdateChallenge } from "./useChallenges";
import { useMutation, useQueryClient } from "react-query";
import {
  // AutocompleteSlugType,
  //AutocompleteType,
  TChallange,
  TChallengeTypeName,
} from "../types";
import { showMessage } from "@common/Toast";
import { useEffect } from "react";
import {
  MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS,
  UNDERWRITING_CHALLENGE_API_KEYS,
} from "features/Merchants/MerchantSidePanel/constants";
// import {
//   AUTOCOMPLETE_CHALLENGES,
//   AUTOCOMPLETE_SLUG_MAP,
//   SyncApprovedNotification,
// } from "../utils";

export function getCanDone(
  challenge: TChallange,
  challengeList: TChallange[] | null | undefined,
) {
  if (challenge.name === "business_profile_verification_and_approval") {
    const BOChallenge = challengeList?.find(
      (c) => c.name === "business_owner_verification",
    );
    const BAChallenge = challengeList?.find(
      (c) => c.name === "business_address",
    );
    const canApproveBusinessProfileVerificationandApproval =
      BOChallenge?.statusName === "closed" &&
      BAChallenge?.statusName === "closed";
    return canApproveBusinessProfileVerificationandApproval;
  } else {
    return true;
  }
}
interface Props {
  challengeList?: TChallange[] | null | undefined;
}
export default function useChallengeActions({ challengeList }: Props = {}) {
  const {
    data: merchantData,
    id: merchantId,
    isEnterprise,
  } = useMerchantSidePanelContext();
  const { remove } = useModal(GIVE_CONFIRMATION_POP_UP);
  const {
    show,
    remove: removeChallengeModal,
    visible,
    args,
  } = useModal(BASE_CHALLENGE_MODAL);
  const queryClient = useQueryClient();
  const documents = merchantData?.documents || [];

  const isPAHAdded =
    merchantData?.primaryAccountHolder?.email &&
    merchantData?.primaryAccountHolder?.id;

  const updateEDDChallenge = useMutation({
    mutationFn: useEDDChallenge,
    mutationKey: ["edd-challenge"],
    onSuccess: () => {
      queryClient.invalidateQueries(
        UNDERWRITING_CHALLENGE_API_KEYS.GET_CHALLENGES,
      );
      queryClient.invalidateQueries(
        UNDERWRITING_CHALLENGE_API_KEYS.GET_CATEGORIES,
      );
      queryClient.invalidateQueries(["item"]);
    },
  });

  const updateChallengeStatus = useMutation({
    mutationFn: useUpdateChallenge,
    mutationKey: ["update-challenge-status"],
    onSuccess: (res: TChallange) => {
      if (res.statusName === "closed") {
        if (
          res.name === "business_profile_verification_and_approval" ||
          res.name === "primary_account_holder_id_verification"
        ) {
          queryClient.invalidateQueries(
            MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET,
          );
        }
      }
      removeChallengeModal();
      queryClient.invalidateQueries(
        UNDERWRITING_CHALLENGE_API_KEYS.GET_CHALLENGES,
      );
      queryClient.invalidateQueries(
        UNDERWRITING_CHALLENGE_API_KEYS.GET_CATEGORIES,
      );
      queryClient.invalidateQueries(["item"]);
      queryClient.invalidateQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET);
    },
    onError: (err: any) => {
      showMessage("Error", err.message);
    },
  });

  const handleReject = (
    challengeID: number,
    reason: string,
    type: TChallengeTypeName,
    reject?: boolean,
  ) => {
    return updateChallengeStatus.mutate({
      merchantID: merchantData.merchantInfo.merchantID,
      challengeID: challengeID,
      data: {
        status: reject ? "rejected" : "open",
        reason: reason,
        type: type,
      },
    });
  };

  const handleApprove = (
    challengeID: number,
    reason: string,
    type: TChallengeTypeName,
  ) => {
    return updateChallengeStatus.mutate({
      merchantID: merchantData.merchantInfo.merchantID,
      challengeID: challengeID,
      data: {
        status: "closed",
        reason: reason,
        type: type,
      },
    });
  };

  const handleEDDChallenge = (challengeID?: number, data?: EDDData) => {
    return updateEDDChallenge.mutate({
      merchantID: merchantData.merchantInfo.merchantID,
      challengeID: challengeID,
      data: data,
    });
  };

  const handleOpenChallenge = (
    challenge: TChallange,
    handleOpenConversation: () => void,
  ) => {
    const isEDD = challenge.categoryName === "enhanced_due_diligence";
    const canDone = getCanDone(challenge, challengeList);
    NiceModal.show(BASE_CHALLENGE_MODAL, {
      doneDisabled: !canDone,
      hasRejectButton: !NO_REJECT_SLUGS.includes(challenge.slug),
      //TODO: no banner needed now because of a BE issue
      // notification:
      //   challenge?.isClosedAutomatically &&
      //   (AUTOCOMPLETE_CHALLENGES.includes(challenge.categoryName)
      //     ? SyncApprovedNotification[challenge.categoryName as AutocompleteType]
      //     : SyncApprovedNotification[
      //         AUTOCOMPLETE_SLUG_MAP[
      //           challenge.slug as AutocompleteSlugType
      //         ] as AutocompleteType
      //       ]),
      actions: {
        onReject: handleReject,
        onApprove: handleApprove,
        handleOpenConversation: handleOpenConversation,
      },
      discussionData: {
        discussionTitle: challenge.merchantDisplayTitle,
        messageImages: challenge.userAvatarsInThread,
        commentsCount: challenge.totalUsersCommentedInThread,
        lastComment: challenge.notifiedAt
          ? moment.unix(challenge.notifiedAt).fromNow()
          : "",
      },
      challengeData: {
        id: challenge.id,
        name: challenge.name,
        challengeID: challenge.challengeID,
        title: isEDD ? challenge.name : challenge.merchantDisplayTitle,
        message: isEDD
          ? challenge.notifiedMessage
          : challenge.merchantDisplayName,
        threadId: challenge.threadID,
        challengeTypeName: challenge.challengeTypeName,
        isPAHChallenge:
          merchantData.primaryAccountHolder.email &&
          merchantData.primaryAccountHolder.id &&
          challenge.categoryName === "primary_account_holder" &&
          challenge.name === "primary_account_holder_id_verification",
        statusName: challenge.statusName,
        isBOVerification:
          challenge.categoryName === "business_profile" &&
          challenge.name === "business_owner_verification",
      },
      merchantData: {
        documents: documents,
        merchantId: merchantId,
        isEnterprise: isEnterprise,
        businessOwners: merchantData.businessOwnersList,
      },
    });
  };

  const handleDeleteChallenge = (challenge: TChallange) => {
    NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
      modalType: "delete",
      title: "Delete Challenge",
      description: (
        <>
          Are you sure you want to delete{" "}
          <strong>{challenge.merchantDisplayTitle}</strong> challenge?
        </>
      ),
      actions: {
        handleSuccess: {
          onClick: () => handleEDDChallenge(challenge.id), //EDD challenges should us id instead of challengeID
        },
        handleCancel: {
          onClick: remove,
        },
      },
    });
  };

  const handleEdit = (challenge: TChallange) => {
    NiceModal.show(EDD_CHALLENGE_MODAL, {
      type: "edit",
      challengeName: challenge.name,
      challengeDescription: challenge.notifiedMessage,
      challengeID: challenge.id, //EDD challenges should us id instead of challengeID
      handleSubmit: handleEDDChallenge,
    });
  };

  useEffect(() => {
    if (visible) {
      show({
        ...args,
        merchantData: {
          documents: documents,
          merchantId: merchantId,
          isEnterprise: isEnterprise,
          businessOwners: merchantData.businessOwnersList,
        },
      });
    }
  }, [documents]);

  return {
    handleOpenChallenge,
    handleDeleteChallenge,
    handleEDDChallenge,
    handleEdit,
    isPAHAdded,
    handleApprove,
  };
}

const NO_REJECT_SLUGS = ["underwriting_match_check", "general_website1"];
