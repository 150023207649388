import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import CreateCampaign from "@components/CreateCampaign/CreateCampaign";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingScreen } from "@redux/slices/app";
import { STATUS_ENUM } from "@components/ProfilePage/ProfileSetupHome/HomepageCard";
import { checkPortals } from "@utils/routing";
import { useGetProfileStatus } from "./useGetProfileStatus";
import { ProfilePageContainer } from "@components/ProfilePage";
import { useTriggerPathEffect } from "@hooks/common/router/useRedirect";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useNavigate } from "react-router-dom";
import { usePaymentFormNavigator } from "@sections/PayBuilder/utils";
import NiceModal from "@ebay/nice-modal-react";
import { CREATE_PRE_BUILD_FORM_MODAL } from "modals/modal_names";
import {
  Background,
  Card,
  CardHeader,
  ChecklistButton,
  ChecklistContainer,
  WelcomePageContainer,
} from "./WelcomePageV2.atoms";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";

ComponentRegistry.registerLazy("campaign", () => CreateCampaign);
ComponentRegistry.registerLazy("profile", () => ProfilePageContainer);

type TWelcomePageSection = "" | "profile" | "campaign";

const WelcomePageV2 = () => {
  const navigate = useNavigate();
  const [showInitialTitle, setShowInitialTitle] = useState(true);
  const { isMasqueradeMode } = useMasqueradeReducer();
  const dispatch = useDispatch();
  const showNextPage = useSelector((state: any) => state.app.ui.onboarding);
  const { isMerchantPortal } = checkPortals();
  const { isAddProductAllowed } = useProductPermission();
  const paymentBuilderNavigation = usePaymentFormNavigator(
    () => {
      navigate("/merchant/payment-forms", {
        state: { newProduct: true },
      });
    },
    () => NiceModal.show(CREATE_PRE_BUILD_FORM_MODAL),
  );

  useTriggerPathEffect(
    () => {
      showNextPage !== "profile" && handleClick("profile");
    },
    { delay: 200 },
  );

  const { data: merchant } = useGetMerchantById();

  const setShowNextPage = (screen: TWelcomePageSection) =>
    dispatch(setOnboardingScreen({ screen }));

  useEffect(() => {
    if (location.hash === "#onboarding") setShowNextPage("profile");
    return () => {
      setShowNextPage("");
    };
  }, []);

  const {
    bankAccounts,
    hasPrincipal,
    isSponsorApproved,
    pahStatus,
    agreementStatus,
    bankAccountStatus,
    isCompletedProfile,
    businessDetailsStatus,
    legalEntity: { data: legalEntity },
  } = useGetProfileStatus(merchant);

  const isFirstCampaignComplete = merchant?.hasCreatedProducts ?? false;

  const handleClick = (show: TWelcomePageSection) => {
    setShowNextPage(show);
    if (!showInitialTitle) setShowInitialTitle(true);
  };
  const isCompleted = isMerchantPortal ? isSponsorApproved : isCompletedProfile;

  useEffect(() => {
    if (isCompleted) handleClick("");
  }, [isCompleted]);

  const actions = [
    {
      label: "Complete your profile",
      action: () => handleClick("profile"),
      isCompleted: isCompleted,
    },
    {
      label: "Create your first payment form",
      action: () => paymentBuilderNavigation(),
      isCompleted: isFirstCampaignComplete,
      disabled: !isAddProductAllowed,
      hidden: !isMerchantPortal,
    },
  ];

  if (showInitialTitle) {
    return (
      <>
        <Background page="welcome" fullscreen />
        <WelcomePageContainer
          isMasqueradeMode={isMasqueradeMode}
          data-testid="welcome-page-container"
          sx={{
            ...(!showNextPage ? "" : unmountedStyle),
          }}
          onAnimationEnd={() => {
            if (showNextPage) setShowInitialTitle(false);
          }}
        >
          <Card>
            <CardHeader
              completed={
                isCompleted &&
                (isMerchantPortal ? isFirstCampaignComplete : true)
              }
            />
            <ChecklistContainer>
              {actions.map((item, index) => {
                if (item.hidden) return null;
                return (
                  <ChecklistButton
                    label={item.label}
                    action={item.action}
                    isCompleted={item.isCompleted}
                    disabled={item.disabled}
                    key={index}
                  />
                );
              })}
            </ChecklistContainer>
          </Card>
        </WelcomePageContainer>
      </>
    );
  }

  return (
    <WelcomePageContainer
      isMasqueradeMode={isMasqueradeMode}
      sx={{
        ...(showNextPage ? mountedStyle : unmountedStyle),
        justifyContent: "flex-start",
      }}
    >
      {ComponentRegistry.show(showNextPage, {
        className: showNextPage,
        backToWelcomePage: () => {
          setShowNextPage("");
          setShowInitialTitle(true);
        },
        legalEntity,
        isBankAccountsCompleted: bankAccountStatus != STATUS_ENUM.INCOMPLETE,
        matchTaxID: true,
        hasPrincipal: hasPrincipal,
        bankAccountStatus: bankAccountStatus,
        msaStatus: agreementStatus,
        bankAccountsList: bankAccounts.rows,
        businessDetailsStatus: businessDetailsStatus,
        pahStatus: pahStatus,
        ...(showNextPage === "profile" && {
          merchant,
        }),
      })}
    </WelcomePageContainer>
  );
};

const mountedStyle = { animation: "inAnimation 270ms ease-in" };
const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards",
};

export default WelcomePageV2;
