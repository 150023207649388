import { Box, Stack } from "@mui/material";
import { Plus, X } from "@phosphor-icons/react";
import React, { useState } from "react";
import ImageModal from "../Forms/About/ImageModal";
import { MediaItem } from "../provider/provider.type";
import { styled } from "@theme/v2/Provider";

interface Props {
  imageUrl?: string;
  handleSelect: (item?: MediaItem) => void;
  height?: string;
  width?: string;
  filterPNGOnly?: boolean;
}
function ImageBox({
  imageUrl,
  handleSelect,
  height = "120px",
  width = "120px",
  filterPNGOnly,
}: Props) {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const onClose = () => setIsImageModalOpen(false);

  const onSelect = (item?: MediaItem) => {
    handleSelect(item);
    onClose();
  };
  const handleOpen = () => setIsImageModalOpen(true);
  return (
    <>
      {imageUrl ? (
        <Box
          height={height}
          width={width}
          onClick={handleOpen}
          position="relative"
        >
          <Box
            borderRadius="8px"
            component="img"
            width="100%"
            height="100%"
            sx={{ objectFit: "contain" }}
            src={imageUrl + "/original"}
          />
          <CancelButton
            onClick={(E) => {
              E.stopPropagation();
              onSelect();
            }}
          >
            <X size="15px" />
          </CancelButton>
        </Box>
      ) : (
        <EmptyImageContainer height={height} width={width} onClick={handleOpen}>
          <Plus />
        </EmptyImageContainer>
      )}

      <ImageModal
        handleSelect={onSelect}
        open={isImageModalOpen}
        onClose={onClose}
        selectedURL={imageUrl || ""}
        filterPNGOnly={filterPNGOnly}
      />
    </>
  );
}

export default ImageBox;

const CancelButton = styled(Stack)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.surface?.primary,
  borderRadius: "50%",
  position: "absolute",
  top: 2,
  right: 2,
  height: "24px",
  width: "24px",
  justifyContent: "center",
  alignItems: "center",
}));

const EmptyImageContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primitive?.transparent["darken-5"],
  borderRadius: "8px",
  justifyContent: "center",
  alignItems: "center",
}));
