import { Box, ButtonProps } from "@mui/material";
import { Icon } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const TooltipWrapper = ({
  title,
  ...props
}: {
  title: string;
} & ButtonProps) => {
  if (props.disabled) return <>{props.children}</>;
  return (
    <Box sx={{ width: "32px" }}>
      <GiveTooltip title={title} color="default">
        <Box>{props.children}</Box>
      </GiveTooltip>
    </Box>
  );
};
interface Props {
  icon: Icon;
  title: string;
}

export function ActionsComponent({
  icon,
  title,
  variant,
  ...props
}: Props & ButtonProps) {
  const { disabled } = props;
  const { isDesktopView } = useCustomThemeV2();
  return (
    <TooltipWrapper disabled={disabled || !isDesktopView} title={title}>
      <GiveIconButton
        Icon={icon}
        weight={title === "Add to watchlist" ? "regular" : "fill"}
        size="small"
        style={{
          border: "none",
          boxShadow: "none",
          background: "inherit",
          padding: "0px",
        }}
        {...props}
      />
    </TooltipWrapper>
  );
}
