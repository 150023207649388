import { useComponentVisibilityOnStatus } from "@components/Merchants/MerchantPreview/sections/StatusReview/hooks/useComponentVisibilityOnStatus";
import { IChipProps } from "@shared/Chip/GiveChip";
import { useAppTheme } from "@theme/v2/Provider";
import { useConfigureChallenges } from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/useChallengesRepository";
import { WithRepositoryProvider } from "features/Merchants/MerchantSidePanel/WithRepository/WithRepositoryContext";
import UnderwritingCardHeader from "./UnderwritingCardHeader";
import UnderwritingChallengeCategories from "./UnderwritingChallengeCategories";
import { CardContainer } from "./UnderwritingCard.style";
import UnderwritingCardBody from "./UnderwritingCardBody";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";

const UnderwritingCard = () => {
  const { palette } = useAppTheme();
  const { configs, repositories } = useConfigureChallenges();
  const {
    data,
    id,
    isUnderwritingView,
    openUnderwritingView,
    isOnboardingView,
  } = useMerchantSidePanelContext();
  const {
    underwritingScorePercentage = 0,
    accID,
    underwriterName,
    underwriterEmail,
    underwriterImageURL,
    underwriterSetByAcquirer,
  } = data?.status || {};

  const { status } = useComponentVisibilityOnStatus({
    data: data?.status,
    isRebranding: true,
  });

  return (
    <WithRepositoryProvider configs={configs} repositories={repositories}>
      <CardContainer backgroundColor={palette.surface?.primary}>
        <UnderwritingCardHeader
          underwritingScorePercentage={underwritingScorePercentage} //TODO: change for onboarding
          merchantId={accID}
          status={status as IChipProps} //TODO: double check once BE is implemented, it should work automatically
        />
        {!isUnderwritingView && !isOnboardingView && (
          <UnderwritingCardBody
            underwriterName={underwriterName}
            underwriterEmail={underwriterEmail}
            underwriterImageURL={underwriterImageURL}
            underwriterSetByAcquirer={underwriterSetByAcquirer}
            id={id}
            handleManageUnderwriting={openUnderwritingView}
            isOnboarding={status.label === "Onboarding"} //TODO: double check once we have BE, should work automatically
          />
        )}
        <UnderwritingChallengeCategories
          allChallengesCompleted={underwritingScorePercentage >= 80}
          merchantId={accID}
          merchantName={data?.merchantInfo?.merchantName}
          show={isUnderwritingView || isOnboardingView}
        />
      </CardContainer>
    </WithRepositoryProvider>
  );
};

export default UnderwritingCard;
