import { useMutation, useQueryClient } from "react-query";
// modal
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import {
  selectMasqueradeMode,
  selectMasqueradeModeHistory,
  setMasqueradeMode,
} from "@redux/slices/app";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectMasquerade } from "@redux/slices/enterprise/merchants";
import NiceModal from "@ebay/nice-modal-react";
import { ENTERPRISE_PREVIEW_PANEL, MASQUERADE_MODAL, MERCHANT_PREVIEW_PANEL_MODAL } from "modals/modal_names";
import { Role } from "@hooks/common/useUser";
import { useLocalMasquerade } from "@hooks/common/masquerade";

type TMasqueradeData = Record<string, any> & {
  accID?: number;
  name?: string;
  imageURL?: string;
  origin?: string;
  role: Role;
  nextRoute: string;
};

export default function useMasquerade() {
  const dispatch = useAppDispatch();
  const historyControlMode = useAppSelector(selectMasqueradeModeHistory);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { skipModal } = useAppSelector(selectMasquerade);
  const queryClient = useQueryClient();
  const storageMasquerade = useLocalMasquerade();
  const reduxMasquerade = useAppSelector(selectMasqueradeMode);

  const putMasqueradeMutation = useMutation((id: number) => {
    return customInstance({
      url: `masquerade/${id}`,
      method: "PUT",
    });
  });

  const deleteMasqueradeMutation = useMutation((data: any) => {
    return customInstance({
      url: "masquerade",
      method: "DELETE",
    }).then(() => {
      return historyControlMode;
    });
  });

  const masqueradeHandler = (
    origin: "provider" | "acquirer",
    rowData?: TMasqueradeData,
    onSuccess?: () => void,
  ) => {
    const data = rowData || ({} as TMasqueradeData);
    if (anchorEl) setAnchorEl(null);
    if (!data?.accID) return;

    putMasqueradeMutation.mutate(data.accID, {
      onError: (error: any) => {
        showMessage("Error", `${error.response?.data?.message}`);
      },
      onSuccess: async (res: any) => {
        /*
          We need to remove completelly the sidepanel from tree when going on control-mode
          If we keep it, then the sidepanel, mounted by the previous screen (example provider list on acquirer),
          will get rendered by the new screen (example merchant list in provider) on the acquirer ID and we get 403 permission error
        */
        NiceModal.remove(ENTERPRISE_PREVIEW_PANEL);
        NiceModal.remove(MERCHANT_PREVIEW_PANEL_MODAL)

        dispatch(
          setMasqueradeMode({
            id: data?.accID || 0,
            name: data?.name || "",
            origin: data?.origin ?? `/${origin}/merchants`,
            imgSrc: data?.imageURL || "",
            masqueradeUserRole: data?.role ?? Role.MERCHANT,
            historyControlMode: historyControlMode?.concat({
              origin: data?.origin ?? `/${origin}/merchants`,
              id: data?.accID,
              role: data?.role ?? Role.MERCHANT,
            }),
            firstName: data?.owner?.firstName,
          }),
        );

        navigate(data.nextRoute);
        if (!data.nextRoute.includes("/provider")) {
          await queryClient.refetchQueries(["get-campaign-stats", data.accID]);
          await queryClient.refetchQueries("list-all-payment-forms");
        }
        if (!skipModal) {
          NiceModal.show(MASQUERADE_MODAL, {
            name: data?.name,
          });
        }
        if (onSuccess) onSuccess();
      },
    });
  };

  const resetMasqueradeMode = (origin = "") => {
    dispatch(
      setMasqueradeMode({
        id: "",
        name: "",
        origin,
        imgSrc: "",
        masqueradeUserRole: "",
        historyControlMode: [],
      }),
    );
  };

  const clearMasquerade = (origin = "") => {
    if (storageMasquerade?.id || reduxMasquerade.id) {
      deleteMasqueradeMutation.mutate({});
    }
    resetMasqueradeMode(origin);
  };

  return {
    putMasqueradeMutation,
    deleteMasqueradeMutation,
    masqueradeHandler,
    clearMasquerade,
    resetMasqueradeMode,
  };
}
