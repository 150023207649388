import { MobileDatePicker } from "@mui/x-date-pickers";
import { GiveTextField } from "@shared/GiveInputs/GiveInput";
import CustomActions from "./components/CustomActions";
import {
  Calendar,
  CalendarBlank,
  CaretDown,
  CaretLeft,
  CaretRight,
} from "@phosphor-icons/react";
import { format, parse } from "date-fns";
import { useAppTheme } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { CustomPalette } from "@theme/v2/palette.interface";
import { Grid } from "@mui/material";

const getDialogSx = (palette: CustomPalette, isMobileView: boolean) => ({
  zIndex: 99999,
  "& .MuiDialog-paper": {
    backgroundImage: "none",
    boxShadow: "none",
    backgroundColor: palette.surface?.primary,
    borderRadius: "20px",
    padding: "0 12px 12px 0px",
    ...(!isMobileView && {
      width: "472px",
      minHeight: "542px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& .MuiBox-root": {
        padding: "0px",
      },
    }),
    ...(isMobileView && {
      height: "480px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: "0 auto",
      "& .MuiDialogContent-root": {
        overflowY: "hidden !important",
      },
      "& .MuiDatePickerToolbar-root": {
        paddingBottom: "0 !important",
      },
      "& .MuiPickersCalendarHeader-switchViewButton": {
        boxShadow: "none",
        border: "none !important",
        background: "none",
      },
      "& .MuiPickersToolbar-content": {
        "& .MuiPickersToolbar-penIconButton": {
          background: "none",
          boxShadow: "none",
          border: "none !important",
        },
      },
    }),
    "& .MuiPickersCalendarHeader-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: isMobileView ? "24px 16px 24px 24px" : "0",
      "& .MuiPickersCalendarHeader-label": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        color: palette.text?.primary,
      },
      "& .MuiPickersArrowSwitcher-root": {
        "& .MuiIconButton-root": {
          color: palette.text?.primary,
          background: "none",
          boxShadow: "none",
          border: "none !important",
        },
        "& .MuiPickersArrowSwitcher-spacer": {
          width: "8px",
        },
        "& .Mui-disabled": {
          color: palette.text?.disabled,
        },
      },
    },
    "& .MuiPickersDay-root": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: palette.surface?.tertiary,
      },
      "&.Mui-selected": {
        backgroundColor: palette.primitive?.neutral[100],
      },
    },
    "& .MuiPickersDay-today": {
      backgroundColor: palette.surface?.tertiary,
      border: "none",
    },
    "& .MuiYearPicker-root": {
      display: "flex",
      flexDirection: "row",
      overflowY: "scroll",
      rowGap: "12px",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: palette.primitive?.neutral[110],
        borderRadius: "10px",
      },
      "& .PrivatePickersYear-root": {
        flexBasis: "33.33%",
        "& .PrivatePickersYear-yearButton": {
          width: "100%",
          height: "100%",
          fontSize: "12px",
          lineHeight: "14px",
          fontWeight: 400,
          paddingY: "8px",
          borderRadius: "40px",
          color: palette.text?.primary,
        },
        "& .Mui-selected": {
          backgroundColor: palette.primitive?.neutral[100],
          color: palette.primitive?.neutral[0],
        },
      },
    },
    "& .MuiCalendarPicker-root": {
      width: "100%",
      overflow: "hidden",
    },
    ...(!isMobileView && {
      "& .MuiCalendarOrClockPicker-root": {
        gap: "8px",
        "& > div": {
          width: "100%",
        },
      },
      "& .MuiDialogActions-root": {
        padding: "0",
      },
      "& .MuiCalendarPicker-root": {
        width: "100%",
        overflow: "hidden",
        "& .MuiDayPicker-header": {
          justifyContent: "space-around",
        },
      },
      "& .MuiDayPicker-slideTransition": {
        minHeight: "400px",
        overflow: "hidden",
      },
      "& .MuiDayPicker-weekContainer": {
        width: "100%",
        justifyContent: "space-around",
        "& .MuiPickersDay-root": {
          width: "48px",
          height: "48px",
        },
      },
    }),
  },
});

export const Picker = ({
  label,
  name,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  textFieldSx,
  customDialogSx,
  disabled,
  placeholder = "mm/dd/yyyy",
  handleClickAccept,
  error,
  field: { ref, ...rest },
  ToolbarComponent,
  tempValue,
  submitButtonProps,
}: any) => {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();

  return (
    <MobileDatePicker
      {...rest}
      label={label}
      onChange={(newValue) => {
        rest.onChange(newValue);
      }}
      value={rest.value}
      inputRef={ref}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      closeOnSelect={false}
      showToolbar
      renderInput={({ inputProps, ...params }) => {
        return (
          <Grid container gap={1}>
            <GiveTextField
              name={name}
              inputRef={ref}
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
              disabled={disabled}
              fullWidth
              {...(tempValue && { tempValue })}
              sx={{
                ...textFieldSx,
              }}
              inputProps={{
                ...inputProps,
                placeholder: placeholder,
              }}
              {...params}
              label={label}
              leftContent={<CalendarBlank size={20} />}
            />
          </Grid>
        );
      }}
      DialogProps={{
        sx: {
          ...getDialogSx(palette, isMobileView),
          ...customDialogSx,
        },
      }}
      components={{
        ActionBar: (props) => (
          <CustomActions
            setValue={handleClickAccept}
            value={rest.value}
            {...(submitButtonProps && { submitButtonProps })}
            {...props}
          />
        ),
        LeftArrowIcon: () => <CaretLeft size={20} />,
        RightArrowIcon: () => <CaretRight size={20} />,
        SwitchViewIcon: () => <CaretDown size={20} />,
      }}
      {...(ToolbarComponent && { ToolbarComponent })}
      dayOfWeekFormatter={(weekday) =>
        format(parse(weekday, "EEEE", new Date()), "EEEEEE")
      }
      disableOpenPicker={Boolean(error)}
    />
  );
};
