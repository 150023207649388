import { Stack } from "@mui/system";
import { styled } from "@theme/v2/Provider";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";
import { useMemo } from "react";
import {
  ContentComponent,
  ImageComponents,
} from "../../../View/fundraiser/components/Atom.component";

const HeroSection = () => {
  const { parsedValues } = usePayBuilderForm();
  const {
    screenStates: { isMobileView },
  } = usePayBuilderContext();

  const url = parsedValues.selectedImage?.URL;
  const position = parsedValues.assetPosition;

  const direction = useMemo(() => {
    if (isMobileView) {
      if (position === "left") {
        return "column";
      }
      return "column-reverse";
    }
    if (position === "left") {
      return "row";
    }
    return "row-reverse";
  }, [isMobileView, position]);

  const image = <ImageComponents isMobileView={isMobileView} url={url} />;
  const content = (
    <ContentComponent
      description={parsedValues.description}
      heading={parsedValues.heading}
      isMobileView={isMobileView}
    />
  );

  return (
    <StyledStack direction={direction} spacing={3} isMobile={isMobileView}>
      {image}

      {content}
    </StyledStack>
  );
};

export default HeroSection;

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  alignItems: "flex-start",
  padding: "64px 0",
  ...(isMobile && {
    paddingTop: 0,
  }),
}));
