import React from "react";
import { CREATE_INVOICE_MODAL } from "modals/modal_names";

import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";

const NewInvoicesBanner = ({ backgroundUrl }: { backgroundUrl: string }) => {
  return (
    <NewProductBanner
      title={"Invoices"}
      campaignType="invoice"
      createModal={CREATE_INVOICE_MODAL}
      analyticsType="invoices"
      background={backgroundUrl}
      createCampaignTitle="Create Invoice"
    />
  );
};

export default NewInvoicesBanner;
