import { APP_BAR_HEIGHT } from "@components/CustomBreadcrumbsPage/BreadcrumbsAppBar";
import { MASQUERADE_SNACKBAR_HEIGHT } from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import { useGetCurrentMerchantId } from "@hooks/common";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";
import { Circle, CaretRight, CheckCircle } from "@phosphor-icons/react";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import GiveAnimationWrapper from "@shared/BannerAnimations/GiveAnimationWrapper";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";

const CONTAINER_PADDINGTOP = 24;

const getPagePixelsOffset = (isMasqueradeMode: boolean) => {
  const appBarOffset = APP_BAR_HEIGHT + CONTAINER_PADDINGTOP * 2;

  if (!isMasqueradeMode) return appBarOffset;
  return appBarOffset + MASQUERADE_SNACKBAR_HEIGHT;
};

export const WelcomePageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMasqueradeMode",
})<{ isMasqueradeMode: boolean }>(({ isMasqueradeMode }) => ({
  display: "flex",
  paddingBlock: "8px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  transition: "transform 0.5s ease-in-out",
  height: `calc(100vh - ${getPagePixelsOffset(isMasqueradeMode)}px)`,

  "@keyframes outAnimation": {
    "0%": {
      transform: "translateX(0%)",
      visibility: " hidden",
    },
    "100%": {
      transform: "translateX(-120%)",
      visibility: "visible",
    },
  },

  "@keyframes inAnimation": {
    "0%": {
      transform: "translateX(100%)",
      visibility: " hidden",
    },
    "100%": {
      transform: "translateX(0%)",
      visibility: "visible",
    },
  },
}));

export const Background = styled(GiveAnimationWrapper)(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 1) 100% )",
    zIndex: 1,
    pointerEvents: "none",
  },
}));

export const Card = styled(Box)(({ theme }) => ({
  padding: "48px 40px",
  gap: "32px",
  backgroundColor: theme.palette.surface?.["primary-transparent"],
  width: "426px",
  borderRadius: theme.customs?.radius.large,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backdropFilter: "blur(25px)",
  zIndex: 3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "32px 20px",
  },
}));

export const ChecklistContainer = styled(Stack)(({ theme }) => ({
  padding: "12px",
  backgroundColor: theme.palette.surface?.primary,
  borderRadius: "20px",
  width: "100%",
}));

export function CardHeader({ completed }: { completed: boolean }) {
  const { masqFirstName } = useGetCurrentMerchantId();
  const {
    globalName: { firstName },
  } = useAppSelector(selectUser);
  return (
    <Stack gap="16px">
      {completed ? (
        <GiveText textAlign="center" variant="h2">
          You’re set up
        </GiveText>
      ) : (
        <GiveText textAlign="center" variant="h2">
          Welcome {masqFirstName ? masqFirstName : firstName}.
          <br />
          Let’s get you started
        </GiveText>
      )}
      <GiveText variant="bodyM" textAlign="center" color="secondary">
        From creation to launch and everything in between, we’ll help you get up
        and running.
      </GiveText>
    </Stack>
  );
}

export function ChecklistButton({
  label,
  action,
  isCompleted,
  disabled,
}: {
  label: string;
  action: () => void;
  isCompleted?: boolean;
  disabled?: boolean;
}) {
  const { palette } = useAppTheme();
  return (
    <GiveButton
      onClick={action}
      disabled={disabled}
      label={
        <GiveText textAlign="left" flex={1} variant="bodyS" color="secondary">
          {label}
        </GiveText>
      }
      startIcon={
        isCompleted ? (
          <CheckCircle
            color={palette.primitive?.success[50]}
            size={24}
            weight="fill"
          />
        ) : (
          <Circle color={palette.text.secondary} size={24} />
        )
      }
      endIcon={
        isCompleted ? undefined : (
          <CaretRight color={palette.text.secondary} size={18} />
        )
      }
      variant="ghost"
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "12px",
        borderRadius: "8px",
        ...(isCompleted && {
          pointerEvents: "none",
        }),
      }}
    />
  );
}
