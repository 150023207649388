import { OptionWrapper } from "./OptionWrapper";

export const Card = ({ width = 226 }: { width?: number }) => {
  return (
    <OptionWrapper type="card">
      <svg
        width={width}
        height="120"
        viewBox={`0 0 ${width} 120`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={width}
          height="120"
          rx="12"
          fill="black"
          fillOpacity="0.05"
        />
        <rect
          x="8"
          y="8"
          width={width - 16}
          height="76"
          rx="8"
          fill="black"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="92"
          width="62"
          height="8"
          rx="4"
          fill="black"
          fillOpacity="0.1"
        />
        <rect
          x="8"
          y="104"
          width="40"
          height="8"
          rx="4"
          fill="black"
          fillOpacity="0.1"
        />
      </svg>
    </OptionWrapper>
  );
};
