import { Grid, Stack } from "@mui/material";
import PayBuilderFormProvider, {
  usePayBuilderForm,
} from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { ThemeWrapper } from "@theme/v2/Provider";
import CheckoutCart from "./components/CheckoutCart";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import PaymentSuccess, {
  PaymentSuccessInfo,
} from "./components/PaymentSuccess";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DarkWithCustomTheme } from "@sections/PayBuilder/FormPreview";
import CheckoutFormPreview from "@sections/PayBuilder/Checkout/CheckoutFormPreview";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import FundraiserRightCard from "@sections/PayBuilder/View/fundraiser/FundraiserRightCard";
import { LogoComponent } from "@sections/PayBuilder/View/fundraiser/components/Atom.component";
import { getEventDate, getEventLocation } from "@sections/PayBuilder/utils";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";

const CheckoutPage = () => {
  return (
    <ThemeWrapper isNewThemeOnly>
      <PayBuilderFormProvider>
        <Content />
      </PayBuilderFormProvider>
    </ThemeWrapper>
  );
};

const Content = () => {
  const { parsedValues, data } = usePayBuilderForm();
  const { isMobileView } = useCustomThemeV2();

  const [paymentSuccessInfo, setPaymentSuccessInfo] =
    useState<PaymentSuccessInfo | null>(null);

  const { id } = useParams();
  const breadcrumbItems = [
    { label: "Browse", path: `/${id}` },
    { label: "Checkout" },
  ];
  const { isFundraiser } = useCheckFormType();

  const eventSuccessDetails = {
    location: getEventLocation(
      data.locationAddress,
      data.locationShortAddress,
      data.locationURL,
    ),
    date: getEventDate(data.startsAt, data.endsAt, data.includeTime),
  };

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: parsedValues.background,
        alignItems: "center",
        paddingTop: "20px",
      }}
    >
      {isMobileView && (
        <LogoComponent logo={parsedValues.logo} isPreviewMode={false} />
      )}
      <DarkWithCustomTheme>
        <Grid
          container
          sx={{
            backgroundColor: parsedValues.background,
            maxWidth: "1090px",
            minHeight: "100dvh",
          }}
          direction={isMobileView ? "column" : "row-reverse"}
          justifyContent="flex-start"
          paddingBottom="20px"
        >
          {isFundraiser && isMobileView ? (
            <>
              <MainContent
                paymentSuccessInfo={paymentSuccessInfo}
                setPaymentSuccessInfo={setPaymentSuccessInfo}
                breadcrumbItems={breadcrumbItems}
              />
              <SidebarContent
                isFundraiser={isFundraiser}
                isMobileView={isMobileView}
                paymentSuccessInfo={paymentSuccessInfo}
              />
            </>
          ) : (
            <>
              <SidebarContent
                isFundraiser={isFundraiser}
                isMobileView={isMobileView}
                paymentSuccessInfo={paymentSuccessInfo}
              />
              <MainContent
                paymentSuccessInfo={paymentSuccessInfo}
                setPaymentSuccessInfo={setPaymentSuccessInfo}
                breadcrumbItems={breadcrumbItems}
                eventSuccessDetails={eventSuccessDetails}
              />
            </>
          )}
        </Grid>
      </DarkWithCustomTheme>
    </Stack>
  );
};

export default CheckoutPage;

const MainContent = ({
  paymentSuccessInfo,
  setPaymentSuccessInfo,
  breadcrumbItems,
  eventSuccessDetails,
}: {
  paymentSuccessInfo: PaymentSuccessInfo | null;
  setPaymentSuccessInfo: (info: PaymentSuccessInfo) => void;
  breadcrumbItems: { label: string; path?: string }[];
  eventSuccessDetails?: any;
}) => (
  <Grid item xs={7}>
    {paymentSuccessInfo?.orderNumber ? (
      <PaymentSuccess
        paymentSuccessInfo={paymentSuccessInfo}
        breadcrumbItems={breadcrumbItems}
        eventSuccessDetails={eventSuccessDetails}
      />
    ) : (
      <CheckoutFormPreview
        isPreviewMode={false}
        onPaymentSuccess={setPaymentSuccessInfo}
        breadcrumbItems={breadcrumbItems}
      />
    )}
  </Grid>
);

const SidebarContent = ({
  isFundraiser,
  isMobileView,
  paymentSuccessInfo,
}: {
  isFundraiser: boolean;
  isMobileView: boolean;
  paymentSuccessInfo: PaymentSuccessInfo | null;
}) => {
  const { parsedValues, methods } = usePayBuilderForm();
  const showDonationTarget = methods.watch("About.addTarget");
  const donationTarget = methods.watch("About.targetValue");
  const showDonationHistory = methods.watch("About.showHistory");
  const totalContribution = methods.watch("About.totalContribution");
  const checkoutText = methods.watch("Style.checkoutContent");
  const isApprovedNonProfit = methods.watch("About.isApprovedNonProfit");
  const isExistingForm = Boolean(methods.watch("productId"));
  const {
    screenStates: {
      isTabletView: isTabletContextView,
      isMobileDevice: isMobileContextView,
    } = {},
  } = usePayBuilderContext() || {};

  return (
    <Grid
      item
      xs={5}
      sx={{
        paddingLeft: isMobileView ? "20px" : undefined,
        paddingRight: isMobileView ? "20px" : undefined,
        width: "100vw",
      }}
    >
      <Stack sx={{ gap: "32px" }}>
        {(isFundraiser
          ? !isMobileView || paymentSuccessInfo?.orderNumber
          : true) && (
          <CheckoutCart isSuccessDisplayed={Boolean(paymentSuccessInfo)} />
        )}
        {isFundraiser && paymentSuccessInfo?.orderNumber && (
          <FundraiserRightCard
            accentColor={parsedValues.accentColor}
            formValues={{
              showDonationTarget,
              donationTarget,
              showDonationHistory,
              totalContribution,
              isExistingForm,
              checkoutText,
              isApprovedNonProfit,
            }}
            isTabletContextView={isTabletContextView}
            isMobileContextView={isMobileContextView}
            showButtons={false}
          />
        )}
      </Stack>
    </Grid>
  );
};
// );
