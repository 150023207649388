import { Box, BoxProps } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import React from "react";

type AlertStyleType = {
  type: "default" | "blue" | "warning" | "error" | "success";
  withBorder?: boolean;
};

interface AlertProps extends AlertStyleType {
  Icon: React.ReactElement;
  label: string;
}

export default function GiveAlert({
  type,
  withBorder = false,
  Icon,
  label,
}: AlertProps) {
  return (
    <AlertContainer type={type} withBorder={withBorder}>
      {Icon}
      <GiveText variant="bodyS">{label}</GiveText>
    </AlertContainer>
  );
}

const AlertContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "type" && prop !== "withBorder",
})<
  BoxProps & {
    type: "default" | "blue" | "warning" | "error" | "success";
    withBorder?: boolean;
  }
>(({ theme, type, withBorder }) => {
  const BACKGORUND = {
    default: theme.palette.primitive?.transparent["darken-5"],
    blue: theme.palette.primitive?.blue[10],
    success: theme.palette.primitive?.success[25],
    warning: theme.palette.primitive?.warning[10],
    error: theme.palette.primitive?.error[25],
  };
  const BORDER = {
    default: theme.palette.primitive?.neutral[50],
    blue: theme.palette.primitive?.blue[100],
    success: theme.palette.primitive?.success[100],
    warning: theme.palette.primitive?.warning[100],
    error: theme.palette.primitive?.error[100],
  };

  const COLOR = {
    default: theme.palette.text.primary,
    blue: theme.palette.primitive?.blue[100],
    success: theme.palette.primitive?.success[100],
    warning: theme.palette.primitive?.warning[100],
    error: theme.palette.primitive?.error[100],
  };

  return {
    boxSizing: "content-box",
    padding: "12px",
    gap: "12px",
    maxHeight: "36px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: BACKGORUND[type],
    "& svg": {
      color: COLOR[type],
      height: "24px",
      width: "24px",
    },
    "& p": {
      color: COLOR[type],
    },
    ...(withBorder && {
      border: `1.5px solid ${BORDER[type]}`,
    }),
  };
});
