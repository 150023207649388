import {
  EditorState,
  Entity,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
  RawDraftContentBlock,
  DraftInlineStyleType,
} from "draft-js";

export const convertEditorToADB = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const rawContent = convertToRaw(contentState);

  const adbContent = rawContent.blocks.map((block) => {
    const blockData = {
      type: block.type,
      text: block.text,
      styles: block.inlineStyleRanges.map((styleRange) => ({
        style: styleRange.style,
        offset: styleRange.offset,
        length: styleRange.length,
      })),
      entities: block.entityRanges.map((entityRange) => {
        const entity = Entity.get(entityRange.key.toString());
        const entityData = entity.getData();

        return {
          entityKey: entityRange.key.toString(),
          offset: entityRange.offset,
          length: entityRange.length,
          entityData,
        };
      }),
    };

    return blockData;
  });

  return adbContent;
};

type ADBContent = {
  type: string;
  text: string;
  styles: { style: string; offset: number; length: number }[];
  entities: {
    entityKey: string;
    offset: number;
    length: number;
    entityData: any;
  }[];
}[];

export const convertADBToEditorState = (
  adbContent: ADBContent,
): EditorState => {
  // Create content blocks with required properties (key and depth)
  const blocks: RawDraftContentBlock[] = adbContent.map((block, index) => {
    return {
      key: `block-${index}`, // Create a unique key for each block
      text: block.text,
      type: block.type,
      entityRanges: block.entities.map((entity) => ({
        key: parseInt(entity.entityKey, 10), // Convert the entityKey to a number
        offset: entity.offset,
        length: entity.length,
      })),
      inlineStyleRanges: block.styles.map((style) => ({
        style: style.style as DraftInlineStyleType, // Cast to DraftInlineStyleType
        offset: style.offset,
        length: style.length,
      })),
      data: {},
      depth: 0, // Set depth to 0 (unless dealing with nested content, like lists)
    };
  });

  // Prepare the raw content state to be converted into Draft.js format
  const rawContent: RawDraftContentState = {
    blocks,
    entityMap: {}, // You can populate the entityMap if you need to map entity data here
  };

  // Recreate the editor state from raw content
  const contentState = convertFromRaw(rawContent);

  // Set entity data back (if applicable)
  adbContent.forEach((block) => {
    block.entities.forEach((entity) => {
      const entityKey = parseInt(entity.entityKey, 10); // Convert entityKey to a number
      // Create or update the entity in the entity map
      Entity.create(entityKey.toString(), "MUTABLE", entity.entityData);
    });
  });

  return EditorState.createWithContent(contentState);
};

export function parseDescription(description: any) {
  try {
    const parsed = JSON.parse(description);
    if (
      parsed.blocks &&
      Array.isArray(parsed.blocks) &&
      parsed.blocks[0]?.text
    ) {
      return parsed.blocks[0].text;
    }
  } catch (e) {
    return description;
  }

  return description;
}
