import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { TermsOfService } from "@pages/TermsOfService";
import SignAgreementSection from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/SignAgreementSection";
import { useFormContext } from "react-hook-form";
import {
  TMerchantInfo,
  TPrimaryAccountHolder,
} from "@components/Merchants/MerchantPreview/data.types";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useScrollCheck } from "../MerchantAgreementSetup/hooks/useScrollCheck";
import ScrollBottomButton from "@components/AcquirerEnterprises/EnterprisePreview/components/ScrollBottomButton";
import { useRef } from "react";
import { LastUpdatedOn_merchant_agreement } from "features/Merchants/MerchantSidePanel/constants";

type TData = {
  merchantInfo: TMerchantInfo;
  primaryAccountHolder: TPrimaryAccountHolder;
  merchantAgreement: TMerchantAgreementPayload;
  status: {
    statusName: string;
  };
};

type Props = {
  data: TData;
  signDisabled?: boolean;
};

const SignTOS = ({ data, signDisabled }: Props) => {
  const { isMobileView } = useCustomTheme();
  const { setValue } = useFormContext();

  const { CheckboxWithTooltip, handleScroll } = useScrollCheck(
    Boolean(data?.merchantAgreement?.signatureURL),
  );
  const containerRef = useRef<HTMLDivElement | null>(null);

  const addSignature = (signature: File) => {
    setValue("signature", signature, { shouldDirty: true });
  };

  return (
    <Stack
      direction="column"
      gap="24px"
      marginInline={isMobileView ? 0 : "30px"}
    >
      <TOSWrapper
        onScroll={handleScroll}
        data-testid="agreement"
        ref={containerRef}
      >
        <TermsOfService
          merchantAgreementVersion={{
            version: data.merchantAgreement.tcVersion || "",
            lastUpdated: LastUpdatedOn_merchant_agreement,
          }}
        />
        <ScrollBottomButton containerRef={containerRef} />
      </TOSWrapper>
      <CheckboxWithTooltip name="hasAgreed" />
      <SignAgreementSection
        data={data}
        canSign
        addSignature={addSignature}
        status={data.merchantAgreement.signatureURL ? "signed" : "not_signed"}
        isOnboarding
        disabled={signDisabled}
      />
    </Stack>
  );
};

export const TOSWrapper = styled(Box)(() => ({
  margin: "0 auto",
  width: "100%",
  height: "618px",
  borderRadius: "16px",
  border: "none",
  backgroundColor: "white",
  overflowY: "scroll",
  position: "relative",
  "*": {
    color: palette.black.main,
  },
  "& .MuiCardMedia-root": {
    backgroundColor: "white",
    height: "618px",
    overflowY: "scroll",
  },
}));

export default SignTOS;
