import { endOfDay } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";

export const toUnixTimeWithTime = (date: Date, time: string): number | null => {
  if (!date) return null;

  if (isNaN(date.getTime())) return null;

  if (time && time.trim() !== "") {
    const [timeString, meridian] = time.split(" ");
    const [hours, minutes] = timeString.split(":").map(Number);

    let adjustedHours = hours % 12;
    if (meridian.toLowerCase() === "pm") {
      adjustedHours += 12;
    }

    date.setHours(adjustedHours, minutes || 0, 0, 0);
  }

  return Math.floor(date.getTime() / 1000);
};

export const toUnixDateFormat = (date: Date) =>
  Math.floor(date?.getTime() / 1000);

export const convertPhoneNumber = (phoneNumber: string): string | null => {
  return phoneNumber?.replace("+", "")?.replace(/\s/g, "");
};
export const isEmptyPhone = (phone: any) =>
  [null, "", "+", "+1", "1"].includes(phone);
export const fromUnixDateFormat = (
  unixTimestamp: number,
  withDots?: boolean,
): string => {
  const date = new Date(unixTimestamp * 1000);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear().toString();
  return withDots ? `${month}.${day}.${year}` : `${month}/${day}/${year}`;
};

export const toUTCStartOfDay = (date: Date) => {
  const timezoneOffset = Math.abs(date.getTimezoneOffset());
  const hoursTime = timezoneOffset / 60;
  date.setHours(hoursTime);
  return date;
};

function addPlural(relativeTimeString: string) {
  const [amount, unit] = relativeTimeString.split(" ");
  if (amount === "1") {
    return {
      amount,
      unit: unit + "s",
    };
  }

  return {
    amount,
    unit,
  };
}

export const parseRelativeTimeString = (relativeTimeString: string) => {
  if (relativeTimeString === "")
    return {
      formattedDate: calculateDate(),
    };

  const { amount, unit } = addPlural(relativeTimeString);

  const timeDelta: Record<string, any> = {};
  timeDelta[unit] = parseInt(amount);

  const formatter = new Intl.DateTimeFormat("it-IT", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const now = new Date();
  const targetDate = new Date(now);
  targetDate.setFullYear(
    now.getFullYear() + timeDelta.years || now.getFullYear(),
  );
  targetDate.setMonth(now.getMonth() + timeDelta.months || now.getMonth());
  targetDate.setDate(now.getDate() + timeDelta.days || now.getDate());
  targetDate.setHours(now.getHours() + timeDelta.hours || now.getHours());
  targetDate.setMinutes(
    now.getMinutes() + timeDelta.minutes || now.getMinutes(),
  );
  targetDate.setSeconds(
    now.getSeconds() + timeDelta.seconds || now.getSeconds(),
  );

  const formattedDate = formatter.format(targetDate).replace(/\D/g, ".");

  return { targetDate, formattedDate };
};

const getElapsedTime = (date: number) => {
  const now = Date.now();

  const elapsedSeconds = Math.floor((now - date) / 1000);
  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  const elapsedHours = Math.floor(elapsedMinutes / 60);
  const elapsedDays = Math.floor(elapsedHours / 24);
  const elapsedMonths = Math.floor(elapsedDays / 30);
  const elapsedYears = Math.floor(elapsedDays / 365);

  return {
    elapsedSeconds,
    elapsedMinutes,
    elapsedHours,
    elapsedDays,
    elapsedMonths,
    elapsedYears,
  };
};

const elapsedTimeToString = (rest: {
  elapsedSeconds: number;
  elapsedMinutes: number;
  elapsedHours: number;
  elapsedDays: number;
  elapsedMonths: number;
  elapsedYears: number;
}) => {
  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  switch (true) {
    case rest.elapsedYears >= 1:
      return rtf.format(-rest.elapsedYears, "year");
    case rest.elapsedMonths >= 1:
      return rtf.format(-rest.elapsedMonths, "month");
    case rest.elapsedDays >= 1:
      return rtf.format(-rest.elapsedDays, "day");
    case rest.elapsedHours >= 1:
      return rtf.format(-rest.elapsedHours, "hour");
    case rest.elapsedMinutes >= 1:
      return rtf.format(-rest.elapsedMinutes, "minute");
    default:
      return rtf.format(-rest.elapsedSeconds, "second");
  }
};

export const getRelativeTime = (date: number) => {
  const { elapsedDays, ...rest } = getElapsedTime(date);

  if (elapsedDays > 90) {
    return { expiresIn: "Expired", elapsedTime: "" };
  } else if (elapsedDays === 90) {
    return { expiresIn: "Expires today", elapsedTime: "" };
  } else if (elapsedDays >= 83) {
    return {
      expiresIn: `Expires in ${90 - elapsedDays} days`,
      elapsedTime: "",
    };
  }

  const result = elapsedTimeToString({ elapsedDays, ...rest });

  return { expiresIn: "", elapsedTime: result };
};

const calculateDate = () => {
  return new Intl.DateTimeFormat("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    formatMatcher: "best fit",
  })
    .format(Date.now())
    .replace(/\D/g, ".");
};

export const checkEnd = (
  startsAt: number | null | undefined,
  endAt: number | null,
): number => {
  if (endAt) {
    return endAt * 1000;
  }

  if (startsAt && !endAt) {
    return endOfDay(new Date(startsAt * 1000)).getTime();
  }

  return 0;
};

type TimeStampFormaterType = {
  hour?: boolean;
  timeZone?: boolean;
  hours12Format?: boolean;
};

export const timestampToFormattedString = (
  timestamp: number,
  options: TimeStampFormaterType = {
    hour: false,
    timeZone: false,
    hours12Format: false,
  },
) => {
  const date = new Date(timestamp);
  let parser = "MMMM d  yyyy";

  if (options.hour) {
    if (options.hours12Format) {
      parser += ", h:mm a";
    } else {
      parser += ", HH:mm";
    }
  }

  // Get user's timezone from the browser
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formattedDate = format(date, parser, {
    timeZone: userTimeZone,
  });

  return options.timeZone ? `${formattedDate} GMT` : `${formattedDate}`;
};

export function getPeriodText(
  startDate?: string | null,
  endDate?: string | null,
) {
  if (!startDate || !endDate) {
    return "All time";
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return "All time";
  }

  const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  const diffInWeeks = diffInDays / 7;
  const diffInMonths = diffInDays / 30.44; // Average number of days in a month
  const diffInYears = diffInDays / 365.25; // Average number of days in a year

  if (diffInYears >= 1) {
    return `${Math.round(diffInYears)} year(s)`;
  } else if (diffInMonths >= 1) {
    return `${Math.round(diffInMonths)} month(s) `;
  } else if (diffInWeeks >= 1) {
    return `${Math.round(diffInWeeks)} week(s) `;
  } else {
    return `${Math.round(diffInDays)} day(s) `;
  }
}
