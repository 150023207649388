import { showMessage } from "@common/Toast";
import { useGetCurrentMerchantId } from "@hooks/common";
import { MediaItem } from "@sections/PayBuilder/provider/provider.type";
import { customInstance } from "@services/api";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";

interface MediaLibraryResponse {
  data: MediaItem[];
}
export default function useManageImageModal({ open }: { open: boolean }) {
  const [openDelete, setOpenDelete] = useState<MediaItem | null>(null);
  const { merchantId } = useGetCurrentMerchantId();

  const handleCloseDelete = () => setOpenDelete(null);
  const getMedia = useQuery<MediaLibraryResponse>(
    ["get-all-media-items"],
    async () => {
      return customInstance({
        url: `/accounts/${merchantId}/media-items?max=20&after=0&sort=-id&page=1`,
      });
    },
    {
      enabled: open && Boolean(merchantId),
    },
  );

  const mutateMedia = useMutation(
    (data: { id?: number }) => {
      return customInstance({
        url: `/accounts/${merchantId}/media-items/${data.id}`,
        method: "DELETE",
      });
    },
    {
      onSuccess() {
        getMedia.refetch();
        handleCloseDelete();
        showMessage("Success", "Image has been successfully deleted");
      },
    },
  );

  const newList = [
    {
      URL: null,
      id: 0,
    },
    ...(getMedia.data?.data || []),
  ];

  const handleDelete = () => {
    openDelete?.id && mutateMedia.mutate({ id: openDelete.id });
  };

  return {
    getMedia,
    mutateMedia,
    openDelete,
    newList,
    setOpenDelete,
    handleDelete,
    handleCloseDelete,
    isLoading: getMedia.isLoading,
  };
}
