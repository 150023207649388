import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { EDDModalCheckbox } from "./components/EDDModalCheckbox";
import { EDDData } from "../hooks/useChallenges";
import { EDD_CHALLENGE_MODAL } from "modals/modal_names";
import { EDDChallengeModalProps } from "../types";

const NOTIFY_MERCHANT_TOOLTIP_TEXT =
  "The merchant will receive a notification containing instructions on how to complete this challenge";
const INTERNAL_NOTE_TOOLTIP_TEXT =
  "This note will be shared in internal discussions with other acquirer users";

const schema = Yup.object().shape({
  name: Yup.string()
    .required("Challenge name is required")
    .min(1, "Challenge name cannot be empty"),
  notifiedMessage: Yup.string()
    .required("How to complete this challenge is required")
    .min(1, "How to complete this challenge cannot be empty"),
});

const TypeMap = {
  create: {
    title: "Add Challenge",
    buttonLabel: "Create",
  },
  edit: {
    title: "Edit Challenge",
    buttonLabel: "Save",
  },
};

const EDDChallengeModal = NiceModal.create(
  ({
    type = "create",
    challengeName,
    challengeDescription,
    challengeID,
    handleSubmit,
  }: EDDChallengeModalProps) => {
    const modal = useModal(EDD_CHALLENGE_MODAL);

    const methods = useForm<EDDData>({
      mode: "onChange",
      reValidateMode: "onSubmit",
      defaultValues: {
        name: type === "edit" ? challengeName : "",
        notifiedMessage: type === "edit" ? challengeDescription : "",
        notifyMerchant: false,
        isInternalNote: false,
      },
      resolver: yupResolver(schema),
    });

    const handleClose = () => {
      methods.reset();
      modal.remove();
    };

    const onSubmit: SubmitHandler<EDDData> = (data: EDDData) => {
      handleSubmit(challengeID, data);
      handleClose();
    };

    return (
      <GiveBaseModal
        open={modal.visible}
        title={TypeMap[type].title}
        width="560px"
        height="67%"
        onClose={handleClose}
        buttons={
          <Stack gap="12px" flexDirection="row">
            <GiveButton
              onClick={handleClose}
              label="Cancel"
              variant="ghost"
              size="large"
            />
            <GiveButton
              onClick={methods.handleSubmit(onSubmit)}
              form="edd-inputs"
              label={TypeMap[type].buttonLabel}
              variant="filled"
              size="large"
              sx={{
                border: "none",
              }}
            />
          </Stack>
        }
      >
        <FormProvider {...methods}>
          <Stack
            gap="24px"
            component="form"
            id="edd-inputs"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <HFGiveInput label="Challenge Name" name="name" maxLength={50} />
            <HFGiveInput
              label="How to complete this challenge?"
              name="notifiedMessage"
              placeholder="Provide clear and concise directions to complete this challenge"
              multiline
              rows={8}
            />
            {type === "create" && (
              <Stack gap="12px">
                <EDDModalCheckbox
                  name="notifyMerchant"
                  label="Notify Merchant"
                  tooltipText={NOTIFY_MERCHANT_TOOLTIP_TEXT}
                />
                <EDDModalCheckbox
                  name="isInternalNote"
                  label="Internal Note"
                  tooltipText={INTERNAL_NOTE_TOOLTIP_TEXT}
                />
              </Stack>
            )}
          </Stack>
        </FormProvider>
      </GiveBaseModal>
    );
  },
);

export default EDDChallengeModal;
