import { Divider, Grid, Stack } from "@mui/material";
import CustomDropZone from "./components/CustomDropZone";
import BrandingCard from "./components/BrandingCard";
import { useThemeContext } from "./Provider/CustomThemeProvider";
import SampleCards from "./components/SampleCards";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { memo } from "react";
import { palette } from "@palette";
import { BrandingHeading, FormActions } from "./components/atoms";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { HiddenComponent } from "@containers/HiddenComponent";
import EmbedCodeSection from "./components/EmbeddedCode";
import { brandingHeadings } from "./constants";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

function Branding() {
  const {
    activeGradientColor,
    onSelectGradientColor,
    colorChoices,
    isEqualDefault,
    resetValues,
    inViewRef,
    handleSaveTheme,
  } = useThemeContext();
  const { isMobileView } = useCustomTheme();

  const renderChoices = (c: (typeof colorChoices)[number], index: number) => {
    const isSelected =
      c.linearColor?.light === activeGradientColor?.light &&
      c.linearColor?.dark === activeGradientColor?.dark;
    return (
      <BrandingCard
        key={c.title + index}
        {...c}
        onSelectGradientColor={onSelectGradientColor}
        isSelected={isSelected}
      />
    );
  };

  const sections = [
    {
      node: <LogoSection />,
      withDivider: true,
    },
    {
      node: (
        <Stack direction="column" gap="16px" alignItems="stretch">
          <BrandingHeading {...brandingHeadings["cards"]} />

          <Grid container columnSpacing="16px" rowSpacing={2}>
            {colorChoices?.map(renderChoices)}
          </Grid>
        </Stack>
      ),
      withDivider: isMobileView,
    },
    {
      node: (
        <Grid container columnSpacing="16px" rowSpacing={2}>
          <SampleCards />
          {/*<SampleCards isDarkMode />*/}
        </Grid>
      ),
      hidden: isMobileView,
      withDivider: true,
    },
    {
      node: <EmbedCodeSection />,
      withDivider: false,
    },
  ];

  return (
    <ErrorCatcher errorID="theme-and-branding">
      <Stack
        ref={inViewRef}
        direction="column"
        gap="50px"
        paddingBottom="16px"
        alignItems="stretch"
        overflow="hidden"
      >
        <Stack direction="column" gap="24px" alignItems="stretch" flexGrow={1}>
          {sections.map(({ node, hidden, withDivider }, index) => (
            <HiddenComponent key={index} hidden={hidden || false}>
              <>
                <FadeUpWrapper delay={50 + 70 * index}>{node}</FadeUpWrapper>
                {withDivider && (
                  <FadeUpWrapper delay={50 + 100 * index}>
                    <Divider color={palette.liftedWhite[100]} />
                  </FadeUpWrapper>
                )}
              </>
            </HiddenComponent>
          ))}
        </Stack>
        <FormActions
          primaryAction={{
            onClick: handleSaveTheme,
            disabled: isEqualDefault,
          }}
          secondaryAction={{
            onClick: resetValues,
            disabled: isEqualDefault,
          }}
        />
      </Stack>
    </ErrorCatcher>
  );
}

export default memo(Branding);

const LogoSection = () => {
  const { isMobileView } = useCustomTheme();
  const isUpdateLogoAllowed = useAccessControl({
    resource: RESOURCE_BASE.ENTERPRISE,
    operation: OPERATIONS.UPDATE,
  });
  return (
    <Stack direction={isMobileView ? "column" : "row"} gap="24px" flexGrow={1}>
      <BrandingHeading {...brandingHeadings["logo"]} />
      <Grid container columnSpacing="16px">
        <Grid item xs={7}>
          <WithTooltipWrapper
            hasTooltip={!isUpdateLogoAllowed}
            tooltipProps={{
              show: !isUpdateLogoAllowed,
              message: EDIT_DENY_MESSAGE,
            }}
          >
            <CustomDropZone
              type="landscape"
              textLabel="200&times;48"
              disabled={!isUpdateLogoAllowed}
            />
          </WithTooltipWrapper>
        </Grid>
        <Grid item xs={5}>
          <WithTooltipWrapper
            hasTooltip={!isUpdateLogoAllowed}
            tooltipProps={{
              show: !isUpdateLogoAllowed,
              message: EDIT_DENY_MESSAGE,
            }}
          >
            <CustomDropZone
              type="square"
              textLabel="48&times;48"
              disabled={!isUpdateLogoAllowed}
            />
          </WithTooltipWrapper>
        </Grid>
      </Grid>
    </Stack>
  );
};
