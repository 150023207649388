import React, { useEffect } from "react";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";
import SidePanelTitle from "@shared/SidePanel/components/SidepanelTitle/SidePanelTitle";
import MerchantInformationSection from "./MerchantInformationSection";
import SponsorApprovalSection from "./SponsorApprovalSection/SponsorApprovalSection";
import AccountStatusOverviewSection from "./AccountStatusOverviewSection/AccountStatusOverviewSection";
import UnderwriterRiskAnalystAssignCard from "./UnderwriterRiskAnalystAssignCard/UnderwriterRiskAnalystAssignCard";
import RiskSection from "./GiveMerchantSections";
import UnderwritingCard from "features/Underwriting/UnderwritingCard/UnderwritingCard";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useComponentVisibilityOnStatus } from "@components/Merchants/MerchantPreview/sections/StatusReview/hooks/useComponentVisibilityOnStatus";

function SidePanelBody() {
  const {
    data,
    isSponsorView,
    isAllTabView,
    isUnderwritingView,
    isOnboardingView,
    isLoadingUnderwriting,
    setIsLoadingUnderwriting,
  } = useMerchantSidePanelContext();
  const imageUrl = data?.header?.imageURL;

  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();
  const { isUnderwritingReportVisible } = useComponentVisibilityOnStatus({
    data: data.status,
    isRebranding: true,
  });
  const { merchant_underwriting } = useEnterprisePermissions();
  const hideUnderwritingCard =
    !isViewUnderwritingAllowed ||
    !merchant_underwriting ||
    !isUnderwritingReportVisible;
  const isPreApprovedOrRFR =
    data?.status?.instant === "Pre Approved" ||
    (data?.status?.sponsorStatusName === "ready_for_review" &&
      isUnderwritingView);

  //set underwriting loading to false automatically if we are hiding the section
  useEffect(() => {
    if (
      isLoadingUnderwriting &&
      (hideUnderwritingCard || !isPreApprovedOrRFR) &&
      !isOnboardingView
    )
      setIsLoadingUnderwriting(false);
  }, [isLoadingUnderwriting]);

  const merchantsSidePanelComponents = [
    {
      component: (
        <SidePanelTitle
          panelType="merchantSidePanel"
          title={data?.merchantInfo?.merchantName}
          imageUrl={imageUrl ? imageUrl + "/thumb" : undefined}
          id={data?.merchantInfo?.merchantID}
        />
      ),
      hide: false,
    },
    {
      component: <MerchantInformationSection />,
      hide:
        (!isAllTabView && isSponsorView) ||
        isUnderwritingView ||
        isOnboardingView,
    },
    {
      component: <SponsorApprovalSection />,
      hide: !isSponsorView,
    },
    {
      component: <UnderwritingCard />,
      hide: (!isPreApprovedOrRFR || hideUnderwritingCard) && !isOnboardingView,
    },
    {
      component: <RiskSection />,
      hide: !["approved", "suspended"].includes(data?.status?.statusName),
    },
    {
      component: <AccountStatusOverviewSection />,
      hide: !["approved", "suspended", "declined"].includes(
        data?.status?.statusName,
      ),
    },
    {
      component: (
        <UnderwriterRiskAnalystAssignCard
          type={
            isOnboardingView
              ? "Assignee"
              : ["approved", "suspended"].includes(data?.status?.statusName)
              ? "Risk Analyst"
              : "Underwriter"
          }
        />
      ),
      hide: false,
    },
  ];
  return (
    <>
      {merchantsSidePanelComponents?.map(({ component, hide }, i) => {
        if (hide) return null;
        return <React.Fragment key={i}>{component}</React.Fragment>;
      })}
    </>
  );
}

export default SidePanelBody;
