import React, { ReactNode } from "react";
import {
  Dialog,
  DialogProps,
  Stack,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Box,
} from "@mui/material";
import { styled } from "@theme/v2/Provider";
import GiveButton from "@shared/Button/GiveButton";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { X } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { IGiveIconButton } from "@shared/IconButton/GiveIconButton.type";

export type GiveBaseModalProps = DialogProps & {
  title?: string;
  width?: string;
  height?: string;
  headerLeftContent?: ReactNode;
  headerRightContent?: ReactNode;
  footerLeftContent?: ReactNode | string;
  buttons?: ReactNode;
  actionLabel?: string;
  onClose: () => void;
  closeIconProps?: Omit<IGiveIconButton, "Icon">;
  customHeader?: ReactNode;
  showFooter?: boolean;
  customFooter?: ReactNode;
  customDialogStyle?: SxProps;
};

const GiveBaseModal: React.FC<GiveBaseModalProps> = ({
  children,
  width = "640px",
  open,
  onClose,
  title = "",
  headerLeftContent,
  headerRightContent,
  footerLeftContent,
  buttons,
  actionLabel,
  closeIconProps,
  showFooter = true,
  customHeader,
  customFooter,
  customDialogStyle,
  ...rest
}) => {
  const defaultAction = (
    <GiveButton label={actionLabel || "Okay"} size="large" variant="filled" />
  );
  const actionButtons = buttons || defaultAction;
  const { isMobileView } = useCustomThemeV2();

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      isMobile={isMobileView}
      width={width}
      PaperProps={{
        style: { transformOrigin: "top" },
        ...(rest?.PaperProps || {}),
      }}
      sx={customDialogStyle}
      {...rest}
    >
      {customHeader ? (
        customHeader
      ) : (
        <DialogHeader
          title={title}
          headerLeftContent={headerLeftContent}
          headerRightContent={headerRightContent}
          onClose={onClose}
          closeIconProps={closeIconProps}
        />
      )}

      <DialogContent>{children}</DialogContent>
      {showFooter && (
        <DialogFooter
          footerLeftContent={footerLeftContent}
          buttons={actionButtons}
          customFooter={customFooter}
        />
      )}
    </StyledDialog>
  );
};

const DialogHeader: React.FC<{
  title: string;
  headerLeftContent?: ReactNode;
  headerRightContent?: ReactNode;
  onClose: () => void;
  closeIconProps?: Omit<IGiveIconButton, "Icon">;
}> = ({
  title,
  headerLeftContent,
  headerRightContent,
  onClose,
  closeIconProps,
}) => {
  return (
    <DialogTitle
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack gap={"12px"} alignItems="center" direction="row">
        {headerLeftContent && headerLeftContent}
        <GiveText>{title}</GiveText>
      </Stack>
      <Stack gap={"12px"} alignItems="center" direction="row">
        {headerRightContent && headerRightContent}
        <GiveIconButton
          aria-label="close"
          onClick={onClose}
          Icon={X}
          variant="ghost"
          {...closeIconProps}
        />
      </Stack>
    </DialogTitle>
  );
};

const DialogFooter: React.FC<{
  footerLeftContent?: ReactNode | string;
  buttons: ReactNode;
  customFooter?: ReactNode;
}> = ({ footerLeftContent, buttons, customFooter }) => (
  <DialogActions sx={{ justifyContent: customFooter ? "center" : "flex-end" }}>
    {customFooter ? (
      customFooter
    ) : (
      <Stack
        direction="row"
        gap="12px"
        justifyContent="space-between"
        width="100%"
      >
        <Stack gap={"12px"} alignItems="center" direction="row">
          {footerLeftContent && footerLeftContent}
        </Stack>
        <Stack gap={"12px"} alignItems="center" direction="row">
          {buttons}
        </Stack>
      </Stack>
    )}
  </DialogActions>
);

const StyledDialog = styled(Dialog)<{
  isMobile: boolean;
  width: string;
  height?: string;
}>(({ theme, isMobile, width, height }) => ({
  overscrollBehavior: "contain",
  ...(isMobile
    ? {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
          margin: 0,
          "& .MuiDialog-paper": {
            margin: "48px 0 0",
            borderRadius: "16px 16px 0 0",
          },
        },
      }
    : {
        "& .MuiDialog-container": {
          alignItems: "center",
          margin: 0,
        },
      }),
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width,
    maxWidth: width,
    background: theme.palette.surface?.primary,
    maxHeight: isMobile ? "calc(100vh - 48px)" : "calc(100vh - 96px)",
    boxShadow: "none",
    top: 0,
    "& .MuiDialogTitle-root": {
      position: "absolute",
      zIndex: 1,
      padding: "16px 20px",
      color: theme.palette.text.primary,
      height: "64px",
      borderBottom: `1px solid ${theme.palette.border?.primary}`,
      background: theme.palette.surface?.["primary-transparent"],
      backdropFilter: "blur(8px)",
      width: "100%",
      top: 0,
    },
    "& .MuiDialogContent-root": {
      padding: "84px 20px 94px 20px",
      scrollbarWidth: "auto !important",
      scrollbarColor: "auto !important",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
      "&:hover::-webkit-scrollbar-track": {
        backgroundColor: theme.palette.surface?.secondary,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
      },
      "&:hover::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.text.secondary,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a8a8a8",
      },
    },
    "& .MuiDialogActions-root": {
      padding: "11.5px 20px",
      height: "74px",
      borderTop: `1px solid ${theme.palette.border?.primary}`,
      background: theme.palette.surface?.["primary-transparent"],
      position: "absolute",
      zIndex: 1,
      backdropFilter: "blur(8px)",
      width: "100%",
      bottom: 0,
    },
    ...(isMobile &&
      height && {
        height,
      }),
  },
}));

export default GiveBaseModal;
