import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack } from "@mui/material";
import { Node } from "@pages/NewAdvancedBuilder/components/Builder";
import { structuredTreeSignal } from "@pages/NewAdvancedBuilder/signals";
import { palette } from "@palette";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { Item_Layout } from "@sections/PayBuilder/provider/provider.type";
import { ProductItemType } from "@sections/PayBuilder/types";
import GiveEmptyStateWrapper from "@shared/EmptyState/GiveEmptyStateWrapper";
import GiveLink from "@shared/Link/GiveLink";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { isEmpty as _isEmpty } from "lodash";
import {
  PRIVACY_POLICY_MODAL,
  PUBLIC_PRODUCT_ITEM_MODAL,
  TERMS_OF_SERVICE_REBRAND_MODAL,
} from "modals/modal_names";
import React, { useEffect } from "react";
import { CartCheckoutFooter } from "./CartCheckoutFooter";
import PageContainer from "./PageContainer";
import ProductList from "./ProductList";
import { TFormStatus, TFormStatusEnum } from "./types";
import { DarkWithCustomTheme } from "@sections/PayBuilder/FormPreview";
import EventMap from "@sections/PayBuilder/views/events/components/EventMap";
import EventDateLocation from "@sections/PayBuilder/views/events/components/EventDateLocation";
import { getDomainName } from "@sections/PayBuilder/utils";
import HeroSelector from "./Hero/HeroSelector";
import useCheckFormType from "../hooks/useCheckFormType";

interface ProductPageContainerProps {
  displayStatus: TFormStatus;
  children?: React.ReactNode;
  items?: ProductItemType[];
  itemLayout?: Item_Layout;
  header?: any;
  isPeekMode?: boolean;
  savedLogo?: string;
  accentColor?: string;
  isPreview?: boolean;
}

const ProductPageContainer: React.FC<ProductPageContainerProps> = ({
  displayStatus,
  items,
  itemLayout,
  header,
  isPeekMode,
  isPreview,
  savedLogo,
}) => {
  const { isCartEmpty, addToCart } = useCart();

  const {
    screenStates: { isMobileView },
  } = usePayBuilderContext();
  const {
    parsedValues: { logo, accentColor },
    isFetched,
    methods,
  } = usePayBuilderForm();

  const { DateLocation } = methods.watch();

  const paymentFormDetails = {
    startsAt: DateLocation.startsAt,
    endsAt: DateLocation.endsAt,
    includeTime: DateLocation.includeTime,
    startsAtTime: DateLocation.startsAtTime,
    endsAtTime: DateLocation.endsAtTime,
    locationURL: DateLocation.locationURL ?? undefined,
    locationShortAddress: DateLocation.locationShortAddress,
    isManualAddress: DateLocation.isManualAddress,
    locationPosition: DateLocation.locationPosition,
    locationAddress: DateLocation.locationAddress,
  };

  const { isEvent, isFundraiser } = useCheckFormType();

  useEffect(() => {
    if (displayStatus == "public" && isFetched && items && items?.length > 0) {
      const searchParams = new URLSearchParams(location.search);
      const idParam = Number(searchParams.get("sharedProductId"));
      if (idParam) {
        const product = items?.find((item) => item.variantID === idParam);
        if (product)
          NiceModal.show(PUBLIC_PRODUCT_ITEM_MODAL, {
            product,
            addToCart,
            accentColor,
          });
      }
    }
  }, [location.search, displayStatus, items]);
  const currentYear = new Date().getFullYear();

  const isEmpty =
    items?.filter((x) => x.display).length === 0 &&
    displayStatus === TFormStatusEnum.PUBLIC;

  const openPrivacyModal = () => {
    NiceModal.show(PRIVACY_POLICY_MODAL);
  };

  const openTermsModal = () => {
    NiceModal.show(TERMS_OF_SERVICE_REBRAND_MODAL);
  };

  const logoUrl = displayStatus === TFormStatusEnum.PUBLIC ? savedLogo : logo;

  const getADBPublishedContent = () => {
    if (isFundraiser) {
      return (
        <HeroSelector
          isPreview={isPreview}
          {...header}
          ADBContent={<Node node={structuredTreeSignal.value} isPublished />}
        />
      );
    }

    return (
      <>
        <Node
          node={structuredTreeSignal.value}
          isPublished={displayStatus === TFormStatusEnum.PUBLIC}
        />
        {!isMobileView && (
          <Box
            sx={{
              width: "1px",
              height: "341px",
              backgroundColor: palette.neutral[30],
              marginLeft: "16px",
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <PageContainer displayStatus={displayStatus}>
        <DarkWithCustomTheme>
          {logoUrl && (
            <StyledImageBox>
              {/* <StyledImage src={logo} /> */}

              <Box
                sx={{
                  background: `url(${logoUrl + "/original"})`,
                  backgroundSize: "contain",
                  backgroundPosition: "left center",
                  backgroundRepeat: "no-repeat",
                  height: "90px",
                  width: "212px",
                }}
              />
            </StyledImageBox>
          )}
          {displayStatus === TFormStatusEnum.PUBLIC ? (
            !_isEmpty(structuredTreeSignal.value) ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  paddingX: "16px",
                  zIndex: 2,
                }}
              >
                {getADBPublishedContent()}
              </Box>
            ) : (
              <HeroSelector isPreview={isPreview} {...header} />
            )
          ) : (
            <HeroSelector isPreview={isPreview} {...header} />
          )}
          {isEvent && (
            <EventDateLocation
              startDate={paymentFormDetails?.startsAt}
              endDate={paymentFormDetails?.endsAt}
              includeTime={paymentFormDetails?.includeTime}
              timers={{
                startsAtTime: paymentFormDetails?.startsAtTime,
                endsAtTime: paymentFormDetails?.endsAtTime,
              }}
              locationURL={getDomainName(paymentFormDetails?.locationURL)}
              locations={{
                locationAddress: paymentFormDetails?.locationAddress,
                locationShortAddress: paymentFormDetails?.locationShortAddress,
              }}
              tabsDetails={{
                isManualAddress: paymentFormDetails.isManualAddress,
                locationPosition: paymentFormDetails.locationPosition,
              }}
              accentColor={accentColor || ""}
            />
          )}
          <StyledContainer>
            <GiveEmptyStateWrapper
              isEmpty={isEmpty && !isFundraiser}
              section="products"
            >
              <ProductList
                displayStatus={displayStatus}
                items={items || ([] as ProductItemType[])}
                itemLayout={itemLayout || "card"}
                isMobileView={isMobileView}
                accentColor={accentColor || ""}
              />
            </GiveEmptyStateWrapper>
          </StyledContainer>
          {isEvent &&
            paymentFormDetails?.locationShortAddress &&
            paymentFormDetails.locationPosition === "onsite" &&
            !paymentFormDetails.isManualAddress && (
              <EventMap
                locations={{
                  locationShortAddress:
                    paymentFormDetails?.locationShortAddress,
                }}
              />
            )}
          {displayStatus === TFormStatusEnum.PUBLIC && (
            <FooterWrapper
              sx={{
                flexDirection: isMobileView ? "column-reverse" : "row",
              }}
            >
              <GiveText variant="bodyS">
                All rights reserved ©Give Corporation {currentYear}
              </GiveText>
              <Stack direction="row" gap="24px">
                <GiveLink onClick={openPrivacyModal}>Privacy Policy</GiveLink>
                <GiveLink onClick={openTermsModal}>Terms & Conditions</GiveLink>
              </Stack>
            </FooterWrapper>
          )}
        </DarkWithCustomTheme>
      </PageContainer>
      {displayStatus === TFormStatusEnum.PUBLIC &&
        !isPeekMode &&
        !isCartEmpty &&
        !isFundraiser && (
          <StyledFooter direction="row">
            <CartCheckoutFooter />
          </StyledFooter>
        )}
    </>
  );
};

export default ProductPageContainer;

const FooterWrapper = styled(Stack)(({ theme }) => ({
  padding: "24px 0",
  borderTop: "1px solid #CCCCC9",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "48px",
  gap: "24px",

  [theme.breakpoints.down("v2_sm")]: {
    flexDirection: "column-reverse",
  },
}));

const StyledContainer = styled(Stack)({
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  "& .empty_text p": {
    maxWidth: "340px",
    textAlign: "center",
  },
  paddingTop: "10px",
});

const StyledFooter = styled(Stack)({
  position: "sticky",
  bottom: 0,
  left: 0,
  right: 0,
  height: "66px",
  padding: "16px",
  borderTop: "1px solid #E5E5E3",
  backdropFilter: "blur(15px)",
  background: "#FAF8F5B2",
  display: "flex",
  justifyContent: "center",
  zIndex: 5,
});

const StyledImageBox = styled(Box)({
  padding: "32px 0",
});
