import React, { useMemo, useState } from "react";
// utils
import { capitalize, sortBy } from "lodash";
import { toast } from "react-toastify";
// mui
import { Avatar } from "@mui/material";
// components
// TODO: the toast to be replaced with a rebranded one
import { ToastMessage } from "@common/Toast/ToastMessage";
import { TParsedAccount } from "@components/Login/useAccountSelectionModal";
// assets
// TODO: the toast icon to be replaced by a phosphor icon
import { SwitchAccountToastIcon } from "@assets/icons/SwitchAccountToastIcon";
// redux
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { ContextualMenuOptionProps } from "@shared/ContextualMenu/ContextualMenu.types";
import { Check } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";

interface Props {
  accountList: TParsedAccount[];

  chooseAccount(index: number): void;

  drawerOpen?: boolean;
  anchorEl: HTMLElement | null;
  onCloseMenu: () => void;
}

const SwitchAccountsMenu = ({
  accountList,
  chooseAccount,
  anchorEl,
  onCloseMenu,
}: Props) => {
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const [searchAccount, setSearchAccount] = useState("");
  const { palette } = useAppTheme();

  const handleChange = (val: string) => setSearchAccount(val);

  const onChangeAccount = React.useCallback(
    (index: number, accountName: string) => {
      const accName = accountName?.replace(/^./, accountName[0].toUpperCase());
      chooseAccount(index);
      toast.info(
        <ToastMessage
          message={`Switched to ${accName}`}
          type="Info"
          description={""}
          icon={
            <SwitchAccountToastIcon fill={palette.primitive?.neutral?.[0]} />
          }
        />,
        { autoClose: 2000 },
      );
    },
    [chooseAccount],
  );

  const accountMenuOptions = useMemo(() => {
    const sortedAccountList = sortBy(accountList, [
      (account) => account.id === selectedAccount?.id,
    ]);

    const searchedAccounts = sortedAccountList?.filter((account) => {
      if (!searchAccount) return true;

      return account.name.toLowerCase().includes(searchAccount.toLowerCase());
    });

    return searchedAccounts?.map((account) => {
      const selected = account.id === selectedAccount?.id;

      return {
        text: account?.name,
        description: capitalize(account?.merchType),
        Image: (
          <Avatar
            src={addSizeToImage(account.img, "thumb")}
            sx={{ height: 32, width: 32, borderRadius: "4px" }}
          />
        ),
        onClick: () => {
          const switchIndex = accountList.findIndex((a) => a.id === account.id);
          onChangeAccount(switchIndex, account.merchType);
        },
        ...(selected && {
          IconRight: Check,
          onClick: () => null,
        }),
      };
    }) as ContextualMenuOptionProps[];
  }, [accountList, searchAccount, selectedAccount]);

  return (
    <ContextualMenu
      anchorEl={anchorEl}
      options={accountMenuOptions}
      handleClose={onCloseMenu}
      color="secondary"
      texture="blurred"
      searchBarProps={
        accountList?.length > 5
          ? {
              value: searchAccount,
              handleChange: handleChange,
            }
          : undefined
      }
      menuWidth={270}
    />
  );
};

export default SwitchAccountsMenu;
