import GiveProgressBar from "@shared/ProgressBar/GiveProgressBar";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import React, { useMemo } from "react";
import CircularScore from "./CircularScore";

type Props = {
  score: number | undefined;
  pendingScore: number;
  merchant_underwriting?: boolean;
  isViewUnderwritingAllowed?: boolean;
  handleClick?: () => void;
  statusDisplayName?: string;
  canProcessMoney?: boolean;
  canTransferMoney?: boolean;
  pendingChallenges?: number;
  hideIcons?: boolean;
  alwaysDisplayProgressBars?: boolean;
};

const GiveUnderwritingScore = React.memo(
  ({
    score = 0,
    pendingScore = 0,
    merchant_underwriting,
    isViewUnderwritingAllowed,
    handleClick,
  }: Props) => {
    const { isDesktopView } = useCustomThemeV2();

    const barVariant = useMemo(() => {
      return !score || score < 80 ? "incomplete" : "completed";
    }, [score]);

    const isUnderwritingDisabled =
      !merchant_underwriting || !isViewUnderwritingAllowed;

    return (
      <>
        {!isDesktopView ? (
          <CircularScore
            disabled={isUnderwritingDisabled}
            handleClick={handleClick}
            score={score}
            pendingScore={pendingScore}
          />
        ) : (
          <GiveProgressBar
            value={(score ?? 0) + pendingScore}
            innerValue={score}
            variant={isUnderwritingDisabled ? "disabled" : barVariant}
            type="default"
          />
        )}
      </>
    );
  },
);
GiveUnderwritingScore.displayName = "GiveUnderwritingScore";

export default GiveUnderwritingScore;
