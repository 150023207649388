import { SkeletonOwnProps } from "@mui/material";

export const MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS = {
  GET: "get-merchant-preview",
  GET_RISK_CATEGORY: "get-merchant-risk-category",
  GET_BANK_FILES: "get-bank-files",
};

export const UNDERWRITING_API_KEY_BASE = "underwriting_items";

export const UNDERWRITING_CHALLENGE_API_KEYS = {
  GET_STATS: [UNDERWRITING_API_KEY_BASE, "challenges_stats"],
  GET_CATEGORIES: [UNDERWRITING_API_KEY_BASE, "challenges_categories"],
  GET_CHALLENGES: [UNDERWRITING_API_KEY_BASE, "all_challenges"],
  GET_ALL: UNDERWRITING_API_KEY_BASE,
};

export const BANK_DETAILS = [
  {
    name: "RS2 Software INC",
    addr1: "4643 S Ulster St Ste 1285 Denver,",
    addr2: "CO, 80237-3090 United States",
  },
  {
    name: "Chesapeake Bank",
    addr1: "97 N. Main Street",
    addr2: "Kilmarnock, VA 22482",
  },
];

export const LastUpdatedOn_merchant_agreement = "June 26,2023"; // TODO: this value will be dynamic in future
export const skeletonDefaultProps: SkeletonOwnProps = {
  sx: { borderRadius: "16px" },
  variant: "rectangular",
};

export const BPVAA_DISABLED_TEXT =
  "Approval of the Business Owner Verification and Business Address is required before approving this challenge";
