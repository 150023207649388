import React from "react";

type TProps = { fill?: string; width?: number; height?: number };

const AcceptPaymentsOn = ({
  fill = "#088750",
  width = 18,
  height = 18,
}: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 3.375H15.75C16.0484 3.375 16.3345 3.49353 16.5455 3.7045C16.7565 3.91548 16.875 4.20163 16.875 4.5V7.3125L15.75 7.3125L5.625 7.3125L2.25 7.3125V13.5H5.06069L5.0625 13.5H6.1875C6.49816 13.5 6.75 13.7518 6.75 14.0625C6.75 14.3732 6.49816 14.625 6.1875 14.625L5.625 14.625H2.25C1.95163 14.625 1.66548 14.5065 1.4545 14.2955C1.24353 14.0845 1.125 13.7984 1.125 13.5V4.5C1.125 4.20163 1.24353 3.91548 1.4545 3.7045C1.66548 3.49353 1.95163 3.375 2.25 3.375ZM15.75 6.1875V4.5H2.25V6.1875H15.75ZM16.1764 10.4919C16.379 10.2564 16.3524 9.90123 16.1169 9.69861C15.8814 9.496 15.5262 9.52265 15.3236 9.75814L11.8849 13.7549L10.5569 12.1632C10.3579 11.9247 10.0032 11.8927 9.76466 12.0917C9.52612 12.2907 9.49408 12.6454 9.6931 12.884L11.4463 14.9854C11.5523 15.1123 11.7087 15.1862 11.874 15.1875C12.0393 15.1888 12.1968 15.1172 12.3047 14.9919L16.1764 10.4919Z"
        fill={fill}
      />
    </svg>
  );
};

export default AcceptPaymentsOn;
