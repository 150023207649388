import { ButtonProps } from "@common/Button/Button";
import { DialogActions } from "@mui/material";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import GiveButton from "@shared/Button/GiveButton";
import { styled } from "@theme/v2/Provider";
import { format } from "date-fns";

type TCustomActionsProps = PickersActionBarProps & {
  setValue: (value: any) => void;
  value: any;
  submitButtonProps?: ButtonProps;
};

const CustomActions = (props: TCustomActionsProps) => {
  const { onAccept, onCancel, className, setValue, value, submitButtonProps } =
    props;

  const handleOnAccept = () => {
    const formattedDate = format(new Date(value), "MM/dd/yyyy");
    setValue(formattedDate);
    onAccept();
  };

  return (
    <StyledActions className={className}>
      <GiveButton
        variant="ghost"
        size="large"
        label="Cancel"
        onClick={onCancel}
      />
      <GiveButton
        variant="filled"
        size="small"
        label="Apply"
        color="primary"
        onClick={handleOnAccept}
        {...submitButtonProps}
      />
    </StyledActions>
  );
};

const StyledActions = styled(DialogActions)(({ theme }) => ({
  [theme.breakpoints.down("new_sm")]: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "flex-end !important",
    "& .MuiButton-root": {
      width: "fit-content !important",
    },
  },
}));

export default CustomActions;
