const standardPreviewStep = [
  "ProductPreviewView",
  "ProductPreviewView",
  "ProductPreviewView",
  "CheckoutFormPreview",
  "ProductPreviewView",
];

const eventPreviewStep = [
  "ProductPreviewView",
  "ProductPreviewView",
  "ProductPreviewView",
  "ProductPreviewView",
  "CheckoutFormPreview",
  "ProductPreviewView",
];

const fundraiserPreviewStep = [
  "ProductPreviewView",
  "ProductPreviewView",
  "CheckoutFormPreview",
  "ProductPreviewView",
];
//this shoudl be propagted down to context through DI otherwise we end in circular deps
export const views = {
  standard: standardPreviewStep,
  event: eventPreviewStep,
  fundraiser: fundraiserPreviewStep,
} as const;
