import { Box, CircularProgress, Skeleton, Stack } from "@mui/material";
import { IInputProps, ILayoutProps } from "react-dropzone-uploader";

import { MouseEvent, useRef } from "react";
import { useGetOperatingSystem } from "@hooks/common/useGetOperatingSystem";
import { IDUploaderMapper } from "./IDUploaderMapper";
import { TOwnerFile } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/types";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
import { Image } from "@components/common/StyledImage/Image";
import { Icon } from "@phosphor-icons/react";
import { UploaderState } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderMachine";

type Props = IInputProps & {
  isUploadable: boolean;
  state: UploaderState;
  fileUrl: string;
  type: TOwnerFile;
  fileName?: string;
  isMobile?: boolean;
  disabled?: boolean;
  Icon?: Icon;
  previewDocument: ({
    tmpUrl,
    type,
    fileName,
  }: {
    tmpUrl?: string | undefined;
    type?: TOwnerFile | undefined;
    fileName?: string;
  }) => void;
};

export const IDUploaderInput = ({
  accept,
  fileUrl,
  isMobile,
  getFilesFromEvent,
  state,
  type,
  fileName,
  onFiles,
  previewDocument,
  isUploadable,
  disabled = false,
}: Props) => {
  // There was a discrepancy between the Android and iOS.
  const { operatingSystemTracker } = useGetOperatingSystem();
  // iOS required a double click to open the preview modal, passing at first click for the hover state
  // Android opened without passing through the hover state
  // This makes both passing through the hover state at first click
  const firstClickTracker = useRef<boolean>(false);

  const clickHandler = (e: MouseEvent<HTMLDivElement>) => {
    const showAfterFirstClick = isMobile && firstClickTracker.current === false;
    const defaultBehavior =
      !isMobile || operatingSystemTracker.current === "iOS";
    if (
      (defaultBehavior || showAfterFirstClick) &&
      state === UploaderState.ON_UPLOADED
    ) {
      return previewDocument({ tmpUrl: fileUrl, type, fileName });
    }
    firstClickTracker.current = false;
  };

  const theme = useAppTheme();

  return (
    <Stack
      height="100%"
      width="100%"
      alignItems="center"
      padding="24px"
      justifyContent="center"
      onMouseEnter={() => {
        firstClickTracker.current = true;
      }}
      onMouseLeave={() => {
        if (firstClickTracker.current) firstClickTracker.current = false;
      }}
      onClick={state === UploaderState.ON_UPLOADED ? clickHandler : undefined}
      sx={{
        height: "100%",
        borderRadius: "12px",
        pointerEvents: "initial",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.primitive?.transparent["darken-5"],
        },
      }}
    >
      <Stack
        height="100%"
        width="100%"
        component="label"
        alignItems="center"
        justifyContent="center"
        sx={{ cursor: "pointer" }}
      >
        <StyledContainer>
          <Stack spacing={0.5} justifyContent="center" alignItems="center">
            {state !== UploaderState.ON_UPLOADED && (
              <>
                <Box
                  sx={{
                    backgroundColor:
                      theme.palette.primitive?.transparent["darken-5"],
                    borderRadius: "50%",
                    display: "flex",
                    padding: "8px",
                  }}
                >
                  {state === UploaderState.ON_UPLOADING ? (
                    <CircularProgress size="20px" sx={{ color: "#292928" }} />
                  ) : (
                    IDUploaderMapper[type].icon("#8f8f8f")
                  )}
                </Box>
                <GiveText paddingY="8px" variant="bodyS" color="secondary">
                  {IDUploaderMapper[type].title}
                </GiveText>
              </>
            )}
            <GiveText variant="bodyXS" color="secondary">
              Drag and drop your files or{" "}
              <StyledLink>Click to browse</StyledLink>
            </GiveText>
          </Stack>
        </StyledContainer>
        {![UploaderState.ON_UPLOADED, UploaderState.ON_UPLOADING].includes(
          state,
        ) && (
          <input
            disabled={Boolean(fileUrl) || !isUploadable || disabled}
            type="file"
            id="upload"
            accept={accept}
            style={{ display: "none" }}
            onChange={async (e) => {
              const chosenFiles = await getFilesFromEvent(e);
              onFiles(chosenFiles);
              (e.target as any).value = null;
            }}
            data-testid="image-upload-input"
          />
        )}
      </Stack>
    </Stack>
  );
};

const StyledContainer = styled(Box)({
  textAlign: "center",
});

export const IDUploaderLayout = ({
  fileUrl,
  actions,
  type,
}: ILayoutProps & {
  fileUrl: string;
  actions?: React.ReactNode;
  type: TOwnerFile;
}) => {
  return (
    <StyledLayout>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack gap={2}>
          <GiveText variant="bodyS" color="secondary">
            {IDUploaderMapper[type].title}
          </GiveText>
          <Stack direction="row">{actions}</Stack>
        </Stack>
        <Box>
          {fileUrl ? (
            <StyledImg height={"108px"} src={fileUrl} alt={"id image"} />
          ) : (
            <Skeleton width="108px" height="108px" variant="rounded" />
          )}
        </Box>
      </Stack>
    </StyledLayout>
  );
};

const StyledLayout = styled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primitive?.transparent["darken-5"],
    display: "block",
    borderRadius: "8px",
    padding: "24px",
  };
});

const StyledImg = styled(Image)(() => {
  return {
    maxWidth: "156px",
    width: "100%",
  };
});

const StyledLink = styled("span")(({ theme }) => {
  return {
    color: theme.palette.primitive?.blue[100],
  };
});
