import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Grid, Stack } from "@mui/material";
import { QFORM_QUERY_KEY } from "@pages/AcquirerPortal/Enterprises/Modal/constants";
import { Check, X } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { CampaignTypeParam } from "features/Minibuilders/PaymentFormMinibuilder/useCreateCampaignFn";
import { isFunction } from "lodash";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryObserver } from "../LaunchStep/hooks/useQueryObserver";
import { useCart } from "../provider/CartContext";
import { formTypeToUrlMap } from "../utils";
import { FormType } from "./hooks/useCheckFormType";

interface Props {
  modalType: "saveDraft" | "editDraft";
  onAccept?: (cb?: () => void) => void;
  onCancel?: (cb?: () => void) => void;
  isFormValid?: boolean;
  id?: string;
  formType?: CampaignTypeParam;
}

export const SaveDraftModal = NiceModal.create(
  ({
    modalType = "saveDraft",
    onAccept,
    onCancel,
    id,
    isFormValid = true,
    formType,
  }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { onClose, TransitionProps, SlideProps, open, modal } =
      useNiceModal();
    const { remove } = useQueryObserver(QFORM_QUERY_KEY);
    const navigate = useNavigate();
    const location = useLocation();
    const { clearCart } = useCart();
    const handleCancel = () => {
      setIsLoading(false);
      modal.hide();
    };
    const {
      title,
      subTitle,
      cancelButtonLabel,
      confirmButtonLabel,
      redirectTo,
    } = getModalContent(modalType, formType);

    const handleCloseAndRedirect = () => {
      if (isLoading) return;
      const isEditDraft = modalType == "editDraft";
      remove();
      handleCancel();
      // navigation is already handled if we are saving draft
      isEditDraft &&
        navigate(
          { pathname: redirectTo, search: `type=${formType}` },
          id
            ? { state: { id: id, prevUrl: location.pathname, formType } }
            : undefined,
        );
    };
    const onConfirm = () => {
      if (isLoading) return;
      clearCart();
      setIsLoading(true);
      isFunction(onAccept)
        ? onAccept(handleCloseAndRedirect)
        : handleCloseAndRedirect();
    };

    const handleCancelAction = () => {
      clearCart();
      remove();
      isFunction(onCancel) ? onCancel(handleCancel) : handleCancel();
    };

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose,
          DrawerProps: {
            SlideProps,
            PaperProps: {
              sx: {
                "&.MuiPaper-root": {
                  padding: "20px",
                  gap: "16px",
                },
              },
            },
          },
          DialogProps: {
            TransitionProps,
            width: "440px",
            contentContainerSx: {
              padding: "20px",
              gap: "16px",
            },
            PaperProps: {
              "& .MuiPaper-root": {},
            },
            scroll: "paper",
          },
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <div
            style={{
              background:
                "linear-gradient(223.56deg, #E8F9FC 0%, #E9E8F7 100%)",
              display: "flex",
              padding: "12px",
              width: "48px",
              height: "48px",
              borderRadius: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Color not present in theme.Waiting for UX team answer */}
            <Check color="#2860B2" />
          </div>
          <GiveIconButton
            Icon={X}
            size="small"
            variant="ghost"
            sx={{
              display: "flex",
              gap: 1,
            }}
            onClick={() => handleCancel()}
          />
        </Grid>
        <Stack gap={2}>
          <GiveText fontSize="24px" fontWeight={350}>
            {title}
          </GiveText>
          <GiveText
            color="secondary"
            fontSize="14px"
            fontWeight={400}
            lineHeight="20px"
          >
            {subTitle}
          </GiveText>
        </Stack>
        <Grid container justifyContent="flex-end" alignItems="center">
          <ReButton
            size="large"
            variant="ghost"
            disabled={isLoading}
            label={cancelButtonLabel}
            onClick={handleCancelAction}
          />
          <GiveButton
            size="large"
            label={confirmButtonLabel}
            disabled={!isFormValid || isLoading}
            onClick={onConfirm}
          />
        </Grid>
      </ModalFactory>
    );
  },
);

const ReButton = styled(GiveButton)(({ theme }) => ({
  color: theme.palette.primitive?.error[100],
}));

const getModalContent = (
  modalType: "saveDraft" | "editDraft",
  formType?: string,
) => {
  const modalContent = {
    saveDraft: {
      title: "Save as draft?",
      subTitle: `Do you want to save this payment form as a draft? If you don’t save
            it, any information added will be lost.`,
      cancelButtonLabel: "Don’t Save",
      confirmButtonLabel: "Yes, Save",
      redirectTo: `/merchant/${formTypeToUrlMap[formType as FormType]}`,
    },

    editDraft: {
      title: "Edit Draft",
      subTitle:
        "This form is an unpublished draft. Do you want to continue editing the form or delete it?",
      cancelButtonLabel: "Delete",
      confirmButtonLabel: "Yes, Edit Draft",
      redirectTo: "/pay_product_builder",
    },
  };

  return modalContent[modalType];
};
