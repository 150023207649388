import { GiveInput, InputProps } from "./GiveInput";
import { MenuItem } from "@mui/material";
import { CaretDown, Check } from "@phosphor-icons/react";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { ContextualMenuProps } from "@shared/ContextualMenu/ContextualMenu.types";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { SyntheticEvent, useState, useRef } from "react";

const ICON_OFFSET = 33;

type GiveSelectOption = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

export type GiveSelectProps = InputProps & {
  options: GiveSelectOption[];
  value?: string | number;
  useMobileDrawer?: boolean;
  useContextualMenu?: boolean;
  contextualMenuProps?: Partial<ContextualMenuProps>;
  updateOpen?: (open: boolean) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: (e: { target: { value: number | string } }) => void;
  inputValueColor?: string;
  withIconOffset?: boolean;
};

export default function GiveSelect({
  options,
  onScroll,
  value,
  useMobileDrawer = true,
  useContextualMenu,
  contextualMenuProps,
  updateOpen,
  onOpen,
  onClose,
  onChange,
  inputValueColor,
  withIconOffset = true,
  ...rest
}: GiveSelectProps) {
  const theme = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const [menuWidth, setMenuWidth] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputRef = useRef<HTMLElement | null>(null);

  const handleOpenMenu = (e?: SyntheticEvent<Element, Event>) => {
    if (rest?.disabled) return;
    if (updateOpen) updateOpen(true);
    if (onOpen) onOpen();

    // Use inputRef.current as the anchor element directly
    if (inputRef.current) {
      setAnchorEl(inputRef.current);
      if (!menuWidth) {
        setMenuWidth(
          inputRef.current.offsetWidth + (withIconOffset ? ICON_OFFSET : 0),
        );
      }
    } else if (e?.currentTarget) {
      setAnchorEl(e?.currentTarget as HTMLElement);
    }
  };

  const closeMenu = () => {
    setAnchorEl(null);
    if (updateOpen) {
      updateOpen(false);
    }
    if (onClose) onClose();
  };

  return (
    <>
      <GiveInput
        inputRef={inputRef}
        select
        value={value}
        onChange={onChange}
        SelectProps={{
          open:
            (isMobileView && useMobileDrawer) || useContextualMenu
              ? false
              : !!anchorEl,
          onClose: closeMenu,
          onOpen: handleOpenMenu,
          IconComponent: () => (
            <CaretDown
              size={20}
              style={{ marginRight: "16px", cursor: "pointer" }}
              onClick={handleOpenMenu}
            />
          ),
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: "360px",
                boxShadow: "none",
                border: `0.5px solid ${theme.palette.border?.primary}`,
                borderRadius: `${theme.customs?.radius.mediumSmall}px`,
                marginTop: "4px",
              },
              style: { padding: "8px" },
              onScroll: onScroll,
            },
          },
          classes: {
            root: anchorEl && useContextualMenu ? "Mui-focused" : "",
          },
        }}
        sx={{
          "& .MuiInputBase-root": {
            padding: 0,
            height: "48px",
          },
          ...(inputValueColor && {
            "& .MuiTypography-root": {
              color: inputValueColor,
            },
          }),
        }}
        {...rest}
      >
        {options?.map((option, index) => {
          return (
            <StyledMenuItem key={index} value={option.value}>
              <GiveText variant="bodyS">{option.label}</GiveText>
            </StyledMenuItem>
          );
        })}
      </GiveInput>
      {((isMobileView && useMobileDrawer) || useContextualMenu) && (
        <ContextualMenu
          anchorEl={anchorEl as any}
          handleClose={closeMenu}
          options={options?.map((option) => {
            return {
              ...option,
              text: option.label,
              IconRight: value === option.value ? Check : undefined,
              iconPlacement: "right",
              disabled: option.disabled,
              onClick: () => {
                onChange?.({ target: { value: option.value } });
                closeMenu();
              },
            };
          })}
          color="primary"
          texture="solid"
          menuWidth={menuWidth}
          horizontalOrigin="left"
          {...contextualMenuProps}
        />
      )}
    </>
  );
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  background: "none !important",
  padding: "8px 8px 8px 12px",
  borderRadius: `${theme?.customs?.radius.extraSmall}px`,
  "&:hover": {
    background: `${theme?.palette?.primitive?.transparent["darken-5"]} !important`,
  },
  "&.Mui-selected": {
    background: theme?.palette?.primitive?.transparent["darken-5"],

    "&:hover": {
      background: theme?.palette?.primitive?.transparent["darken-5"],
    },
  },
}));
