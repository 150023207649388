import { Info } from "@phosphor-icons/react";
import { useAppSelector } from "@redux/hooks";
import { selectCardType } from "@redux/slices/checkout";
import { GiveInput, InputProps } from "@shared/GiveInputs/GiveInput";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import React from "react";
import { useFormContext } from "react-hook-form";

const CVVField = (props: InputProps) => {
  const { setValue, watch } = useFormContext();
  const checkoutCardType = useAppSelector(selectCardType);

  const normalizeInput = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");

    return currentValue;
  };

  const CVVLimit = React.useMemo(() => {
    switch (checkoutCardType) {
      case "AMEX":
        return 4;
      default:
        return 3;
    }
  }, [checkoutCardType]);

  const handleChange = (value: string) => {
    setValue("payment.cvv", normalizeInput(value));
  };

  return (
    <GiveInput
      {...props}
      onChange={(event) => handleChange(event.target.value)}
      rightContent={
        <GiveTooltip
          title="3-digit security code on the back of your card (4 digits for AMEX)"
          placement="top"
          color="default"
        >
          <Info size={16} />
        </GiveTooltip>
      }
      maxLength={CVVLimit}
      InputProps={{
        inputMode: "numeric",
      }}
    />
  );
};

export default CVVField;
