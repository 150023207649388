import PaymentFormsPublicForm from "@components/PaymentForms/PublicPaymentForm/PaymentFormsPublicForm";
import CheckoutPage from "@pages/Checkout/CheckoutPage";
import FFLoadingStateWrapper from "FeatureFlags/FFLoadingStateWrapper";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
import { lazy } from "react";
import { PUBLIC_FORM_PATHS } from "./paths";
import PublicProductWrapper from "@sections/PayBuilder/ProductWrapper";

const EventsPublicForm = lazy(
  () => import("@components/Events/PublicPaymentForm/EventsPublicForm"),
);

const SweepstakesPublicForm = lazy(
  () =>
    import("@components/Sweepstakes/PublicPaymentForm/SweepstakesPublicForm"),
);

const FundraisersPublicForm = lazy(
  () =>
    import("@components/Fundraisers/PublicPaymentForm/FundraisersPublicForm"),
);

const InvoicesPublicForm = lazy(
  () => import("@components/Invoices/PublicPaymentForm/InvoicesPublicForm"),
);

const MembershipsPublicForm = lazy(
  () =>
    import("@components/Memberships/PublicPaymentForm/MembershipsPublicForm"),
);

const ProductPublicForm = lazy(() => import("@containers/ProductPublicForm"));

const publicFormRoutesList = [
  {
    path: PUBLIC_FORM_PATHS.EVENTS,
    element: <EventsPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.SWEEPSTAKES,
    element: <SweepstakesPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.INVOICES,
    element: <InvoicesPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.FUNDRAISERS,
    element: <FundraisersPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.MEMBERSHIPS,
    element: <MembershipsPublicForm />,
  },
  // {
  //   path: PUBLIC_FORM_PATHS.PRODUCT,
  //   element: <ProductPublicForm />,
  // },
  {
    path: PUBLIC_FORM_PATHS.PAYMENT_FORMS,
    element: <PaymentFormsPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.PRODUCT,
    element: (
      <FFLoadingStateWrapper>
        <FlaggedWrapper
          ActiveComponent={<PublicProductWrapper />}
          FallbackComponent={<ProductPublicForm />}
          name={FeatureFlagKeys.PAY_BUILDER}
        />
      </FFLoadingStateWrapper>
    ),
  },
  {
    path: PUBLIC_FORM_PATHS.CHECKOUT,
    element: <CheckoutPage />,
  },
];

export default publicFormRoutesList;
