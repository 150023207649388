import { Box, Stack, BoxProps } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { CaretRight, ChatsCircle, Lock } from "@phosphor-icons/react";
import useGetSimpleCardSectionData, {
  TVariant,
} from "../hooks/useGetSimpleCardSectionData";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import GivePepStatusChip from "../businessOwners/GivePepStatusChip";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { checkPortals } from "@utils/routing";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

const SimpleCardSection = ({
  variant,
  disabled,
}: {
  variant: TVariant;
  disabled: boolean;
}) => {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const sectionData = useGetSimpleCardSectionData(variant);
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isAcquirerPortal } = checkPortals();

  const {
    title,
    description,
    descriptionOnDisable,
    lastCheck,
    onClick,
    onClickConv,
    status,
    permissionDenied,
    isLocked,
    tooltipMessage = ACTION_DENY_MESSAGE,
  } = sectionData;
  const disableSection = permissionDenied || disabled;
  return (
    <Box width="100%">
      <Stack
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        width="100%"
      >
        <GiveText>{title}</GiveText>
        {merchant_underwriting && isAcquirerPortal && (
          <GiveIconButton
            sx={{
              cursor: "pointer",
            }}
            variant="ghost"
            onClick={onClickConv}
            Icon={ChatsCircle}
          />
        )}
      </Stack>
      <GiveTooltip
        width="compact"
        alignment="left"
        title={tooltipMessage}
        color="default"
        disableHoverListener={!permissionDenied && !isLocked}
      >
        <Container
          disabled={disableSection}
          onClick={!disableSection && !isLocked ? onClick : undefined}
        >
          {disableSection ? (
            <Stack gap="10px" direction="row" alignItems="center">
              <Lock color={palette.icon?.["icon-secondary"]} size={20} />
              <GiveText variant="bodyS" color="secondary">
                {descriptionOnDisable}
              </GiveText>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <CustomWrapper isMobileView={isMobileView}>
                <GiveText variant="bodyS" color="secondary">
                  {description}
                </GiveText>
                <GiveText variant="bodyS" color="primary">
                  {lastCheck}
                </GiveText>
              </CustomWrapper>
              <Stack ml="8px" gap="8px" direction="row" alignItems="center">
                {status && (
                  <GivePepStatusChip ownerPepStatus={status} />
                )}
                {isLocked ? (
                  <Lock color={palette.icon?.["icon-secondary"]} size={20} />
                ) : (
                  <CaretRight
                    color={palette.icon?.["icon-secondary"]}
                    size={20}
                  />
                )}
              </Stack>
            </Stack>
          )}
        </Container>
      </GiveTooltip>
    </Box>
  );
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "opened",
})<BoxProps & { disabled: boolean }>(({ disabled, theme }) => ({
  marginTop: "16px",
  borderRadius: `${theme?.customs?.radius?.medium}px`,
  padding: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: disabled
    ? theme.palette.surface?.tertiary
    : theme.palette.background.paper,
  cursor: disabled ? "default" : "pointer",
  ...(!disabled && {
    "&:hover": {
      boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
      "& .circular-button": {
        display: "flex",
      },
    },
  }),
}));

const CustomWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})<{ isMobileView?: boolean }>(({ theme, isMobileView }) => ({
  display: "flex",
  alignItems: isMobileView ? "flex-start" : "center",
  justifyContent: "space-between",
  width: "100%",
  flexDirection: isMobileView ? "column" : "row",
}));

export default SimpleCardSection;
