function ProductSidePanelIcon({ fill = "", size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.88 5.36654L10.63 0.849664C10.4371 0.743133 10.2204 0.687256 10 0.687256C9.77965 0.687256 9.56289 0.743133 9.37 0.849664L1.12 5.36654C0.913567 5.47949 0.741297 5.64586 0.621226 5.84823C0.501154 6.05061 0.437698 6.28154 0.4375 6.51685V15.4831C0.437698 15.7184 0.501154 15.9493 0.621226 16.1517C0.741297 16.3541 0.913567 16.5205 1.12 16.6334L9.37 21.1503C9.56295 21.2567 9.77968 21.3124 10 21.3124C10.2203 21.3124 10.4371 21.2567 10.63 21.1503L18.88 16.6334C19.0864 16.5205 19.2587 16.3541 19.3788 16.1517C19.4988 15.9493 19.5623 15.7184 19.5625 15.4831V6.51685C19.5623 6.28154 19.4988 6.05061 19.3788 5.84823C19.2587 5.64586 19.0864 5.47949 18.88 5.36654ZM9.90625 1.83591C9.93384 1.82082 9.96479 1.8129 9.99625 1.8129C10.0277 1.8129 10.0587 1.82082 10.0863 1.83591L17.9228 6.12498L14.7409 7.86591L6.81719 3.52904L9.90625 1.83591ZM9.4375 19.9062L1.65625 15.6472C1.6275 15.6306 1.60367 15.6066 1.5872 15.5778C1.57073 15.549 1.5622 15.5163 1.5625 15.4831V7.12435L9.4375 11.4369V19.9062ZM2.07719 6.12498L5.64625 4.17029L13.5691 8.50716L10 10.46L2.07719 6.12498ZM18.4375 15.4831C18.4378 15.5163 18.4293 15.549 18.4128 15.5778C18.3963 15.6066 18.3725 15.6306 18.3438 15.6472L10.5625 19.9062V11.435L13.9375 9.58716V13.25C13.9375 13.3992 13.9968 13.5422 14.1023 13.6477C14.2077 13.7532 14.3508 13.8125 14.5 13.8125C14.6492 13.8125 14.7923 13.7532 14.8977 13.6477C15.0032 13.5422 15.0625 13.3992 15.0625 13.25V8.97217L18.4375 7.12435V15.4831Z"
        fill="url(#paint0_linear_6215_9413)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6215_9413"
          x1="19.5625"
          y1="0.687256"
          x2="-0.551991"
          y2="20.2975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ProductSidePanelIcon;
