// /merchants/3/category-codes?q=%22amuse%22

import { useQuery } from "react-query";
import { customInstance } from "@services/api";

interface Props {
    id?: number;
    searchKey?: string;
  }

export default function useGetEnterpriseCategoryCodes({id, searchKey}:Props) { 
    const searchQuery= searchKey? `?q=%22${searchKey}%22` : ""
  return useQuery(
    ["category-codes", id, searchKey],
    async () => {
      return customInstance({
        url: `/merchants/${id}/category-codes${searchQuery}`,
      });
    },
    { enabled: Boolean(id) },
  );
}