import NiceModal from "@ebay/nice-modal-react";
import { PUBLIC_FORM_PATHS } from "@routes/paths";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { NavigateOptions, useNavigate } from "react-router-dom";
interface TBaseState<T> {
  prevUrl: string;
  id: null | string | number;
  campaignName: T;
}

type TCampaignLocationState = {
  // Here you can add typings for location states
  fundraisers: unknown;
  standard: unknown;
  //...
};

type TStateMap = {
  [K in keyof typeof canCampaignUseNewBuilder]: TBaseState<K> &
    (K extends keyof TCampaignLocationState
      ? TCampaignLocationState[K]
      : unknown);
};

const canCampaignUseNewBuilder = {
  // enabled campaigns
  fundraiser: true,
  event: true,
  standard: true,
  // not enabled campaigns
  invoice: false,
  membership: false,
  sweepstake: false,
  // "manage-money": false,
  "payment-forms": false,
  customers: false,
} as const;

// This is because BE sends us the types in singular but in the app we have them in plural in a lot of places
export const campaignMapper = {
  event: "event",
  fundraiser: "fundraiser",
  standard: "standard",
  invoice: "invoice",
  membership: "membership",
  sweepstake: "sweepstake",
} as const;

export type CampaignMapperValues =
  (typeof campaignMapper)[keyof typeof campaignMapper];

export type CampaignTypeParam = keyof typeof canCampaignUseNewBuilder;

export const useCreateCampaignFn = () => {
  const navigate = useNavigate();
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();

  const createCampaignFn = <T extends CampaignTypeParam = CampaignTypeParam>(
    MODAL_ID: string,
    campaignName: T,
    state?: TStateMap[T],
  ): void => {
    if (!isPayBuilderEnabled || !canCampaignUseNewBuilder[campaignName]) {
      NiceModal.show(MODAL_ID);
      return;
    }

    navigate(
      {
        pathname: `/${PUBLIC_FORM_PATHS.PAY_PRODUCT_BUILDER}`,
        search: `type=${campaignName}`,
      },
      {
        state: {
          prevUrl: location.pathname,
          id: null,
          campaignName,
          ...state,
        } as TStateMap[T],
      } as NavigateOptions,
    );
  };

  return {
    createCampaignFn,
    canCampaignUseNewBuilder,
  };
};
