import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { BaseMainContainer } from "./components/BaseMainContainer";
import { LeftPanelActions } from "./components/LeftPanelActions";
import { MobileLeftPanelMainHeader } from "./components/MobileLeftPanelMainHeader";
import { StackRow } from "./components/StackRow";
import Stepper from "./components/Stepper";
import { usePayBuilderContext } from "./provider/PayBuilderContext";
import { scrollbarStyles } from "./utils";

function BuilderForm() {
  const { isMobileView, isTabletView, isSmallDesktop } = useCustomThemeV2();

  const { activeItem, activeStepIndex, formStep } = usePayBuilderContext();
  const isMobileOrTablet = isTabletView || isMobileView;
  const ActiveView = formStep[activeStepIndex];

  const { isMasqueradeMode } = useMasqueradeReducer();

  return (
    <Container
      isMasqueradeMode={isMasqueradeMode}
      xs={isMobileView ? 12 : undefined}
      sm={isTabletView ? 12 : undefined}
      md={isSmallDesktop ? 4 : undefined}
      lg={4}
      item
    >
      {isMobileOrTablet && <MobileLeftPanelMainHeader />}
      <StackRow
        sx={isMobileOrTablet ? { paddingBottom: 0, paddingLeft: "14px" } : {}}
      >
        <Stepper />
      </StackRow>
      {isMobileOrTablet && (
        <StackRow sx={{ paddingTop: 0, paddingLeft: "22px" }}>
          {<GiveText>{activeItem.label}</GiveText>}
        </StackRow>
      )}
      <StackRow sx={scrollbarStyles}>
        <ActiveView />
      </StackRow>
      <StackRow
        container
        alignItems="center"
        gap={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",

          borderTop: "1px solid #E5E5E3",
          padding: "12px 24px",
        }}
      >
        <LeftPanelActions />
      </StackRow>
    </Container>
  );
}

export default BuilderForm;

type ContainerProps = {
  isMasqueradeMode?: boolean;
};

const Container = styled(BaseMainContainer)<ContainerProps>(
  ({ theme, isMasqueradeMode }) => {
    return {
      height: "100dvh",
      backgroundColor: theme.palette.surface?.primary,
      borderRight: `1px solid ${theme.palette.border?.primary} `,
      paddingTop: isMasqueradeMode ? "35px" : undefined,
      maxWidth: "100%",

      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
      },
      // [theme.breakpoints.up("xs")]: {
      //   maxWidth: "560px",
      // },
    };
  },
);
