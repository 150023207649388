import { Box, SxProps, TableCell } from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import React from "react";

export const HeaderState = {
  ASCENDING: "asc",
  DESCENDING: "desc",
  NONE: "none",
} as const;
interface Props {
  state?: (typeof HeaderState)[keyof typeof HeaderState];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  width?: number | string;
  sxProps?: SxProps;
  checkBoxElement?: React.ReactNode;
  sxContentProps?: SxProps;
}
const GiveTableHeader = ({
  children,
  state = "none",
  onClick,
  width,
  sxProps,
  checkBoxElement,
  sxContentProps,
}: Props) => {
  const { palette } = useAppTheme();
  const [hovered, setHovered] = React.useState(false);

  return (
    <TableCell
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      version="two"
      width={width}
      sx={{
        background: palette.surface?.primary,
        ...sxProps,
      }}
    >
      <Container
        onClick={onClick}
        sx={{
          cursor: onClick ? "pointer" : "default",
          ...sxContentProps,
        }}
      >
        {checkBoxElement && checkBoxElement}
        <GiveText variant="bodyS" color={hovered ? "primary" : "secondary"}>
          {children}
        </GiveText>

        {(hovered || state !== HeaderState.NONE) && (
          <CaretDown
            size={16}
            color={palette.icon?.["icon-primary"]}
            style={{
              transform: `rotate(${
                state === HeaderState.ASCENDING ? -180 : 0
              }deg)`,
              transition: "transform 0.1s ease",
              flexShrink: 0,
            }}
          />
        )}
      </Container>
    </TableCell>
  );
};

export default GiveTableHeader;

const Container = styled(Box)(() => ({
  gap: "4px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  width: "fit-content",
  userSelect: "none",
  whiteSpace: "nowrap",
  // increase clickable area without padding
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-25%", // 50% of 50% (for top and bottom)
    left: "-12.5%", // 25% of 50% (for left and right)
    width: "125%", // Original width + 25%
    height: "150%", // Original height + 50%
  },
}));
