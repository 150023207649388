import { Controller, useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import GiveText from "@shared/Text/GiveText";
import { CheckboxProps } from "@shared/GiveCheckbox/type";

export const HFCheckbox = ({
  label,
  name,
  ...props
}: CheckboxProps & {
  name: string;
  label?: string;
  dataTestId?: string;
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, ref, ...rest },
        fieldState: { error },
      }) => (
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" spacing="12px">
            <GiveCheckbox
              inputRef={ref}
              checked={value}
              onChange={(e) => {
                onChange(e);
              }}
              {...rest}
              {...props}
            />
            <GiveText variant="bodyS">{label}</GiveText>
          </Stack>
          {error && (
            <GiveText
              sx={{ paddingLeft: "24px" }}
              variant="bodyXS"
              color="error"
            >
              {error.message}
            </GiveText>
          )}
        </Stack>
      )}
    />
  );
};
