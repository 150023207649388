import { Box, Stack, styled } from "@mui/material";
import { MerchantAgreementForm } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Form";
import MerchantAgreementHeader from "./MerchantAgreementHeader";
import { BankDisclosure } from "@components/Merchants/CreateMerchantPanel/modals/components/BankDisclosure";
import { useMemo } from "react";
import {
  TMerchantAgreementPayload,
  useAddMerchantAgreement,
} from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { FormProvider } from "react-hook-form";
import { IParsedData } from "../data.types";
import moment from "moment";
import { Disclaimer } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Disclaimer";
import RefundPolicyForm from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/RefundPolicyForm";
import AgreementSnapshotCard from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/AgreementSnapshotCard";
import { TOSWrapper } from "@components/ProfilePage/EnterpriseAgreement/SignTOS";
import { TermsOfService } from "@pages/TermsOfService";
import MerchantAgreementSignSection from "@components/Merchants/MerchantPreview/MerchantAgreement/MerchantAgreementSignSection";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { LastUpdatedOn_merchant_agreement } from "features/Merchants/MerchantSidePanel/constants";

export interface MerchantAgreementProps {
  handleClosePanel: () => void;
  handleOpenAgreementSnapshot?: () => void;
  merchantAgreement: TMerchantAgreementPayload;
  data: IParsedData;
  canSign?: boolean;
  isRefundPolicy?: boolean;
  isSettings?: boolean;
  isEnterprise?: boolean;
}

function MerchantAgreement({
  handleClosePanel,
  handleOpenAgreementSnapshot,
  merchantAgreement,
  data,
  canSign,
  isRefundPolicy = true,
  isSettings,
  isEnterprise,
}: MerchantAgreementProps) {
  const { methods } = useAddMerchantAgreement(
    undefined,
    merchantAgreement,
    undefined,
  );

  const msaLastAcceptedAt = merchantAgreement?.msaLastAcceptedAt;

  const agreementDetails = [
    {
      title: "Name of Previous Processor",
      value: merchantAgreement?.msaPreviousTerminationProcessorName,
      size: 6,
    },
    {
      title: "Date of Termination",
      value: merchantAgreement?.msaPreviousTerminationAt
        ? moment
            .unix(merchantAgreement?.msaPreviousTerminationAt as number)
            .format("MM/DD/YYYY")
        : "",
      size: 6,
    },
    {
      title: "Reason for Termination",
      value: merchantAgreement?.msaPreviousTerminationReason,
      size: 12,
    },
  ];

  const status = useMemo(() => {
    if (!merchantAgreement) return "not_signed";
    const { msaLastAcceptedVersion, tcVersion, msaHadAgreed } =
      merchantAgreement;
    const isCorrectVersion = msaLastAcceptedVersion === tcVersion;

    if (msaHadAgreed && isCorrectVersion) return "signed";
    if ((!msaHadAgreed && isCorrectVersion) || !msaLastAcceptedVersion)
      return "not_signed";

    return "pending";
  }, [merchantAgreement, data?.merchantInfo?.merchantID]);

  const isEditForm = status === "signed";
  const isSnapshot = status !== "not_signed";

  const hasPermissionToViewSnapshot = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.SNAPSHOT),
    operation: OPERATIONS.READ,
  });

  return (
    <FormProvider {...methods}>
      <AgreementFormWrapper data-testid="merchant-agreement-container">
        <MerchantAgreementHeader
          isEnterPrise={isEnterprise}
          onClose={handleClosePanel}
        />
        <Disclaimer
          status={status}
          isEnterPrise={isEnterprise}
          accountHolderNameFullName={`${data?.primaryAccountHolder?.firstName} ${data?.primaryAccountHolder?.lastName}`}
          dateTime={
            msaLastAcceptedAt
              ? moment.unix(msaLastAcceptedAt).format("MM-DD-YYYY HH:mm:ss")
              : ""
          }
          lastVersion={`${merchantAgreement?.msaLastAcceptedVersion}`}
          version={`${merchantAgreement?.tcVersion}`}
          merchantId={data?.merchantInfo?.merchantID}
          signatureURL={merchantAgreement?.signatureURL}
        />
        {isEnterprise ? (
          <TOSWrapper>
            <TermsOfService
              merchantAgreementVersion={{
                version: merchantAgreement.msaVersion || "",
                lastUpdated: LastUpdatedOn_merchant_agreement,
              }}
              isEnterPrise={isEnterprise}
            />
          </TOSWrapper>
        ) : (
          <>
            {isSnapshot &&
              handleOpenAgreementSnapshot &&
              hasPermissionToViewSnapshot && (
                <AgreementSnapshotCard
                  handleOpen={handleOpenAgreementSnapshot}
                />
              )}
            <BankDisclosure />
            {isRefundPolicy && (
              <Box mt={3}>
                <RefundPolicyForm
                  id={data?.merchantInfo?.merchantID}
                  description={merchantAgreement?.msaRefundPolicy}
                  disableInput={status === "signed"}
                />
              </Box>
            )}
            <MerchantAgreementForm
              isEditForm={isEditForm}
              items={agreementDetails}
              data={data}
              isSettings={isSettings}
            />
          </>
        )}

        <MerchantAgreementSignSection
          data={{
            ...data,
            merchantAgreement,
            status: {
              statusName: data?.status?.sponsorStatusName || "",
            },
          }}
          canSign={canSign}
          merchantId={data?.merchantInfo?.merchantID}
          isSettings={isSettings && status === "not_signed"}
          status={status}
          isEnterprise={isEnterprise}
          parentCheckBoxFieldKey="msaPCICompliant"
        />
      </AgreementFormWrapper>
    </FormProvider>
  );
}

export const AgreementFormWrapper = styled(Stack)(() => ({
  backgroundColor: "#FAFAFA",
  height: "100%",
  borderRadius: "16px",
  padding: "12px 16px",
  boxShadow: "0px 4px 15px 0px #0000000D",

  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none" /* IE and Edge */,
  scrollbarWidth: "none" /* Firefox */,
}));

export default MerchantAgreement;
