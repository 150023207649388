import { PUBLIC_FORM_PATHS } from "@routes/paths";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import {
  Assertion_position,
  CheckoutItem,
  ICheckout,
  Item_Layout,
  Location_position,
  LocationAddress,
  MediaItem,
  Product_payload_type,
  Time_Type,
  TransformedItems,
  TTypeName,
} from "./provider/provider.type";
import { ProductItemType } from "./types";
import {
  CONTACT_DEFAULT_MESSAGE,
  DEFAULT_EVENT_RECEIPT_MESSAGE,
  DEFAULT_EVENT_THANK_YOU_MESSAGE,
  DEFAULT_FUNDRAISER_OPTIONAL_AMOUNTS,
  DEFAULT_FUNDRAISER_RECEIPT_MESSAGE,
  DEFAULT_FUNDRAISER_THANK_YOU_MESSAGE,
  DEFAULT_RECEIPT_MESSAGE,
  DEFAULT_THANK_YOU_MESSAGE,
  SUPPORT_DEFAULT_MESSAGE,
} from "./Checkout/consts";
import { hexToRgb } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { getNumberSuffix, parseAmount, rgbaToHex } from "@utils/index";
import { getCountryNameFromCode } from "@utils/country_dial_codes";
import { FormType } from "./components/hooks/useCheckFormType";
import { toUnixTimeWithTime } from "@utils/date.helpers";
import { CampaignMapperValues } from "features/Minibuilders/PaymentFormMinibuilder/useCreateCampaignFn";
import { recurringOptions } from "./items/AddProductItemModal";
import { getValueFromString } from "@shared/GiveInputs/GiveCustomAmount";

export const usePaymentFormNavigator = (defaultCb?: any, newCb?: any) => {
  const navigate = useNavigate();
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();

  if (isPayBuilderEnabled) {
    if (newCb) {
      return newCb;
    }
    return () => navigate(`/${PUBLIC_FORM_PATHS.PAY_PRODUCT_BUILDER}`);
  }

  return defaultCb;
};

function dimColor(hex: string, opacity: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getTextColorsBasedOnBackground(hexColor: string) {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // We are using Realative Luminance formula to calculate the luminance/brightness of the color
  // 0.2126, 0.7152, 0.0722 are the weights that represent human perception sensitivity to each color channel.
  const luminance =
    0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  const titleColor = luminance > 0.5 ? "#000000" : "#FFFFFF";

  const subtitleColor = dimColor(titleColor, 0.6);

  return {
    titleColor,
    subtitleColor,
    luminance,
  };
}

const addIfDefined = <T,>(
  key: keyof T,
  value: T[keyof T],
  obj: Partial<T>,
  initialValue?: T[keyof T],
) => {
  // Only add if the value is defined and not the same as the initial value
  if (value !== undefined && value !== initialValue) {
    obj[key] = value;
  }
  return obj;
};

export const generatePayloadForProduct = (
  data: Partial<FormDataType>,
  initialFormData: Partial<FormDataType>,
  formTypeName: CampaignMapperValues,
): Partial<Product_payload_type> => {
  const isManualAddress = data.DateLocation?.isManualAddress;

  const payload: Partial<Product_payload_type> = {
    typeName: formTypeName,

    itemLayout: (data?.Style?.itemLayout || "list") as Item_Layout,
    ...(data?.Style?.background && {
      backgroundColor: rgbaToHex(data?.Style?.background, true),
    }),
    ...(data?.Style?.accent && {
      accentColor: rgbaToHex(data?.Style?.accent, true),
    }),

    checkoutButtonText: data?.Style?.checkoutContent,
    paymentLogoURL: data?.Style?.logo,
    stopStep: data.stopStep,
    ...(formTypeName === "event" && {
      startsAt: toUnixTimeWithTime(
        new Date(data?.DateLocation?.startsAt as any),
        data.DateLocation?.startsAtTime as string,
      ),
      endsAt: data?.DateLocation?.endsAt
        ? toUnixTimeWithTime(
            new Date(data.DateLocation.endsAt as any),
            data.DateLocation?.endsAtTime as string,
          )
        : null,
      includeTime: data?.DateLocation?.includeTime,
      locationAddress:
        isManualAddress &&
        data.DateLocation?.locationPosition === "onsite" &&
        data.DateLocation?.locationShortAddress
          ? {
              line1: data.DateLocation?.locationAddress?.line1,
              line2: data.DateLocation?.locationAddress?.line2,
              city: data.DateLocation?.locationAddress?.city,
              state: data.DateLocation?.locationAddress?.state,
              zip: data.DateLocation?.locationAddress?.zip,
              country: data.DateLocation?.locationAddress?.country,
            }
          : null,
      locationShortAddress:
        data.DateLocation?.locationPosition === "onsite" && !isManualAddress
          ? data.DateLocation?.locationShortAddress
          : null,
      locationURL:
        data.DateLocation?.locationPosition === "online"
          ? data?.DateLocation?.locationURL
          : null,
      recurringIntervals: [
        {
          interval: "once",
        },
      ],
    }),

    ...(formTypeName === FormType.FUNDRAISERS && {
      ...(data.About?.addTarget && {
        targetValue: Number(data.About?.targetValue?.replace(/,/g, "")) * 100,
      }),
      showHistory: data.About?.showHistory,
      recurringIntervals: data.Donations?.enableRecurringDonations
        ? data?.Donations?.recurringIntervals?.map((item) => {
            return { interval: item };
          })
        : [{ interval: "once" }],
    }),
  };

  addIfDefined(
    "name",
    data?.About?.heading,
    payload,
    initialFormData?.About?.heading,
  );

  addIfDefined(
    "description",
    removeNextLine(data?.About?.description),
    payload,
    initialFormData?.About?.description,
  );

  addIfDefined(
    "imageURL",
    data?.About?.selectedImage?.URL,
    payload,
    initialFormData?.About?.selectedImage?.URL,
  );
  addIfDefined(
    "position",
    data?.About?.assetPosition,
    payload,
    initialFormData?.About?.assetPosition,
  );

  return payload;
};

export const formatVariantsToMatchPayload = (variants: ProductItemType[]) => {
  const data = variants?.map((variant, index) => {
    const price =
      typeof variant?.amount === "string"
        ? parseFloat(variant?.amount?.replace(/,/g, ""))
        : variant?.amount;

    const inventory = !isNil(variant?.in_stock)
      ? Number(variant?.in_stock)
      : null;
    return {
      id: variant.variantID,
      name: variant?.title,
      displayOrder: index,
      price: price && price * 100,
      isEnabled: variant?.display,
      imageURL: variant?.thumbnail,
      inventory,
      ...(inventory === null && {
        unlimitedInventory: true,
      }),
      showAvailableVariants: variant?.display,
      description: removeNextLine(variant?.description),
      bundle: 1,
      recurringIntervals: [
        {
          interval: variant.paymentType,
        },
      ],
    };
  });

  return data;
};

export const getInitialFormData = (type: TTypeName) => {
  const { thankYou: defaultThankyou, receipt: defaultMessageForCustomReceipt } =
    getDefaultMessages(type);

  return {
    productId: null as number | null,
    stopStep: "product_creation",
    About: {
      heading: "",
      description: "",
      assetPosition: "right" as Assertion_position,
      selectedImage: {} as MediaItem,
      ...(type === FormType.FUNDRAISERS && {
        addTarget: false,
        showHistory: false,
        targetValue: "",
        totalContribution: "",
        isApprovedNonProfit: true,
      }),
    },
    DateLocation: {
      startsAt: null as string | null,
      endsAt: null as string | null,
      locationURL: null as string | null,
      locationAddress: {
        country: "US",
      } as LocationAddress,
      locationShortAddress: null as string | null,
      includeTime: false,
      startsAtTime: "",
      endsAtTime: "",
      timeActiveTab: "single" as Time_Type,
      locationPosition: "onsite" as Location_position,
      isManualAddress: false,
    },
    Items: [] as ProductItemType[],
    variantsToDelete: [] as ProductItemType[],
    Style: {
      background: "rgb(255,255,255)",
      accent: "rgb(153, 138, 107)",
      itemLayout: "list",
      logo: "",
      checkoutContent:
        type === FormType.FUNDRAISERS ? "Donate Now" : "Checkout",
    },
    Checkout: {
      email: {
        render: true,
        required: true,
      },
      phoneNumber: {
        render: false,
        required: false,
        optionalMessage: CONTACT_DEFAULT_MESSAGE,
      },
      delivery: {
        render: false,
        required: false,
      },
      support: {
        render: false,
        required: false,
        optionalMessage: SUPPORT_DEFAULT_MESSAGE,
      },
      thankYouMessage: {
        render: false,
        optionalMessage: defaultThankyou,
      },
      receiptMessage: {
        render: false,
        optionalMessage: defaultMessageForCustomReceipt,
      },
    },
    ...(type === FormType.FUNDRAISERS && {
      Donations: {
        allowCustomAmount: false,
        enableSuggestedAmounts: false,
        enableRecurringDonations: false,
        selectedAmount: "",
        optionalAmounts: DEFAULT_FUNDRAISER_OPTIONAL_AMOUNTS,
        suggestedAmount: "",
        recurringIntervals: recurringOptions.map(
          (item) => item.value,
        ) as string[],
        isAnonymous: false,
      },
    }),
    Launch: { heading: "" },

    hasCheckoutForm: false,
    hasPaymentForm: false,
    hasVariants: false,
    publishedStatus: "",
  };
};
export type FormDataType = ReturnType<typeof getInitialFormData>;

const getDefaultMessages = (
  typeName?: TTypeName,
): { thankYou: string; receipt: string } => {
  switch (typeName) {
    case FormType.FUNDRAISERS:
      return {
        thankYou: DEFAULT_FUNDRAISER_THANK_YOU_MESSAGE,
        receipt: DEFAULT_FUNDRAISER_RECEIPT_MESSAGE,
      };
    case FormType.EVENTS:
      return {
        thankYou: DEFAULT_EVENT_THANK_YOU_MESSAGE,
        receipt: DEFAULT_EVENT_RECEIPT_MESSAGE,
      };
    default:
      return {
        thankYou: DEFAULT_THANK_YOU_MESSAGE,
        receipt: DEFAULT_RECEIPT_MESSAGE,
      };
  }
};

export const generateFormData = (
  data: Partial<Product_payload_type>,
): Partial<FormDataType> => {
  const { checkout, typeName } = data;

  const customCheckoutForm = checkout?.customCheckoutForm;

  const defaultItems = checkout?.defaultCheckoutForm
    ? transformItems(checkout.defaultCheckoutForm.items)
    : {};
  const customItems = customCheckoutForm
    ? transformItems(checkout?.customCheckoutForm?.items)
    : {};

  const { thankYou: defaultThankyou, receipt: defaultMessageForCustomReceipt } =
    getDefaultMessages(typeName);

  const donationRecommendedAmount = customCheckoutForm?.recommendedAmount
    ? `${parseAmount(customCheckoutForm.recommendedAmount / 100)}`
    : null;

  const donationOptionalAmounts = Array(4)
    .fill("")
    .map((_, index) => {
      const value = customCheckoutForm?.optionalAmounts?.[index] ?? 0;
      return `${parseAmount(value / 100)}`;
    });

  const optionalAmountsHasNonEmptyValue = Boolean(
    donationOptionalAmounts?.filter((item) => parseFloat(item))?.length,
  );

  const donationRecurringIntervals =
    customCheckoutForm?.optionalRecurringIntervals;

  const enableRecurringDonations = Boolean(
    donationRecurringIntervals?.slice(1)?.length,
  );

  const recurringIntervals = (
    enableRecurringDonations
      ? donationRecurringIntervals
      : recurringOptions.map((item) => item.value)
  ) as string[];

  return {
    productId: data?.id || null,
    stopStep: data?.stopStep || "product_creation",
    About: {
      heading: data?.name || "",
      description: data?.description || "",
      assetPosition: (data?.position || "right") as Assertion_position,
      selectedImage: {
        URL: data?.imageURL || "",
      } as MediaItem,
      ...(typeName === FormType.FUNDRAISERS && {
        addTarget: Boolean(data?.targetValue),
        showHistory: data?.showHistory || false,
        targetValue: data?.targetValue
          ? (data.targetValue / 100).toFixed(2)
          : "",

        totalContribution: data?.netGenerated
          ? parseAmount(data?.netGenerated / 100)
          : "0",

        isApprovedNonProfit: data?.isVerified && data?.isNonProfit,
      }),
    },
    variantsToDelete: [],
    DateLocation: {
      timeActiveTab: data?.endsAt ? "range" : "single",
      startsAt: data?.startsAt ? extractDateTime(data?.startsAt).date : null,
      endsAt: data?.endsAt ? extractDateTime(data?.endsAt).date : null,
      startsAtTime: data?.includeTime
        ? extractDateTime(data?.startsAt).time
        : "",
      endsAtTime:
        data?.includeTime && data?.endsAt
          ? extractDateTime(data?.endsAt).time
          : "",
      locationURL: data?.locationURL || null,
      locationAddress: data?.locationAddress
        ? {
            line1: data.locationAddress.line1,
            line2: data.locationAddress.line2,
            city: data.locationAddress.city,
            state: data.locationAddress.state,
            zip: data.locationAddress.zip,
            country: data.locationAddress.country,
          }
        : {
            country: "US",
          },
      locationShortAddress: data?.locationShortAddress || null,
      includeTime: data?.includeTime ?? false,
      locationPosition: data.locationURL ? "online" : "onsite",
      isManualAddress: !!data?.locationAddress,
    },
    Items: (data?.variants?.map((item) => ({
      id: item.id,
      variantID: item.id,
      title: item?.name,
      amount: (item.price / 100).toFixed(2),
      in_stock: item.inventory,
      display: item.isEnabled,
      description: item.description,
      thumbnail: item?.imageURL,
      paymentType: item.recurringIntervals?.[0] || "once",
    })) || []) as ProductItemType[],
    Style: {
      itemLayout: data?.itemLayout || ("list" as Item_Layout),
      background: data?.backgroundColor
        ? hexToRgb(data?.backgroundColor)
        : "rgb(255,255,255)",
      accent: data?.accentColor
        ? hexToRgb(data?.accentColor)
        : "rgb(153, 138, 107)",
      logo: data?.paymentLogoURL || "",
      checkoutContent: data?.checkoutButtonText || "Checkout",
    },

    Donations: {
      allowCustomAmount: optionalAmountsHasNonEmptyValue,
      enableSuggestedAmounts: Boolean(donationRecommendedAmount),
      enableRecurringDonations,
      optionalAmounts: optionalAmountsHasNonEmptyValue
        ? donationOptionalAmounts
        : DEFAULT_FUNDRAISER_OPTIONAL_AMOUNTS,
      suggestedAmount: donationRecommendedAmount ?? "",
      selectedAmount: donationRecommendedAmount ?? "",
      recurringIntervals,
      isAnonymous: false,
    },
    Checkout: {
      email: {
        render: true,
        required: customItems?.email
          ? customItems?.email?.isRequired
          : defaultItems?.email?.isRequired,
      },

      phoneNumber: {
        render: customItems?.phone_number?.isVisible ?? false,
        required: customItems?.phone_number
          ? customItems?.phone_number?.isRequired
          : defaultItems?.phone_number?.isRequired,
        optionalMessage:
          customItems?.phone_number?.description ??
          defaultItems?.phone_number?.description ??
          CONTACT_DEFAULT_MESSAGE,
      },
      delivery: {
        render: customItems?.delivery_address?.isVisible ?? false,
        required: customItems?.delivery_address
          ? customItems?.delivery_address?.isRequired
          : defaultItems?.delivery_address?.isRequired,
      },
      support: {
        render: customItems?.pass_fees?.isVisible ?? false,
        required: customItems?.pass_fees
          ? customItems?.pass_fees?.isRequired
          : defaultItems?.pass_fees?.isRequired,
        optionalMessage:
          customItems?.pass_fees?.description ??
          defaultItems?.pass_fees?.description ??
          SUPPORT_DEFAULT_MESSAGE,
      },
      thankYouMessage: {
        render: !!checkout?.customCheckoutForm?.successMessage,
        optionalMessage:
          checkout?.customCheckoutForm?.successMessage?.trim() ||
          checkout?.defaultCheckoutForm?.successMessage?.trim() ||
          defaultThankyou,
      },
      receiptMessage: {
        render: !!checkout?.customCheckoutForm?.receiptMessage,
        optionalMessage:
          checkout?.customCheckoutForm?.receiptMessage?.trim() ||
          checkout?.defaultCheckoutForm?.receiptMessage?.trim() ||
          defaultMessageForCustomReceipt,
      },
    },
    Launch: { heading: "" },
    hasCheckoutForm: data?.hasCheckoutForm || false,
    hasPaymentForm: data?.hasPaymentForm || false,
    hasVariants: data?.hasVariants || false,
    publishedStatus: data?.publishedStatus || "",
  };
};

const transformItems = (items: CheckoutItem[]): TransformedItems => {
  return items.reduce((acc, item) => {
    const { paramName, ...rest } = item;
    acc[paramName] = rest;
    return acc;
  }, {} as TransformedItems);
};

export const generatePayloadForCheckout = (
  data: Partial<FormDataType>,
  type: CampaignMapperValues,
): Partial<Product_payload_type> => {
  const payload: Partial<ICheckout> = {
    items: [
      {
        paramName: "email",
        isRequired: true, //BE accet only true for this value since cant be false
        isVisible: data.Checkout?.email?.render || false,
        description: "email field",
      },
      {
        paramName: "phone_number",
        isRequired: data.Checkout?.phoneNumber?.required || false,
        isVisible: data.Checkout?.phoneNumber?.render || false,
        description:
          data.Checkout?.phoneNumber?.optionalMessage ||
          CONTACT_DEFAULT_MESSAGE,
      },
      {
        paramName: "delivery_address",
        isRequired: data.Checkout?.delivery?.required || false,
        isVisible: data.Checkout?.delivery?.render || false,
        description: "delivery address field",
      },
      {
        paramName: "pass_fees",
        isRequired: data.Checkout?.support?.required || false,
        isVisible: data.Checkout?.support?.render || false,
        description:
          removeNextLine(data.Checkout?.support?.optionalMessage) ||
          SUPPORT_DEFAULT_MESSAGE,
      },
    ],
    successMessage: data.Checkout?.thankYouMessage?.render
      ? removeNextLine(data.Checkout?.thankYouMessage?.optionalMessage)
      : "",
    receiptMessage: data.Checkout?.receiptMessage?.render
      ? removeNextLine(data.Checkout?.receiptMessage?.optionalMessage)
      : "",
    ...(type === FormType.FUNDRAISERS && {
      optionalAmounts: data.Donations?.allowCustomAmount
        ? data.Donations?.optionalAmounts
            ?.filter((item) => parseFloat(item))
            ?.map((item) => getValueFromString(item) * 100)
        : null,
      recommendedAmount: data.Donations?.enableSuggestedAmounts
        ? getValueFromString(data.Donations?.suggestedAmount) * 100
        : null,
      optionalRecurringIntervals: data.Donations?.enableRecurringDonations
        ? data.Donations?.recurringIntervals
        : ["once"],
    }),
  };

  return payload;
};

export const scrollbarStyles = {
  overflowY: "auto",
  flexGrow: 1,
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#80807e",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d32f2f",
  },
  scrollbarWidth: "thin",
  scrollbarColor: "#80807e transparent",
};

const removeNextLine = (text?: string) => {
  if (!text) return;
  return text.replace(/\n/g, "");
};

export const formatPaymentFormDate = (
  day: Date | string | null,
  includeTime?: boolean,
  time?: string,
): string | null => {
  if (!day) return null;
  let date: Date;
  if (typeof day === "string") {
    date = new Date(day);
    if (isNaN(date.getTime())) {
      return null;
    }
  } else if (day instanceof Date) {
    if (isNaN(day.getTime())) {
      return null;
    }
    date = day;
  } else if (typeof day === "number") {
    date = new Date(day * 1000);
    if (isNaN(date.getTime())) {
      return null;
    }
  } else {
    return null;
  }

  const months = moment.months();
  const month = months?.[date?.getMonth?.() ?? -1];
  const dayOfMonth = date?.getDate?.();
  const suffix = getNumberSuffix(dayOfMonth);

  let formattedDate = `${month}. ${dayOfMonth}${suffix}, ${date?.getFullYear?.()}`;

  if (includeTime && time) {
    formattedDate += ` ${time}`;
  }
  return formattedDate;
};

export function extractDateTime(timestamp: any) {
  const momentObj = moment.unix(timestamp);
  const date = momentObj.format("MM/DD/YYYY");
  const time = momentObj.format("hh:mm A");
  return { date, time };
}

export const getDomainName = (url?: string) => {
  if (!url) return null;
  try {
    return new URL(url).hostname;
  } catch (error) {
    return null;
  }
};

export const formatMapAddress = (locationAddress: any) => {
  if (!locationAddress) return null;
  const { line1, line2, city, state, zip, country } = locationAddress;
  const cityState = [city, state].filter(Boolean).join(" ").trim();
  const addressParts = [
    line1,
    line2,
    cityState,
    zip,
    getCountryNameFromCode(country),
  ].filter((part) => part && part.trim() && part !== null);

  return addressParts.join(", ");
};

export const getEventLocation = (
  locationAddress: any,
  locationShortAddress: any,
  locationURL: string | null,
) => {
  if (locationURL) return getDomainName(locationURL);
  return locationAddress
    ? formatMapAddress(locationAddress)
    : locationShortAddress;
};
export const getEventDate = (
  startsAt: any,
  endsAt: any,
  includeTime: boolean,
) => {
  const eventStartTime = startsAt
    ? formatPaymentFormDate(
        startsAt,
        includeTime,
        extractDateTime(startsAt).time,
      )
    : null;
  const eventEndsTime = endsAt
    ? formatPaymentFormDate(endsAt, includeTime, extractDateTime(startsAt).time)
    : null;
  if (eventStartTime && eventEndsTime) {
    return `${eventStartTime} to ${eventEndsTime}`;
  }
  return eventStartTime || eventEndsTime || null;
};

export const isEventEnded = ({
  startDate,
  endDate,
  includeTime,
}: {
  startDate?: Date | null;
  endDate?: Date | null;
  includeTime: boolean;
}) => {
  const currentTime = includeTime
    ? Date.now()
    : new Date().setHours(0, 0, 0, 0);

  const eventHasEnded =
    (endDate && endDate.getTime() < currentTime) ||
    (startDate && startDate.getTime() < currentTime);

  return eventHasEnded;
};

export const formTypeToUrlMap = {
  standard: "standard",
  sweepstake: "sweepstakes",
  event: "events",
  invoice: "invoices",
  membership: "memberships",
  fundraiser: "fundraisers",
};
