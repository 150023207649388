import { NameInput, OwnershipTabsInput } from "@common/BusinessProfileInputs";
import { BirthDatePicker } from "@common/DatePickers";
import { ArrowLeft } from "@assets/icons";
import * as Yup from "yup";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "@components/ProfilePage/form.components";
import { subDays } from "date-fns";
import { Box, Grid, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { RHFInput, RHFTelInput } from "@common/Input";
import { palette } from "@palette";
import { RHFCheckbox } from "@common/Checkbox";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Text } from "@common/Text";
import { RHFSelect } from "@common/Select";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { ProvinceInput, ZipCodeInput } from "@common/AddressInputs";
import { usePersonalInformationProvider } from "../Provider/PersonalInformationProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { matchIsValidTel } from "mui-tel-input";
import { useRef, useEffect } from "react";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { isEqual } from "lodash";
import { personalInfoEnum } from "../types";
import CustomTaxSsnInput from "@common/BusinessProfileInputs/CustomTaxSsnInput";
import {
  nonResidentInputsSchema,
  ssnSchema,
  streetAddressValidator,
} from "@utils/validation.helpers";
import { TinType } from "@components/Merchants/CreateMerchantPanel/modals/useBusinessProfileModal";
import { PersonalInfoTabDefaultData } from "../types";
import { RHFStreetAddress } from "@common/RHFStreetAddress";
import { NonResidentInputs } from "@common/NonResidentInput/NonResidentInputs";
import { getIsOwnerBoxDisabled } from "@sections/VerifyAccountHolder_v2/form/form.const";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

function PersonalInfoTab() {
  const { isNewOnboardingEnabled } = useGetFeatureFlagValues();
  const { selectCountriesList } = useCountryCodes();
  const valueRef = useRef<any>();
  const {
    homepageReset,
    handleUpdateStatusValue,
    personalTabDefaultData,
    onHandleSubmit,
    isIndividualSoleProprietorship,
    leType,
    fullOwnershipReleased,
  } = usePersonalInformationProvider();

  const isOwnerCheckDisabled = getIsOwnerBoxDisabled(
    leType,
    fullOwnershipReleased,
  );

  const handleUpdateCountValue = handleUpdateStatusValue(
    personalInfoEnum.PERSONAL_INFORMATION,
  );
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });

  const methods = useForm<PersonalInfoTabDefaultData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: personalTabDefaultData as PersonalInfoTabDefaultData,
  });

  const {
    watch,
    formState: { dirtyFields },
  } = methods;
  const values = watch();
  const isAddressHidden = !watch("isOwner");

  useEffect(() => {
    if (!isEqual(values, valueRef.current)) {
      valueRef.current = values;

      (async () => {
        const count = await calculatePercentageNested(schema, values);
        handleUpdateCountValue(count);
      })();
    }
  }, [values, dirtyFields]);

  const formFileds = [
    {
      component: (
        <NameInput
          name="firstName"
          label="First Name"
          placeholder="First Name"
          isLegalName
        />
      ),
      xs: 12,
    },
    {
      component: (
        <NameInput
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
          isLegalName
        />
      ),
      xs: 12,
    },
    {
      component: (
        <BirthDatePicker name="DOB" label="Date of Birth" openPickerOnFocus />
      ),
      xs: 12,
    },
    {
      component: (
        <RHFTelInput
          name="phoneNumber"
          label="Phone number"
          onBlur={() => {
            methods.trigger("phoneNumber");
          }}
          fullWidth
          flagStyles={{
            width: "20px",
            height: "15px",
            borderRadius: 0,
          }}
        />
      ),
      xs: 12,
    },
    ...(!isNewOnboardingEnabled
      ? [
          {
            component: <NonResidentInputs />,
            xs: 12,
          },
          {
            component: (
              <Box display="flex" alignItems="flex-end" width="100%">
                <RHFCheckbox
                  name="hasManagerialAuthority"
                  label="I have managerial authority of the business"
                />
              </Box>
            ),
            xs: 12,
          },
          {
            component: (
              <Box display="flex" alignItems="flex-end" width="100%">
                <RHFCheckbox
                  disabled={isOwnerCheckDisabled}
                  name="isOwner"
                  label={
                    ["tax_exempt_organization", "government_agency"].includes(
                      leType,
                    )
                      ? "I'm also a controller of the organization"
                      : "I'm also an owner of this business"
                  }
                />
              </Box>
            ),
            xs: 12,
          },
        ]
      : [
          {
            component: (
              <CheckboxComponent
                isOwnerCheckDisabled={isOwnerCheckDisabled}
                leType={leType}
              />
            ),
            xs: 12,
          },
        ]),
    {
      component: (
        <CustomTaxSsnInput
          ssnName="ssn"
          taxIdName="taxID"
          businessTypeName="businessType"
          tinType="tinType"
          isBusiness={false}
        />
      ),
      xs: 12,
      hidden: isAddressHidden,
    },
    {
      component: (
        <Box display="flex" alignItems="flex-end" width="100%">
          <Text fontWeight="regular" fontSize="18px" color="#575353">
            Your address
          </Text>
        </Box>
      ),
      xs: 12,
      hidden: isAddressHidden,
    },
    {
      component: (
        <RHFSelect
          name="country"
          label="Country"
          options={selectCountriesList}
          disabled
        />
      ),
      xs: 12,
      hidden: isAddressHidden,
    },
    {
      component: (
        <RHFStreetAddress
          name="street"
          label="Street Address"
          placeholder="Enter Street Address..."
          fullWidth
        />
      ),
      xs: 12,
      hidden: isAddressHidden,
    },
    {
      component: (
        <RHFInput
          name="city"
          label="City"
          placeholder="Enter city..."
          fullWidth
        />
      ),
      xs: 12,
      hidden: isAddressHidden,
    },
    {
      component: <ProvinceInput isUS name="state" />,
      xs: 12,
      hidden: isAddressHidden,
    },
    {
      component: (
        <ZipCodeInput
          name="zip"
          label="Zip Code"
          placeholder="Enter Zip Code"
          letterSpacing="4px"
        />
      ),
      xs: 12,
      hidden: isAddressHidden,
    },
    {
      component: (
        <OwnershipTabsInput
          name="ownership"
          label="Custom percentage"
          placeholder="0-100"
          labelProps={{
            sx: {
              textAlign: "left",
              color: "#575353",
              fontSize: "18px",
              fontWeight: "regular",
            },
          }}
          disabled={isIndividualSoleProprietorship}
          isFullyDisabled={isIndividualSoleProprietorship}
          leType={leType}
        />
      ),
      xs: 12,
      hidden: isAddressHidden,
    },
  ];

  const onSubmit = (data: PersonalInfoTabDefaultData) =>
    onHandleSubmit(personalInfoEnum.PERSONAL_INFORMATION, data);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        flexGrow={1}
        id="business-profile-form"
        display="flex"
        height="100%"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ProfileSetupFormContainer>
          <Stack direction="column" gap={4}>
            <ProfileSetupFormTitle title="Let us know more about yourself" />
            <Grid container rowSpacing="12px">
              {formFileds?.map(({ component, xs, hidden }, idx) => (
                <HiddenComponent hidden={hidden || false} key={idx}>
                  <Grid item xs={xs}>
                    <FadeUpWrapper delay={200 + idx * 50}>
                      {component}
                    </FadeUpWrapper>
                  </Grid>
                </HiddenComponent>
              ))}
            </Grid>
          </Stack>

          <ProfileSetupFormActions
            secondaryAction={{
              onClick: homepageReset,
              children: (
                <Box
                  component="span"
                  color={palette.black[100]}
                  display="inline-flex"
                  alignItems="center"
                  gap="4px"
                >
                  <ArrowLeft height={20} width={20} fill={palette.black[100]} />{" "}
                  Back
                </Box>
              ),
            }}
            primaryAction={{
              children: "Next",
            }}
          />
        </ProfileSetupFormContainer>
      </Box>
    </FormProvider>
  );
}

export default PersonalInfoTab;

const addressFieldSchema = (name: string) =>
  Yup.string().when("isOwner", {
    is: true,
    then: Yup.string().required(`${name} is required`),
    otherwise: Yup.string(),
  });

const schema = Yup.object().shape({
  firstName: Yup.string().required("first Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  DOB: Yup.date()
    .typeError("Please provide a valid date")
    .nullable()
    .required("A starting date is required")
    .max(subDays(new Date(), 16), "A starting date must be in the past"),
  phoneNumber: Yup.string()
    .nullable()
    .test(
      "is-valid-or-special-case",
      "Please enter a valid phone number",
      function (value) {
        if (value == null || value === "" || value === "+" || value === "+1") {
          return true;
        }
        return matchIsValidTel(value);
      },
    ),
  isOwner: Yup.boolean().nullable(),
  hasManagerialAuthority: Yup.boolean().nullable(),
  country: addressFieldSchema("country"),
  street: Yup.string().when("isOwner", {
    is: true,
    then: streetAddressValidator(true, "Street Address is required"),
    otherwise: Yup.string(),
  }),
  city: addressFieldSchema("city"),
  state: addressFieldSchema("state"),
  zip: addressFieldSchema("zip"),
  ownership: addressFieldSchema("ownership"),
  tinType: Yup.mixed<TinType>().oneOf(["ssn", "ein"]),
  ssn: Yup.string().when(["tinType", "isOwner"], {
    is: (isOwner: any, tinType: any) => isOwner && tinType === "ssn",
    then: ssnSchema("ssn"),
  }),
  taxID: Yup.string().when("tinType", {
    is: "ein",
    then: Yup.string().test("taxID", "test", function (value, ctx: any) {
      if (!value) {
        return this.createError({
          message: `Tax ID is required`,
          path: "taxID",
        });
      }
      if (value.replace(/(\s|-)/g, "").length !== 9) {
        return this.createError({
          message: `Invalid format. Format should be 12-3456789`,
          path: "taxID",
        });
      }
      return true;
    }),
  }),
  ...nonResidentInputsSchema,
});

const CheckboxComponent = ({
  isOwnerCheckDisabled,
  leType,
}: {
  isOwnerCheckDisabled: boolean;
  leType: string;
}) => {

  const formFileds = [
    {
      component: <NonResidentInputs />,
      xs: 12,
    },
    {
      component: (
        <Box display="flex" alignItems="flex-end" width="100%">
          <RHFCheckbox
            name="hasManagerialAuthority"
            label="I have managerial authority of the business"
          />
        </Box>
      ),
      xs: 12,
    },
    {
      component: (
        <Box display="flex" alignItems="flex-end" width="100%">
          <WithTooltipWrapper
            hasTooltip={!leType}
            tooltipProps={{
              show: !leType,
              message: "You don't have yet a business profile",
            }}
          >
            <RHFCheckbox
              disabled={isOwnerCheckDisabled || !leType}
              name="isOwner"
              label={
                ["tax_exempt_organization", "government_agency"].includes(
                  leType,
                )
                  ? "I’m also a controller of the organization"
                  : "I’m also an owner of this business"
              }
            />
          </WithTooltipWrapper>
        </Box>
      ),
      xs: 12,
    },
  ];
  return (
    <>
      {formFileds.map(({ component }, index) => (
        <Stack key={`key-${index}`}>{component}</Stack>
      ))}
    </>
  );
};
