import { Stack } from "@mui/material";
import { ChatsCircle, PaperPlaneTilt } from "@phosphor-icons/react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import TitleLabel from "./components/TitleLable";
import { useNotifications } from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/hooks/useNotifications";
import { checkPortals } from "@utils/routing";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import GiveLink from "@shared/Link/GiveLink";
import ProfileImage from "@shared/ProfileImage/ProfileImage";
import useChangeProfileImage from "./hooks/useChangeProfileImage";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";
import { ArrowPlacement } from "@shared/Tooltip/GiveTooltip.types";

interface Props {
  title: string;
  imageUrl?: string;
  panelType?: "merchantSidePanel" | "other";
  id: number;
}

function SidePanelTitle({ title, imageUrl, panelType, id }: Props) {
  const { isMobileView } = useCustomThemeV2();
  const { handleOpenConversationsModal } = useConversationsModal();
  const { openNotifyMerchantModal } = useNotifications();
  const { merchant_underwriting } = useEnterprisePermissions();

  const { isEnterprisePortal } = checkPortals();

  // We are hiding the action items section if merchant_underwriting is false and we are in enterprise portal
  const isHidden = !merchant_underwriting && isEnterprisePortal;
  const { onUpload, onDelete, disabled } = useChangeProfileImage(id);

  if (panelType === "merchantSidePanel") {
    const handleClick = () => {
      openNotifyMerchantModal({});
    };
    const actionItemList = [
      {
        name: "Notify Merchant",
        Icon: PaperPlaneTilt,
        onClick: handleClick,
        hidden: isHidden,
      },
      {
        name: "View All Conversations",
        Icon: ChatsCircle,
        onClick: () => handleOpenConversationsModal(true),
        hidden: isHidden,
      },
    ];

    return (
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems={isMobileView ? "start" : "end"}
        maxWidth="100%"
        gap={1}
      >
        <Stack flexShrink={1} overflow="hidden">
          <TitleLabel title={title} />
          <Stack
            flexDirection={!isMobileView ? "row" : "column"}
            gap={!isMobileView ? "21px" : "20px"}
            mt="16px"
          >
            {actionItemList.map((item) => {
              const { Icon, name, onClick, hidden } = item || {};
              if (hidden) return null;
              return (
                <GiveLink
                  key={name}
                  component="button"
                  Icon={Icon}
                  onClick={onClick}
                >
                  {name}
                </GiveLink>
              );
            })}
          </Stack>
        </Stack>

        <GiveTooltip
          arrowPlacement={ArrowPlacement.TOP_START}
          title={EDIT_DENY_MESSAGE}
          color="default"
          disableHoverListener={!disabled}
          sx={{ width: "fit-content" }}
        >
          <ProfileImage
            imageUrl={imageUrl}
            title={title}
            onUpload={onUpload}
            onDelete={onDelete}
            disabled={disabled}
          />
        </GiveTooltip>
      </Stack>
    );
  } else return <div> other panel titles created here</div>;
}

export default SidePanelTitle;
