import { AppBarProps, AppBar, styled, Box, Stack } from "@mui/material";
import { DotsMenuIcon } from "@assets/icons";
import { StyledIconButton as IconButton } from "@common/IconButton";
import { useRouting } from "@hooks/common/useRouting";
import { useDrawer } from "@components/Drawer/hooks/useDrawer";
import CustomBreadcrumbs from "./CustomBreadcrumbs";
import BreadcrumbsTextItem from "./BreadcrumbsTextItem";
import BreadcrumbsProductItem from "./BreadcrumbsProductItem";
import { RefObject } from "react";
import React from "react";
import AppbarActions from "./AppbarMobileActions";
import { MASQUERADE_SNACKBAR_HEIGHT } from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import { useMatch } from "react-router-dom";
import { isMobile } from "@utils/index";

export const APP_BAR_HEIGHT = 40;

interface BreadcrumbsAppBarProps extends AppBarProps {
  appBarRef: RefObject<HTMLDivElement>;
  isMasqueradeMode?: boolean;
  showOnlyAppBarNavigation?: boolean;
}

const BreadcrumbsAppBar: React.FC<BreadcrumbsAppBarProps> = ({
  appBarRef,
  isMasqueradeMode,
  showOnlyAppBarNavigation,
}) => {
  const { toggleDrawer } = useDrawer();
  const { subPaths, isSettingsPage, isBankAccountPage } = useRouting();

  const hide = useMatch("/acquirer/settlements/:id");
  if (hide && isMobile) return null;

  return (
    <AppBar
      sx={{
        backgroundColor: "transparent",
        transition: "box-shadow 150ms ease-in",
        height: APP_BAR_HEIGHT,
        width: "100%",
        zIndex: 100,
        top: isMasqueradeMode ? `${MASQUERADE_SNACKBAR_HEIGHT}px` : 0,
      }}
      elevation={0}
      ref={appBarRef}
      position="sticky"
    >
      <PageContainer
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        display="flex"
        maxHeight="100%"
      >
        <Box display="flex" alignItems="center">
          {!showOnlyAppBarNavigation && (
            <IconButton onClick={toggleDrawer}>
              <DotsMenuIcon />
            </IconButton>
          )}
          <CustomBreadcrumbs
            maxElements={2}
            paths={subPaths.slice(1)}
            FallbackComponent={BreadcrumbsTextItem}
            isAlwaysReturnFallback={isSettingsPage || isBankAccountPage}
            breadcrumbsElements={[
              {
                indexes: [0],
                component: BreadcrumbsTextItem,
              },
              {
                indexes: [1],
                component: BreadcrumbsProductItem,
              },
            ]}
          />
        </Box>
        {!showOnlyAppBarNavigation && <AppbarActions />}
      </PageContainer>
    </AppBar>
  );
};

export default React.memo(BreadcrumbsAppBar);

export const PageContainer = styled(Stack)(({ theme }) => ({
  padding: "0 58px",
  width: "100%",
  marginInline: "auto",
  maxWidth: "1440px",
  [theme.breakpoints.down(1024)]: {
    padding: "0 32px",
  },
  [theme.breakpoints.down("new_sm_md")]: {
    padding: "0 16px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "0 16px",
  },
}));
