import { DialogContent } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectThreads } from "@redux/slices/conversations";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useState } from "react";
import ChatAction from "../Chat/ChatAction";
import ChatMainContent from "../Chat/ChatMainContent";
import { useListThreads } from "../hooks/useListThreads";
import { TQueryObject } from "../types";
import { TMentionedUser } from "../utils/functions";
import ReplySection from "./ReplySection";

const Chat = ({
  merchantId,
  queryObject,
  numberOfUnreadMessages,
  handleScroll,
  isRepliesOpen,
  isExpanded,
  onMessageSubmit,
  handleOpenConversationsModal,
}: {
  queryObject: TQueryObject;
  merchantId: number;
  numberOfUnreadMessages: number;
  handleScroll: (e: any) => void;
  isRepliesOpen: boolean;
  isExpanded: boolean;
  onMessageSubmit?: (...props: any[]) => void;
  handleOpenConversationsModal: (open?: boolean) => void;
}) => {
  const [mentionedUser, setMentionedUser] = useState<TMentionedUser>({
    user: "",
    authorAccID: undefined,
  });
  const threads = useAppSelector(selectThreads);
  const { isMobileView } = useCustomTheme();

  const { isLoading, isRefetching, ...rest } = useListThreads({
    merchantId,
    hasUnreadMessages: !!numberOfUnreadMessages,
    topicId: queryObject.id as number,
  });

  return (
    <>
      <DialogContent
        sx={{
          "&.MuiDialogContent-root, & .MuiDialogContent-root": {
            padding: "0px !important",
          },
        }}
        data-testid="conversation-scroll-container"
        onScroll={handleScroll}
      >
        {isRepliesOpen ? (
          <ReplySection data={rest.data} isMobileView={isMobileView} />
        ) : (
          <ChatMainContent
            isMobileView={isMobileView}
            isExpanded={isExpanded}
            setMentionedUser={setMentionedUser}
            topicId={queryObject.id || undefined}
            parentThreadName={queryObject.name}
            merchantId={merchantId}
            hasUnreadMessages={!!numberOfUnreadMessages}
            data={rest.data}
            isLoading={isLoading}
            isRefetching={isRefetching}
            threads={threads}
          />
        )}
      </DialogContent>
      <ChatAction
        mentionedUser={isRepliesOpen ? mentionedUser : undefined}
        merchantId={merchantId}
        defaultMessage={queryObject.defaultMessage}
        shouldHideMentionIcon={queryObject.shouldHideMentionIcon}
        onMessageSubmit={onMessageSubmit}
        handleOpenConversationsModal={handleOpenConversationsModal}
      />
    </>
  );
};

export default Chat;
