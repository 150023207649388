import { AddButtonIcon } from "@assets/icons";
import { CloseIcon } from "@assets/rebrandIcons";
import { Button } from "@common/Button";
import { SelectProps } from "@common/Select/Select";
import { SelectOptionProps } from "@common/Select/types";
import { Image } from "@common/StyledImage/Image";
import { Text, TruncateText } from "@common/Text";
import {
  AssigneesType,
  useUpdateAssignee,
} from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import CustomAssignMenu from "@components/Merchants/MerchantPreview/sections/StatusReview/components/CustomAssignMenu";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
} from "@constants/queryKeys";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import { Box, Stack, StackProps } from "@mui/material";
import { palette } from "@palette";
import { checkPortals } from "@utils/routing";
import placeholder from "assets/images/profile-placeholder.png";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import React, { memo, useCallback, useState } from "react";
import { useQueryClient } from "react-query";

interface AssignmentSelectProps extends Omit<SelectProps, "options"> {
  data: MerchantData;
  action?: (assignee?: string | null) => void;
  onOpening?: (event: string, data?: any) => void;
  type?: AssigneesType;
}

const NewAssignmentSelect = ({
  data,
  action,
  onOpening,
  type = "underwriting",
}: AssignmentSelectProps) => {
  const { isAcquirerPortal } = checkPortals();
  const {
    accID: merchantID,
    underwriterID,
    underwriterName,
    underwriterImageURL,
    underwriterEmail,
  } = data;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const queryClient = useQueryClient();
  const { handleChangeAssignee } = useUpdateAssignee(merchantID);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      setAnchorEl(event.currentTarget);
      if (onOpening) {
        onOpening("toggle_tooltip", true);
      }
    },
    [],
  );

  const handleChange = (value: string | null) => {
    const newAssignee = value === "unassigned" ? null : value;
    const onSuccess = () => {
      if (action) action(newAssignee);
      queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_STATS);
      queryClient.refetchQueries(QKEY_LIST_MERCHANTS);
      queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
    };
    handleChangeAssignee(underwriterID, newAssignee, onSuccess);
  };

  if (!isAcquirerPortal && data?.underwriterSetByAcquirer)
    return (
      <Text
        fontSize={14}
        variant="body"
        fontWeight="book"
        lineHeight="16.8px"
        color={palette.gray[100]}
        sx={{
          cursor: "default",
        }}
        ml={"2px"}
      >
        Managed by the Acquirer
      </Text>
    );

  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();

  const renderValue = () => {
    if (!underwriterID)
      return (
        <Button
          disabled={!isUpdateUnderwriterAllowed}
          background="tertiary"
          startIcon={
            <AddButtonIcon
              width={19}
              height={19}
              stroke={palette.neutral[80]}
            />
          }
          sx={{
            padding: 0,
            color: palette.neutral[80],
          }}
          onClick={handleOpenMenu}
        >
          Assign
        </Button>
      );

    return (
      <SelectUser
        handleOpenMenu={handleOpenMenu}
        option={{
          userLabel: underwriterName || underwriterEmail,
          value: underwriterID || 0,
          imageUrl: underwriterImageURL,
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        ...containerStyle,
        "& .MuiOutlinedInput-root div": {
          fontWeight: 350,
          "&: hover": {
            color: `${palette.neutral[80]} !important`,
          },
        },
      }}
    >
      {renderValue()}
      <CustomAssignMenu
        anchorEl={anchorEl}
        handleClose={() => {
          setAnchorEl(null);
          if (onOpening) {
            onOpening("toggle_tooltip", false);
          }
        }}
        statusName={data?.statusName}
        handleChange={handleChange}
        underwriterID={underwriterID || 0}
        merchantId={merchantID}
        type={type}
      />
    </Box>
  );
};

const containerStyle = {
  width: "100%",
  "& .MuiInputBase-root": {
    backgroundColor: `transparent !important`,
    borderRadius: "16px !important",
    border: "none !important",
    padding: "0 !important",
    color: palette.neutral[50],
  },
};

export default memo(NewAssignmentSelect);

function SelectUser({
  option,
  wrapperProps,
  handleOpenMenu,
}: {
  option?: SelectOptionProps;
  wrapperProps?: StackProps;
  handleOpenMenu: (event: any) => void;
}) {
  return (
    <Box onClick={handleOpenMenu}>
      <Stack gap={1} alignItems="center" direction="row" {...wrapperProps}>
        <Image
          height={20}
          width={20}
          sx={{
            borderRadius: "50%",
          }}
          src={option?.imageUrl ? `${option.imageUrl}/thumb` : placeholder}
        />
        <TruncateText
          lineClamp={1}
          color={(theme) => theme.palette.neutral[90]}
        >
          {option?.userLabel}
        </TruncateText>
        {option?.selected && option?.value !== "unassigned" && (
          <>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              onClick={(e) => {
                e.stopPropagation();
                if (option.handleDeselect) option.handleDeselect();
              }}
              display="inline-flex"
              data-testid="deselect-assignee"
            >
              <CloseIcon height={20} width={20} />
            </Box>
          </>
        )}
      </Stack>{" "}
    </Box>
  );
}
