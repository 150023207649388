import React, { ElementType, memo } from "react";
import { animated, useSpring } from "react-spring";
import { getAnimationConfig } from "./config";
import { SxProps, TableRow, TableRowProps } from "@mui/material";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type Props = {
  Wrapper?: ElementType;
  height?: number;
  children?: React.ReactNode;
  index?: number;
  rowProps?: TableRowProps;
  shouldAnimate?: boolean;
  sx?: SxProps;
};

const GiveAnimatedListItem = ({
  Wrapper = animated(TableRow),
  height,
  children,
  rowProps,
  index = 0,
  shouldAnimate,
  ...props
}: Props) => {
  const config = getAnimationConfig(height || 50);

  const usedIndex = index > 20 ? index % 20 : index;
  const { isDesktopView } = useCustomThemeV2();

  const styles = useSpring({
    ...config,
    delay: 40 * (usedIndex + 1),
  });

  return (
    <Wrapper
      {...rowProps}
      {...props}
      style={shouldAnimate ? styles : {}}
      sx={{
        ...rowProps?.sx,
        ...props?.sx,
        ...(!isDesktopView && {
          WebkitTapHighlightColor: "transparent",
        }),
        borderBottom: "none",
      }}
    >
      {children}
    </Wrapper>
  );
};

export default memo(GiveAnimatedListItem);
