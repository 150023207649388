import NewReportView from "./NewReportView";
import { Dispatch, SetStateAction } from "react";
import { ContentViewTypes } from "../MatchReportModal";
import AddNewReportView from "./AddNewReportView";
import ReportDetailsView from "./ReportDetailsView";
import { ReportType } from "@components/Merchants/MerchantPreview/MATCH/types";

interface IComponentProps {
  setContentView: Dispatch<SetStateAction<ContentViewTypes>>;
  contentView: ContentViewTypes;
  merchantID: number;
  reportsData: ReportType[];
  reportDetailsViewData: ReportType | null;
  setReportDetailsViewData: Dispatch<SetStateAction<ReportType | null>>;
}

const ModalContentView = ({
  setContentView,
  contentView,
  merchantID,
  reportsData,
  reportDetailsViewData,
  setReportDetailsViewData,
}: IComponentProps) => {
  const handleChangeView = (view: ContentViewTypes) => setContentView(view);

  const handleSetDetailsView = (report: ReportType) => {
    setReportDetailsViewData(report);
    setContentView(ContentViewTypes.REPORT_DETAILS);
  };

  if (contentView === ContentViewTypes.ADD_NEW_REPORT) {
    return (
      <AddNewReportView
        merchantID={merchantID}
        handleChangeView={handleChangeView}
      />
    );
  }

  if (
    contentView === ContentViewTypes.REPORT_DETAILS &&
    reportDetailsViewData
  ) {
    return (
      <ReportDetailsView
        reportDetailsViewData={reportDetailsViewData}
        merchantID={merchantID}
      />
    );
  }

  return (
    <NewReportView
      handleChangeView={handleChangeView}
      handleSetDetailsView={handleSetDetailsView}
      reportsData={reportsData}
    />
  );
};

export default ModalContentView;
