import { Stack } from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react";
import { StyledGiveButton } from "./UnderwritingCard.style";
import { CustomToolTip as PermissionTooltip } from "componentsV2/Table/CustomTooltip";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import GiveAssignButton from "@shared/AssignButton/GiveAssignButton";
import { checkPortals } from "@utils/routing";

type TUnderwritingCardBody = {
  underwriterName?: string;
  underwriterEmail?: string;
  underwriterImageURL?: string;
  underwriterSetByAcquirer?: boolean;
  handleManageUnderwriting?: () => void;
  id: number;
  isOnboarding: boolean;
};

const UnderwritingCardBody = ({
  underwriterName,
  underwriterEmail,
  underwriterImageURL,
  handleManageUnderwriting,
  underwriterSetByAcquirer,
  id,
  isOnboarding,
}: TUnderwritingCardBody) => {
  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();
  const { isAcquirerPortal } = checkPortals();
  const hideAssignButton = underwriterSetByAcquirer && !isAcquirerPortal;

  return (
    <Stack direction="row" justifyContent="space-between">
      {hideAssignButton ? (
        <div />
      ) : (
        <GiveAssignButton
          title="Assign"
          data={{
            accID: id,
            underwriterName,
            underwriterImageURL,
            underwriterEmail,
            underwriterSetByAcquirer,
          }}
          showCaretIcon={false}
        />
      )}
      {handleManageUnderwriting && (
        <PermissionTooltip
          showToolTip={!isViewUnderwritingAllowed}
          message={ACTION_DENY_MESSAGE}
          placement="top"
          alignment="end"
          fullWidth={true}
        >
          <StyledGiveButton
            variant="filled"
            label={isOnboarding ? "Onboarding" : "Underwriting"}
            endIcon={<ArrowRight />}
            onClick={handleManageUnderwriting}
            disabled={!isViewUnderwritingAllowed}
          />
        </PermissionTooltip>
      )}
    </Stack>
  );
};

export default UnderwritingCardBody;
