import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import AccountStatusDropdown from "./components/AccountStatusDropdown";
import AccountStatusSettings from "./components/AccountStatusSettings";
import StatusSection from "./components/StatusSection";

const AccountStatusOverviewSection = () => {
  const { data } = useMerchantSidePanelContext();
  const statusData = data.status;
  const status = statusData.statusName;
  const shouldShowApproved =
    status === "suspended" &&
    statusData.approvedAt &&
    (statusData?.approvedByName || statusData?.approvedByEmail);

  return (
    <Stack spacing={2}>
      <Stack direction="row" gap="10px">
        <GiveText>Account Status Overview</GiveText>
        <AccountStatusDropdown />
      </Stack>
      <ContainerStack>
        <StatusSection
          statusUpdatedAt={statusData.statusUpdatedAt}
          by={
            statusData?.statusUpdatedByName?.trim() ||
            statusData?.statusUpdatedByEmail
          }
          imageURL={statusData.statusUpdatedByImageURL}
          status={status}
        />
        {shouldShowApproved && (
          <StatusSection
            statusUpdatedAt={statusData.approvedAt}
            by={
              statusData?.approvedByName?.trim() ||
              statusData?.approvedByEmail ||
              ""
            }
            imageURL={statusData?.approvedByImageUrl || ""}
            status="approved"
          />
        )}
        {statusData.statusName === "declined" && (
          <ReasonContainer>
            <GiveText color="secondary" variant="bodyS">
              Reason
            </GiveText>
            <GiveText variant="bodyS">
              {data?.status.elevatedRiskReason}
            </GiveText>
          </ReasonContainer>
        )}
        {statusData.statusName != "declined" && <AccountStatusSettings />}
      </ContainerStack>
    </Stack>
  );
};

const ContainerStack = styled(Stack)(({ theme }) => ({
  padding: "20px",
  borderRadius: "20px",
  backgroundColor: theme.palette?.surface?.primary,
  gap: "16px",
}));

const ReasonContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette?.primitive?.transparent?.["darken-5"],
}));

export default AccountStatusOverviewSection;
