import { styled } from "@theme/v2/Provider";
import { Stack } from "@mui/material";
import { UploadSimple } from "@phosphor-icons/react";
import {
  FundraiserIcon,
  MembershipIcon,
  MerchantIcon,
  MerchantIconLarge,
  ProviderIcon,
  ProviderIconLarge,
  SweepstakeIcon,
  EventIcon,
  FileIcon,
  ImageIcon,
  BuildingIcon,
  ProductIcon,
  InvoiceIcon
} from "@assets/rebrandIcons/GiveThumbnailIcons";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import { AvatarSizesProps } from "@shared/Avatar/GiveAvatar.types";

const ICON_TYPES = {
  product: { small: ProductIcon, large: ProductIcon },
  membership: { small: MembershipIcon, large: MembershipIcon },
  merchant: { small: MerchantIcon, large: MerchantIconLarge },
  provider: { small: ProviderIcon, large: ProviderIconLarge },
  sweepstake: { small: SweepstakeIcon, large: SweepstakeIcon },
  fundraiser: { small: FundraiserIcon, large: FundraiserIcon },
  invoice: { small: InvoiceIcon, large: InvoiceIcon },
  event: { small: EventIcon, large: EventIcon },
  file: { small: FileIcon, large: FileIcon },
  image: { small: ImageIcon, large: ImageIcon },
  building: { small: BuildingIcon, large: BuildingIcon },
};

export type ThumbnailType = keyof typeof ICON_TYPES;

export interface GiveThumbnailProps {
  size?: "small" | "large";
  type: ThumbnailType;
  imageUrl?: string;
  name?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const SIZE_STYLING = {
  small: {
    width: 40,
    height: 40,
    borderRadius: 8,
    iconSize: 16,
    avatarSize: "40px",
  },
  large: {
    width: 72,
    height: 72,
    borderRadius: 16,
    iconSize: 24,
    avatarSize: "72px",
  },
};

const GiveThumbnail = ({
  size = "small",
  type,
  name = "",
  imageUrl,
  disabled = true,
  onClick = () => null,
}: GiveThumbnailProps) => {
  const { borderRadius, iconSize, avatarSize } = SIZE_STYLING[size];
  const Icon = ICON_TYPES?.[type]?.[size];

  const handleClickPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick();
  };
  return (
    <Container size={size}>
      {imageUrl ? (
        <GiveAvatar
          imageUrl={imageUrl}
          name={name}
          shape="square"
          size={avatarSize as AvatarSizesProps}
        />
      ) : (
        <Icon />
      )}
      <Overlay
        disabled={disabled}
        borderRadius={borderRadius}
        onClick={handleClickPropagation}
      >
        <UploadSimple size={iconSize} />
      </Overlay>
    </Container>
  );
};

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: GiveThumbnailProps["size"] }>(({ theme, size = "small" }) => ({
  width: SIZE_STYLING[size].width,
  height: SIZE_STYLING[size].height,
  backgroundColor: theme.palette.surface?.tertiary,
  borderRadius: SIZE_STYLING[size].borderRadius,
  overflow: "hidden",
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
}));

const Overlay = styled(Stack)<{ disabled: boolean; borderRadius: number }>(
  ({ disabled, borderRadius, theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: "pointer",
    borderRadius: borderRadius,
    backgroundColor: "transparent",
    transition: "backgroundColor 0.3s ease",
    pointerEvents: disabled ? "none" : "auto",

    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",

    "& > svg": {
      fill: "transparent",
      transition: "fill 0.3s ease",
    },

    "&:hover": {
      backgroundColor: theme.palette.primitive?.transparent["darken-25"],
      "& > svg": {
        fill: theme.palette.primitive?.neutral[0],
      },
    },
  }),
);

export default GiveThumbnail;
