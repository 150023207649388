import { showMessage } from "@common/Toast";
import { parseAmount } from "@utils/index";
import { cloneDeep } from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useCartItems } from "../components/hooks/useCartMutations";
export interface ICartItem {
  id: string;
  productVariantName: string;
  productType?: string;
  productVariantID: string;
  productVariantPrice: number;
  quantity: number;
  unitPrice: number | string;
  productVariantImageURL: string;
  recurringIntervalName: string | any;
  in_stock?: number | null;
  description?: string;
}

interface CartContextProps {
  cartItems: ICartItem[];
  clearCart: () => void;
  addToCart: (
    item: ICartItem,
    addedQuantity?: number,
    quantityChangeType?: string,
    replace?: boolean,
    isFundraiser?: boolean,
  ) => void;
  removeFromCart: (ProductItemTypeId: string) => void;
  getItemInCart: (productId: string) => ICartItem | undefined;
  isCartEmpty: boolean;
  subTotal: string;
  totalAmount: number | string;
  totalItemsInCart: number;
  fees: number | null;
  setFees: (data: number) => void;
  displayFees: boolean;
  setDisplayFees: (value: boolean) => void;
  outOfStockItemIds: (string | number)[];
  setOutOfStockItemIds: (data: (string | number)[]) => void;
  setCartItems: any;
}

interface CartProviderProps {
  children: React.ReactNode;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState<{ [id: string]: ICartItem }>({});
  const [outOfStockItemIds, setOutOfStockItemIds] = useState<
    (string | number)[]
  >([]);

  const [fees, setFees] = useState<null | number>(null);
  const [displayFees, setDisplayFees] = useState<boolean>(false);

  const clearCart = () => {
    setCartItems({});
  };

  const addToCart = async (
    item: any,
    addedQuantity = 1,
    quantityChangeType = "increment",
    replace?: boolean,
    isFundraiser?: boolean,
  ) => {
    const existingItem =
      !isFundraiser && cloneDeep(cartItems[item.productVariantID]);

    let newQuantity = 0;
    if (replace || !existingItem) {
      newQuantity = addedQuantity;
    } else {
      newQuantity =
        quantityChangeType === "increment"
          ? existingItem.quantity + addedQuantity
          : existingItem.quantity - addedQuantity;
    }

    if (!item.in_stock || newQuantity <= item.in_stock) {
      setCartItems((prevState) => ({
        ...prevState,
        [item.productVariantID]: existingItem
          ? { ...existingItem, quantity: newQuantity }
          : {
              ...item,
              quantity: newQuantity,
            },
      }));
    } else {
      showMessage("Error", "Invalid quantity");
    }
  };

  const removeFromCart = (productId: string) => {
    setCartItems((prevState) => {
      const { [productId]: _, ...rest } = prevState;

      return rest;
    });

    if (outOfStockItemIds.length && outOfStockItemIds.includes(productId)) {
      setOutOfStockItemIds((prev) => prev.filter((item) => item !== productId));
    }
  };

  const getItemInCart = useCallback(
    (productId: string) => cartItems[productId],
    [cartItems],
  );

  const subTotal = Object.values(cartItems).reduce(
    (total, item) => total + Number(item.productVariantPrice) * item.quantity,
    0,
  );

  const totalAmount = parseAmount(subTotal + Number(displayFees ? fees : 0));

  const totalItemsInCart = Object.values(cartItems).reduce(
    (count, item) => count + item.quantity,
    0,
  );

  return (
    <CartContext.Provider
      value={{
        cartItems: Object.values(cartItems),
        clearCart,
        addToCart,
        removeFromCart,
        isCartEmpty: Object.values(cartItems).length === 0,
        getItemInCart,
        subTotal: parseAmount(subTotal),
        totalAmount,
        totalItemsInCart,
        fees,
        setFees,
        displayFees,
        setDisplayFees,
        outOfStockItemIds,
        setOutOfStockItemIds,
        setCartItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("use useCart within CartProvider wrapper");
  }
  return context;
};

export function useInitCartItems(isEnabled: boolean, variants: any) {
  const { setFees, setCartItems } = useCart();

  useCartItems(isEnabled, (cartData: any) => {
    if (cartData && variants?.length) {
      const variantIDs = variants?.map((item: any) => item.id);
      setFees(cartData?.fees / 100);
      // Filter out cart items that don't belong to this product
      const filtered = cartData.items
        ?.filter((item: any) => variantIDs.includes(item.productVariantID))
        .map((x: any) => ({
          ...x,
          in_stock: variants.find(
            (variant: any) => variant.id === x.productVariantID,
          )?.inventory,
        }));
      setCartItems(
        filtered
          ? filtered.reduce(
              (acc: any, v: any) => ({ ...acc, [v.productVariantID]: v }),
              {},
            )
          : {},
      );
    }
  });
}
