import { Skeleton, SkeletonOwnProps, Stack } from "@mui/material";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const skeletonDefaultProps: SkeletonOwnProps = {
  sx: { borderRadius: "16px" },
  variant: "rectangular",
};

export interface IPanelLoadingSkeletonProps {
  variant?: "default" | "underwriting" | "invite_sent" | "merchant_file";
}

interface TypeComponentsProps {
  isMobileView: boolean;
  variant: IPanelLoadingSkeletonProps["variant"];
}

const PanelLoadingSkeleton = ({
  variant = "default",
}: IPanelLoadingSkeletonProps) => {
  const { isMobileView } = useCustomThemeV2();
  if (variant === "invite_sent") return <InviteSentSkeletonVariant />;
  if (variant === "merchant_file") return <MerchantFileSkeleton />;
  if (variant === "underwriting") return <UnderwritingSkeleton />;
  return <BaseSkeletonVariant isMobileView={isMobileView} variant={variant} />;
};

const BaseSkeletonVariant = ({
  isMobileView,
  variant,
}: TypeComponentsProps) => {
  return (
    <Stack gap={4}>
      <ProfileSectionSkeleton />
      <GeneralSectionSkeleton />
      <GeneralSectionSkeleton
        showTitle={variant !== "underwriting"}
        bodyHeight={isMobileView ? 272 : 320}
      />
    </Stack>
  );
};

const InviteSentSkeletonVariant = () => {
  return (
    <Stack gap={4}>
      <Skeleton height="150px" width="100%" {...skeletonDefaultProps} />
      <ProfileSectionSkeleton />
      <GeneralSectionSkeleton bodyHeight={126} />
    </Stack>
  );
};

const ProfileSectionSkeleton = () => {
  return (
    <Stack direction="row" gap={3}>
      <Stack gap={2} flex={1}>
        <Skeleton height={25} width="100%" {...skeletonDefaultProps} />
        <Stack direction="row" gap={2}>
          <Skeleton height={25} width="50%" {...skeletonDefaultProps} />
          <Skeleton height={25} width="50%" {...skeletonDefaultProps} />
        </Stack>
      </Stack>
      <Skeleton
        height={66}
        width="10%"
        {...skeletonDefaultProps}
        sx={{ ...skeletonDefaultProps.sx, minWidth: "72px" }}
      />
    </Stack>
  );
};

const MerchantFileSkeleton = () => {
  return (
    <Stack gap={2} flex={1}>
      <ProfileSectionSkeleton />
      <Skeleton height="40px" width="280px" {...skeletonDefaultProps} />
      <Skeleton height="40px" width="100%" {...skeletonDefaultProps} />
      <Skeleton height="350px" width="100%" {...skeletonDefaultProps} />
    </Stack>
  );
};

const UnderwritingSkeleton = () => {
  return (
    <Stack margin="0px 20px 40px 20px" padding="20px 12px 12px 12px" flex={1}>
      <Skeleton height="100%" width="100%" {...skeletonDefaultProps} />
    </Stack>
  );
};

const GeneralSectionSkeleton = ({
  showTitle = true,
  bodyHeight = 250,
}: {
  showTitle?: boolean;
  bodyHeight?: number;
}) => {
  return (
    <Stack gap={2}>
      {showTitle && (
        <Skeleton height={40} width={283} {...skeletonDefaultProps} />
      )}
      <Skeleton height={bodyHeight} width="100%" {...skeletonDefaultProps} />
    </Stack>
  );
};

export default PanelLoadingSkeleton;
