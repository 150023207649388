import { UseQueryOptions, useMutation, useQuery } from "react-query";
import { customInstance } from "..";
import { ProductParams, useGetTransactionsFactory } from "./queryFactory";
import { useGetCurrentMerchantId } from "@hooks/common";
import { RecurringItem } from "@customTypes/recurring.items.types";
import { buildMerchantEndpoints } from "../utils.api";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { baseGetMerchantStats } from "../merchants";
import { DEFAULT_QUERY_CONFIG } from "@components/VirtualList/hooks/queries";

const modifyRecurring = (
  merchId: string | number,
  orderRecurringItemID: string,
  params: any,
) => {
  return customInstance({
    url: `merchants/${merchId}/recurring-items/${orderRecurringItemID}/`,
    method: "PATCH",
    data: params,
  });
};

const refund = ({ reason, transactionIDs, transactionItemIDs }: any) => {
  return customInstance({
    url: "/refunds",
    method: "POST",
    data: { reason, transactionIDs, transactionItemIDs },
  });
};

const cancelTransfer = ({ transactionId, cancel, customerID }: any) => {
  return customInstance({
    url: buildMerchantEndpoints(`transfers/${transactionId}`, customerID),
    method: "PATCH",
    data: { cancel: cancel },
  });
};

export const useModiFyRecurring = (orderRecurringItemID: string) => {
  const { merchantId } = useGetCurrentMerchantId();

  return useMutation((data: any) => {
    return modifyRecurring(merchantId, orderRecurringItemID, data);
  });
};

export const useGetAllTransactions = (
  queryKey = "get-all-transactions",
  path = "transactions",
) => useGetTransactionsFactory(path, queryKey);

export const useGetTransactionsByProduct =
  (id: string, queryKey: string) =>
  (
    { queryString, page, sorting, searchQuery }: ProductParams,
    options: Omit<
      UseQueryOptions<any, any, any, any>,
      "queryKey" | "queryFn"
    > = {},
  ) =>
    useGetTransactionsFactory(`products/${id}/transaction-items`, queryKey)(
      { queryString, page, sorting, searchQuery },
      options,
    );

export const useGetTransactionsByMerchant =
  (queryKey: string) =>
  (
    { queryString, page, sorting, searchQuery }: ProductParams,
    options: Omit<
      UseQueryOptions<any, any, any, any>,
      "queryKey" | "queryFn"
    > = {},
  ) =>
    useGetTransactionsFactory(`transactions`, queryKey)(
      { queryString, page, sorting, searchQuery },
      options,
    );

export const useGetSingleTransaction = (
  thxId: string,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  return useQuery(
    ["get-single-transaction", thxId],
    async () => {
      const data = await customInstance({
        url: buildMerchantEndpoints(`transactions/${thxId}`),
        method: "GET",
      });
      return data;
    },
    options,
  );
};

export const useGetStats = (merchId?: any) => {
  const { merchantId } = useGetCurrentMerchantId();
  const dispatch = useAppDispatch();
  const id = merchId ?? merchantId;

  return useQuery(
    [`get-transaction-stats`, id],
    async () => {
      const data = await baseGetMerchantStats(id);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 2,
      ...DEFAULT_QUERY_CONFIG,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              manage_money_stats: true,
            }),
          );
        }
      },
    },
  );
};

export const useRefund = () => {
  return useMutation((data: any) => {
    return refund(data);
  });
};

export const useCancelTransfer = () => {
  return useMutation((data: any) => {
    return cancelTransfer(data);
  });
};

export const useFindRecurringItem = (itemId: string, merchantId?: number) => {
  const dispatch = useAppDispatch();

  const getRecurringItem = (id: string) => {
    return customInstance({
      url: buildMerchantEndpoints(`recurring-items/${id}`, merchantId),
      method: "GET",
    });
  };

  return useQuery<RecurringItem>(
    ["get-recurring-item", itemId],
    async () => {
      const data = await getRecurringItem(itemId);
      return data;
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              modify_recurring_view: true,
            }),
          );
        }
      },
    },
  );
};

export const useUpdateRecurring = (itemId: string, merchant_id: number) => {
  const updateRecurrence = (id: string, data: any) => {
    return customInstance({
      url: `/merchants/${merchant_id}/recurring-items/${id}`,
      method: "PATCH",
      data,
    });
  };
  return useMutation((data: any) => {
    return updateRecurrence(itemId, data);
  });
};

export const getLastBlockedTransaction = () =>
  customInstance({
    url: buildMerchantEndpoints(
      `transactions?sort=-createdAt&page=1&max=1&filter=isBlocked:true%3BfirstCheckedAt:null%3BisFalsePositive:false`,
    ),
    method: "GET",
  });

export const setCheckedTransaction = async (
  merchantID: number,
  transactionID: string,
) =>
  customInstance({
    url: `merchants/${merchantID}/transactions/${transactionID}/read`,
    method: "PUT",
  });
