import NiceModal from "@ebay/nice-modal-react";
// components
import EnterpriseNotAvailableModal from "@components/AcquirerMerchants/Banner/EnterpriseNotAvailableModal";
import ViewTransaction from "@components/AcquirerMerchants/ViewTransaction/ViewTransaction";
import ApproveMerchantModal from "@components/Merchants/MerchantPreview/modals/ApproveMerchantPopup";
import DeclineMerchantModal from "@components/Merchants/MerchantPreview/modals/DeclineMerchantPopup";
import UnapprouvedBankAccountMessageModal from "@components/ManageMoney/UnapprouvedBankAccountMessageModal/UnapprouvedBankAccountMessageModal";
import CreateEnterprisePanel from "@components/AcquirerEnterprises/CreateEnterprise/CreateEnterprisePanel";

// names
import {
  ACQUIRER_VIEW_TRANSACTION_MODAL,
  ENTERPRISE_NOT_AVAILABLE_MODAL,
  APPROVE_MERCHANT_MODAL,
  DECLINE_MERCHANT_MODAL,
  EDIT_MERCHANT_INFO_MODAL,
  EDIT_PRIMARY_ACCOUNT_HOLDER,
  MANAGE_BANK_ACCOUNTS_MODAL,
  EDIT_MERCHANT_FEES_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  EDIT_BUSINESS_OWNER_MODAL,
  CREATE_MERCHANT_PANEL_MODAL,
  CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL,
  CREATE_BUSINESS_PROFILE_MODAL,
  UNAPPROUVED_BANK_ACCOUNT_MESSAGE_MODAL,
  TRANSACTION_RISK_MODAL,
  CREATE_ENTERPRISE_PANEL_MODAL,
  ENTERPRISE_PREVIEW_PANEL,
  EDIT_ENTERPRISE_INFO_MODAL,
  IP_PROBE_ADDITIONAL_INFO_MODAL,
  EDIT_ENTERPRISE_FEES_MODAL,
  EDIT_ENTERPRISE_MCC_MODAL,
  ENTERPRISE_CREATED,
  DISCARD_CHANGE_MODAL,
  TEAM_MEMBER_PANEL_MODAL,
  CREATE_MERCHANT_AGREEMENT_MODAL,
  SUSPEND_MERCHANT_MODAL,
  BUSINESS_PROFILE_MISSING_FIELDS_MODAL,
  MERCHANT_AGREEMENT_UPDATE_MODAL,
  MERCHANT_AGREEMENT_PANEL,
  PEP_CONFIRM_MATCH_MODAL,
  NOTIFY_MERCHANT_MODAL,
  OFAC_CONFIRM_MATCH_MODAL,
  CONFIRMATION_POP_UP,
  CHANGE_PROFILE_STATUS_MODAL,
  NOTIFY_MERCHANT_V2,
  ENTERPRISE_CONFIGURATION_MODAL,
  UPDATE_PRIMARY_ACCOUNT_HOLDER_EMAIL,
  MANAGE_BUSINESS_OWNERS_MODAL,
  DELETE_CONFIRMATION_MODAL,
  TRANSFER_FILTER_PANEL,
  EXPEDITE_TRANSFER_MODAL,
  MERCHANT_BULK_ACTIONS_MODAL,
  SPONSOR_DECLINE_MERCHANT_MODAL,
  MARK_MERCHANT_AS_PENDING_MODAL,
  MERCHANT_INITIAL_APPROVAL_MODAL,
  DISPUTE_PREVIEW_PANEL,
  RESPOND_TO_DISPUTE_MODAL,
  DISPUTE_ADD_A_NOTE_MODAL,
  DISPUTE_CONFIRM_SUBMISSION_POPUP,
  DISPUTE_FRAUD_MODAL,
  CHALLENGES_GENERAL_MODAL,
  EVIDENCE_PROVIDED_MODAL,
  DISPUTES_FILTER_PANEL,
  FILTER_PROVIDER_PANEL,
  CHANGE_PROVIDER_MODAL,
  APPROVE_MERCHANT_MODAL_REBRAND,
  EDIT_MERCHANT_INFO_MODAL_V2,
  NEW_MERCHANT_AGREEMENT_MODAL,
  INVITE_PAH_MODAL,
  DECLINE_MERCHANT_MODAL_REBRAND,
  MEMBER_MOBILE,
  GIVE_CONFIRMATION_POP_UP,
  BASE_CHALLENGE_MODAL,
  EDD_CHALLENGE_MODAL,
} from "./modal_names";
import EditMerchantInfoModal from "@components/Merchants/MerchantPreview/modals/EditMerchantInfoModal";
import EditMerchantInfoModalV2 from "features/Merchants/MerchantSidePanel/Modals/EditMerchantInfoModalV2";
import EditPrimaryAccountHolder from "@components/Merchants/MerchantPreview/modals/EditPrimaryAccountHolder";
import ManageBankAccountsModal from "@components/Merchants/MerchantPreview/modals/ManageBankAccountsModal";
import EditFees from "@components/Merchants/MerchantPreview/modals/EditFees";
import EditBankAccountModal from "@components/Merchants/MerchantPreview/modals/EditBankAccountModal";
import EditBusinessOwnerModal from "@components/Merchants/MerchantPreview/modals/EditBusinessOwnerModal";
import CreateMerchantPanel from "@components/Merchants/CreateMerchantPanel";
import CreatePrimaryAccountHolder from "@components/Merchants/CreateMerchantPanel/modals/CreatePrimaryAccountHolderModal";
import CreateBusinessProfileModal from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import TransactionRiskModal from "features/TransactionsRiskProfile/TransactionRiskModal";
import EnterprisePreviewPanel from "@components/AcquirerEnterprises/EnterprisePreview/EnterprisePreviewPanel";
import EditEnterpriseInfoModal from "@components/AcquirerEnterprises/EnterprisePreview/modals/EditEnterpriseInfoModal";
import AdditionalInfoModal from "features/TransactionsRiskProfile/components/AdditionalInfoModal";
import EditEnterpriseFees from "@components/AcquirerEnterprises/EnterprisePreview/modals/EditEnterpriseFees";
import EditEnterpriseMCC from "@components/AcquirerEnterprises/EnterprisePreview/modals/EditEnterpriseMCC";
import EnterpiseCreated from "@components/AcquirerEnterprises/CreateEnterprise/modals/EnterpiseCreated";
import DiscardChangeModal from "@components/AcquirerEnterprises/CreateEnterprise/modals/DiscardChangeModal";
import TeamMemberPanel from "features/Permissions/ModalV2/TeamMemberPanel";
import CreateMerchantAgreementModal from "@components/Merchants/CreateMerchantPanel/modals/CreateMerchantAgreementModal";
import SuspendMerchantModal from "@components/Merchants/MerchantPreview/modals/SuspendMerchantPopup";
import BusinessProfileMissingFieldsPopup from "@components/Merchants/MerchantPreview/modals/BusinessProfileMissingFieldsPopup";
import MerchantAgreementUpdate from "@components/Merchants/MerchantPreview/modals/MerchantAgreementUpdate";
import MerchantAgreementPanel from "@components/Merchants/MerchantPreview/MerchantAgreement/MerchantAgreementModal";
import PepConfirmMatchModal from "@components/Merchants/MerchantPreview/PEP/modals/PepConfirmMatchModal";
import NotifyMerchantModal from "@components/Merchants/MerchantPreview/modals/NotifyMerchantModal";
import OFACConfirmMatchModal from "@components/Merchants/MerchantPreview/OFAC/components/OFACConfirmMatchModal";
import ConfirmationPopUp from "@components/Settings/BusinessDetails/components/BusinessProfile/Modals/ConfirmationPopUp";
import ChangeProfileStatusModal from "@components/Settings/BusinessDetails/components/BusinessProfile/Modals/ChangeProfileStatusModal";
import NotifyMerchantV2 from "@components/Merchants/modals/NotifyMerchant/NotifyMerchantV2";
import EnterpriseConfigurationModal from "@components/AcquirerEnterprises/CreateEnterprise/modals/EnterpriseConfigurationModal";
import UpdatePrimaryAcccountHolderEmail from "@components/Merchants/MerchantPreview/modals/UpdatePrimaryAccountHolderEmail";
import ManageBuisnessOwnersModal from "@components/Merchants/MerchantPreview/modals/ManageBuisnessOwnersModal";
import DeleteConfirmationModal from "@common/Modal/DeleteModal/DeleteConfirmationModal";
import TransferFilterPanel from "@components/Tranfers/modals/TransferFilterPanel";
import ExpediteTransferModal from "@components/ManageMoney/TransactionTable/TableActions/ExpediteTransferModal";
import MerchantBulkActionsModal from "@components/AcquirerMerchants/MerchantList/BulkActions/MerchantBulkActionsModal";
import SponsorDeclineMerchantModal from "@components/Merchants/MerchantPreview/sections/SponsorApproval/SponsorDeclineMerchantModal";
import MarkMerchantAsPendingModal from "@components/Merchants/MerchantPreview/sections/SponsorApproval/MarkMerchantAsPendingModal";
import MerchantInitialApprovalModal from "@components/Merchants/MerchantPreview/modals/MerchantInitialApprovalModal";
import DisputePreviewPanel from "@components/Disputes/DisputePreview/DisputePreviewPanel";
import RespondToDisputeModal from "@components/Disputes/RespondToDispute/RespondToDisputeModal";
import DisputeFraud from "@components/Disputes/DisputePreview/components/DisputeFraud";
import DisputeAddANoteModal from "@components/Disputes/RespondToDispute/modals/DisputeAddANoteModal";
import DisputeConfirmSubmissionPopup from "@components/Disputes/RespondToDispute/modals/ConfirmSubmissionPopup";
import ChallengesGeneralModal from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/ChallengesGeneralModal";
import EvidenceProvidedModal from "@components/Disputes/DisputePreview/modals/EvidenceProvidedModal/EvidenceProvidedModal";
import DisputesFilterPanel from "@components/Disputes/DisputesList/modal/DisputesFilterPanel";
import ProviderFilterModal from "@pages/AcquirerPortal/Enterprises/Modal/ProviderFilterModal";
import ChangeProviderModal from "@components/Merchants/CreateMerchantPanel/components/modals/ChangeProviderModal";
import ApproveMerchantModalRebrand from "features/Merchants/MerchantSidePanel/Modals/ApproveMerchantModal";
import MerchantAgreementModal from "features/Merchants/MerchantSidePanel/Modals/MerchantAgreementModal";
import InvitePAHModal from "../features/Merchants/MerchantSidePanel/Modals/InvitePAHModal";
import DeclineMerchantModalRebrand from "features/Merchants/MerchantSidePanel/Modals/DeclineMerchantModal";
import { TeamMemberModal } from "@components/Settings/ManageTeam/Actions";
import GiveConfirmationPopUp from "features/Merchants/MerchantSidePanel/Modals/GiveConfirmationPopUp";
import BaseChallengeModal from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/Modals/BaseChallengeModal";
import EDDChallengeModal from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/Modals/EDDChallengeModal";

NiceModal.register(GIVE_CONFIRMATION_POP_UP, GiveConfirmationPopUp);
NiceModal.register(ACQUIRER_VIEW_TRANSACTION_MODAL, ViewTransaction);
NiceModal.register(ENTERPRISE_NOT_AVAILABLE_MODAL, EnterpriseNotAvailableModal);
NiceModal.register(APPROVE_MERCHANT_MODAL, ApproveMerchantModal);
NiceModal.register(NEW_MERCHANT_AGREEMENT_MODAL, MerchantAgreementModal);
NiceModal.register(APPROVE_MERCHANT_MODAL_REBRAND, ApproveMerchantModalRebrand);
NiceModal.register(
  MERCHANT_INITIAL_APPROVAL_MODAL,
  MerchantInitialApprovalModal,
);
NiceModal.register(DECLINE_MERCHANT_MODAL, DeclineMerchantModal);
NiceModal.register(DECLINE_MERCHANT_MODAL_REBRAND, DeclineMerchantModalRebrand);
NiceModal.register(SPONSOR_DECLINE_MERCHANT_MODAL, SponsorDeclineMerchantModal);
NiceModal.register(MARK_MERCHANT_AS_PENDING_MODAL, MarkMerchantAsPendingModal);
NiceModal.register(SUSPEND_MERCHANT_MODAL, SuspendMerchantModal);
NiceModal.register(EDIT_MERCHANT_INFO_MODAL, EditMerchantInfoModal);
NiceModal.register(EDIT_MERCHANT_INFO_MODAL_V2, EditMerchantInfoModalV2);
NiceModal.register(EDIT_PRIMARY_ACCOUNT_HOLDER, EditPrimaryAccountHolder);
NiceModal.register(
  UPDATE_PRIMARY_ACCOUNT_HOLDER_EMAIL,
  UpdatePrimaryAcccountHolderEmail,
);
NiceModal.register(MANAGE_BANK_ACCOUNTS_MODAL, ManageBankAccountsModal);
NiceModal.register(EDIT_MERCHANT_FEES_MODAL, EditFees);
NiceModal.register(EDIT_BANK_ACCOUNT_MODAL, EditBankAccountModal);
NiceModal.register(EDIT_BUSINESS_OWNER_MODAL, EditBusinessOwnerModal);
NiceModal.register(MANAGE_BUSINESS_OWNERS_MODAL, ManageBuisnessOwnersModal);
NiceModal.register(
  BUSINESS_PROFILE_MISSING_FIELDS_MODAL,
  BusinessProfileMissingFieldsPopup,
);

NiceModal.register(CREATE_MERCHANT_PANEL_MODAL, CreateMerchantPanel);
NiceModal.register(CREATE_ENTERPRISE_PANEL_MODAL, CreateEnterprisePanel);
NiceModal.register(
  CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL,
  CreatePrimaryAccountHolder,
);
NiceModal.register(INVITE_PAH_MODAL, InvitePAHModal);
NiceModal.register(CREATE_BUSINESS_PROFILE_MODAL, CreateBusinessProfileModal);
NiceModal.register(
  CREATE_MERCHANT_AGREEMENT_MODAL,
  CreateMerchantAgreementModal,
);
NiceModal.register(MERCHANT_AGREEMENT_UPDATE_MODAL, MerchantAgreementUpdate);
NiceModal.register(
  UNAPPROUVED_BANK_ACCOUNT_MESSAGE_MODAL,
  UnapprouvedBankAccountMessageModal,
);
NiceModal.register(TRANSACTION_RISK_MODAL, TransactionRiskModal);

NiceModal.register(ENTERPRISE_PREVIEW_PANEL, EnterprisePreviewPanel);
NiceModal.register(EDIT_ENTERPRISE_INFO_MODAL, EditEnterpriseInfoModal);
NiceModal.register(IP_PROBE_ADDITIONAL_INFO_MODAL, AdditionalInfoModal);
NiceModal.register(EDIT_ENTERPRISE_FEES_MODAL, EditEnterpriseFees);
NiceModal.register(EDIT_ENTERPRISE_MCC_MODAL, EditEnterpriseMCC);
NiceModal.register(ENTERPRISE_CREATED, EnterpiseCreated);
NiceModal.register(DISCARD_CHANGE_MODAL, DiscardChangeModal);

NiceModal.register(TEAM_MEMBER_PANEL_MODAL, TeamMemberPanel);

NiceModal.register(MERCHANT_AGREEMENT_PANEL, MerchantAgreementPanel);
NiceModal.register(MERCHANT_BULK_ACTIONS_MODAL, MerchantBulkActionsModal);

NiceModal.register(PEP_CONFIRM_MATCH_MODAL, PepConfirmMatchModal);
NiceModal.register(NOTIFY_MERCHANT_MODAL, NotifyMerchantModal);
NiceModal.register(OFAC_CONFIRM_MATCH_MODAL, OFACConfirmMatchModal);
NiceModal.register(CONFIRMATION_POP_UP, ConfirmationPopUp);
NiceModal.register(CHANGE_PROFILE_STATUS_MODAL, ChangeProfileStatusModal);
NiceModal.register(NOTIFY_MERCHANT_V2, NotifyMerchantV2);

NiceModal.register(
  ENTERPRISE_CONFIGURATION_MODAL,
  EnterpriseConfigurationModal,
);
NiceModal.register(DELETE_CONFIRMATION_MODAL, DeleteConfirmationModal);

NiceModal.register(TRANSFER_FILTER_PANEL, TransferFilterPanel);
NiceModal.register(DISPUTES_FILTER_PANEL, DisputesFilterPanel);
NiceModal.register(EXPEDITE_TRANSFER_MODAL, ExpediteTransferModal);

NiceModal.register(DISPUTE_PREVIEW_PANEL, DisputePreviewPanel);
NiceModal.register(RESPOND_TO_DISPUTE_MODAL, RespondToDisputeModal);
NiceModal.register(EVIDENCE_PROVIDED_MODAL, EvidenceProvidedModal);
NiceModal.register(DISPUTE_ADD_A_NOTE_MODAL, DisputeAddANoteModal);
NiceModal.register(
  DISPUTE_CONFIRM_SUBMISSION_POPUP,
  DisputeConfirmSubmissionPopup,
);

NiceModal.register(DISPUTE_FRAUD_MODAL, DisputeFraud);
NiceModal.register(CHALLENGES_GENERAL_MODAL, ChallengesGeneralModal);
NiceModal.register(FILTER_PROVIDER_PANEL, ProviderFilterModal);
NiceModal.register(CHALLENGES_GENERAL_MODAL, ChallengesGeneralModal);
NiceModal.register(CHANGE_PROVIDER_MODAL, ChangeProviderModal);
NiceModal.register(BASE_CHALLENGE_MODAL, BaseChallengeModal);
NiceModal.register(EDD_CHALLENGE_MODAL, EDDChallengeModal);

NiceModal.register(MEMBER_MOBILE, TeamMemberModal);
