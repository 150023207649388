import NiceModal, { useModal } from "@ebay/nice-modal-react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { ArrowLeft, ChatsCircle } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import ModalContentView from "./components/ModalContentView";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useMemo, useState } from "react";
import PortalFooterButtons from "./components/PortalFooterButtons";
import { Stack } from "@mui/system";
import GiveText from "@shared/Text/GiveText";
import { ReportType } from "@components/Merchants/MerchantPreview/MATCH/types";
import { useGetMATCHReports } from "@components/Merchants/MerchantPreview/MATCH/hooks/useMATCHReports";
import moment from "moment/moment";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";

export enum ContentViewTypes {
  MATCH = "match",
  ADD_NEW_REPORT = "add-new-report",
  REPORT_DETAILS = "report-details",
}

interface IComponentProps {
  merchantID: number;
  defaultReportsData: ReportType[];
}

const MatchReportModal = NiceModal.create(
  ({ merchantID, defaultReportsData }: IComponentProps) => {
    const [contentView, setContentView] = useState<ContentViewTypes>(
      ContentViewTypes.MATCH,
    );
    const { data: reportsData = defaultReportsData } =
      useGetMATCHReports(merchantID);
    const [reportDetailsViewData, setReportDetailsViewData] =
      useState<ReportType | null>(null);
    const { openConversationHandler } = useConversationsModal();

    const { remove, visible } = useModal();
    const { isMobileView } = useCustomThemeV2();
    const onClose = () => {
      remove();
    };

    const modalTitle = useMemo(() => {
      switch (contentView) {
        case ContentViewTypes.MATCH:
          return "MATCH";
        case ContentViewTypes.ADD_NEW_REPORT:
          return "Add New Report";
        default:
          return "";
      }
    }, [contentView]);

    const headerLeftContent = useMemo(() => {
      switch (contentView) {
        case ContentViewTypes.REPORT_DETAILS:
          return (
            <Stack direction="row" gap={1} alignItems="center">
              <GiveText color="secondary">MATCH / </GiveText>
              {reportDetailsViewData && (
                <GiveText>
                  {moment(reportDetailsViewData.createdAt * 1000).format(
                    "MMM D, YYYY",
                  )}{" "}
                </GiveText>
              )}
            </Stack>
          );
        default:
          return <></>;
      }
    }, [contentView]);

    const handleReturnToBaseView = () => setContentView(ContentViewTypes.MATCH);
    const handleOpenMatchConversation = () => {
      openConversationHandler({ id: 0, name: "MATCH Check", paths: [] });
    };
    return (
      <GiveBaseModal
        title={modalTitle}
        open={visible}
        onClose={onClose}
        width="560px"
        showFooter={contentView === ContentViewTypes.ADD_NEW_REPORT}
        PaperProps={{
          sx: {
            ...(isMobileView ? { height: "auto !important" } : {}),
          },
        }}
        headerRightContent={
          <GiveIconButton
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenMatchConversation();
            }}
            Icon={ChatsCircle}
          />
        }
        headerLeftContent={
          contentView !== ContentViewTypes.MATCH && (
            <>
              <GiveIconButton
                variant="ghost"
                onClick={handleReturnToBaseView}
                Icon={ArrowLeft}
                data-testid="match-report-return-arrow"
              />
              {headerLeftContent}
            </>
          )
        }
        buttons={<PortalFooterButtons />}
      >
        <ModalContentView
          setContentView={setContentView}
          contentView={contentView}
          merchantID={merchantID}
          reportsData={reportsData}
          setReportDetailsViewData={setReportDetailsViewData}
          reportDetailsViewData={reportDetailsViewData}
        />
      </GiveBaseModal>
    );
  },
);

export default MatchReportModal;
