import SidePanelTitle from "@shared/SidePanel/components/SidepanelTitle/SidePanelTitle";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import PAHInfoSection from "./components/PAHInfoSection";
import { useEffect } from "react";

const InviteSentPanelContent = () => {
  const { data, id, setIsLoadingUnderwriting, isLoadingUnderwriting } =
    useMerchantSidePanelContext();
  const theme = useAppTheme();
  const imageUrl = data?.header?.imageURL;

  useEffect(() => {
    setIsLoadingUnderwriting(false);
  }, [isLoadingUnderwriting]);

  const inviteSentMessage = `The invitation has been sent to ${
    data?.primaryAccountHolder?.email || ""
  }. We're now waiting for them to complete the onboarding process. If you need to change the primary account holder, feel free to update the email and resend the invitation.`;
  return (
    <>
      <MessageContainerStack>
        <IconBox>
          <PaperPlaneTilt
            size={24}
            color={theme.palette?.icon?.["icon-primary"]}
          />
        </IconBox>
        <Stack gap="10px">
          <GiveText>Invitation sent</GiveText>
          <InviteMessageText variant="bodyS" color="secondary">
            {inviteSentMessage}
          </InviteMessageText>
        </Stack>
      </MessageContainerStack>
      <SidePanelTitle
        panelType="merchantSidePanel"
        title={data?.merchantInfo?.merchantName}
        imageUrl={imageUrl ? imageUrl + "/thumb" : undefined}
        id={data?.merchantInfo?.merchantID}
      />
      <PAHInfoSection data={data} id={id} />
    </>
  );
};

const MessageContainerStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "12px",
  backgroundColor: theme.palette?.surface?.tertiary,
  padding: "20px",
  borderRadius: "20px",
}));

const IconBox = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: "50%",
  padding: "8px",
  backgroundColor: theme.palette?.surface?.secondary,
}));

const InviteMessageText = styled(GiveText)(() => ({
  overflowWrap: "break-word",
  wordBreak: "break-word",
}));

export default InviteSentPanelContent;
