import { Button } from "@common/Button";
import { Text } from "@common/Text";
import useResendInvite from "@hooks/merchant-api/manage-team/useResendInvite";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { TMerchantInvitation } from "../data.types";
import { useUpdateMerchantInfo } from "../hooks/useUpdateMerchantInfo";
import { isEmpty } from "lodash";
import PreviewInvitationButton from "@components/Merchants/Common/PreviewInvitationButton/PreviewInvitationButton";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import NewTag, {
  BusinessProfileStatusType,
} from "@common/Tag/BusinessProfileTag";
import { PaperPlaneRight } from "@phosphor-icons/react";
import {
  LONG_COOLDOWN_MESSAGE,
  useResendButtonState,
} from "../hooks/useResendButtonState";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useGetCurrentMerchantId } from "@hooks/common";
import { millisecondsToHHMMSS } from "@utils/index";

type Props = {
  data: TMerchantInvitation;
  id: number;
  pendingEmail?: string;
  merchantName?: string;
  providerName?: string;
  provideImageUrl?: string;
  isEnterprise?: boolean;
  memberStatus?: string;
  inviteStatusDisplayName?: string;
  pahStatus?: string;
};

const SendInvitation = ({
  data,
  id,
  pendingEmail,
  merchantName,
  providerName,
  isEnterprise,
  provideImageUrl,
  memberStatus,
  inviteStatusDisplayName,
  pahStatus,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const { handleSubmit } = useUpdateMerchantInfo(id);
  const { sendInvite } = useResendInvite();
  const { selectedUser } = useGetCurrentMerchantId();
  const { merchType, userRole } = selectedUser || {};
  const isMember =
    (merchType === "enterprise" || merchType === "provider") &&
    userRole === "member";

  const {
    isDisableResend,
    setIsDisableResend,
    countdown,
    remainingTimeRef,
    isLongCooldownActive,
    inviteButtonText,
    shouldSendNewEmail,
  } = useResendButtonState({
    cooldownEndsAt: data?.cooldownEndsAt,
    sentEmailCount: data?.sentEmailCount,
    inviteStatus: data?.inviteStatus,
  });

  const handleResend = () => {
    if (!data?.inviteId || shouldSendNewEmail) {
      handleSubmit("invitation", id);
    } else {
      sendInvite(id, data?.inviteId);
    }
    setIsDisableResend(true);
  };

  const isSendInvitationAllowed = useAccessControl({
    resource: composePermission(
      isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.INVITE,
    ),
    operation: OPERATIONS.CREATE,
  });

  const isPahApproved = pahStatus === "approved";
  const hidden: boolean = isPahApproved
    ? true
    : !isEmpty(pendingEmail) && data?.emailIsVerified
    ? false
    : data?.emailIsVerified;

  const isResendButtonDisabled =
    isDisableResend || data?.noOwner || !isSendInvitationAllowed || isMember;

  return (
    <Container
      hidden={hidden}
      inviteStatus={data?.inviteStatus}
      data-testid="PAH-invitation-container"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Text fontWeight="book" color={palette.neutral[600]}>
          Invitation
        </Text>
        {memberStatus === "invited" && (
          <NewTag
            statusCode={inviteStatusDisplayName as BusinessProfileStatusType}
          />
        )}
      </Stack>
      <Stack
        direction={
          isMobileView && data.inviteStatus === "invited" ? "column" : "row"
        }
        alignItems={
          isMobileView && data.inviteStatus === "invited"
            ? "flex-start"
            : "center"
        }
        justifyContent="center"
        gap="4px"
      >
        <StyledButton
          tooltipProps={{
            show: !isSendInvitationAllowed || isLongCooldownActive || isMember,
            message: isLongCooldownActive
              ? LONG_COOLDOWN_MESSAGE
              : FEATURE_DENY_MESSAGE,
          }}
          background="secondary"
          endIcon={
            !isResendButtonDisabled ? (
              <PaperPlaneRight width={14} height={16} />
            ) : null
          }
          disabled={isResendButtonDisabled}
          onClick={handleResend}
        >
          {inviteButtonText}{" "}
          {remainingTimeRef.current <= 0
            ? null
            : `in ${millisecondsToHHMMSS(countdown, isLongCooldownActive)}`}
        </StyledButton>
        <PreviewInvitationButton
          title="Preview"
          background="text"
          isLinkButton
          receiverName={isEnterprise ? merchantName : ""}
          senderName={isEnterprise ? providerName : merchantName}
          senderLogo={provideImageUrl}
          isSendingToProvider={Boolean(isEnterprise)}
        />
      </Stack>
    </Container>
  );
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "inviteStatus" && prop !== "hidden",
})<BoxProps & { hidden: boolean; inviteStatus: string }>(
  ({ hidden, theme, inviteStatus }) => ({
    display: hidden ? "none" : "flex",
    alignItems: "center",
    paddingBottom: "16px",
    borderBottom: `1px solid ${palette.neutral[20]}`,

    [theme.breakpoints.up("sm")]: {
      "& > div": {
        flexGrow: 1,
      },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      alignItems: inviteStatus === "invited" ? "flex-start" : "center",
    },
  }),
);

const Tag = styled(Text)(() => ({
  fontSize: "12px",
  padding: "2px 8px",
  borderRadius: "4px",
  color: palette.tag.success.hover,
  background: palette.tag.success.bg,
}));

const StyledButton = styled(Button)(() => ({
  fontSize: 14,
  padding: "8px 24px",
  minWidth: 0,
  maxHeight: "32px",
  gap: "4px",
  "&:disabled": {
    color: palette.gray[100],
  },
}));

export default SendInvitation;
