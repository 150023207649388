import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useForm } from "react-hook-form";
import { showMessage } from "@common/Toast";
import NiceModal from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetPaymentFormInfos } from "@hooks/payment-forms/useListPaymentForms";
import { QFORM_QUERY_KEY } from "@pages/AcquirerPortal/Enterprises/Modal/constants";
import { useAppSelector } from "@redux/hooks";
import { selectPaymentFormID } from "@redux/slices/products";
import { customInstance } from "@services/api";
import { getSettledPromise, rgbaToHex } from "@utils/index";
import {
  CAMPAIGN_DETAILS_MODAL,
  INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  SAVE_MINI_BUILDER_DRAFT,
} from "modals/modal_names";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { PaymentRecurringType, PendingActionType } from "../types";
import {
  formatVariantsToMatchPayload,
  FormDataType,
  formTypeToUrlMap,
  generateFormData,
  generatePayloadForCheckout,
  generatePayloadForProduct,
  getInitialFormData,
} from "../utils";
import { usePayBuilderContext } from "./PayBuilderContext";
import { Variant } from "./provider.type";
import { useInitCartItems } from "./CartContext";
import { useIsEnabled } from "../components/hooks/useHelpers";
import { isEmpty } from "lodash";
import useCheckFormType, {
  FormType,
} from "../components/hooks/useCheckFormType";
import { getCampaignModal } from "features/Minibuilders/hooks";

const getStartIndex = (stop?: string) => {
  switch (stop) {
    case "launch":
      return 4;
    case "checkout_configuration":
      return 3;
    case "style_configuration":
      return 2;
    case "variants_creation":
      return 1;
    default:
      return 0;
  }
};

export default function useManagePayFormProvider() {
  const { id } = useParams();

  const queryClient = useQueryClient();
  const { formType } = useCheckFormType();
  const methods = useForm<FormDataType>({
    reValidateMode: "onChange",
    resolver: yupResolver(
      formType === "event" ? schema.concat(eventSchema) : schema,
    ),
    defaultValues: getInitialFormData(formType),
  });
  const { merchantId } = useGetCurrentMerchantId();
  const { isLastStep } = usePayBuilderContext();
  const location = useLocation();
  const prevPath = location.state?.prevUrl;
  const navigate = useNavigate();
  const { setLastStepCompleted, lastStepCompleted } = usePayBuilderContext();
  const { isADBEnabled, isPayBuilderEnabled } = useGetFeatureFlagValues();

  const peekedFormId = useAppSelector(selectPaymentFormID);

  /* TODO: we should remove all the instances of calling this hook in another files.
  in some cases id should be 
  */
  const {
    data,
    isLoading: isPaymentFormInfosLoading,
    isFetched,
  } = useGetPaymentFormInfos(id || location.state?.id || peekedFormId);
  // Check if the current route matches either `/:id` or `/:id/checkout` to enable get cart request
  const isEnabled = useIsEnabled();

  useInitCartItems(isEnabled, data?.variants);

  useEffect(() => {
    if (isFetched) {
      const formData = generateFormData(data as any);

      methods.reset(formData);
      setLastStepCompleted?.(getStartIndex(formData.stopStep));
    }
  }, [isPaymentFormInfosLoading, isFetched]);

  const { mutate, isLoading } = useMutation(
    async ({
      cb,
      publish,
      shouldNavigate = true,
    }: {
      cb?: () => void;
      publish?: boolean;
      shouldNavigate?: boolean;
    }) => {
      const values = methods.watch();
      const cachedId = (queryClient.getQueryData(QFORM_QUERY_KEY) as any)
        ?.product?.id;
      const cachedCheckooutId = (
        queryClient.getQueryData(QFORM_QUERY_KEY) as any
      )?.checkout?.value?.id;
      const productId = location.state?.id || cachedId;
      const isPatch = !!productId;

      try {
        const productResponse = await customInstance({
          url: isPatch
            ? `/merchants/${merchantId}/products/${productId}`
            : `/merchants/${merchantId}/products`,
          method: isPatch ? "PATCH" : "POST",
          data: generatePayloadForProduct(
            { ...values, stopStep: lastStepCompleted },
            getInitialFormData(formType),
            formType,
          ),
        });

        const dbProductId = productResponse?.id || productId;

        const variantsToUpdate = formatVariantsToMatchPayload(
          values?.Items?.filter((item) => item.variantID),
        );

        const variantsToCreate = formatVariantsToMatchPayload(
          formType === FormType.FUNDRAISERS
            ? [
                {
                  amount: null,
                  description: "",
                  display: false,
                  id: "1",
                  in_stock: null,
                  paymentType: "once" as PaymentRecurringType,
                  thumbnail: "",
                  title: "Any Amount",
                  allowCustomPrice: true,
                },
              ]
            : values?.Items?.filter((item) => !item.variantID),
        );
        const variantsToDelete = values.variantsToDelete?.map(
          (v) => v.variantID,
        );

        const variantsURL = `/merchants/${merchantId}/products/${dbProductId}/variants`;
        const checkoutId =
          data?.checkout?.customCheckoutForm?.id || cachedCheckooutId;
        const isCheckoutPost = !checkoutId;
        const checkoutUrl = isCheckoutPost
          ? `/merchants/${merchantId}/products/${dbProductId}/checkout-forms`
          : `/merchants/${merchantId}/products/${dbProductId}/checkout-forms/${checkoutId}`;
        const nexRequests = [
          variantsToUpdate.length > 0
            ? customInstance({
                url: variantsURL,
                data: variantsToUpdate,
                method: "PATCH",
              })
            : Promise.resolve(null),
          variantsToCreate.length > 0
            ? customInstance({
                url: `/merchants/${merchantId}/products/${dbProductId}/variants/bulk-create`,
                data: { variants: variantsToCreate },
                method: "POST",
              })
            : Promise.resolve(null),
          variantsToDelete.length > 0
            ? customInstance({
                url: variantsURL,
                data: { variantIDs: variantsToDelete },
                method: "DELETE",
              })
            : Promise.resolve(null),
          publish && dbProductId
            ? customInstance({
                url: `/merchants/${merchantId}/products/${dbProductId}/publish-status`,
                data: {
                  publishedStatus: "public",
                },
                method: "PATCH",
              })
            : Promise.resolve(null),
          dbProductId
            ? customInstance({
                url: checkoutUrl,
                method: isCheckoutPost ? "POST" : "PATCH",
                data: generatePayloadForCheckout(values, formType),
              })
            : Promise.resolve(null),
        ];

        const [updateRes, createRes, deleteRes, publishedRes, checkoutRes] =
          await Promise.allSettled(nexRequests);

        const updatedVariants: Variant[] =
          getSettledPromise(updateRes)?.succeeded || [];
        const createdVariants: Variant[] =
          getSettledPromise(createRes)?.data || [];

        // when either of the above request fails in totality
        const publishedProduct = getSettledPromise(publishedRes);

        const failedResponses = {
          update:
            updateRes.status === "rejected"
              ? updateRes.reason?.response?.data?.message
              : null,
          create:
            createRes.status === "rejected"
              ? createRes.reason?.response?.data?.message
              : null,
          delete:
            deleteRes.status === "rejected"
              ? deleteRes.reason?.response?.data?.message
              : null,
          publish:
            publishedRes?.status === "rejected"
              ? publishedRes?.reason?.response?.data?.message
              : null,
          checkout:
            checkoutRes?.status === "rejected"
              ? checkoutRes?.reason?.response?.data?.message ||
                "Checkout request failed."
              : null,
        };
        const variants = [...createdVariants, ...updatedVariants];

        const product =
          (publishedRes as any)?.value && publishedRes?.status === "fulfilled"
            ? publishedProduct
            : productResponse;

        return {
          product: product,
          checkout: checkoutRes,
          failedResponses,
          variants,
          publishedProduct,
          dbProductId,
        };
      } catch (error) {
        throw new Error("Product request failed");
      }
    },
    {
      onSuccess(data, variables) {
        const { product, failedResponses, publishedProduct, dbProductId } =
          data || {};

        queryClient.setQueryData([QFORM_QUERY_KEY], data);
        if (product?.id) methods.setValue("productId", product?.id);

        if (dbProductId)
          queryClient.refetchQueries([
            "get-payment-form-checkout",
            dbProductId,
          ]);

        queryClient.refetchQueries("list-all-payment-forms");
        if (failedResponses) {
          Object.entries(failedResponses).forEach(([key, message]) => {
            if (message) {
              showMessage("Error", message);
            }
          });
        }
        variables?.cb?.();
        NiceModal.hide(CAMPAIGN_DETAILS_MODAL);
        if (variables?.shouldNavigate !== false) {
          navigate(`/merchant/${formTypeToUrlMap[formType]}`);
          queryClient.removeQueries({ queryKey: QFORM_QUERY_KEY });
        } else {
          navigate(
            isEmpty(publishedProduct)
              ? { pathname: location.pathname, search: location.search }
              : {
                  pathname:
                    prevPath || `/merchant/${formTypeToUrlMap[formType]}`,
                },
            {
              state: {
                id: product?.id,
              },
              replace: true,
            },
          );
        }
        if (
          isADBEnabled &&
          location.pathname === "/pay_product_builder" &&
          product?.publishedStatus !== "draft"
        ) {
          NiceModal.show(
            getCampaignModal(formType, isADBEnabled, isPayBuilderEnabled)
              ?.success,
            {
              data: {
                ...product,
                about: {
                  title: product.name,
                  description: product.description,
                },
                style: {
                  image: {
                    URL: product.imageURL,
                  },
                },
                campaign: product.typeName,
              },
              productId: product?.id,
            },
          );
        } else {
          showMessage(
            "Success",
            `Product ${
              location.state?.id ? "updated" : "created"
            } successfully`,
          );
        }
      },
      onError(error, variables, context) {
        showMessage("Error", "error creating the products");
      },
      onSettled(data, error, variables, context) {
        variables?.cb?.();
      },
    },
  );

  const handleSubmit = (data: {
    handleSuccessCB?: () => void;
    isSaveDb?: boolean;
    publish?: boolean;
    shouldNavigate?: boolean;
  }) => {
    if (!isLastStep && !data?.isSaveDb)
      return data.handleSuccessCB && data.handleSuccessCB();

    mutate({
      cb: data?.handleSuccessCB,
      publish: data?.publish,
      shouldNavigate: data?.shouldNavigate,
    });
  };

  const handleProceed = (action: PendingActionType) => {
    if (action === "back") {
      prevPath ? navigate(prevPath) : navigate(-1);
    } else if (action === "forward") {
      navigate(1);
    } else if (action === "reload") {
      navigate(0);
    }
  };

  const showModal = (nextAction: PendingActionType) => {
    const proceed = (cb?: () => void) => {
      cb?.();
      handleProceed(nextAction);
    };

    if (
      ((id && prevPath) || (!id && prevPath)) &&
      methods.formState.isValid &&
      methods.formState.isDirty
    ) {
      NiceModal.show(SAVE_MINI_BUILDER_DRAFT, {
        modalType: "saveDraft",
        formType,
        onCancel: (cb?: () => void) => {
          proceed(cb);
        },
        onAccept: (cb?: () => void) => {
          handleSubmit({
            handleSuccessCB: () => {
              proceed(cb);
            },
            isSaveDb: true,
          });
        },
        isFormValid: methods.formState.isValid,
      });
    } else {
      handleProceed(nextAction);
      queryClient.removeQueries({ queryKey: QFORM_QUERY_KEY });
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      const direction = window.history.state?.direction ?? "back";
      if (direction === "back") {
        showModal("back");
      } else {
        showModal("forward");
      }
      window.history.pushState(null, "", window.location.href);
    };

    window.addEventListener("popstate", handlePopState);

    window.history.pushState(null, "", window.location.href);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location]);

  return {
    data,
    methods,
    mutate: handleSubmit,
    merchantId,
    isLoading,
    isFetched,
    isPaymentFormInfosLoading,
    queryClient,
    parsedValues: {
      accentColor: rgbaToHex(methods.watch().Style.accent, true),
      background: rgbaToHex(methods.watch().Style.background, true),
      itemsLayout: methods.watch().Style.itemLayout,
      heading: methods.watch().About.heading,
      description: methods.watch().About.description,
      selectedImage: methods.watch().About.selectedImage,
      assetPosition: methods.watch().About.assetPosition,
      items: methods.watch().Items,
      logo: methods.watch().Style.logo,
      checkoutContent: methods.watch().Style.checkoutContent,
    },
  };
}

export const startsAtValidationSchema = Yup.mixed()
  .nullable()
  .required("This field is required")
  .test("is-valid-date", "Provide a valid date", (value) => {
    if (!value) return false;
    const date = new Date(value);
    return date instanceof Date && !isNaN(date.getTime());
  });

export const endsAtValidationSchema = Yup.mixed()
  .nullable()
  .when("timeActiveTab", {
    is: "range",
    then: Yup.mixed()
      .required("This field is required")
      .test("is-valid-date", "Provide a valid date", (value) => {
        if (!value) return false;
        const date = new Date(value);
        return date instanceof Date && !isNaN(date.getTime());
      })
      .test(
        "is-after-start",
        "End date must be after or equal to the start date",
        function (value) {
          const { startsAt } = this.parent || {};
          if (!value || !startsAt) return true;
          const startDate = new Date(startsAt);
          const endDate = new Date(value);
          return endDate >= startDate;
        },
      ),
    otherwise: Yup.mixed().nullable(),
  });

export const locationURLValidationSchema = Yup.string().when(
  "locationPosition",
  {
    is: "online",
    then: Yup.string()
      .required("Location URL is required")
      .url("Please enter a valid URL"),
    otherwise: Yup.string().nullable(),
  },
);

export const locationAddressSchema = Yup.object().when(
  ["locationPosition", "isManualAddress"],
  {
    is: (locationPosition: string, isManualAddress: boolean) =>
      locationPosition === "onsite" && isManualAddress === true,
    then: Yup.object().shape({
      line1: Yup.string().required("Line 1 is required"),
      line2: Yup.string().nullable(),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zip: Yup.string().required("ZIP is required"),
      country: Yup.string().required("Country is required"),
    }),
    otherwise: Yup.object().nullable().shape({
      line1: Yup.string().nullable(),
      line2: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      zip: Yup.string().nullable(),
      country: Yup.string().nullable(),
    }),
  },
);
const schema = Yup.object().shape({
  About: Yup.object().shape({
    heading: Yup.string().required("Heading is required"),
  }),
});

export const eventSchema = Yup.object().shape({
  DateLocation: Yup.object().shape({
    locationPosition: Yup.string().oneOf(
      ["online", "onsite"],
      "Invalid location position",
    ),
    isManualAddress: Yup.boolean(),
    startsAt: startsAtValidationSchema,
    endsAt: endsAtValidationSchema,
    locationURL: locationURLValidationSchema,
    locationShortAddress: Yup.string()
      .nullable()
      .when(["locationPosition", "isManualAddress"], {
        is: (locationPosition: string, isManualAddress: boolean) =>
          locationPosition === "onsite" && isManualAddress === false,
        then: Yup.string().required("Short address is required"),
        otherwise: Yup.string().nullable(),
      }),
    locationAddress: locationAddressSchema,
    startsAtTime: Yup.mixed()
      .nullable()
      .when("includeTime", {
        is: true,
        then: Yup.string()
          .required("Start time is required")
          .test("is-valid-time", "Provide a valid time", (value) => {
            const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
            return timeRegex.test(value ?? "");
          }),
        otherwise: Yup.mixed().nullable(),
      }),
    endsAtTime: Yup.mixed()
      .nullable()
      .when(["timeActiveTab", "includeTime"], {
        is: (timeActiveTab: string, includeTime: boolean) =>
          timeActiveTab === "range" && includeTime === true,
        then: Yup.string()
          .required("End time is required")
          .test("is-valid-time", "Provide a valid time", (value) => {
            const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
            return timeRegex.test(value ?? "");
          }),
        otherwise: Yup.mixed().nullable(),
      }),
  }),
});
