import { useEffect, useRef } from "react";
import flagsmith from "flagsmith";
import { Text } from "@common/Text";
import { showMessage } from "@common/Toast";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { useLogout, useResetApp } from "./common/useHandleLogout";
import { clearToasts } from "@common/Toast/ShowToast";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";

const SCHEDULER_TIME = 90000;

const storageDto = () => {
  const versionKey = "v";
  const isVersionUpdatedKey = "version_updated";

  const currentAppVersion = localStorage.getItem(versionKey);
  const isVersionUpdated = localStorage.getItem(isVersionUpdatedKey);

  return {
    versionKey,
    currentAppVersion,
    isVersionUpdated,
    updateVersion: (newVersion?: string) => {
      localStorage.setItem(versionKey, newVersion || "1.0");
    },
    updateIsVersionUpdated: (newValue: "true" | "false") => {
      localStorage.setItem(isVersionUpdatedKey, newValue);
    },
    removeIsVersionUpdated: () => {
      localStorage.removeItem(isVersionUpdatedKey);
    },
  };
};

export const useVersionUpdate = () => {
  const { clearLocalData, handleReset } = useResetApp();
  const { mutateAsync } = useLogout();
  const messageOpenStatus = useRef(false);

  const {
    versionKey,
    currentAppVersion,
    isVersionUpdated,
    updateIsVersionUpdated,
    updateVersion,
    removeIsVersionUpdated,
  } = storageDto();

  const handleUpdate = (newVersion: string) => () => {
    //If the user is already logged in, we need to make sure to invalidate the current session by calling logOut
    messageOpenStatus.current = false;
    updateIsVersionUpdated("true");
    updateVersion(newVersion);
    window.location.reload();
  };

  function checksPaymentFormPathName() {
    const pathname = location.pathname.replace("/", "");
    //Public form name is a string that contains an integer
    if (!Number.isNaN(Number(pathname)) || pathname.match(/^\d+\/checkout$/)) {
      return true;
    }
    if (pathname === "signup") {
      return true;
    }
  }

  const pushMessage = (newVersion: string) => {
    if (checksPaymentFormPathName()) return;
    messageOpenStatus.current = true;
    showMessage(
      "Info",
      "New version is available, please refresh the page",
      true,
      "New version",
      false,
      {
        style: {
          width: "340px",
        },
      },
      {
        action: <Action handleUpdate={handleUpdate(newVersion)} />,
      },
    );
  };

  function checkForUpdates() {
    const newVersion = flagsmith.getValue("react_app_version");

    if (newVersion && currentAppVersion !== newVersion) {
      pushMessage(newVersion as string);
    }

    setTimeout(checkForUpdates, SCHEDULER_TIME);
  }

  function listenStorage(e: StorageEvent) {
    if (e.key === versionKey) {
      //here probabaly the user tampered with the localstorage manually
      pushMessage(flagsmith.getValue("react_app_version") as string);
    }
  }

  const onStart = async () => {
    await flagsmith.getFlags();

    if (isVersionUpdated === "true") {
      removeIsVersionUpdated();
      if (Cookies.get("user")) {
        mutateAsync({} as any, {
          onSettled: () => {
            Sentry.setUser(null);
            handleReset();
          },
        });
      } else {
        handleReset();
      }
    }
    // appendVersionToScripts(process.env.REACT_APP_VERSION || "1.0");
    checkForUpdates();
  };

  useEffect(() => {
    if (checksPaymentFormPathName() && messageOpenStatus.current) {
      clearToasts();
      messageOpenStatus.current = false;
    }
  }, [location.pathname]);

  useEffect(() => {
    onStart();

    flagsmith.startListening(SCHEDULER_TIME);
    window.addEventListener("storage", listenStorage);

    return () => {
      flagsmith.stopListening();
      window.removeEventListener("storage", listenStorage);
    };
  }, []);
};

function Action({ handleUpdate }: any) {
  return (
    <Button
      onClick={handleUpdate}
      sx={{
        marginTop: "auto",
        marginBottom: "auto",
        padding: "1px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      size="medium"
      background="text"
    >
      <Text fontSize="16px" color={palette.givebox[200]}>
        Refresh
      </Text>
    </Button>
  );
}

function appendVersionToScripts(version: string) {
  document.addEventListener("DOMContentLoaded", () => {
    const scripts = document.querySelectorAll("script[src]");

    scripts.forEach((script) => {
      let src = script.getAttribute("src");

      if (src) {
        // Check if src already has a query string
        if (src.includes("?")) {
          // If there is already a query string, append &version=version
          src += `&version=${version}`;
        } else {
          // Otherwise, append ?version=version
          src += `?version=${version}`;
        }

        script.setAttribute("src", src);
      }
    });
  });
}
