import { OptionWrapper } from "./OptionWrapper";

export const List = ({ width = "226" }: { width?: string | number }) => {
  return (
    <OptionWrapper type="list">
      <svg
        width={width}
        height="56"
        viewBox={`0 0 ${width} 56`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={width}
          height="56"
          rx="12"
          fill="black"
          fillOpacity="0.05"
        />
        <rect
          x="8"
          y="8"
          width="40"
          height="40"
          rx="8"
          fill="black"
          fillOpacity="0.1"
        />
        <rect
          x="56"
          y="18"
          width="62"
          height="8"
          rx="4"
          fill="black"
          fillOpacity="0.1"
        />
        <rect
          x="56"
          y="30"
          width="40"
          height="8"
          rx="4"
          fill="black"
          fillOpacity="0.1"
        />
      </svg>
      <svg
        width={width}
        height="56"
        viewBox={`0 0 ${width} 56`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={width}
          height="56"
          rx="12"
          fill="black"
          fillOpacity="0.05"
        />
        <rect
          x="8"
          y="8"
          width="40"
          height="40"
          rx="8"
          fill="black"
          fillOpacity="0.1"
        />
        <rect
          x="56"
          y="18"
          width="62"
          height="8"
          rx="4"
          fill="black"
          fillOpacity="0.1"
        />
        <rect
          x="56"
          y="30"
          width="40"
          height="8"
          rx="4"
          fill="black"
          fillOpacity="0.1"
        />
      </svg>
    </OptionWrapper>
  );
};
