import GiveText from "@shared/Text/GiveText";
import { ReactNode } from "react";

type TDefaultProps = React.ComponentProps<typeof GiveText>;

const PayFormSectionTitle = ({
  isEdit,
  children,
}: {
  isEdit?: boolean;
  children: ReactNode;
}) => {
  const textProps: Partial<TDefaultProps> = {
    ...(!isEdit
      ? { fontWeight: 271, fontSize: "28px", color: "primary" }
      : {
          variant: "h3",
          fontWeight: 300,
        }),
  };
  return <GiveText {...textProps}>{children}</GiveText>;
};


export default PayFormSectionTitle