import { createTheme } from "@mui/material";
import { getJsonValue } from "./utils";
import { CustomTheme } from "./palette.interface";
import {
  getLargeButtonStyle,
  getSmallButtonStyle,
  getFilledVariants,
  getOutlinedVariants,
  getGhostVariants,
  getDefaultButtonStyle,
} from "./customStyles/customButtonStyle";
import { getCustomBaseInputStyle } from "./customStyles/customBaseInputStyle";
import { typography } from "./typography";
import { getTextFieldOverrides } from "./customStyles/getTextFieldOverrides";

import { getCheckboxCustomStyle } from "./customStyles/customCheckboxStyle";
import { getCustomBaseSwitchRootStyles } from "./customStyles/customBaseSwitchStyle";
import { getCustomLinkStyle } from "@theme/v2/customStyles/customLinkStyle";
import { getCustomIconButtonStyle } from "./customStyles/customIconButtonStyle";
import { ThemeMode } from "@theme/v2/types";
import { getCustomBaseRadioRootStyles } from "@theme/v2/customStyles/customRadioStyle";
import {
  getDefaultStyles,
  getSuccessStyles,
  getWarningStyles,
  getBlueStyles,
  getErrorStyles,
  getLargeChipStyle,
  getSmallChipStyle,
} from "./customStyles/customChipStyle";
import { breakpoints } from "@theme/v2/breakpoints";
import { getCustomDrawerStyle } from "@theme/v2/customStyles/customDrawerStyle";
import { getCustomTooltipStyles } from "./customStyles/customTooltipStyle";
import { getCustomFormControlStyle } from "@theme/v2/customStyles/customFormControlStyle";
import { customTableCellStyle } from "@theme/v2/customStyles/customTableCellStyle";
import { getCustomPaperStyle } from "@theme/v2/customStyles/customPaperStyle";
import { getCustomTypographyStyle } from "@theme/v2/customStyles/customTypographyStyle";

const makePalette = (mode: "light" | "dark") =>
  ({
    givebox: {
      "600": "#326ec5ff",
      blue: "#6d9cf8ff",
    },
    primitive: {
      blue: {
        "10": getJsonValue(`tokens.${mode}.primitive.blue.10`),
        "25": getJsonValue(`tokens.${mode}.primitive.blue.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.blue.50`),
        "100": getJsonValue(`tokens.${mode}.primitive.blue.100`),
      },
      "citrus-peel": {
        "100": getJsonValue(`tokens.${mode}.primitive.citrus-peel.100`),
      },
      error: {
        "25": getJsonValue(`tokens.${mode}.primitive.error.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.error.50`),
        "75": getJsonValue(`tokens.${mode}.primitive.error.75`),
        "100": getJsonValue(`tokens.${mode}.primitive.error.100`),
      },
      "error-inverted": {
        "25": getJsonValue(`tokens.${invertMode(mode)}.primitive.error.25`),
        "50": getJsonValue(`tokens.${invertMode(mode)}.primitive.error.50`),
        "100": getJsonValue(`tokens.${invertMode(mode)}.primitive.error.100`),
      },
      "moon-purple": {
        "100": getJsonValue(`tokens.${mode}.primitive.moon-purple.100`),
      },
      neutral: {
        "0": getJsonValue(`tokens.${mode}.primitive.neutral.0`),
        "5": getJsonValue(`tokens.${mode}.primitive.neutral.5`),
        "10": getJsonValue(`tokens.${mode}.primitive.neutral.10`),
        "20": getJsonValue(`tokens.${mode}.primitive.neutral.20`),
        "25": getJsonValue(`tokens.${mode}.primitive.neutral.25`),
        "30": getJsonValue(`tokens.${mode}.primitive.neutral.30`),
        "40": getJsonValue(`tokens.${mode}.primitive.neutral.40`),
        "50": getJsonValue(`tokens.${mode}.primitive.neutral.50`),
        "60": getJsonValue(`tokens.${mode}.primitive.neutral.60`),
        "70": getJsonValue(`tokens.${mode}.primitive.neutral.70`),
        "80": getJsonValue(`tokens.${mode}.primitive.neutral.80`),
        "90": getJsonValue(`tokens.${mode}.primitive.neutral.90`),
        "95": getJsonValue(`tokens.${mode}.primitive.neutral.95`),
        "100": getJsonValue(`tokens.${mode}.primitive.neutral.100`),
        "110": getJsonValue(`tokens.${mode}.primitive.neutral.110`),
      },
      success: {
        "25": getJsonValue(`tokens.${mode}.primitive.success.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.success.50`),
        "100": getJsonValue(`tokens.${mode}.primitive.success.100`),
      },
      "success-inverted": {
        "25": getJsonValue(`tokens.${invertMode(mode)}.primitive.success.25`),
        "50": getJsonValue(`tokens.${invertMode(mode)}.primitive.success.50`),
        "100": getJsonValue(`tokens.${invertMode(mode)}.primitive.success.100`),
      },
      transparent: {
        "darken-10": getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
        "darken-5": getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-5`,
        ),
        "darken-2": getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-2`,
        ),
        "darken-25": getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-25`,
        ),
      },
      warning: {
        "10": getJsonValue(`tokens.${mode}.primitive.warning.10`),
        "25": getJsonValue(`tokens.${mode}.primitive.warning.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.warning.50`),
        "100": getJsonValue(`tokens.${mode}.primitive.warning.100`),
      },
      "warning-inverted": {
        "10": getJsonValue(`tokens.${invertMode(mode)}.primitive.warning.10`),
        "25": getJsonValue(`tokens.${invertMode(mode)}.primitive.warning.25`),
        "50": getJsonValue(`tokens.${invertMode(mode)}.primitive.warning.50`),
        "100": getJsonValue(`tokens.${invertMode(mode)}.primitive.warning.100`),
      },
    },
    gradient: {
      "ocean-blue": {
        default: `linear-gradient(224deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.start`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.end`,
        )} 100%)`,
        highlight: `linear-gradient(223.56deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.end-light`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.start-light`,
        )} 100%)`,
        "highlight-hover": `linear-gradient(180deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.start-light-hover`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.end-light-hover`,
          )} 100%)`,        
        disabled: `linear-gradient(85.95deg, rgba(79, 134, 213, 0.5) 0%, rgba(97, 199, 232, 0.5) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));`,
        border: `linear-gradient(${getJsonValue(
          `tokens.${mode}.colour.surface.primary`,
        )}, ${getJsonValue(
          `tokens.${mode}.colour.surface.primary`,
        )}) padding-box, linear-gradient(223.56deg, #28C0D9 0%, #4D449E 100%) border-box`,
      },
      "citrus-peel": {
        default: `linear-gradient(224deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.citrus-peel.start`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.citrus-peel.end`,
        )} 100%));`,
        highlight: `linear-gradient(224deg, rgba(255, 170, 108, 0.20) 0%, rgba(224, 77, 121, 0.20) 100%)`,
      },
      "moon-purple": {
        default: `linear-gradient(224deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.moon-purple.start`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.moon-purple.end`,
        )} 100%)`,
        highlight: `linear-gradient(224deg, rgba(137, 164, 234, 0.20) 0%, rgba(116, 89, 233, 0.20) 100%)`,
      },
      "aqua-horizon": {
        border: `linear-gradient(85.95deg, #4F86D5 0%, #61C7E8 100%)`,
      },
    },
  } as const);

export type TMakePalette = ReturnType<typeof makePalette>;
const makeCtx = (mode: "light" | "dark") => ({
  text: {
    primary: getJsonValue(`tokens.${mode}.colour.text.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.text.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
    invert: getJsonValue(`tokens.${mode}.colour.text.invert`),
  },
  "text-inverted": {
    primary: getJsonValue(`tokens.${invertMode(mode)}.colour.text.primary`),
    secondary: getJsonValue(`tokens.${invertMode(mode)}.colour.text.secondary`),
    tertiary: getJsonValue(`tokens.${invertMode(mode)}.colour.text.tertiary`),
    invert: getJsonValue(`tokens.${invertMode(mode)}.colour.text.invert`),
  },
  surface: {
    primary: getJsonValue(`tokens.${mode}.colour.surface.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.surface.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.surface.tertiary`),
    "primary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.primary-transparent`,
    ),
    "secondary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.secondary-transparent`,
    ),
    "tertiary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.tertiary-transparent`,
    ),
    overlay: getJsonValue(`tokens.${mode}.colour.surface.overlay`),
  },
  "surface-inverted": {
    primary: getJsonValue(`tokens.${invertMode(mode)}.colour.surface.primary`),
    secondary: getJsonValue(
      `tokens.${invertMode(mode)}.colour.surface.secondary`,
    ),
    tertiary: getJsonValue(
      `tokens.${invertMode(mode)}.colour.surface.tertiary`,
    ),
    "primary-transparent": getJsonValue(
      `tokens.${invertMode(mode)}.colour.surface.primary-transparent`,
    ),
    "secondary-transparent": getJsonValue(
      `tokens.${invertMode(mode)}.colour.surface.secondary-transparent`,
    ),
    overlay: getJsonValue(`tokens.${invertMode(mode)}.colour.surface.overlay`),
  },
  border: {
    primary: getJsonValue(`tokens.${mode}.colour.border.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.border.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.border.tertiary`),
    active: getJsonValue(`tokens.${mode}.colour.border.active`),
  },
  buttons: {
    default: getJsonValue(`tokens.${mode}.colour.buttons.default`),
    "default-hover": getJsonValue(
      `tokens.${mode}.colour.buttons.default-hover`,
    ),
  },
  analytics: {
    blue: getJsonValue(`tokens.${mode}.colour.analytics.blue`),
    red: getJsonValue(`tokens.${mode}.colour.analytics.red`),
    purple: getJsonValue(`tokens.${mode}.colour.analytics.purple`),
    yellow: getJsonValue(`tokens.${mode}.colour.analytics.yellow`),
  },
  icon: {
    "remain-dark": getJsonValue(`tokens.${mode}.colour.icon.remain-dark`),
    "remain-light": getJsonValue(`tokens.${mode}.colour.icon.remain-light`),
    "icon-primary": getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
    "icon-secondary": getJsonValue(`tokens.${mode}.colour.icon.icon-secondary`),
  },
  "icon-inverted": {
    "remain-dark": getJsonValue(
      `tokens.${invertMode(mode)}.colour.icon.remain-dark`,
    ),
    "remain-light": getJsonValue(
      `tokens.${invertMode(mode)}.colour.icon.remain-light`,
    ),
    "icon-primary": getJsonValue(
      `tokens.${invertMode(mode)}.colour.icon.icon-primary`,
    ),
    "icon-secondary": getJsonValue(
      `tokens.${invertMode(mode)}.colour.icon.icon-secondary`,
    ),
  },
});

export const createThemeBuilder = (mode: ThemeMode): CustomTheme => {
  const basePallete = makePalette(mode);
  const palette = makeCtx(mode);

  return createTheme({
    typography,
    breakpoints,
    customs: {
      shadows: {
        small: `0px 4px 8px 0px ${palette.surface.tertiary}`,
        left: `-8px 0px 20px 0px ${palette.surface.tertiary}`,
      },
      materials: {
        primaryTransparent: {
          medium: {
            backdropFilter: "blur(25px)",
            backgroundColor: palette.surface["primary-transparent"],
          },
          light: {
            backdropFilter: "blur(15px)",
            backgroundColor: palette.surface["primary-transparent"],
          },
          glass: {
            backgroundColor: palette.surface["primary-transparent"],
            backdropFilter: "blur(15px)",
            boxShadow:
              mode === "light"
                ? "inset 1px 1px 1px 0px #FFFFFF,1px 1px 2.5px -1px #0000001A"
                : "inset 1px 1px 1px 0px #FFFFFF66,1px 1px 2.5px -1px #00000080",
          },
        },
        secondaryTransparent: {
          medium: {
            backdropFilter: "blur(25px)",
            backgroundColor: palette.surface["secondary-transparent"],
          },
          light: {
            backdropFilter: "blur(15px)",
            backgroundColor: palette.surface["secondary-transparent"],
          },
        },
      },
      spacing: {
        grid: {
          "margin-page-desktop": 64,
          "margin-page-mobile": 20,
          "margin-panel-desktop": 20,
          "margin-panel-mobile": 20,
        },
      },
      radius: {
        extraSmall: 8,
        small: 12,
        mediumSmall: 16,
        medium: 20,
        large: 32,
      },
    },
    palette: {
      mode,
      ...basePallete,
      ...palette,
    },
    components: {
      MuiButton: {
        variants: [
          ...getDefaultButtonStyle,
          ...getLargeButtonStyle,
          ...getSmallButtonStyle,
          ...getFilledVariants(mode, basePallete),
          ...getOutlinedVariants(mode, basePallete),
          ...getGhostVariants(mode),
        ],
      },
      MuiLink: getCustomLinkStyle(mode),
      MuiIconButton: getCustomIconButtonStyle(mode),
      MuiInput: {
        ...getCustomBaseInputStyle(mode, basePallete, palette),
      },
      MuiSwipeableDrawer: {
        defaultProps: {
          PaperProps: {
            sx: {
              height: "auto",
              maxHeight: "96%",
              backgroundColor: getJsonValue(
                `tokens.${mode}.colour.surface.secondary`,
              ),
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              width: "100%",
              overflow: "auto",
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            bodyS: "p",
            bodyXS: "p",
            bodyXXS: "p",
            bodyL: "p",
            bodyM: "p",
          },
        },
        styleOverrides: getCustomTypographyStyle(),
      },
      MuiTextField: {
        ...getTextFieldOverrides(palette, basePallete),
      },
      MuiFormControl: getCustomFormControlStyle(palette, basePallete),
      MuiCheckbox: getCheckboxCustomStyle(mode),
      MuiSwitch: getCustomBaseSwitchRootStyles(mode),
      MuiRadio: getCustomBaseRadioRootStyles(mode),
      MuiTableCell: customTableCellStyle,
      MuiPaper: getCustomPaperStyle(palette, basePallete),
      MuiChip: {
        styleOverrides: {
          root: {
            variants: [
              ...getSmallChipStyle(),
              ...getLargeChipStyle(),
              ...getDefaultStyles(mode),
              ...getSuccessStyles(mode),
              ...getWarningStyles(mode),
              ...getBlueStyles(mode),
              ...getErrorStyles(mode),
            ],
          },
        },
      },
      MuiTooltip: getCustomTooltipStyles(mode),
      MuiDrawer: getCustomDrawerStyle(mode),
    },
  } as any);
};

const invertMode = (mode: "light" | "dark") =>
  mode === "light" ? "dark" : "light";
