import { Box } from "@mui/material";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { useAppTheme } from "@theme/v2/Provider";

export const OptionWrapper = ({ children, type }: any) => {
  const { methods, parsedValues } = usePayBuilderForm();
  const { palette } = useAppTheme();

  return (
    <Box
      onClick={() => {
        methods.setValue("Style.itemLayout", type);
      }}
      gap={2}
      sx={{
        cursor: "pointer",
        maxHeight: "140px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "12px",
        borderRadius: "12px",
        border: `1px solid ${
          parsedValues.itemsLayout === type
            ? palette.border?.active
            : palette.border?.primary
        }`,
      }}
    >
      {children}
    </Box>
  );
};
