import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import ContactSection from "./components/ContactSection";
import SupportSection from "./components/SupportSection";
import ConfirmationSection from "./components/ConfirmationSection";
import DeliverySection from "./components/DeliverySection";
import useCheckFormType from "../components/hooks/useCheckFormType";

const CheckoutStep = () => {
  const { isEvent } = useCheckFormType();
  return (
    <Stack gap="32px">
      <GiveText variant="h3" fontWeight={300}>
        Checkout Details
      </GiveText>
      <ContactSection />
      {!isEvent && <DeliverySection />}
      <SupportSection />
      <ConfirmationSection />
    </Stack>
  );
};

export default CheckoutStep;
