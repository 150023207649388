import React, { useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { Button } from "@common/Button";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { useFormData } from "./MerchantAgreementFormProvider";
import * as Yup from "yup";
import { palette } from "@palette";
import { cloneDeep, isEqual } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useQueryClient } from "react-query";
import { toUnixDateFormat } from "@utils/date.helpers";
import { TermsOfService } from "@pages/TermsOfService";
import SignAgreementSection from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/SignAgreementSection";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { showMessage } from "@common/Toast/ShowToast";
import { useGetMerchantAcquirer } from "@services/api/merchants";
import { validateSignature } from "../EnterpriseAgreement/useEnterpriseAgreement";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useAsyncAction } from "@hooks/customReactCore";
import { useScrollCheck } from "./hooks/useScrollCheck";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import ScrollBottomButton from "@components/AcquirerEnterprises/EnterprisePreview/components/ScrollBottomButton";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ArrowLeft } from "@assets/icons";
import { TOSWrapper } from "../EnterpriseAgreement/SignTOS";
import { LastUpdatedOn_merchant_agreement } from "features/Merchants/MerchantSidePanel/constants";

type Props = {
  handleBack: () => void;
  goToWelcome: () => void;
  handleUpdateStatusValue: (value: number) => void;
  merchantData: IParsedData;
};

type FormInputs = {
  signature: File | string;
  agreementConfirmation: boolean;
};

const AddTermsAndConditions = ({
  handleBack,
  goToWelcome,
  handleUpdateStatusValue,
  merchantData,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const { handleSubmit, isLoading: isLoadingInfo } =
    useUpdateMerchantInfo(merchantId);
  const saveOnUnmount = React.useRef<any>();
  const { formData, setFormValues } = useFormData();
  const [isLoading, triggerAction] = useAsyncAction();
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  const {
    handleUploadSignature: handleUploadSignatureNew,
    isLoading: isFileUploadLoadingNew,
  } = useAddSignatureNew();
  const {
    handleUploadSignature: handleUploadSignatureOld,
    isLoading: isFileUploadLoadingOld,
  } = useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const loadingSignature = isFileUploadRefactorEnabled
    ? isFileUploadLoadingNew
    : isFileUploadLoadingOld;

  const { data } = useGetMerchantAcquirer(merchantId, {
    enabled: !!merchantId,
  });

  const isSigned =
    merchantData?.merchantAgreement.tcVersion ===
      merchantData?.merchantAgreement.msaLastAcceptedVersion &&
    !!merchantData?.merchantAgreement?.signatureURL;

  const { CheckboxWithTooltip, handleScroll } = useScrollCheck(isSigned);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const schema = Yup.object().shape({
    agreementConfirmation: Yup.boolean()
      .required()
      .oneOf([true], "Should Agree to proceed"),
    signature: Yup.mixed<File | string>().test(
      "is-valid-signature",
      "The signature is not valid",
      (value) => validateSignature(value),
    ),
  });

  const defaultValues = {
    agreementConfirmation: isSigned,
    signature: merchantData?.merchantAgreement?.signatureURL || "",
  };

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const addSignature = (signature: File) => {
    setValue("signature", signature, { shouldDirty: true });
  };

  const values = watch();
  const savedData = useRef<any>();

  const { isDesktopView } = useCustomTheme();

  React.useEffect(() => {
    (async () => {
      const condition =
        Object.keys(dirtyFields).length > 0 &&
        !isEqual(values, savedData.current);
      if (condition) {
        savedData.current = cloneDeep(values);
        const value = await calculatePercentageNested(schema, values);
        handleUpdateStatusValue(value);
      }
    })();
  }, [values, dirtyFields]);

  React.useEffect(() => {
    saveOnUnmount.current = values;
  }, [values]);

  React.useEffect(() => {
    return () => {
      setFormValues("termsOfService", saveOnUnmount.current);
    };
  }, []);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (!values?.signature)
      return showMessage(
        "Error",
        "Signature Needed! Insert your signature below to complete this step",
      );

    if (!dirtyFields.signature) {
      goToWelcome();
      return;
    }

    const signatureURL = await handleUploadSignature(
      merchantId,
      values?.signature,
      false,
    );
    if (!signatureURL && !merchantData?.merchantAgreement?.msaHadAgreed) return;

    const status = data?.agreementConfirmation;
    const payload = {
      msaRefundPolicy: formData.merchantAgreement.msaRefundPolicy,
      msaPCICompliant: formData.merchantAgreement.msaPCICompliant,
      msaPreviousTermination: formData.merchantAgreement.msaPreviousTermination,
      msaHadAgreed: status ? status : formData.merchantAgreement.msaHadAgreed,

      ...(formData.merchantAgreement.msaPreviousTermination && {
        msaPreviousTerminationProcessorName:
          formData.merchantAgreement.msaPreviousTerminationProcessorName,
        msaPreviousTerminationReason:
          formData.merchantAgreement.msaPreviousTerminationReason,
        msaPreviousTerminationAt: formData.merchantAgreement
          .msaPreviousTerminationAt
          ? toUnixDateFormat(
              new Date(formData.merchantAgreement.msaPreviousTerminationAt),
            )
          : null,
      }),
    };

    handleSubmit("merchant_agreement", { ...payload, signatureURL }, () => {
      goToWelcome();
      queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
    });
  };

  const loading = isLoadingInfo || loadingSignature || isLoading;

  const isDisabledSubmit =
    (!dirtyFields.signature && !validateSignature(values.signature)) ||
    !values.agreementConfirmation ||
    loading;

  return (
    <FormProvider {...methods}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ flexGrow: 1, margin: "0 auto", marginBottom: "32px" }}
        component="form"
        onSubmit={(data) => triggerAction(methods.handleSubmit(onSubmit), data)}
      >
        <Box
          onScroll={handleScroll}
          data-testid="agreement"
          ref={containerRef}
          sx={{
            margin: "0 auto",
            width: "100%",
            height: "618px",
            borderRadius: "16px",
            border: "none",
            backgroundColor: "white",
            overflowY: "scroll",
            "*": {
              color: palette.black.main,
            },
            "& .MuiCardMedia-root": {
              backgroundColor: "white",
              height: "618px",
              overflowY: "scroll",
            },
          }}
        >
          <TermsOfService
            merchantAgreementVersion={{
              version: data?.msaVersion,
              lastUpdated: LastUpdatedOn_merchant_agreement,
            }}
            {...(isMobileView && {
              customStyles: {
                padding: 0,
                width: "90%",
                margin: "0 auto",
              },
            })}
          />
          <ScrollBottomButton containerRef={containerRef} />
        </Box>
        <CheckboxWithTooltip
          name="agreementConfirmation"
          containerProps={{ mt: 3 }}
        />
        <Box sx={{ "& div": { borderTop: "none", padding: 0 } }}>
          <SignAgreementSection
            data={merchantData}
            canSign
            addSignature={addSignature}
            status={
              data?.merchantAgreement?.signatureURL ? "signed" : "not_signed"
            }
            isOnboarding
            disabled={!values.agreementConfirmation}
          />
        </Box>
        <Box flexGrow={1} />
        <Box
          my={3}
          display="flex"
          flexDirection={isDesktopView ? "row" : "column-reverse"}
          alignItems={isDesktopView ? "center" : "stretch"}
          justifyContent={isDesktopView ? "space-between" : "center"}
          gap={isDesktopView ? 0 : 2}
        >
          <Button
            background="tertiary"
            size="small"
            startIcon={<ArrowLeft />}
            onClick={handleBack}
            sx={{ color: palette.neutral[80] }}
          >
            Back
          </Button>
          <Button
            disabled={isDisabledSubmit}
            background="primary"
            size="small"
            type="submit"
            fullWidth={!isDesktopView}
          >
            Done
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AddTermsAndConditions;
