import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import {
  ADD_PRODUCT_ITEM_MODAL,
  GIVE_CONFIRMATION_POP_UP,
} from "modals/modal_names";
import PaymentFormItem from "./PaymentFormItem";
import { ProductItemType } from "../types";
import { useFormContext } from "react-hook-form";
import {
  DndContext,
  closestCorners,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import useCheckFormType from "../components/hooks/useCheckFormType";

const maximumAllowedCreate = 20;

const PaymentFormItems = () => {
  const { watch, setValue } = useFormContext();
  const { isEvent } = useCheckFormType();
  const items = (watch("Items") || []) as ProductItemType[];
  const isEmptyList = items?.length === 0;
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const existingItemsToDelete = watch("variantsToDeleteIds") || [];
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((x: any) => x.id === active.id);
      const newIndex = items.findIndex((x: any) => x.id === over?.id);

      const newList = arrayMove(items, oldIndex, newIndex);

      setValue("Items", newList, { shouldDirty: true });
    }
  };
  const newVariantsLength = items.filter(
    (item) => !Object.prototype.hasOwnProperty.call(item, "variantID"),
  )?.length;

  const hasUniqueTitle = (title: string, id?: string) => {
    const isNotUnique = items.some(
      (amount) => id !== amount.id && amount.title === title,
    );
    return !isNotUnique;
  };

  const handleAddItem = () => {
    NiceModal.show(ADD_PRODUCT_ITEM_MODAL, {
      onClose: (item: ProductItemType) => {
        setValue("Items", [item, ...items], {
          shouldDirty: true,
        });
      },
      hasUniqueTitle,
    });
  };

  const handleUpdateItem = (updatedItem: ProductItemType) => {
    const updatedItems = items?.map((item) => {
      if (item.id === updatedItem.id) return { ...item, ...updatedItem };

      return item;
    });

    setValue("Items", updatedItems, { shouldDirty: true });
  };

  const handleDeleteItem = (id: string, title: string) => () => {
    const itemToDelete = items?.find((item) => item?.id === id);
    const updatedItems = items?.filter((item) => item?.id !== id);
    const deleteFromDb = !!itemToDelete?.variantID;
    const handleUpdateForm = () => {
      setValue("Items", updatedItems, { shouldDirty: true });

      if (deleteFromDb)
        setValue("variantsToDelete", [...existingItemsToDelete, itemToDelete]);
      NiceModal.hide(ADD_PRODUCT_ITEM_MODAL);
    };

    NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
      modalType: "delete",
      title: "Delete Item",
      description: `Are you sure you want to delete ${title}?`,
      actions: {
        handleSuccess: {
          onClick: () => {
            handleUpdateForm();
          },
        },
      },
    });
  };

  const handleEditItem = (item: ProductItemType) => () => {
    NiceModal.show(ADD_PRODUCT_ITEM_MODAL, {
      item,
      onClose: (updatedItem: ProductItemType) => {
        handleUpdateItem(updatedItem);
      },
      hasUniqueTitle,
      handleDelete: handleDeleteItem(item?.id, item?.title),
    });
  };

  const itemName = isEvent ? "Ticket" : "Item";

  return (
    <Stack spacing={4} width="100%" height="100%">
      <GiveText variant="h3" fontWeight="300">
        {`Add ${itemName}s`}
      </GiveText>

      {!isEmptyList && (
        <TableContainer>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              <Stack gap={1}>
                {items.map((item) => {
                  return (
                    <PaymentFormItem
                      key={item?.id}
                      item={item}
                      handleUpdateItem={handleUpdateItem}
                      handleEditItem={handleEditItem(item)}
                    />
                  );
                })}
              </Stack>
            </SortableContext>
          </DndContext>
        </TableContainer>
      )}

      <StyledButton
        startIcon={<Plus size={18} />}
        label={`Add ${itemName}`}
        size="large"
        variant="filled"
        color="light"
        disabled={newVariantsLength >= maximumAllowedCreate}
        onClick={handleAddItem}
      />
    </Stack>
  );
};

const TableContainer = styled(Box)(() => ({
  flexGrow: 1,
  width: "100%",
}));

const StyledButton = styled(GiveButton)(() => ({
  width: "100%",
  height: "42px",
  border: "none",
}));

export default PaymentFormItems;
