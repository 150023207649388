import React, { useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { RHFInput } from "@common/Input";
// form
import { FormProvider } from "react-hook-form";
import { Button } from "@common/Button";
import SignupFormContainer from "./SignupFormContainer";
import { RHFCheckbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import ImagePlaceholder from "@assets/images/logo-placeholder.png";
import { KotoBackArrow } from "@assets/icons";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import useSignup from "@hooks/onboarding/useSignup";
import { useFormData } from "./SignupFormProvider";
import { EDIT_AVATAR_MODAL, TERMS_OF_SERVICE_MODAL } from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { cloneDeep, isEqual } from "lodash";
import BusinessTypeSelect from "@common/BusinessProfileInputs/BusinessTypeSelect";
import CustomTaxSsnInput from "@common/BusinessProfileInputs/CustomTaxSsnInput";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { TEditAvatarModalActions } from "@components/AvatarUpload/modal/EditAvatarModal";
import AvatarUpload from "@components/AvatarUpload/AvatarUpload";
import { IMAGE_UPLOAD_SIZE_LIMIT, SINGLE_KB } from "@constants/constants";
import { showMessage } from "@common/Toast";

type Props = {
  handleNext: () => void;
  handleBack: () => void;
  handleUpdateStatusValue: (value: number) => void;
  organizationDetailsStatusBar: number;
};

const SignupOrganizationDetails = ({
  handleBack,
  handleNext,
  handleUpdateStatusValue,
  organizationDetailsStatusBar,
}: Props) => {
  const { setFormValues, formData } = useFormData();
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const { isMobileView } = useCustomTheme();
  const isMerchant = formData?.organizationType === "merchant";
  const {
    methods,
    schema,
    onSubmit,
    handleChangeStatus,
    isLoading,
    isSuccess,
  } = useSignup({
    handleNext,
  });

  const saveOnUnmount = React.useRef<any>();

  const {
    watch,
    setValue,
    formState: { isValid, dirtyFields },
  } = methods;

  const goBackHandler = () => {
    setFormValues("organizationDetails", values);
    handleBack();
  };

  const openTermsConditions = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    NiceModal.show(TERMS_OF_SERVICE_MODAL, {
      agree: () =>
        setValue("termsConditions", true, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        }),
    });
  };

  const values = watch();
  useEffect(() => {
    (async () => {
      const condition =
        Object.keys(dirtyFields).length > 0 &&
        !isEqual(values, saveOnUnmount.current);
      if (condition) {
        const value = await calculatePercentageNested(schema, values);
        handleUpdateStatusValue(value);
      }
      saveOnUnmount.current = cloneDeep(values);
    })();
  }, [values, dirtyFields]);

  useEffect(() => {
    return () => {
      setFormValues("organizationDetails", saveOnUnmount.current);
    };
  }, []);
  const labelPlaceholder = generateNameLabelPlaceholder(
    formData?.classification.name,
  );
  const nodeList = [
    {
      node: (
        <RHFInput
          name="name"
          label={`${labelPlaceholder} Name`}
          placeholder={`${labelPlaceholder} Name`}
          fullWidth
        />
      ),
      sm: 12,
    },
    {
      node: (
        <BusinessTypeSelect
          hasArrowDownIcon
          name="businessType"
          label="Business type"
        />
      ),
      sm: 12,
    },
    {
      node: (
        <Text fontSize={18} fontWeight="book">
          Tax ID
        </Text>
      ),
      sm: 12,
    },
    {
      node: <CustomTaxSsnInput businessTypeName="businessType" />,
      sm: 12,
    },
    {
      node: (
        <RHFInput
          name="businessPurpose"
          label="Purpose & Mission"
          placeholder="Purpose & Mission"
          multiline
          rows={6}
          fullWidth
        />
      ),
      sm: 12,
    },
    {
      node: (
        <RHFCheckbox
          name="termsConditions"
          dataTestId="terms-checkBox"
          label={
            <>
              <Text
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                <Box component="span">I accept the&nbsp;</Box>
                <Box
                  data-testid="terms-button"
                  sx={{
                    textDecoration: "underline",
                  }}
                  component="span"
                  color={palette.neutral[600]}
                  onClick={openTermsConditions}
                >
                  Terms & Conditions
                </Box>
              </Text>
            </>
          }
        />
      ),
    },
  ];

  return (
    <FormProvider {...methods}>
      <SignupFormContainer
        title="Business Details"
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        avatar={
          <>
            <AvatarUpload
              size={75}
              onUpload={handleChangeStatus}
              onDelete={() => methods.setValue("logo", "")}
              defaultImageURL={
                values.logo && URL.createObjectURL(values.logo as any)
              }
              placeholder={ImagePlaceholder}
              canDelete
              canUpload
            />
          </>
        }
      >
        <Stack>
          <Grid
            direction={isMobileView ? "column" : undefined}
            container
            spacing={1.5}
          >
            {nodeList.map(({ node, sm }, index) => (
              <Grid key={index} item sm={sm || 6}>
                {node}
              </Grid>
            ))}
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              background="tertiary"
              size="small"
              startIcon={<KotoBackArrow stroke={palette.neutral[80]} />}
              onClick={goBackHandler}
            >
              Previous page
            </Button>
            <Button
              background="primary"
              size="medium"
              type="submit"
              disabled={
                !isValid ||
                !values.termsConditions ||
                isLoading ||
                organizationDetailsStatusBar < 100 ||
                isSuccess
              }
            >
              Create account
            </Button>
          </Box>
        </Stack>
      </SignupFormContainer>
    </FormProvider>
  );
};

export default SignupOrganizationDetails;

export const generateNameLabelPlaceholder = (type: string) => {
  switch (type) {
    case "iso":
      return `ISO`;
    case "psp":
      return `PSP`;
    case "fiscal_sponsor":
      return `Fiscal Sponsor`;
    case "msp":
      return `MSP`;
    case "payment_facilitator":
      return `Payment Facilitator`;
    case "reseller":
      return `Reseller`;
    case "other":
      return `Other`;
    case "profit":
      return "Merchant";
    case "non_profit":
      return "Non Profit";
    case "individual":
      return "Your";
    case "crowdfunding":
      return "Crowdfunder";
    default:
      return `Merchant`;
  }
};
