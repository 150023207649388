import NiceModal from "@ebay/nice-modal-react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import GiveTabs from "@shared/Tabs/GiveTabs";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Stack } from "@mui/material";
import {
  DonorComponent,
  DonorsSkeleton,
} from "../View/fundraiser/components/Atom.component";
import { useGetDonorsList } from "../provider/useManageApi";
import { donorModalType } from "../types";

interface DonorsModalProps {
  type: donorModalType;
  accentColor: string;
  id: string | number;
}

const DonorsModal = NiceModal.create(
  ({ type, accentColor, id }: DonorsModalProps) => {
    const { open, SlideProps } = useNiceModal();
    const [typeState, setTypeState] = useState<donorModalType>(type);

    const { donors, isLoading, hasNextPage, fetchNextPage, total } =
      useGetDonorsList({
        id,
        max: 20,
        typeState,
      });
    const [ref, inView] = useInView();

    useEffect(() => {
      if (inView) fetchNextPage();
    }, [inView]);

    return (
      <GiveBaseModal
        open={open}
        title={`${total} Donations`}
        width="560px"
        onClose={SlideProps.onExit}
        showFooter={false}
      >
        <GiveTabs
          selected={typeState}
          containerSx={{
            width: "100%",
            justifyContent: "space-around",
            padding: "4px",
          }}
          tabSx={{ flex: 1, justifyContent: "center" }}
          items={donorsTabs}
          onClick={(e) => {
            setTypeState(e as donorModalType);
          }}
          type="segmented"
        />
        <Stack mt={3}>
          {isLoading ? (
            <DonorsSkeleton count={7} />
          ) : (
            <>
              {donors?.map((donor) => (
                <DonorComponent
                  key={donor?.id}
                  accentColor={accentColor}
                  {...donor}
                />
              ))}
              {hasNextPage && (
                <div ref={ref}>
                  <DonorsSkeleton count={1} />
                </div>
              )}
            </>
          )}
        </Stack>
      </GiveBaseModal>
    );
  },
);

export default DonorsModal;

const donorsTabs = [
  {
    label: "Most Recent",
    value: "most_recent",
  },
  {
    label: "Top Donors",
    value: "top_donors",
  },
];
