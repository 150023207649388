import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { palette } from "@palette";
import * as React from "react";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
// mui
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { RHFCheckbox } from "@common/Checkbox";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFSelect } from "@common/Select";
import { Text } from "@common/Text";
import { BankFeesInfo } from "./BankFeesInfo";
// assets
import { ArrowDownChevron, InfoIcon } from "@assets/icons";
import { StatsTitle } from "@common/NewStats";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useTransferMoney } from "@hooks/merchant-api/transferMoney/useTransferMoney";
import { makeStyles } from "@mui/styles";
import { formatVariantValue, parseAmount } from "@utils/index";
import { TERMS_OF_SERVICE_MODAL } from "modals/modal_names";
import TransferModalHeader from "../TransferModalHeader";
import TransferMoneySkeleton from "./TransferMoneySkeleton";

const MIN_AMOUNT_MESSAGE = "Minimum transfer is 10.00";
const EXCEEDS_BALANCE_MESSAGE = "This amount exceeds your balance";

type IFormInputs = {
  account: string;
  amount: string;
  agree: boolean;
};

type Props = {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  balance: number;
};

const useStyles = makeStyles(() => ({
  icon: {
    pointerEvents: "none",
    position: "absolute",
    right: 12,
  },
}));

const getFee = (amount: number | string) => {
  let amountValue;
  if (typeof amount === "number") {
    amountValue = amount;
  } else {
    amountValue = getValueFromString(amount);
  }

  if (!amountValue) return "0.00";
  if (amountValue <= 999) return "0.99";
  if (amountValue > 999 && amountValue <= 20000)
    return (amountValue * 0.001).toFixed(2);
  return "20.00";
};

const getValueFromString = (amount: string) =>
  !amount ? 0 : parseFloat(amount.replaceAll(",", ""));

const MoneyTransferDetails = ({ setDisabled, setStep, balance }: Props) => {
  const theme = useTheme();
  const modal = useModal();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [infoOpen, setInfoOpen] = React.useState(false);

  const maxAmount = balance - getValueFromString(getFee(balance));
  const classes = useStyles();
  const schema = Yup.object({
    account: Yup.string().required("Choose an account"),
    amount: Yup.string()
      .test("test-amount", EXCEEDS_BALANCE_MESSAGE, function (value) {
        const { path, createError } = this;

        if (value && getValueFromString(value) < 10)
          return createError({
            path,
            message: MIN_AMOUNT_MESSAGE,
          });

        let isExceeding = false;
        if (value) isExceeding = getValueFromString(value) > maxAmount;

        return (
          !isExceeding ||
          createError({ path, message: EXCEEDS_BALANCE_MESSAGE })
        );
      })
      .required(MIN_AMOUNT_MESSAGE),
    agree: Yup.boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "Read and Agree to our Terms of Service"),
  });

  const defaultValues = {
    account: "",
    amount: "",
    agree: true,
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = methods;
  const values = watch();

  const isExceeding = getValueFromString(values.amount) > maxAmount;

  const {
    bankAccounts,
    isLoading,
    transferLoading,
    handleTransferMoneySubmit,
  } = useTransferMoney({ action: setStep });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const bankAccountID = +data.account;
    const amount = formatVariantValue(data.amount);

    if (!isNaN(bankAccountID) && !isNaN(amount)) {
      handleTransferMoneySubmit({
        bankAccountID: bankAccountID,
        amount,
      });
    }
  };

  React.useEffect(() => {
    setDisabled(!values.agree);
  }, [values.agree]);

  React.useEffect(() => {
    if (isExceeding) {
      setError("amount", { message: EXCEEDS_BALANCE_MESSAGE });
    } else {
      clearErrors("amount");
    }
    setDisabled(isExceeding);
  }, [values.amount]);

  React.useEffect(() => {
    if ((isLoading && isDirty) || transferLoading) {
      setDisabled(isLoading || transferLoading);
    } else if (isDirty && values.agree) {
      setDisabled(false);
    }
  }, [isDirty, isLoading, transferLoading]);

  const newBalance =
    balance -
    getValueFromString(values.amount) -
    getValueFromString(getFee(values.amount));

  if (isLoading) return <TransferMoneySkeleton />;

  return (
    <Box>
      <TransferModalHeader title="Send Money" onClose={() => modal.hide()} />
      <FadeUpWrapper delay={100}>
        <Stack
          mb={1}
          gap={1}
          direction={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "center" : "flex-start"}
        >
          <StatsTitle
            isAmount
            isMainTitle
            title={"Available balance"}
            value={parseAmount(balance)}
            fontSize={32}
            alignFigure="left"
            align="left"
          />
        </Stack>
      </FadeUpWrapper>
      <FormProvider {...methods}>
        <Box
          mb={1}
          component="form"
          id="transfer-money-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FadeUpWrapper delay={150}>
                <RHFSelect
                  name="account"
                  label="Select an account"
                  placeholder="Account Receiving Transfer"
                  helperText={"Only verified accounts are displayed"}
                  SelectProps={{
                    IconComponent: () => (
                      <ArrowDownChevron
                        className={classes.icon}
                        width={16}
                        height={9}
                        color={palette.gray[300]}
                      />
                    ),
                  }}
                  options={bankAccounts?.map((bankAccount: any) => ({
                    value: bankAccount.id,
                    label: bankAccount.name,
                    helperText: "*****" + bankAccount.numberLast4,
                  }))}
                />
              </FadeUpWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FadeUpWrapper delay={200}>
                <Box
                  sx={{
                    "& .amount-input-box": {
                      ...(isExceeding && {
                        boxShadow:
                          "0px 0px 4px rgba(2, 2, 2, 0.5), 0px 0px 0px 3px #FB8989",
                      }),
                    },
                  }}
                >
                  <RHFCustomAmountInput
                    name="amount"
                    label="Amount"
                    placeholder="0,00.00"
                    currency="usd"
                    bounded={false}
                    helper={
                      errors.amount ? (
                        errors.amount?.message === MIN_AMOUNT_MESSAGE ? (
                          <Text color={palette.neutral[70]} fontSize={12}>
                            {errors.amount.message}{" "}
                            <sup style={{ fontSize: "8px", lineHeight: "8px" }}>
                              USD
                            </sup>
                          </Text>
                        ) : (
                          errors.amount.message
                        )
                      ) : undefined
                    }
                  />
                </Box>
              </FadeUpWrapper>
            </Grid>
          </Grid>
          {/** ------------- BALANCE ------------- */}
          <Balance>
            <Stack>
              <FadeUpWrapper delay={250}>
                <Stack
                  mb={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text color={palette.neutral[80]} fontWeight="regular">
                    Recipient will get (USD)
                  </Text>
                  <Text color={palette.neutral[80]} fontWeight="regular">
                    {getValueFromString(values.amount) > 0
                      ? values.amount
                      : "0.00"}
                  </Text>
                </Stack>
              </FadeUpWrapper>
              <FadeUpWrapper delay={300}>
                <Stack
                  mb="4px"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack gap={1} direction="row" alignItems="center">
                    <Text sx={textStyle}>Fee (USD)</Text>
                    <Stack
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setInfoOpen(true)}
                      data-testid="fee-breakdown-button"
                    >
                      <InfoIcon
                        width={16}
                        height={16}
                        path_fill={palette.gray[100]}
                      />
                    </Stack>
                  </Stack>
                  <Text sx={textStyle} data-testid="send-money-fee">
                    {getFee(values.amount)}
                  </Text>
                </Stack>
                <Text fontSize={12} color={palette.neutral[70]}>
                  Fee deducted from the available balance
                </Text>
              </FadeUpWrapper>
            </Stack>

            <FadeUpWrapper delay={350}>
              <Stack
                direction={"row"}
                alignItems={isDesktop ? "center" : "flex-start"}
                justifyContent={"space-between"}
              >
                <Text sx={textStyle}>
                  Amount to be deducted from balance (USD)
                </Text>
                <Text sx={textStyle}>
                  {parseAmount(
                    getValueFromString(values.amount) +
                      getValueFromString(getFee(values.amount)),
                  )}
                </Text>
              </Stack>
            </FadeUpWrapper>
            <FadeUpWrapper delay={400}>
              <Divider sx={{ my: 1 }} />

              <Stack
                direction={"row"}
                alignItems={isDesktop ? "center" : "flex-start"}
                justifyContent={"space-between"}
              >
                <Text sx={textStyle}>New balance (USD)</Text>
                <Text sx={textStyle} data-testid="new-balance-value">
                  {parseAmount(newBalance)}
                </Text>
              </Stack>
            </FadeUpWrapper>
          </Balance>

          <FadeUpWrapper delay={450}>
            <Stack direction="row" alignItems="center">
              <RHFCheckbox
                name="agree"
                sx={{ "& .MuiCheckbox-root": { paddingRight: "0px" } }}
                dataTestId="money-transfer-t&c-checkbox"
              />
              <Box>
                <Text
                  fontWeight="light"
                  color={palette.neutral[950]}
                  data-testid="money-transfer-t&c"
                >
                  I agree to the{" "}
                  <Link
                    color="inherit"
                    fontWeight="light"
                    onClick={() => NiceModal.show(TERMS_OF_SERVICE_MODAL)}
                    sx={{ cursor: "pointer" }}
                  >
                    Terms & Conditions
                  </Link>
                </Text>
                {errors.agree && (
                  <Text
                    variant="caption"
                    fontWeight="light"
                    color={palette.neutral[70]}
                  >
                    {errors.agree.message}
                  </Text>
                )}
              </Box>
            </Stack>
          </FadeUpWrapper>
        </Box>
      </FormProvider>
      <BankFeesInfo open={infoOpen} setOpen={setInfoOpen} />
    </Box>
  );
};

const textStyle = {
  fontWeight: 300,
  color: palette.neutral[80],
};

export const displayAmount = (amount: string) => {
  return (
    <Text
      fontSize={18}
      variant="headline"
      fontWeight="regular"
      color={palette.neutral[80]}
    >
      {amount}
    </Text>
  );
};

const Balance = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  backgroundColor: palette.neutral.white,
  borderRadius: "8px",
  padding: "16px",

  "& .MuiDivider-root": {
    background: palette.liftedWhite[100],
  },

  "& sup": {
    fontSize: 9,
    lineHeight: "9px",
  },
}));

export default MoneyTransferDetails;
