import React from "react";
import GivePagination from "@shared/Table/GivePagination";
import { PaginationItem as PaginationItemType } from "@shared/Table/GiveTable.types";
import { styled } from "@theme/v2/Provider";
import { Box } from "@mui/material";

type Props = {
  numberOfPages: number;
  page: number;
  setPageDispatcher: React.Dispatch<React.SetStateAction<number>>;
};

const TablePagination = ({ numberOfPages, page, setPageDispatcher }: Props) => {
  const onNextPage = () => {
    page !== numberOfPages && setPageDispatcher((p: number) => p + 1);
  };

  const onPreviousPage = () => {
    page !== 1 && setPageDispatcher((p: number) => p - 1);
  };

  const isStart = page < 5;
  const isEnd = page > numberOfPages - 4;

  const fewPages = Array.from(
    { length: numberOfPages },
    (_, index) => index + 1,
  );
  const startPages = [1, 2, 3, 4, 5, "space", numberOfPages];
  const endPages = [
    1,
    "space",
    numberOfPages - 4,
    numberOfPages - 3,
    numberOfPages - 2,
    numberOfPages - 1,
    numberOfPages,
  ];

  const middlePages = [
    1,
    "space",
    page - 1,
    page,
    page + 1,
    "space",
    numberOfPages,
  ];

  const pages =
    numberOfPages < 8
      ? fewPages
      : isStart
      ? startPages
      : isEnd
      ? endPages
      : middlePages;

  return (
    <Container>
      <GivePagination
        currentPage={page}
        onClick={setPageDispatcher}
        pages={pages as PaginationItemType[]}
        previousDisabled={page === 1}
        nextDisabled={page === numberOfPages}
        onClickNext={onNextPage}
        onClickPrevious={onPreviousPage}
      />
    </Container>
  );
};

export default TablePagination;

const Container = styled(Box)({
  padding: "20px 0",
});
