export const isAmex = (cardNumber: string) =>
  cardNumber.startsWith("37") || cardNumber.startsWith("34");

export const isValidCardNumber = (cardNumber: string) => {
  if (cardNumber.length < 2) return false;
  if (isAmex(cardNumber) && cardNumber.length < 15) return false;
  if (!isAmex(cardNumber) && cardNumber.length < 19) return false;
  return true;
};

export const isNonValidExpirationDate = (expiration: string) => {
  const month = expiration.substring(0, 2);
  const year = expiration.substring(5, 7);
  if (month.length < 2 || year.length < 2) return true;

  const today = new Date();

  //Credit cards are usually valid until the last day of the specified month
  const expiryDate = new Date(
    parseInt(today.getFullYear().toString().substring(0, 2) + year),
    +month,
    0, // Day 0 of the next month gives the last day of the current month
  );

  if (expiryDate < today) return true;

  return false;
};

export const normalizeInput = (value: string) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 3) return currentValue;
  if (cvLength < 5)
    return `${
      parseInt(currentValue.slice(0, 2)) > 12 ? 12 : currentValue.slice(0, 2)
    } / ${currentValue.slice(2, 5)}`;
};
