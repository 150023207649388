import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { NEW_ACTION_DENY_MESSAGE } from "@constants/permissions";
import { useGetUploadFilePermission } from "@hooks/merchant-api/manage-money/useUploadBankAccountDocument";
import {
  AcceptAllowedGeneralDocumentsTypes,
  fiveMB,
  useUploadFiles,
} from "@hooks/upload-api/uploadHooks";
import { Stack, SxProps } from "@mui/material";
import GiveUploadArea from "@shared/FileUpload/GiveUploadArea";
import GiveUploadStack from "@shared/FileUpload/GiveUploadStack";
import GiveText from "@shared/Text/GiveText";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { checkPortals } from "@utils/routing";
import { useQueryClient } from "react-query";
import useDeleteDocument from "../hooks/useDeleteDocument";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "../../constants";
import { GiveUploadItemProps } from "@shared/FileUpload/types";
import moment from "moment";

const DocumentsSection = ({
  id,
  data,
  isEnterprise = false,
  title,
  customUploadStackStyles,
  isHideDelete = false,
  challengeID,
}: {
  id: number;
  challengeID?: number;
  data: TMerchantDocument[];
  isEnterprise?: boolean;
  title?: string;
  customUploadStackStyles?: SxProps;
  isHideDelete?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { isAcquirerPortal, isEnterprisePortal } = checkPortals();
  const { isMobileView } = useCustomThemeV2();
  const isUploadAllowed = useGetUploadFilePermission(isEnterprise);
  const tag = `${
    isAcquirerPortal ? "Acquirer" : isEnterprisePortal ? "Provider" : "Merchant"
  } upload`;
  const { handleUpload } = useUploadFiles();
  const { deleteDocument } = useDeleteDocument();

  const handleDeleteDocument = (fileName: string, fileId: number) => {
    deleteDocument(id, { id: fileId, fileName }, () =>
      queryClient.refetchQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET),
    );
  };

  const attemptUpload = async (file: File) => {
    await handleUpload(
      {
        list: [{ file }],
        merchantId: id,
        resourceID: id,
        attachmentType: "underwriting_profile",
        label: "",
        tag,
        refetcherKey: ["get-merchant-preview"],
      },
      undefined,
      challengeID,
    );
    queryClient.invalidateQueries("get-merchant-msp-status");
    queryClient.invalidateQueries(["read-challenge-files", id, challengeID]);
    queryClient.refetchQueries("get-merchant-preview");
  };

  const parsedData = parseDocumentsList({
    documents: data,
    id: id,
    handleDeleteDocument: handleDeleteDocument,
  });
  return (
    <Stack gap="20px">
      {title && <GiveText>{title}</GiveText>}
      <GiveTooltip
        width="compact"
        alignment="left"
        title={NEW_ACTION_DENY_MESSAGE}
        color="default"
        disableHoverListener={isUploadAllowed}
      >
        <GiveUploadArea
          message="Up to 5MB each (.pdf, .png, .jpg, .jpeg, .webp)"
          disabled={!isUploadAllowed}
          uploadFunction={attemptUpload}
          accept={AcceptAllowedGeneralDocumentsTypes}
          maxFiles={5}
          multiple
          isMobile={isMobileView}
          maxSizeInBytes={fiveMB}
        />
      </GiveTooltip>
      {parsedData?.length > 0 && (
        <GiveUploadStack
          items={parsedData}
          customStyles={customUploadStackStyles}
          isHideDelete={isHideDelete}
        />
      )}
    </Stack>
  );
};

export const parseDocumentsList = ({
  documents,
  id,
  handleDeleteDocument,
}: {
  documents: TMerchantDocument[];
  id: number;
  handleDeleteDocument: (fileName: string, fileId: number) => void;
}) => {
  const parsedData: GiveUploadItemProps[] = documents?.map((document) => {
    const {
      tag,
      id: fileId,
      attTypeName,
      userFullName,
      fileName,
      updatedAt,
    } = document;
    return {
      merchantId: id,
      state: "uploaded",
      value: tag || attTypeName,
      setValue: () => null,
      byMessage: userFullName ? `by ${userFullName}` : "",
      dateMessage: moment.unix(updatedAt).format("MMM DD, YYYY, HH:mm"),
      fileData: document,
      setTagWithApi: true,
      onDelete: () => handleDeleteDocument(fileName, fileId),
    };
  });
  return parsedData;
};
export default DocumentsSection;
