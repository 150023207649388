import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import { Box, Grid } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import { BANK_DETAILS } from "../../../constants";

function BankDisclosure() {
  const { isMobileView } = useCustomThemeV2();

  return (
    <SectionCardBase sx={{ mt: "20px" }} leftTitle="Bank Disclosure">
      {BANK_DETAILS.map(({ name, addr1, addr2 }, index) => {
        return (
          <Grid
            mb={!isMobileView ? "12px" : 0}
            spacing="8px"
            container
            key={name}
          >
            <Grid item xs={12} sm={6}>
              <GiveText variant="bodyS" color="secondary">
                {name}
              </GiveText>
            </Grid>

            <Grid item xs={12} sm={6}>
              <GiveText variant="bodyS">
                {addr1}
                <br />
                {addr2}
              </GiveText>
            </Grid>

            <Box width="100%" px="8px">
              {index < BANK_DETAILS.length - 1 && isMobileView && (
                <GiveDivider />
              )}
            </Box>
          </Grid>
        );
      })}
    </SectionCardBase>
  );
}

export default BankDisclosure;
