import { useNavigate } from "react-router-dom";
// nice-modal
import NiceModal from "@ebay/nice-modal-react";
// mui
// components
import { showMessage } from "@common/Toast/ShowToast";
// localization
import GivePopup from "@shared/Popup/GivePopup";
import { Monitor } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { Stack } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type GiveIntroduceADBModalProps = {
  data: any;
  productId: number;
};

const GiveIntroduceADBModal = NiceModal.create(
  ({ data, productId }: GiveIntroduceADBModalProps) => {
    const { open, modal } = useNiceModal();
    const navigate = useNavigate();
    const { isDesktopView, isMobileView } = useCustomThemeV2();
    const { palette } = useAppTheme();

    const handleClose = () => {
      modal.hide();
    };

    const handleNotNow = () => {
      handleClose();
      showMessage(
        "Success",
        <>
          Your new Payment Form{" "}
          <span style={{ fontWeight: "600" }}>“{data.about.title}”</span> has
          been successfully created and it’s ready to accept payments
        </>,
        isDesktopView,
        "Payment Form Created",
      );
    };

    const handleTryNow = () => {
      handleClose();
      navigate("/merchant/new-advanced-builder", {
        state: {
          ...data,
          productId: productId,
          productTitle: data?.about?.title,
          headerImageUrl:
            data?.style?.useAsBackground && data?.style?.image?.URL,
          description: data?.about?.description,
          campaign: data?.campaign,
        },
      });
    };

    return (
      <GivePopup
        open={open}
        description={
          <GiveText variant="bodyS" color="secondary">
            {formText.intoduce_advanced_form_builder_description}
          </GiveText>
        }
        paperSx={{ height: "fit-content !important" }}
        sx={{
          "& .MuiDialogActions-root": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
          },
        }}
        onClose={handleNotNow}
        iconType={"advanced-builder"}
        title={formText.intoduce_advanced_form_builder}
        type="success"
        isMobile={isMobileView}
        data-testid="advanced-form-builder-modal"
        buttons={[
          {
            label: "Cancel",
            onClick: handleNotNow,
            variant: "ghost",
            sxButton: { flex: 1 },
          },
          {
            label: formText.open_advanced_builder,
            onClick: handleTryNow,
            variant: "filled",
            sxButton: { flex: 3 },
            disabled: !isDesktopView,
          },
        ]}
        {...(isMobileView && {
          inputNodes: [
            {
              element: (
                <Stack
                  direction="row"
                  gap="12px"
                  padding="12px"
                  borderRadius="8px"
                  alignItems="center"
                  sx={{
                    background: palette.primitive?.transparent["darken-5"],
                  }}
                >
                  <Monitor size={20} />
                  <GiveText variant="bodyS">
                    {formText.switch_to_desktop}
                  </GiveText>
                </Stack>
              ),
            },
          ],
        })}
      />
    );
  },
);

export default GiveIntroduceADBModal;

// text to be added to localization later
const formText = {
  intoduce_advanced_form_builder: "Introducing Advanced Form Builder",
  intoduce_advanced_form_builder_description:
    "Your payment form has been created! Resume building your form with whole new additional features and interactive options to increase audience engagement.",
  skip: "Cancel",
  open_advanced_builder: "Yes, Open Advanced Builder",
  switch_to_desktop: "Please switch to desktop to access this feature.",
};
