import { customInstance } from "@services/api";
import { useQuery } from "react-query";

export const useReadChallengeFiles = ({
  challengeId,
  merchantId,
}: {
  challengeId: number;
  merchantId: number;
}) => {
  return useQuery(
    ["read-challenge-files", merchantId, challengeId],
    async ({ queryKey }) =>
      await customInstance({
        url: `/merchants/${queryKey[1]}/underwriting-challenges/${queryKey[2]}/files`,
        method: "GET",
      }),
    {
      enabled: !!merchantId && !!challengeId,
    },
  );
};
