import { RHFCheckbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { MerchantSectionContainer } from "@components/Merchants/MerchantPreview/components/atoms";
import NiceModal from "@ebay/nice-modal-react";
import { Grid } from "@mui/material";
import { palette } from "@palette";
import { CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL } from "modals/modal_names";
import { useFormContext } from "react-hook-form";
import { TCreatePrimaryAccountHolder } from "../types";
import { AssignToMe } from "../modals/components/AssignToMe";
import PreviewInvitationButton from "@components/Merchants/Common/PreviewInvitationButton/PreviewInvitationButton";
import { checkPortals } from "@utils/routing";
import { useGetCurrentMerchantId } from "@hooks/common";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { useMemo } from "react";

export const CreatePrimaryAccountHolder = () => {
  const { watch, setValue } = useFormContext();
  const { isMasqueradeMode } = useMasqueradeReducer();
  const selectedUser = useAppSelector(selectSelectedAccount);
  const values = watch();

  const handleClosePrimaryAccountHolder = (
    data: TCreatePrimaryAccountHolder,
  ) => {
    setValue("primaryAccountHolder", data);
  };

  const editHandler = () => {
    NiceModal.show(CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL, {
      onClose: handleClosePrimaryAccountHolder,
      data: values.primaryAccountHolder,
    });
  };

  const { email, assignToMe } = values.primaryAccountHolder || {};
  const { merchantName } = values.merchantInfo || {};

  const { isEnterprisePortal } = checkPortals();
  const { img } = useGetCurrentMerchantId();

  const correctProviderUrl = useMemo(() => {
    const enterpriseUsrImg = isMasqueradeMode ? img : selectedUser?.img ?? "";
    return isEnterprisePortal
      ? enterpriseUsrImg
      : values.merchantInfo.enterpriseImageUrl;
  }, [isMasqueradeMode, isEnterprisePortal, values]);

  if (!email && !assignToMe) return null;


  return (
    <MerchantSectionContainer
      sectionTitle="Primary Account Holder"
      editHandler={editHandler}
    >
      {assignToMe ? (
        <AssignToMe showCheckbox={false} />
      ) : (
        <Grid container rowSpacing={0.5} columnSpacing={2}>
          <Grid item xs={12} sm={4.5}>
            <Text fontWeight="light" color={palette.neutral[80]}>
              Email
            </Text>
          </Grid>
          <Grid item xs={12} sm={7.5}>
            <Text fontWeight="book" color={palette.neutral[80]}>
              {values.primaryAccountHolder?.email}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <RHFCheckbox
              name="primaryAccountHolder.invite"
              label="Invite upon creation"
            />
          </Grid>
          <Grid item xs={12} display="flex">
            <PreviewInvitationButton
              title="Preview Invitation"
              senderName={merchantName}
              senderLogo={correctProviderUrl}
              isSendingToProvider={false}
            />
          </Grid>
        </Grid>
      )}
    </MerchantSectionContainer>
  );
};

export default CreatePrimaryAccountHolder;
