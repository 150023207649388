import { Text } from "@common/Text";
import { Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { CheckCircle, CircleDashed } from "@phosphor-icons/react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useRef } from "react";
import { useGetChallengesStats } from "../../manageUnderwriting/Challenges/hooks/useGetChallengesStats";
import StatusReviewAssignmetComponent from "./components/StatusReviewAssignmetComponent";
import { Container, HeaderText } from "./components/StatusReviewAtoms";
import SuspendButton from "./components/SuspendButton";
import { useStatusReview } from "../../hooks/useStatusReview";

export const UnderwritingReportSummary = ({
  data,
  merchantId,
  underwritingScorePercentage,
  status,
  handleManageUnderwriting,
}: any) => {
  const { isMobileView } = useCustomTheme();
  const { handleSuspend } = useStatusReview({ data });


  const { data: challengesStatsData } = useGetChallengesStats({
    merchantId,
  });

  const sizes = useRef({
    total: 0,
    completed: 0,
    ready: 0,
    opened: 0,
  });

  if (challengesStatsData) {
    const total =
      challengesStatsData.closedChallengesCount +
      challengesStatsData.openChallengesCount +
      challengesStatsData.readyForVerificationChallengesCount;

    sizes.current = {
      total,
      completed: challengesStatsData.closedChallengesCount,
      ready: challengesStatsData.readyForVerificationChallengesCount,
      opened: challengesStatsData.openChallengesCount,
    };
  }

  return (
    <Container
      sx={{
        borderRadius: "12px",
        boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
      }}
      component="form"
      isMobile={isMobileView}
      padding="16px"
      data-testid="underwriting-report"
    >
      <Stack spacing={3}>
        <Header container isMobile={isMobileView}>
          <HeaderText isMobile={isMobileView} sx={{ flexGrow: 1 }}>Underwriting Report</HeaderText>
          <SuspendButton
            statusName={data.statusName}
            onClick={() => handleSuspend()}
          />
          <Text
            color={status?.color}
            sx={{
              textAlign: "center",
              borderRadius: "16px",
              backgroundColor: status?.bg_color,
              padding: "2px 16px 2px 16px",
            }}
          >
            {status?.label}
          </Text>
        </Header>

        <Stack spacing={2}>
          <Text
            fontSize="24px"
            fontWeight="book"
            color={palette.neutral[80]}
            data-testid="underwriting-percentage-text"
          >
            {underwritingScorePercentage}%
          </Text>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            data-testid="underwriting-percentage-bar"
          >
            <Bar
              customStyle={{
                background: palette.filled.success,
                display: sizes.current.completed > 0 ? "block" : "none",
              }}
              width={(100 * sizes.current.completed) / sizes.current.total}
            />
            <Bar
              customStyle={{
                border: `1px solid ${palette.filled.success}`,
                display: sizes.current.ready > 0 ? "block" : "none",
              }}
              width={(100 * sizes.current.ready) / sizes.current.total}
            />
            <Bar
              customStyle={{
                background: palette.neutral[50],
                display: sizes.current.opened > 0 ? "block" : "none",
              }}
              width={(100 * sizes.current.opened) / sizes.current.total}
            />
          </Grid>

          <ReportStatusContainer isMobile={isMobileView}>
            <Grid
              display="flex"
              alignItems="center"
              data-testid="completed-count-text"
              gap="4px"
            >
              <CheckCircle
                size={24}
                color={palette.filled.success}
                weight="fill"
              />
              <Text
                fontWeight="book"
                color={palette.neutral[80]}
                data-testid="underwriting-report-completed-text"
              >
                Completed ({sizes.current.completed})
              </Text>
            </Grid>
            <Grid
              display="flex"
              alignItems="center"
              data-testid="ready-for-confirmation-count-text"
              gap="4px"
            >
              <CheckCircle size={24} color={palette.filled.success} />
              <Text fontWeight="book" color={palette.neutral[80]}>
                Ready for confirmation ({sizes.current.ready})
              </Text>
            </Grid>
            <Grid
              display="flex"
              alignItems="center"
              data-testid="open-count-text"
              gap="4px"
            >
              <CircleDashed size={24} color={palette.neutral[60]} />
              <Text fontWeight="book" color={palette.neutral[80]}>
                Open ({sizes.current.opened})
              </Text>
            </Grid>
          </ReportStatusContainer>
        </Stack>
        <Grid
          container
          paddingX="4px"
          alignItems="center"
          justifyContent="space-between"
        >
          <StatusReviewAssignmetComponent
            isRefactored
            data={data}
            handleManageUnderwriting={handleManageUnderwriting}
          />
        </Grid>
      </Stack>
    </Container>
  );
};

function Bar({ width, customStyle }: any) {
  return (
    <div
      style={{
        padding: 2,
        width: `${Math.floor(width)}%`,
      }}
    >
      <div
        style={{
          borderRadius: 4,
          height: 8,
          ...customStyle,
        }}
      />
    </div>
  );
}

const Header = styled(Grid, {
  shouldForwardProp: (prop: string) => prop !== "isMobile",
})(({ isMobile }: { isMobile: boolean }) => ({
  alignItems: isMobile ? "flex-start" : "center",
  justifyContent: "space-between",
  flexDirection: isMobile ? "column" : "row",
  gap: isMobile ? "12px" : "0",
}));

const ReportStatusContainer = styled(Stack, {
  shouldForwardProp: (prop: string) => prop !== "isMobile",
})(({ isMobile }: { isMobile: boolean }) => ({
  flexDirection: isMobile ? "column" : "row",
  gap: isMobile ? "8px" : "24px",
  alignItems: isMobile ? "flex-start" : "center",
}));
