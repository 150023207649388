// this is required because the break points for rebranded flow and old flow is different

import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

export const useDynamicTheme = () => {
  const { isRebrandingTableEnabled } = useGetFeatureFlagValues();
  const { isDesktopView: desktopOld, isMobileView: mobileOld } = useCustomTheme();
  const { isDesktopView: desktopNew, isWideView, isMobileView: mobileNew } = useCustomThemeV2();

  return {
    isDesktopView: isRebrandingTableEnabled ? desktopNew : desktopOld,
    isWideView: isRebrandingTableEnabled ? isWideView : desktopOld,
    isMobileView: isRebrandingTableEnabled ? mobileNew : mobileOld,
  };
};
