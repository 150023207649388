import { Box, Stack } from "@mui/material";
import GiveProgressBar from "@shared/ProgressBar/GiveProgressBar";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import {
  BlueBadge,
  ColouredButton,
  TranslucentButton,
} from "./components/Atom.component";
import { ShieldCheck } from "@phosphor-icons/react";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import NiceModal from "@ebay/nice-modal-react";
import PublicURLShareModal from "@sections/PayBuilder/LaunchStep/PublicURLShare/PublicURLShareModal";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import RecentDonors from "./components/RecentDonors";
import { parseAmount } from "@utils/index";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import { useGetFundraiserVariants } from "@sections/PayBuilder/provider/useManageApi";
import { isEmpty } from "lodash";
import { parsePriceToInteger } from "@utils/helpers";
import { useAddToCart } from "@hooks/merchant-api/cart";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import CheckoutButtonWithSpinner from "@sections/PayBuilder/items/CheckoutButtonWithSpinner";
import { useState } from "react";

type Props = {
  accentColor: string;
  isTabletContextView?: boolean;
  isMobileContextView?: boolean;
  showButtons?: boolean;
  isPreview?: boolean;
  formValues: any;
};

function FundraiserRightCard({
  showButtons = true,
  isPreview,
  accentColor,
  isTabletContextView,
  isMobileContextView,
  formValues: {
    showDonationTarget,
    donationTarget,
    showDonationHistory,
    totalContribution,
    isApprovedNonProfit,
    isExistingForm,
    checkoutText,
  },
}: Props) {
  const { data } = useGetMerchantById({
    enabled: !isExistingForm,
  });
  const { methods, data: formData } = usePayBuilderForm();
  const values = methods.watch();
  const suggestedAmount = values.Donations?.suggestedAmount;
  const optionalAmounts = values?.Donations?.optionalAmounts;

  const enableSuggestedAmounts = values.Donations?.allowCustomAmount;
  const amountToUs =
    !isEmpty(optionalAmounts) &&
    !isEmpty(suggestedAmount) &&
    enableSuggestedAmounts
      ? suggestedAmount
      : !isEmpty(optionalAmounts) && enableSuggestedAmounts
      ? optionalAmounts?.[0]
      : "1.00";

  const { cartItems, addToCart, clearCart } = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const { addToCartHandler } = useAddToCart({
    disableFetchCart: true,
    newCartItems: cartItems,
    showModal: false,
  });
  const { id } = useParams();
  const { data: variants } = useGetFundraiserVariants(id || "");
  const { isFundraiser } = useCheckFormType();
  const { isTabletView, isMobileView } = useCustomThemeV2();
  const isTabletOrMobile =
    (isTabletContextView ?? isTabletView) ||
    (isMobileContextView ?? isMobileView);
  const theme = useAppTheme();
  const navigate = useNavigate();

  const contribution = isPreview
    ? `${parseAmount(formatString(donationTarget || "2,000") * 0.3)}`
    : totalContribution;
  const percentage = calculatePercentage(contribution, donationTarget);
  const isObjectiveAchieved = percentage >= 100;

  const isDonationTargetEnabledAndFilled =
    showDonationTarget && donationTarget && contribution;
  const hasContribution = Number((contribution || "0")?.replace(/,/g, "")) > 0;
  const isMerchantVerified =
    data?.class?.name === "non_profit" && data?.statusName === "approved";
  // If product exists, we can rely on value sent from BE, otherwise we can read the value from merchants/id endpoint
  const showNonProfitBadge = isExistingForm
    ? isApprovedNonProfit
    : isMerchantVerified;

  const handleGoToCheckout = async () => {
    if (isEmpty(variants) || isEmpty(variants?.data) || !id) {
      return;
    }
    setIsLoading(true);
    clearCart();
    const variant = variants?.data[0];
    const initPrice = amountToUs || "1.00";
    const cartItem = {
      id: String(variant.id),
      productVariantName: variant.name,
      productVariantID: variant.id,
      productVariantPrice: parsePriceToInteger(initPrice, false),
      quantity: 1,
      unitPrice: 1,
      productVariantImageURL: variant.imageURL || "",
      recurringIntervalName: variant.defaultRecurringIntervalName || "once",
      in_stock: 1,
      price: parsePriceToInteger(initPrice, false),
    };

    addToCart(cartItem, 1, undefined, true, isFundraiser);

    await addToCartHandler(() =>
      navigate(`/${id}/checkout`, {
        state: {
          initPrice,
        },
      }),
    );

    setIsLoading(false);
  };
  return (
    <Container>
      {Boolean(contribution) && (
        <CustomText lineHeight="100%" mb="4px" fontSize="36px">
          $ {parseAmount(contribution)}{" "}
          {!isDonationTargetEnabledAndFilled && (
            <GiveText variant="bodyM" color="primary" component="span">
              Raised
            </GiveText>
          )}
        </CustomText>
      )}

      <Box>
        {isDonationTargetEnabledAndFilled && (
          <CustomText mb="14px" fontSize="16px">
            Raised of $ {parseAmount(donationTarget)} target
          </CustomText>
        )}
        {isDonationTargetEnabledAndFilled && hasContribution && (
          <GiveProgressBar
            showDot={false}
            customColor={
              isObjectiveAchieved
                ? theme.palette.primitive?.success[50]
                : accentColor
            }
            type="default"
            value={percentage}
            variant="custom"
          />
        )}
      </Box>
      {showButtons && (
        <>
          <Stack
            gap="8px"
            flexDirection={isTabletOrMobile ? "row" : "column"}
            my="20px"
          >
            <CheckoutButtonWithSpinner isLoading={isLoading}>
              <ColouredButton
                label={checkoutText}
                variant="filled"
                disabled={isLoading}
                size="small"
                accentColor={accentColor}
                onClick={handleGoToCheckout}
              />
            </CheckoutButtonWithSpinner>

            <TranslucentButton
              label="Share"
              variant="filled"
              size="small"
              onClick={() => {
                id &&
                  NiceModal.show(PublicURLShareModal, { idFromProps: id + "" });
              }}
            />
          </Stack>
          {showNonProfitBadge && (
            <BlueBadge>
              <ShieldCheck size="18px" />
              <GiveText variant="bodyXS" color="blue">
                Verified Non-Profit
              </GiveText>
            </BlueBadge>
          )}
        </>
      )}
      {showDonationHistory && (
        <RecentDonors isPreview={isPreview} accentColor={accentColor} />
      )}
    </Container>
  );
}

export default FundraiserRightCard;

const Container = styled(Box)(({ theme }) => {
  return {
    width: "100%",
    padding: "20px",
    paddingTop: "36px",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.primitive?.transparent["darken-10"]} `,
  };
});

const CustomText = styled(GiveText)(() => {
  return {
    wordWrap: "break-word",
  };
});
const formatString = (val: string) =>
  Number(val.replace(/,/g, "").replace(/\.\d+$/, ""));
const calculatePercentage = (totalContribution = "", donationTarget = "") => {
  return (formatString(totalContribution) / formatString(donationTarget)) * 100;
};
