import { SxProps } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { MouseEventHandler } from "react";

type Props = {
  showToolTip: boolean;
  children: any;
  message?: string;
  fullWidth?: boolean;
  customStyle?: any;
  extraMessage?: string;
  maxWidth?: string;
  placement?: "bottom" | "left" | "top" | "right" | "bottom-end";
  alignment?: "start" | "center" | "end";
  customTooltipStyles?: SxProps;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const CustomToolTip = ({
  children,
  showToolTip,
  message = "The edit is blocked by a confirmed match on PEP",
  customStyle,
  extraMessage,
  placement,
  alignment,
  fullWidth,
  customTooltipStyles,
  onClick,
}: Props) => (
  <GiveTooltip
    disableFocusListener={!showToolTip}
    disableHoverListener={!showToolTip}
    disableTouchListener={!showToolTip}
    color="default"
    placement={placement}
    alignment={alignment}
    width={fullWidth ? "Max width" : "compact"}
    customTooltipStyles={customTooltipStyles}
    onClick={onClick}
    title={
      <GiveText
        variant="bodyXS"
        textAlign={customStyle?.textAlign || "left"}
        color="default"
      >
        {message}
        {extraMessage}
      </GiveText>
    }
  >
    {children}
  </GiveTooltip>
);
