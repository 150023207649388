import { useAppSelector } from "@redux/hooks";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { createComponentRepository } from "../createComponentRepository";
import { IComponentRepository, RepositoryConfigs } from "../types";
import { useRepository } from "../WithRepositoryContext";
import {
  TCategoryStats,
  TChallange,
  TChallengeCategory,
  TChallengeRepositories,
  TChallengeStatus,
} from "./types";
import { selectQueryString } from "@redux/slices/search";

export const useChallengesRepository = <K extends keyof TChallengeRepositories>(
  tag: K,
) => {
  return useRepository<keyof TChallengeRepositories, TChallengeRepositories, K>(
    tag,
  );
};

export const useConfigureChallenges = () => {
  const { id } = useMerchantSidePanelContext();

  const configs = {
    all_challenges: {
      baseUrl: `/merchants/${id}/underwriting-challenges`,
      tag: "all_challenges",
    },
    challenges_categories: {
      baseUrl: `/merchants/${id}/underwriting-challenge-categories`,
      tag: "challenges_categories",
    },
    challenges_stats: {
      baseUrl: `/merchants/${id}/underwriting-challenges/stats`,
      tag: "challenges_stats",
    },
  } satisfies RepositoryConfigs<keyof TChallengeRepositories>;

  const repositories = {
    challenges_categories: createComponentRepository<TChallengeCategory>(),
    challenges_stats: createComponentRepository<TCategoryStats>(),
    all_challenges: createComponentRepository<TChallange>(),
  } satisfies {
    [K in keyof TChallengeRepositories]: IComponentRepository<
      TChallengeRepositories[K]
    >;
  };

  return {
    repositories,
    configs,
  };
};

export const useGetChallenges = (filter = "") => {
  const { useGetItems: useGetChallengesCategories } = useChallengesRepository(
    "challenges_categories",
  );
  const { useGetItems: useGetChallengesStats } =
    useChallengesRepository("challenges_stats");
  const {
    data: challengesCategoriesData,
    isLoading: areChallengesCategoriesLoading,
  } = useGetChallengesCategories(`?${filter}sort=sortOrderPriority`);
  const { data: challengesStatsData, isLoading: areChallengesStatsLoading } =
    useGetChallengesStats("");

  return {
    data: {
      challengesStatsData,
      challengesCategoriesData,
    },
    isLoading: areChallengesStatsLoading || areChallengesCategoriesLoading,
  };
};

export const useGetSpecificChallengeData = (
  challengeIdentifier: string,
  statusFilters: TChallengeStatus[],
  userTaggedInFilter: string,
  hasUnreadMessagesFilter: string,
) => {
  const { useGetItems: useGetChallengesList } =
    useChallengesRepository("all_challenges");
  const searchText = useAppSelector((state) =>
    selectQueryString(state, "items_all_challenges"),
  );
  const status = statusFilters.map(
    (status) => `statusName:"${filterMapper[status]}"`,
  );
  let filterParam = challengeIdentifier
    ? `?filter=%3B(categoryName:"${challengeIdentifier}")`
    : "";
  if (status.length > 0) filterParam += `%3B(${status.join(",")})`;
  if (hasUnreadMessagesFilter) filterParam += hasUnreadMessagesFilter;
  if (userTaggedInFilter) filterParam += userTaggedInFilter;
  const searchParam = searchText ? `&q="${searchText}"` : "";
  const {
    data: challengesList,
    isLoading: isChallengesListLoading,
    error,
  } = useGetChallengesList(`${filterParam}${searchParam}`);

  const { useGetItems } = useChallengesRepository("challenges_categories");
  const { data: categories, isLoading: areStatsLoading } = useGetItems(
    "?sort=sortOrderPriority",
  );
  return {
    data: {
      categories,
      challengesList,
    },
    isLoading: areStatsLoading && isChallengesListLoading,
    isLoadingList: isChallengesListLoading,
    error,
  };
};

const filterMapper = {
  open: 1,
  ready_for_verification: 2,
  closed: 3,
};
