import NiceModal from "@ebay/nice-modal-react";
// components
import BankAccountStatusApprovalPopup from "@components/Merchants/MerchantPreview/modals/BankAccountStatusApproval";
import BankAccountStatusDeclinePopup from "@components/Merchants/MerchantPreview/modals/BankAccountStatusDeclinePopup";
import ViewTransaction from "@components/Merchants/ViewTransaction/ViewTransaction";
import { RemoveWatchlist } from "@components/Merchants/Watchlist";
import UploadStatement from "@components/BankAccounts/UploadStatement";
import MasqueradeModal from "@components/Merchants/Masquerade/MasqueradeModal";
import ProcessingMakeRecuring from "@components/Processing/ProcessingMakeRecurring";
import ProcessingSendReceipt from "@components/Processing/ProcessingSendReceipt";
import { ViewCustomer } from "@components/Processing/ViewCustomer";
import {
  Refund,
  Cancel,
} from "@components/ManageMoney/TransactionTable/TableActions";
import { DownloadReport } from "sections";
import ProcessingModifyRecurring from "@components/Processing/ProcessingModifyRecurring";

import {
  BANK_ACCOUNT_STATUS_APPROVAL_MODAL,
  BANK_ACCOUNT_STATUS_DECLINE_MODAL,
  DOWNLOAD_REPORT_MODAL,
  MASQUERADE_MODAL,
  MERCHANT_VIEW_TRANSACTION_MODAL,
  PROCESSING_MAKE_RECURRING_MODAL,
  PROCESSING_MODIFY_RECURRING_MODAL,
  PROCESSING_REFUND_TRANSACTION_MODAL,
  PROCESSING_SEND_RECEIPT_MODAL,
  PROCESSING_VIEW_CUSTOMER_MODAL,
  REMOVE_FROM_WATCHLIST_MODAL,
  TRANSFER_MONEY_MODAL,
  UPLOAD_STATEMENT_MODAL,
  PROCESSING_CANCEL_TRANSACTION_MODAL,
  MERCHANT_PREVIEW_PANEL_MODAL,
  PROFILE_MODAL,
  TABLE_FILTERS_MODAL,
  RISK_MONITOR_FILTERS_MODAL,
  GENERATE_API_KEY_MODAL,
  API_KEY_SUCCESS_MODAL,
  DEVELOPER_API_PANEL,
} from "./modal_names";
import TransferMoneyModal from "@components/ManageMoney/TransferMoney/TransferMoneyModal";
import ProfileModal from "@components/ProfileMenu/modals/ProfileModal";
import TableFilterModal from "@common/TableFilters/TableFilterModal";
import TransactionFilterModal from "@components/Merchants/MerchantPreview/RiskProfile/components/Transactions/modal/TransactionFilterModal";
import GenerateApiKeyModal from "@components/DeveloperApi/modals/GenerateApiKeyModal";
import ApiKeySuccessModal from "@components/DeveloperApi/modals/ApiKeySuccessModal";
import DeveloperApiPanel from "@components/DeveloperApi/components/DeveloperApiPanel";
import NewMerchantSidePanel from "features/Merchants/MerchantSidePanel/NewMerchantSidePanel";

NiceModal.register(MERCHANT_PREVIEW_PANEL_MODAL, NewMerchantSidePanel);
NiceModal.register(
  BANK_ACCOUNT_STATUS_APPROVAL_MODAL,
  BankAccountStatusApprovalPopup,
);
NiceModal.register(
  BANK_ACCOUNT_STATUS_DECLINE_MODAL,
  BankAccountStatusDeclinePopup,
);
NiceModal.register(MERCHANT_VIEW_TRANSACTION_MODAL, ViewTransaction);
NiceModal.register(REMOVE_FROM_WATCHLIST_MODAL, RemoveWatchlist);
NiceModal.register(UPLOAD_STATEMENT_MODAL, UploadStatement);
NiceModal.register(PROCESSING_REFUND_TRANSACTION_MODAL, Refund);
NiceModal.register(PROCESSING_CANCEL_TRANSACTION_MODAL, Cancel);
NiceModal.register(MASQUERADE_MODAL, MasqueradeModal);
NiceModal.register(TRANSFER_MONEY_MODAL, TransferMoneyModal);
NiceModal.register(PROCESSING_SEND_RECEIPT_MODAL, ProcessingSendReceipt);
NiceModal.register(PROCESSING_MAKE_RECURRING_MODAL, ProcessingMakeRecuring);
NiceModal.register(PROCESSING_VIEW_CUSTOMER_MODAL, ViewCustomer);
NiceModal.register(
  PROCESSING_MODIFY_RECURRING_MODAL,
  ProcessingModifyRecurring,
);

NiceModal.register(DOWNLOAD_REPORT_MODAL, DownloadReport);

NiceModal.register(PROFILE_MODAL, ProfileModal);
NiceModal.register(TABLE_FILTERS_MODAL, TableFilterModal);
NiceModal.register(RISK_MONITOR_FILTERS_MODAL, TransactionFilterModal);

NiceModal.register(GENERATE_API_KEY_MODAL, GenerateApiKeyModal);
NiceModal.register(API_KEY_SUCCESS_MODAL, ApiKeySuccessModal);
NiceModal.register(DEVELOPER_API_PANEL, DeveloperApiPanel);
