import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetBuilderData } from "@pages/NewAdvancedBuilder/api/builderApi";
import { build } from "@pages/NewAdvancedBuilder/hooks/useGetBuilder";
import { useAppSelector } from "@redux/hooks";
import { selectAuth } from "@redux/slices/auth/auth";
import { customInstance } from "@services/api";
import { checkPortals } from "@utils/routing";
import { AxiosError } from "axios";
import { useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import { TFormStatusEnum } from "../products/types";
import { TPublicForm } from "./types";

type R = UseQueryResult<TPublicForm> & {
  isAuthenticated: boolean;
  notFound: boolean;
  formId: string | undefined;
  builderData: any;
  isADBPaymentFormLoading: boolean;
};

enum ERRORS_ENUM {
  NOT_FOUND = 404,
}

const useGetBuilderStandardFormData = ({
  enabler,
  accID,
  name,
  description,
  publishedStatus,
}: {
  enabler: boolean;
  accID: number;
  name: string;
  description: string;
  publishedStatus: string;
}) => {
  const { id } = useParams();

  // const { merchantId } = useGetCurrentMerchantId()

  const { isLoading: isADBPaymentFormLoading, data: builderData } =
    useGetBuilderData(accID, Number(id), {
      enabled:
        enabler &&
        publishedStatus === TFormStatusEnum.PUBLIC &&
        !isNaN(Number(id)),
      retry: (_, error) => {
        return (error as AxiosError).status !== ERRORS_ENUM.NOT_FOUND;
      },
      onSuccess(res: any) {
        if (res?.snapshot) {
          build(
            // old snapshots may still use "HTML" in uppercase
            res.snapshot?.html ?? res.snapshot?.HTML,
            name,
            description ?? "",
          );
        }
      },
    });

  return {
    isADBPaymentFormLoading,
    builderData,
  };
};

// 1- GET /merchant/{merchant_id}/products/{product_id} : within the merchant portal
// 2- GET /products/{product_id}:  for public payment form
export const useGetPublicForm = (): R => {
  const isAuthenticated = useAppSelector(selectAuth);
  const { isMerchantPortal } = checkPortals();
  const { merchantId, name } = useGetCurrentMerchantId();
  const [notFound, setNotFound] = useState<boolean>(false);

  const params = useParams<{ id: string }>(); // by pattern /:id
  const isValidId = !!params.id && !isNaN(Number(params.id));

  const queryData = useQuery({
    queryKey: "public-form-id",
    queryFn: async () => {
      if (isAuthenticated && isMerchantPortal) {
        return customInstance({
          url: `/merchants/${merchantId}/products/${params.id}`,
        });
      } else {
        return customInstance({
          url: `/products/${params.id}`,
        });
      }
    },
    onSuccess: () => void 0,

    // From MDN: Clients that receive a 400 response should expect that repeating the request without modification will fail with the same error.
    retry: (_, error) => {
      return (error as AxiosError).status !== ERRORS_ENUM.NOT_FOUND;
    },
    onError: (error) => {
      if ((error as AxiosError).status === ERRORS_ENUM.NOT_FOUND) {
        setNotFound(true);
      }
    },

    staleTime: Infinity,
    cacheTime: 0,
    enabled: isValidId,
  });

  const {
    accID,
    description,
    name: formName,
    publishedStatus,
  } = (queryData?.data || {}) as TPublicForm;

  const { builderData, isADBPaymentFormLoading } =
    useGetBuilderStandardFormData({
      enabler: queryData.isSuccess && !isNaN(Number(params.id)),
      accID: accID,
      description: description ?? "",
      name: formName,
      publishedStatus,
    });

  return {
    ...queryData,
    isADBPaymentFormLoading,
    isAuthenticated,
    notFound,
    formId: params.id,
    builderData,
  };
};
