import { Box, Stack } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import React from "react";
import { PaginationProps } from "./GiveTable.types";

const GivePagination = ({
  currentPage,
  pages,
  onClick,
  previousDisabled,
  nextDisabled,
  onClickNext,
  onClickPrevious,
}: PaginationProps) => {
  const { isMobileView } = useCustomThemeV2();

  return (
    <Stack
      direction="row"
      gap={isMobileView ? "12px" : "16px"}
      sx={{
        width: "fit-content",
      }}
    >
      {isMobileView ? (
        <GiveIconButton
          Icon={ArrowLeft}
          size="small"
          disabled={previousDisabled}
          onClick={onClickPrevious}
        />
      ) : (
        <GiveButton
          label="Previous"
          startIcon={<ArrowLeft size={18} />}
          color="light"
          variant="ghost"
          size="small"
          sx={{
            width: "fit-content",
          }}
          disabled={previousDisabled}
          onClick={onClickPrevious}
        />
      )}

      <Stack direction="row" gap={isMobileView ? "5px" : "8px"}>
        {pages.map((page, index) => {
          if (page === "space") {
            return <Space key={`space-${index}`} />;
          }

          return (
            <Page
              key={page}
              page={page}
              state={currentPage === page ? State.SELECTED : State.IDLE}
              onClick={() => onClick && onClick(page)}
            />
          );
        })}
      </Stack>
      {isMobileView ? (
        <GiveIconButton
          Icon={ArrowRight}
          size="small"
          disabled={nextDisabled}
          onClick={onClickNext}
        />
      ) : (
        <GiveButton
          label="Next"
          endIcon={<ArrowRight size={18} />}
          color="light"
          variant="ghost"
          size="small"
          sx={{
            width: "fit-content",
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        />
      )}
    </Stack>
  );
};

const State = {
  IDLE: "idle",
  SELECTED: "selected",
} as const;

interface TPage {
  page: number | string;
  state: (typeof State)[keyof typeof State];
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Page = ({ page, state, onClick }: TPage) => {
  const { palette } = useAppTheme();

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: state === State.SELECTED ? "default" : "pointer",
        height: "36px",
        width: "36px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.2s",
        background:
          state === State.SELECTED
            ? palette.gradient["ocean-blue"]?.highlight
            : "transparent",
        "&:hover": {
          background:
            state === State.SELECTED
              ? palette.gradient["ocean-blue"]?.highlight
              : palette.primitive?.transparent["darken-5"],
        },
      }}
    >
      <GiveText
        variant="bodyS"
        color={state === State.SELECTED ? "link" : "primary"}
      >
        {page}
      </GiveText>
    </Box>
  );
};

const Space = () => {
  return (
    <Box
      sx={{
        cursor: "default",
        height: "36px",
        width: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",
      }}
    >
      <GiveText variant="bodyS" color="primary">
        ...
      </GiveText>
    </Box>
  );
};

export default GivePagination;
