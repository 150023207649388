import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Stack,
  SxProps,
} from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { ReactNode } from "react";

type Props = {
  children: ReactNode | JSX.Element;
  icon?: ReactNode;
  title: string | ReactNode;
  sx?: SxProps;
  withExpandIcon?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
};

const GiveCollapsableComponent = ({
  children,
  icon,
  title,
  sx,
  withExpandIcon = true,
  isFirst,
  isLast,
}: Props) => {
  return (
    <Stack position="relative" sx={sx}>
      <CustomAccordion
        isFirst={isFirst}
        isLast={isLast}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <CustomSummary withExpandIcon={withExpandIcon}>
          {typeof title === "string" ? (
            <Stack direction="row" sx={{ gap: "8px" }} alignItems="center">
              {icon}
              <GiveText
                fontSize="18px"
                color="primary"
                sx={{
                  lineHeight: "120%",
                }}
              >
                {title}
              </GiveText>
            </Stack>
          ) : (
            <>{title}</>
          )}
        </CustomSummary>
        {children}
      </CustomAccordion>
    </Stack>
  );
};

interface CustomAccordionProps extends AccordionProps {
  isFirst?: boolean;
  isLast?: boolean;
}
const CustomAccordion = styled(
  (props: CustomAccordionProps) => (
    <Accordion elevation={0} disableGutters {...props} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "isFirst" && prop !== "isLast",
  },
)(({ theme, isFirst, isLast }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette.border?.primary,
  borderRadius:
    isFirst && isLast
      ? `${theme.customs?.radius.medium}px !important`
      : isFirst
      ? `${theme.customs?.radius.medium}px ${theme.customs?.radius.medium}px 0 0 !important`
      : isLast
      ? `0 0 ${theme.customs?.radius.medium}px ${theme.customs?.radius.medium}px !important`
      : "0",
  padding: "8px 4px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "& .MuiPaper-root": {},
  "&:before": {
    display: "none",
  },
  position: "initial",
}));

const CustomSummary = styled(
  ({
    withExpandIcon,
    ...props
  }: AccordionSummaryProps & { withExpandIcon?: boolean }) => (
    <AccordionSummary
      expandIcon={withExpandIcon ? <CaretDown /> : null}
      {...props}
    />
  ),
)(({ withExpandIcon }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-content": {
    margin: "0 !important",
    paddingBlock: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },

  ...(!withExpandIcon && {
    "& .MuiAccordionSummary-expandIconWrapper": {
      display: "none",
    },
  }),
}));

export default GiveCollapsableComponent;
