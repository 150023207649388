import { DEFAULT_QUERY_CONFIG } from "@components/VirtualList/hooks/queries";
import { QKEY_LIST_ACQUIRER_MERCHANTS } from "@constants/queryKeys";
import useFindMerchantStatsById from "@hooks/acquirer-api/merchants/stats/useFindMerchantStatsById";
import { useGetCurrentMerchantId } from "@hooks/common";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { selectQueryString } from "@redux/slices/search";
import { useDynamicTheme } from "@theme/hooks/useDynamicTheme";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useMemo } from "react";
import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { customInstance } from ".";
import { ProductParams } from "./products/queryFactory";

export const getMerchants = ({
  queryString,
  page,
  sorting,
  searchQuery,
  filter,
  signal,
  tab = "",
}: ProductParams & { signal: AbortSignal | undefined }) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";

  const filterQuery = filter
    ? "filter=" + encodeURIComponent(`;${filter}`)
    : "filter=";
  const pageQuery = page ? `&page=${page}` : "";

  return customInstance({
    url: `/submerchants${tab}?${filterQuery}${queryString}&sort=${
      sorting || "-accID"
    }${search}${pageQuery}&max=${ROWS_PER_PAGE}`,
    method: "GET",
    signal,
  });
};

export const baseGetMerchantStats = (id: string | number) => {
  if (id == 0) return Promise.resolve(0);
  return customInstance({
    url: `/merchants/${id}/stats`,
    method: "GET",
  });
};

export const getMerchantLevels = (merchId: string) => {
  return baseGetMerchantStats(merchId as string);
};

export const useGetMerchants = (
  params: ProductParams,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
  filter?: string,
  disableStats?: boolean,
) => {
  const { isTablesOptimizationsEnabled } = useGetFeatureFlagValues();
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const { isMobileView} = useDynamicTheme();
  const dispatch = useAppDispatch();
  const { data: stats, isLoading: isLoadingStats } = useFindMerchantStatsById(
    merchantId,
    { enabled: !disableStats },
  );

  const newSorting = useMemo(() => {
    let _s = params.sorting;

    if (_s && _s === "-name") {
      return (_s += ",-accID");
    }
    return _s;
  }, [params.sorting]);

  const { data, isLoading, error, isFetching, refetch, isPreviousData } =
    useQuery(
      [
        QKEY_LIST_ACQUIRER_MERCHANTS,
        params.queryString,
        params.page,
        newSorting,
        params.searchQuery,
        params.tab,
        filter,
      ],
      async ({ signal }) =>
        await getMerchants({ ...params, sorting: newSorting, signal }),
      {
        ...options,
        retry: 2,
        ...(isTablesOptimizationsEnabled && {
          ...DEFAULT_QUERY_CONFIG,
          initialData: () => {
            return queryClient.getQueryData([
              QKEY_LIST_ACQUIRER_MERCHANTS,
              params.queryString,
              params.page,
              newSorting,
              params.searchQuery,
              filter,
            ]);
          },
        }),
        onError(err: any) {
          if (err.not_authorized) {
            dispatch(
              updatePermissions({
                get_merchants_list: true,
              }),
            );
          }
          if (options.onError) {
            options.onError(err);
          }
        },
      },
    );

  const statsData = {
    countApproved: stats?.countApproved || 0,
    countInoperative: stats?.countInoperative || 0,
    countUnderwriting: stats?.merchantUnderwritingCount || 0,
    countTotal:
      (stats?.merchantRiskCount || 0) + (stats?.merchantUnderwritingCount || 0),
    countAquirerUnderwriting: stats?.merchantUnderwritingCount || 0,
    countRisk: stats?.merchantRiskCount || 0,
    countTotalAquirer: stats?.merchantCount || 0,
    sponsorCount: stats?.merchantSponsorCount || 0,
    countInvited: stats?.merchantOwnerInvitedCount || 0,
    countOnboarding: stats?.merchantOnboardingCount || 0,
  };

  const hasNoMerchants =
    stats?.merchantUnderwritingCount === 0 &&
    stats?.countApproved === 0 &&
    stats?.merchantSponsorCount === 0 &&
    stats?.merchantRiskCount === 0;

  return {
    data,
    stats: statsData,
    error,
    isLoading: isLoading || isLoadingStats,
    isFetching,
    refetch,
    isPreviousData,
    hasNoMerchants,
  };
};

interface IReturnLegalEntityTypes {
  data: LegalEntityType[];
  total: number;
}

export const useGetLegalEntitiesTypes = () => {
  const dispatch = useAppDispatch();
  const QKEY = "get-legal-entities-types";
  const searchQuery = useAppSelector((state) => selectQueryString(state, QKEY));

  let baseURL = "legal-entity-types";
  if (searchQuery) baseURL += `?q=${searchQuery}`;

  const getBusinessTypes = async () =>
    await customInstance({
      method: "GET",
      url: `/${baseURL}`,
    });

  const { data, ...rest } = useQuery<IReturnLegalEntityTypes>(
    [QKEY],
    async () => await getBusinessTypes(),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              get_legal_entities_types: true,
            }),
          );
        }
      },
    },
  );

  const filteredData = useMemo(() => {
    if (!data?.data) return [];
    if (!searchQuery) return data.data;
    return data.data.filter((item) =>
      item.name.includes(searchQuery.toLowerCase()),
    );
  }, [data, searchQuery]);
  return {
    data: {
      ...data,
      data: filteredData,
    },
    ...rest,
  };
};

type LegalEntityType = {
  createdAt: number;
  description: string;
  id: number;
  name: string;
  updatedAt: number;
};

export const getMerchantAcquirer = (id: number | string) => {
  return customInstance({
    url: `merchants/${id}/acquirer`,
    method: "GET",
  });
};

export const useGetMerchantAcquirer = (id: number | string, props?: any) => {
  const key = ["get-merchant-acquirer", id];
  return useQuery(
    key,
    async () => {
      return await getMerchantAcquirer(id);
    },
    {
      ...props,
      retry: 2,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 100000000,
      cacheTime: 300000,
      // enabled: false,
    },
  );
};
