import NiceModal from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { batch } from "@preact/signals-react";
import { CustomTheme } from "@theme/v2/palette.interface";
import { addSizeToImage } from "@utils/image.helpers";
import { pl } from "date-fns/locale";
import { ADVANCED_BUILDER_ERROR_MODAL } from "modals/modal_names";
import { getColorsMap, useColors } from "../api";
import { useGetBuilderData } from "../api/builderApi";
import { colors as defaulGlobalColors } from "../api/test";
import { optionsMap } from "../components/FontFamilySelector";
import { defaultWeights } from "../consts/fontWeights";
import {
  defaultDescriptionMetaAttributes,
  defaultDescriptionStyle,
  defaultDescriptionTag,
  defaultHeadingMetaAttributes,
  defaultHeadingStyle,
  defaultHeadingTag,
  headingKeys,
  headingsSignal,
  mobileHeadingsSignal,
} from "../consts/headings";
import { icons } from "../consts/icons";
import { ROOT_NODE_ID, rootNodeStyles } from "../consts/values";
import { imageWithTextNodeBlueprint } from "../consts/widgets";
import { onClickWidget } from "../consts/widgets/actions";
import {
  IMAGE_WITH_TEXT_CONTENT_WRAPPER_BLUEPRINT,
  IMAGE_WITH_TEXT_DESCRIPTION_BLUEPRINT,
  IMAGE_WITH_TEXT_HEADING_BLUEPRINT,
  IMAGE_WITH_TEXT_IMAGE_BLUEPRINT,
  IMAGE_WITH_TEXT_IMAGE_CONTAINER_BLUEPRINT,
  IMAGE_WITH_TEXT_ROOT,
} from "../consts/widgets/helpers";
import {
  imageWithTextContentWrapperBlueprint,
  imageWithTextDescriptionBlueprint,
  imageWithTextHeadingBlueprint,
  imageWithTextImageBlueprint,
  imageWithTextImageContainerBlueprint,
  imageWithTextRootBlueprint,
} from "../consts/widgets/helpers/imageWithText";
import { widgetFactory } from "../consts/widgets/widgetFactory";
import {
  saveNodeDeviceTypeProperties,
  savePublishedNodeDeviceTypeProperties,
} from "../NodeDeviceTypePropertiesMap";
import {
  formUpdatedAt,
  globalButtonStyles,
  globalColors,
  isBuilderError,
  isDraftCreated,
  isDraftEnabled,
  isPublished,
  isPublishEnabled,
  lastItemSelected,
} from "../signals";
import {
  builder,
  createGradient,
  decodeSpecialCharacters,
  generateUniqueID,
} from "../utils";
import {
  deviceSpecificWidgets,
  getButtonWidgetDeviceTypeCssProperties,
  getDividerDeviceTypeCssProperties,
  getHeadingDeviceTypeCssProperties,
  getSingleImageDeviceTypeCssProperties,
} from "../utils/helpers";
import useSavePaymentForm from "./useSavePaymentForm";
import { FormType } from "@sections/PayBuilder/components/hooks/useCheckFormType";

type Props = {
  productId: number;
  title: string;
  description: string;
  theme: CustomTheme;
  campaign?: "standard" | "event" | "fundraiser";
  position?: string;
  imageURL?: string;
};

export const build = (
  nodeList: any[] | undefined,
  title: string | undefined,
  description: string | undefined,
  campaign?: Props["campaign"],
  position?: string,
  imageURL?: string,
) => {
  const hasNodeList = nodeList && nodeList.length > 0;
  const titleId = generateUniqueID();
  const descriptionId = generateUniqueID();
  // Add title element to node map
  !hasNodeList &&
    saveNodeDeviceTypeProperties(
      titleId,
      getHeadingDeviceTypeCssProperties(
        defaultHeadingTag,
        defaultHeadingStyle,
        defaultHeadingMetaAttributes,
      ),
      defaultHeadingMetaAttributes,
      defaultHeadingTag,
      title || "",
    );

  !hasNodeList &&
    description &&
    saveNodeDeviceTypeProperties(
      descriptionId,
      getHeadingDeviceTypeCssProperties(
        defaultDescriptionTag,
        defaultDescriptionStyle,
        defaultDescriptionMetaAttributes,
      ),
      defaultDescriptionMetaAttributes,
      defaultDescriptionTag,
      description || "",
    );

  hasNodeList && populateNodeDeviceMapWithPublishedElements(nodeList);

  const isNewForm = ["standard", "event", "fundraiser"].includes(
    campaign || "",
  );

  const isFundraiser = campaign === FormType.FUNDRAISERS;
  const imagePosition = isFundraiser ? "left" : position || "right";
  builder.buildTree([
    {
      key: ROOT_NODE_ID,
      tag: "div",
      parentID: null,
      widgetType: "root",
      style: rootNodeStyles,
      tabletStyle: rootNodeStyles,
      mobileStyle: rootNodeStyles,
    },
    ...(!hasNodeList
      ? campaign && !isNewForm
        ? allCampaignsBuilder({
            hasNodeList,
            titleId,
            title,
            description,
            descriptionId,
            nodeList,
          })
        : []
      : nodeList || []),
  ]);

  if (campaign && isNewForm && nodeList === undefined) {
    // Create widget manually attach to the preview
    const imageUrl = imageURL ? addSizeToImage(imageURL, "medium") : pl;
    const widget = widgetFactory({
      id: "image_text",
      tabs: 2,
      title: "Image + Text",
      icon: icons.image_text,
      isEnabled: true,
      tagName: imageWithTextNodeBlueprint,
      state: Object.freeze({
        backgroundSize: {
          value: "cover",
          name: "Cover",
        },
        imageSrc: `url("${imageUrl}")`,
        imagePosition: ({ left: 1, right: 2 } as any)[imagePosition], //1-left;2-right
        imageSize: "60", // 40 | 50 | 60
        yAlignment: "center", //top | center  | bottom
        headingTextAlign: "start", //start | center  | end
        descriptionContentTextAlign: "start", //start | center  | end,
        headingContent: title,
        descriptionContent: description,
        dataHidden: "false",
        borderRadius: "20px",
        ...(isFundraiser && {
          dataDisplayVerticallyOnDesktop: "true",
        }),
      }),
      blueprints: {
        [IMAGE_WITH_TEXT_ROOT]: imageWithTextRootBlueprint,
        [IMAGE_WITH_TEXT_IMAGE_CONTAINER_BLUEPRINT]:
          imageWithTextImageContainerBlueprint,
        [IMAGE_WITH_TEXT_IMAGE_BLUEPRINT]: imageWithTextImageBlueprint,
        [IMAGE_WITH_TEXT_CONTENT_WRAPPER_BLUEPRINT]:
          imageWithTextContentWrapperBlueprint,
        [IMAGE_WITH_TEXT_HEADING_BLUEPRINT]: imageWithTextHeadingBlueprint,
        [IMAGE_WITH_TEXT_DESCRIPTION_BLUEPRINT]:
          imageWithTextDescriptionBlueprint,
      },
    });

    onClickWidget(widget, lastItemSelected.value);
  }
};

const allCampaignsBuilder = ({
  hasNodeList,
  titleId,
  title,
  description,
  descriptionId,
  nodeList,
}: any) => {
  return [
    {
      key: ROOT_NODE_ID,
      tag: "div",
      parentID: null,
      widgetType: "root",
      style: rootNodeStyles,
      tabletStyle: rootNodeStyles,
      mobileStyle: rootNodeStyles,
    },
    ...(!hasNodeList
      ? [
          {
            key: titleId,
            tag: defaultHeadingTag,
            tabletTag: defaultHeadingTag,
            mobileTag: defaultHeadingTag,
            parentID: ROOT_NODE_ID,
            widgetType: "heading",
            content: title,
            tabletContent: title,
            mobileContent: title,
            metaAttributes: defaultHeadingMetaAttributes,
            tabletMetaAttributes: defaultHeadingMetaAttributes,
            mobileMetaAttributes: defaultHeadingMetaAttributes,
            style: defaultHeadingStyle,
            tabletStyle: defaultHeadingStyle,
            mobileStyle: defaultHeadingStyle,
          },
          ...(description
            ? [
                {
                  key: descriptionId,
                  tag: defaultDescriptionTag,
                  tabletTag: defaultDescriptionTag,
                  mobileTag: defaultDescriptionTag,
                  parentID: ROOT_NODE_ID,
                  widgetType: "heading",
                  content: description,
                  tabletContent: description,
                  mobileContent: description,
                  metaAttributes: defaultDescriptionMetaAttributes,
                  tabletMetaAttributes: defaultDescriptionMetaAttributes,
                  mobileMetaAttributes: defaultDescriptionMetaAttributes,
                  style: defaultDescriptionStyle,
                  tabletStyle: defaultDescriptionStyle,
                  mobileStyle: defaultDescriptionStyle,
                },
              ]
            : []),
        ]
      : nodeList),
  ];
};

const useGetBuilder = ({
  productId,
  title,
  description,
  theme,
  campaign,
  position,
  imageURL,
}: Props) => {
  const { merchantId } = useGetCurrentMerchantId();

  const { handleRefetch } = useSavePaymentForm({
    productId,
    productTitle: title,
  });

  useGetBuilderData(merchantId, productId, {
    onSuccess(data) {
      if (data?.html) {
        batch(() => {
          isBuilderError.value = false;

          build(data.html, title, description, campaign, position, imageURL);

          isDraftCreated.value =
            !data.snapshot || data.updatedAt > data.snapshot.UpdatedAt;
          isPublished.value = Boolean(data?.snapshot);

          formUpdatedAt.value = data.updatedAt || 0;

          isDraftEnabled.value = !isDraftCreated.value;
          isPublishEnabled.value = isDraftCreated.value || !isPublished.value;

          globalColors.general.value = {
            ...globalColors.general.value,
            ...getColorsMap(data.globalColors),
          };

          globalColors.custom.value = {
            ...globalColors.custom.value,
            ...getColorsMap(data.customColors),
          };

          setHeadingStyle(data, headingsSignal, "");
          setHeadingStyle(data, mobileHeadingsSignal, "Mobile");
          setButtonStyle(data);
        });
      }
    },
    onError(err) {
      if (err && err?.response?.status === 404) {
        batch(() => {
          globalColors.general.value = getColorsMap(
            defaulGlobalColors.GeneralColors,
          );
          globalColors.custom.value = getColorsMap(
            defaulGlobalColors.CustomColors,
          );
          build(undefined, title, description, campaign, position, imageURL);
        });
        isBuilderError.value = false;
      } else {
        NiceModal.show(ADVANCED_BUILDER_ERROR_MODAL, {
          theme,
          handleRefetch,
        });

        isBuilderError.value = true;
      }
    },
  });
};

const setButtonStyle = (data: any) => {
  //TODO: research for a scalable solution
  const { colors } = useColors({ withGradients: true });

  batch(() => {
    if (data?.buttonDefaultStyles?.borderRadius) {
      globalButtonStyles.borderRadius.value =
        data?.buttonDefaultStyles?.borderRadius;
    }
    if (data?.buttonDefaultStyles?.padding) {
      globalButtonStyles.padding.value = data?.buttonDefaultStyles?.padding;
    }
    if (data?.buttonDefaultStyles?.fontSize) {
      globalButtonStyles.fontSize.value = data?.buttonDefaultStyles?.fontSize;
    }
    if (data?.buttonDefaultStyles?.backgroundColor) {
      const bgColorName = data?.buttonDefaultStyles?.backgroundColor;
      let bgColor;

      if (colors?.[bgColorName]?.isGradient) {
        bgColor = createGradient(colors?.[bgColorName]);
      } else {
        bgColor = colors?.[bgColorName]?.hexes[0]?.hexValue;
      }

      globalButtonStyles.backgroundColor.value = {
        name: bgColorName,
        color: bgColor,
      };
    }
    if (data?.buttonDefaultStyles?.color) {
      globalButtonStyles.textColor.value = {
        name: data?.buttonDefaultStyles?.color,
        color: colors?.[data?.buttonDefaultStyles?.color]?.hexes[0]?.hexValue,
      };
    }
  });
};

const setHeadingStyle = (data: any, signal: any, mobileKey: string) => {
  //TODO: research for a scalable solution
  const { colors } = useColors({ withGradients: true });

  batch(() => {
    for (const key of headingKeys) {
      const headingItem =
        key === "body"
          ? `pDefault${mobileKey}Styles`
          : key + `Default${mobileKey}Styles`;

      const itemData = data?.[headingItem];

      if (itemData?.fontFamily) {
        signal[key].fontFamily.value = {
          name: itemData?.fontFamily,
          value: optionsMap?.[itemData?.fontFamily as keyof typeof optionsMap],
        };
      }

      if (itemData?.fontWeight) {
        signal[key].fontWeight.value = {
          name:
            itemData.fontWeight.charAt(0).toUpperCase() +
            itemData.fontWeight.slice(1),
          value:
            defaultWeights?.[
              itemData?.fontWeight as keyof typeof defaultWeights
            ]?.value || 400,
        };
      }

      const colorName = itemData?.color;
      if (colors[colorName]) {
        signal[key].textColor.value = {
          name: colorName,
          color: colors[colorName]?.isGradient
            ? createGradient(colors[colorName])
            : colors[colorName].hexes[0].hexValue,
        };
      }

      if (itemData?.fontSize) {
        signal[key].fontSize.value = Number(itemData?.fontSize);
      }
      if (itemData?.lineHeight) {
        signal[key].lineHeight.value = Number(itemData?.lineHeight);
      }
      if (itemData?.letterSpacing) {
        signal[key].letterSpacing.value = Number(itemData?.letterSpacing);
      }
    }
  });
};

const getCssProperties = (
  widgetType: any,
  style: any,
  metaAttributes: any,
  tag: any,
) => {
  switch (widgetType) {
    case "heading":
      return getHeadingDeviceTypeCssProperties(tag, style, metaAttributes);
    case "divider":
      return getDividerDeviceTypeCssProperties(style, metaAttributes);
    case "button":
      return getButtonWidgetDeviceTypeCssProperties(style, metaAttributes);
    case "single_image":
      return getSingleImageDeviceTypeCssProperties(style, metaAttributes);
    default:
      return {};
  }
};

const populateNodeDeviceMapWithPublishedElements = (nodeList: any) => {
  nodeList.forEach((node: any) => {
    const {
      key,
      widgetType,
      style,
      tabletStyle,
      mobileStyle,
      tag,
      tabletTag,
      mobileTag,
      content,
      tabletContent,
      mobileContent,
      metaAttributes,
      tabletMetaAttributes,
      mobileMetaAttributes,
      href,
      tabletHref,
      mobileHref,
    } = node;
    // This is done temporarily so it only works for basic widgets
    if (!deviceSpecificWidgets.has(widgetType)) return;

    const cssProperties = getCssProperties(
      widgetType,
      style,
      metaAttributes,
      tag,
    );
    const tabletCssProperties = getCssProperties(
      widgetType,
      tabletStyle,
      tabletMetaAttributes,
      tabletTag,
    );
    const mobileCssProperties = getCssProperties(
      widgetType,
      mobileStyle,
      mobileMetaAttributes,
      mobileTag,
    );

    savePublishedNodeDeviceTypeProperties(
      key,
      cssProperties,
      tabletCssProperties,
      mobileCssProperties,
      metaAttributes,
      tabletMetaAttributes,
      mobileMetaAttributes,
      tag,
      tabletTag,
      mobileTag,
      decodeSpecialCharacters(content),
      decodeSpecialCharacters(tabletContent),
      decodeSpecialCharacters(mobileContent),
      href,
      tabletHref,
      mobileHref,
    );
  });
};

export default useGetBuilder;
