import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { formatCardNumber } from "@utils/index";
import { useAppDispatch } from "@redux/hooks";
import { setCheckoutCardType } from "@redux/slices/checkout";
import { useGetCardInfos } from "@hooks/merchant-api/cart/useGetCardInfos";
import { GiveInput, InputProps } from "@shared/GiveInputs/GiveInput";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import { InputAdornment } from "@mui/material";
import { getCardBrandIcon } from "@sections/Checkout/Payment/inputs/CardNumberInput";

const CardNumberField = (props: InputProps) => {
  const { setValue, setError, clearErrors } = useFormContext();
  const [cardType, setCardType] = useState<string>("");
  const dispatch = useAppDispatch();
  const { handleCardNumberCheck, cardObject } = useGetCardInfos();
  const { setFees } = useCart();

  React.useEffect(() => {
    dispatch(setCheckoutCardType(cardType));
  }, [cardType]);

  const maxCardNumberLength = cardType && cardType === "AMEX" ? 17 : 19;
  const normalizeInput = (value: string) => {
    if (!value) return value;
    const card = formatCardNumber(value);
    const allowedLength = card?.cardType && card?.cardType === "AMEX" ? 17 : 19;
    if (card?.formattedNumber?.length === allowedLength) {
      clearErrors("payment.cardNumber");
      handleCardNumberCheck(value, (msg) =>
        setError("payment.cardNumber", {
          message: msg,
        }),
      );
    }
    setCardType(card.cardType);

    return card.formattedNumber;
  };

  useEffect(() => {
    if (cardObject) setFees && setFees(+cardObject.fees / 100);
  }, [cardObject]);

  const handleChange = (value: string) => {
    setValue("payment.cardNumber", normalizeInput(value));
  };

  return (
    <GiveInput
      {...props}
      onChange={(event) => handleChange(event.target.value)}
      maxLength={maxCardNumberLength}
      sx={{
        letterSpacing: "2px",
        "& .MuiFormHelperText-root": {
          letterSpacing: "0px",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" sx={{ alignItems: "center" }}>
            {getCardBrandIcon(cardType.toUpperCase())}
          </InputAdornment>
        ),
        inputMode: "numeric",
      }}
    />
  );
};

export default CardNumberField;
