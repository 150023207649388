import GiveProgressBar from "@shared/ProgressBar/GiveProgressBar";
import CircularScore from "@shared/UnderwritingScore/CircularScore";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

export default function OnboardingScore({ score }: { score: number }) {
  const { isDesktopView } = useCustomThemeV2();
  if (isDesktopView)
    return (
      <GiveProgressBar
        value={score}
        variant={score === 100 ? "completed" : "incomplete"}
        type="onboarding"
      />
    );

  return <CircularScore score={score} simple />;
}
