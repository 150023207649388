import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Grid, Stack } from "@mui/material";
import { Check, X } from "@phosphor-icons/react";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveButton from "@shared/Button/GiveButton";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { CAMPAIGN_DETAILS_MODAL } from "modals/modal_names";
import { useEffect, useRef, useState } from "react";

const SaveAndCloseModal = NiceModal.create(
  ({
    mutate,
    customClose,
    isFormValid = true,
  }: {
    customClose?: () => void;
    mutate?: ReturnType<typeof usePayBuilderForm>["mutate"];
    isFormValid?: boolean;
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { open, onClose, modal, SlideProps, TransitionProps } =
      useNiceModal();

    const timeoutRef = useRef<string | number | NodeJS.Timeout | undefined>();

    const handleCancel = () => modal.hide();

    const onConfirm = () => {
      if (isLoading) return;
      setIsLoading(true);
      mutate &&
        mutate({
          handleSuccessCB: () => {
            setIsLoading(false);
            handleCancel();
            onClose();
          },
        });
    };
    const onCancel = () => {
      handleCancel();
      onClose();
      customClose?.();
    };

    const deleteCallback = () => {
      timeoutRef.current = setTimeout(() => {
        NiceModal.remove(CAMPAIGN_DETAILS_MODAL);
        !modal.keepMounted && modal.remove();
      }, 90);
    };

    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose,
          DrawerProps: {
            SlideProps: {
              ...SlideProps,
              onExited: () => {
                NiceModal.hide(CAMPAIGN_DETAILS_MODAL);
                deleteCallback();
              },
            },
            PaperProps: {
              sx: {
                "&.MuiPaper-root": {
                  padding: "20px",
                  gap: "16px",
                },
              },
            },
          },
          DialogProps: {
            TransitionProps: {
              ...TransitionProps,
              onExited: () => {
                NiceModal.hide(CAMPAIGN_DETAILS_MODAL);
                deleteCallback();
              },
            },
            width: "440px",
            contentContainerSx: {
              padding: "20px",
              gap: "16px",
            },
            PaperProps: {
              "& .MuiPaper-root": {},
            },
            scroll: "paper",
          },
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <div
            style={{
              background:
                "linear-gradient(223.56deg, #E8F9FC 0%, #E9E8F7 100%)",
              display: "flex",
              padding: "12px",
              width: "48px",
              height: "48px",
              borderRadius: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Color not present in theme.Waiting for UX team answer */}
            <Check color="#2860B2" />
          </div>
          <GiveIconButton
            Icon={X}
            size="small"
            variant="ghost"
            sx={{
              display: "flex",
              gap: 1,
            }}
            onClick={() => handleCancel()}
          />
        </Grid>
        <Stack gap={2}>
          <GiveText fontSize="24px" fontWeight={350}>
            Save Changes?
          </GiveText>
          <GiveText
            color="secondary"
            fontSize="14px"
            fontWeight={400}
            lineHeight="20px"
          >
            Do you want to save the changes you made to this Payment Form?
          </GiveText>
        </Stack>
        <Grid container justifyContent="flex-end" alignItems="center">
          <ReButton
            size="large"
            variant="ghost"
            label="Don’t Save"
            disabled={isLoading}
            onClick={onCancel}
          />
          <GiveButton
            size="large"
            label="Yes, Save Changes"
            disabled={!isFormValid || isLoading}
            onClick={onConfirm}
          />
        </Grid>
      </ModalFactory>
    );
  },
);

const ReButton = styled(GiveButton)(({ theme }) => ({
  color: theme.palette.primitive?.error[100],
}));

export default SaveAndCloseModal;
