import React, { isValidElement, useEffect, useRef } from "react";
import { CustomTheme } from "@theme/v2/palette.interface";
import {
  Table,
  TableContainer,
  TableBody,
  Paper,
  TableHeadProps,
  TableRowProps,
} from "@mui/material";
import {
  FixedHeaderContent,
  ItemContent,
  TableComponents,
  TableVirtuoso,
  TableVirtuosoHandle,
} from "react-virtuoso";
import GiveAnimatedListItem from "@shared/GiveAnimatedList/GiveAnimatedListItem";
import { TableColumnType } from "componentsV2/Table/Table.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveTableLoadingPlaceholder from "./GivaTableLoadingPlaceholder";
import { useAppTheme } from "@theme/v2/Provider";
import { Box } from "@mui/material";

interface BaseContext {
  sticky?: boolean;
  top?: number;
  isWideView?: boolean;
  hasPagination?: boolean;
}

interface Props<TData, TCustomContext extends BaseContext = BaseContext> {
  sticky?: boolean;
  top?: number;
  rowProps?: (data: TData, idx: number) => TableRowProps;
  data: TData[];
  columns: TableColumnType[];
  itemContent: ItemContent<TData, TCustomContext>;
  fixedHeaderContent?: FixedHeaderContent;
  emptyStateContent?: FixedHeaderContent;
  endReached?: (index: number) => void;
  context?: Omit<TCustomContext, keyof BaseContext>;
  isLoading?: boolean;
  page?: number;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  hasPagination?: boolean;
  isInfiniteScroll?: boolean;
}

const TRow = React.forwardRef<
  HTMLTableRowElement,
  TableRowProps & { context?: BaseContext }
>(({ style, children, context, ...otherProps }, ref) => {
  const { palette } = useAppTheme();
  if (!isValidElement(children)) {
    console.error("TRow expects a single child element.");
    return null;
  }

  const ChildComponent = children.type;
  const childProps = {
    ...children.props,
    ...otherProps,
    sx: {
      ...style,
      cursor: "pointer",
      borderBottom: palette.border?.primary,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      "&:hover": {
        backgroundColor: palette.primitive?.transparent["darken-2"],
      },
      ...children.props.sx,
    },
    ref,
  };

  return <ChildComponent {...childProps} />;
});
TRow.displayName = "TRow";

const THead = React.forwardRef<
  HTMLTableSectionElement,
  TableHeadProps & { context?: BaseContext }
>((props, ref) => {
  const { style, context, children, ...otherProps } = props;

  if (!isValidElement(children)) {
    console.error("THead expects a single child element.");
    return null;
  }

  const ChildComponent = children.type;
  const childProps = {
    ...children.props,
    ...otherProps,
  };

  return <ChildComponent {...childProps} />;
});
THead.displayName = "THead";

const TBody = React.forwardRef<
  HTMLTableSectionElement,
  React.ComponentPropsWithoutRef<typeof TableBody>
>((props, ref) => <TableBody {...props} ref={ref} />);
TBody.displayName = "TableBody";

const Scroller = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof TableContainer>
>(({ style, ...props }, ref) => (
  <TableContainer
    component={Paper}
    {...props}
    style={{
      ...style,
      boxShadow: "none",
      backgroundColor: "transparent",
    }}
    sx={({ palette }: CustomTheme) => ({
      "&::-webkit-scrollbar": {
        width: 10,
      },
      "&::-webkit-scrollbar-thumb": {
        border: "2px solid #F9FCFF",
        borderRadius: "8px",
        backgroundColor: palette.surface?.tertiary,
      },
      "&::-webkit-scrollbar-thumb:focus": {
        backgroundColor: palette.surface?.tertiary,
      },
      "&::-webkit-scrollbar-thumb:active": {
        backgroundColor: palette.surface?.tertiary,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: palette.surface?.tertiary,
      },
    })}
    ref={ref}
  />
));
Scroller.displayName = "Scroller";

const BaseTableComponents: TableComponents<any, any> = {
  Scroller,
  TableHead: THead,
  Table: (props) => {
    const { isWideView } = useCustomThemeV2();
    const { breakpoints } = useAppTheme();
    const {
      context: { hasPagination },
    } = props;
    return (
      <Box
        sx={{
          padding: "0px 64px 0 64px",
          [breakpoints.down("v2_md")]: {
            padding: "0px 20px 0 20px",
          },
          paddingBottom: hasPagination ? "76px !important" : 0,
        }}
      >
        <Table
          {...props}
          style={{
            borderCollapse: "collapse",
            tableLayout: isWideView ? "fixed" : "auto",
            height: "1px",
          }}
        />
      </Box>
    );
  },
  TableRow: TRow,
  TableBody: TBody,
};

const GiveTable = <TData, TCustomContext extends BaseContext = BaseContext>({
  sticky,
  top,
  data,
  itemContent,
  fixedHeaderContent,
  emptyStateContent,
  endReached,
  context: customContext,
  isLoading,
  rowProps,
  columns,
  onScroll,
  hasPagination,
  isInfiniteScroll,
}: Props<TData, TCustomContext>) => {
  const { isWideView } = useCustomThemeV2();
  const virtuoso = useRef<TableVirtuosoHandle | null>(null);
  const Components = {
    ...BaseTableComponents,
    // Add EmptyPlaceholder component to show loading state with header
    EmptyPlaceholder: () =>
      isLoading ? (
        <GiveTableLoadingPlaceholder columns={columns} />
      ) : emptyStateContent ? (
        emptyStateContent()
      ) : null,
  } as TableComponents<TData, TCustomContext>;

  const context = {
    sticky,
    top,
    isWideView,
    hasPagination,
    ...customContext,
  } as TCustomContext;

  const shouldAnimate = useRef(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoading) {
      !isInfiniteScroll && virtuoso.current?.scrollToIndex(0);
      shouldAnimate.current = true;
    } else {
      timeout = setTimeout(() => {
        shouldAnimate.current = false;
      }, 800);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <TableVirtuoso<TData, TCustomContext>
      increaseViewportBy={{ top: 1000, bottom: 0 }}
      context={context}
      style={{
        height: "100%",
        width: "100%",
        scrollbarWidth: "none",
        ...(isLoading && isWideView && { overflowY: "hidden" }),
      }}
      ref={virtuoso}
      data={data}
      onScroll={onScroll}
      components={Components}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={(index, data, context) => {
        //TODO: loading is to be fixed separately
        // if (isLoading) return <GiveListLoadingSkeletons columns={columns} />;

        return (
          <GiveAnimatedListItem
            index={index}
            key={index}
            shouldAnimate={shouldAnimate.current}
            rowProps={rowProps && rowProps(data, index)}
          >
            {itemContent(index, data, context)}
          </GiveAnimatedListItem>
        );
      }}
      endReached={endReached}
    />
  );
};

export default GiveTable;
