import { ReportFormFields } from "../components/AddNewReportView";
import { useMutation, useQueryClient } from "react-query";
import { ReportType } from "@components/Merchants/MerchantPreview/MATCH/types";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type MutationRequestParams = {
  merchantID: number;
  files: ReportFormFields["files"];
  data: Omit<ReportFormFields, "files">;
};

export type UseSubmitMatchReportType = {
  merchantID: number;
  onSubmitSuccess: () => void;
};

const useSubmitMatchReport = ({
  merchantID,
  onSubmitSuccess,
}: UseSubmitMatchReportType) => {
  const queryClient = useQueryClient();
  const { isDesktopView } = useCustomTheme();
  const { handleUpload: handleUploadEdit, isLoading } = useUploadFiles();

  const uploadFiles = async (
    merchantID: number,
    reportID: number,
    files: any,
  ) => {
    const uploadRes: string[] | "upload_failed" = await handleUploadEdit({
      list: files,
      attachmentType: "underwriting_match_report",
      merchantId: merchantID,
      resourceID: reportID,
      label: "underwriting match report",
      tag: "underwriting match report",
    });

    if (uploadRes === "upload_failed")
      showMessage("Error", "Evidence upload failed.", isDesktopView);

    onSubmitSuccess();
  };

  const handleEvidenceUpload = (
    responseData: ReportType,
    params: MutationRequestParams,
  ) => {
    const { merchantID, files = [] } = params;

    if (responseData && files.length) {
      uploadFiles(merchantID, responseData.ID, files);
      return;
    }
    onSubmitSuccess();
  };

  const createMatchReportMutation = useMutation(
    ({ data, merchantID }: MutationRequestParams) => {
      return customInstance({
        url: `/merchants/${merchantID}/match-reports`,
        method: "POST",
        data,
      });
    },
    {
      onSuccess: handleEvidenceUpload,
      onError: (error: any) => {
        showMessage(
          "Error",
          error?.message || error?.response?.status,
          true,
          "Something went wrong...",
        );
      },
      onSettled: () => {
        queryClient.refetchQueries(["list-match-reports", merchantID]);
        queryClient.invalidateQueries("get-merchant-preview");
      },
    },
  );

  const handleSubmit = async (formData: ReportFormFields) => {
    createMatchReportMutation.mutate({
      merchantID,
      files: formData.files,
      data: { findings: formData.findings, status: formData.status },
    });
  };

  return {
    handleSubmit,
    isSubmitting: createMatchReportMutation.isLoading || isLoading,
  };
};

export default useSubmitMatchReport;
