import NiceModal, {
  NiceModalHandler,
  muiDialogV5,
  useModal,
} from "@ebay/nice-modal-react";
import Popup from "@common/Popup";
import { palette } from "@palette";
import { Grid, Stack, SxProps } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { useState } from "react";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";

type ApproveMerchantProps = {
  handleApprove: (cb?: any) => void;
  statusName: string;
  name: string;
  isProvider?: boolean;
};

const ApproveMerchantModal = NiceModal.create(
  ({ handleApprove, statusName, name, isProvider }: ApproveMerchantProps) => {
    const isMIDIssue = statusName === "preapproved_mid_issue";
    const props = {
      modalTitle: isMIDIssue
        ? "Retry Approval"
        : isProvider
        ? "Approve Provider"
        : "Approve Merchant",
      submitLabel: isMIDIssue ? "Continue" : "Approve",
      children: isMIDIssue ? (
        <Text fontWeight="book" color={palette.neutral[70]} lineHeight="16.8px">
          The MSP declined the initial application. Make sure all data is valid
          before retrying.
        </Text>
      ) : (
        <Text fontWeight="book" color={palette.neutral[70]} lineHeight="16.8px">
          Are you sure you want to approve{" "}
          <span style={{ color: palette.neutral[100] }}>{name}</span>? This
          action will finalize the approval status and cannot be undone.
        </Text>
      ),
    };
    return (
      <UnderwritingPopupBase
        handleSubmit={handleApprove}
        primaryButtonBackground={palette.filled.success}
        actionSx={{ fontSize: 18 }}
        {...props}
      />
    );
  },
);

interface UnderwritingPopupBaseProps {
  handleSubmit: (cb?: any) => void;
  submitLabel: string;
  modalTitle: string;
  primaryButtonBackground: string;
  children?: React.ReactNode;
  width?: string;
  actionSx?: SxProps;
}

export const UnderwritingPopupBase = ({
  handleSubmit,
  submitLabel,
  modalTitle,
  primaryButtonBackground,
  children,
  width = "549px",
  actionSx,
}: UnderwritingPopupBaseProps) => {
  const modal = useModal();
  const { isDesktopView } = useCustomTheme();

  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    modal.hide();
  };

  const onSubmit = () => {
    setIsLoading(true);

    handleSubmit(() => {
      setIsLoading(false);
      onCancel();
    });
  };

  if (isLoading) {
    return <Loader submitLabel={submitLabel} modal={modal} />;
  }

  return (
    <Popup
      {...muiDialogV5(modal)}
      title={modalTitle}
      isClose
      onCancel={onCancel}
      width={width}
      titleSx={{
        fontSize: 18,
        fontWeight: 350,
        color: palette.neutral[80],
      }}
      actions={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={isDesktopView ? "flex-end" : "stretch"}
          gap={isDesktopView ? 2 : 0}
          width="100%"
        >
          <Button
            background="tertiary"
            onClick={onCancel}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: palette.neutral[70],
              ...(actionSx && actionSx),
              ...(!isDesktopView && {
                flexGrow: 1,
              }),
            }}
          >
            Cancel
          </Button>
          <Button
            background="primary"
            onClick={onSubmit}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              padding: "12px 24px",
              background: primaryButtonBackground,
              "&:hover": {
                background: primaryButtonBackground,
              },
              ...(actionSx && actionSx),
              ...(!isDesktopView && {
                flexGrow: 1,
              }),
            }}
            data-testid="approve-merchant-modal-submit-button"
          >
            {submitLabel}
          </Button>
        </Stack>
      }
      sx={{
        ...(!isDesktopView && {
          "& .MuiDialog-paper": {
            backgroundColor: palette.neutral.white,
          },
        }),
        "& .MuiDialogContent-root": {
          padding: "16px !important",
        },
        "& .MuiDialogActions-root": {
          padding: "16px !important",
        },
      }}
    >
      {children}
    </Popup>
  );
};

const Loader = ({
  modal,
  submitLabel,
}: {
  modal: NiceModalHandler<Record<string, unknown>>;
  submitLabel: string;
}) => {
  return (
    <Popup
      {...muiDialogV5(modal)}
      actions={<div />}
      sx={{
        "& .MuiDialog-paper": {
          border: "none",
          width: "244px", //width prop doesn override the 500 set by default
        },
        "& .MuiDialogActions-root": {
          padding: 0,
        },
      }}
    >
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <LoadingSpinner
          color={palette.accent[3]}
          sx={{
            height: "inherit",
          }}
        />
        <Text fontSize="16px" color={palette.neutral[100]}>
          {ingEnding(submitLabel)}...
        </Text>
      </Grid>
    </Popup>
  );
};

const ingEnding = (label: string) => {
  if (label.endsWith("e")) {
    return label.slice(0, label.length - 1) + "ing";
  }
  return label;
};

export default ApproveMerchantModal;
