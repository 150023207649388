import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { useAppSelector } from "@redux/hooks";
import { selectAuth } from "@redux/slices/auth/auth";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { Item_Layout } from "../provider/provider.type";
import ProductPageContainer from "./products/ProductPageContainer";
import UnpublishedPage from "./products/UnpublishedPage";

import { Box } from "@mui/material";
import PreviewWrapper from "./PreviewWrapper";
import { TFormSourceEnum } from "./products/types";

const PREVIEW_TEXT = "You are viewing unpublished version of payment form.";

const ProductsCustomerView = ({
  isPeekMode = false,
}: {
  isPeekMode?: boolean;
}) => {
  const isAuthenticated = useAppSelector(selectAuth);
  const { displayStatus } = usePayBuilderContext();
  const { methods, data, isPaymentFormInfosLoading } = usePayBuilderForm();
  const {
    Style: { itemLayout },
    Items,
  } = methods.watch();
  const header = {
    heading: data.name,
    description: data.description,
    assetPosition: data.position,
  };

  const savedLogo = data.paymentLogoURL;
  const accentColor = data.accentColor;

  if (displayStatus === "draft" && !isAuthenticated) {
    return <UnpublishedPage />;
  }

  if (isPaymentFormInfosLoading) {
    return <LoadingSpinner />; // TODO: PUT SKELETONS
  }

  if (displayStatus === "draft" && isAuthenticated) {
    return (
      <PreviewWrapper
        background={methods.watch().Style.background}
        previewText={PREVIEW_TEXT}
        source={TFormSourceEnum.PUBLIC}
      >
        <ProductPageContainer
          displayStatus={displayStatus}
          header={header}
          items={Items}
          itemLayout={itemLayout as Item_Layout}
        />
      </PreviewWrapper>
    );
  }

  return (
    <Box sx={{ background: data.backgroundColor }}>
      <ProductPageContainer
        displayStatus={displayStatus}
        header={header}
        items={Items}
        itemLayout={itemLayout as Item_Layout}
        isPeekMode={isPeekMode}
        savedLogo={savedLogo}
        accentColor={accentColor}
      />
    </Box>
  );
};
export default ProductsCustomerView;
