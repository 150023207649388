import { Box } from "@mui/material";
import { styled } from "@theme/v2/Provider";
import GiveAnimationWrapper from "shared/BannerAnimations/GiveAnimationWrapper";
import { PageAnimationsType } from "../BannerAnimations/animations";

type Props = {
  page: PageAnimationsType;
};

const BackgroundAnimation = ({ page }: Props) => {
  return (
    <StyledWrapper page={page} id="slide-me">
      <Box flexGrow={1} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(GiveAnimationWrapper)(({ theme, paddingTop }) => ({
  zIndex: 0,
  width: "100%",
  position: "absolute",
  height: "258px",
  padding: "64px 64px 0 64px",
  [theme.breakpoints.between("v2_sm", "v2_md")]: {
    height: "222px",
    padding: `${paddingTop}px 20px 0 20px`,
  },
  [theme.breakpoints.down("new_sm")]: {
    height: "fit-content",
    padding: `140px 20px 0 20px`,
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFFFFF 85.53%)",
    [theme.breakpoints.down("new_sm")]: {
      background: `
      linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFFFFF 59.03%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.09) 20.5%)
    `,
    },
    zIndex: 1,
    pointerEvents: "none",
  },
}));

export default BackgroundAnimation;
