import { Box, SxProps, Stack } from "@mui/material";
import GiveSearchBar from "shared/SearchBar/GiveSearchBar";
import GiveButton from "shared/Button/GiveButton";
import { styled } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { GiveTooltipProps } from "@shared/Tooltip/GiveTooltip.types";

type Props = {
  actions: {
    label: string | React.ReactNode;
    icon?: JSX.Element;
    handleAction: any;
    disabled?: boolean;
    endIcon?: JSX.Element;
    tooltipProps?: Partial<GiveTooltipProps>;
    sx?: SxProps;
    hidden?: boolean;
    order?: number;
  }[];
  sponsorActions?: {
    label: string;
    handleAction: any;
    isSelected: boolean;
  }[];
  search: string;
  onSearchChange: (value: string) => void;
  searchOnEnter?: boolean;
  isAllActionsHidden?: boolean;
  sx?: SxProps;
  leftActions?: React.ElementType | null;
};

const GiveMerchantTableActions = ({
  actions,
  search,
  onSearchChange,
  searchOnEnter = false,
  isAllActionsHidden = false,
  sponsorActions,
  sx,
  leftActions: LeftActions,
}: Props) => {
  const { isWideView, isMobileView } = useCustomThemeV2();

  return (
    <Container sx={sx}>
      {sponsorActions && <SponsorActions sponsorActions={sponsorActions} />}
      <Stack direction="row" spacing="12px">
        <GiveSearchBar
          value={search}
          handleChange={onSearchChange}
          searchOnEnter={searchOnEnter}
          sx={{
            width: !isWideView ? "100%" : "320px",
            paddingX: isMobileView ? "20px" : 0,
          }}
        />
        {isWideView && LeftActions && <LeftActions />}
      </Stack>

      {!isAllActionsHidden && (
        <ActionButtons>
          {!isWideView && LeftActions && <LeftActions />}
          {actions
            .sort((a, b) => (a?.order || 0) - (b?.order || 0))
            .map(
              (
                {
                  label,
                  icon,
                  handleAction,
                  disabled,
                  endIcon,
                  tooltipProps,
                  hidden,
                  sx,
                },
                index,
              ) => {
                if (hidden) return null;
                return (
                  <GiveTooltip
                    key={index}
                    disableHoverListener={
                      tooltipProps?.disableHoverListener || true
                    }
                    title={tooltipProps?.title || ""}
                    color={tooltipProps?.color || "default"}
                    {...tooltipProps}
                    sx={{ width: "auto" }}
                  >
                    <StyledButton
                      variant={!isWideView ? "filled" : "ghost"}
                      label={label}
                      size="large"
                      startIcon={icon}
                      onClick={handleAction}
                      disabled={disabled}
                      endIcon={endIcon}
                      color="light"
                      sx={sx}
                    />
                  </GiveTooltip>
                );
              },
            )}
        </ActionButtons>
      )}
    </Container>
  );
};

const SponsorActions = ({
  sponsorActions,
}: {
  sponsorActions: Props["sponsorActions"];
}) => {
  const { isMobileView } = useCustomThemeV2();

  return (
    <ActionButtons order={isMobileView ? 2 : 0}>
      {sponsorActions?.map(({ label, handleAction, isSelected }, index) => {
        return (
          // Without this box wrapper, horizontal scrolling in mobile view is not working
          <Box key={label}>
            <SponsorStyledButton
              variant="ghost"
              label={label}
              size="large"
              onClick={() => handleAction(index)}
              isSelected={isSelected}
            />
          </Box>
        );
      })}
    </ActionButtons>
  );
};

const Container = styled(Box)(({ theme }) => ({
  paddingTop: "24px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "20px",
  "& .MuiFormControl-root": {
    width: "auto",
    "& .MuiTextField-root": {
      width: "320px",
    },
  },
  [theme.breakpoints.down("v2_md")]: {
    paddingTop: "12px",
  },
  [theme.breakpoints.between("v2_sm", "new_lg")]: {
    "& .MuiFormControl-root": {
      width: "auto",
      "& .MuiTextField-root": {
        width: "auto",
      },
    },
  },
  [theme.breakpoints.down("v2_sm")]: {
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100vw",
    position: "relative",
    marginLeft: "-20px",
    marginRight: "-20px",
    overflow: "hidden",
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  },
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "42px",
  gap: "12px",
  [theme.breakpoints.down("v2_sm")]: {
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
    alignItems: "start",
    overflow: "auto",
    scrollbarWidth: "none",
    "& > div": {
      alignItems: "start",
    },
  },
}));

const StyledButton = styled(GiveButton)(({ theme }) => ({
  borderRadius: "40px",
  whiteSpace: "nowrap",
  border: "none",
}));

const SponsorStyledButton = styled(GiveButton)<{ isSelected?: boolean }>(
  ({ theme, isSelected }) => ({
    color: isSelected
      ? theme.palette.primitive?.blue[100]
      : theme.palette.text.secondary,
    borderRadius: "40px",
    whiteSpace: "nowrap",
    border: "none",
    background: isSelected
      ? theme.palette.gradient["ocean-blue"]?.highlight
      : "transparent",
    "&:hover": {
      ...(isSelected && {
        background: theme.palette.gradient["ocean-blue"]?.highlight,
        color: theme.palette.primitive?.blue[100],
      }),
    },
  }),
);

export default GiveMerchantTableActions;
