import { useModal } from "@ebay/nice-modal-react";
import { BoxProps, Stack, styled } from "@mui/material";
import { Button } from "@common/Button";
import { useState } from "react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { Virtuoso } from "react-virtuoso";
import CategoryItem from "@components/AcquirerEnterprises/EnterprisePreview/components/CategoryItem";
import { keyframes } from "@mui/material/styles";
import { useDebouncedCallback } from "use-debounce";
import { Box } from "@mui/material";
import { Minus, Plus, Spinner } from "@phosphor-icons/react";
import NoResultsState from "@common/EmptyState/NoResultsState";
import MCCSearchInput from "@components/AcquirerEnterprises/EnterprisePreview/modals/MCCSearchInput";
import { EDIT_ENTERPRISE_MCC_MODAL } from "modals/modal_names";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { isEmpty } from "lodash";
import { Tooltip } from "@common/Tooltip";

interface ModalProps {
  id: number;
  categories: TCategoryCode[];
  parentCategories: TCategoryCode[];
  onClose?: (data: any) => void;
}

const EditEnterpriseMCCNew = ({
  id,
  categories = [],
  parentCategories,
  onClose,
}: ModalProps) => {
  const [search, setSearch] = useState("");
  const [placeholderSearch, setPlaceholderSearch] = useState("");
  const [isLoadingSearch, setIsLoading] = useState(false);
  const [added, setAdded] = useState<TCategoryCode[]>(categories);
  const [filteredParentCategories, setFilteredParentCategories] =
    useState<TCategoryCode[]>(parentCategories);

  const { isEnabledNewMccFlow } = useGetFeatureFlagValues();
  const debounced = useDebouncedCallback((value) => {
    setFilteredParentCategories(
      parentCategories.filter((c) => {
        return (
          c.code.includes(value) ||
          c.title.toLowerCase().includes(value.toLowerCase()) ||
          value === ""
        );
      }),
    );
    setIsLoading(false);
    setPlaceholderSearch(value);
  }, 1000);

  const availableItems = isEnabledNewMccFlow
    ? filteredParentCategories?.filter(
        (item) => !added.some((cat) => cat.id === item.id),
      )
    : filteredParentCategories;
  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setSearch(value);
    setIsLoading(true);
    debounced(e.target.value);
  }
  const { isMobileView } = useCustomTheme();
  const { handleSubmit, isLoading } = useUpdateMerchantInfo(id);
  function submit() {
    if (onClose) {
      onClose(added);
      handleCancel();
    } else {
      const addedIds = added.map((c) => c.id);
      handleSubmit(
        "categories",
        {
          categories: addedIds,
        },
        handleCancel,
      );
    }
    return;
  }
  const modal = useModal(EDIT_ENTERPRISE_MCC_MODAL);
  const open = modal.visible;
  const handleCancel = () => {
    modal.hide();
    isMobileView && modal.remove();
  };
  const onAnimationEnd = () => {
    modal.remove();
  };
  function handleAdd(id: TCategoryCode["id"]) {
    const category = parentCategories.find((c) => c.id === id);
    if (category) {
      setAdded((prev) => {
        return [...prev, category];
      });
    }
  }
  function handleRemove(id: TCategoryCode["id"]) {
    setAdded((prev) => {
      return prev.filter((c) => c.id !== id);
    });
  }

  const handleAddAll = () => {
    setAdded(parentCategories);
  };
  const handleRemoveAll = () => {
    setAdded([]);
  };

  const isAddedEmpty = isEmpty(added) && isEnabledNewMccFlow;
  const areAllAdded = isEmpty(availableItems) && isEnabledNewMccFlow;

  return (
    <EditMerchantBaseModal
      onAnimationEnd={onAnimationEnd}
      title="Add Merchant Category Codes (MCC)"
      description="Choose the categories this Provider can assign to its merchants"
      open={open}
      handleCancel={handleCancel}
      sx={{
        "& .MuiPaper-root": {
          width: "928px !important",
          maxWidth: "928px !important",
        },
      }}
      actions={
        <>
          <Button
            size="medium"
            background="tertiary"
            onClick={handleCancel}
            disabled={isLoading}
            sx={{
              padding: "8px 24px",
              ...(isMobileView && {
                width: "50%",
              }),
            }}
          >
            Cancel
          </Button>
          <Tooltip
            disableHoverListener={!isAddedEmpty}
            title="Add at least one MCC code"
          >
            <Button
              size="medium"
              background="primary"
              form="edit-merchant-info-form"
              disabled={isLoading || isAddedEmpty}
              sx={{
                padding: "8px 24px",
                ...(isMobileView && {
                  width: "50%",
                }),
              }}
              onClick={submit}
            >
              Save
            </Button>
          </Tooltip>
        </>
      }
    >
      <Stack
        direction="column"
        id="edit-merchant-info-form"
        gap={2}
        height="100%"
      >
        <MCCSearchInput value={search} onChange={handleSearch} />
        <Stack
          direction={isMobileView ? "column" : "row"}
          gap={2}
          height="100%"
        >
          <Container
            hideButton={areAllAdded}
            total={availableItems?.length || 0}
            onClick={handleAddAll}
          >
            <Virtuoso
              style={{
                height: "100%",
                width: "100%",
                opacity: isLoadingSearch ? 0.5 : 1,
                transition: "opacity 0.3s ease-in-out",
              }}
              components={{
                ...(!isEmpty(search) &&
                  isEmpty(availableItems) && {
                    EmptyPlaceholder: () => (
                      <NoResultsState
                        sx={{
                          height: "100%",
                          width: "100%",
                        }}
                        searchQuery={placeholderSearch}
                      />
                    ),
                  }),
              }}
              data={availableItems}
              itemContent={(_, c) => {
                const isSelected = Boolean(added.find((a) => a.id === c.id));

                return (
                  <CategoryItem
                    key={c.id}
                    code={c.code}
                    riskStatus={c.riskStatus}
                    title={c.title}
                    onClick={() =>
                      isSelected ? handleRemove(c.id) : handleAdd(c.id)
                    }
                    isSelected={isSelected}
                  />
                );
              }}
            />
            {isLoadingSearch && <StyledSpinner />}
          </Container>
          <Container
            total={added?.length || 0}
            type="remove"
            onClick={handleRemoveAll}
            hideButton={isAddedEmpty}
          >
            <Virtuoso
              style={{
                height: "100%",
                width: "100%",
              }}
              data={added}
              itemContent={(_, c) => (
                <CategoryItem
                  key={c.id}
                  riskStatus={c.riskStatus}
                  code={c.code}
                  title={c.title}
                  onClick={() => handleRemove(c.id)}
                  isOnlyRemaining={added.length === 1}
                  added
                />
              )}
            />
          </Container>
        </Stack>
      </Stack>
    </EditMerchantBaseModal>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const StyledSpinner = styled(Spinner)(() => ({
  animation: `${fadeIn} 250ms ease-in-out`,
  position: "absolute",
}));

const Container = ({
  children,
  onClick,
  type = "add",
  total,
  hideButton,
}: {
  children: React.ReactNode;
  onClick: () => void;
  type?: "add" | "remove";
  total: number;
  hideButton?: boolean;
}) => {
  const { isMobileView } = useCustomTheme();
  const { isEnabledNewMccFlow } = useGetFeatureFlagValues();
  const isAvailableBox = type === "add";

  const Icon = isAvailableBox ? Plus : Minus;
  return (
    <Stack flex={1}>
      {isEnabledNewMccFlow && (
        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          mb="12px"
        >
          <Text color={palette.black[100]} fontSize="14px">
            {isAvailableBox ? "Available" : "Added"} {total}
          </Text>
          {!hideButton && (
            <Stack
              sx={{
                cursor: "pointer",
              }}
              gap="4px"
              alignItems="center"
              flexDirection="row"
              onClick={onClick}
            >
              <Icon size="20px" fill={palette.givebox[600]} />
              <Text color={palette.givebox[600]} fontSize="14px">
                {isAvailableBox ? "Add All" : "Remove All"}
              </Text>
            </Stack>
          )}
        </Stack>
      )}

      <StyledBox isMobile={isMobileView}>{children}</StyledBox>
    </Stack>
  );
};

const StyledBox = styled(Box)<BoxProps & { isMobile?: boolean }>(
  ({ theme, isMobile }) => ({
    height: isMobile ? "100%" : "400px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    border: `1px solid ${theme.palette.neutral[10]}`,
    borderRadius: "8px",
  }),
);

export default EditEnterpriseMCCNew;
