import React from "react";
import { Box, Link, SxProps, Theme, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Text } from "@common/Text";
import { Link as RouterLink } from "react-router-dom";
import { palette } from "@palette";
import { BaseCampaignCard } from "@common/Campaigns/BaseCampaignCard";
import { CommonProductProps } from "@common/Campaigns/campaigns.types";
import { animated } from "react-spring";
import { isEmpty } from "lodash";

type CampaignCardProps = {
  id: number | string;
  name?: string;
  imageURL?: string;
  others?: any[];
  sx?: SxProps<Theme> | undefined;
  noModal?: boolean;
  openSidePanel?: () => void;
  isSelected?: boolean;
  isDraft?: boolean;
};

const otherTextStyles = {
  paddingInline: "8px",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "16.8px",
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  marginTop: "auto",
  marginBottom: "auto",
  wordBreak: "break-word",
};

const CampaignCard = ({
  name,
  imageURL,
  others,
  id,
  sx = {},
  columns,
  noModal,
  openSidePanel,
  isSelected,
  height,
  isDraft,
}: CampaignCardProps & CommonProductProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <CustomParent
      isModal={!noModal}
      openSidePanel={openSidePanel as any}
      sx={{ ...sx, background: isSelected ? "white" : "transparent" }}
      id={id}
    >
      <animated.div style={{ height }}>
        <StyledRoot>
          <BaseCampaignCard isDraft={isDraft} name={name} imageURL={imageURL} />
          {isDesktop && (
            <>
              {others?.map((item, index) => {
                const colIndex = Math.min(index + 1, columns?.length);
                const size = columns ? columns[colIndex].size ?? 0 : 1;
                const computedWidth = size * 10;
                const formattedItem = Number.isFinite(item) ? `${item}` : item; //convert to string if item is a number
                const isItemEmpty = isEmpty(formattedItem);
                return (
                  <Text
                    width={`${computedWidth}%`}
                    sx={otherTextStyles}
                    key={index}
                    boxSizing="border-box"
                  >
                    {isItemEmpty ? "-" : item}
                  </Text>
                );
              })}
            </>
          )}
        </StyledRoot>
      </animated.div>
    </CustomParent>
  );
};

const StyledRoot = styled(Box)({
  padding: "12px 4px",
  display: "flex",
  flexDirection: "row",
  "&:hover": {
    background: palette.common.white,
  },
});

export default CampaignCard;

const CustomParent = ({
  isModal,
  children,
  openSidePanel,
  sx,
  id,
}: {
  isModal: boolean;
  children: React.ReactNode;
  openSidePanel: () => void;
  sx?: SxProps<Theme> | undefined;
  id?: string | number;
}) =>
  isModal ? (
    <Box
      component={"button"}
      color="inherit"
      sx={{ all: "unset", ...sx, cursor: "pointer", width: "100%" }}
      onClick={openSidePanel}
      data-testid={`campaign-card-${id}`}
    >
      {children}
    </Box>
  ) : (
    <Link
      component={RouterLink}
      underline="none"
      color="inherit"
      to={`${id}`}
      sx={{ ...sx }}
    >
      {children}
    </Link>
  );
