import React from "react";
import { Box } from "@mui/material";
import { IMerchantBankAccount } from "../../data.types";
import { ExternalLinkIcon } from "@assets/rebrandIcons";
import { Button } from "@common/Button";
import { Text, TruncateText } from "@common/Text";
import { palette } from "@palette";
import { BankAccountTag, BankAccountTagType } from "@common/Tag/BankAccountTag";
import { checkPortals } from "@utils/routing";
import { useAppSelector } from "@redux/hooks";
import { selectMasqueradeMode } from "@redux/slices/app";
import NiceModal from "@ebay/nice-modal-react";
import { PLAID_ACCOUNT_INFO } from "modals/modal_names";

type DetailsItemProps = {
  label: string;
  description: string | React.ReactNode;
  highlight?: boolean;
  hidden?: boolean;
};

const BankAccountDetails = ({
  bankName,
  name,
  plaid,
  routingNumber,
  type,
  authPlaidInfo,
  created,
  accountNumber,
  status,
}: Omit<IMerchantBankAccount, "status"> & {
  status: BankAccountTagType | null;
}) => {
  const { name: masqName } = useAppSelector(selectMasqueradeMode);
  const { isEnterprisePortal } = checkPortals();

  const handleViewPlaidInfo = () => {
    if (!authPlaidInfo) return;
    NiceModal.show(PLAID_ACCOUNT_INFO, { data: authPlaidInfo });
  };

  const detailsList = [
    {
      label: "Status",
      value: (
        <Box display="inline-flex">
          {status && <BankAccountTag type={status} />}
        </Box>
      ),
      hidden: !status,
    },
    {
      label: "Plaid",
      value: plaid ? (
        <Button
          background="tertiary"
          size="medium"
          sx={buttonExternalLinkStyle}
          endIcon={<ExternalLinkIcon />}
          onClick={handleViewPlaidInfo}
        >
          <Text fontWeight="book" color={palette.neutral[90]}>
            Connected (View Info)
          </Text>
        </Button>
      ) : (
        "Not Connected"
      ),
      hidden: !authPlaidInfo || (isEnterprisePortal && !masqName),
    },
    { label: "Created", value: created },
    { label: "Account Type", value: type },
    { label: "Account Name", value: name },
    { label: "Account Number", value: accountNumber },
    { label: "Routing Number", value: routingNumber },
    { label: "Bank Name", value: bankName },
  ];

  return (
    <>
      {detailsList.map((item, index) => (
        <DetailsItem
          key={item.label}
          label={item.label}
          description={item.value}
          highlight={Boolean(index % 2 === 1)}
          hidden={item.hidden}
        />
      ))}
    </>
  );
};

const DetailsItem = ({
  label,
  description,
  highlight,
  hidden,
}: DetailsItemProps) => (
  <Box
    display={hidden ? "none" : "flex"}
    sx={(theme) => ({
      borderRadius: "4px",
      padding: "4px 8px",
      background: highlight ? palette.background.dimmedWhite : "inherit",
      [theme.breakpoints.down("sm")]: {
        "& > *": { flex: 1 },
      },
    })}
  >
    <Text fontWeight="light" color={palette.neutral[80]} width="184px">
      {label}
    </Text>
    {typeof description === "string" ? (
      <TruncateText lineClamp={1} fontWeight="book" color={palette.neutral[90]}>
        {description}
      </TruncateText>
    ) : (
      description
    )}
  </Box>
);

const buttonExternalLinkStyle = {
  padding: 0,
  height: "20px",
};

export default BankAccountDetails;
