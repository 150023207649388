import { Box, Stack } from "@mui/material";
import Contact from "./components/Contact";
import { FormProvider } from "react-hook-form";
import Payment from "./components/Payment";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import useCheckoutPreview from "./hooks/useCheckoutPreview";
import SupportPreview from "./components/SupportPreview";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { PaymentSuccessInfo } from "@pages/Checkout/components/PaymentSuccess";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { useCart } from "../provider/CartContext";
import CheckoutFooter from "./components/CheckoutFooter";
import CheckoutTermsCheckbox from "./components/CheckoutTermsCheckbox";
import { LogoComponent } from "../View/fundraiser/components/Atom.component";
import CheckoutButton from "./components/CheckoutButton";
import CheckoutSelector from "./CheckoutSelector";
import GiveBreadcrumb from "@shared/Breadcrumbs/GiveBreadcrumb";
import { BreadcrumbItem } from "@shared/Breadcrumbs/GiveBreadcrumbs.types";
import CheckoutCart from "@pages/Checkout/components/CheckoutCart";
import useCheckFormType from "../components/hooks/useCheckFormType";
import { useNavigate, useParams } from "react-router-dom";

const CheckoutFormPreview = ({
  isPreviewMode = true,
  onPaymentSuccess,
  breadcrumbItems,
}: {
  isPreviewMode?: boolean;
  onPaymentSuccess?: (data: PaymentSuccessInfo) => void;
  breadcrumbItems?: BreadcrumbItem[];
}) => {
  const { isDesktopView, isMobileView, isTabletView } = useCustomThemeV2();
  const { fees } = useCart();
  const builderContext = usePayBuilderContext();
  const {
    screenStates: {
      isMobileView: isMobileStateSelected,
      isTabletView: isTabletStateSelected,
      isDesktopView: isDesktopStateSelected,
    },
  } =
    Object.keys(builderContext)?.length > 0
      ? builderContext
      : {
          screenStates: {
            isMobileView: isMobileView,
            isTabletView: isTabletView,
            isDesktopView: isDesktopView,
          },
        };

  const { methods, onSubmit, isLoading, isDeclined } =
    useCheckoutPreview(onPaymentSuccess);
  const {
    methods: leftSidepanelMethods,
    parsedValues: { background, accentColor, logo },
  } = usePayBuilderForm();

  const { render: isSupportEnabled } =
    leftSidepanelMethods.watch().Checkout.support;

  const { isFundraiser } = useCheckFormType();

  const navigate = useNavigate();
  const { id } = useParams();

  const handleLogoClick = () => {
    isPreviewMode ? undefined : navigate(`/${id}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: background,
        padding:
          isMobileView || isMobileStateSelected || isTabletStateSelected
            ? "16px"
            : "40px",
        ...(isPreviewMode && { marginTop: "20px" }),
        ...(isDesktopView && isPreviewMode && { borderRadius: "20px" }),
      }}
    >
      <FormProvider {...methods}>
        <Stack gap="48px" id="checkout-form" component="form">
          {(isPreviewMode || !isMobileView) && logo && (
            <LogoComponent
              logo={logo}
              isPreviewMode={isPreviewMode}
              handleClick={handleLogoClick}
            />
          )}
          <GiveBreadcrumb items={breadcrumbItems} />

          <Contact isDisabledFields={isPreviewMode} />
          <CheckoutSelector
            isMobileView={isMobileStateSelected}
            isDisabledFields={isPreviewMode}
          />

          <Payment
            isMobileView={isMobileStateSelected}
            isDeclined={isDeclined}
            isDisabledFields={isPreviewMode}
          />

          {isMobileView && isFundraiser && <CheckoutCart />}

          {isSupportEnabled && (
            <SupportPreview fees={fees} isDisabledFields={isPreviewMode} />
          )}
          <Stack gap="24px" direction="column">
            <CheckoutTermsCheckbox
              control={methods.control}
              isPreviewMode={isPreviewMode}
            />
            <CheckoutButton
              isPreviewMode={isPreviewMode}
              isLoading={isLoading}
              handleSubmit={methods.handleSubmit(onSubmit)}
              accentColor={accentColor}
            />
          </Stack>
          <CheckoutFooter isDesktopView={isDesktopStateSelected} />
        </Stack>
      </FormProvider>
    </Box>
  );
};

export default CheckoutFormPreview;
