import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { Controller } from "react-hook-form";
import About from "./About";
import HFGiveCustomAmount from "@shared/HFInputs/HFGiveCustomAmount/HFGiveCustomAmount";

const MAX_TARGET = 99999999999999;

function FundraiserAbout({
  isEdit,
  isFromADB,
}: {
  isEdit?: boolean;
  isFromADB?: boolean;
}) {
  const { methods } = usePayBuilderForm();
  const { watch, control } = methods;

  const isAddTargetEnabled = watch("About.addTarget");

  return (
    <Box pb="20px">
      <About isEdit={isEdit} isFromADB={isFromADB} />
      <Stack spacing={2} marginTop={2}>
        <Stack direction="row" spacing={1}>
          <Controller
            control={control}
            name="About.addTarget"
            render={({ field: { onChange, value } }) => {
              return <GiveCheckbox checked={value} onChange={onChange} />;
            }}
          />
          <GiveText color="secondary" variant="bodyS" alignItems="center">
            Add Target
          </GiveText>
        </Stack>
        {isAddTargetEnabled && (
          <HFGiveCustomAmount
            label="Target"
            name="About.targetValue"
            currency="usd"
            bounded
            max={MAX_TARGET}
          />
        )}
        <Stack direction="row" spacing={1}>
          <Controller
            control={control}
            name="About.showHistory"
            render={({ field: { onChange, value } }) => {
              return <GiveCheckbox checked={value} onChange={onChange} />;
            }}
          />
          <GiveText color="secondary" variant="bodyS" alignItems="center">
            Show Donations History
          </GiveText>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FundraiserAbout;
