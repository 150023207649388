import { Autocomplete, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { generateTimeOptions } from "./utils";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import { Clock } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";

export function AutoCompleteTime({ onChange, error, value }: any) {
  const theme = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // We do not filter based on input, always return the full list of options
  const filterOptions = (options: any) => options;

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Autocomplete
        disableClearable
        freeSolo
        open={open}
        value={value}
        sx={{ width: "100%" }}
        componentsProps={{
          popper: {
            sx: { display: isMobileView ? "none" : "auto" },
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 10],
                },
              },
            ],
          },
          paper: {
            sx: {
              borderRadius: "16px",
              boxShadow: "none",
              padding: "8",
              overflow: "hidden",
              background: theme.palette.primitive?.neutral[25],
              border: `1px solid #CCCCC9`,
              backdropFilter: "blur(7.5px)",
            },
          },
        }}
        onClose={() => setOpen(false)}
        options={generateTimeOptions()}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option}
        popupIcon={null}
        openOnFocus={false}
        onInputChange={(event, newValue) => onChange(newValue)}
        renderInput={(params) => {
          const { InputProps, ...rest } = params;
          return (
            <GiveInput
              {...rest}
              ref={inputRef}
              error={Boolean(error)}
              helperText={error?.message}
              InputProps={{
                ...InputProps,
                startAdornment: (
                  <Stack sx={{ marginRight: "8px" }}>
                    <Clock size={20} />
                  </Stack>
                ),
              }}
              variant="outlined"
              inputProps={{
                style: {
                  caretColor: "transparent",
                  cursor: "pointer",
                },
                onKeyDown: (e) => e.preventDefault(),
                ...rest.inputProps,
              }}
              onClick={(e: any) => {
                setOpen((prev) => !prev);
                setAnchorEl(e?.currentTarget as HTMLElement);
              }}
              sx={{
                "& .MuiInputBase-root": {
                  cursor: "pointer",
                },
              }}
            />
          );
        }}
      />
      {isMobileView ? (
        <ContextualMenu
          anchorEl={anchorEl as any}
          handleClose={closeMenu}
          options={generateTimeOptions().map((x) => ({
            text: x,
            onClick: () => {
              onChange(x);
              closeMenu();
            },
          }))}
          color="primary"
          texture="solid"
          horizontalOrigin="left"
          menuWidth={200}
          onChange={(v) => {
            console.log(v);
          }}
        />
      ) : null}
    </>
  );
}
