import { Typography } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import { TextProps } from "./giveText.types";

const GiveText = ({
  children,
  variant = "bodyM",
  color = "primary",
  ...rest
}: TextProps) => {
  const { palette } = useAppTheme();
  const textColors = {
    primary: palette.text.primary,
    secondary: palette.text.secondary,
    link: palette.primitive?.blue[100],
    success: palette.primitive?.success[50],
    error: palette.primitive?.error[50],
    default: palette.text.invert,
    warning: palette.primitive?.warning?.["100"],
    warning1: palette.primitive?.warning?.[50],
    success1: palette.primitive?.success?.["100"],
    light: palette.text.primary,
    error1: palette.primitive?.error?.["100"],
    tertiary: palette.text.tertiary,
    blue: palette.primitive?.blue[100],
  };
  return (
    <Typography
      variant={variant}
      version="two"
      color={textColors[color]}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default GiveText;
