import GivePayment from "@assets/icons/GivePayment";
import NiceModal from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { Lock } from "@phosphor-icons/react";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import GiveLink from "@shared/Link/GiveLink";
import GiveText from "@shared/Text/GiveText";
import { PRIVACY_POLICY_MODAL, REFUND_POLICY_MODAL } from "modals/modal_names";

const CheckoutFooter = ({ isDesktopView }: { isDesktopView: boolean }) => {
  const handleOpenRefundModal = () => {
    NiceModal.show(REFUND_POLICY_MODAL);
  };

  const openPrivacyModal = () => {
    NiceModal.show(PRIVACY_POLICY_MODAL);
  };

  return (
    <>
      <Stack
        direction={isDesktopView ? "row" : "column"}
        justifyContent={isDesktopView ? "space-between" : "center"}
        alignItems="center"
        spacing={isDesktopView ? 0 : 2}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Lock size={16} />
          <GiveText variant="bodyS" color="secondary">
            Transaction Secured and Encrypted
          </GiveText>
        </Stack>
        <GivePayment />
      </Stack>

      <Stack spacing="24px">
        <GiveDivider />
        <Stack direction="row" justifyContent="center" spacing={2}>
          <GiveLink
            onClick={handleOpenRefundModal}
            component="p"
            sx={{ cursor: "pointer" }}
          >
            Refund Policy
          </GiveLink>
          <GiveLink
            onClick={openPrivacyModal}
            component="p"
            sx={{ cursor: "pointer" }}
          >
            Privacy Policy
          </GiveLink>
        </Stack>
      </Stack>
    </>
  );
};

export default CheckoutFooter;
