import { QFORM_QUERY_KEY } from "@pages/AcquirerPortal/Enterprises/Modal/constants";
import { SaveAndCloseBtn } from "@sections/PayBuilder/components/SaveAndCloseBtn";
import { useQueryObserver } from "@sections/PayBuilder/LaunchStep/hooks/useQueryObserver";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveButton from "@shared/Button/GiveButton";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { SAVE_AND_CLOSE_MODAL } from "modals/modal_names";
import { FC } from "react";

const PanelActions: FC<{ handleClose?: () => void }> = ({ handleClose }) => {
  const { isMobileView } = useCustomThemeV2();
  const { activeStepIndex, isLastStep, goToStep } = usePayBuilderContext();
  const { mutate, isLoading, methods } = usePayBuilderForm();
  const isNextDisabled = isLoading || !methods.formState.isValid;
  const { remove } = useQueryObserver(QFORM_QUERY_KEY);
  return (
    <>
      {!isMobileView && <SaveAndCloseBtn modalId={SAVE_AND_CLOSE_MODAL} />}
      {activeStepIndex !== 0 && (
        <GiveButton
          size="large"
          sx={{
            marginLeft: isMobileView ? 0 : "auto",
          }}
          variant="ghost"
          label="Back"
          onClick={() => goToStep({ offSet: -1 })}
        />
      )}

      <GiveButton
        size="large"
        label={isLastStep ? "Save" : "Next"}
        disabled={isNextDisabled}
        onClick={() => {
          mutate({
            handleSuccessCB: () => {
              !isLastStep && goToStep({ offSet: 1 });
              isLastStep && handleClose?.();
              remove();
            },
          });
        }}
        sx={{
          marginLeft: isMobileView ? "auto" : 0,
        }}
      />
    </>
  );
};

export default PanelActions;
