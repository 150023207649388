import { ConversationsIconNotFilled } from "@assets/icons";
import { EditIcon } from "@assets/icons/RebrandedIcons";
import CopyButton from "@common/CopyButton";
import { CountryFlag } from "@common/CountryFlag";
import { StyledIconButton } from "@common/IconButton";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { Text, TruncateText } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { Box, Divider, Grid, Stack, SxProps, styled } from "@mui/material";
import { palette } from "@palette";
import { parseAmount } from "@utils/index";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useAccessControl } from "features/Permissions/AccessControl";
import React, { Fragment, useMemo } from "react";
import useSlugPrefixFormatter from "@components/Merchants/MerchantPreview/hooks/useSlugPrefixFormatter";
import { followLink } from "@hooks/common/documents/utils";

export const MerchantModalSectionTitle = styled(Text)(() => ({
  color: palette.gray[300],
  fontSize: "18px",
  lineHeight: "32px",
  letterSpacing: "-0.18px",
  background: "inherit",
  whiteSpace: "nowrap",
}));

type Props = {
  keyName: string | React.ReactNode;
  value?: string | number | React.ReactNode;
  fullWidth?: boolean;
  clampText?: boolean;
  isPhoneNumber?: boolean;
  isValue?: boolean;
  isAmount?: boolean;
  suf?: string;
  prefix?: string;
  link?: string;
  canBeCopied?: boolean;
};

export const KeyValue = ({
  keyName,
  value,
  fullWidth = false,
  clampText = true,
  isPhoneNumber = false,
  isValue,
  isAmount,
  suf,
  prefix = "",
  link,
  canBeCopied,
}: Props) => {
  const linkHandler = () => {
    if (link) followLink(link, { target: "__blank" });
  };
  const slugPrefix = useSlugPrefixFormatter(prefix);
  return (
    <Stack
      direction="column"
      gap="4px"
      minWidth={fullWidth ? "100%" : "50%"}
      data-testid={`keyvalue-${keyName}-container`}
      position="relative"
    >
      <Text
        color={palette.black[100]}
        variant="body"
        lineHeight="16.8px"
        fontWeight="light"
      >
        {keyName}
      </Text>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="flex-start"
        >
          {isPhoneNumber && typeof value === "string" && (
            <CountryFlag phoneNumber={value} />
          )}
          {clampText ? (
            <TruncateText
              lineClamp={1}
              color={palette.black[100]}
              variant="body"
              lineHeight="16.8px"
              fontWeight="book"
              minHeight="16.8px"
              link={link}
            >
              {(isValue || isAmount || suf || prefix) && value ? (
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  onClick={linkHandler}
                  width="100%"
                >
                  {prefix && (
                    <span
                      style={{
                        color: palette.neutral[60],
                        marginRight: "3px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {keyName === "Permalink" ? slugPrefix : prefix}
                    </span>
                  )}
                  <StyledFigure component="span">
                    {isAmount ? parseAmount(+value) : value}
                  </StyledFigure>
                  {suf && <span style={{ marginLeft: "3px" }}>{suf}</span>}
                </Box>
              ) : (
                value
              )}
            </TruncateText>
          ) : (
            <Text
              color={palette.black[100]}
              variant="body"
              lineHeight="16.8px"
              fontWeight="book"
              minHeight="16.8px"
            >
              {value}
            </Text>
          )}
        </Stack>
        {canBeCopied && link && (
          <CopyButton text={link} sx={{ width: "20px", height: "20px" }} />
        )}
      </Stack>
    </Stack>
  );
};

const StyledFigure = styled(Box)(() => ({
  fontWeight: 350,
  color: palette.black[100],
  fontSize: 14,
  display: "-webkit-box",
  lineClamp: 1,
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflowWrap: "break-word",
  textOverflow: "ellipsis",
  overflow: "hidden",
  wordBreak: "break-word",
}));

type MerchantSectionContainerProps = {
  children: React.ReactNode;
  editHandler?: () => void;
  sectionTitle: string;
  customHeader?: React.ReactNode;
  containerSx?: SxProps;
  titleSx?: ITextProps["sx"];
  isAcquirer?: boolean;
  isEnterpriseSidePane?: boolean;
};

export const MerchantSectionContainer = ({
  children,
  editHandler,
  sectionTitle,
  containerSx,
  titleSx,
  customHeader,
  isAcquirer,
  isEnterpriseSidePane,
}: MerchantSectionContainerProps) => {
  const { openConversationHandler } = useConversationsModal();

  const onClick = () => {
    openConversationHandler({
      id: 0,
      name:
        sectionTitle === "Business address" ? "Business Address" : sectionTitle,
      paths: [],
    });
  };

  const formattedSectionTitle = sectionTitle.toLowerCase().split(" ").join("-");
  const { merchant_underwriting } = useEnterprisePermissions();
  const isUpdateMCCAllowed = useAccessControl({
    resource: isEnterpriseSidePane
      ? RESOURCE_BASE.ENTERPRISE
      : RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  return (
    <Container sx={containerSx}>
      <Stack direction="row" alignItems="center">
        <MerchantModalSectionTitle sx={titleSx}>
          {sectionTitle}
        </MerchantModalSectionTitle>
        {customHeader || (
          <>
            <Box flexGrow={1} />
            {merchant_underwriting && isAcquirer && (
              <EditButton
                data-testid={`conversation-${sectionTitle}-button`}
                onClick={onClick}
              >
                {
                  <ConversationsIconNotFilled
                    section={`${formattedSectionTitle}`}
                  />
                }
              </EditButton>
            )}
            {editHandler && (
              <WithTooltipWrapper
                hasTooltip={!isUpdateMCCAllowed}
                tooltipProps={{
                  show: !isUpdateMCCAllowed,
                  message: EDIT_DENY_MESSAGE,
                }}
              >
                <EditButton
                  data-testid="merchant-info-edit-button"
                  disabled={!isUpdateMCCAllowed}
                  onClick={isUpdateMCCAllowed ? editHandler : undefined}
                >
                  <EditIcon />
                </EditButton>
              </WithTooltipWrapper>
            )}
          </>
        )}
      </Stack>
      {children}
    </Container>
  );
};

export const EditButton = styled(StyledIconButton)(({ theme }) => ({
  maxWidth: "24px",
  maxHeight: "24px",
  "&:active, :hover": {
    backgroundColor: palette.liftedWhite[200],
  },
  borderRadius: "4px",
  padding: "0 !important",
  visibility: "hidden",

  [theme.breakpoints.down("sm")]: {
    visibility: "visible",
  },
}));

const Container = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "16px",
  paddingBottom: "16px",
  borderBottom: `1px solid ${palette.liftedWhite[200]}`,

  "&:hover": {
    "& .MuiButtonBase-root.MuiButton-root": {
      visibility: "visible",
    },
  },
}));

export type TListItem = {
  title: string;
  value?: string | number | React.ReactNode;
  size: number;
  clampText?: boolean;
  isPhoneNumber?: boolean;
  split?: boolean;
  hidden?: boolean;
  link?: string;
};

function chunk<T extends Record<string, any>>(data: T[], attr: keyof T) {
  const splits = {} as any;

  for (let i = 0; i < data.length; i++) {
    const v = data[i][attr];

    if (v) {
      splits[`${i}`] = data[i];
    }
  }

  const newArr = [];
  let start = 0;

  for (const k in splits) {
    const indx = Number(k);
    newArr.push(data.slice(start, indx));
    start = indx;
  }
  if (data.length > start) {
    newArr.push(data.slice(start));
  }

  return newArr;
}

type EditMerchantSectionProps = {
  sectionTitle: string;
  editHandler?: () => void;
  items: TListItem[];
  customHeader?: React.ReactNode;
  containerSx?: SxProps;
  SubTitleSection?: React.ReactNode;
  isAcquirer?: boolean;
};

export const EditMerchantSection = ({
  sectionTitle,
  editHandler,
  items,
  containerSx,
  customHeader,
  SubTitleSection,
  isAcquirer,
}: EditMerchantSectionProps) => {
  const splits = useMemo(
    () =>
      chunk(
        items.filter((x) => (x.value || x.value === 0) && !x?.hidden),
        "split",
      ),
    [items],
  );

  return (
    <MerchantSectionContainer
      sectionTitle={sectionTitle}
      editHandler={editHandler}
      containerSx={containerSx}
      customHeader={customHeader}
      isAcquirer={isAcquirer}
    >
      {SubTitleSection && SubTitleSection}

      {splits.map((x, idx) => (
        <Fragment key={idx}>
          <Grid container rowSpacing="12px" columnSpacing="8xp">
            <Section items={x} />
          </Grid>
          {idx < splits.length - 1 && splits.length > 1 && (
            <Divider sx={{ margin: "16px 0" }} />
          )}
        </Fragment>
      ))}
    </MerchantSectionContainer>
  );
};

function Section({ items }: { items: TListItem[] }) {
  return (
    <Grid container rowSpacing="16px" columnSpacing="8xp">
      {items.map((item) => {
        const { size, title, ...rest } = item;
        return (
          <Grid item xs={12} sm={size} key={title}>
            <KeyValue keyName={title} fullWidth {...rest} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export const CircularButton = styled(Box)(({ theme }) => {
  return {
    display: "none",
    cursor: "pointer",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    padding: "4px",
    borderRadius: "50%",
    "&:hover": {
      opacity: 1,
      backgroundColor: theme.palette.neutral[10],
    },
  };
});
