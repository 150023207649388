import CheckIconColored from "@assets/icons/RebrandedIcons/CheckIconColored";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { showMessage } from "@common/Toast";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Markup } from "interweave";
import useNotificationsActions from "../hooks/useNotificationsActions";
import useNotificationsRedirect from "../hooks/useNotificationsRedirect";
import { ALERT_NAMES_ENUM, TParsedNotification } from "../types";
import { getNotificationDate } from "../utils";
import { NotificationGroupLabel } from "./Body.atoms";
import NotificationImage from "./NotificationImage";
import NiceModal from "@ebay/nice-modal-react";
import { useNavigate } from "react-router";
import { checkPortals } from "@utils/routing";
import { useAppDispatch } from "@redux/hooks";
import { setMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { PROFILE_MODAL } from "modals/modal_names";
import { TProfileTab } from "componentsV2/AccountProfile/AccountProfileModal/types";

type NotificationItemProps = TParsedNotification & {
  isLast: boolean;
  closeModal: VoidFunction;
  renderLabel: boolean;
  accID?: number;
};

const transactionAlertNames = [
  ALERT_NAMES_ENUM.CHARGE_BACK_REVERSAL,
  ALERT_NAMES_ENUM.CHARGEBACK,
  ALERT_NAMES_ENUM.REFUND,
];
const merchantPanelRedirectsNames = [
  ALERT_NAMES_ENUM.MERCHANT_READY_FOR_APPROVAL,
  ALERT_NAMES_ENUM.PROVIDER_READY_FOR_APPROVAL,
  ALERT_NAMES_ENUM.UNDEWRWRITER_ASSIGNMENT,
  ALERT_NAMES_ENUM.NEW_MERCHANT,
  ALERT_NAMES_ENUM.NEW_PROVIDER,
  ALERT_NAMES_ENUM.MERCHANT_UNDERWRITING_APPROVAL,
];
const allowForMobile = [ALERT_NAMES_ENUM.UNDERWRITING_EDD_CHALLENGE];

const NotificationItem = ({
  id,
  title,
  description,
  isMandatory,
  firstCheckedAt,
  type,
  createdAt,
  alertName,
  challengeSlug,
  isLast,
  closeModal,
  label,
  renderLabel,
  transactionID,
  targetMerchantId,
  conversationParams,
  threadId,
  disputeId,
  accID,
}: NotificationItemProps) => {
  const { isMobileView } = useCustomTheme();
  const { markNotificationAsRead } = useNotificationsActions();

  const {
    challengesRedirect,
    merchantPanelRedirect,
    transactionPanelRedirect,
    notificationsRedirects,
    riskMonitorRedirect,
    ofacRedirect,
    merchantBankAccountsRedirect,
    ownerApprovalRedirect,
    midIssueRedirect,
    conversationRedirect,
    openNotificationSidePanel,
  } = useNotificationsRedirect();

  const markAsRead = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) event.stopPropagation();
    if (firstCheckedAt) return;
    markNotificationAsRead(id);
  };

  const triggerAction = (cb: () => void) => {
    closeModal();
    cb();
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAcquirerPortal } = checkPortals();
  const isBulkInvite = alertName === ALERT_NAMES_ENUM.Bulk_merchant_create;
  const notificationRedirects = [
    {
      condition: alertName === ALERT_NAMES_ENUM.Bulk_merchant_create,
      action: () => {
        navigate(`/${isAcquirerPortal ? "acquirer" : "provider"}/merchants`);
        setTimeout(() => {
          dispatch(setMerchantStatusName(StatusNames?.invited));
        }, 0);
      },
    },
    {
      condition: title === "Primary Account Holder Set Password",
      action: () => {
        NiceModal.show(PROFILE_MODAL, {
          initialTab: "Security",
        } as {
          initialTab: TProfileTab;
        });
      },
    },
    {
      condition: title === "Primary Account Holder Verify Email",
      action: () => {
        NiceModal.show(PROFILE_MODAL);
      },
    },
    {
      condition: type === "underwriting" && challengeSlug,
      action: () => challengesRedirect(challengeSlug),
    },
    {
      condition: merchantPanelRedirectsNames.includes(alertName),
      action: () =>
        merchantPanelRedirect({
          title,
          description,
          targetMerchantId,
          alertName,
        }),
    },
    {
      condition: transactionAlertNames.includes(alertName),
      action: () => transactionPanelRedirect(transactionID),
    },
    {
      condition: title.includes("Risk Profile"),
      action: () => riskMonitorRedirect({ description }),
    },
    {
      condition: alertName.includes(ALERT_NAMES_ENUM.MID_ISSUE),
      action: () => midIssueRedirect({ alertName, targetMerchantId }),
    },
    {
      condition: alertName === ALERT_NAMES_ENUM.TRANSFER,
      action: () => transactionPanelRedirect(transactionID),
    },
    {
      condition: alertName === ALERT_NAMES_ENUM.NEW_MEMBER,
      action: () => notificationsRedirects["team_members"](),
    },
    {
      condition: alertName === ALERT_NAMES_ENUM.NEW_BANK,
      action: () => merchantBankAccountsRedirect({ description }),
    },
    {
      condition: alertName === ALERT_NAMES_ENUM.OFAC_MATCH,
      action: () => ofacRedirect(targetMerchantId),
    },
    {
      condition:
        alertName === ALERT_NAMES_ENUM.MERCHANT_OWNER_UNDERWRITING_APPROVAL,
      action: () => ownerApprovalRedirect({ description, targetMerchantId }),
    },
    {
      condition: alertName === ALERT_NAMES_ENUM.NEGATIVE_BALANCE,
      action: () => notificationsRedirects["processing"](),
    },
    {
      condition: alertName === ALERT_NAMES_ENUM.CONVO_MENTION,
      action: () => conversationRedirect(conversationParams),
    },
    {
      condition:
        alertName === ALERT_NAMES_ENUM.NEW_DISPUTE ||
        alertName === ALERT_NAMES_ENUM.DISPUTE_STATS_CHANGED,
      action: () => notificationsRedirects["disputes"](`${disputeId}`),
    },
    {
      condition:
        ALERT_NAMES_ENUM.RISK_MONITORING === alertName ||
        ALERT_NAMES_ENUM.UNDERWRITING_EDD_CHALLENGE === alertName ||
        ALERT_NAMES_ENUM.UNDERWRITING_CHALLHENGE_REJECTION === alertName,
      action: () =>
        openNotificationSidePanel({
          id: conversationParams?.threadId || threadId,
        }),
    },
  ];
  const handleNotificationClick = () => {
    if (isMobileView && !allowForMobile?.includes(alertName)) {
      showSnackbarMessage();
      return;
    }
    markAsRead();

    const clickAction = notificationRedirects.find((item) => item.condition);
    if (clickAction?.action) triggerAction(clickAction.action);
  };

  const parsedDate = getNotificationDate(createdAt);
  const isUnread = firstCheckedAt === null;
  const hideFloatingMarkAsRead = !isUnread || isMobileView;

  return (
    <>
      <HiddenComponent hidden={!label || !renderLabel}>
        <NotificationGroupLabel>{label}</NotificationGroupLabel>
      </HiddenComponent>
      <ItemWrapper
        isHighlighted={isMandatory}
        isLast={isLast}
        onClick={handleNotificationClick}
        data-testid={`notification-wrapper-${id}`}
      >
        <NotificationImage
          variant={type}
          isUnread={isUnread}
          isMandatory={isMandatory}
        />
        <Stack
          direction="column"
          gap="8px"
          alignItems="stretch"
          flexGrow={1}
          sx={{
            "& .description": {
              color: palette.gray[300],
              fontSize: "12px",
              fontWeight: 350,
              lineHeight: "14.4px",
              "& b": {
                color: palette.black[300],
                fontWeight: 350,
                fontSize: "12px",
              },
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={1}
          >
            <CustomText color={palette.black[100]}>{title}</CustomText>
            <CustomText color={palette.gray[200]} sx={{ whiteSpace: "nowrap" }}>
              {parsedDate}
            </CustomText>
          </Stack>
          <Markup
            content={description}
            className="description"
            disableLineBreaks={!isBulkInvite}
          />
        </Stack>
        <HiddenComponent hidden={hideFloatingMarkAsRead}>
          <MarkAsReadButton
            background="tertiary"
            className="mark-as-read"
            onClick={markAsRead}
            data-testid="floating-mark-as-read"
          >
            <CheckIconColored
              width={14}
              height={14}
              fill={palette.black[100]}
            />
            Mark as read
          </MarkAsReadButton>
        </HiddenComponent>
      </ItemWrapper>
    </>
  );
};

export default NotificationItem;

const showSnackbarMessage = () => {
  showMessage(
    "Info",
    "",
    true,
    "Please access this notification from the desktop version for full functionality.",
    2000,
    {
      position: "top-center",
      style: {
        top: "50px",
        width: "calc(100% - 16px)",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    {
      titleClamp: 3,
    },
  );
};

const MarkAsReadButton = styled(Button)(() => ({
  display: "none",
  padding: "2px 8px",
  boxShadow: "0px 4px 8px 0px #00000014",
  borderRadius: "8px",
  alignItems: "center",
  gap: "6px",
  backgroundColor: palette.neutral.white,
  color: palette.black[100],
  fontSize: "10px",
  fontWeight: 350,
  lineHeight: "12px",

  height: "20px",
  position: "absolute",
  top: "12px",
  right: "16px",

  "&:hover": {
    backgroundColor: `${palette.neutral.white} !important`,
    textDecoration: "none !important",
  },
}));

const CustomText = styled(Text)(() => ({
  fontSize: "12px",
  fontWeight: 350,
  lineHeight: "14.4px",
}));

const ItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isHighlighted" && prop !== "isLast",
})<{ isHighlighted: boolean; isLast: boolean }>(
  ({ isHighlighted, isLast }) => ({
    cursor: "pointer",
    padding: "12px 16px",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "8px",
    backgroundColor: isHighlighted ? palette.tag.warning.bg : "inherit",
    position: "relative",
    ...(!isLast && {
      borderBottom: `1px solid ${palette.liftedWhite[100]}`,
    }),
    "&:hover": {
      backgroundColor: isHighlighted
        ? palette.tag.warning.light_hover
        : "inherit",
      "& .mark-as-read": {
        display: "inline-flex",
      },
    },
  }),
);
