import { useBulkMarkMerchants } from "@components/AcquirerMerchants/hooks/useBulkMarkMerchants";
import { Box, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useGetMerchantsTableColumns } from "features/Merchants/MerchantsTable/hooks/useGetMerchantsTableColumns";
import { styled, useAppTheme } from "@theme/v2/Provider";
import NiceModal from "@ebay/nice-modal-react";
import { GIVE_CONFIRMATION_POP_UP } from "modals/modal_names";
import { BellRinging } from "@phosphor-icons/react";
import { useBulkUpdateSponsorStatus } from "@components/AcquirerMerchants/hooks/useBulkUpdateSponsorStatus";
import { memo } from "react";

const TableFooterContainer = ({
  children,
  hasPagination,
}: {
  children: React.ReactNode;
  hasPagination: boolean;
}) => {
  const { selectedMerchantsCount } = useBulkMarkMerchants();
  const { isSponsorBulkApprovalEnabled } = useGetFeatureFlagValues();
  const { bulkUpdateSponsorStatus } = useBulkUpdateSponsorStatus();
  const {
    selectedTabStatus: { isSponsorTabSelected },
  } = useGetMerchantsTableColumns();

  const isShowBulkActions =
    isSponsorTabSelected &&
    !!selectedMerchantsCount &&
    isSponsorBulkApprovalEnabled;

  const handleDecline = () => {
    NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
      modalType: "declined",
      title: "Decline Merchants",
      description: (
        <Stack gap="16px">
          <GiveText variant="bodyS" color="secondary">
            Are you sure you want to decline the selected merchants? This action
            will result in the rejection of the merchants&apos; application.
          </GiveText>
          <WarningComponent />
        </Stack>
      ),
      actions: {
        handleSuccess: { onClick: () => bulkUpdateSponsorStatus("decline") },
      },
    });
  };

  const handleApprove = () => {
    NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
      modalType: "approved",
      title: "Approve Merchants",
      description: (
        <Stack gap="16px">
          <GiveText variant="bodyS" color="secondary">
            Are you sure you want to approve the selected merchants? This action
            will finalize their approval process.
          </GiveText>
          <WarningComponent />
        </Stack>
      ),
      actions: {
        handleSuccess: { onClick: () => bulkUpdateSponsorStatus("approve") },
      },
    });
  };

  if (!hasPagination && !isShowBulkActions) {
    return null;
  }

  return (
    <StyledStack
      direction="row"
      justifyContent={isShowBulkActions ? "space-between" : "center"}
      alignItems="center"
    >
      {isShowBulkActions && (
        <GiveText variant="bodyS" color="secondary">
          {`${selectedMerchantsCount} Merchant${
            selectedMerchantsCount > 1 ? "s" : ""
          } Selected`}
        </GiveText>
      )}
      {hasPagination && children}
      {isShowBulkActions && (
        <Stack direction="row" alignItems="center" gap="8px">
          <GiveButton
            size="large"
            label="Decline"
            variant="ghost"
            color="destructive"
            onClick={handleDecline}
          />
          <GiveButton
            size="large"
            label="Approve"
            variant="filled"
            onClick={handleApprove}
          />
        </Stack>
      )}
    </StyledStack>
  );
};

export default memo(TableFooterContainer);

const StyledStack = styled(Stack)(({ theme }) => {
  return {
    width: "100%",
    position: "absolute",
    bottom: 0,
    backgroundColor: theme.palette.surface?.["primary-transparent"],
    backdropFilter: "blur(8px)",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.border?.primary}`,
    zIndex: 2,
    padding: "0 64px",

    [theme.breakpoints.down("v2_sm")]: {
      padding: "16px 20px",
    },
  };
});

const WarningComponent = () => {
  const { palette } = useAppTheme();
  return (
    <Stack
      direction="row"
      gap="12px"
      alignItems="flex-start"
      sx={{
        backgroundColor: palette.primitive?.transparent["darken-5"],
        padding: "12px",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          width: 24,
          height: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BellRinging size={24} />
      </Box>
      <GiveText variant="bodyS" color="primary" mt="2px">
        You won’t be able to perform another bulk action until it’s complete. We
        will notify you once it’s finished.
      </GiveText>
    </Stack>
  );
};
