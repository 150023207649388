import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import EvidenceProvidedBody from "./EvidenceProvidedBody";
import { TCaseEvidence } from "../../data.types";

type Props = {
  caseAction: string;
  data: TCaseEvidence;
};

const EvidenceProvidedModal = NiceModal.create(
  ({ caseAction, data }: Props) => {
    const modal = useModal();
    const handleCancel = () => modal.hide();

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 618,
          DialogProps: {
            contentContainerSx: {
              overflowY: "auto",
            },
          },
        }}
      >
        <ModalTitle
          title="Evidence Provided"
          padding="24px"
          onClose={handleCancel}
          textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
        />

        <EvidenceProvidedBody data={data} caseAction={caseAction} />

        <ModalActions
          padding="0px 24px 16px"
          primaryAction={{
            label: "Close",
            sx: { fontSize: "18px" },
            onClick: handleCancel,
          }}
          secondaryAction={{
            hidden: true,
          }}
          animationDelay={300}
        />
      </ModalFactory>
    );
  },
);

export default EvidenceProvidedModal;
