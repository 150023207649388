import { Box, Grid, Stack } from "@mui/material";
import { ColorPickerInput } from "@sections/PayBuilder/components/ColorPickerInput";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import PayFormSectionTitle from "features/Minibuilders/PaymentFormMinibuilder/PayFormSectionTitle";
import ADBStylerLink from "./ADBStylerLink";
import { Card } from "./components/Card";
import { List } from "./components/List";
import { LogoUploader } from "./components/LogoUploader";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import { styled } from "@theme/v2/Provider";

export function Styling({
  isEdit,
  isFromADB,
}: {
  isEdit?: boolean;
  isFromADB?: boolean;
}) {
  const { methods } = usePayBuilderForm();
  const { setValue, watch } = methods;
  const { isMobileView, isTabletView } = useCustomThemeV2();

  const { accent, background } = watch().Style;
  const { isFundraiser } = useCheckFormType();

  return (
    <Box>
      <PayFormSectionTitle isEdit={isEdit}>Style your Form</PayFormSectionTitle>
      {isEdit && isFromADB && <ADBStylerLink />}

      <Stack gap="24px" mt="40px">
        {!isFromADB && (
          <Grid container justifyContent="space-between" gap={1}>
            <Stack sx={{ width: "48%" }} gap={1}>
              <GiveText>Background</GiveText>
              <ColorPickerInput
                onSave={(val: string) => {
                  setValue("Style.background", val);
                }}
                defaultColor={background}
              />
            </Stack>
            <Stack sx={{ width: "48%" }} gap={1}>
              <GiveText>Accent</GiveText>
              <ColorPickerInput
                onSave={(val: string) => {
                  setValue("Style.accent", val);
                }}
                defaultColor={accent}
              />
            </Stack>
          </Grid>
        )}

        {!isFundraiser && (
          <Stack gap={1}>
            <GiveText>Item Layout</GiveText>
            <Grid container alignItems="center" justifyContent="space-between">
              <StyledGridItem item>
                <List width={isMobileView || isTabletView ? 160 : undefined} />
                <GiveText color="secondary">List</GiveText>
              </StyledGridItem>
              <StyledGridItem item>
                <Card width={isMobileView || isTabletView ? 140 : undefined} />
                <GiveText color="secondary">Card</GiveText>
              </StyledGridItem>
            </Grid>
          </Stack>
        )}
        <LogoUploader />
        <HFGiveInput
          name="Style.checkoutContent"
          label="Checkout Button Text"
          maxLength={50}
          handleNormalizeInput={(value: string) => {
            return value.replace(/[^a-zA-Z ]/g, "");
          }}
        />
      </Stack>
    </Box>
  );
}

const StyledGridItem = styled(Grid)(() => ({
  width: "48%",
  display: "flex",
  flexDirection: "column",
  gap: 1,
}));
