import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CheckoutButtonWithSpinner = ({
  children,
  isLoading,
}: {
  isLoading: boolean;
  children: React.ReactElement;
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
      {isLoading && (
        <CircularProgress
          style={{
            position: "absolute",
          }}
        />
      )}
    </Box>
  );
};

export default CheckoutButtonWithSpinner;
