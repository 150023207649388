import { customInstance } from "@services/api";
import { useState } from "react";

const getCardNumber = ({
  cardNumber,
  passFees,
}: {
  cardNumber: string;
  passFees: boolean;
}) => {
  return customInstance({
    method: "POST",
    url: "/cart/payment-method",
    data: {
      number: cardNumber.replace(/\s/g, ""),
      passFees,
    },
  });
};

export const useGetCardInfos = (
  { passFees }: { passFees: boolean } = { passFees: false },
) => {
  const [cardObject, setCardObject] = useState<Record<any, any>>();

  const handleCardNumberCheck = async (
    cardNumber: string,
    handleInvalid?: (message: string) => void,
  ) => {
    try {
      const cardObj = await getCardNumber({ cardNumber, passFees });

      setCardObject(cardObj);
      return cardObj;
    } catch (err: any) {
      const message = err.response.data.message || err.message;
      handleInvalid?.(message || "error occurred while validating card number");
    }
  };
  return { handleCardNumberCheck, cardObject };
};
