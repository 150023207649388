import { ArrowForwardIcon } from "@assets/icons";
import EditIconV2 from "@assets/icons/EditIconV2";
import UserIconV3 from "@assets/icons/UserIconV3";
import { Button } from "@common/Button";
import { Text, TruncateText } from "@common/Text";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import CollapsableComponent from "@components/Merchants/CreateMerchantPanel/modals/CollapsableComponents";
import PAHUploaderActions from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderActions";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import PepStatusChip from "@components/Merchants/MerchantPreview/PEP/components/PepStatusChip";
import {
  PEPStatusType,
  TPEPOwner,
} from "@components/Merchants/MerchantPreview/PEP/types";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { useDocumentPreview } from "@hooks/common/documents";
import { downloadDocument } from "@hooks/common/documents/utils";
import { toCountryName } from "@hooks/helpers";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { isEmptyPhone } from "@utils/date.helpers";
import { checkPortals } from "@utils/routing";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { isFunction } from "lodash";
import moment from "moment";
import { memo, useEffect, useMemo } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { ownershipStyle, userStyle } from "./BusinessOwnerItemStyle";
import { TBusinessOwner } from "./types";

const BusinessOwnerItem = ({
  owner,
  selectBusinessOwner,
  onEdit,
  withPepStatus = false,
  disabled = false,
  showViewPep = true,
  idFile,
  merchantId,
  isSelectedPEP = false,
  isEnterprise = false,
  businessType,
}: IOwnerBody) => {
  return (
    <CollapsableComponent
      sx={{
        "& .MuiButtonBase-root": {
          borderRadius: "8px",
          boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
        },
        "& .MuiAccordion-root": {
          borderRadius: "8px",
        },
        "& .MuiAccordionSummary-content": {
          padding: "0px",
        },
        "& .MuiAccordionDetails-root": {
          padding: "0px 16px 12px 16px",
        },
        "& .MuiStack-root": {
          margin: "0px",
        },
      }}
      title={
        <OwnerHeader
          firstName={owner.firstName}
          lastName={owner.lastName}
          ownership={owner.ownership}
          pepStatusName={owner?.pepStatusName}
          withPepStatus={withPepStatus}
          pepStatusSetByUnderwriter={owner.pepStatusSetByUnderwriter}
          manualPepStatus={owner?.manualPepStatus}
          businessType={businessType}
        />
      }
      withExpandIcon={false}
    >
      <OwnerBody
        owner={owner}
        onEdit={onEdit}
        selectBusinessOwner={selectBusinessOwner}
        withPepStatus={withPepStatus}
        disabled={disabled}
        showViewPep={showViewPep}
        idFile={idFile}
        merchantId={merchantId}
        isSelectedPEP={isSelectedPEP}
        isEnterprise={isEnterprise}
        businessType={businessType}
      />
    </CollapsableComponent>
  );
};

type TOwnerHeader = {
  firstName?: string;
  lastName?: string;
  ownership?: string;
  manualPepStatus?: PEPStatusType;
  withPepStatus: boolean;
  pepStatusName: PEPStatusType;
  pepStatusSetByUnderwriter?: boolean;
  businessType: string;
};

const OwnerHeader = ({
  firstName,
  lastName,
  ownership,
  withPepStatus,
  pepStatusName,
  pepStatusSetByUnderwriter,
  manualPepStatus,
  businessType,
}: TOwnerHeader) => {
  const { isDesktopView } = useCustomTheme();
  const { merchant_underwriting } = useEnterprisePermissions();
  const ownershipStr = ownership || "0";
  const ownerShipPercentage =
    businessType === "tax_exempt_organization" ||
    businessType === "government_agency"
      ? 0
      : parseFloat(parseFloat(ownershipStr).toFixed(2));

  const { isMerchantPortal } = checkPortals();

  const showStatus =
    withPepStatus &&
    isDesktopView &&
    merchant_underwriting &&
    !isMerchantPortal;

  return (
    <Stack
      direction="row"
      flexGrow={1}
      justifyContent="space-between"
      width="100%"
      gap={2}
    >
      <Stack direction="row" gap="8px" alignItems="center" overflow="hidden">
        <UserIconV3 height={20} width={20} color={palette.neutral[70]} />
        <TruncateText
          lineClamp={1}
          sx={userStyle}
        >{`${firstName} ${lastName}`}</TruncateText>
        <Text sx={{ ...ownershipStyle, fontSize: "8px" }}>•</Text>
        <Text sx={ownershipStyle}>Ownership&nbsp;{ownerShipPercentage}%</Text>
      </Stack>
      {showStatus && (
        <PepStatusChip
          ownerPepStatus={pepStatusName}
          manualPepStatus={manualPepStatus}
          pepStatusSetByUnderwriter={pepStatusSetByUnderwriter}
        />
      )}
    </Stack>
  );
};

type DataItem = { label: string; description: string; hidden?: boolean };
interface IOwnerBody {
  owner: TBusinessOwner;
  onEdit?: (event: React.MouseEvent<HTMLElement>) => void;
  selectBusinessOwner?: (data: TPEPOwner) => void;
  withPepStatus?: boolean;
  disabled?: boolean;
  showViewPep?: boolean;
  idFile?: TMerchantDocument | IFileWithMeta;
  merchantId?: number;
  isSelectedPEP?: boolean;
  isEnterprise?: boolean;
  businessType: string;
}

const OwnerBody = ({
  owner,
  onEdit,
  disabled,
  selectBusinessOwner,
  withPepStatus = false,
  showViewPep = true,
  idFile,
  merchantId,
  isSelectedPEP = false,
  isEnterprise = false,
  businessType,
}: IOwnerBody) => {
  const { isDesktopView } = useCustomTheme();
  const { merchant_underwriting } = useEnterprisePermissions();
  const isLocalFile = idFile && "meta" in idFile;
  const idUrl = useMemo(
    () => (isLocalFile ? idFile.meta?.previewUrl : idFile?.fileURL),
    [isLocalFile, idFile],
  );
  const idName = isLocalFile ? idFile.meta?.name : idFile?.fileName;
  const idFileType = isLocalFile
    ? idFile?.meta?.type.split("/")[1]
    : idFile?.fileType;
  const idFileId = isLocalFile ? idFile?.meta?.id : idFile?.id;
  const isConfirmMatch = owner?.pepStatusName === "confirmed_match";

  const downloadID = async () => {
    if (!idFile) return;
    downloadDocument(
      {
        fileName: idName || "undefined",
        fileURL: idUrl || "",
      },
      isDesktopView,
    );
  };

  const { handlePreview } = useDocumentPreview({
    merchantID: merchantId as number,
    handlers: {
      handleDownload: downloadID,
    },
  });

  const previewDocument = () => {
    // if no file url, skip preview
    if (!idUrl) return;
    const fileToPreview = {
      fileURL: idUrl,
      fileName: idName,
      fileType: idFileType,
      id: idFileId,
      tag: "legal_principal",
    } as TMerchantDocument;

    handlePreview(fileToPreview, [fileToPreview]);
  };
  const isSSN = owner?.ssn;
  const ownership =
    businessType === "tax_exempt_organization" ||
    businessType === "government_agency"
      ? 0
      : !isNaN(parseFloat(owner.ownership))
      ? parseFloat(owner.ownership) % 1 === 0
        ? parseInt(owner.ownership)
        : parseFloat(owner.ownership).toFixed(2)
      : owner.ownership;

  const { countryOfResidence, citizenship } = owner;
  let nraFields;
  if (citizenship && countryOfResidence === citizenship) {
    nraFields = [
      {
        label: "Country of Citizenship and Residence",
        description: toCountryName(citizenship),
      },
    ];
  } else {
    nraFields = [
      {
        label: "Country of Citizenship",
        description: toCountryName(citizenship) || "",
        hidden: !citizenship,
      },
      {
        label: "Country of Residence",
        description: toCountryName(countryOfResidence) || "",
        hidden: !countryOfResidence,
      },
    ];
  }

  const data: DataItem[] = [
    {
      label: "Full name",
      description: `${owner.firstName} ${owner.lastName}`,
    },
    {
      label: "Email",
      description: owner.email,
    },
    {
      label: "Ownership",
      description: `${ownership ?? 0}%`,
    },
    {
      label: isSSN ? "SSN" : "EIN",
      description: isSSN
        ? formatSSN("ssn", owner?.ssn?.slice(-4) || "")
        : formatSSN("ein", owner?.ein?.slice(-4) || ""),
    },
    {
      label: "Date of Birth",
      description: owner.dob ? moment(owner.dob).format("MMM DD yyyy") : "",
    },
    {
      label: "Business Owner Phone",
      description: isEmptyPhone(owner?.phone) ? "" : owner?.phone,
    },
    ...nraFields,
  ];

  const permissionResource = composePermission(
    isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.LEGAL_ENTITY,
    RESOURCE_BASE.PRINCIPAL,
    RESOURCE_BASE.PEP,
  );

  const isPEPAllowed = useAccessControl({
    resource: permissionResource,
    operation: OPERATIONS.LIST,
  });

  const showPEP =
    showViewPep &&
    withPepStatus &&
    isDesktopView &&
    merchant_underwriting &&
    isPEPAllowed;

  const canDispatchOwner = owner?.id && isFunction(selectBusinessOwner);

  const name = `${owner?.firstName} ${owner?.lastName}`;
  const businessOwnerPEP = {
    id: owner?.id ?? "",
    name: name,
    pepStatusName: owner?.pepStatusName,
    createdAt: owner?.createdAt,
  };

  const handleViewPEP = () => {
    if (showPEP && canDispatchOwner) {
      selectBusinessOwner(businessOwnerPEP);
    }
  };

  useEffect(() => {
    if (isSelectedPEP) handleViewPEP();
  }, [name]);

  return (
    <Stack spacing={0.5} width={"100%"}>
      {data
        .filter((entry) => !entry.hidden)
        .map(({ label, description }, index) => (
          <DetailsItem
            key={label}
            label={label}
            description={description}
            highlight={index % 2 !== 0}
          />
        ))}
      {idFile && (
        <ImageWrapper data-testid="id-file-preview" fileURL={idUrl || ""}>
          <PAHUploaderActions
            type={"businessOwnerID"}
            downloadHandler={downloadID}
            previewDocument={previewDocument}
            disabled={{
              delete: isConfirmMatch,
            }}
          />
        </ImageWrapper>
      )}
      <Stack direction="row" justifyContent={"center"}>
        {!disabled && (
          <Button
            sx={{ mt: "16px", py: "0px" }}
            background="tertiary"
            size="small"
            endIcon={<EditIconV2 stroke={palette.gray[300]} />}
            onClick={onEdit}
            data-testid="edit-bo-button"
          >
            <Text
              color={palette.gray[300]}
              fontWeight="regular"
              fontSize="14px"
            >
              Edit
            </Text>
          </Button>
        )}
        {showPEP && (
          <Button
            sx={{ mt: "16px", py: "0px" }}
            background="tertiary"
            size="small"
            endIcon={<ArrowForwardIcon stroke={palette.gray[300]} />}
            onClick={handleViewPEP}
          >
            <Text
              color={palette.gray[300]}
              fontWeight="regular"
              fontSize="14px"
            >
              View PEP
            </Text>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

type DetailsItemProps = {
  label: string;
  description: string;
  highlight?: boolean;
  percent?: boolean;
};

const DetailsItem = ({ label, description, highlight }: DetailsItemProps) => (
  <Box
    display="flex"
    sx={{
      borderRadius: "4px",
      padding: "4px 8px",
      background: highlight ? "inherit" : palette.background.dimmedWhite,
    }}
  >
    <Text
      fontSize="14px"
      fontWeight="book"
      color={palette.neutral[70]}
      sx={{ flex: 1 }}
    >
      {label}
    </Text>
    <TruncateText
      lineClamp={1}
      fontWeight="book"
      color={palette.neutral[80]}
      sx={{ flex: 1 }}
      fontSize="14px"
    >
      {description}
    </TruncateText>
  </Box>
);

export default memo(BusinessOwnerItem);

const formatSSN = (type: "ssn" | "ein", value: string) => {
  const prefix = type === "ssn" ? "XXX-XX-" : "XX-XXX";
  return prefix + value.toLowerCase().replaceAll("x", "");
};

const ImageWrapper = styled(Box)(
  ({ fileURL, disabled }: { fileURL: string; disabled?: boolean }) => {
    return {
      backgroundImage: `url(${fileURL})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "118px",
      borderRadius: "8px",
      position: "relative",
      background: `linear-gradient(180deg, rgba(12, 12, 12, 0.24) 0%, rgba(12, 12, 12, 0.82) 70%), url(${fileURL}) lightgray 50% / cover no-repeat`,
      transition: "background-color 0.5s ease",
      ".pah-uploader-actions": {
        opacity: 1,
      },
      ...(disabled && { filter: "grayscale(100%)" }),
    };
  },
);
