import { TransactionInfo } from "@components/ManageMoney/TransactionTable/TransactionInfoModal";
import { CreateSweepstakes } from "@components/Sweepstakes/MiniBuilder/CreateSweepstakesModal";
import { SidePanel } from "@containers/SidePanel";
import NiceModal from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useGetBuilderData } from "@pages/NewAdvancedBuilder/api/builderApi";
import { build } from "@pages/NewAdvancedBuilder/hooks/useGetBuilder";
import { structuredTreeSignal } from "@pages/NewAdvancedBuilder/signals";
import { palette } from "@palette";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { EditEvent } from "features/Minibuilders/EventsMinibuilder/EditEventModal";
import { EditFundraiser } from "features/Minibuilders/FundraisersMinibuilder/EditFundraiserModal";
import { EditInvoice } from "features/Minibuilders/InvoicesMinibuilder/EditInvoiceMinibuilder";
import { EditMembership } from "features/Minibuilders/MembershipsMinibuilder/EditMembershipsModal";
import NewEditPaymentFormModal from "features/Minibuilders/PaymentFormMinibuilder/NewEditPaymentFormModal";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Body from "./Body";
import Header from "./Header";
import LoadingPanelState from "./LoadingPanelState";
import useCampaignModal, { CAMPAIGN_PANEL_WIDTH } from "./useCampaignModal";

type Props = {
  id: number;
  name?: string;
  isFirst: boolean;
  isLast: boolean;
  setSelectedRow: (newIdx: string | number) => void;
  shouldOpenSecondPanelOnOpening?: boolean;
  width?: string;
  isSetDouble?: boolean;
};

const secondPanel = {
  fundraiser: NewEditPaymentFormModal,
  event: NewEditPaymentFormModal,
  membership: EditMembership,
  invoice: EditInvoice,
  sweepstake: CreateSweepstakes,
  "payment-form": NewEditPaymentFormModal,
  standard: NewEditPaymentFormModal,
};

const CampaignDetailsModal = NiceModal.create(
  ({
    id,
    isFirst,
    isLast,
    setSelectedRow,
    name,
    shouldOpenSecondPanelOnOpening,
    isSetDouble = false,
  }: Props) => {
    const {
      handleClose,
      isLoading,
      isFetching,
      modal,
      actions,
      handleNext,
      handlePrevious,
      handlePreview,
      handleExpand,
      doublePanel,
      setDoublePanel,
      campaign,
      imgUrl,
      handleEdit,
      handleShare,
      BodyData,
      transactionLoading,
      chooseTransaction,
      transactionData,
      selectedTransaction,
      hasEnded,
    } = useCampaignModal({
      id,
      name,
      setSelectedRow,
      isSetDouble,
      isFirst,
      isLast,
    });
    const { isMobileView } = useCustomThemeV2();

    const { merchantId } = useGetCurrentMerchantId();

    const isNewPayBuilder = ["standard", "event", "fundraiser"].includes(
      BodyData.typeName,
    );

    const { data: builderData, isLoading: isBuilderDataLoading } =
      useGetBuilderData(merchantId, id as any, {
        enabled: isNewPayBuilder,
        onError: () => {
          structuredTreeSignal.value = null;
        },
        onSuccess: (data: any) => {
          if (data?.snapshot) {
            build(
              // old snapshots may still use "HTML" in uppercase
              data.snapshot?.html ?? data.snapshot?.HTML,
              BodyData.name,
              BodyData.description,
            );
          }
        },
      });

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const isResponsivePanel = useMediaQuery(
      theme.breakpoints.up(CAMPAIGN_PANEL_WIDTH * 2),
    );

    const editProductProps = {
      id: id.toString(),
      noModal: true,
      handleClose: (closeDouble = true) =>
        closeDouble ? setDoublePanel(false) : modal.hide(),
      ...(isNewPayBuilder && {
        isFromADB: builderData != null,
      }),
    };

    const transactionPanelProps = {
      noModal: true,
      data: transactionData,
      ...(!isResponsivePanel && {
        handleClose: () => setDoublePanel(false),
      }),
    };

    const handleAdvancedBuilderRedirection = () => {
      handleClose();
      navigate("/merchant/new-advanced-builder", {
        state: {
          ...BodyData,
          productId: id,
          productTitle: BodyData?.name,
          headerImageUrl: BodyData?.usesBackgroundImage && imgUrl,
          description: BodyData?.description,
          imageURL: BodyData?.imageURL,
          campaign: BodyData?.typeName,
          position: BodyData?.position,
          paymentLogoURL: BodyData?.paymentLogoURL,
        },
      });
    };
    useEffect(() => {
      if (shouldOpenSecondPanelOnOpening) handleEdit();
    }, [shouldOpenSecondPanelOnOpening]);

    const Campaign = secondPanel[campaign];

    const isEditNewCampaign = !doublePanel
      ? true
      : campaign !== "payment-form" &&
        campaign !== "standard" &&
        campaign !== "fundraiser" &&
        campaign !== "event";

    return (
      <SidePanel
        width={
          isMobileView
            ? "100%"
            : isResponsivePanel && doublePanel && isEditNewCampaign
            ? CAMPAIGN_PANEL_WIDTH * 2
            : CAMPAIGN_PANEL_WIDTH
        }
        modal={modal}
        onCloseDrawer={handleClose}
      >
        <Box sx={panelStyle}>
          {isEditNewCampaign && (
            <Box
              width={isMobileView ? "100%" : CAMPAIGN_PANEL_WIDTH}
              height="100vh"
              sx={{
                display:
                  !isResponsivePanel && doublePanel && !isEditNewCampaign
                    ? "none"
                    : "block",
              }}
            >
              {isLoading || isBuilderDataLoading ? (
                <LoadingPanelState />
              ) : (
                <Stack
                  className={classes.container}
                  sx={{
                    overflow: "auto",
                    position: "relative",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Header
                    imageURL={imgUrl}
                    actions={actions}
                    nextPrevState={{ isFirst, isLast }}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    onCloseDrawer={handleClose}
                    handlePreview={handlePreview}
                    handleExpand={handleExpand}
                  />
                  <Body
                    BodyData={BodyData}
                    handleShare={handleShare}
                    handleEdit={handleEdit}
                    handleExpand={handleExpand}
                    selectedTransaction={selectedTransaction}
                    isloading={transactionLoading}
                    isEditFormStyleDisabled={isFetching}
                    chooseTransaction={chooseTransaction}
                    hasEnded={hasEnded}
                    handleEditFromStyle={handleAdvancedBuilderRedirection}
                    isEvent={campaign === "event"}
                  />
                </Stack>
              )}
            </Box>
          )}
          {doublePanel && (
            <Box
              sx={
                campaign === "payment-form" || isEditNewCampaign
                  ? {
                      height: "100dvh",
                    }
                  : secondPanelStyle
              }
              width={isMobileView ? "100%" : CAMPAIGN_PANEL_WIDTH}
            >
              {transactionData ? (
                <TransactionInfo {...transactionPanelProps} />
              ) : (
                Campaign && <Campaign {...(editProductProps as any)} />
              )}
            </Box>
          )}
        </Box>
      </SidePanel>
    );
  },
);

const panelStyle = {
  background: palette.neutral[5],
  display: "flex",
  width: "100%",
};

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    alignItems: "baseline",
    overflow: "scroll",
  },
}));

const secondPanelStyle = {
  background: "rgba(251, 251, 251, 0.75)",
  boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(50px)",
  margin: "16px 8px 16px 0px",
  "@media(max-width: 1200px)": {
    margin: "16px 8px 16px 8px",
  },
  borderRadius: "16px",
  perspective: "1px",
  overflow: "hidden",
  height: "96vh",
};

export default CampaignDetailsModal;
