import { isMobile } from "@utils/index";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import useInputFieldFocusListener from "@common/FilePreview/hooks/useInputFieldFocusListener";
import { PageInputProps, TStyle } from "./types";
import { Box } from "@mui/material";
import { CustomIntegerInput } from "@common/CustomIntegerInput";
import { styled } from "@mui/material";
import { palette } from "@palette";

const PageInput = ({
  page,
  onChange,
  numPages,
  style,
  onFocus,
}: PageInputProps) => {
  const [value, setValue] = useState<string>("1");
  const inputRef = useRef<HTMLInputElement>(null);

  useInputFieldFocusListener({ inputRef, onFocus });

  useEffect(() => {
    setValue(page.toString());
  }, [page]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (isMobile && !!event.target.value)
      handleSubmit(event, event.target.value);
  };

  const handleSubmit = (
    event: React.FormEvent<HTMLDivElement>,
    mobileValue?: string,
  ) => {
    event.preventDefault();
    const sanitizedInput = parseInt(mobileValue || value);
    if (inputRef.current && !isMobile) {
      inputRef.current.blur();
    }
    if (Number.isNaN(sanitizedInput)) {
      onChange(1);
    } else if (sanitizedInput <= numPages) {
      onChange(sanitizedInput);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <StyledInput
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        min={1}
        max={numPages}
        data-testid="pagination-input"
        disabled={numPages === 1}
        style={style}
        inputProps={{
          inputMode: "numeric",
        }}
        containerProps={{
          sx: {
            width: "30px",
          },
        }}
      />
    </Box>
  );
};

const StyledInput = styled(CustomIntegerInput, {
  shouldForwardProp: (prop) => prop !== "style",
})<{ style: TStyle }>(({ theme, style: { color, ...rest } }) => ({
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    padding: "2px 6px",
    border: "none",
    backgroundColor: "inherit",
    borderRadius: "2px",

    "& input": {
      marginTop: 0,
      color: color["primary"],
      ...rest,
    },
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused": {
    backgroundColor: palette.liftedWhite.main,
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: palette.gray[300],
    },
  },
}));

export default PageInput;
