import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { BankAccountTagType } from "@common/Tag/BankAccountTag";
import { IDropzoneProps, StatusValue } from "react-dropzone-uploader";
import { useModal } from "@ebay/nice-modal-react";
import { findBankByRoutingNumber } from "@utils/bank_list";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { IMerchantBankAccount, TMerchantDocument } from "../data.types";
import { showMessage } from "@common/Toast";
import { uniqueId } from "lodash";
import { format } from "date-fns";
import { renameBEBankAccountStatus } from "../helpers/bankAccounts";
import { formatOwnersDateUTC } from "./useCreateMerchantBusinessOwner";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import {
  useUploadFiles,
  useUploadPresignedDocument,
} from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { BANK_ACCOUNT_NUMBER_REGEX } from "@constants/constants";
import { SchemaLike } from "yup/lib/types";
import { useUpdateBankAccountSettings } from "./useMerchantBankAccounts";
import { getValidBankAccountNumber } from "@utils/bankAccountUtils";

export type TLoadedDocument =
  | TMerchantDocument
  | {
      updatedAt: string;
      fileName: string;
      fileURL: any;
      id: string;
    };

type FormInputsNew = Omit<IMerchantBankAccount, "files"> & {
  files: {
    allFiles: {
      file: File;
      id: number;
    }[];
  };
  uploadedBankFiles: TLoadedDocument[];
  isLinkedBankAccount: boolean;
  linkedBankAccountId: number;
  linkedBankAccount?: IMerchantBankAccount;
};
type FormInputsOld = Omit<IMerchantBankAccount, "files"> & {
  files: {
    fileWithMeta: any | null;
    status: StatusValue;
    allFiles: any[];
  };
  uploadedBankFiles: TLoadedDocument[];
  isLinkedBankAccount: boolean;
  linkedBankAccountId: number;
  linkedBankAccount?: IMerchantBankAccount;
};
type Props = {
  merchantId: number;
  bankAccountId?: number;
  data: IMerchantBankAccount;
  onClose?: (data: IMerchantBankAccount, id?: number) => void;
  isDefault?: boolean;
  isLinkedBA?: boolean;
};

const useCreateMerchantBankAccount = ({
  merchantId,
  bankAccountId,
  data: bankAccount,
  onClose,
  isDefault,
  isLinkedBA,
}: Props) => {
  const modal = useModal();
  const open = modal.visible;
  const queryClient = useQueryClient();
  const {
    populateSnackbarFiles,
    isUploadAllowed,
    isLoading: uploadLoading,
    failedFiles,
  } = useFileUploadContext();

  //instead of using slug for BA file upload we need to use challenge id
  const { data: challengeData } = useGetChallengeId(merchantId);

  const { isMobileView } = useCustomTheme();

  const [uploadList, setUploadList] = useState<TLoadedDocument[]>([]);
  const [loadingFile, setLoadingFile] = useState("");
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUpload, isLoading: loadingDocument } = useUploadFiles();
  const isForceError = useRef<boolean>(false);

  const getRequiredField = (field: SchemaLike) => {
    return Yup.string().when(["isLinkBankAccount", "linkedBankAccountId"], {
      is: (isLinkBankAccount: boolean, linkedBankAccountId: number) =>
        !isLinkBankAccount && !linkedBankAccountId,
      then: field,
    });
  };

  const schema = Yup.object().shape({
    isLinkBankAccount: Yup.boolean(),
    linkedBankAccountId: Yup.number().when("isLinkBankAccount", {
      is: true,
      then: Yup.number().required("This field is required"),
    }),
    name: getRequiredField(
      Yup.string().trim().required("This field is required"),
    ),
    accountNumber: getRequiredField(
      Yup.string()
        .matches(
          BANK_ACCOUNT_NUMBER_REGEX,
          "Please enter a valid account number",
        )
        .when("name", {
          is: () => Boolean(bankAccountId),
          then: Yup.string(),
          otherwise: Yup.string().required("This field is required"),
        }),
    ),
    routingNumber: getRequiredField(
      Yup.string()
        .required("This field is required")
        .matches(/^[0-9]{9}$/, "Please enter a valid routing number"),
    ),
  });

  const defaultValues = {
    isLinkedBankAccount: false,
    linkedBankAccountId: 0,
    bankName: "",
    status: "pending" as BankAccountTagType,
    type: "checking",
    name: "",
    routingNumber: "",
    accountNumber: "",
  };

  const methods = useForm<FormInputsNew | FormInputsOld>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset, watch, setValue, setError } = methods;
  const values = watch();

  const {
    handleUpload: handleUploadPresignedDocument,
    isLoading: loadingPresignedDocument,
  } = useUploadPresignedDocument();
  const hideModal = () => {
    queryClient.removeQueries("get-bank-files");
    modal.remove();
  };

  const handleCancel = () => {
    reset();
    hideModal();
  };

  useEffect(() => {
    if (!bankAccountId) {
      reset(defaultValues);
      return;
    }

    reset({
      isLinkedBankAccount: isLinkedBA,
      linkedBankAccountId: isLinkedBA ? bankAccountId : 0,
      bankName: bankAccount?.bankName || "",
      status: bankAccount?.status || ("pending" as BankAccountTagType),
      type: bankAccount?.type?.toLocaleLowerCase() || "checking",
      name: bankAccount?.name || "",
      routingNumber: bankAccount?.routingNumber || "",
      accountNumber: bankAccount?.accountNumber || "",
      notes: bankAccount?.notes || "",
      uploadedBankFiles: bankAccount?.uploadedBankFiles || [],
      statements: bankAccount?.statements || [],
      files: {
        allFiles: [],
      },
    });

    setUploadList(bankAccount?.uploadedBankFiles || []);
  }, [bankAccountId]);

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    fileWithMeta,
    status,
  ) => {
    //TODO: we should keep only one file list state and maintain it ,here we have 3 (internal library state, react-hook-form state, native react state)
    //this is a very bad approach to handle it like that ,we need to refactore it in the future
    (async () => {
      if (status === "rejected_file_type") {
        failedFiles.current.push(fileWithMeta);
      }

      if (status !== "rejected_file_type") {
        setLoadingFile(fileWithMeta.file.name);
      }
      const areAllFilesProcessed =
        fileWithMeta.meta.status === "done" ||
        fileWithMeta.meta.status === "error_file_size";

      if (areAllFilesProcessed) {
        setValue(
          "files",
          {
            fileWithMeta,
            status,
            allFiles: [fileWithMeta, ...(values?.files?.allFiles || [])],
          } as any,
          { shouldDirty: true },
        );
        setUploadList((prev) => [
          {
            updatedAt: formatOwnersDateUTC(new Date(), isMobileView),
            fileName: fileWithMeta.file.name,
            fileURL: URL.createObjectURL(fileWithMeta.file),
            id: fileWithMeta.meta.id,
          },
          ...prev,
        ]);
      }
      setLoadingFile("");
    })();
  };
  const attemptUpload = (file: File) => {
    const id = Math.random().toString(36).substr(2, 9);
    setValue(
      "files",
      {
        allFiles: [
          ...(values?.files?.allFiles || []),
          {
            file,
            id,
          },
        ],
      } as any,
      { shouldDirty: true },
    );
    setUploadList((prev) => [
      {
        updatedAt: formatOwnersDateUTC(new Date(), isMobileView),
        fileName: file.name,
        fileURL: URL.createObjectURL(file),
        id: id,
      },
      ...prev,
    ]);
  };

  const deleteDocumentOld = async (file: TMerchantDocument) => {
    //TODO: we should keep only one file list state and maintain it ,here we have 3 (internal library state, react-hook-form state, native react state)
    //this is a very bad approach to handle it like that ,we need to refactore it in the future
    // like here we are updating 3 states
    await values?.files?.allFiles
      ?.find((f) => {
        return f.meta.id === file.id;
      })
      ?.remove();

    setValue("files", {
      ...values.files,
      allFiles: values.files?.allFiles?.filter((f) => f.meta.id !== file.id),
    });

    setValue(
      "statements",
      values?.statements?.filter(
        (statement) => !statement?.includes(file?.fileURL),
      ) || [],
    );

    setUploadList(uploadList.filter((doc) => doc.id !== file.id));
  };

  const deleteDocumentNew = async (file: TMerchantDocument) => {
    setValue("files", {
      ...values.files,
      allFiles: values.files?.allFiles?.filter((f) => f.id !== file.id),
    });

    setValue(
      "statements",
      values?.statements?.filter(
        (statement) => !statement?.includes(file?.fileURL),
      ) || [],
    );

    setUploadList(uploadList.filter((doc) => doc.id !== file.id));
  };

  useEffect(() => {
    if (modal.visible) {
      setLoadingFile("");
    }
  }, [bankAccount?.id, modal.visible]);

  const createBankAccountMutation = useMutation((data: any) => {
    return customInstance({
      url: bankAccountId
        ? `/merchants/${merchantId}/bank-accounts/${bankAccountId}`
        : `/merchants/${merchantId}/bank-accounts`,
      method: bankAccountId ? "PATCH" : "POST",
      data,
    });
  });

  const handleBankDocumentsUploadNew = async (
    data: FormInputsNew | FormInputsOld,
  ) => {
    let status: BankAccountTagType = bankAccount?.status || "upload";
    let uploadedFiles;
    // for create bank account
    if (
      (!bankAccountId || !merchantId) &&
      data?.files &&
      data?.files?.allFiles?.length !== 0
    ) {
      uploadedFiles = await handleUploadPresignedDocument({
        list: data.files?.allFiles,
      });

      status = "pending";
      if (uploadedFiles === "upload_failed") return;
    }
    // for edit bank account
    if (
      bankAccountId &&
      merchantId &&
      data?.files &&
      data?.files?.allFiles?.length !== 0
    ) {
      await handleUpload(
        {
          list: data.files?.allFiles,
          attachmentType: "bank_account",
          label: "bank account document",
          tag: "bank account document",
          merchantId: merchantId,
          resourceID: bankAccountId,
        },
        undefined,
        challengeData?.[0]?.id,
      );
      status = "pending";
    }
    return { status, uploadedFiles };
  };

  const handleBankDocumentsUploadOld = async (
    data: FormInputsNew | FormInputsOld,
  ) => {
    let status: BankAccountTagType = bankAccount?.status || "upload";
    let uploadedFiles;
    // for create bank account
    if (
      (!bankAccountId || !merchantId) &&
      data?.files &&
      data?.files?.allFiles?.length !== 0
    ) {
      uploadedFiles = await populateSnackbarFiles({
        fileWithMeta:
          (data as FormInputsOld).files?.fileWithMeta ||
          data.files?.allFiles[0],
        status: (data as FormInputsOld).files?.status,
        allFiles: (data as FormInputsOld).files?.allFiles,
      });
      status = "pending";
      if (uploadedFiles === "upload_failed") return;
    }
    // for edit bank account
    if (
      bankAccountId &&
      merchantId &&
      data?.files &&
      data?.files?.allFiles?.length !== 0
    ) {
      const editUploadRes = await populateSnackbarFiles(
        {
          fileWithMeta:
            (data as FormInputsOld).files?.fileWithMeta ||
            data.files?.allFiles[0],
          status: (data as FormInputsOld).files?.status,
          allFiles: (data as FormInputsOld).files?.allFiles,
          attachmentType: "bank_account",
          label: "bank account document",
          tag: "bank account document",
          merchantId: merchantId,
          resourceID: bankAccountId,
          onSuccess: () => {
            data?.files?.allFiles.forEach((file) => file.remove());
          },
        },
        undefined,
        challengeData?.[0]?.id,
      );
      if (editUploadRes === "upload_failed") return;
      status = "pending";
    }
    return { status, uploadedFiles };
  };

  const handleBankDocumentsUpload = (data: FormInputsNew | FormInputsOld) =>
    isFileUploadRefactorEnabled
      ? handleBankDocumentsUploadNew(data)
      : handleBankDocumentsUploadOld(data);

  const {
    handleUpdateBankAccountSettings,
    isLoading: loadingUpdateBankAccountSettings,
  } = useUpdateBankAccountSettings(merchantId);

  const onSubmit: SubmitHandler<FormInputsNew | FormInputsOld> = async (
    data,
  ) => {
    if (data?.isLinkedBankAccount && data?.linkedBankAccountId) {
      // create merchant / linked bank account
      if (onClose) {
        // to populate bank accounts list with the relevant data
        if (data?.linkedBankAccount)
          onClose({ ...data?.linkedBankAccount, isLinked: true });

        hideModal();
      } else {
        // edit merchant / linked bank account
        const linkedData = {
          linkedAccountID: data?.linkedBankAccountId,
        };

        handleUpdateBankAccountSettings(linkedData, () => {
          hideModal();
        });
      }

      return;
    }

    const { status, uploadedFiles } =
      (await handleBankDocumentsUpload(data)) ?? {};

    if (!status) return;

    if (onClose) {
      const accountData = {
        ...data,
        status,
        created: data?.created || format(new Date(), "MM / dd / yyyy"),
        statements: [
          ...(values?.statements || []),
          ...(uploadedFiles || []),
        ] as string[],
        id: bankAccount?.id || +uniqueId(),
        isDefault: isDefault || false,
        uploadedBankFiles: uploadList,
        files: [],
      };

      onClose(accountData);
      reset(bankAccount?.id ? accountData : defaultValues);
      hideModal();
      return;
    }

    const customData = {
      routingNumber: data.routingNumber,
      notes: data.notes,
      ...(status !== "upload" && {
        status: renameBEBankAccountStatus(status),
      }),
      name: data.name,
      type: data.type,
      statements: uploadedFiles,
      ...(!bankAccount?.id && {
        isDefault: isDefault || false,
      }),
      ...getValidBankAccountNumber({
        isForceError,
        setError,
        accountNumber: data?.accountNumber,
        defaultAccountNumber: bankAccount?.accountNumber,
      }),
      bankName: findBankByRoutingNumber(data.routingNumber),
    };

    !isForceError.current &&
      createBankAccountMutation.mutate(
        bankAccountId ? customData : { params: { ...customData } },
        {
          onSuccess: () => {
            queryClient.refetchQueries(["get-merchant-preview", merchantId]);
            queryClient.invalidateQueries("get-merchant-msp-status");
            queryClient.invalidateQueries("read-challenge-files");
            hideModal();
            if (bankAccountId) {
              reset(data);
            } else {
              reset();
            }
          },
          onError: (err: any) => {
            showMessage("Error", err?.response?.data?.message);
          },
        },
      );
  };

  return {
    open,
    methods,
    handleCancel,
    onSubmit,
    handleChangeStatus,
    attemptUpload,
    isLoading:
      createBankAccountMutation.isLoading ||
      uploadLoading ||
      loadingUpdateBankAccountSettings ||
      loadingDocument ||
      loadingPresignedDocument,
    uploadList,
    loadingFile,
    deleteDocumentNew,
    deleteDocumentOld,
    isUploadAllowed,
  };
};

export default useCreateMerchantBankAccount;

const useGetChallengeId = (merchantID: number) => {
  const { data, ...rest } = useQuery(
    ["challenge_by_slug", merchantID],
    async () => {
      return await customInstance({
        url: `/merchants/${merchantID}/underwriting-challenges?filter=%3B(slug:%22bank_account2%22)`,
        method: "GET",
      });
    },
    { enabled: !!merchantID },
  );
  return { data: data?.data, ...rest };
};
