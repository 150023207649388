import { SidePanel } from "@containers/SidePanel";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import CreateEnterpriseHeader from "./CreateEnterpriseHeader";
import CreateEnterpriseGeneralForm from "./CreateEnterpriseGeneralForm";
import { Box, Stack, styled } from "@mui/material";
import { useCreateEnterpise } from "./hooks/useCreateEnterprise";
import { FormProvider } from "react-hook-form";
import EnterprisePrimaryAccountHolder from "./components/EnterprisePrimaryAccountHolder";
import CreateEnterprisePlaceholders from "./components/CreateEnterprisePlaceholders";
import CreateBusinessProfile from "@components/Merchants/CreateMerchantPanel/components/CreateBusinessProfile";
import MerchantBusinessOwners from "@components/Merchants/MerchantPreview/components/BusinessOwners/MerchantBusinessOwners";
import MerchantBankAccounts from "@components/Merchants/MerchantPreview/components/BankAccounts/MerchantBankAccounts";
import { Button } from "@common/Button";
import CreateEnterpriseMCCSection from "./components/CreateEnterpriseMCCSection";
import { isEqual } from "lodash";
import { defaultValues } from "./defaultValues";
import { DISCARD_CHANGE_MODAL } from "modals/modal_names";
import { CREATE_MERCHANT_AGREEMENT_MODAL } from "modals/modal_names";
import MerchantAgreement from "@components/Merchants/MerchantPreview/sections/MerchantAgreement/MerchantAgreement";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const CreateEnterprisePanel = NiceModal.create(() => {
  const modal = useModal();

  const { isEnabledNewMccFlow } = useGetFeatureFlagValues();
  const {
    methods,
    onSubmit,
    isLoading,
    handleCloseBusinessOwnerModal,
    handleCloseBankAccountModal,
    parentCategories,
    isReadyForSubmission,
    handleCloseMCC,
  } = useCreateEnterpise();
  const {
    watch,
    formState: { isValid },
  } = methods;

  const values = watch();

  const handleClose = () => {
    if (isEqual(defaultValues, values)) {
      modal.hide();
    } else {
      NiceModal.show(DISCARD_CHANGE_MODAL, {
        handleSubmit: () => modal.hide(),
      });
    }
  };
  const handleCloseMerchantAgreement = (data: TMerchantAgreementPayload) => {
    methods.setValue("agreement", data);
  };
  const handleOpenMerchantAgreement = () => {
    NiceModal.show(CREATE_MERCHANT_AGREEMENT_MODAL, {
      onClose: handleCloseMerchantAgreement,
      data: { ...values.agreement, isEnterPrise: true },
    });
  };

  const isSoleProprietorship =
    values.businessProfile?.businessType === "individual_sole_proprietorship";

  return (
    <SidePanel
      modal={modal}
      onCloseDrawer={() => null}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "-8px 0px 50px 0px rgba(0, 0, 0, 0.15) !important",
        },
      }}
    >
      <CreateEnterpriseHeader handleClose={handleClose} />
      <FormProvider {...methods}>
        <Container component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <CreateEnterpriseGeneralForm />
          <Stack spacing={2} padding="0 16px">
            <EnterprisePrimaryAccountHolder />
            <CreateBusinessProfile isEnterprise />
            {values.businessOwners?.length !== 0 ? (
              <MerchantBusinessOwners
                data={values.businessOwners}
                businessType={values.businessProfile.businessType}
                onCloseModal={handleCloseBusinessOwnerModal}
                isEnterprise
                isIndividualSoleProprietorship={isSoleProprietorship}
                isBPLinked={values.businessProfile?.isLinkBusinessProfile}
              />
            ) : null}
            {values.bankAccounts?.length !== 0 ? (
              <MerchantBankAccounts
                data={values.bankAccounts}
                onCloseModal={handleCloseBankAccountModal}
              />
            ) : null}

            {values?.agreement?.msaPCICompliant && (
              <MerchantAgreement
                data={values?.agreement as any}
                handleOpenMerchantAgreement={handleOpenMerchantAgreement}
              />
            )}
          </Stack>
          <CreateEnterprisePlaceholders parentCategories={parentCategories} />
          {values.categories.length !== 0 && !isEnabledNewMccFlow && (
            <FadeUpWrapper delay={400 + 50 * (10 + 1)}>
              <MCCContainer>
                <CreateEnterpriseMCCSection handleCloseMCC={handleCloseMCC} />
              </MCCContainer>
            </FadeUpWrapper>
          )}
          <Box flexGrow={1} />
          <CreateButton size="medium" type="submit" disabled={isLoading}>
            {!isValid || !isReadyForSubmission ? "Next" : "Create"}
          </CreateButton>
        </Container>
      </FormProvider>
    </SidePanel>
  );
});

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  height: "100%",
  background: theme.palette.background.dimmedWhite,
  padding: "13px 12px 20px",
  overflowY: "auto",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    padding: "19px 6px",
  },
}));

const CreateButton = styled(Button)(() => ({
  margin: "0 auto",
  width: 200,
  position: "sticky",
  bottom: 0,
  zIndex: 99,
}));
const MCCContainer = styled(Box)(() => ({
  padding: "0px 16px",
}));
export default CreateEnterprisePanel;
