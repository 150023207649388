import { useQuery } from "react-query";
import { customInstance } from "../services/api";

const getMerchantsPublic = () => {
  return customInstance({
    url: "/merchants-public",
    method: "GET",
  });
};

export const useGetMerchantsPublic = ({ enabled }: { enabled: boolean }) => {
  return useQuery(["get-merchants-public"], getMerchantsPublic, {
    refetchOnWindowFocus: false,
    enabled,
  });
};
