import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import WelcomePageV2 from "./WelcomePageV2";
import WelcomePageV1 from "./WelcomePageV1";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

export default function WelcomePage() {
  return (
    <FlaggedWrapper
      ActiveComponent={WelcomePageV2}
      FallbackComponent={WelcomePageV1}
      name={FeatureFlagKeys.REBRANDING}
    />
  );
}
