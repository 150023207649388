import { SxProps } from "@mui/material";

export type AvatarSizesProps =
  | "24px"
  | "28px"
  | "32px"
  | "40px"
  | "44px"
  | "56px"
  | "64px"
  | "72px"
  | "80px";
type AvatarShapes = "square" | "rounded";
export interface GiveAvatarProps {
  size?: AvatarSizesProps;
  shape?: AvatarShapes;
  imageUrl?: string;
  name?: string;
  sx?: SxProps;
}

export const squareToBorderRadiusMap: Record<AvatarSizesProps, string> = {
  "24px": "4px",
  "28px": "4px",
  "32px": "8px",
  "40px": "8px",
  "44px": "10px",
  "56px": "10px",
  "64px": "10px",
  "72px": "16px",
  "80px": "8px",
};
