import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import AgreementAndSnapshot from "./AgreementAndSnapshot/AgreementAndSnapshot";
import GiveMerchantFile from "./GiveMerchantFile/GiveMerchantFile";
import { useMerchantSidePanelContext } from "./Provider/MerchantSidePanelProvider";

const SecondPanelOptions = () => {
  const { isMobileView } = useCustomThemeV2();
  const {
    secondPanelContent,
    setSecondPanelContent,
    handleCloseAll,
    contentHistory,
    isSinglePanelSize
  } = useMerchantSidePanelContext();

  const handleClose = () => {
    (!isMobileView || isSinglePanelSize) && contentHistory !== ""
      ? handleCloseAll()
      : setSecondPanelContent("");
  };

  switch (secondPanelContent) {
    case "merchantFile":
      return <GiveMerchantFile handleClosePanel={handleClose} />;
    case "agreement":
      return <AgreementAndSnapshot handleClose={handleClose} />;
    default:
      return <></>;
  }
};

export default SecondPanelOptions;
