import { Stack } from "@mui/material";
import { memo } from "react";
import { CheckCircle, Prohibit, WarningCircle } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";

export type TRiskLevel = "normal" | "high" | "restricted";

type Props = {
  riskLevelName?: TRiskLevel | null;
};

const getRiskLevelData = (palette: any) => {
  const riskLevelColors = {
    success1: palette.primitive?.success[100],
    warning: palette.primitive?.warning[100],
    error1: palette.primitive?.error[100],
  };

  return {
    normal: {
      label: "Normal",
      color: "success1",
      icon: <CheckCircle size={18} color={riskLevelColors.success1} />,
    },
    high: {
      label: "High",
      color: "warning",
      icon: <WarningCircle size={18} color={riskLevelColors.warning} />,
    },
    restricted: {
      label: "Restricted",
      color: "error1",
      icon: <Prohibit size={18} color={riskLevelColors.error1} />,
    },
  } as const;
};

const RiskStatusChip = ({ riskLevelName }: Props) => {
  const { palette } = useAppTheme();
  const riskLevelStatus = getRiskLevelData(palette);

  if (!riskLevelName) return <GiveText variant="bodyXS">Not Assigned</GiveText>;

  const { color, label, icon } = riskLevelStatus[riskLevelName as TRiskLevel];

  return (
    <Stack direction="row" alignItems="center" gap="4px">
      {icon}
      <GiveText variant="bodyXS" color={color}>
        {label}
      </GiveText>
    </Stack>
  );
};

export default memo(RiskStatusChip);
