import GiveButton from "@shared/Button/GiveButton";
import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import { GIVE_CONFIRMATION_POP_UP } from "modals/modal_names";
import { statusOptions } from "./ReportStatusSelector";
import { useFormContext, useWatch } from "react-hook-form";
import { ReportFormFields } from "./AddNewReportView";
import useSubmitMatchReport, {
  UseSubmitMatchReportType,
} from "../hooks/useSubmitMatchReport";

const SubmitButton = ({
  merchantID,
  onSubmitSuccess,
}: UseSubmitMatchReportType) => {
  const { getValues } = useFormContext<ReportFormFields>();
  const findings: ReportFormFields["findings"] = useWatch({
    name: "findings",
  });
  const { handleSubmit, isSubmitting } = useSubmitMatchReport({
    merchantID,
    onSubmitSuccess,
  });

  const handleShowConfirmation = () => {
    const formValues = getValues();
    const { status } = formValues;
    NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
      modalType: status === "clear" ? "approved" : "warning-red",
      title: "Submit MATCH Report",
      customSubmitBtnText: `Submit as ${statusOptions[status].label}`,
      showCancelBtn: true,
      description:
        "Please confirm that you have thoroughly reviewed all the information and that it is accurate and complete.",
      actions: {
        handleSuccess: { onClick: () => handleSubmit(formValues) },
      },
    });
  };

  return (
    <GiveButton
      label="Submit"
      size="large"
      variant="filled"
      disabled={!findings.trim() || isSubmitting}
      onClick={handleShowConfirmation}
    />
  );
};


export default SubmitButton;
