import { Stack } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import ChallengeLayout from "./ChallengeLayout";
import ImagesStacker from "features/Minibuilders/Conversations/utils/ImagesStacker";
import {
  BellSimpleRinging,
  CaretRight,
  ChatsCircle,
  CheckCircle,
  CircleDashed,
  DotsThreeVertical,
  XCircle,
} from "@phosphor-icons/react";
import { CustomPalette } from "@theme/v2/palette.interface";
import { TChallange, TChallengeStatus } from "./types";
import GiveText from "@shared/Text/GiveText";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveButton from "@shared/Button/GiveButton";
import { useNotifications } from "./hooks/useNotifications";
import useChallengeActions from "./hooks/useChallangeActions";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";

interface Props {
  challenge: TChallange;
  isMerchantOnboarding?: boolean;
  handleOpenMenu: (e: HTMLElement, challenge: TChallange) => void;
  handleOpenConversation: ({
    convTag,
    challengeID,
  }: {
    convTag: string;
    challengeID: number;
  }) => void;
  handleOpenChallenge: (
    challenge: TChallange,
    handleOpenConversation: () => void,
  ) => void;
  isPAHAdded: number | "";
}

export default function ChallengeItem({
  challenge,
  isMerchantOnboarding,
  handleOpenMenu,
  handleOpenConversation,
  handleOpenChallenge,
  isPAHAdded,
}: Props) {
  const { openNotifyMerchantModal } = useNotifications();

  const { palette } = useAppTheme();
  const isMerchantNotified = Boolean(challenge.notifiedAt);
  const isEDD = challenge.categoryName === "enhanced_due_diligence";

  const convTag = challenge.merchantDisplayTitle || challenge.name;

  const handleOpen = () => {
    if (isMerchantOnboarding) return;
    const openConv = () =>
      handleOpenConversation({
        convTag: convTag,
        challengeID: challenge.id,
      });
    handleOpenChallenge(challenge, openConv);
  };

  const isOptional = challenge.name === "bank_account_connected_with_plaid";

  return (
    <ChallengeLayout
      icon={getStatusIcons(challenge.statusName, palette)}
      applyIconBG={false}
      alwaysShowConversation
      horizontalPadding="8px"
      attachConversations={
        <Stack direction="row" alignItems="center">
          <ImagesStacker data={challenge.userAvatarsInThread || []} />
          <GiveIconButton
            Icon={ChatsCircle}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenConversation({ convTag, challengeID: challenge.id });
            }}
          />
        </Stack>
      }
      main={
        <Stack>
          <GiveText
            variant="bodyS"
            sx={{
              color: palette.primitive?.neutral[90],
            }}
          >
            {isEDD ? challenge.name : challenge.merchantDisplayTitle}
            {isOptional ? " (Optional)" : ""}
          </GiveText>
          <Stack direction="row" gap="6px" alignItems="center">
            <GiveText
              variant="bodyXS"
              color={
                challenge.statusName === "rejected" ? "error" : "secondary"
              }
              pr="10px"
            >
              {StatusDisplayName[challenge.statusName as TChallengeStatus]}
            </GiveText>
            {isMerchantNotified && (
              <>
                <BellSimpleRinging size={16} color={palette.text.secondary} />
                <GiveText variant="bodyXS" color="secondary">
                  Merchant Notified
                </GiveText>
              </>
            )}
          </Stack>
        </Stack>
      }
      end={
        isMerchantOnboarding ? (
          <GiveTooltip
            width="compact"
            alignment="left"
            title="This merhcant doesn't have a Primary Account Holder"
            color="default"
            disableHoverListener={!!isPAHAdded}
          >
            <GiveButton
              label="Notify"
              size="small"
              color="light"
              variant="filled"
              disabled={!isPAHAdded}
              sx={{ border: "none" }}
              onClick={(e) => {
                e.stopPropagation();
                openNotifyMerchantModal({
                  challengeTypeName: challenge?.challengeTypeName,
                  challengeID: challenge?.id,
                  topicName: challenge?.merchantDisplayTitle,
                  defaultMessage: challenge?.predefinedReason ?? undefined,
                  hideInputs: ["module"],
                });
              }}
            />
          </GiveTooltip>
        ) : (
          <Stack direction="row" gap="8px" alignItems="center">
            <GiveIconButton
              Icon={DotsThreeVertical}
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenMenu(e.currentTarget, challenge);
              }}
            />
            <GiveIconButton
              Icon={CaretRight}
              variant="ghost"
              size="small"
              onClick={handleOpen}
            />
          </Stack>
        )
      }
      onClick={handleOpen}
    />
  );
}

const getStatusIcons = (status: TChallengeStatus, palette: CustomPalette) => {
  const icons = {
    closed: (
      <CheckCircle
        size={24}
        color={palette.primitive?.success[50]}
        weight="fill"
      />
    ),
    ready_for_verification: (
      <CheckCircle size={24} color={palette.primitive?.success[50]} />
    ),
    open: <CircleDashed size={24} color={palette.icon?.["icon-secondary"]} />,
    rejected: (
      <XCircle size={24} color={palette.primitive?.error["50"]} weight="fill" />
    ),
  };
  return icons[status];
};

const StatusDisplayName = {
  closed: "Completed",
  ready_for_verification: "Ready for Confirmation",
  open: "Open",
  rejected: "Rejected",
};
