import { useGetCurrentMerchantId } from "@hooks/common";
import flagsmith from "flagsmith";
import { useEffect, useState } from "react";

interface Feature {
  id: number;
  enabled: boolean;
  value: string | null; // Assuming value can be null or a string
}

type EvaluationEvent = unknown;

interface Trait {
  [key: string]: string;
}

export interface TFlagSmithState {
  environmentID: string;
  evaluationEvent: EvaluationEvent;
  flags: {
    [key: string]: Feature;
  };
  identity: number;
  traits: Trait;
  ts: null;
}

export const useGetFlags = () => {
  const { merchantId, selectedUser } = useGetCurrentMerchantId();
  const [flags, setFlags] = useState<Map<string, boolean>>(new Map());
  const [isForceLoading, setIsForceLoading] = useState<boolean>(false);

  const isFlagsmithLoading =
    !flagsmith.loadingState?.error && flagsmith.loadingState?.isLoading;

  // Basically each feature flag is create globally at environment level for all identities (users) in that environment
  // Each identity/user can override the status of a particular flag for testing
  // That's why we first Identify the user (if the user is not present - flagsmith adds a new identity for that user AUTOMATICALLY with the all the features set as their global state)
  useEffect(() => {
    const identifyUser = async () => {
      setIsForceLoading(true);
      try {
        // In this case we're passing the entire user informations as traits (2nd parameter).
        // Traits are optional filters, a way to identify a subset of identities (for example all the merchants with role Provider)
        await flagsmith.identify(merchantId.toString(), {
          ...selectedUser,
          id: merchantId,
        });

        const allFlags = flagsmith.getAllFlags();

        setFlags(
          Object.keys(allFlags).reduce((all, key) => {
            all.set(key, allFlags[key].enabled);
            return all;
          }, new Map<string, boolean>()),
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsForceLoading(false);
      }
    };

    identifyUser();
  }, [merchantId, selectedUser]);

  return {
    flags,
    flagsmithState: flagsmith.getState() as unknown as TFlagSmithState,
    isLoading: isFlagsmithLoading || (isForceLoading && flags.size === 0),
  };
};
