import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { StyledGiveButton } from "./UnderwritingCard.style";
import NiceModal from "@ebay/nice-modal-react";
import {
  APPROVE_MERCHANT_MODAL_REBRAND,
  DECLINE_MERCHANT_MODAL_REBRAND,
} from "modals/modal_names";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { NEW_ACTION_DENY_MESSAGE } from "@constants/permissions";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";

type TChallengeCategoriesBottom = {
  isAllCompleted: boolean;
  merchantId: number;
  merchantName: string;
};

const ChallengeCategoriesBottom = ({
  isAllCompleted,
  merchantId,
  merchantName,
}: TChallengeCategoriesBottom) => {
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const handleApprove = () => {
    NiceModal.show(APPROVE_MERCHANT_MODAL_REBRAND, {
      merchantId,
    });
  };

  const handleDecline = () => {
    NiceModal.show(DECLINE_MERCHANT_MODAL_REBRAND, {
      merchantId,
      merchantName,
    });
  };

  return (
    <>
      <GiveText variant="bodyS" color="primary">
        All of the above criteria must be met before approving this account.
      </GiveText>
      <Stack direction="row" gap={1.5}>
        <UnderwriterAllowedTooltip>
          <StyledGiveButton
            disabled={!isAllCompleted || !isUpdateUnderwriterAllowed}
            variant="filled"
            label="Approve this Account"
            onClick={handleApprove}
          />
        </UnderwriterAllowedTooltip>

        <UnderwriterAllowedTooltip>
          <StyledGiveButton
            disabled={!isUpdateUnderwriterAllowed}
            variant="outline"
            label="Decline"
            color="destructive"
            onClick={handleDecline}
          />
        </UnderwriterAllowedTooltip>
      </Stack>
    </>
  );
};

const UnderwriterAllowedTooltip = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  return (
    <GiveTooltip
      title={NEW_ACTION_DENY_MESSAGE}
      color="default"
      disableHoverListener={isUpdateUnderwriterAllowed}
      customTooltipStyles={{ width: "fit-content" }}
    >
      {children}
    </GiveTooltip>
  );
};

export default ChallengeCategoriesBottom;
