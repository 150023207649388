import { Stack } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useDocumentHandlers } from "@hooks/common/documents";
import { PAH_UPLOADER_PREVIEW_MODAL } from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { TOwnerFile } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/types";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import IDUploader from "./IDUploader";
import { TIDPreviewModalProps } from "./types";
import { IDUploaderMapper } from "./IDUploaderMapper";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";

type TOwnerFileItems = Array<{
  type: TOwnerFile;
  file: TMerchantDocument | undefined;
}>;

type Props = {
  isOptionalEnterprise?: boolean;
  merchantId?: number;
  isOptionalRefetching?: boolean;
  documents?: TMerchantDocument[];
  isDeleteHidden?: boolean;
};

function IdentityVerification({
  isOptionalEnterprise,
  merchantId,
  documents,
  isDeleteHidden = false,
}: Props) {
  const { isEnterprise, id, data } = useMerchantSidePanelContext();
  const documentsData = data?.documents || documents || [];
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();
  const isHidden = !merchant_underwriting && isEnterprisePortal;
  const { isMobileView } = useCustomTheme();

  const PAH_ID = documentsData?.find(
    (doc) => doc?.attTypeName === "account_owner",
  );
  const PAH_SELFIE = documentsData?.find(
    (doc) => doc?.attTypeName === "account_owner_selfie",
  );

  const resource = composePermission(
    isEnterprise || isOptionalEnterprise
      ? RESOURCE_BASE.ENTERPRISE
      : RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.FILES,
  );

  const isUploadAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.CREATE,
  });

  const isDeleteAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.DELETE,
  });

  const [files, setFiles] = useState<TOwnerFileItems>([]);

  const createMapper = useCallback((): TOwnerFileItems => {
    return [
      {
        type: "primaryAccountHolderID",
        file: PAH_ID,
      },
      {
        type: "primaryAccountHolderSelfie",
        file: PAH_SELFIE,
      },
    ];
  }, [PAH_ID?.fileURL, PAH_SELFIE?.fileURL]);

  useEffect(() => {
    const _files = createMapper();
    setFiles(_files);
    return () => setFiles([]);
  }, [documentsData]);

  const { downloadHandler } = useDocumentHandlers(merchantId || id);

  const previewDocument = ({
    tmpUrl = "",
    type = "" as TOwnerFile,
    fileName = "",
  } = {}) => {
    const filesData = files.map(({ type: _type, file }) => {
      return {
        fileName:
          file?.fileName ||
          (type && fileName && type === _type ? fileName : ""),
        src:
          file?.fileURL ||
          (type && tmpUrl && type === _type ? tmpUrl : undefined) ||
          "",
        type: type,
        icon: IDUploaderMapper[_type].icon,
        title: _type === "primaryAccountHolderID" ? "ID" : "Selfie",
      };
    });

    NiceModal.show(PAH_UPLOADER_PREVIEW_MODAL, {
      filesData,
    } as TIDPreviewModalProps);
  };

  return (
    <Stack gap={1}>
      <Stack gap={1}>
        {files.map(({ type, file }, index) => {
          if (isHidden && !file) return null;
          return (
            <IDUploader
              key={index}
              type={type}
              isMobile={isMobileView}
              disabled={!isUploadAllowed}
              merchantId={merchantId || id}
              docUrl={file?.fileURL || ""}
              downloadHandler={() => downloadHandler(file)}
              previewDocument={previewDocument}
              isUploadAllowed={isUploadAllowed}
              isDeleteAllowed={isDeleteAllowed}
              fileId={file?.id}
              isDeleteHidden={isDeleteHidden}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

export default memo(IdentityVerification);
