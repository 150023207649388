import React from "react";

type TProps = { fill?: string; width?: number; height?: number };

const RotateLeft = ({ fill = "black", width = 21, height = 20 }: TProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6919 1.69194C14.936 1.44786 14.936 1.05214 14.6919 0.808058C14.4479 0.563981 14.0521 0.563981 13.8081 0.808058L11.3081 3.30806C11.064 3.55214 11.064 3.94786 11.3081 4.19194L13.8081 6.69194C14.0521 6.93602 14.4479 6.93602 14.6919 6.69194C14.936 6.44786 14.936 6.05214 14.6919 5.80806L13.2613 4.37742C15.5044 4.41961 16.7826 5.00876 17.5255 5.87549C18.3146 6.79612 18.625 8.16976 18.625 10C18.625 10.3452 18.9048 10.625 19.25 10.625C19.5952 10.625 19.875 10.3452 19.875 10C19.875 8.08024 19.5604 6.32888 18.4745 5.06201C17.4076 3.81727 15.7118 3.169 13.2567 3.12716L14.6919 1.69194ZM10.5 10H1.75L1.75 18.75H10.5V10ZM1.75 8.75C1.05964 8.75 0.5 9.30964 0.5 10V18.75C0.5 19.4404 1.05964 20 1.75 20H10.5C11.1904 20 11.75 19.4404 11.75 18.75V10C11.75 9.30964 11.1904 8.75 10.5 8.75H1.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default RotateLeft;
