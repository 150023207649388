import { Stack } from "@mui/material";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { FormDataType } from "@sections/PayBuilder/utils";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import GiveSwitch from "@shared/Switch/GiveSwitch";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { Controller } from "react-hook-form";

const CheckoutDetailsSectionItem = ({
  label,
  showRequiredLabel = false,
  secondaryText,
  name,
  isEmail,
  showRequiredSwitch,
  optionalInput,
  isLastItem = false,
}: {
  label: string;
  showRequiredLabel?: boolean;
  secondaryText?: string;
  name: keyof FormDataType["Checkout"];
  isEmail?: boolean;
  showRequiredSwitch?: boolean;
  optionalInput?: React.ReactElement;
  isLastItem?: boolean;
}) => {
  const { palette } = useAppTheme();
  const { methods } = usePayBuilderForm();
  const { watch, control } = methods;
  const values = watch();
  const isCheckboxSelected = values[`Checkout`][name].render;

  return (
    <Stack
      borderBottom={
        isLastItem ? "none" : `1px solid ${palette.border?.primary}`
      }
      padding="12px 0"
      sx={{
        overflowX: "hidden",
      }}
      spacing={2}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1.5}>
          <Controller
            control={control}
            name={`Checkout.${name}.render`}
            render={({ field: { onChange, value } }) => {
              return (
                <GiveCheckbox
                  checked={value}
                  disabled={isEmail}
                  onChange={onChange}
                />
              );
            }}
          />
          <Stack spacing={1} maxWidth="90%">
            <GiveText variant="bodyS">{label}</GiveText>
            {secondaryText && (
              <GiveText variant="bodyS" color="secondary">
                {secondaryText}
              </GiveText>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1.5}>
          {showRequiredLabel && isCheckboxSelected && (
            <GiveText variant="bodyS" color={isEmail ? "secondary" : "primary"}>
              Required
            </GiveText>
          )}
          {showRequiredSwitch && isCheckboxSelected && (
            <Controller
              control={control}
              name={`Checkout.${name}.required` as any}
              render={({ field: { onChange, value } }) => {
                return <GiveSwitch checked={value} onChange={onChange} />;
              }}
            />
          )}
        </Stack>
      </Stack>
      {optionalInput}
    </Stack>
  );
};

export default CheckoutDetailsSectionItem;
