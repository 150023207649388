import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useUploadSingleImage } from "@hooks/upload-api/uploadHooks";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

const useChangeProfileImage = (merchantId: number) => {
  const hasUpdatePermissions = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });
  const { handleSubmit } = useUpdateMerchantInfo(merchantId);
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUpload } = useUploadSingleImage();
  const { populateSnackbarFiles } = useFileUploadContext();

  const handleChangeStatus: any = async ({ file }: { file: File }) => {
    if (!file) return;

    if (isFileUploadRefactorEnabled) {
      const img = await handleUpload({ file, formType: "customer_avatar" });
      if (img) handleSubmit("avatar", { avatar: img });
      return;
    }

    const fileToUpload = {
      file,
      meta: {
        status: "done",
        type: file.type,
        id: Math.random().toString(),
        size: file.size,
        name: file.name,
      },
    } as any;

    const img = await populateSnackbarFiles(
      {
        fileWithMeta: fileToUpload,
        status: fileToUpload?.status,
        allFiles: [fileToUpload],
        isCustomerUpload: true,
      },
      ACCEPTED_IMAGE_FORMATS,
    );
    const url = !!img && typeof img?.[0] === "string" ? img[0] : null;
    url && handleSubmit("avatar", { avatar: url });
  };

  const onUpload = (file: File) => handleChangeStatus({ file });

  const onDelete = () => handleSubmit("avatar", { avatar: null });

  return {
    onUpload,
    onDelete,
    disabled: !hasUpdatePermissions,
  };
};

export default useChangeProfileImage;
