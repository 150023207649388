import { useCallback, useMemo, useRef, useState } from "react";
import { palette } from "@palette";
import { Switch_V2 } from "@common/Switch";
import { CheckMarkIcon, StarIcon, TickIcon } from "@assets/icons";
import Selector from "../Selector";
import { useInfiniteEnterprises } from "@common/BusinessProfileInputs/EnterprisesSelect";
import { useForm } from "react-hook-form";
import { Box, Divider, Stack } from "@mui/material";
import ListItem from "../ListItem";
import Chip, { ChipOption } from "../Chip";
import { Text, TruncateText } from "@common/Text";
import { styled } from "@mui/material";
import {
  customFilterDates,
  customTotalProcessing,
  selectedQueries,
  setFilterDates,
  selectedQueryString,
  setCustomTotalProcessing,
  setFilters,
  FilterDate,
  useTableFilters,
} from "@redux/slices/tableFilters";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/hooks";
import {
  CustomSections,
  defaultFormValues,
  FilterSectionItemType,
  formatQueryParamsList,
  FormFields,
  FormValues,
  getCustomProcessingLabel,
  getDateValue,
  getProcessingAmount,
  SelectorOption,
  UnderwritingScoreValues,
} from "../utils";
import { debounce, isEqual } from "lodash";
import CustomVolumePopup from "../CustomVolumePopup";
import { useGetMerchantStatuses } from "@hooks/acquirer-api/merchants/useGetMerchantStatuses";
import WrapperWithDateRange from "../WrapperWithDateRange";
import UpTrendIconV2 from "@assets/icons/UpTrendIconV2";
import DownTrendIconV2 from "@assets/icons/DownTrendIconV2";
import SectionItem from "../SectionItem";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { Avatar } from "@mui/material";
import Placeholder from "assets/images/Placeholder.png";
import AssigneesSelector from "../AssigneesSelector";
import { checkPortals } from "@utils/routing";
import {
  MerchantConditions,
  MerchantConditionsEndElement,
} from "../MerchantConditions";
import { DetectiveIcon } from "@assets/icons/RebrandedIcons";
import { QKEY_LIST_ENTERPRISES } from "@constants/queryKeys";
import useListCategories from "@hooks/acquirer-api/useListCategoriesCodes";
import { Siren } from "@phosphor-icons/react";

interface Props {
  type: "merchant";
  tab: "portfolio" | "underwriting" | "risk";
  onClose: () => void;
}

const useFilterSections = ({ type, tab, onClose }: Props) => {
  const { resetTableFilters, quickFilter, setQueryStringWithQuickFilter } =
    useTableFilters();
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  //----import required hooks and context logics----
  const { data, handleScroll } = useInfiniteEnterprises();
  const [mccSearch, setMccSearch] = useState("");
  const { data: categoryCodes, handleScroll: handleScrollMcc } =
    useListCategories({
      all: false,
      searchTerm: mccSearch,
      scrollBoxRef,
    });
  const { data: statuses } = useGetMerchantStatuses();
  const [dateFiltersArray, setDateFiltersArray] = useState<FilterDate[] | []>(
    [],
  );
  const dispatch = useDispatch();
  const contextQueries = useAppSelector(selectedQueries);
  const contextCustomTotalProcessing = useAppSelector(customTotalProcessing);
  const contextSignupDate = useAppSelector(customFilterDates);
  const contextQueryString = useAppSelector(selectedQueryString);
  const {
    img: currUserImg,
    id: currUserId,
    email: currUserEmail,
  } = useUserReducer();
  const { isAcquirerPortal } = checkPortals();

  //----handle form logic like default values and checks----
  const defaultValues: FormValues = {
    ...defaultFormValues,
    ...contextQueries,
    ...(contextSignupDate &&
      contextSignupDate?.createdAt?.endDate && {
        createdAt: getDateValue({
          modifier: "is between",
          range: {
            startDate: contextSignupDate?.createdAt?.startDate,
            endDate: contextSignupDate?.createdAt?.endDate,
          },
        }),
      }),
  };
  const methods = useForm<FormValues>({ defaultValues: defaultValues });
  const values = methods.watch();

  const areFiltersSelected =
    !isEqual(defaultFormValues, values) || contextQueryString;

  const clearAllEnabled =
    areFiltersSelected && !isEqual(defaultFormValues, values);

  //----handle hook logic like reset, select and submit----
  const handleMultiSelect = (
    fieldName: FormFields,
    value: SelectorOption | SelectorOption[],
  ) => {
    const currentValues: SelectorOption[] =
      !!values[fieldName] && Array.isArray(values[fieldName])
        ? (values[fieldName] as SelectorOption[])
        : [];

    // replace form value if the value param is an array
    if (Array.isArray(value)) {
      methods.setValue(fieldName, value);
      return;
    }
    if (currentValues && currentValues.find((item) => item.id === value.id)) {
      methods.setValue(
        fieldName,
        currentValues?.filter((item) => item.id !== value.id),
      );
    } else {
      const vals = currentValues ? [...currentValues, value] : [value];
      methods.setValue(fieldName, vals);
    }
  };

  const handleOwnerInvite = (value?: SelectorOption, checkStatus?: boolean) => {
    const isNotInvited = value?.value === "!=invited"; // Check if the new value is '!=invited'
    const hasNotInvited = values.ownerMembershipStatus?.some(
      (i) => i.value === "!=invited",
    ); // Check if the current ownerMembershipStatus includes '!=invited'
    const hasStatusDisplayName = values.statusDisplayName?.length !== 0; // Check if statusDisplayName has any length

    // If the new value is equal to the current ownerMembershipStatus
    if (isEqual([value], values.ownerMembershipStatus)) {
      // If there are status display names and checkStatus is true, set ownerMembershipStatus to '!=invited' and 'null'
      if (hasStatusDisplayName && checkStatus) {
        methods.setValue("ownerMembershipStatus", [
          { value: "!=invited" },
          { value: "null" },
        ]);
      } else {
        // Otherwise, reset ownerMembershipStatus to undefined
        methods.setValue("ownerMembershipStatus", undefined);
      }
      return;
    }

    // If no new value is provided
    if (!value) {
      // If there are status display names and checkStatus is true, set ownerMembershipStatus to '!=invited' and 'null'
      if (hasStatusDisplayName && checkStatus) {
        methods.setValue("ownerMembershipStatus", [
          { value: "!=invited" },
          { value: "null" },
        ]);
      } else {
        // Otherwise, reset ownerMembershipStatus to undefined
        methods.setValue("ownerMembershipStatus", undefined);
      }
      return;
    }

    // If the new value is '!=invited' and the current ownerMembershipStatus does not include '!=invited'
    if (isNotInvited && !hasNotInvited) {
      methods.setValue("ownerMembershipStatus", [value, { value: "null" }]); // Add '!=invited' and 'null' to ownerMembershipStatus
    } else if (!isNotInvited) {
      // If the new value is not '!=invited'
      methods.setValue("ownerMembershipStatus", [value]); // Set ownerMembershipStatus to the new value
    } else if (hasStatusDisplayName && checkStatus) {
      // If there are status display names and checkStatus is true
      methods.setValue("ownerMembershipStatus", [
        { value: "!=invited" },
        { value: "null" },
      ]); // Set ownerMembershipStatus to '!=invited' and 'null'
    } else {
      methods.setValue("ownerMembershipStatus", undefined); // Otherwise, reset ownerMembershipStatus to undefined
    }
  };

  const handleReset = () => {
    resetTableFilters();
    methods.reset(defaultFormValues);
  };

  const onSubmit = (values: FormValues) => {
    const { filterDates, ...rest } = values;
    if (isEqual(defaultFormValues, values)) {
      handleReset();
    } else {
      const changedValuesEntries = Object.entries(rest).filter(
        ([key, rest]) => !isEqual(rest, defaultFormValues[key as FormFields]),
      );
      const changedValues = Object.fromEntries(changedValuesEntries);
      const extraJoinKey =
        Boolean(
          values.ownerMembershipStatus?.find((x) => x.value === "invited"),
        ) &&
        values.statusDisplayName &&
        values.statusDisplayName.length > 0
          ? "%2C"
          : undefined;
      const beforeKey = extraJoinKey ? "ownerMembershipStatus" : undefined;
      const formattedParams = formatQueryParamsList(values, {
        extraJoinKey,
        beforeKey,
      });

      dispatch(
        setFilters({
          queryParams: changedValues,
          queryString: formattedParams,
        }),
      );

      if (quickFilter) {
        const curRiskLevelFilters = values?.riskLevelLabelDisplayName;
        const opt = riskLevelArray?.find((i) => i.value === quickFilter);
        const addOption =
          !curRiskLevelFilters?.length ||
          curRiskLevelFilters?.find((i) => i.value === quickFilter);

        const queryStrQuickF = formatQueryParamsList({
          ...values,
          riskLevelLabelDisplayName: addOption && opt ? [opt] : [],
        });

        setQueryStringWithQuickFilter(
          queryStrQuickF +
            (addOption ? "" : `(riskLevelLabelDisplayName:"undefined")`),
        );
      }
    }

    dateFiltersArray?.forEach((date) => {
      dispatch(setFilterDates(date));
    });
    setDateFiltersArray([]);
    onClose();
  };

  const handleSaveProcessingVolume = (param: {
    from: number | undefined;
    to: number | undefined;
  }) => {
    dispatch(setCustomTotalProcessing({ from: param.from, to: param.to }));
    const stringValue = getProcessingAmount({
      ...(typeof param.from === "number" && { amount: param.from * 100 }),
      ...(typeof param.to === "number" && { secondAmount: param.to * 100 }),
      modifier: "is between",
    });

    methods.setValue("totalProcessed", stringValue);
  };

  //----handle options logic----
  const enterpriseOptions = useMemo(
    () =>
      data.map((item) => ({
        label: item.name,
        id: item.accID,
        imageURL: item.imageURL,
        value: item.name,
      })),
    [data],
  );

  const debouncedSetMccSearch = useCallback(
    debounce((value) => {
      setMccSearch(value);
    }, 300),
    [],
  );

  const mccOptions = useMemo(
    () =>
      categoryCodes.map((item) => ({
        label: `${item.code} - ${item.title}`,
        id: `${item.id}`,
        imageURL: "",
        value: item.code,
      })),
    [categoryCodes],
  );

  const statusOptions: Partial<SelectorOption>[] = useMemo(() => {
    const statusList: Partial<SelectorOption>[] = [];
    const addedLabels = new Set<string>();

    statuses?.data?.forEach((item) => {
      const label = item.displayName.includes("Pre Approved")
        ? "Underwriting"
        : item.displayName;

      if (!addedLabels.has(label) && label !== "Deactivated") {
        statusList.push({
          label,
          value: item.displayName,
          id: item.displayName,
        });
        addedLabels.add(label);
      }
    });
    if (!addedLabels.has("Invited")) {
      statusList.push({
        label: "Invited",
        value: "invited",
        id: "Invited",
      });
      addedLabels.add("Invited");
    }

    return statusList;
  }, [statuses]);

  //----handle UI logic----
  const merchantSections = useMemo(() => {
    const customProcessingValue = processingOptions.find(
      (option) => option.value === values.totalProcessed,
    )
      ? false
      : values.totalProcessed;

    const defaultSections: FilterSectionItemType[] = [
      {
        title: "Watchlist only",
        description: "See merchants only in the watchlist",
        startIcon: <StarIcon fill={palette.neutral[80]} />,
        endElement: (
          <Switch_V2
            size="small"
            containerProps={{ alignItems: "initial" }}
            name="watchlist"
            checked={Boolean(values.watchlist)}
            onChange={(e, checked) =>
              methods.setValue("watchlist", checked ? checked : undefined)
            }
          />
        ),
      },
      {
        title: "Provider name",
        description: "Filter by provider",
        hidden: !isAcquirerPortal,
        endElement: (
          <Selector
            options={enterpriseOptions}
            onScroll={handleScroll}
            loading={false}
            queryKey={QKEY_LIST_ENTERPRISES}
            value={values?.parentName}
            handleChange={(val) =>
              handleMultiSelect("parentName", val as SelectorOption)
            }
            handleDeselect={(val) => handleMultiSelect("parentName", val)}
            multiSelect
          />
        ),
        children: (
          <Stack
            spacing="6px"
            paddingTop="8px"
            data-testid="filter-selected-provider-items"
          >
            {Boolean(values.parentName) &&
              values.parentName?.map((item: SelectorOption) => (
                <ListItem
                  key={item.label}
                  item={item}
                  onDelete={(val) => handleMultiSelect("parentName", val)}
                />
              ))}
          </Stack>
        ),
      },
    ];

    const mccFilter = {
      title: "MCC",
      description: "Filter merchants by MCC code",
      endElement: (
        <Selector
          options={mccOptions}
          onScroll={handleScrollMcc}
          loading={false}
          value={values?.categoryCode}
          handleChange={(val) =>
            handleMultiSelect("categoryCode", val as SelectorOption)
          }
          searchedWord={mccSearch}
          handleSearch={debouncedSetMccSearch}
          handleDeselect={(val) => handleMultiSelect("categoryCode", val)}
          multiSelect
          renderCustomElement={(option) => {
            const {
              item: { label },
              selected,
            } = option;
            const [description, title] = label.split("-");
            return (
              <Stack direction="row" alignItems="center" p="2px 8px">
                <Stack gap="2px" flex={1} direction="column">
                  <Stack
                    flex={1}
                    justifyContent="space-between"
                    gap="16px"
                    direction={"row"}
                  >
                    <TruncateText flex={1} height="20px" lineClamp={1}>
                      {title}
                    </TruncateText>
                    {selected && (
                      <Box width="15px" height="20px">
                        <TickIcon size={15} fill={palette.neutral[80]} />
                      </Box>
                    )}
                  </Stack>
                  <StyledText isDescription>{description.trim()}</StyledText>
                </Stack>
              </Stack>
            );
          }}
          scrollBoxRef={scrollBoxRef}
        />
      ),
      children: (
        <Stack spacing="6px" paddingTop="8px">
          {Boolean(values.categoryCode) &&
            values.categoryCode?.map((item: SelectorOption) => (
              <ListItem
                key={item.label}
                noImage
                item={item}
                onDelete={(val) => handleMultiSelect("categoryCode", val)}
              />
            ))}
        </Stack>
      ),
    };

    const dateFilter = (
      title: string,
      description: string,
      name: FormFields,
    ) => ({
      title: title,
      description: description,
      children: (
        <Wrapper>
          <WrapperWithDateRange
            dateName={name}
            selectedDate={contextSignupDate}
            rangeSaveCallback={(val) => {
              setDateFiltersArray((prev) => {
                const index = prev.findIndex(
                  (obj) => obj.dateName === val.dateName,
                );
                if (index !== -1) {
                  prev[index] = val;
                } else {
                  prev.push(val as never);
                }
                return [...prev];
              });
            }}
          />
        </Wrapper>
      ),
    });

    const signupDateFilter = dateFilter(
      "Signup Date",
      "See merchants that signed up within a specific date range",
      "createdAt",
    );
    const approvedDateFilter = dateFilter(
      "Approved Date",
      "See merchants that were approved within a specific date range",
      "approvedAt",
    );
    const lastEscalationDate = dateFilter(
      "Last Escalation Date ",
      "",
      "lastEscalationAt",
    );
    const lastRiskActivity = dateFilter(
      "Last Risk Activity ",
      "",
      "lastRiskActivityAt",
    );
    const RiskLevels = {
      title: "Risk Level",
      description: "View merchants with certain risk level",
      children: (
        <Wrapper>
          {riskLevelArray?.map((option) => {
            const isSelected = values.riskLevelLabelDisplayName?.find(
              (item) => item.value === option.value,
            );
            return (
              <Chip
                key={option.value}
                item={option}
                selected={Boolean(isSelected)}
                allowMultiSelect
                onSelect={() => {
                  handleMultiSelect(
                    "riskLevelLabelDisplayName",
                    option as SelectorOption,
                  );
                }}
              />
            );
          })}
        </Wrapper>
      ),
    };

    const RiskStatus = {
      title: "Risk Status",
      description: "View merchants with certain risk status",
      children: (
        <Wrapper>
          {riskStatusArray?.map((option) => {
            const selected = values?.riskStatusDisplayName?.find(
              (item) => item.value === option.value,
            );

            const color = selected ? palette.info.main : palette.neutral[80];

            return (
              <Chip
                key={option.value}
                item={{
                  ...option,
                  StartIcon: () => (
                    <option.Icon fill={color} color={color} weight="bold" />
                  ),
                }}
                selected={!!selected}
                allowMultiSelect
                onSelect={() => {
                  methods.setValue(
                    "riskStatusDisplayName",
                    (() => {
                      const arr = values?.riskStatusDisplayName || [];
                      const index = arr?.findIndex(
                        (obj) => obj.value === option.value,
                      );
                      if (index !== -1) {
                        return arr.filter((obj) => obj.value !== option.value);
                      } else {
                        return [...arr, option];
                      }
                    })(),
                  );
                }}
              />
            );
          })}
        </Wrapper>
      ),
    };

    const MerchantRiskStatus = {
      title: "Merchant Risk Category",
      description: "Filter merchants by Merchant Category Code risk level",
      children: (
        <Wrapper>
          {merchantRiskCategoryArray?.map((option) => {
            const isSelected = Boolean(
              values.merchantRiskStatusDisplayName?.find(
                (item) => item.value === option.value,
              ),
            );
            return (
              <Chip
                key={option.value}
                item={option}
                selected={isSelected}
                allowMultiSelect
                onSelect={() => {
                  handleMultiSelect(
                    "merchantRiskStatusDisplayName",
                    option as SelectorOption,
                  );
                }}
              />
            );
          })}
        </Wrapper>
      ),
    };

    const assignToObj = {
      title: "Assigned to me",
      description: "See merchants that are assigned to you",
      startIcon: <StyledAvatar src={currUserImg || Placeholder} />,
      endElement: (
        <Switch_V2
          size="small"
          containerProps={{ alignItems: "initial" }}
          checked={Boolean(
            values.underwriterEmail?.find(
              (item) => item.value === currUserEmail,
            ),
          )}
          onChange={(e, checked) =>
            handleMultiSelect("underwriterEmail", {
              label: currUserEmail,
              value: currUserEmail,
              id: `${currUserId}`,
            })
          }
        />
      ),
      children: (
        <Stack gap={1}>
          <StyledDivider />
          <SectionItem
            containerStyle={{ padding: 0 }}
            title="Other Assignees"
            description="See merchants that are assigned to specific assignees"
            endElement={
              <AssigneesSelector
                id={currUserId}
                selectedValues={values?.underwriterEmail}
                handleMultiSelect={handleMultiSelect}
                type={tab === "risk" ? "risk" : "underwriting"}
              />
            }
          />
          <Stack spacing="6px" paddingTop={1}>
            {!!values?.underwriterEmail &&
              values.underwriterEmail
                ?.filter((item) => item.value !== currUserEmail)
                .map((item: SelectorOption) => (
                  <ListItem
                    key={item.label}
                    item={item}
                    onDelete={(val) =>
                      handleMultiSelect("underwriterEmail", val)
                    }
                  />
                ))}
          </Stack>
        </Stack>
      ),
    };

    const merchantConditionsFilter = {
      title: "Merchant Conditions",
      endElement: <MerchantConditionsEndElement />,
      children: <MerchantConditions />,
    };

    const customOptions: CustomSections = {
      portfolio: [
        {
          title: "Processing Volume",
          description: "See merchants with a specific volume range",
          children: (
            <Wrapper>
              {processingOptions.map((option) => {
                const isCustom =
                  !!customProcessingValue && option.value === "custom";

                const customLabel = getCustomProcessingLabel(
                  contextCustomTotalProcessing?.from,
                  contextCustomTotalProcessing?.to,
                );
                return (
                  <Chip
                    key={option.value}
                    item={
                      isCustom
                        ? { label: customLabel, value: option.value }
                        : option
                    }
                    {...(option.value === "custom" && {
                      PopupComponent: CustomVolumePopup,
                      popupCallback: handleSaveProcessingVolume,
                    })}
                    onSelect={(val) => {
                      methods.setValue("totalProcessed", val as string);
                    }}
                    selected={
                      isCustom || option.value === values.totalProcessed
                    }
                    deleteCallback={() =>
                      dispatch(
                        setCustomTotalProcessing({
                          from: undefined,
                          to: undefined,
                        }),
                      )
                    }
                  />
                );
              })}
            </Wrapper>
          ),
        },
        {
          title: "Merchant Status",
          description: "View merchants based on their current status",
          children: (
            <Wrapper>
              {statusOptions?.map((option) => {
                const isInvitedSelected = Boolean(
                  values.ownerMembershipStatus?.find(
                    (item) => item.value === "invited",
                  ),
                );
                const isAnyOwnerInviteSelected = Boolean(
                  values.ownerMembershipStatus,
                );
                return (
                  <Chip
                    key={option.value}
                    item={option}
                    onSelect={(val) => {
                      if ((val as SelectorOption)?.value === "invited") {
                        handleOwnerInvite(val as SelectorOption, true);
                      } else {
                        handleMultiSelect(
                          "statusDisplayName",
                          val as SelectorOption,
                        );
                        if (
                          !isAnyOwnerInviteSelected ||
                          (values.statusDisplayName?.length === 1 &&
                            Boolean(
                              values.statusDisplayName?.find(
                                (item) =>
                                  item.value === (val as SelectorOption)?.value,
                              ),
                            ) &&
                            !isInvitedSelected)
                        ) {
                          handleOwnerInvite({
                            value: "!=invited",
                          } as SelectorOption);
                        }
                      }
                    }}
                    selected={Boolean(
                      values.statusDisplayName?.find(
                        (item) => item.value === option.value,
                      ) ||
                        values.ownerMembershipStatus?.find(
                          (item) => item.value === option.value,
                        ),
                    )}
                    allowMultiSelect
                  />
                );
              })}
            </Wrapper>
          ),
        },
        mccFilter,
        MerchantRiskStatus,
        signupDateFilter,
      ],
      underwriting: [
        assignToObj,
        {
          title: "Underwriting Score",
          description: "Specify merchants based on certain underwriting scores",
          children: (
            <Stack direction="column">
              <SectionItem
                containerStyle={{ paddingInline: 0 }}
                startIcon={<UpTrendIconV2 />}
                title="Score exceeds 80%"
                description="See merchants with score of 80% or higher"
                endElement={
                  <Switch_V2
                    size="small"
                    containerProps={{ alignItems: "initial" }}
                    checked={
                      values.underwritingChallengeScorePercentage ===
                      UnderwritingScoreValues.overEighty
                    }
                    onChange={(e, checked) =>
                      methods.setValue(
                        "underwritingChallengeScorePercentage",
                        checked
                          ? UnderwritingScoreValues.overEighty
                          : undefined,
                      )
                    }
                  />
                }
              />
              <StyledDivider />
              <SectionItem
                startIcon={<DownTrendIconV2 />}
                containerStyle={{ paddingInline: 0 }}
                title="Score below 80%"
                description="See merchants with score of 80% or lower"
                endElement={
                  <Switch_V2
                    size="small"
                    containerProps={{ alignItems: "initial" }}
                    checked={
                      values.underwritingChallengeScorePercentage ===
                      UnderwritingScoreValues.belowEighty
                    }
                    onChange={(e, checked) =>
                      methods.setValue(
                        "underwritingChallengeScorePercentage",
                        checked
                          ? UnderwritingScoreValues.belowEighty
                          : undefined,
                      )
                    }
                  />
                }
              />
            </Stack>
          ),
        },
        mccFilter,
        MerchantRiskStatus,
        signupDateFilter,
      ],
      risk: [
        RiskLevels,
        RiskStatus,
        lastEscalationDate,
        lastRiskActivity,
        merchantConditionsFilter,
        assignToObj,
        mccFilter,
        MerchantRiskStatus,
        approvedDateFilter,
      ],
    };

    return [...defaultSections, ...customOptions[tab]];
  }, [tab, enterpriseOptions, values, mccOptions, statusOptions]);

  const sections = {
    merchant: merchantSections,
  };

  return {
    sections: sections[type],
    methods,
    onSubmit,
    handleReset,
    areFiltersSelected,
    clearAllEnabled,
  };
};

export default useFilterSections;

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isDescription",
})<{ isDescription?: boolean }>(({ isDescription }) => ({
  fontSize: isDescription ? "12px" : "14px",
  lineHeight: "16.8px",
  color: isDescription ? palette.neutral[70] : palette.neutral[80],
  textOverflow: "ellipsis",
  textWrap: "nowrap",
}));

const Wrapper = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  spacing: "8px",
  flexWrap: "wrap",
  gap: "8px",
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.neutral[10],
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

const processingOptions: ChipOption[] = [
  {
    label: "0 USD",
    value: getProcessingAmount({
      amount: 0,
      modifier: "is equal to or less than",
    }),
  },
  {
    label: "1 - 1000 USD",
    value: getProcessingAmount({
      amount: 100,
      secondAmount: 100000,
      modifier: "is between",
    }),
  },
  {
    label: "> 1000 USD",
    value: getProcessingAmount({
      amount: 100000,
      modifier: "greater than",
    }),
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const riskLevelArray: Partial<SelectorOption>[] = [
  {
    value: "low",
    id: "low",
    label: "Low",
  },
  {
    value: "medium",
    id: "medium",
    label: "Medium",
  },
  {
    value: "high",
    id: "high",
    label: "High",
  },
  {
    value: "critical",
    id: "critical",
    label: "Critical",
  },
  {
    value: "Suspended",
    id: "Suspended",
    label: "Suspended",
  },
];

const riskStatusArray = [
  {
    label: "Routine monitoring",
    value: "routine_monitoring",
    Icon: CheckMarkIcon,
  },
  {
    label: "Alerted",
    value: "alerted",
    Icon: Siren,
  },
  {
    label: "Active monitoring",
    value: "active_monitoring",
    Icon: DetectiveIcon,
  },
];

const merchantRiskCategoryArray: Partial<SelectorOption>[] = [
  {
    label: "Normal",
    id: "normal",
    value: "Normal",
  },
  {
    label: "High",
    id: "high",
    value: "High",
  },
  {
    label: "Restricted",
    id: "restricted",
    value: "Restricted",
  },
];
