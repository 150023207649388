import { memo } from "react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { AppBar as AppBarMui, IconButton, Stack, Toolbar } from "@mui/material";
import MenuTwo from "@assets/iconsV2/MenuTwo";
import GiveText from "@shared/Text/GiveText";
import UserSection from "./UserSection";
import { MASQUERADE_SNACKBAR_HEIGHT } from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { rebrandedPages } from "@constants/constants";
import { palette } from "@theme/colors";

const APP_BAR_HEIGHT = 64;

type TParams = {
  toggleDrawer: () => void;
  availableBalance: string;
};

const MobileNavBar = ({ toggleDrawer, availableBalance }: TParams) => {
  const { palette } = useAppTheme();
  const { isMasqueradeMode } = useMasqueradeReducer();
  const { isRebrandingEnabled, isRebrandingTableEnabled } =
    useGetFeatureFlagValues();
  const { isMobileView } = useCustomThemeV2();
  const pageRebranded = rebrandedPages.includes(location.pathname);

  const showMobileNavBarOverlay =
    isMobileView &&
    isRebrandingEnabled &&
    ((!isRebrandingTableEnabled && pageRebranded) || !pageRebranded);

  return (
    <AppBar
      id="slide-me2"
      elevation={0}
      isMasqueradeMode={isMasqueradeMode}
      showMobileNavBarOverlay={showMobileNavBarOverlay}
    >
      <Container>
        <Stack direction="row" spacing="16px">
          <IconButton
            size="large"
            onClick={toggleDrawer}
            version="two"
            sx={{ padding: 0 }}
          >
            <MenuTwo fill={palette.text.primary} />
          </IconButton>
          <Stack>
            <GiveText variant="bodyS" color="primary">
              {availableBalance}
            </GiveText>
            <GiveText variant="bodyXS" color="secondary">
              Balance (USD)
            </GiveText>
          </Stack>
        </Stack>
        <UserSection isAppBar />
      </Container>
    </AppBar>
  );
};
export default memo(MobileNavBar);

const AppBar = styled(AppBarMui)<{
  isMasqueradeMode: boolean;
  showMobileNavBarOverlay: boolean;
}>(({ theme, isMasqueradeMode, showMobileNavBarOverlay }) => ({
  top: isMasqueradeMode ? `${MASQUERADE_SNACKBAR_HEIGHT}px` : 0,
  height: APP_BAR_HEIGHT,
  width: "100%",
  borderBottom: `1px solid transparent`,
  backgroundColor: showMobileNavBarOverlay
    ? palette.background.newWhite
    : "transparent",
  "&.overlay-enabled": {
    borderBottom: `1px solid ${theme.palette.border?.primary}`,
    backgroundColor: theme.palette.surface?.primary,
  },
}));

const Container = styled(Toolbar)({
  padding: "16px 20px",
  justifyContent: "space-between",
  alignItems: "center",
});
