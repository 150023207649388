import { useInfiniteQuery } from "react-query";
import { queryFunctionBuilder } from "../api";
import { useCallback, useRef, useEffect } from "react";

export const DEFAULT_QUERY_CONFIG = {
  staleTime: 1000 * 60 * 0.5,
  cacheTime: 1000 * 60 * 5,
  refetchOnReconnect: false,
  refetchOnMount: false,
};

export const useGetInfiniteTransactionsV2 = (queryParams: any, config: any) => {
  return useInfiniteQuery(config.queryKey, queryFunctionBuilder(queryParams), {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextCursor;
    },
    ...(typeof config.enabled === "boolean" && {
      enabled: config.enabled,
      ...DEFAULT_QUERY_CONFIG,
    }),
  });
};

export const useGetInfiniteTransactions = (queryParams: any, config: any) => {
  // this is a hack,
  // when we invalidate/refetch queries,
  // useInfiniteQuery uses the very first instance of query function provided to it
  // this workaround is to keep a stable function reeference
  // and make sure the query function is always updated with the latest search params
  // the correct solution is to pass config.queryKey as an array of [queryKey, queryParams]
  // but seems this can be a tech debt item because it can require a lot of changes and side effects

  const queryParamsRef = useRef(queryParams);

  useEffect(() => {
    queryParamsRef.current = queryParams;
  }, [queryParams]);

  const stableQueryFunction = useCallback((context: any) => {
    return queryFunctionBuilder(queryParamsRef.current)(context);
  }, []);

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    remove,
  } = useInfiniteQuery(config.queryKey, stableQueryFunction, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextCursor;
    },
    ...(typeof config.enabled === "boolean" && {
      enabled: config.enabled,
      ...DEFAULT_QUERY_CONFIG,
    }),
    refetchOnMount: false,
  });

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    remove,
  };
};

export type useGetInfiniteTransactionsReturnType = ReturnType<
  typeof useGetInfiniteTransactions
>;
