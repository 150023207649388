import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { FormDataType } from "@sections/PayBuilder/utils";
import { AutoCompleteTime } from "./AutoCompleteTime";
import { Controller, UseControllerProps } from "react-hook-form";

export function ControlledAutoCompleteTime({
  name,
}: {
  name: UseControllerProps<FormDataType>["name"];
}) {
  const { methods } = usePayBuilderForm();

  return (
    <Controller
      control={methods.control}
      name={name}
      render={({ field: { ref, value }, fieldState: { error } }) => {
        return (
          <AutoCompleteTime
            value={value}
            onChange={(_value: string) => {
              methods.setValue(name, _value, {
                shouldValidate: true,
              });
            }}
            error={error}
          />
        );
      }}
    />
  );
}
