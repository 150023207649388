import ChartBarIcon from "@assets/iconsV2/ChartBarIcon";
import { Box, Stack } from "@mui/material";
import { Icon } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import GiveLargeIconButton from "@shared/LargeIconButton/GiveLargeIconButton";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { parseAmount } from "@utils/index";
import { capitalize } from "lodash";
import React from "react";
import { GiveTooltipProps } from "@shared/Tooltip/GiveTooltip.types";
import { HiddenComponent } from "containers/HiddenComponent";
type Stat = {
  isAmount?: boolean;
  title: string;
  value: string | number;
  percent?: boolean;
};
type Props = {
  title: string;
  mainStat: Stat;
  parseStats: Stat[];
  onOpenAnalytics: (() => Promise<unknown>) | undefined;
  mainAction: {
    label: string;
    icon: Icon;
    tooltipProps: Partial<GiveTooltipProps>;
    action: () => void;
    disabled?: boolean;
    hidden?: boolean;
  };
};

const ProductBannerBase = ({
  title,
  mainAction,
  mainStat,
  parseStats,
  onOpenAnalytics,
}: Props) => {
  const { isWideView } = useCustomThemeV2();

  return (
    <StyledWrapper>
      <StyledTitle variant="h1">{title}</StyledTitle>
      <Stack
        direction={!isWideView ? "column" : "row"}
        alignItems={!isWideView ? "flex-start" : "flex-end"}
        justifyContent="space-between"
        spacing={3}
      >
        <Pair
          value={parseAmount(mainStat?.value)}
          label={mainStat.title}
          isAmount={mainStat.isAmount}
          textAlign="left"
        />
        <StatsContainer>
          {parseStats.map((stat: Stat) => (
            <Pair
              key={stat?.title}
              value={stat?.value}
              label={capitalize(stat?.title)}
              percent={stat?.percent || false}
              isAmount={stat?.isAmount}
              flexRow={!isWideView}
              textAlign="right"
            />
          ))}
          {isWideView && (
            <>
              <GiveLargeIconButton
                Icon={ChartBarIcon}
                variant="secondary"
                label="View Analytics"
                onClick={onOpenAnalytics}
              />
              <HiddenComponent hidden={mainAction.hidden}>
                <GiveLargeIconButton
                  Icon={mainAction.icon}
                  label={mainAction.label}
                  onClick={mainAction.action}
                  tooltipProps={mainAction.tooltipProps}
                  disabled={mainAction.disabled}
                />
              </HiddenComponent>
            </>
          )}
        </StatsContainer>
        {!isWideView && (
          <NarrowViewActions gap="12px" width="100%">
            <GiveButton
              size="large"
              variant="outline"
              label="View Analytics"
              onClick={onOpenAnalytics}
              fullWidth
            />
            <HiddenComponent hidden={mainAction.hidden}>
              <GiveButton
                size="large"
                variant="filled"
                label={mainAction.label}
                onClick={mainAction.action}
                disabled={mainAction.disabled}
                fullWidth
              />
            </HiddenComponent>
          </NarrowViewActions>
        )}
      </Stack>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  padding: "120px 0 0 0",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  [theme.breakpoints.down("new_lg")]: {
    gap: "24px",
    padding: "76px 0 24px 0",
  },
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "40px",
  alignItems: "flex-end",
  [theme.breakpoints.down("new_lg")]: {
    width: "100%",
    gap: "12px",
    alignItems: "flex-start",
    flexDirection: "column",
  },
}));

const StyledTitle = styled(GiveText)(({ theme }) => ({
  background: theme.palette.gradient["ocean-blue"]?.default,
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  width: "fit-content",
}));

const NarrowViewActions = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  [theme.breakpoints.down("v2_sm")]: {
    flexDirection: "column",
  },
}));

type PairProps = {
  label: string;
  value: string | number;
  percent?: boolean;
  isAmount?: boolean;
  flexRow?: boolean;
  textAlign?: "left" | "right";
};

const Pair = ({
  label,
  value,
  isAmount,
  percent,
  flexRow,
  textAlign,
}: PairProps) => {
  const suffix = percent ? " (%)" : isAmount ? " (USD)" : "";

  return (
    <Stack
      spacing={1}
      {...(flexRow && {
        direction: "row-reverse",
        justifyContent: "space-between",
        width: "100%",
      })}
    >
      <GiveText
        variant={flexRow ? "bodyS" : "h4"}
        fontWeight={flexRow ? "350" : "300"}
        textAlign={textAlign}
      >
        {isAmount || percent ? parseAmount(value) : value}
      </GiveText>
      <GiveText textAlign={textAlign} variant="bodyS" color="secondary">
        {label}
        {suffix}
      </GiveText>
    </Stack>
  );
};

export default ProductBannerBase;
