import { useState } from "react";
import { Stack } from "@mui/material";
import { ArrowLeft, X } from "@phosphor-icons/react";

import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { SidePanelHeaderBase } from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeader";
import GiveText from "@shared/Text/GiveText";
import {
  SidePanelBodyWrapper,
  SidePanelContainerWrapper,
} from "@shared/SidePanel/SidePanelAtoms";
import AgreementPanel from "./Agreement/AgreementPanel";
import SnapshotPanel from "./Snapshot/Snapshot";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";
import { JoinedProps, StateType, tabs, MainPages } from "./agreements.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveLink from "@shared/Link/GiveLink";
import { useAppTheme } from "@theme/v2/Provider";

interface Props {
  handleClose: () => void;
}

const sections = (props: JoinedProps) => ({
  Agreement: <AgreementPanel {...props} />,
  Snapshot: <SnapshotPanel {...props} />,
});

const defaultValues: StateType = {
  mainPage: "Agreement" as MainPages,
  tab: "merchantApplication" as tabs,
};

const AgreementAndSnapshot = ({ handleClose }: Props) => {
  const [page, setPage] = useState<StateType>(defaultValues);
  const { mainPage, tab } = page;
  const { isMobileView } = useCustomThemeV2();
  const { palette } = useAppTheme();

  const resetPage = () => setPage(defaultValues);

  const props = { setPage, page };
  const isNextPage = mainPage !== "Agreement";

  const tabsData = [
    {
      label: "Merchant Application",
      onClick: () =>
        setPage((prev) => ({ ...prev, tab: "merchantApplication" })),
      isActive: tab === "merchantApplication",
    },
    {
      label: "Fees Schedule",
      onClick: () => setPage((prev) => ({ ...prev, tab: "fees" })),
      isActive: tab === "fees",
    },
    {
      label: "Refund Policy",
      onClick: () => setPage((prev) => ({ ...prev, tab: "refund" })),
      isActive: tab === "refund",
    },
  ];

  return (
    <SidePanelContainerWrapper>
      <SidePanelHeaderBase
        leftItems={
          //TODO replace this when the breadcrump reusable componenet will be implemented for storybook
          <Stack gap="8px" alignItems="center" flexDirection="row">
            {isNextPage && (
              <GiveIconButton
                variant="ghost"
                Icon={ArrowLeft}
                size="small"
                onClick={() => resetPage()}
              />
            )}
            {!isMobileView || (isMobileView && !isNextPage) ? (
              <GiveLink
                component="button"
                color={isNextPage ? "secondary" : "inherit"}
                disabled={!isNextPage}
                onClick={resetPage}
                sx={{
                  color: isNextPage
                    ? `${palette.text.secondary} !important`
                    : `${palette.text.primary} !important`,
                  "&:hover": {
                    color: `${palette.text.primary} !important`,
                  },
                }}
              >
                Agreement
              </GiveLink>
            ) : (
              <></>
            )}
            {isNextPage && !isMobileView && <GiveText>/</GiveText>}
            {isNextPage && (
              <GiveText variant="bodyS" color="primary">
                Snapshot
              </GiveText>
            )}
          </Stack>
        }
        rightItems={
          <GiveIconButton
            Icon={X}
            variant="ghost"
            onClick={isNextPage ? resetPage : handleClose}
          />
        }
        {...(isNextPage && { tabsData })}
      />
      <SidePanelBodyWrapper>{sections(props)[mainPage]}</SidePanelBodyWrapper>
    </SidePanelContainerWrapper>
  );
};

export default AgreementAndSnapshot;
