import GiveListLoadingSkeletons from "@shared/GiveAnimatedList/GiveListLoadingSkeletons";
import { TableColumnType } from "componentsV2/Table/Table.types";

interface Props {
  columns: TableColumnType[];
}

export default function GiveTableLoadingPlaceholder({ columns }: Props) {
  return (
    <tbody>
      <GiveListLoadingSkeletons columns={columns} />
    </tbody>
  );
}
