import React from "react";
import {
  ActionButton,
  ActionsContainer,
  ListSkeleton,
  OpenCodeEditorButton,
} from "./AddPermissionModal.styles";
import { Box, Stack, styled } from "@mui/material";
import {
  PermissionItem,
  PermissionSearchBar,
} from "./AddPermissionModal.atoms";
import { CodeIconWithBar } from "@assets/icons";
import FilterPermissionList from "../FilterPermissionList";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NoResultsState from "@common/EmptyState/NoResultsState";
import useAddPermissionsModal from "../../hooks/useAddPermissionsModal";
import { TPermissionsData } from "../../types";
import { shouldBeHidden } from "@constants/constants";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { GroupedVirtuoso } from "react-virtuoso";
import { palette } from "@palette";
import { Text } from "@common/Text";

export interface IPermissionListProps {
  onClose: () => void;
  onAdd: (logs: TPermissionsData) => void;
  memberId: number;
}

const PermissionListContent = ({
  onClose,
  onAdd,
  setIsCodeEditor,
  memberId,
}: IPermissionListProps & {
  setIsCodeEditor: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    listMutated,
    clearChanges,
    logs,
    searchString,
    handleSearch,
    selectedGroup,
    filters,
    orderedKeys,
    handleSelect,
    isSearching,
    dataIsLoading,
    permissionsData,
    setPermissionStatus,
    filteredGroups,
  } = useAddPermissionsModal(memberId);

  const isAddAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MEMBER,
      RESOURCE_BASE.ACCESS_POLICIES,
    ),
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const onCancel = () => {
    clearChanges();
    onClose();
  };

  const onSubmit = () => onAdd(logs);

  const lengths = Object.keys(filteredGroups).map(
    (k) => filteredGroups[k].length,
  );
  
  return (
    <>
      <PermissionSearchBar value={searchString} onChange={handleSearch} />
      <Stack
        padding="16px 12px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <FilterPermissionList
          defaultValue={selectedGroup}
          filterData={filters}
          onHandleSelect={handleSelect}
        />
        {!shouldBeHidden.customPermission && (
          <OpenCodeEditorButton
            background="tertiary"
            onClick={() => setIsCodeEditor(true)}
            disabled={!isAddAllowed}
            tooltipProps={{
              show: !isAddAllowed,
              message: CREATE_DENY_MESSAGE,
            }}
          >
            <CodeIconWithBar fill="#6D9CF8" /> Add Custom Permission
          </OpenCodeEditorButton>
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="stretch"
        flexGrow={1}
        sx={{ overflowY: "scroll" }}
      >
        {dataIsLoading || isSearching ? (
          <ListSkeleton />
        ) : !orderedKeys.length ? (
          <NoResultsState searchQuery={searchString} />
        ) : (
          <GroupedVirtuoso
            groupCounts={lengths}
            style={{ height: 400 }}
            groupContent={(index) => {
              const text = () => {
                switch (index) {
                  case 0:
                    return "Merchant";
                  case 1:
                    return "Provider";
                  case 2:
                    return "Acquirer";
                  case 3:
                    return "User";
                  default:
                    return "";
                }
              };
              return (
                <StyledBox>
                  <Tag>{text()}&nbsp;permissions</Tag>
                </StyledBox>
              );
            }}
            itemContent={(i, group) => {
              let index = i;

              if (group === 1) {
                index = i - lengths[0];
              }
              if (group === 2) {
                index = i - lengths[0] - lengths[1];
              }
              if (group === 3) {
                index = i - lengths[0] - lengths[1] - lengths[2];
              }
              const key =
                filteredGroups[Object.keys(filteredGroups)[group]][index];
              const data = permissionsData.data[key];
              const { permissionName, description, isAttached } = data;
              return (
                <PermissionItem
                  permissionName={permissionName}
                  description={description}
                  assigned={isAttached}
                  onClick={() => setPermissionStatus(key, data)}
                />
              );
            }}
          />
        )}
      </Stack>
      {listMutated && (
        <FadeUpWrapper delay={200}>
          <ActionsContainer>
            <ActionButton background="tertiary" size="small" onClick={onCancel}>
              Cancel
            </ActionButton>
            <ActionButton background="primary" size="small" onClick={onSubmit}>
              Save
            </ActionButton>
          </ActionsContainer>
        </FadeUpWrapper>
      )}
    </>
  );
};

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#FBFBFB",
  display: "flex",
  alignItems: "center",
  height: 32,
  borderBottom: `1px solid ${palette.liftedWhite[100]}`,
}));
export default PermissionListContent;

const Tag = styled(Text)(() => ({
  padding: "2px 8px",
  borderRadius: "4px",
  background: palette.liftedWhite[100],
  color: palette.black[100],
  fontSize: "12px",
  lineHeight: "14.4px",
  marginLeft: "8px",
  marginRight: "auto",
  width: "fit-content",
  height: "fit-content",
}));
