import { Box, Divider, Stack } from "@mui/material";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import {
  ArrowLeft,
  ArrowRight,
  Circle,
  DownloadSimple,
  MagnifyingGlass,
  Minus,
  Plus,
  Trash,
  X,
} from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import useImagePreviewer from "@common/FilePreview/hooks/useImagePreviewer";
import { TDocument } from "@common/FilePreview/types";
import { FILE_PREVIEW_MODAL_V2 } from "modals/modal_names";
import { CustomTheme } from "@theme/v2/palette.interface";
import useDocumentListV2 from "./hooks/useDocumentListV2";
import {
  deleteDocument,
  downloadDocument,
} from "@hooks/common/documents/utils";
import { useQueryClient } from "react-query";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "features/Merchants/MerchantSidePanel/constants";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import usePdfPreviewerV2 from "./hooks/usePdfPreviewerV2";
import GiveText from "@shared/Text/GiveText";

type Props = {
  file: TDocument;
  documentList: TDocument[];
  isDeleteHidden?: boolean;
  entityId: number;
};

const GiveFilePreviewModal = NiceModal.create(
  ({ file, documentList, isDeleteHidden = false, entityId }: Props) => {
    const queryClient = useQueryClient();
    const theme = useAppTheme();
    const { isMobileView } = useCustomThemeV2();
    const { visible, remove: removeModal } = useModal(FILE_PREVIEW_MODAL_V2);
    const { currentFile, currentIndex, navigation } = useDocumentListV2(
      documentList,
      file,
      removeModal,
    );
    const { name } = currentFile || {};
    const { changeFile, showNext, showPrev } = navigation || {};
    const hasMultipleFiles = documentList?.length > 1;
    const isPdf = currentFile?.type === "pdf";

    const {
      Previewer,
      onZoom: onPdfZoom,
      toolbar,
      toggleSearch,
      isSearchActive,
    } = usePdfPreviewerV2({
      file: currentFile as TDocument,
      customDocumentWrapperStyles: pdfPreviewStyles,
    });

    const { Previewer: ImagePreviewer, onZoom: onImageZoom } =
      useImagePreviewer({
        file: currentFile as TDocument,
        customImageStyle: {
          maxWidth: "100%",
          maxHeight: "100%",
          transformOrigin: "center center",
        },
      });

    const previewer = isPdf ? Previewer : ImagePreviewer;

    const handleDeleteDocument = (file: TDocument | null) => {
      if (file && entityId) {
        deleteDocument(entityId, { id: file.id, fileName: file.name }, () =>
          queryClient.refetchQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET),
        );
      }
    };

    const handleDownloadDocument = (file: TDocument | null) => {
      if (file)
        downloadDocument({
          fileName: file.name,
          fileURL: file.URL,
        });
    };

    const CustomHeader = (
      <Stack
        direction="row"
        gap="16px"
        alignItems="center"
        padding="16px 20px"
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${theme.palette.border?.primary}`,
        }}
      >
        <GiveText variant="bodyS" color="secondary" flex={1}>
          {name}
        </GiveText>
        <Stack direction="row" gap="12px" alignItems="center">
          {isPdf && (
            <GiveIconButton
              bgColor="solidWhite"
              Icon={MagnifyingGlass}
              onClick={() => toggleSearch?.(!isSearchActive)}
            />
          )}
          <GiveIconButton bgColor="solidWhite" Icon={X} onClick={removeModal} />
        </Stack>
      </Stack>
    );

    const CustomFooter = (
      <Stack
        direction="row"
        gap="16px"
        alignItems="center"
        justifyContent={isMobileView ? "flex-end" : "center"}
        position="relative"
        width="100%"
      >
        <GiveIconButton
          Icon={Minus}
          onClick={() => (isPdf ? onPdfZoom("out") : onImageZoom("out"))}
          bgColor="solidWhite"
        />
        <GiveIconButton
          Icon={Plus}
          onClick={() => (isPdf ? onPdfZoom("in") : onImageZoom("in"))}
          bgColor="solidWhite"
        />
        <Divider orientation="vertical" flexItem />
        <GiveIconButton
          Icon={DownloadSimple}
          onClick={() => handleDownloadDocument(currentFile)}
          bgColor="solidWhite"
        />
        {!isDeleteHidden && (
          <GiveIconButton
            Icon={Trash}
            color={theme.palette.primitive?.error[50]}
            onClick={() => {
              handleDeleteDocument(currentFile);
            }}
            bgColor="solidWhite"
          />
        )}
        {isPdf && toolbar.primary.content}
      </Stack>
    );

    const ControlComponents = (
      <>
        <StyledGiveIconButton
          Icon={ArrowLeft}
          bgColor="solidWhite"
          left="16px"
          onClick={() => changeFile("prev")}
          disabled={!showPrev}
        />
        <StyledGiveIconButton
          Icon={ArrowRight}
          bgColor="solidWhite"
          right="16px"
          onClick={() => changeFile("next")}
          disabled={!showNext}
        />
        <NavContainer>
          {documentList.map((_, index) => (
            <NavDot
              theme={theme}
              key={index}
              selected={index === currentIndex}
              weight="fill"
            />
          ))}
        </NavContainer>
      </>
    );

    return (
      <GiveBaseModal
        open={visible}
        title={name}
        width="1040px"
        height="100%"
        onClose={removeModal}
        closeIconProps={{
          bgColor: "solidWhite",
        }}
        customHeader={CustomHeader}
        customFooter={CustomFooter}
        customDialogStyle={customDialogStyles}
      >
        <Container>
          <FilePreviewWrapper>{previewer}</FilePreviewWrapper>
          {hasMultipleFiles && ControlComponents}
        </Container>
      </GiveBaseModal>
    );
  },
);

export default GiveFilePreviewModal;

const Container = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

const FilePreviewWrapper = styled(Box)(() => ({
  width: "100%",
  margin: "0 auto",
  height: "100%",
  minWidth: "20%",
  maxWidth: "100%",
  overflowY: "auto",
  touchAction: "auto",
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserDrag: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const pdfPreviewStyles = {
  width: "100%",
  height: "100%",
  "& .react-pdf__Document": {
    overflow: "hidden",
  },
  "& .react-pdf__Page__canvas": {
    margin: "0 auto",
  },
  "& .react-pdf__Page:first-of-type": {
    marginTop: "0px",
  },
};

const customDialogStyles = {
  "& .MuiDialog-paper": {
    "& .MuiDialogContent-root": {
      height: "60vh",
      padding: "0px !important",
    },
  },
};

const StyledGiveIconButton = styled(GiveIconButton)(
  ({ right, left }: { right?: string; left?: string }) => ({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
    right: right,
    left: left,
  }),
);

const NavContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "5px",
  alignItems: "center",
  position: "absolute",
  bottom: "24px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1,
  backgroundColor: theme.palette.surface?.["primary-transparent"],
  borderRadius: "30px",
  padding: "8px",
  backdropFilter: "blur(15px)",
  WebkitBackdropFilter: "blur(15px)",
}));

const NavDot = styled(Circle)(
  ({ theme, selected }: { theme: CustomTheme; selected: boolean }) => ({
    width: "8px",
    height: "8px",
    color: theme.palette.background.dimmedWhite,
    opacity: selected ? 1 : 0.5,
    padding: 0,
  }),
);
