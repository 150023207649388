import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import { Text } from "@common/Text";
import PaymentTypeCard from "@components/CreateCampaign/components/PaymentTypeCard";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import useGenerateTypeList from "@hooks/useGenerateTypeList";
import { Box, Grid } from "@mui/material";
import { palette } from "@palette";
import {
  CREATE_EVENT_MODAL,
  CREATE_FUNDRAISER_MODAL,
  CREATE_INVOICE_MODAL,
  CREATE_MEMBERSHIP_MODAL,
  CREATE_PAYMENT_FORM_MODAL,
  CREATE_SWEEPSTAKES_MODAL,
} from "modals/modal_names";
import { useState } from "react";
import {
  CampaignMapperValues,
  useCreateCampaignFn,
} from "./useCreateCampaignFn";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
import PaymentFormSelectionPanel from "../PaymentFormV2/PaymentFormSelectionPanel";

const CreatePreBuildFormPanel = () => {
  const [campaign, setCampaign] = useState<CampaignMapperValues>("standard");
  const handleSelectType = (name: CampaignMapperValues) => {
    setCampaign(name);
  };
  const { createCampaignFn } = useCreateCampaignFn();
  const { typeList } = useGenerateTypeList();
  const modal = useModal();

  const onClose = () => {
    modal.hide();
  };

  const fn = (name: CampaignMapperValues) => {
    onClose();

    switch (name) {
      case "event":
        return createCampaignFn<"event">(CREATE_EVENT_MODAL, "event");
      case "fundraiser":
        return createCampaignFn<"fundraiser">(
          CREATE_FUNDRAISER_MODAL,
          "fundraiser",
        );
      case "membership":
        return createCampaignFn<"membership">(
          CREATE_MEMBERSHIP_MODAL,
          "membership",
        );
      case "sweepstake":
        return createCampaignFn<"sweepstake">(
          CREATE_SWEEPSTAKES_MODAL,
          "sweepstake",
        );
      case "invoice":
        return createCampaignFn<"invoice">(CREATE_INVOICE_MODAL, "invoice");
      case "standard":
        return createCampaignFn<"standard">(
          CREATE_PAYMENT_FORM_MODAL,
          "standard",
        );
      default:
        return NiceModal.show(CREATE_PAYMENT_FORM_MODAL);
    }
  };

  return (
    <ModalDrawer
      onModalClose={onClose}
      isPreBuildFormBuilder
      HeaderProps={{
        title: "Create Payment Form",
      }}
      primaryAction={{
        onClick: () => fn(campaign),
        disabled: false,
        label: "Next",
        form: "create-payment-form",
      }}
    >
      <Box component="form" id="create-payment-form">
        <Text
          sx={{
            color: palette.black[200],
            fontSize: "32px",
            fontWeight: "350",
            lineHeight: "120%",
            textAlign: "left",
            mb: 4,
          }}
        >
          What type of Payment Form are you creating?
        </Text>
        <Grid container spacing={1.2}>
          {typeList.map((type, index) => {
            return (
              <PaymentTypeCard
                key={
                  type.name === "Standard" ? "Standard Payment Form" : type.name
                }
                handleSelectType={handleSelectType}
                selected={campaign ? campaign === type.key : index === 0}
                type={type}
              />
            );
          })}
        </Grid>
      </Box>
    </ModalDrawer>
  );
};

const CreatePreBuildFormModal = NiceModal.create(() => {
  return (
    <FlaggedWrapper
      name={FeatureFlagKeys.REBRANDING}
      ActiveComponent={PaymentFormSelectionPanel}
      FallbackComponent={CreatePreBuildFormPanel}
    />
  );
});

export default CreatePreBuildFormModal;
