// Define the types for the tree structure
type Image = {
  url: string;
  position: "left" | "right";
};

type PageContent = {
  logo: string;
  heading: string;
  description: string;
  image?: Image;
  displayStatus: TFormStatus;
  productDisplayMode: "card" | "list";
};

export type Product = {
  id: string;
  thumbnail: string;
  amount: string;
  title: string;
  in_stock?: number | null;
  description: string | null;
  paymentType?: string;
  variantID: string;
};

export type TreeNode = {
  id: string;
  content: PageContent;
  children: Product[];
};

export type TFormStatus = "public" | "draft" | "private" | undefined;

export enum TFormStatusEnum {
  PUBLIC = "public",
  DRAFT = "draft",
}

export enum TFormSourceEnum {
  PUBLIC = "public",
  PREVIEW = "preview",
}