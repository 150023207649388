import { showMessage } from "@common/Toast";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useUploadCustomerImage } from "@hooks/merchant-api/customers/useUploadCustomerImage";
import { customInstance } from "@services/api";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useMutation, useQueryClient } from "react-query";

export default function useThemeManager() {
  const [inViewRef, inView] = useInView();
  const { handlePostImage } = useUploadCustomerImage();
  const { isMobileView } = useCustomTheme();
  const { merchantId } = useGetCurrentMerchantId();
  const defaultValues = {
    activeGradientColor: {
      light: defaultGradients[0],
      dark: defaultGradients[0],
      id: 1,
      name: "ocean_blue",
    },
    isDarkMode: false,
    images: {
      square: null,
      landscape: null,
    },
  };
  const queryClient = useQueryClient();
  const [customTheme, setCustomTheme] = useState(defaultValues);
  const onSelectGradientColor = (colors: {
    light: string;
    dark: string;
    id: number;
    name: string;
  }) => {
    setCustomTheme((p) => ({ ...p, activeGradientColor: colors }));
  };

  const setDarkMode = (status: boolean) =>
    setCustomTheme((p) => ({ ...p, isDarkMode: status }));
  const { data } = useGetMerchantById({
    onSuccess: (data: any) => {
      const theme = data?.theme;

      setCustomTheme((p) => ({
        ...p,
        activeGradientColor: {
          light: theme?.lightStyle
            ? theme?.lightStyle
            : p.activeGradientColor?.light,
          dark: theme?.darkStyle
            ? theme?.darkStyle
            : p.activeGradientColor?.dark,
          id: theme?.id,
          name: theme?.name,
        },
        images: {
          square: data?.imageURL || null,
          landscape: data?.landscapeImageURL || null,
        },
      }));
    },
  });

  const apiDefaultColor = useMemo(
    () => ({
      light: data?.theme?.lightStyle || defaultValues.activeGradientColor.light,
      dark: data?.theme?.darkStyle || defaultValues.activeGradientColor.dark,
      id: data?.theme?.id || defaultValues.activeGradientColor.id,
      name: data?.theme?.name || defaultValues.activeGradientColor.name,
    }),
    [data, defaultValues],
  );

  const isEqualDefault = isEqual(
    apiDefaultColor,
    customTheme.activeGradientColor,
  );
  const resetValues = () =>
    setCustomTheme((p) => ({ ...p, activeGradientColor: apiDefaultColor }));
  useEffect(() => {
    if (!isEqualDefault && !inView) resetValues();
  }, [inView, isEqualDefault]);

  const parseLinearGradientColor = useCallback(
    (mode?: "dark" | "light") => {
      const light =
        apiDefaultColor?.light || customTheme?.activeGradientColor?.light;
      const dark =
        apiDefaultColor?.dark || customTheme?.activeGradientColor?.dark;
      let linearGradient = customTheme?.isDarkMode ? dark : light;
      if (mode === "dark") {
        linearGradient = dark;
      } else if (mode === "light") {
        linearGradient = light;
      }
      return generateColors(linearGradient);
    },
    [apiDefaultColor, customTheme?.isDarkMode],
  );

  const colorChoices = useMemo(() => {
    const arr = [
      {
        title: "Ocean Blue",
        subTitle:
          "Striking palette that draws inspiration from the vibrant hues ocean.",
        linearColor: {
          light: defaultGradients[0],
          dark: defaultGradients[0],
          id: 1,
          name: "ocean_blue",
        },
      },
      {
        title: "Citrus Peel",
        subTitle:
          "Drawing from the citrus flamboyant colors, Citrus Peel embodies a joyful and lively palette.",
        linearColor: {
          light: defaultGradients[1],
          dark: defaultGradients[1],
          id: 2,
          name: "citrus_peel",
        },
      },
      {
        title: "Moon Purple",
        subTitle:
          "A captivating palette inspired by moonlit mystique and serenity.",
        linearColor: {
          light: defaultGradients[2],
          dark: defaultGradients[2],
          id: 3,
          name: "moon_purple",
        },
      },
      {
        title: "Custom Color",
        subTitle: "Choose your own palette.",
        isCustom: true,
        linearColor: {
          light: "",
          dark: "",
          id: 4,
          name: "Custom",
        },
      },
    ];
    const isPresentLight = arr.some(
      (obj) =>
        obj.linearColor.light === customTheme?.activeGradientColor?.light,
    );

    const isPresentDark = arr.some(
      (obj) => obj.linearColor.dark === customTheme?.activeGradientColor?.dark,
    );
    if (!isPresentLight) {
      arr[3].linearColor.light = customTheme?.activeGradientColor?.light;
    }
    if (!isPresentDark) {
      arr[3].linearColor.dark = customTheme?.activeGradientColor?.dark;
    }
    return arr;
  }, [customTheme?.activeGradientColor, isMobileView]);
  const { gradient, isLinearGradient } = useMemo(() => {
    const linearGradientRegex = /^linear-gradient/i;
    const gradient = customTheme?.isDarkMode
      ? apiDefaultColor?.dark || customTheme?.activeGradientColor?.dark
      : apiDefaultColor?.light || customTheme?.activeGradientColor?.light;
    const isLinearGradient = linearGradientRegex.test(gradient);
    return { gradient, isLinearGradient };
  }, [customTheme, apiDefaultColor]);

  const updateThemeMutation = useMutation(
    (data: any) => {
      return customInstance({
        url: `/merchants/${merchantId}`,
        method: "PATCH",
        data,
      });
    },
    {
      onSuccess(updatedData: any) {
        queryClient.setQueryData(
          [QKEY_GET_MERCHANT_BY_ID, merchantId],
          updatedData,
        );
        showMessage("Success", "Theme update successfully");
      },
      onError(error: any) {
        showMessage("Error", error?.response?.data?.message || error?.message);
      },
    },
  );

  const handleSaveTheme = () => {
    updateThemeMutation.mutate({
      ...(customTheme?.activeGradientColor?.id !== 4
        ? {
            uiThemeID: customTheme?.activeGradientColor?.id,
          }
        : {
            uiThemeName: customTheme?.activeGradientColor?.name,
            uiThemeDarkStyle: customTheme?.activeGradientColor?.dark?.trim(),
            uiThemeLightStyle: customTheme?.activeGradientColor?.light?.trim(),
          }),
    });
  };

  const updateLogo = useMutation(
    (data: any) => {
      return customInstance({
        url: `/merchants/${merchantId}`,
        method: "PATCH",
        data,
      });
    },
    {
      onSuccess() {
        queryClient.refetchQueries(`user`);
        showMessage("Success", "Logo update successfully");
      },
      onError(error: any) {
        showMessage("Error", error?.response?.data?.message || error?.message);
      },
    },
  );

  const handleUpdateImage = async ({
    type,
    file,
  }: {
    type: ImageType;
    file: any;
  }) => {
    const url = await handlePostImage(file);
    const payload =
      type === "landscape"
        ? {
            landscapeImageURL: url,
          }
        : {
            imageURL: url,
          };
    updateLogo.mutate(payload);
    setCustomTheme((p) => ({
      ...p,
      images: {
        ...p?.images,
        [type]: file,
      },
    }));
  };

  const handleDeleteImage = ({ type }: { type: ImageType }) => {
    const payload =
      type === "landscape"
        ? {
            landscapeImageURL: null,
          }
        : {
            imageURL: null,
          };
    updateLogo.mutate(payload);
    setCustomTheme((p) => ({
      ...p,
      images: {
        ...p?.images,
        [type]: null,
      },
    }));
  };
  return {
    ...customTheme,
    onSelectGradientColor,
    setDarkMode,
    parseLinearGradientColor,
    colorChoices,
    isEqualDefault,
    resetValues,
    inViewRef,
    gradient,
    isLinearGradient,
    handleSaveTheme,
    handleUpdateImage,
    handleDeleteImage,
    apiDefaultColor,
  };
}
export type ImageType = "landscape" | "square";
export const defaultGradients = [
  "linear-gradient(224deg, #72ECF0 0%, #528DDF 100%)",
  "linear-gradient(54deg, #FFAA6C 15.45%, #E04D79 89.48%)",
  "linear-gradient(224deg, #89A4EA 0.00%, #7459E9 100.00%)",
];
const regex =
  /^linear-gradient\(([\d.]{1,15})deg, (.{1,100}?) ([\d.]{1,15})%, (.{1,100}?) ([\d.]{1,15})%\)/;
export const generateColors = (gradientColor: string) => {
  const matches = gradientColor.match(regex);
  if (!matches || matches.length !== 6) {
    const color1 = gradientColor;
    const color2 = gradientColor;

    return [color1, color2];
  }

  const color1 = matches[2];
  const color2 = matches[4];

  return [color1, color2];
};

export const baseColor = "rgba(209,209,208,1)";
