import { Slider, Stack } from "@mui/material";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { Minus, Plus, Trash } from "@phosphor-icons/react";

import GiveText from "@shared/Text/GiveText";
import RotateLeft from "@assets/iconsV2/RotateLeft";

type ImageEditorToolbarProps = {
  zoom: number;
  minZoom: number;
  maxZoom: number;
  handleRotate: () => void;
  handleZoom: (isAdd?: boolean) => void;
  handleChangeSlider: (event: Event, zoom: number | number[]) => void;
  handleDelete: () => void;
  canDelete: boolean;
};

const ImageEditorToolbar = ({
  zoom,
  minZoom,
  maxZoom,
  handleRotate,
  handleZoom,
  handleChangeSlider,
  handleDelete,
  canDelete,
}: ImageEditorToolbarProps) => {
  const { palette } = useAppTheme();

  const isMaxZoom = zoom >= maxZoom;
  const isMinZoom = zoom <= minZoom;
  return (
    <Container>
      <SliderBox>
        <GiveIconButton
          Icon={Minus}
          color={palette.icon?.["icon-primary"]}
          size="small"
          variant="ghost"
          onClick={() => handleZoom()}
          disabled={isMinZoom}
        />

        <CustomSlider
          value={zoom}
          min={minZoom}
          max={maxZoom}
          step={0.1}
          aria-label="Zoom"
          onChange={handleChangeSlider}
        />

        <GiveIconButton
          Icon={Plus}
          color={palette.icon?.["icon-primary"]}
          size="small"
          variant="ghost"
          onClick={() => handleZoom(true)}
          disabled={isMaxZoom}
        />
      </SliderBox>

      <GiveText
        color="secondary"
        variant="bodyXS"
        textAlign="right"
        sx={{ width: "36px" }}
      >
        {zoom}%
      </GiveText>

      <Stack gap="12px" alignItems="center" flexDirection="row">
        <GiveIconButton
          CustomIcon={<RotateLeft fill={palette.text.primary} />}
          color={palette.icon?.["icon-primary"]}
          size="small"
          variant="ghost"
          onClick={() => handleRotate()}
        />

        {canDelete && (
          <GiveIconButton
            Icon={Trash}
            color={palette.primitive?.error[50]}
            size="small"
            variant="ghost"
            onClick={handleDelete}
          />
        )}
      </Stack>
    </Container>
  );
};

export default ImageEditorToolbar;

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "24px",
  [theme.breakpoints.down("v2_sm")]: {
    gap: "16px",
  },
}));

const SliderBox = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  [theme.breakpoints.down("v2_sm")]: {
    gap: "4px",
  },
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.border?.primary,
  height: 8,
  width: 280,
  [theme.breakpoints.down("v2_sm")]: {
    width: 120,
  },

  "& .MuiSlider-track": {
    border: "none",
    color: theme.palette.border?.active,
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primitive?.neutral[0],
    border: `1px solid ${theme.palette.border?.secondary}`,

    boxShadow: `0px 4px 8px 0px ${theme.palette.primitive?.neutral[10]}`,

    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },

  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));
