import { Fragment } from "react";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import { ISectionItem } from "../hooks/useGetSectionItems";
import { Grid } from "@mui/material";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import { GiveSectionItem } from "../GiveSectionItem";
import NiceModal from "@ebay/nice-modal-react";
import { EDIT_MERCHANT_INFO_MODAL_V2 } from "modals/modal_names";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { checkPortals } from "@utils/routing";

const MerchantInfoSection = ({ items }: { items: ISectionItem[] }) => {
  const { id, data } = useMerchantSidePanelContext();
  const { isAcquirerPortal } = checkPortals();
  const { openConversationHandler } = useConversationsModal();
  const { merchant_underwriting, modify_merchant } = useEnterprisePermissions();
  const isUpdateMCCAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const actions = {
    handleOpenConversation: (e: React.MouseEvent) => {
      e.stopPropagation();
      openConversationHandler({
        id: 0,
        name: "Merchant Info",
        paths: [],
      });
    },
    handleEdit: () =>
      NiceModal.show(EDIT_MERCHANT_INFO_MODAL_V2, {
        id,
        data: data.merchantInfo,
      }),
  };

  const disableEdit = !isUpdateMCCAllowed || !modify_merchant;

  const disabeledActions = {
    handleOpenConversation: {
      hidden: !merchant_underwriting && !isAcquirerPortal,
    },
    handleEdit: {
      disabled: disableEdit,
      tooltipTitle: EDIT_DENY_MESSAGE,
      disableTooltip: !disableEdit,
      hidden: disableEdit && !isAcquirerPortal,
    },
  };

  return (
    <SectionCardBase
      leftTitle="Merchant Information"
      actions={actions}
      disabeledActions={disabeledActions}
    >
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Fragment key={index}>
            {item.split && (
              <Grid container item xs={12} padding="0 0 0 16px !important">
                <GiveDivider />
              </Grid>
            )}
            <GiveSectionItem key={index} item={item} />
          </Fragment>
        ))}
      </Grid>
    </SectionCardBase>
  );
};

export default MerchantInfoSection;
