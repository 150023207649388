import { useCallback } from "react";

export const useHandleScroll = () => {
  const onScroll: React.UIEventHandler<HTMLDivElement> = useCallback((e) => {
    const target = e.currentTarget;
    const div = document.getElementById("slide-me");
    const bar = document.getElementById("slide-me2");
    if (div) {
      div.style.transform = `translateY(${-target.scrollTop}px)`;
    }
    if (bar) {
      if (target.scrollTop > 1) {
        bar.classList.add("overlay-enabled");
      } else {
        bar.classList.remove("overlay-enabled");
      }
    }
  }, []);

  return { onScroll };
};
