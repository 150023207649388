import { useNavigate, useParams } from "react-router-dom";
import GiveButton from "@shared/Button/GiveButton";
import { useAddToCart } from "@hooks/merchant-api/cart";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";
import CheckoutButtonWithSpinner from "@sections/PayBuilder/items/CheckoutButtonWithSpinner";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import { TFormStatusEnum } from "./types";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { ModalHolder, useModal } from "@ebay/nice-modal-react";
import { CART_PREVIEW_MODAL } from "modals/modal_names";

export function CheckoutNavButton({ fullWidth }: { fullWidth?: boolean }) {
  const navigate = useNavigate();
  const {
    cartItems,
    setOutOfStockItemIds,
    outOfStockItemIds,
    addToCart,
    removeFromCart,
    subTotal,
  } = useCart();
  const { id } = useParams();
  const { isLoading, addToCartHandler } = useAddToCart({
    showModal: false,
    newCartItems: cartItems,
  });

  const { parsedValues } = usePayBuilderForm();
  const { accentColor, checkoutContent } = parsedValues;
  const { displayStatus, screenStates } = usePayBuilderContext();
  const { isMobileView } = screenStates;
  const isCustomer = displayStatus === TFormStatusEnum.PUBLIC;

  const sxW = fullWidth
    ? {
        minWidth: isMobileView ? "200px" : "340px",
      }
    : {
        maxWidth: isMobileView ? "150px" : "300px",
      };
  const cartModalHandler = useModal(CART_PREVIEW_MODAL);
  return (
    <CheckoutButtonWithSpinner isLoading={isCustomer ? isLoading : false}>
      <>
        <GiveTooltip
          title="Please remove items that are out of stock before completing payment"
          color="default"
          disableHoverListener={outOfStockItemIds.length === 0}
        >
          <GiveButton
            label={checkoutContent}
            size="large"
            onClick={
              isCustomer
                ? async () => {
                    const isCheckoutDisallowed = await addToCartHandler();
                    if (
                      Array.isArray(isCheckoutDisallowed) &&
                      isCheckoutDisallowed?.length
                    ) {
                      setOutOfStockItemIds(isCheckoutDisallowed);
                      cartModalHandler.show();
                    }

                    if (!isCheckoutDisallowed) navigate(`/${id}/checkout`);
                  }
                : undefined
            }
            sx={{
              ...sxW,
              height: "42px",
              background: accentColor,
              borderColor: accentColor,
              flex: 1,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",

              display: "inline-block",
              position: "relative",
              "&:hover span": {
                animation: "scroll-text 5s linear infinite",
              },
              "@keyframes scroll-text": {
                "0%": { transform: "translateX(0)" },
                "100%": { transform: "translateX(-100%)" },
              },
            }}
            disabled={outOfStockItemIds.length > 0}
          />
        </GiveTooltip>

        <ModalHolder
          modal={CART_PREVIEW_MODAL}
          handler={cartModalHandler}
          cartItems={cartItems}
          totalAmount={subTotal}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
      </>
    </CheckoutButtonWithSpinner>
  );
}
