import MenuIcon2 from "@assets/icons/MenuIcon2";
import { EditIconV2, ShareIconUpV2 } from "@assets/icons/RebrandedIcons";
import { Button } from "@common/Button";
import Figure from "@common/Figure";
import { Text } from "@common/Text";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Box, Divider, Grid, List, Stack, StackProps } from "@mui/material";
import { palette } from "@palette";
import moment from "moment";
import React, { memo, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { EndsAt, StartsAt } from "./DateLabel";
import { CAMPAIGN_PANEL_WIDTH } from "./useCampaignModal";
import { PaperIcon } from "@assets/emptyStatesIcons";
import CustomArrowRight from "@assets/icons/RebrandedIcons/ArrowRight";
import { EmptyState } from "@common/EmptyState";
import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { VerticalBarChart } from "@components/charts";
import EmptyChart from "@components/charts/EmptyStates/EmptyChart";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";
import { CampaignInventoryItems } from "./CampaignInventoryItems";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";
import { DEADLINE_REACHED_MESSAGE } from "./constants";
import { BODY_DATA, VARIANT } from "./types";
import { WrappedText } from "@common/Text/StyledText";
import { Palette } from "@phosphor-icons/react";

interface Props {
  handleEdit: () => void;
  handleShare: () => void;
  BodyData: BODY_DATA;
  handleExpand: () => void;
  isloading: boolean;
  chooseTransaction: (id: string) => void;
  selectedTransaction: string | null;
  hasEnded: boolean;
  handleEditFromStyle: () => void;
  isEvent: boolean;
  isEditFormStyleDisabled: boolean;
}

const campaignInfoSection = (
  info: { name: string; value: string | number }[],
) => {
  return (
    <Stack direction="row" spacing={8}>
      {info.map(({ name, value }) => {
        return (
          <Stack spacing={1} key={name}>
            <Text color={palette.neutral[80]} fontWeight="book">
              {name}
            </Text>
            <Text color={palette.neutral[80]} fontSize="24px" fontWeight="book">
              {value}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

function Body({
  handleEdit,
  handleShare,
  BodyData,
  handleExpand,
  isloading,
  chooseTransaction,
  selectedTransaction,
  hasEnded,
  handleEditFromStyle,
  isEvent,
  isEditFormStyleDisabled,
}: Props) {
  const { isADBEnabled } = useGetFeatureFlagValues();
  const bottomRef = useRef<any>(null);
  const [inViewRef, inView] = useInView();

  const setRefs = React.useCallback(
    (node: HTMLElement | null) => {
      bottomRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const scrollToBottom = () => {
    if (!bottomRef.current) return;
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const { gradient } = useThemeContext();

  const FigureComponent = useMemo(
    () => (
      <Stack direction="column" gap="4px">
        {isEvent && (
          <Text color={palette.neutral[80]} fontSize="18px">
            Statistics
          </Text>
        )}
        <Text
          fontWeight="book"
          color={isEvent ? palette.neutral[80] : palette.neutral[70]}
          fontSize="14px"
          lineHeight="16.8px"
        >
          {isEvent ? "Total Sales (USD)" : "Total (USD)"}
        </Text>
        <Figure
          value={BodyData?.total}
          color={gradient}
          variant="h3"
          lineHeight="normal"
          fontWeight="book"
          isAmount
        />
      </Stack>
    ),
    [BodyData?.total, gradient],
  );

  const { isEditProductAllowed } = useProductPermission();

  const campaignGeneralInfo = [
    {
      name: "Average (USD)",
      value: BodyData.averageTransactions,
    },
    {
      name: "Purchases",
      value: BodyData.purchases,
    },
    {
      name: "Visitors",
      value: BodyData.Visitors,
    },
    {
      name: "Conversion",
      value: `${BodyData.conversion}%`,
    },
  ];

  const campaignTicketsInfo = [
    {
      name: "Avg. Purchase",
      value: BodyData.averageTotalSoldVariants,
    },
    {
      name: "Sale Rate",
      value: `${BodyData.totalVariantsPurchasesRate}%`,
    },
    {
      name: "Available",
      value: BodyData.totalAvailableVariants,
    },
    {
      name: "Sold",
      value: BodyData.totalSoldVariants,
    },
  ];

  const sectionsList = [
    {
      section: (
        <>
          <Text
            color={palette.neutral[80]}
            lineHeight="40px"
            fontSize="32px"
            fontWeight="light"
          >
            {BodyData.name}
          </Text>
          <Text
            fontSize="12px"
            lineHeight="14px"
            color={palette.neutral[70]}
            fontWeight="book"
            mt={1}
          >
            Created on{" "}
            <span
              style={{
                color: palette.neutral[80],
                fontWeight: 400,
              }}
            >
              {moment.unix(BodyData.createdAt).format("MM/DD/YYYY")}
            </span>
          </Text>
        </>
      ),
      delay: 100,
    },
    {
      section: (
        <>
          {BodyData.campaignEndsAt && BodyData.typeName !== "event" ? (
            <EndsAt campaignEndsAt={BodyData.campaignEndsAt} />
          ) : null}
          {BodyData.startsAt && BodyData.typeName === "event" ? (
            <StartsAt
              campaignStartsAt={BodyData.startsAt}
              campaignEndedAt={BodyData.campaignEndsAt}
            />
          ) : null}
        </>
      ),
    },
    {
      section: (
        <Stack
          flexDirection="row"
          my={1}
          alignItems="center"
          marginRight="24px"
          justifyContent="space-between"
        >
          <Stack flexDirection="row" my={1} width="100%" flex={0.8}>
            {isADBEnabled && (
              <Button
                sx={{
                  paddingLeft: 0,
                  color: palette.neutral[80],
                  gap: "6px",
                }}
                size="medium"
                endIcon={<Palette size={20} fill={palette.neutral[80]} />}
                background="tertiary"
                onClick={handleEditFromStyle}
                disabled={hasEnded || !isEditProductAllowed}
                tooltipProps={{
                  message: !isEditProductAllowed
                    ? EDIT_DENY_MESSAGE
                    : DEADLINE_REACHED_MESSAGE,
                  show: hasEnded || !isEditProductAllowed,
                }}
              >
                Advanced Builder
              </Button>
            )}

            <Button
              sx={{
                color: palette.neutral[80],
                gap: "6px",
              }}
              size="medium"
              endIcon={<ShareIconUpV2 />}
              background="tertiary"
              onClick={handleShare}
            >
              Share
            </Button>
          </Stack>
          {/* TODO: feature flag will be removed once the advanced builder finishes */}

          <Button
            sx={{
              backgroundColor: "#292727",
              color: "#ffffff",
              borderRadius: "32px",
              marginRight: "8px",
              flex: 0.2,
              height: "auto",
              paddingY: "4px",
            }}
            onClick={handleEdit}
            disabled={hasEnded || isEditFormStyleDisabled}
            tooltipProps={{
              show: hasEnded,
              message: DEADLINE_REACHED_MESSAGE,
            }}
          >
            Edit
          </Button>
        </Stack>
      ),
    },
    {
      section: (
        <>
          <Divider
            sx={{
              marginRight: "24px",
            }}
          />
          <Stack
            flexDirection="row"
            py={4}
            justifyContent="space-between"
            width="100%"
            marginRight="24px"
          >
            <Text
              color={palette.neutral[80]}
              lineHeight="21.6px"
              fontSize="18px"
              fontWeight="regular"
            >
              Overview
            </Text>
            <Stack>
              <OverViewText
                text="Conversion"
                amount={BodyData?.conversion || 0}
              />
              <OverViewText text="Visitors" amount={BodyData?.Visitors || 0} />
              <OverViewText
                text="Purchases"
                amount={BodyData?.purchases || 0}
              />
              <OverViewText
                text="Average (USD)"
                amount={BodyData.averageTransactions}
              />
            </Stack>
          </Stack>
        </>
      ),
      hidden: isEvent,
    },
    {
      section: (
        <Stack
          direction="column"
          gap={4}
          alignItems="stretch"
          marginRight="24px"
        >
          <Divider
            sx={{
              marginRight: "24px",
            }}
          />
          {FigureComponent}
          {BodyData.total === 0 || BodyData.total === "0.00" ? (
            <EmptyChart />
          ) : (
            <VerticalBarChart height={180} data={BodyData.statsData} />
          )}
          {isEvent && (
            <>
              <Divider
                sx={{
                  marginRight: "24px",
                  marginBottom: -2,
                }}
              />
              {campaignInfoSection(campaignGeneralInfo)}
              {BodyData.variants && <CampaignInventoryItems {...BodyData} />}
              {campaignInfoSection(campaignTicketsInfo)}
            </>
          )}
          <Stack direction="column" gap={2} alignItems="stretch">
            <Text
              fontWeight="medium"
              lineHeight="21px"
              fontSize="18px"
              color={palette.neutral[80]}
              my={1}
            >
              Latest purchases
            </Text>
            <List>
              {BodyData?.variantsList?.map((variant, idx) => (
                <LatestPurchasesText
                  {...variant}
                  isSelected={selectedTransaction === variant?.id}
                  onClick={() => chooseTransaction(variant.id)}
                  key={`${variant.name} ${idx}`}
                />
              ))}
            </List>
          </Stack>
        </Stack>
      ),
    },
    {
      section: (
        <Box ref={setRefs} sx={{ height: "100%" }}>
          {BodyData?.hideViewMore ? (
            <EmptyState
              Icon={<PaperIcon />}
              title={{
                main: `You do not have purchases`,
              }}
              sx={{ flexGrow: 1 }}
            />
          ) : (
            <Stack
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                size={"medium"}
                endIcon={<MenuIcon2 />}
                background="tertiary"
                onClick={handleExpand}
                sx={{ color: palette.neutral[80], fontSize: "14px" }}
              >
                View all Purchases
              </Button>
            </Stack>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        padding="10px 0 10px 24px"
        flex={1}
        width="100%"
        display="flex"
        flexDirection="column"
      >
        {isloading ? (
          <LoadingSpinner />
        ) : (
          <>
            {sectionsList.map(({ section, delay, hidden = false }, index) => {
              if (hidden) return;
              return (
                <FadeUpWrapper key={index} delay={(delay || 100) + index * 80}>
                  {section}
                </FadeUpWrapper>
              );
            })}
          </>
        )}
      </Box>
      {!inView && (
        <Box
          width={CAMPAIGN_PANEL_WIDTH}
          position="fixed"
          bottom={40}
          display="flex"
          justifyContent="center"
        >
          <Box
            onClick={scrollToBottom}
            bgcolor="#FFFFFF"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: 1.5,
              borderRadius: "50%",
              cursor: "pointer",
              transform: "rotate(90deg)",
              boxShadow: " 0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
            }}
          >
            <CustomArrowRight />
          </Box>
        </Box>
      )}
    </>
  );
}

export default memo(Body);

const OverViewText = ({
  text,
  amount,
}: {
  text: string;
  amount: null | number | string;
}) => (
  <Grid
    container
    mr={5}
    rowSpacing={2}
    columnSpacing={10}
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item xs={6}>
      <Text
        fontSize="14px"
        lineHeight="16.8px"
        color={palette.neutral[70]}
        fontWeight="book"
        sx={{ whiteSpace: "nowrap" }}
      >
        {text}
      </Text>
    </Grid>
    <Grid item xs={6}>
      <Text
        fontSize="18px"
        fontWeight="book"
        color={Number(amount) > 0 ? palette.neutral[80] : "#8F8F8F"}
      >
        {amount}
        {text === "Conversion" && "%"}
      </Text>
    </Grid>
  </Grid>
);

const LatestPurchasesText = ({
  name,
  createdAt,
  totalPrice,
  creator,
  inventory,
  onClick,
  isSelected,
  ...props
}: VARIANT & StackProps) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        transition: "background-color 0.3s ease-in-out",
        cursor: "pointer",
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: isSelected ? palette.neutral[10] : "",
        "&:hover": {
          backgroundColor: palette.neutral[10],
        },
      }}
      py={2}
      px={2}
      onClick={onClick}
      data-testid={`latest-purchase-${name}`}
    >
      <Stack gap={0.5}>
        <Text
          fontWeight="medium"
          lineHeight="16px"
          fontSize="14px"
          color={palette.neutral[80]}
        >
          {name}{" "}
          <span
            style={{
              color: palette.neutral[70],
              fontWeight: 300,
            }}
          >
            (x{inventory})
          </span>
        </Text>
        <WrappedText
          fontWeight="book"
          fontSize="14px"
          lineHeight="16px"
          color={palette.neutral[70]}
        >
          {moment(createdAt).format("MM/DD/YYYY")} - {creator}
        </WrappedText>
      </Stack>
      <Text fontWeight="book" fontSize="14px" color={palette.neutral[80]}>
        {totalPrice} USD
      </Text>
    </Stack>
  );
};
