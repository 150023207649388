import SectionPlaceholders, {
  SectionPlaceholderProps,
} from "@components/Merchants/CreateMerchantPanel/components/AddSections/SectionPlaceholders";
import { BankIcon, ScrollIcon } from "@assets/icons/RebrandedIcons";
import { SectionType } from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";

const PlaceholderSection = ({
  data,
  clickHandlers,
}: {
  data: IParsedData;
  clickHandlers: {
    businessProfile: (section: SectionType, isEdit?: boolean) => void;
    bankAccount: () => void;
  };
}) => {
  const isBusinessProfileHidden = Boolean(
    data.businessProfile.taxID || data?.businessProfile?.ssn,
  );
  const isAllowedCreateBusinessProfile = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.ENTERPRISE,
      RESOURCE_BASE.LEGAL_ENTITY,
    ),
    operation: OPERATIONS.CREATE,
  });

  const isAllowedCreateBankAccount = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.ENTERPRISE,
      RESOURCE_BASE.BANK_ACCOUNT,
    ),
    operation: OPERATIONS.CREATE,
  });

  const list: SectionPlaceholderProps["placeholdersList"] = [
    {
      icon: <ScrollIcon />,
      title: "Business Profile",
      description: "Also known as Legal Entity",
      hidden: isBusinessProfileHidden,
      disabled: !isAllowedCreateBusinessProfile,
      tooltipProps: {
        show: !isAllowedCreateBusinessProfile,
        message: CREATE_DENY_MESSAGE,
      },
      onClick: () => clickHandlers.businessProfile("business_profile", false),
    },
    {
      icon: <BankIcon />,
      title: "Bank Accounts",
      description: "Add a Bank Account",
      hidden: data.bankAccountList?.length !== 0,
      disabled: !isAllowedCreateBankAccount,
      tooltipProps: {
        show: !isAllowedCreateBankAccount,
        message: CREATE_DENY_MESSAGE,
      },
      onClick: clickHandlers.bankAccount,
    },
  ];
  return <SectionPlaceholders placeholdersList={list} />;
};

export default PlaceholderSection;
