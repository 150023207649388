import { Stack } from "@mui/system";
import { useAppTheme } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
import { Icon as StatusIcon } from "@shared/Popup/GivePopupIcons";
import React from "react";
import { ArrowRight } from "@phosphor-icons/react";
import { ContentViewTypes } from "../MatchReportModal";
import GiveChip from "@shared/Chip/GiveChip";
import { ReportType } from "@components/Merchants/MerchantPreview/MATCH/types";
import moment from "moment/moment";
import { statusOptions } from "./ReportStatusSelector";
import {
  StyledAddNewReportButton,
  StyledPreviousCheckRowContainer,
  StyledPreviousCheckStack,
  StyledLatestCheckStack,
  StyledAddNewReportStack,
  StyledMatchStatusStackContainer,
  StyledMatchStatusBoxRoot,
} from "../styles/NewReportView.style";

const SUMMARY_TEXT =
  "The Member Alert to Control High-risk Merchants (MATCH) report compiles comprehensive results obtained from the screening of a merchant's business and ownership details.";

interface IComponentProps {
  handleChangeView: (value: ContentViewTypes) => void;
  handleSetDetailsView: (report: ReportType) => void;
  reportsData: ReportType[];
}

const NewReportView = ({
  handleChangeView,
  handleSetDetailsView,
  reportsData,
}: IComponentProps) => {
  const theme = useAppTheme();
  const hasPreviousChecks = reportsData.length > 1;
  const recentReport = reportsData[0];

  const showAddNewReportView = () => {
    handleChangeView(ContentViewTypes.ADD_NEW_REPORT);
  };

  const showReportDetailsView = (report: ReportType = recentReport) => {
    handleSetDetailsView(report);
  };

  return (
    <Stack gap={3}>
      <StyledAddNewReportStack>
        <GiveText variant="bodyS" color="secondary">
          {SUMMARY_TEXT}
        </GiveText>
        <StyledAddNewReportButton
          label="Add New Report"
          size="small"
          onClick={showAddNewReportView}
        />
      </StyledAddNewReportStack>
      {reportsData.length ? (
        <StyledMatchStatusBoxRoot>
          <StyledMatchStatusStackContainer
            onClick={() => showReportDetailsView()}
          >
            <StatusIcon
              type={
                recentReport.statusName === "clear"
                  ? "success-regular"
                  : "warning"
              }
            />
            <Stack direction="row" justifyContent="space-between" pt={3}>
              <GiveText variant="h5">
                {statusOptions[recentReport.statusName].label}
              </GiveText>
              <ArrowRight
                size={20}
                color={theme.palette.icon?.["icon-primary"]}
              />
            </Stack>
            <StyledLatestCheckStack>
              <GiveText variant="bodyXS" color="secondary">
                Latest check:
              </GiveText>
              <GiveText variant="bodyXS">
                {convertToUTC(recentReport.createdAt)}
              </GiveText>
            </StyledLatestCheckStack>
          </StyledMatchStatusStackContainer>
          {hasPreviousChecks && (
            <Stack gap={2.5}>
              <GiveText>Previous Checks</GiveText>
              <StyledPreviousCheckStack>
                {reportsData.slice(1).map((report, index) => (
                  <PreviousCheckRow
                    report={report}
                    showReportDetailsView={showReportDetailsView}
                    key={`prev-check-${index}`}
                  />
                ))}
              </StyledPreviousCheckStack>
            </Stack>
          )}
        </StyledMatchStatusBoxRoot>
      ) : (
        <></>
      )}
    </Stack>
  );
};

const PreviousCheckRow = ({
  report,
  showReportDetailsView,
}: {
  report: ReportType;
  showReportDetailsView: (report: ReportType) => void;
}) => {
  return (
    <StyledPreviousCheckRowContainer
      onClick={() => showReportDetailsView(report)}
    >
      <GiveText variant="bodyS" color="secondary">
        {convertToUTC(report.createdAt)}
      </GiveText>
      <GiveChip
        color={report.statusName === "clear" ? "success" : "error"}
        label={statusOptions[report.statusName].label}
        variant="light"
      />
    </StyledPreviousCheckRowContainer>
  );
};

const convertToUTC = (value: number) => {
  return moment(value * 1000)
    .utc()
    .format("MMMM DD, YYYY, hh:mm:ss A [UTC]");
};

export default NewReportView;
