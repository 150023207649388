import { Box, BoxProps, Grid, Stack, styled } from "@mui/material";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { palette } from "@palette";
import { Text, TruncateText } from "@common/Text";
import { PlusIcon, TrashBin } from "@assets/icons/RebrandedIcons";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { Minus, Plus } from "@phosphor-icons/react";

type MerchantCategoryItemProps = {
  added?: boolean;
  isSelected?: boolean;
  isOnlyRemaining?: boolean;
  onClick?: () => void;
} & Omit<TCategoryCode, "id"> &
  BoxProps;

const CategoryItem = ({
  code,
  title,
  added,
  isSelected,
  onClick,
  isOnlyRemaining,
  riskStatus,
  ...props
}: MerchantCategoryItemProps) => {
  const { isEnabledNewMccFlow } = useGetFeatureFlagValues();

  const textColor = {
    normal: palette.filled.green1,
    high: palette.filled.orange,
    restricted: palette.filled.red,
  };

  return (
    <StyledMerchantCategoryItem
      {...props}
      isSelected={isSelected}
      isOnlyRemaining={isOnlyRemaining}
      my={0.5}
      isEnabledNewMccFlow={isEnabledNewMccFlow}
      onClick={isOnlyRemaining ? () => null : onClick} // cannot remove only remaining MCC
      data-testid={`mcc-${added ? "selected" : "selectable"}-item-${code}`}
    >
      <Stack gap="8px" alignItems="center" flexDirection="row">
        <CategoryCode isSelected={isSelected}>{code}</CategoryCode>
        <Stack>
          <TruncateText
            lineClamp={1}
            sx={{ wordBreak: "break-all", userSelect: "none" }}
            fontWeight="book"
            color={isSelected ? palette.neutral[60] : palette.neutral[80]}
            flexGrow={1}
          >
            {title}
          </TruncateText>
          {isEnabledNewMccFlow && (
            <Text
              textTransform="capitalize"
              color={textColor?.[riskStatus]}
              fontSize="12px"
              mt="4px"
            >
              {riskStatus}
            </Text>
          )}
        </Stack>
      </Stack>
      <Box display="none" className="mcc-trail-icon">
        {added || isSelected ? (
          isOnlyRemaining ? (
            <></>
          ) : isEnabledNewMccFlow ? (
            <Stack gap="4px" alignItems="center" flexDirection="row">
              <Minus size="20px" fill={palette.neutral[80]} />
              <ActionText text="Remove" />
            </Stack>
          ) : (
            <TrashBin height={20} width={20} />
          )
        ) : isEnabledNewMccFlow ? (
          <Stack gap="4px" alignItems="center" flexDirection="row">
            <Plus fill={palette.neutral[80]} height={20} width={20} />
            <ActionText text="Add" />
          </Stack>
        ) : (
          <PlusIcon height={20} width={20} />
        )}
      </Box>
    </StyledMerchantCategoryItem>
  );
};

const ActionText = ({ text = "Add" }) => (
  <Text color={palette.neutral[80]} fontWeight={350} fontSize="12px">
    {text}
  </Text>
);

const StyledMerchantCategoryItem = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isSelected" && prop !== "isOnlyRemaining",
})<
  BoxProps & {
    isSelected?: boolean;
    isOnlyRemaining?: boolean;
    isEnabledNewMccFlow?: boolean;
  }
>(({ isSelected, isOnlyRemaining, isEnabledNewMccFlow }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  padding: "4px",
  borderRadius: "4px",
  cursor: isOnlyRemaining ? "not-allowed" : "pointer",
  ...(isEnabledNewMccFlow && {
    justifyContent: "space-between",
  }),

  background: isSelected ? palette.neutral[10] : "transparent",
  "&:hover": {
    background: isSelected ? palette.neutral[10] : palette.neutral[5],
    "& .mcc-trail-icon": {
      display: "block",
      position: "relative",
      top: "2px",
    },
  },
}));

const CategoryCode = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ isSelected }) => ({
  padding: "4px",
  borderRadius: "4px",
  background: "#EBEBEB",
  fontSize: 12,
  fontWeight: 350,
  color: isSelected ? palette.neutral[60] : palette.neutral[80],
  userSelect: "none",
}));

export default CategoryItem;
