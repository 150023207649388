import { MerchantStats } from "@customTypes/merchant.stats.types";
import { UseQueryOptions, useQuery } from "react-query";
import { baseGetMerchantStats } from "@services/api/merchants";
import { QKEY_LIST_MERCHANT_STATS } from "@constants/queryKeys";
import { DEFAULT_QUERY_CONFIG } from "@components/VirtualList/hooks/queries";

type QueryReturnedType = MerchantStats;

const getMerchantStatsById = (id: number) => {
  return baseGetMerchantStats(id as number);
};

const useFindMerchantStatsById = (
  id: number,
  options?: Omit<UseQueryOptions<any, any, any, any>, "queryKey" | "queryFn">,
) => {
  return useQuery<QueryReturnedType>(
    [QKEY_LIST_MERCHANT_STATS, id],
    async () => {
      const merchantStats = await getMerchantStatsById(id);
      return merchantStats;
    },
    {
      ...DEFAULT_QUERY_CONFIG,
      ...options,
    },
  );
};

export default useFindMerchantStatsById;
