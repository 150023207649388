import { Divider as DividerMui, Stack } from "@mui/material";
import { Check, Plus } from "@phosphor-icons/react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { isFunction } from "lodash";
import GiveText from "../../shared/Text/GiveText";
import { ContextualMenuOptionProps } from "./ContextualMenu.types";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Text_color_types } from "@shared/Text/giveText.types";
import { ArrowPlacement } from "@shared/Tooltip/GiveTooltip.types";
import { memo } from "react";

const ContextualMenuOption = ({
  text,
  description,
  trailingText,
  onClick,
  type = "default",
  disabled = false,
  IconRight,
  IconLeft,
  Image,
  handleClose,
  id,
  isSelected,
  checkedIconType,
  tooltipTitle,
  disableTooltip,
  hidden = false,
  tooltipDirection = ArrowPlacement.BOTTOM,
  showRightIconOnHover = false,
  ...props
}: ContextualMenuOptionProps) => {
  const { palette } = useAppTheme();
  const isError = type === "destructive";
  const isLabel = type === "label";
  const color = isError ? palette.primitive?.error[50] : palette.text.primary;

  if (type === "divider") {
    return <Divider />;
  }
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onClick && onClick(event, id || text);
    isFunction(handleClose) && handleClose();
  };
  const CheckedIcon = checkedIconType
    ? checkedIcons?.[checkedIconType]
    : undefined;

  const Wrapper = ({
    tooltipTitle,
    disableTooltip,
    tooltipDirection,
    children,
  }: {
    tooltipTitle?: string;
    disableTooltip?: boolean;
    tooltipDirection: ArrowPlacement;
    children: React.ReactElement<any, any> & React.ReactNode;
  }) => {
    if (tooltipTitle) {
      return (
        <GiveTooltip
          width="compact"
          alignment="left"
          title={tooltipTitle}
          color="default"
          arrowPlacement={tooltipDirection}
          disableHoverListener={disableTooltip}
        >
          {children}
        </GiveTooltip>
      );
    }
    return <>{children}</>;
  };

  return (
    <HiddenComponent hidden={hidden} key={text}>
      <Wrapper
        tooltipTitle={tooltipTitle}
        disableTooltip={disableTooltip}
        tooltipDirection={tooltipDirection}
      >
        <Container
          disabled={disabled}
          isSelected={isSelected}
          onClick={handleClick}
          showRightOnHover={showRightIconOnHover}
          isLabel={isLabel}
          {...props}
        >
          <Left>
            {IconLeft && <IconLeft size={20} color={color} />}
            {Image}
            <Stack direction="column" spacing="4px" overflow="hidden">
              <Text
                variant={type === "label" ? "bodyXS" : "bodyS"}
                color={(COLOR[type] as Text_color_types) || "primary"}
              >
                {text}
              </Text>
              {description && (
                <Text variant="bodyXS" color="secondary">
                  {description}
                </Text>
              )}
            </Stack>
          </Left>
          <Right className="option-right">
            {CheckedIcon && isSelected ? (
              <CheckedIcon size={20} color={color} />
            ) : (
              <>
                {trailingText && (
                  <GiveText variant="bodyS" color="secondary">
                    {trailingText}
                  </GiveText>
                )}
                {IconRight && <IconRight size={20} color={color} />}
              </>
            )}
          </Right>
        </Container>
      </Wrapper>
    </HiddenComponent>
  );
};

const COLOR = {
  destructive: "error",
  label: "secondary",
  default: "primary",
  success: "success1",
};

const Container = styled(Stack)<{
  disabled: boolean;
  isSelected?: boolean;
  isLabel?: boolean;
  showRightOnHover?: boolean;
}>(({ theme, disabled, isSelected, isLabel, showRightOnHover }) => ({
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  display: "grid",
  gridTemplateColumns: "minmax(0, 100%) auto",
  alignItems: "center",
  gap: "8px",
  ...(isLabel
    ? {
        pointerEvents: "none",
      }
    : {
        pointerEvents: disabled ? "none" : "auto",
      }),
  opacity: disabled ? 0.5 : 1,
  "&:hover": {
    background: theme.palette.primitive?.transparent["darken-5"],
    cursor: "pointer",
  },
  ...(isSelected && {
    backgroundColor: theme.palette.primitive?.transparent["darken-5"],
    cursor: "none",
  }),
  "& * svg": {
    flexShrink: 0,
  },
  ...(showRightOnHover && {
    "& .option-right": {
      visibility: "hidden",
    },
    "&:hover .option-right": {
      visibility: "visible",
    },
  }),
}));

const Left = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  gap: "12px",
});

const Right = styled(Stack)(() => ({
  flexDirection: "row",
  maxWidth: "40%",
  alignItems: "center",
  gap: "10px",
  transition: "opacity 0.1s",
}));

const Divider = styled(DividerMui)(({ theme }) => ({
  margin: "8px 8px 8px 12px",
  color: theme.palette.border?.secondary,
}));

const Text = styled(GiveText)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  flexGrow: 1,
});

export default memo(ContextualMenuOption);

const checkedIcons = {
  Plus: Plus,
  Check: Check,
};
