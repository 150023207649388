import { Stack } from "@mui/material";
import { CheckCircle } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import CheckoutDetailsSectionItem from "./CheckoutDetailsSectionItem";
import { Controller, useFormContext } from "react-hook-form";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import { FormDataType } from "@sections/PayBuilder/utils";

const ConfirmationSection = () => {
  const methods = useFormContext();

  const { methods: leftSidepanelMethods } = usePayBuilderForm();
  const checkoutValues = leftSidepanelMethods.watch().Checkout;
  const isThankYouEnabled = checkoutValues.thankYouMessage.render;
  const isReceiptMessageEnabled = checkoutValues.receiptMessage.render;

  const messageSections = [
    {
      name: "thankYouMessage",
      condition: isThankYouEnabled,
      label: "Custom Thank You Message",
    },
    {
      name: "receiptMessage",
      condition: isReceiptMessageEnabled,
      label: "Custom Message for Receipt",
      isLastItem: true,
    },
  ];

  return (
    <Stack gap="12px">
      <Stack direction="row" spacing={2}>
        <CheckCircle size={24} />
        <GiveText variant="bodyL">Confirmation</GiveText>
      </Stack>
      {messageSections.map(({ name, condition, label, isLastItem }, index) => {
        return (
          <CheckoutDetailsSectionItem
            key={index}
            label={label}
            name={name as keyof FormDataType["Checkout"]}
            isLastItem={isLastItem}
            optionalInput={
              condition ? (
                <Stack paddingLeft="28px">
                  <Controller
                    control={methods.control}
                    name={`Checkout.${name}.optionalMessage`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <GiveInput
                          name={`${name}.optionalMessage`}
                          value={value}
                          onChange={onChange}
                          maxLength={500}
                          displayCounter
                          multiline
                          rows={6}
                        />
                      );
                    }}
                  />
                </Stack>
              ) : (
                <></>
              )
            }
          />
        );
      })}
    </Stack>
  );
};

export default ConfirmationSection;
