import { Box, Stack } from "@mui/material";
import { Check, Trash } from "@phosphor-icons/react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { MediaItem } from "@sections/PayBuilder/provider/provider.type";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GivePopup from "@shared/Popup/GivePopup";
import ImageUploader from "./ImageUploader";
import useManageImageModal from "./useManageImageModal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

function ImageModal({
  open,
  onClose,
  handleSelect,
  selectedURL,
  filterPNGOnly,
}: {
  open: boolean;
  onClose: () => void;
  handleSelect: (item: MediaItem) => void;
  selectedURL: string;
  filterPNGOnly?: boolean;
}) {
  const { isMobileView } = useCustomThemeV2();

  const {
    mutateMedia,
    newList,
    openDelete,
    setOpenDelete,
    handleDelete,
    handleCloseDelete,
    isLoading,
  } = useManageImageModal({ open });

  const { isLoading: isDeleteLoading } = mutateMedia;

  const handleSelectImage = (item: MediaItem) => handleSelect(item);

  return (
    <>
      <GiveBaseModal
        title="Media Library"
        open={open}
        onClose={onClose}
        showFooter={false}
        height={isMobileView ? undefined : "348px"}
        width={isMobileView ? "100%" : "560px"}
      >
        <Stack gap="16px" flexDirection="row" flexWrap="wrap">
          {isLoading
            ? [...Array(8)].map((_, index) => <LoadingImage key={index} />)
            : newList?.map((item, idx) => {
                const { URL } = item;
                if (!URL)
                  return (
                    <ImageUploader filterPNGOnly={filterPNGOnly} key={idx} />
                  );

                const isPng = item?.label?.endsWith(".png");

                if (filterPNGOnly && !isPng) return null;
                return (
                  <ImageItem
                    handleSelectImage={handleSelectImage}
                    item={item}
                    selectedURL={selectedURL}
                    setOpenDelete={setOpenDelete}
                    key={URL}
                  />
                );
              })}
        </Stack>
      </GiveBaseModal>

      <GivePopup
        open={Boolean(openDelete)}
        description={`Are you sure you want to remove ${openDelete?.label}? This will no longer be available in media library.`}
        onClose={() => {
          if (isDeleteLoading) return;
          handleCloseDelete();
        }}
        title="Remove Image"
        type="destructive"
        buttons={[
          {
            label: "Cancel",
            onClick: handleCloseDelete,
            variant: "ghost",
            disabled: isDeleteLoading,
          },
          {
            label: "Yes, Delete",
            onClick: handleDelete,
            variant: "filled",
            color: "destructive",
            disabled: isDeleteLoading,
          },
        ]}
      />
    </>
  );
}

export default ImageModal;
const ImageItem = ({
  item,
  selectedURL,
  handleSelectImage,
  setOpenDelete,
}: {
  item: MediaItem;
  selectedURL: string;
  handleSelectImage: (item: MediaItem) => void;
  setOpenDelete: Dispatch<SetStateAction<MediaItem | null>>;
}) => {
  const { URL, id } = item as {
    URL: string;
    id: number;
  };
  const isSelected = selectedURL === URL;

  const [isLoaded, setIsLoaded] = useState(false);

  const checkImage = async () => {
    try {
      const response = await fetch(URL + "/thumb");
      if (!response.ok) {
        throw new Error();
      }
      setIsLoaded(true);
    } catch (err) {
      setTimeout(checkImage, 2000);
    }
  };

  useEffect(() => {
    checkImage();
  }, [URL]);
  if (!isLoaded) {
    return <LoadingImage />;
  }

  return (
    <ImageContainer
      isSelected={isSelected}
      onClick={() => handleSelectImage(item)}
      data-testid="image-container"
    >
      <Image alt={URL} src={URL + "/original"} />
      <Overlay className="overlay" isSelected={isSelected}>
        {isSelected ? (
          <Check fill="#FFFFFF" size="24px" />
        ) : (
          <GiveIconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpenDelete(item);
            }}
            color="#FFFFFF"
            size="small"
            Icon={Trash}
          />
        )}
      </Overlay>
    </ImageContainer>
  );
};
const Image = styled("img")(({ theme }) => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  display: "block",
  transition: "opacity 0.3s ease",
  zIndex: 1,
}));

const ImageContainer = styled(Box)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    position: "relative",
    width: "114px",
    height: "114px",
    overflow: "hidden",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: theme.palette.primitive?.transparent["darken-5"],
    ...(isSelected && {
      border: `2px solid ${theme.palette.primitive?.blue[50]}`,
    }),
    "&:hover .overlay": {
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 16px)",
    },
  }),
);
const LoadingImage = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "114px",
  height: "114px",
  overflow: "hidden",
  borderRadius: "8px",
  backgroundColor: theme.palette.primitive?.transparent["darken-5"],

  [theme.breakpoints.down("sm")]: {
    width: "calc(50% - 16px)",
  },
}));
const Overlay = styled(Stack)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    position: "absolute",
    backgroundColor: "#00000066",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    ...(isSelected
      ? { justifyContent: "center", alignItems: "center" }
      : {
          justifyContent: "flex-end",
          alignItems: "flex-end",
          padding: theme.spacing(1),
        }),
    opacity: isSelected ? 1 : 0,
  }),
);
