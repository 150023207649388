import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";

import { HiddenComponent } from "@containers/HiddenComponent";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { Assertion_position } from "@sections/PayBuilder/provider/provider.type";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import GiveTabs from "@shared/Tabs/GiveTabs";
import { styled } from "@theme/v2/Provider";
import PayFormSectionTitle from "features/Minibuilders/PaymentFormMinibuilder/PayFormSectionTitle";
import ImageSection from "./ImageSection";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";

function About({
  isEdit,
  isFromADB,
}: {
  isEdit?: boolean;
  isFromADB?: boolean;
}) {
  const { methods } = usePayBuilderForm();
  const { setValue, watch } = methods;

  const assetPosition = watch("About.assetPosition");

  const { isFundraiser } = useCheckFormType();

  return (
    <Box pb="20px">
      <PayFormSectionTitle isEdit={isEdit}>
        Introduce your products
      </PayFormSectionTitle>
      <GiveText mt="12px" fontWeight={400} fontSize="14px" color="secondary">
        This introduction is placed at the top of the page
      </GiveText>

      <Stack gap="24px" mt="40px">
        <HFGiveInput
          maxLength={100}
          name="About.heading"
          label="Heading"
          placeholder="heading"
        />
        <TextArea
          label="Description (Optional)"
          name="About.description"
          InputProps={{
            inputComponent: "textarea",
          }}
          disabled={isFromADB}
          sx={{
            "&.disabled": {
              backgroundColor: "transparent",
            },
          }}
          maxLength={1600}
          rows={6}
          multiline
          displayCounter
        />
        <HiddenComponent hidden={!!isFromADB}>
          <>
            {!isFundraiser && (
              <Box>
                <GiveText
                  mb="8px"
                  fontWeight={400}
                  fontSize="14px"
                  color="primary"
                >
                  Asset Position
                </GiveText>
                <GiveTabs
                  selected={assetPosition}
                  containerSx={{
                    width: "100%",
                    justifyContent: "space-around",
                    padding: "4px",
                  }}
                  tabSx={{ flex: 1, justifyContent: "center" }}
                  items={assetTabs}
                  onClick={(e) => {
                    setValue("About.assetPosition", e as Assertion_position);
                  }}
                  type="segmented"
                />
              </Box>
            )}
            <ImageSection />
          </>
        </HiddenComponent>
      </Stack>
    </Box>
  );
}

export default About;

const assetTabs = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
];

const TextArea = styled(HFGiveInput)(({ theme }) => {
  return {
    "& .MuiInputBase-root": {
      padding: "0 !important",
    },
    "& .MuiInputBase-input": {
      height: "150px",
      padding: "10px !important",
    },
    "& textarea": {
      resize: "none",
    },
  };
});
