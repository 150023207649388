import React from "react";
import { GoogleMaps } from "@services/google-api";
import useLoadGoogleScript from "@hooks/google-api/useLoadGoogleScript";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { Stack } from "@mui/material";

interface Props {
  locations: any;
}

const EventMap = ({ locations }: Props) => {
  const { isLoaded } = useLoadGoogleScript();
  const { locationShortAddress } = locations;

  return (
    <MapContainer>
      <GiveText variant="bodyL">Location</GiveText>
      <GoogleMaps
        address={locationShortAddress}
        isLoaded={isLoaded}
        containerStyle={{
          height: "480px",
          borderRadius: "12px",
        }}
      />
    </MapContainer>
  );
};

export default EventMap;

const MapContainer = styled(Stack)({
  padding: "32px 0",
  gap: "16px",
});
