import NiceModal from "@ebay/nice-modal-react";
// components
import AccountSelectionModal from "@components/Login/AccountSelectionModal";
import { NewAccount } from "@components/ManageMoney/BankAccount";
import {
  CreateFundraiserModal,
  CreateInvoiceModal,
  CreateMembershipsModal,
  CreateSweepstakesModal,
  EditFundraiserModal,
  EditInvoiceModal,
  EditMembershipsModal,
  FundraiserSuccessModal,
  Revenues,
  Share,
  TermsOfService,
} from "sections";

import SweepstakesSuccessModal from "@components/Sweepstakes/MiniBuilder/SweepstakesSuccessModal";
import {
  ACCOUNT_SELECTION_MODAL,
  ADD_PRODUCT_ITEM_MODAL,
  ADVANCED_BUILDER_DELETE_CUSTOM_COLOR_MODAL,
  ADVANCED_BUILDER_ERROR_MODAL,
  ADVANCED_BUILDER_EXIT_MODAL,
  ADVANCED_BUILDER_PUBLISH_MODAL,
  ADVANCED_BUILDER_SAVE_DRAFT_MODAL,
  ADVANCED_BUILDER_SCREEN_SIZE_WARNING_MODAL,
  BANK_ACCOUNTS_MODAL,
  CAMPAIGN_ANALYTICS_PANEL,
  CAMPAIGN_DETAILS_MODAL,
  CART_PREVIEW_MODAL,
  CHANGE_EMAIL_MODAL,
  CHECKOUT_MODAL,
  CREATE_EVENT_MODAL,
  CREATE_EVENT_SUCCESS_MODAL,
  CREATE_FUNDRAISER_MODAL,
  CREATE_INVOICE_MODAL,
  CREATE_INVOICE_SUCCESS_MODAL,
  CREATE_MEMBERSHIP_MODAL,
  CREATE_MEMBERSHIP_SUCCESS_MODAL,
  CREATE_PAYMENT_FORM_MODAL,
  CREATE_PRE_BUILD_FORM_MODAL,
  CREATE_SWEEPSTAKES_MODAL,
  CREDIT_CARD_FEES_MODAL,
  CUSTOMERS_ANALYTICS_PANEL,
  DONORS_MODAL,
  EDIT_AVATAR_MODAL,
  EDIT_EVENT_MODAL,
  EDIT_FUNDRAISER_MODAL,
  EDIT_INVOICE_MODAL,
  EDIT_MEMBERSHIP_MODAL,
  EDIT_PROFILE_IMAGE_MODAL,
  EXPIRED_PAYMENT_FORM_MODAL,
  FUNDRAISER_SUCCESS_MODAL,
  GENERATE_SIGNATURE_MODAL,
  INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  LOGOUT_MODAL,
  MANAGE_MONEY_FILTER_MODAL,
  MANAGE_RISK_TRIGGER_MODAL,
  MANUAL_SIGN_AGREEMENT_MODAL,
  MATCH_MOBILE_VIEW,
  MATCH_REPORT_MODAL,
  MERCHANT_ADD_CUSTOMER,
  MERCHANT_EDIT_CUSTOMER,
  MERCHANT_PORTAL_CANCEL_MODAL,
  MERCHANT_PORTAL_MAKE_RECURRING_MODAL,
  MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL,
  MERCHANT_PORTAL_MODIFY_RECURRING_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
  MERCHANT_PORTAL_SHARE_MODAL,
  MERCHANT_PORTAL_VIEW_TRANSACTION_MODAL,
  MERCHANT_VIEW_CUSTOMER,
  MINIBUILDER_CREATE_AMOUNT,
  MINIBUILDER_CREATE_SUBSCRIPTION,
  MINIBUILDER_CREATE_SWEEPSTAKE_AMOUNT,
  MINIBUILDER_EDIT_ANY_AMOUNT,
  NEW_ACCOUNT_MODAL,
  NEW_INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  NEW_MATCH_REPORT_MODAL,
  NEW_PEEK_MODE_MODAL,
  NO_ACCOUNT_MODAL,
  NOT_ABLE_PROCESS_PAYMENT_FORM_MODAL,
  NOTIFICATION_SIDE_PANEL,
  OFAC_CHECK_MODAL,
  PAH_UPLOADER_PREVIEW_MODAL,
  PASSWORD_EXPIRED_MODAL,
  PAYMENT_FORM_SUCCESS_MODAL,
  PEEK_MODE_MODAL,
  PEP_HISTORY_MODAL,
  PLAID_ACCOUNT_INFO,
  PREVIEW_INVITATION_MODAL,
  PRIVACY_POLICY_MODAL,
  PUBLIC_FORM_PRODUCT_INFO_MODAL,
  PUBLIC_PRODUCT_ITEM_MODAL,
  REFUND_POLICY_MODAL,
  REVENUES_PRODUCT_MODAL,
  RISK_MONITOR_MARK_ALL_AS_OK_MODAL,
  RISK_MONITOR_TOGGLE_TRIGGER_MODAL,
  SAVE_AND_CLOSE_MODAL,
  SIGN_AGREEMENT_MODAL,
  SWEEPSTAKE_SUCCESS_MODAL,
  TERMS_OF_SERVICE_MODAL,
  TERMS_OF_SERVICE_REBRAND_MODAL,
  THANK_YOU_MODAL,
  UPLOAD_SIGNATURE_MODAL,
  VERIFY_ACCOUNT_HOLDER_V2,
} from "./modal_names";

import CampaignDetailsModal from "@common/CampaignCard/CampaignDetailsModal";
import PeekModeModal from "@common/PeekMode/PeekModeModal";
import NewChangeEmailModal from "@common/Settings/NewChangeEmail/NewchangeEmailModal";
import { CampaignSidePanel } from "@components/Analytics/SidePanels";
import CustomersSidePanel from "@components/Analytics/SidePanels/CustomersSidePanel/CustomersSidePanel";
import { EditAvatarModal } from "@components/AvatarUpload/modal/EditAvatarModal";
import PlaidAccountInfoModal from "@components/BankAccounts/modals/PlaidAccountInfoModal";
import AddCustomer from "@components/Customers/AddCustomer";
import EditCustomer from "@components/Customers/EditCustomerWrapper";
import ViewTransaction from "@components/DonationList/Table/ViewTransaction/ViewTransaction";
import NoAccountModal from "@components/Login/NoAccount";
import PasswordExpiredModal from "@components/Login/PasswordExpiredModal";
import {
  Cancel,
  ModifyRecurring,
  Refund,
  SendReceipt,
  ViewCustomer,
} from "@components/ManageMoney/TransactionTable/TableActions";
import MakeRecuring from "@components/ManageMoney/TransactionTable/TableActions/MakeRecurring";
import MarkAsFraudModal from "@components/ManageMoney/TransactionTable/TableActions/MarkAsFraudModal";
import PreviewInvitationModal from "@components/Merchants/Common/PreviewInvitationModal/PreviewInvitationModal";
import PAHUploaderPreviewModal from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderPreviewModal";
import MATCHMobileModal from "@components/Merchants/MerchantPreview/MATCH/MATCHMobileModal";
import ReportModal from "@components/Merchants/MerchantPreview/MATCH/ReportModal";
import ManageRiskTriggerModal from "@components/Merchants/MerchantPreview/modals/ManageRiskTriggerModal";
import MarkAllAsOkModal from "@components/Merchants/MerchantPreview/modals/MarkAllAsOkModal";
import OFACCheckModal from "@components/Merchants/MerchantPreview/modals/OFACCheckModal";
import PEPHistoryModal from "@components/Merchants/MerchantPreview/modals/PEPHistoryModal";
import ToggleRiskTriggerModal from "@components/Merchants/MerchantPreview/modals/ToggleRiskTriggerModal";
import ExpiredPaymentFormModal from "@components/PaymentForm/ExpiredPaymentForm";
import NotAbleProcessPaymentFormModal from "@components/PaymentForm/NotAbleProcessPaymentForm";
import GenerateSignatureModal from "@components/ProfilePage/MerchantAgreementSetup/modals/GenerateSignatureModal";
import ManualSignAgreementModal from "@components/ProfilePage/MerchantAgreementSetup/modals/ManualSignAgreementModal";
import SignAgreementModal from "@components/ProfilePage/MerchantAgreementSetup/modals/SignAgreementModal";
import UploadSignatureModal from "@components/ProfilePage/MerchantAgreementSetup/modals/UploadSignatureModal";
import CreateSweepstackesAmount from "@components/Sweepstakes/MiniBuilder/Modal/CreateSweepstackesAmount";
import ManageMoneyFilterModal from "@pages/ManageMoney/Modal/ManageMoneyFilterModal";
import AdvancedBuilderDeleteCustomColorModal from "@pages/NewAdvancedBuilder/components/modals/AdvancedBuilderDeleteCustomColorModal";
import AdvancedBuilderErrorModal from "@pages/NewAdvancedBuilder/components/modals/AdvancedBuilderErrorModal";
import AdvancedBuilderExitModal from "@pages/NewAdvancedBuilder/components/modals/AdvancedBuilderExitModal";
import AdvancedBuilderPublishModal from "@pages/NewAdvancedBuilder/components/modals/AdvancedBuilderPublishModal";
import AdvancedBuilderSaveDraftModal from "@pages/NewAdvancedBuilder/components/modals/AdvancedBuilderSaveDraftModal";
import AdvancedBuilderScreenSizeWarningModal from "@pages/NewAdvancedBuilder/components/modals/AdvancedBuilderScreenSizeWarningModal";
import CheckoutModal from "@sections/Checkout/CheckoutModal";
import CreditCardFeesModal from "@sections/Checkout/CreditCardFeesModal";
import PrivacyModal from "@sections/Checkout/PrivacyModal";
import RefundPolicyModal from "@sections/Checkout/RefundPolicyModal";
import ThankYouModal from "@sections/Checkout/ThankYouModal";
import AddProductItemModal from "@sections/PayBuilder/items/AddProductItemModal";
import CartPreviewModal from "@sections/PayBuilder/items/CartPreviewPanel";
import PublicProductItemModal from "@sections/PayBuilder/items/PublicProductItemModal";
import ProductInfoModal from "@sections/ProductInfosModal/ProductInfosModal";
import { VerifyAccountHolder_v2 } from "@sections/VerifyAccountHolder_v2";
import { EditProfileImageModal } from "@shared/ProfileImage/EditProfileImageModal/EditProfileImageModal";
import {
  CreateEventModal,
  EditEventModal,
} from "features/Minibuilders/EventsMinibuilder";
import EventSuccessModal from "features/Minibuilders/EventsMinibuilder/EventSuccessModal";
import InvoiceSuccessModal from "features/Minibuilders/InvoicesMinibuilder/InvoiceSuccessModal";
import MembershipSuccessModal from "features/Minibuilders/MembershipsMinibuilder/MembershipSuccessModal";
import { CreateAmountModal } from "features/Minibuilders/modals/CreateAmount";
import { CreateSubscriptionModal } from "features/Minibuilders/modals/CreateSubscription";
import { EditAnyAmountModal } from "features/Minibuilders/modals/EditAnyAmount";
import CreatePaymentFormModal from "features/Minibuilders/PaymentFormMinibuilder/CreatePaymentFormModal";
import CreatePreBuildFormModal from "features/Minibuilders/PaymentFormMinibuilder/CreatePreBuildFormModal";
import IntroduceAdvancedFormBuilderModal from "features/Minibuilders/PaymentFormMinibuilder/IntroduceAdvancedFormBuilderModal";
import PaymentFormSuccessModal from "features/Minibuilders/PaymentFormMinibuilder/PaymentFormSuccessModal";
import SaveAndCloseModal from "features/Minibuilders/PaymentFormMinibuilder/SaveAndCloseModal";
import NotificationModal from "features/Notifications/NotificationsCenter/modals/NotificationModal";
import { LogoutModal } from "sections";
import BankAccounts from "../components/BankAccounts/BankAccounts";
import MatchReportModal from "../features/Merchants/MerchantSidePanel/Modals/MatchReport/MatchReportModal";
import TermsOfServiceRebrandModal from "@sections/TermsOfService/TermsOfServiceRebrandModal";
import NewPeekModeModal from "@common/PeekMode/NewPeekModeModal";
import DonorsModal from "@sections/PayBuilder/Modals/DonorsModal";
import GiveIntroduceADBModal from "features/Minibuilders/PaymentFormMinibuilder/GiveIntroduceADBModal";

NiceModal.register(NEW_ACCOUNT_MODAL, NewAccount);
NiceModal.register(CREATE_EVENT_MODAL, CreateEventModal);
NiceModal.register(ACCOUNT_SELECTION_MODAL, AccountSelectionModal);
NiceModal.register(FUNDRAISER_SUCCESS_MODAL, FundraiserSuccessModal);
NiceModal.register(PAYMENT_FORM_SUCCESS_MODAL, PaymentFormSuccessModal);
NiceModal.register(CREATE_FUNDRAISER_MODAL, CreateFundraiserModal);
NiceModal.register(CREATE_PAYMENT_FORM_MODAL, CreatePaymentFormModal);
NiceModal.register(CREATE_PRE_BUILD_FORM_MODAL, CreatePreBuildFormModal);
NiceModal.register(CAMPAIGN_DETAILS_MODAL, CampaignDetailsModal); //
NiceModal.register(EDIT_FUNDRAISER_MODAL, EditFundraiserModal);
NiceModal.register(EDIT_EVENT_MODAL, EditEventModal);
NiceModal.register(CREATE_SWEEPSTAKES_MODAL, CreateSweepstakesModal);
// NiceModal.register(CREATE_MEMBERSHIP_MODAL, CreateMembershipModal);
NiceModal.register(CREATE_MEMBERSHIP_MODAL, CreateMembershipsModal);
NiceModal.register(EDIT_MEMBERSHIP_MODAL, EditMembershipsModal);
NiceModal.register(MINIBUILDER_CREATE_SUBSCRIPTION, CreateSubscriptionModal);
NiceModal.register(TERMS_OF_SERVICE_MODAL, TermsOfService);
NiceModal.register(CREATE_MEMBERSHIP_SUCCESS_MODAL, MembershipSuccessModal);
NiceModal.register(SWEEPSTAKE_SUCCESS_MODAL, SweepstakesSuccessModal);
NiceModal.register(CREATE_EVENT_SUCCESS_MODAL, EventSuccessModal);
NiceModal.register(CREATE_INVOICE_MODAL, CreateInvoiceModal);
NiceModal.register(EDIT_INVOICE_MODAL, EditInvoiceModal);
NiceModal.register(CREATE_INVOICE_SUCCESS_MODAL, InvoiceSuccessModal);
NiceModal.register(MINIBUILDER_CREATE_AMOUNT, CreateAmountModal);
NiceModal.register(NOTIFICATION_SIDE_PANEL, NotificationModal);
NiceModal.register(MANAGE_MONEY_FILTER_MODAL, ManageMoneyFilterModal);
NiceModal.register(
  MINIBUILDER_CREATE_SWEEPSTAKE_AMOUNT,
  CreateSweepstackesAmount,
);
NiceModal.register(MINIBUILDER_EDIT_ANY_AMOUNT, EditAnyAmountModal);
NiceModal.register(CHECKOUT_MODAL, CheckoutModal);
NiceModal.register(THANK_YOU_MODAL, ThankYouModal);
NiceModal.register(MERCHANT_VIEW_CUSTOMER, ViewCustomer);
NiceModal.register(MERCHANT_PORTAL_REFUND_MODAL, Refund);
NiceModal.register(MERCHANT_PORTAL_CANCEL_MODAL, Cancel);
NiceModal.register(MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL, MarkAsFraudModal);
NiceModal.register(MERCHANT_PORTAL_SEND_RECEIPT_MODAL, SendReceipt);
NiceModal.register(MERCHANT_PORTAL_MODIFY_RECURRING_MODAL, ModifyRecurring);
NiceModal.register(MERCHANT_PORTAL_VIEW_TRANSACTION_MODAL, ViewTransaction);
NiceModal.register(MERCHANT_PORTAL_MAKE_RECURRING_MODAL, MakeRecuring);
NiceModal.register(MERCHANT_PORTAL_SHARE_MODAL, Share);
NiceModal.register(LOGOUT_MODAL, LogoutModal);
NiceModal.register(REVENUES_PRODUCT_MODAL, Revenues);
NiceModal.register(PUBLIC_FORM_PRODUCT_INFO_MODAL, ProductInfoModal);
// NiceModal.register(PUBLIC_PRODUCT_INFO_MODAL, PublicProductModal);
NiceModal.register(CHANGE_EMAIL_MODAL, NewChangeEmailModal);
NiceModal.register(MERCHANT_EDIT_CUSTOMER, EditCustomer);
NiceModal.register(MERCHANT_ADD_CUSTOMER, AddCustomer);
NiceModal.register(BANK_ACCOUNTS_MODAL, BankAccounts);
NiceModal.register(EXPIRED_PAYMENT_FORM_MODAL, ExpiredPaymentFormModal);
NiceModal.register(
  NOT_ABLE_PROCESS_PAYMENT_FORM_MODAL,
  NotAbleProcessPaymentFormModal,
);
NiceModal.register(PEEK_MODE_MODAL, PeekModeModal);
NiceModal.register(NEW_PEEK_MODE_MODAL, NewPeekModeModal);
NiceModal.register(PASSWORD_EXPIRED_MODAL, PasswordExpiredModal);
NiceModal.register(NO_ACCOUNT_MODAL, NoAccountModal);
NiceModal.register(CAMPAIGN_ANALYTICS_PANEL, CampaignSidePanel);
NiceModal.register(CUSTOMERS_ANALYTICS_PANEL, CustomersSidePanel);

NiceModal.register(SIGN_AGREEMENT_MODAL, SignAgreementModal);
NiceModal.register(UPLOAD_SIGNATURE_MODAL, UploadSignatureModal);
NiceModal.register(MANUAL_SIGN_AGREEMENT_MODAL, ManualSignAgreementModal);
NiceModal.register(GENERATE_SIGNATURE_MODAL, GenerateSignatureModal);
NiceModal.register(VERIFY_ACCOUNT_HOLDER_V2, VerifyAccountHolder_v2);
NiceModal.register(EDIT_AVATAR_MODAL, EditAvatarModal);
NiceModal.register(PAH_UPLOADER_PREVIEW_MODAL, PAHUploaderPreviewModal);
NiceModal.register(
  INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  IntroduceAdvancedFormBuilderModal,
);
NiceModal.register(
  NEW_INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  GiveIntroduceADBModal,
);
NiceModal.register(
  ADVANCED_BUILDER_SAVE_DRAFT_MODAL,
  AdvancedBuilderSaveDraftModal,
);
NiceModal.register(
  ADVANCED_BUILDER_SCREEN_SIZE_WARNING_MODAL,
  AdvancedBuilderScreenSizeWarningModal,
);
NiceModal.register(ADVANCED_BUILDER_ERROR_MODAL, AdvancedBuilderErrorModal);
NiceModal.register(
  ADVANCED_BUILDER_DELETE_CUSTOM_COLOR_MODAL,
  AdvancedBuilderDeleteCustomColorModal,
);
NiceModal.register(ADVANCED_BUILDER_EXIT_MODAL, AdvancedBuilderExitModal);
NiceModal.register(ADVANCED_BUILDER_PUBLISH_MODAL, AdvancedBuilderPublishModal);
NiceModal.register(PEP_HISTORY_MODAL, PEPHistoryModal);
NiceModal.register(OFAC_CHECK_MODAL, OFACCheckModal);
NiceModal.register(RISK_MONITOR_MARK_ALL_AS_OK_MODAL, MarkAllAsOkModal);
NiceModal.register(RISK_MONITOR_TOGGLE_TRIGGER_MODAL, ToggleRiskTriggerModal);
NiceModal.register(MANAGE_RISK_TRIGGER_MODAL, ManageRiskTriggerModal);
NiceModal.register(PREVIEW_INVITATION_MODAL, PreviewInvitationModal);
NiceModal.register(MATCH_REPORT_MODAL, ReportModal);
NiceModal.register(MATCH_MOBILE_VIEW, MATCHMobileModal);

NiceModal.register(PLAID_ACCOUNT_INFO, PlaidAccountInfoModal);
NiceModal.register(ADD_PRODUCT_ITEM_MODAL, AddProductItemModal);
NiceModal.register(PUBLIC_PRODUCT_ITEM_MODAL, PublicProductItemModal);
NiceModal.register(REFUND_POLICY_MODAL, RefundPolicyModal);
NiceModal.register(PRIVACY_POLICY_MODAL, PrivacyModal);

NiceModal.register(CREDIT_CARD_FEES_MODAL, CreditCardFeesModal);
NiceModal.register(CART_PREVIEW_MODAL, CartPreviewModal);
NiceModal.register(NEW_MATCH_REPORT_MODAL, MatchReportModal);

NiceModal.register(SAVE_AND_CLOSE_MODAL, SaveAndCloseModal);
NiceModal.register(EDIT_PROFILE_IMAGE_MODAL, EditProfileImageModal);
NiceModal.register(TERMS_OF_SERVICE_REBRAND_MODAL, TermsOfServiceRebrandModal);
NiceModal.register(DONORS_MODAL, DonorsModal);
