import NiceModal from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import GiveText from "@shared/Text/GiveText";
import { TERMS_OF_SERVICE_REBRAND_MODAL } from "modals/modal_names";
import { Control, Controller } from "react-hook-form";
import { ICheckoutInputs } from "../types";

const CheckoutTermsCheckbox = ({
  control,
  isPreviewMode = false,
}: {
  control: Control<ICheckoutInputs, any>;
  isPreviewMode?: boolean;
}) => {
  const openTermsModal = () => {
    NiceModal.show(TERMS_OF_SERVICE_REBRAND_MODAL);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Controller
        control={control}
        name="termsConditions"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Stack direction="column" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <GiveCheckbox
                name="termsConditions"
                value={value}
                checked={value}
                onChange={onChange}
                disabled={isPreviewMode}
              />
              <GiveText>
                I Accept the{" "}
                <GiveText
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  display="inline"
                  onClick={openTermsModal}
                >
                  Terms & Conditions
                </GiveText>
              </GiveText>
            </Stack>
            {error && (
              <GiveText
                sx={{ paddingLeft: "24px" }}
                variant="bodyXS"
                color="error"
              >
                {error.message}
              </GiveText>
            )}
          </Stack>
        )}
      />
    </Stack>
  );
};

export default CheckoutTermsCheckbox;
