import { DialogTitle, Stack } from "@mui/material";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { X } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type THeaderTitleComponent = {
  title: string | React.ReactNode;
  description?: string;
  onClick: () => void;
};
const EditProfileImageHeader = (props: THeaderTitleComponent) => {
  const { title, onClick, description } = props;
  const { isMobileView } = useCustomThemeV2();
  return (
    <Container>
      <Stack spacing="4px">
        <GiveText color="primary" variant="bodyL">
          {title}
        </GiveText>
        {description && (
          <GiveText color="secondary" variant="bodyS">
            {description}
          </GiveText>
        )}
      </Stack>
      {!isMobileView ? (
        <GiveIconButton
          aria-label="close"
          onClick={onClick}
          Icon={X}
          variant="ghost"
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

const Container = styled(DialogTitle)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "fit-content !important",
}));

export default EditProfileImageHeader;
