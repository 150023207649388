import { useAppTheme } from "@theme/v2/Provider";
import { LastMessageActivityType } from "@hooks/enterprise-api/account/useGetMerchants";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import GiveText from "@shared/Text/GiveText";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import { Stack } from "@mui/material";
import placeholder from "assets/images/Placeholder.png";

const BadgeTooltipContent = ({ data }: { data: LastMessageActivityType }) => {
  const { palette } = useAppTheme();
  const { subject, createdAt, authorAvatar } = data || {};

  return (
    <Stack direction="row" spacing="12px">
      <Stack
        color={palette.text.primary}
        spacing="4px"
        width="179px"
        textAlign="left"
      >
        <GiveText variant="bodyXS">Notified Merchant</GiveText>
        <Stack direction="row" spacing="4px">
          <GiveText color="secondary" variant="bodyXS" minWidth={46}>
            Subject:
          </GiveText>
          <GiveText noWrap variant="bodyXS">
            {subject}
          </GiveText>
        </Stack>
        <Stack direction="row" spacing="4px">
          <GiveText color="secondary" variant="bodyXS" minWidth={46}>
            Replied:
          </GiveText>
          <GiveText variant="bodyXS">
            {createdAt
              ? formatDistanceToNow(fromUnixTime(createdAt), {
                  addSuffix: true,
                })
              : "N/A"}
          </GiveText>
        </Stack>
      </Stack>
      <GiveAvatar
        size="24px"
        imageUrl={authorAvatar ? `${authorAvatar}/thumb` : placeholder}
      />
    </Stack>
  );
};

export default BadgeTooltipContent;
