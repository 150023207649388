import { PayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";
import PayBuilderFormProvider from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { ThemeWrapper } from "@theme/v2/Provider";
import PaymentFormModalContent from "./PaymentFormModalContent";

type TEditPaymentFormModalProps = {
  id: string;
  handleClose?: () => void;
  noModal?: boolean;
  isFromADB: boolean;
};

const NewEditPaymentFormModal = ({
  id,
  handleClose,
  noModal,
  isFromADB,
}: TEditPaymentFormModalProps) => {
  return (
    <>
      <ThemeWrapper isNewThemeOnly>
        <PayBuilderContext isLaunchVisible={false}>
          <PayBuilderFormProvider>
            <PaymentFormModalContent
              id={id}
              handleClose={handleClose}
              isFromADB={isFromADB}
            />
          </PayBuilderFormProvider>
        </PayBuilderContext>
      </ThemeWrapper>
    </>
  );
};

export default NewEditPaymentFormModal;
