import {
  ArrowsLeftRight,
  Users,
  GearSix,
  CalendarPlus,
  SealCheck,
  Ticket,
  PlusSquare,
} from "@phosphor-icons/react";
import ChartBarBox from "@assets/iconsV2/ChartBarBox";
import DonationBox from "@assets/iconsV2/DonationBox";
import FileDollar from "@assets/iconsV2/FileDollar";
import ProductSidePanelIcon from "@assets/iconsV2/ProductSidePanelIcon";

type Props = {
  isAddProductAllowed: boolean;
};

const getMerchantMenu = ({ isAddProductAllowed }: Props) => ({
  menu: [
    {
      label: "Dashboard",
      value: "/merchant/dashboard",
      CustomIcon: <ChartBarBox fill="currentColor" />,
    },
    {
      label: "Manage money",
      value: "/merchant/manage-money",
      Icon: ArrowsLeftRight,
    },
    {
      label: "Customers",
      value: "/merchant/customers",
      Icon: Users,
    },
    {
      value: "",
      type: "divider",
    },
    {
      label: "Fundraisers",
      value: "/merchant/fundraisers",
      CustomIcon: <DonationBox fill="currentColor" />,
      isForm: true,
    },
    {
      label: "Events",
      value: "/merchant/events",
      Icon: CalendarPlus,
      isForm: true,
    },
    {
      label: "Products",
      value: "/merchant/standard",
      CustomIcon: <ProductSidePanelIcon fill="currentColor" />,
      isForm: true,
      name: "standards",
    },
    {
      label: "Invoices",
      value: "/merchant/invoices",
      CustomIcon: <FileDollar fill="currentColor" />,
      isForm: true,
    },
    {
      label: "Memberships",
      value: "/merchant/memberships",
      Icon: SealCheck,
      isForm: true,
    },
    {
      label: "Sweepstakes",
      value: "/merchant/sweepstakes",
      Icon: Ticket,
      isForm: true,
    },
    ...(isAddProductAllowed
      ? [
          {
            label: "New Payment Form",
            value: "new-form",
            Icon: PlusSquare,
          },
        ]
      : []),
  ],
  footer: [
    {
      label: "Settings",
      value: "/merchant/settings",
      Icon: GearSix,
    },
  ],
});

export default getMerchantMenu;
