import { customInstance } from "@services/api";
import { TChallengeTypeName, TNotifyData } from "../types";

export type EDDData = {
  name: string;
  notifiedMessage: string;
  notifyMerchant?: boolean;
  isInternalNote?: boolean;
};

type TQueryEDDChallenge = {
  merchantID: number;
  challengeID?: number;
  data?: EDDData;
};

export const useEDDChallenge = async ({
  merchantID,
  challengeID,
  data,
}: TQueryEDDChallenge) => {
  return await customInstance({
    url: `/merchants/${merchantID}/underwriting-challenges${
      challengeID ? `/${challengeID}` : ""
    }`,
    method: !challengeID ? "POST" : !data ? "DELETE" : "PATCH",
    data,
  });
};

type TUpdateChallange = {
  merchantID: number;
  challengeID?: number;
  data: {
    reason: string;
    status: "open" | "closed" | "rejected";
    type: TChallengeTypeName;
  };
};

export const useUpdateChallenge = async ({
  merchantID,
  challengeID,
  data,
}: TUpdateChallange) => {
  return await customInstance({
    url: `/merchants/${merchantID}/underwriting-challenges/${challengeID}/status`,
    method: "PATCH",
    data,
  });
};

type TNotifyMerchant = {
  merchantID: number;
  challengeID: number;
  data: TNotifyData;
};

export const useNotifyMerchant = async ({
  merchantID,
  challengeID,
  data,
}: TNotifyMerchant) => {
  return await customInstance({
    url: `merchants/${merchantID}/underwriting-challenges/${challengeID}/notify`,
    method: "POST",
    data,
  });
};
