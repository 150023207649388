import { TDocument } from "../types";

const VALID_FILE_EXTENSIONS = ["pdf", "jpg", "jpeg", "png", "webp"];

export const isValidExtension = (type?: string) => {
  if (!type || typeof type !== "string") return false;
  return VALID_FILE_EXTENSIONS.some((extension) =>
    type.toLowerCase().includes(extension),
  );
};

export const sanitizeList = (list: TDocument[]) => {
  if (!Array.isArray(list)) return [];

  const filteredList = list.filter((document) => {
    if (!document?.name || !document?.type || !document?.URL) return false;
    return isValidExtension(document.type);
  });

  return filteredList;
};
