import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { Box, TableCell } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";
import GiveEmptyStateWrapper from "@shared/EmptyState/GiveEmptyStateWrapper";
import GiveTable from "@shared/Table/GiveTable";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled, useAppTheme } from "@theme/v2/Provider";
import React, { memo, useCallback, useMemo } from "react";
import { TableColumnType, TableProps } from "./Table.types";
import TableFooterContainer from "./TableFooterContainer";
import TableHeaderComponent from "./TableHeaderComponent";
import TablePagination from "./components/TablePagination";
import { useTableSort } from "./hooks/useTableSort";

const Table = memo(
  ({
    type,
    allRows,
    isLoading,
    totalRows,
    page,
    setPageDispatcher,
    columns,
    emptyStateAction,
    searchQuery,
    handleSearchChange,
    setSelectedRowIdx,
    selectedRowIdx,
    loadMore,
    numberOfPages,
    top,
    Head,
    onScroll,
    handleResetFilters,
    hasFilters,
    defaultSortKey,
    showMobileColumnHeader,
    isCurrentTabEmpty,
    enabledInfiniteScroll,
  }: TableProps) => {
    const { isWideView } = useCustomThemeV2();
    const shouldLoadMore = enabledInfiniteScroll || !isWideView;

    const { palette } = useAppTheme();
    const { handleClick, sortKey, order } = useTableSort({
      defaultSort: { key: defaultSortKey || "createdAt,-accID", isDesc: true },
    });

    const getWidth = useCallback(
      (index: number, col: TableColumnType) => {
        const length = columns.length + 1;
        let columnWidth = 1 / length;
        if (index === 0) columnWidth = 2.2 / length;
        if (col.key === "actions") columnWidth = 0.8 / length;

        return typeof col.columnWidth !== "number"
          ? col.columnWidth
          : `${((col.columnWidth ?? 0) / length || columnWidth) * 100}%`;
      },
      [columns],
    );
    const { isMasqueradeMode } = useMasqueradeReducer();

    const isEmpty = totalRows === 0 && !isLoading;
    const hasSearch = searchQuery !== "";
    const isResultsEmpty = isEmpty && !isLoading;

    const isSearchResultsEmpty = hasSearch && isResultsEmpty;
    const isFilterResultsEmpty = !hasSearch && hasFilters && isResultsEmpty;

    const isShowHeader =
      !isResultsEmpty && (isWideView || showMobileColumnHeader);

    const headerContent = useMemo(() => {
      const HeaderComponent = () => (
        <>
          <caption
            style={{
              captionSide: "top",
              padding: 0,
            }}
          >
            {Head}
          </caption>
          {isShowHeader && (
            <TableHeaderComponent
              columns={columns}
              sortKey={sortKey}
              order={order}
              handleClick={handleClick}
              getWidth={getWidth}
            />
          )}
        </>
      );

      HeaderComponent.displayName = "HeaderComponent";

      return HeaderComponent;
    }, [isWideView, columns, handleClick, sortKey, order, isResultsEmpty]);

    const isInfiniteScroll = enabledInfiniteScroll || !isWideView;
    const hasPagination = !isInfiniteScroll && !!numberOfPages;

    const emptyState = useMemo(() => {
      if (hasSearch && hasFilters && isResultsEmpty)
        return {
          type: "empty-search-and-filter" as const,
          action: () => handleSearchChange?.(""),
        };
      if (isFilterResultsEmpty)
        return {
          type: "no-filter-results" as const,
          action: handleResetFilters,
        };
      if (isSearchResultsEmpty)
        return {
          type: "empty-search" as const,
          action: () => handleSearchChange?.(""),
        };
      if (isCurrentTabEmpty)
        return {
          type: "emptyTab" as const,
          action: () => null,
        };

      return {
        type,
        action: emptyStateAction?.handleAction,
      };
    }, [
      isSearchResultsEmpty,
      isFilterResultsEmpty,
      isCurrentTabEmpty,
      emptyStateAction,
      type,
      hasSearch,
      hasFilters,
      isResultsEmpty,
    ]);

    const emptyStateContent = useCallback(() => {
      return (
        <tbody>
          <tr>
            <TableCell
              colSpan={columns.length}
              sx={{ paddingTop: "64px", borderWidth: 0 }}
            >
              <GiveEmptyStateWrapper
                isEmpty={isEmpty}
                section={emptyState.type}
                searchValue={searchQuery}
                action={{
                  handleAction: emptyState.action,
                  disabled:
                    isEmpty && !hasFilters && !hasSearch
                      ? emptyStateAction?.disabled
                      : false,
                }}
              />
            </TableCell>
          </tr>
        </tbody>
      );
    }, [
      isSearchResultsEmpty,
      isFilterResultsEmpty,
      hasFilters,
      hasSearch,
      searchQuery,
      type,
      emptyState,
    ]);

    const itemContent = (rowIdx: number, row: unknown) => {
      return (
        <InnerItem
          rowIdx={rowIdx}
          row={row}
          getWidth={getWidth}
          columns={columns}
          key={rowIdx}
        />
      );
    };
    return (
      <Container pb={isMasqueradeMode && isWideView ? "20px !important" : 0}>
        <GiveTable
          top={top}
          data={allRows}
          columns={columns}
          hasPagination={hasPagination}
          isLoading={isLoading}
          onScroll={onScroll}
          rowProps={(_, index) => {
            return {
              onClick: () => setSelectedRowIdx?.(index),
              sx: {
                backgroundColor:
                  selectedRowIdx === index
                    ? palette.primitive?.transparent["darken-2"]
                    : "inherit",
              },
            };
          }}
          itemContent={itemContent}
          fixedHeaderContent={headerContent}
          emptyStateContent={emptyStateContent}
          isInfiniteScroll={isInfiniteScroll}
          endReached={shouldLoadMore ? loadMore : undefined}
        />
        <TableFooterContainer hasPagination={hasPagination}>
          <TablePagination
            page={page}
            numberOfPages={numberOfPages}
            setPageDispatcher={setPageDispatcher}
          />
        </TableFooterContainer>
      </Container>
    );
  },
);

export default Table;

Table.displayName = "Table";

const InnerItem = React.memo(
  ({
    rowIdx,
    row,
    columns,
    getWidth,
  }: {
    rowIdx: number;
    row: unknown;
    columns: TableColumnType[];
    getWidth: (index: number, col: TableColumnType) => string | undefined;
  }) => {
    const { isWideView } = useCustomThemeV2();
    const { palette } = useAppTheme();
    const visibleColumns = columns.filter((column) => !column.hideColumn);
    const lastColumnIndex = visibleColumns.length - 1;

    return (
      <>
        {columns.map((column, colIdx) => {
          if (column?.hideColumn) return null;
          const isLastColumn =
            visibleColumns.indexOf(column) === lastColumnIndex;
          return (
            <TableCell
              key={`${rowIdx}-${colIdx}`}
              width={getWidth(colIdx, column)}
              sx={{
                padding: 0,
                borderBottomColor: "transparent",
              }}
            >
              <CellContainer
                style={{
                  padding: !isWideView ? "16px 0px" : "16px",
                  justifyContent:
                    !isWideView && isLastColumn ? "end" : undefined,
                }}
              >
                {column.renderColumn && column.renderColumn(row)}
                {!isWideView && isLastColumn && (
                  <CaretRight
                    size={16}
                    color={palette.icon?.["icon-secondary"]}
                  />
                )}
              </CellContainer>
            </TableCell>
          );
        })}
      </>
    );
  },
);

InnerItem.displayName = "InnerItem";
const CellContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "13.5px",
  height: "100%",
  borderBottom: `1px solid ${theme.palette.border?.primary}`,
  [theme.breakpoints.down("v2_sm")]: {
    gap: "8px",
  },
}));

const Container = styled(Box)(({ theme }) => ({
  zIndex: 100,
  flex: 1,
  display: "flex",
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  background: "transparent",
  gap: "24px",
  justifyContent: "center",
  width: "100%",
  maxWidth: "1920px",
  minHeight: "400px",
}));
