import { Divider, useMediaQuery, Avatar } from "@mui/material";
import { palette } from "@palette";
import { MemberData, MemberRoleName } from "@customTypes/team.member";
import { CloseIcon } from "@assets/icons";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import InfoDisplay from "./InfoDisplay";
import moment from "moment";
import PermissionsPanel from "./PermissionsPanel/PermissionsPanel";
import { useAppSelector } from "@redux/hooks";
import {
  PanelContainer,
  TeamInfoContainer,
  StyledIconButton,
} from "./TeamPanelBody.styles";
import FadeInWrapper from "@components/animation/FadeInWrapper";
import ChangeRole from "./ChangeRole";
import DeleteMember from "./DeleteMember";
import { selectUser } from "@redux/slices/auth/auth";
import { useTeamPermissions } from "features/Permissions/AccessControl/hooks";
import { useState } from "react";
import { LoadingSkeleton } from "./components";
import InfoEdit from "features/Permissions/ModalV2/components/InfoEdit";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  rowData: MemberData;
  handleClose?: () => void;
  showPermissions: boolean;
};
export interface EditInfo {
  Joined: string;
  "First name": string;
  "Last name": string;
  Email: string;
  "Job Title": string;
  Employer: string;
}
const TeamPanelBody = ({ rowData, handleClose, showPermissions }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const { isMobileView } = useCustomTheme();
  const { firstName, lastName, imageURL, email, accID, occupation, employer } =
    rowData?.user || {};
  const image = addSizeToImage(imageURL, "medium");
  const { email: currentUserEmail } = useAppSelector(selectUser);

  const [currentRole, setCurrentRole] = useState<MemberRoleName>(
    rowData.roleName,
  );
  const [currentData, setCurrentData] = useState<EditInfo>({
    Joined: moment(new Date(rowData.joinedAt * 1000)).format("MM/DD/YYYY"),
    "First name": firstName,
    "Last name": lastName,
    Email: email,
    "Job Title": occupation,
    Employer: employer,
  });

  const updateRole = (newRole: MemberRoleName) => {
    setCurrentRole(newRole);
  };

  const hasNotGetPermissionsListPermission = useAppSelector(
    (state) => state.app.permissions?.["get-team-members-all-permissions"],
  );
  const isCurrentUser = currentUserEmail === email;

  const {
    isEditAllowed,
    isDeleteAllowed,
    isListAccessPoliciesAllowed,
    isDeleteOwnerAllowed,
  } = useTeamPermissions();
  const [isEdit, setEdit] = useState(false);
  function onClickEdit() {
    setEdit(true);
  }

  const fullName = `${currentData["First name"]} ${currentData["Last name"]}`;
  const teamMemberDetails = [
    {
      section: (
        <Avatar
          src={image}
          sx={{
            borderRadius: "50%",
            width: 94,
            height: 94,
            marginInline: "auto",
          }}
        />
      ),
      delay: 250,
    },
    {
      section: isEdit ? (
        <InfoEdit
          items={currentData}
          setCurrentData={setCurrentData}
          id={accID}
          setEdit={setEdit}
        />
      ) : (
        <InfoDisplay
          items={{
            Joined: currentData.Joined,
            "Full name": fullName === " " ? "-" : fullName,
            Email: currentData.Email,
            "Job Title": currentData["Job Title"] || "-",
            Employer: currentData.Employer || "-",
          }}
          onClickEdit={onClickEdit}
          isEditAllowed={isEditAllowed}
        />
      ),
      delay: 300,
    },
    ...(isMobileView
      ? []
      : [
          {
            section: <Divider />,
            delay: 300,
          },
        ]),
    {
      section: (
        <ChangeRole
          role={currentRole}
          updateRole={updateRole}
          isCurrentUser={isCurrentUser}
          isOwner={rowData?.roleName === "owner" || false}
          id={accID}
          isEditAllowed={isEditAllowed}
        />
      ),
      delay: 350,
    },
    {
      section: <Divider />,
      delay: 350,
    },
    {
      section: (
        <DeleteMember
          rowData={rowData}
          isCurrentUser={isCurrentUser}
          isDeleteAllowed={isDeleteAllowed}
          isDeleteOwnerAllowed={isDeleteOwnerAllowed}
        />
      ),
      delay: 400,
    },
  ];

  return (
    <PanelContainer>
      <TeamInfoContainer>
        <StyledIconButton
          data-testid="team-panel-close-button"
          aria-label="close"
          onClick={handleClose}
          size="small"
          sx={{
            position: "absolute",
            top: 0,
            right: 16,
          }}
        >
          <CloseIcon width={24} height={24} stroke={palette.neutral[70]} />
        </StyledIconButton>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          teamMemberDetails.map(({ section, delay }, index) => (
            <FadeInWrapper key={index} delay={delay}>
              {section}
            </FadeInWrapper>
          ))
        )}
      </TeamInfoContainer>
      {isDesktop &&
        showPermissions &&
        isListAccessPoliciesAllowed &&
        !hasNotGetPermissionsListPermission && (
          <PermissionsPanel
            memberId={rowData.user.accID}
            setIsLoading={setIsLoading}
          />
        )}
    </PanelContainer>
  );
};

export default TeamPanelBody;
