import { Stack } from "@mui/material";
import { Lock } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import PageContainer from "./PageContainer";

const UnpublishedPage = () => {
  const { palette } = useAppTheme();
  return (
    <>
      <PageContainer
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: "16px",
            borderRadius: "40px",
            backgroundColor: palette.primitive?.transparent["darken-5"],
            height: "64px",
            width: "64px",
          }}
        >
          <Lock width="24px" height="27px" />
        </Stack>
        <GiveText
          fontSize="28px"
          sx={{
            color: palette.primitive?.neutral[90],
          }}
        >
          This page has not been published
        </GiveText>
        <GiveText
          fontSize="14px"
          sx={{
            color: palette.primitive?.neutral[60],
          }}
        >
          Only authorised members can view the draft
        </GiveText>
      </PageContainer>
    </>
  );
};

export default UnpublishedPage;
