import { Stack } from "@mui/material";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { ArrowLineLeft } from "@phosphor-icons/react";
import { NEW_BASE_PANEL_WIDTH } from "@shared/SidePanel/GiveSidePanel";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import GiveMotionWrapper from "@shared/Motion/GiveMotionWrapper";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";

const NAVIGATION_ELEMENT_WIDTH = 64;
const NAVIGATION_ELEMENT_HEIGHT = 110;

type Props = {
  show: boolean;
  image: string;
};
const CollapseNavigation = ({ image, show }: Props) => {
  const theme = useAppTheme();

  const {
    contentHistory,
    firstPanelContent,
    setContentHistory,
    setFirstPanelContent,
    secondPanelContent,
    isSinglePanelSize,
    setSecondPanelContent,
  } = useMerchantSidePanelContext();
  const handleBack = () => {
    if (secondPanelContent) {
      if (isSinglePanelSize) {
        setSecondPanelContent("");
        return;
      }
      if (!firstPanelContent) {
        setFirstPanelContent(
          contentHistory === "default" ? "" : contentHistory,
        );
        setContentHistory("");
      }
      return;
    }
    setFirstPanelContent(contentHistory === "default" ? "" : contentHistory);
    setContentHistory("");
  };

  return (
    <GiveMotionWrapper
      type="slide"
      inView={show}
      direction={"left"}
      delay={300}
    >
      <StyledContainer data-testid="panel1-minimized-image">
        <GiveIconButton
          Icon={ArrowLineLeft}
          onClick={handleBack}
          sx={{
            backgroundColor: theme.palette.surface?.secondary,
            "&:hover": {
              backgroundColor: theme.palette.primitive?.transparent["darken-5"],
            },
          }}
        />
        <GiveAvatar size="32px" shape="square" imageUrl={image} />
      </StyledContainer>
    </GiveMotionWrapper>
  );
};

const StyledContainer = styled(Stack)(({ theme }) => ({
  width: `${NAVIGATION_ELEMENT_WIDTH}`,
  height: `${NAVIGATION_ELEMENT_HEIGHT}`,
  borderBottomLeftRadius: theme.customs?.radius.medium,
  padding: "16px",
  position: "fixed",
  left: `calc(100% - ${NEW_BASE_PANEL_WIDTH + NAVIGATION_ELEMENT_WIDTH}px)`,
  boxShadow: "initial",
  backgroundColor: theme.palette.surface?.secondary,
  gap: "16px",
  alignItems: "center",
  zIndex: -1,
}));

export default CollapseNavigation;
