import { Box, Stack } from "@mui/material";
import { Check } from "@phosphor-icons/react";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme, styled } from "@theme/v2/Provider";
import VisaCard from "@assets/images/PaymentCards/visa.png";
import MasterCard from "@assets/images/PaymentCards/mastercard.png";
import AmexCard from "@assets/images/PaymentCards/amex.png";
import { DiscoverCardIcon } from "@assets/builderIcons";

function CardTypes() {
  const { palette } = useAppTheme();

  return (
    <SectionCardBase
      leftTitle="Card Types"
      childrenContainerSx={{
        bgcolor: "transparent",
        p: "0px",
      }}
      sx={{
        marginTop: "20px",
      }}
    >
      <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
        {cardTypesInfo.map(({ name, Icon }) => {
          return (
            <Container key={name}>
              {Icon}
              <GiveText fontSize="12px" color="primary">
                {name}
              </GiveText>
              <Check fill={palette.text.primary} size="16px" />
            </Container>
          );
        })}
      </Box>
    </SectionCardBase>
  );
}

const Image = styled("img")<{ hasBackground?: boolean }>(
  ({ theme, hasBackground }) => ({
    height: 18,
    width: 28,
    ...(hasBackground && {
      borderRadius: "2px",
      background: theme.palette.primitive?.neutral[0],
    }),
  }),
);

const Container = styled(Stack)(({ theme }) => {
  return {
    gap: "8px",
    flexDirection: "row",
    borderRadius: "8px",
    padding: "8px",
    alignItems: "center",
    margin: "4px",
    backgroundColor: theme.palette.primitive?.transparent["darken-5"],
  };
});

const cardTypesInfo = [
  {
    Icon: <Image src={VisaCard} hasBackground />,
    name: "Visa",
  },
  {
    Icon: <Image src={MasterCard} />,
    name: "Mastercard",
  },
  {
    Icon: <Image src={AmexCard} />,
    name: "AMEX",
  },
  {
    Icon: <DiscoverCardIcon height={18} width={28} />,
    name: "Discover",
  },
];

export default CardTypes;
