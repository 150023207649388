import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { IComponentRepository, RepositoryConfig } from "./types";
import { UNDERWRITING_API_KEY_BASE } from "../constants";

export const createRepositoryHooks = <T>(
  config: RepositoryConfig,
  repository: IComponentRepository<T>,
) => ({
  useGetItemById: (id: string, options?: UseQueryOptions<T, Error>) => {
    const queryKey: QueryKey = ["item", config.tag, config.baseUrl, id];

    const result = useQuery({
      queryKey,
      queryFn: () => repository.getById(config.baseUrl, id),
      ...options,
    });

    return {
      data: result.data,
      isLoading: result.isLoading,
      error: result.error,
      refetch: result.refetch,
    };
  },
  useGetItemsByQueryParams: (
    queryParams: string,
    options?: UseQueryOptions<T, Error>,
  ) => {
    const queryKey: QueryKey = [
      "item",
      config.tag,
      config.baseUrl,
      queryParams,
    ];

    const result = useQuery({
      queryKey,
      queryFn: () => repository.getByQueryParams(config.baseUrl, queryParams),
      ...options,
    });

    return {
      data: result.data,
      isLoading: result.isLoading,
      error: result.error,
      refetch: result.refetch,
    };
  },

  useGetItems: (
    urlParams: string,
    options?: UseQueryOptions<
      { total: number; data: T[] },
      Error,
      { total: number; data: T[] },
      QueryKey
    >,
  ) => {
    const queryKey: QueryKey = [
      UNDERWRITING_API_KEY_BASE,
      config.tag,
      config.baseUrl,
      urlParams,
    ];

    const result = useQuery({
      queryKey,
      queryFn: () => repository.getAll(config.baseUrl + urlParams),
      ...options,
    });

    return {
      data: result.data,
      total: result.data?.total,
      isLoading: result.isLoading,
      error: result.error,
      refetch: result.refetch,
    };
  },
});
