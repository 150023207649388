import React, { useState } from "react";
import { StatusInfo } from "../../agreements.types";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import { RenderTextsComponent } from "../../Snapshot/components/BaseCardComponent";
import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { useAsyncAction } from "@hooks/customReactCore";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { useQueryClient } from "react-query";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { addSizeToImage } from "@utils/image.helpers";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  NEW_ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import useAgreementSnapshot from "@components/Merchants/MerchantPreview/MerchantAgreement/AgreementSnapshot/hooks/useAgreementSnapshot";
import SignAgreementModal from "features/Merchants/MerchantSidePanel/Modals/Signature/SignAgreementModal";
import MerchantAgreementPDFLink from "./PDFLink";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";

interface Props {
  statusInfo?: StatusInfo;
  radioState?: {
    isPciChecked: boolean;
    isPreviousTerminationChecked: boolean;
  };
  canBeHidden?: boolean;
}

const SignedBySection: React.FC<Props> = ({ statusInfo, radioState }) => {
  const { status, signedBy, signedOn, msaLastAcceptedVersion, signatureURL } =
    statusInfo || {};
  const { data, id: merchantId } = useMerchantSidePanelContext();
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);
  const isUpdateAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });
  const [isLoading, triggerAction] = useAsyncAction();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const queryClient = useQueryClient();

  const { agreement_signing } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();

  const hiddenSection = !agreement_signing && isEnterprisePortal;

  const closeSignModal = () => setIsSignModalOpen(false);
  const isDisabled = !radioState?.isPciChecked || isLoading || !isUpdateAllowed;

  const isTooltipEnabled = !isUpdateAllowed;

  // Determine the appropriate signature upload handler
  const { handleUploadSignature: handleUploadSignatureNew } =
    useAddSignatureNew();
  const { handleUploadSignature: handleUploadSignatureOld } =
    useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const shouldFetchSnapshot =
    data?.status?.statusName === "approved" &&
    data?.merchantAgreement?.msaHadAgreed;

  const { data: snapshot, isLoading: isSnapshotLoading } = useAgreementSnapshot(
    {
      merchantId: merchantId || 0,
      enabled: shouldFetchSnapshot,
    },
  );

  const { handleSubmit } = useUpdateMerchantInfo(merchantId || 0);

  // Handle signature upload
  const handleSignature = async (file: File) => {
    if (!merchantId) return;
    const signatureUrl = await handleUploadSignature(merchantId, file, false);
    if (!signatureUrl) return;

    const payload: Partial<TMerchantAgreementPayload> = {
      msaHadAgreed: true,
      msaPCICompliant: true,
      msaRefundPolicy:
        data?.merchantAgreement?.msaRefundPolicy || "30 Days of Purchase",
      signatureURL: signatureUrl,
    };

    await handleSubmit("merchant_agreement", payload);
    await queryClient.refetchQueries("get_agreement_snapshot");
  };

  const uploadSignature = (file: File) => triggerAction(handleSignature, file);

  const handleSignAgreement = () => {
    if (isDisabled) return;

    setIsSignModalOpen(true);
  };

  const payload = [
    { label: "Signed by", value: signedBy },
    { label: "Signed at", value: signedOn },
    { label: "Version", value: msaLastAcceptedVersion },
  ];

  // Define component for signature section
  const SignatureSection: React.FC = () => (
    <GiveTooltip
      title={NEW_ACTION_DENY_MESSAGE}
      color="default"
      disableHoverListener={!isTooltipEnabled}
      customTooltipStyles={{ width: "fit-content" }}
    >
      <LoadedSignature
        isSigningDisabled={isDisabled}
        onClick={handleSignAgreement}
      >
        {signatureURL ? (
          <Image src={addSizeToImage(signatureURL, "medium")} alt="Signature" />
        ) : (
          <>
            {!isDisabled && (
              <GiveText textAlign="center" color="primary" variant="bodyS">
                Place your signature here
              </GiveText>
            )}
          </>
        )}
      </LoadedSignature>
    </GiveTooltip>
  );

  const snapshotData =
    data?.status?.statusName === "approved" ? snapshot : data;

  if (hiddenSection) return null;

  return (
    <SectionCardBase>
      {status === "notSigned" ? (
        <Stack flexDirection={{ xs: "column", sm: "row" }}>
          <Box>
            <Stack mb="8px" flexDirection="row" alignItems="center" gap="8px">
              <GiveText color="primary" variant="bodyS">
                Agreement Signature
              </GiveText>
              <NotSignedText>Not Signed</NotSignedText>
            </Stack>
            <GiveText color="secondary" variant="bodyXS">
              Please sign the agreement by drawing or uploading your digital
              signature, or by automatically generating it from your name.
            </GiveText>
          </Box>
          <SignatureSection />
        </Stack>
      ) : (
        <>
          {payload.map(({ label, value }) => (
            <RenderTextsComponent key={label} label={label} value={value} />
          ))}
          <Stack
            justifyContent="space-between"
            alignItems="baseline"
            flexDirection={{ xs: "column", sm: "row" }}
            spacing="16px"
            mt="16px"
          >
            <MerchantAgreementPDFLink
              isSnapshotLoading={isSnapshotLoading}
              snapshotData={snapshotData}
            />
            <SignatureSection />
          </Stack>
        </>
      )}
      <SignAgreementModal
        uploadSignature={uploadSignature}
        open={isSignModalOpen}
        onClose={closeSignModal}
      />
    </SectionCardBase>
  );
};

export default SignedBySection;

// Styled components
const NotSignedText = styled(GiveText)(({ theme }) => ({
  color: theme.palette.primitive?.error[100],
  border: `1px solid ${theme.palette.primitive?.error[50]}`,
  backgroundColor: theme.palette.primitive?.error[25],
  borderRadius: "8px",
  padding: "5px 8px",
  width: "79px",
  height: "24px",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  whiteSpace: "nowrap", // Prevent text wrapping
  overflow: "hidden", // Hide overflow text
  textOverflow: "ellipsis", // Add ellipsis for overflowing text
}));

const LoadedSignature = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSigningDisabled",
})<{ isSigningDisabled?: boolean }>(({ isSigningDisabled = true, theme }) => ({
  cursor: isSigningDisabled ? "default" : "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "132px",
  minWidth: "132px",
  height: "108px",
  borderRadius: "6px",
  background: theme.palette.surface?.secondary,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Image = styled("img")({
  height: "100%",
  width: "100%",
  objectFit: "cover",
});
