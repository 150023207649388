import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import useCheckFormType from "./hooks/useCheckFormType";

function Stepper({ isLaunchVisible }: { isLaunchVisible?: boolean }) {
  const { activeStepIndex, goToStep, lastStepCompletedIndex, stepsArray } =
    usePayBuilderContext();
  const { isMobileView } = useCustomThemeV2();
  const { methods } = usePayBuilderForm();
  const { isEvent } = useCheckFormType();

  const isEditAndHeadingCompleted = methods.formState.isValid;

  return (
    <Container flexDirection="row">
      {stepsArray.map(({ label, id }, index) => {
        const isActive = index === activeStepIndex;

        return (
          <ItemBox
            onClick={() =>
              goToStep({
                index,
                free: isEditAndHeadingCompleted,
              })
            }
            isActive={isActive}
            key={label}
            isMobileView={isMobileView}
            sx={{
              maxWidth: `${100 / stepsArray.length}%`,
              minWidth: `${100 / stepsArray.length}%`,
            }}
          >
            <CustomDivider
              isCompleted={index <= lastStepCompletedIndex}
              isActive={isActive}
            />
            <CustomText isActive={isActive} isLaunchVisible={isLaunchVisible}>
              {label}
            </CustomText>
          </ItemBox>
        );
      })}

      <GiveText
        fontSize="12px"
        display={{
          xs: "block",
          sm: "none",
        }}
        color="primary"
        fontWeight={400}
      >
        {activeStepIndex + 1}/{stepsArray?.length}
      </GiveText>
    </Container>
  );
}

export default Stepper;

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  overflowX: "auto",
}));

const CustomText = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isActive" && prop !== "isLaunchVisible",
})<{ isActive?: boolean; isLaunchVisible?: boolean }>(
  ({ theme, isActive = false, isLaunchVisible }) => ({
    display: "block",
    fontSize: "12px",
    color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
    visibility: "visible",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    ...(!isLaunchVisible && {
      fontSize: "12px",
    }),
  }),
);

const ItemBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isMobileView",
})<{ isActive?: boolean; isMobileView: boolean }>(
  ({ theme, isActive, isMobileView }) => {
    return {
      borderRadius: "8px",
      padding: "10px",
      backgroundColor: isActive
        ? isMobileView
          ? "transparent"
          : theme.palette.primitive?.transparent["darken-5"]
        : "transparent",
      flexGrow: 1,
      flexBasis: 0,
      cursor: "pointer",
    };
  },
);

const CustomDivider = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean; isCompleted?: boolean }>(
  ({ theme, isActive = false, isCompleted = false }) => ({
    marginBottom: "9px",
    backgroundColor: (function () {
      if (isActive) {
        return theme.palette.primitive?.neutral[90];
      }
      if (isCompleted) {
        return theme.palette.primitive?.neutral[60];
      }
      return theme.palette.primitive?.transparent["darken-25"];
    })(),
    borderRadius: "2px",
    height: "2px",
    position: "relative",
    width: "100%",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: isActive
        ? theme.palette.icon?.["icon-primary"]
        : theme.palette.primitive?.transparent["darken-2"],
      height: "100%",
      width: isCompleted ? "100%" : 0,
      borderRadius: "2px ",
    },
  }),
);
