import { HeaderText } from "@components/Merchants/MerchantPreview/sections/StatusReview/components/StatusReviewAtoms";
import { Grid, Stack } from "@mui/material";
import { styled, useAppTheme } from "@theme/v2/Provider";
import type { TMerchantDataFields } from "../GiveMerchantSectionsContainer";
import RiskActivity from "./RiskActivity";
import GiveChip, { TChipColors } from "@shared/Chip/GiveChip";
import { Check, Detective, Siren } from "@phosphor-icons/react";
import GiveAssignButton from "@shared/AssignButton/GiveAssignButton";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useComponentVisibilityOnStatus } from "@components/Merchants/MerchantPreview/sections/StatusReview/hooks/useComponentVisibilityOnStatus";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RiskMonitorButton from "./components/RiskMonitorButton";
import { checkPortals } from "@utils/routing";

const RiskSummarySection = ({
  merchantId,
  riskProfile,
  status,
  id,
}: TMerchantDataFields) => {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const {
    level,
    riskStatusName: _riskStatusName,
    profileID,
  } = riskProfile || {};
  const { underwriterName, underwriterImageURL, underwriterEmail } =
    status || {};
  const riskLabel = level || "low";
  const riskStatusName = _riskStatusName || "routine_monitoring";

  const { risk_monitoring } = useEnterprisePermissions();

  const normalizedLabel = riskLabel.toLowerCase();
  const { isRiskSummaryVisible } = useComponentVisibilityOnStatus({
    data: status,
  });
  const color = {
    low: "success",
    critical: "error",
    medium: "warning",
    high: "warning2",
  };
  const canUpdateEscalation = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.RISK_PROFILE_ESCALATION,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const canUpdateTrigger = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.RISK_PROFILE_FACTOR,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const canReadTransactions = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.TRANSACTION,
    ),
    operation: OPERATIONS.READ,
  });

  const isRiskMonitorDisabled = [
    canReadTransactions,
    canUpdateTrigger,
    canUpdateEscalation,
    isRiskSummaryVisible,
    risk_monitoring,
  ].includes(false);



  const secondChip: Record<
    string,
    { label: string; Icon: JSX.Element; chipColor: TChipColors }
  > = {
    routine_monitoring: {
      label: "Routine Monitoring",
      Icon: <Check fill={palette?.primitive?.success[100]} size="16px" />,
      chipColor: "success",
    },
    active_monitoring: {
      label: "Active Monitoring",
      Icon: <Detective fill={palette.primitive?.error[100]} size="16px" />,
      chipColor: "error",
    },
    alerted: {
      label: "Alerted",
      Icon: <Siren fill={palette.primitive?.warning[100]} size="16px" />,
      chipColor: "warning",
    },
  };
  const { label, chipColor, Icon } =
    secondChip[riskStatusName as keyof typeof secondChip];

  return (
    <Stack spacing={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        gap={isMobileView ? 2 : 0}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <HeaderText
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: palette.primitive?.neutral[90],
            }}
            isMobile={isMobileView}
          >
            Risk Summary
          </HeaderText>
          <Stack direction="row" alignItems="center" gap={1}>
            <StyledGiveChip
              label={riskLabel?.charAt(0).toUpperCase() + riskLabel?.slice(1)}
              color={
                (color[normalizedLabel as keyof typeof color] as TChipColors) ||
                "default"
              }
              variant="outline"
            />
            <StyledGiveChip
              icon={Icon}
              label={label}
              color={chipColor}
              variant="outline"
            />
          </Stack>
        </Stack>
      </Grid>
      <ContainerStack gap={3}>
        <RiskActivity
          profileId={profileID}
          merchantId={merchantId}
          riskLabel={riskLabel}
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.05) !important",
          }}
        />

        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          width="100%"
        >
          <GiveAssignButton
            title="Assign"
            data={{
              accID: id,
              underwriterName,
              underwriterImageURL,
              underwriterEmail,
            }}
          />
          <RiskMonitorButton isDisabled={isRiskMonitorDisabled} />
        </Stack>
      </ContainerStack>
    </Stack>
  );
};

const ContainerStack = styled(Stack)(({ theme }) => ({
  padding: "20px",
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette?.surface?.primary,
}));

const StyledGiveChip = styled(GiveChip)({
  fontSize: "12px",
  "&.MuiChip-root": {
    gap: "4px",
    paddingInline: "7px",
  },
  "& .MuiChip-icon": {
    margin: 0,
    fontSize: "16px",
  },
  "& span": {
    lineHeight: "normal",
  },
});

export default RiskSummarySection;
