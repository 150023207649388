import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import useGetSectionItems from "../hooks/useGetSectionItems";
import BusinessOwnersSection from "./BusinessOwnersSection";
import { Stack } from "@mui/material";
import BankAccountSection from "./BankAccountSection";
import SimpleCardSection from "./SimpleCardSection";
import FeesSection from "./FeesSection";
import DocumentsSection from "./DocumentsSection";
import { checkPortals } from "@utils/routing";
import useMerchantPlaceholders from "../hooks/useMerchantPlaceholders";
import PlaceholderSection from "./PlaceholderSection";
import PAHInfoSection from "features/Merchants/MerchantSidePanel/components/InviteSentPanelContent/components/PAHInfoSection";
import BusinessProfileSection from "../businessProfile/BusinessProfileSection";
import MerchantInfoSection from "./MerchantInfoSection";
import { Fragment }  from "react";

function GiveMerchantFileBody() {
  const {
    modify_merchant,
    merchant_underwriting,
    bank_account_linking,
    manage_bank_account,
    money_transfers,
  } = useEnterprisePermissions();
  const { data, id } = useMerchantSidePanelContext();
  const { businessProfileItems, primaryAccountHolderItems, merchantInfoItems } =
    useGetSectionItems(data);
  const {
    businessOwnersList,
    bankAccountList,
    bankAccountSettings,
    fees,
    documents,
  } = data;
  const { placeholdersList } = useMerchantPlaceholders(data);

  const invitationStatus = data.primaryAccountHolder?.inviteStatusDisplayName;

  const hasFullPAH =
    !!data?.primaryAccountHolder.email &&
    !!data?.primaryAccountHolder.firstName &&
    !!data?.primaryAccountHolder.lastName;

  const { isEnterprisePortal } = checkPortals();

  const merchantFileBodyContent = [
    {
      component: <MerchantInfoSection items={merchantInfoItems} />,
      hide: false,
    },
    {
      component: <PlaceholderSection placeholdersList={placeholdersList} />,
      hide: !modify_merchant,
    },
    {
      component: (
        <SimpleCardSection
          variant="ofac"
          disabled={
            !data?.businessProfile?.taxID &&
            !data?.businessProfile.ssn &&
            !hasFullPAH &&
            data?.businessOwnersList?.length < 1
          }
        />
      ),
      hide: !merchant_underwriting,
    },
    {
      component: (
        <SimpleCardSection
          variant="match"
          disabled={!data?.businessProfile?.taxID && !data?.businessProfile.ssn}
        />
      ),
      hide: !merchant_underwriting,
    },
    {
      component: (
        <BankAccountSection
          merchantId={id}
          bankAccounts={bankAccountList}
          parentAccID={data?.merchantInfo.enterpriseID}
          bankAccountSettings={bankAccountSettings}
        />
      ),
      hide:
        (!modify_merchant &&
          !(bank_account_linking || manage_bank_account || money_transfers)) ||
        bankAccountList?.length === 0,
    },
    {
      component: (
        <PAHInfoSection items={primaryAccountHolderItems} id={id} data={data} />
      ),
      hide:
        !data?.primaryAccountHolder?.email || invitationStatus === "In progress",
    },
    {
      component: (
        <BusinessProfileSection
          id={id}
          data={data}
          items={businessProfileItems}
          isEnterprise={isEnterprisePortal}
        />
      ),
      hide:
        (!data?.businessProfile?.taxID && !data?.businessProfile?.ssn) ||
        !modify_merchant,
    },
    {
      component: (
        <BusinessOwnersSection
          businessOwners={businessOwnersList}
          merchantId={id}
        />
      ),
      hide: data.businessOwnersList?.length === 0 || !modify_merchant,
    },

    {
      component: <FeesSection id={id} data={fees} />,
    },
    {
      component: (
        <DocumentsSection
          id={id}
          data={documents}
          isEnterprise={isEnterprisePortal}
          title="Documents"
        />
      ),
      hide: !modify_merchant && isEnterprisePortal,
    },
  ];

  return (
    <Stack px={2.5} pb={2} pt={4} gap={4}>
    {merchantFileBodyContent?.map(({ component, hide }, index) => {
      if (hide) return null;
      return <Fragment key={index}>{component}</Fragment>;
    })}
  </Stack>
  );
}

export default GiveMerchantFileBody;
