import { DrawerProps, SxProps, useMediaQuery } from "@mui/material";
import GiveMotionWrapper from "@shared/Motion/GiveMotionWrapper";
import PanelWrapper from "@shared/SidePanel/components/PanelWrapper";
import {
  FirstPanelContainer,
  SecondPanelContainer,
} from "@shared/SidePanel/GiveSidePanel.style";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { ReactNode } from "react";
import { SidePanelButton } from "./SidePanelAtoms";
import ErrorCatcher from "@common/Error/ErrorCatcher";

export const MIN_ALLOWED_SCREEN_SIZE_FOR_DOUBLE_PANELS = 1280;
export const NEW_BASE_PANEL_WIDTH = 600;

export interface IGiveSidePanelProps extends DrawerProps {
  isSecondPanelOpen?: boolean;
  secondPanel?: ReactNode;
  isSingleOnly?: boolean;
  width?: number;
  containerProps?: { firstPanel?: SxProps; secondPanel?: SxProps };
  floatingContent?: ReactNode;
  isFirstPannelHidden?: boolean;
}

const GiveSidePanel = ({
  isSecondPanelOpen = false,
  isSingleOnly = false,
  children,
  secondPanel,
  width = NEW_BASE_PANEL_WIDTH,
  containerProps,
  floatingContent,
  isFirstPannelHidden = false,
  ...rest
}: IGiveSidePanelProps) => {
  const { isMobileView } = useCustomThemeV2();

  const isSinglePanelOnly =
    isMobileView ||
    window.innerWidth < MIN_ALLOWED_SCREEN_SIZE_FOR_DOUBLE_PANELS ||
    isSingleOnly;

  const isDoublePanelMode = isFirstPannelHidden
    ? false
    : isSecondPanelOpen && !isSinglePanelOnly;

  const collapseTransitionTimeout = isSinglePanelOnly
    ? { enter: 300, exit: 300 }
    : { enter: 300 };

  return (
    <PanelWrapper
      isMobileView={isMobileView}
      isDoublePanelMode={isDoublePanelMode}
      width={width}
      drawerProps={rest}
      isSinglePanelOnly={isSinglePanelOnly}
    >
      <ErrorCatcher errorID="sidepanel-base">
        {floatingContent ? floatingContent : <></>}
        {isFirstPannelHidden ? (
          <></>
        ) : (
          (isDoublePanelMode || !isSecondPanelOpen) && (
            <FirstPanelContainer
              sx={containerProps?.firstPanel}
              isMobileView={isMobileView}
              containerWidth={width}
            >
              {children}
            </FirstPanelContainer>
          )
        )}
        <GiveMotionWrapper
          type="slide"
          inView={isSecondPanelOpen}
          direction={isMobileView ? "up" : "left"}
          delay={collapseTransitionTimeout.enter}
        >
          <SecondPanelContainer
            isSinglePanelOnly={isSinglePanelOnly}
            isMobileView={isMobileView}
            containerWidth={width}
            sx={containerProps?.secondPanel}
          >
            {secondPanel}
          </SecondPanelContainer>
        </GiveMotionWrapper>
      </ErrorCatcher>
    </PanelWrapper>
  );
};

GiveSidePanel.Button = SidePanelButton;

export default GiveSidePanel;
