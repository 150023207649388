import { MemberData } from "@customTypes/team.member";
import { palette } from "@palette";
import TeamPanelBody from "features/Permissions/ModalV2/components/TeamPanelBody";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

type IProps = {
  open: boolean;
  data?: MemberData;
  setOpen: (open: boolean) => void;
};
export const TeamMemberModal = NiceModal.create(({ data }: IProps) => {
  const modal = useModal();

  const bodyProps = {
    rowData: data as MemberData,
    showPermissions: false,
    handleClose: () => modal.remove(),
  };

  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      open={modal.visible}
      onClose={() => {
        modal.remove();
      }}
      onOpen={() => undefined}
      PaperProps={{
        sx: {
          background: palette.neutral[5],
          top: "96px",
          paddingBottom: "32px",
          paddingX: "16px",
          position: "relative",
          height: "90%",
        },
      }}
    >
      {data && <TeamPanelBody {...bodyProps} />}
    </SwipeableDrawerMobile>
  );
});
