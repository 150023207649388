import { SxProps } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { Picker } from "./Picker";
import CustomToolbar from "./components/CustomToolbar";
import { useState } from "react";
import { ButtonProps } from "@shared/Button/GiveButton.types";

type TGiveDatePicker = {
  /** Label for the date picker input */
  label?: string;
  /** Callback function called when the date changes. It can be used to get the selected date */
  onSetDate: (date: any) => void;
  /** Minimum selectable date */
  minDate?: any;
  /** Maximum selectable date */
  maxDate?: any;
  /** If true, disables selection of future dates */
  disableFuture?: boolean;
  /** If true, disables selection of past dates */
  disablePast?: boolean;
  /** Custom styles for the text field */
  textFieldSx?: SxProps;
  /** Custom styles for the dialog component (used in popup variant) */
  customDialogSx?: any;
  /** If true, disables the date picker */
  disabled?: boolean;
  /** Placeholder for the date picker input */
  placeholder?: string;
  /** RHF field name */
  name?: string;
  /** Ok / Apply Button props */
  submitButtonProps?: ButtonProps;
};

const GiveDatePicker = ({
  label,
  onSetDate,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  textFieldSx,
  customDialogSx,
  disabled,
  placeholder = "mm/dd/yyyy",
  name = "date",
  submitButtonProps,
}: TGiveDatePicker) => {
  const { control } = useForm();
  const [tempValue, setTempValue] = useState<string | null>("");

  const handleClickAccept = (value: any) => {
    setTempValue(value);
    if (onSetDate) {
      onSetDate(value);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Picker
          label={label}
          onSetDate={onSetDate}
          minDate={minDate}
          maxDate={maxDate}
          disableFuture={disableFuture}
          disablePast={disablePast}
          textFieldSx={textFieldSx}
          customDialogSx={customDialogSx}
          disabled={disabled}
          placeholder={placeholder}
          error={error}
          field={field}
          handleClickAccept={handleClickAccept}
          tempValue={tempValue}
          submitButtonProps={submitButtonProps}
          ToolbarComponent={() => (
            <CustomToolbar date={field.value || new Date()} />
          )}
        />
      )}
    />
  );
};

export default GiveDatePicker;
