import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetMarkedMerchants,
  selectMarkedMerchants,
  setAllMerchantsMarked,
  setMerchantExcluded,
  setMerchantIncluded,
} from "@redux/slices/enterprise/merchants";
import { useCallback, useMemo } from "react";
import useCheckSponsor from "@hooks/common/useCheckSponsor";
import { useGetCurrentMerchantId } from "@hooks/common";
import useFindMerchantStatsById from "@hooks/acquirer-api/merchants/stats/useFindMerchantStatsById";

export const useBulkMarkMerchants = () => {
  const { all, include, exclude } = useAppSelector(selectMarkedMerchants) || [];
  const dispatch = useAppDispatch();
  const { hasSponsorAccess } = useCheckSponsor();
  const { merchantId } = useGetCurrentMerchantId();

  const { data: stats } = useFindMerchantStatsById(merchantId);

  const toggleMark = useCallback(
    ({ id, mark }: { id: number; mark: boolean }) => {
      if (all) {
        dispatch(setMerchantExcluded({ id, mark }));
      } else {
        dispatch(setMerchantIncluded({ id, mark }));
      }
    },
    [all, include, exclude],
  );

  const checkIfMerchantDisabled = (status: string) => {
    // disabled if no sponsor permisison

    if (!hasSponsorAccess) return true;
    return !["ready_for_review"].includes(status);
  };

  const toggleBulkMark = useCallback((markAll: boolean) => {
    dispatch(resetMarkedMerchants());
    dispatch(setAllMerchantsMarked(markAll));
  }, []);

  const checkIfMerchantMarked = (row: any) => {
    // always unchecked if disabled
    if (checkIfMerchantDisabled(row.sponsorStatus)) return false;

    return all ? !exclude.includes(row.accID) : include.includes(row.accID);
  };

  const isMarkAllChecked = all;

  const selectedMerchantsCount = useMemo(() => {
    let totalCount = include?.length;

    if (all) {
      totalCount =
        (stats?.merchantSponsorReadyForReviewCount || 0) - exclude?.length;
    }

    return totalCount || 0;
  }, [stats, include, exclude, all]);

  return {
    toggleMark,
    toggleBulkMark,
    checkIfMerchantMarked,
    isMarkAllChecked,
    checkIfMerchantDisabled,
    selectedMerchantsCount,
    merchantId,
    stats,
  };
};
