import * as React from "react";
import {
  Box,
  Autocomplete,
  debounce,
  Paper,
  TextFieldProps,
  SxProps,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { MagnifyingGlass } from "@phosphor-icons/react";

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

type TGiveMapsAutocomplete = {
  disabled?: boolean;
  label?: string;
  sx?: SxProps;
  initialValue?: string | null;
  reset?: boolean;
  onValueChange: (value: string) => void;
  inputProps?: TextFieldProps;
  isLoaded: boolean;
};

function GiveMapsAutocomplete({
  disabled,
  label,
  sx,
  inputProps,
  reset,
  initialValue,
  onValueChange,
  isLoaded,
}: TGiveMapsAutocomplete) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const isMounted = React.useRef<boolean>(false);
  const autocompleteService = React.useRef<
    google.maps.places.AutocompleteService | undefined
  >(undefined);

  const setInitialValue = (initialValue: string) => {
    setInputValue(initialValue);
    setValue({
      description: initialValue,
      structured_formatting: {
        main_text: initialValue,
        secondary_text: initialValue,
      },
    });
  };

  React.useEffect(() => {
    if (reset && initialValue) {
      setValue(null);
      setInputValue("");
      isMounted.current = false;
    }
  }, [reset, initialValue]);

  React.useEffect(() => {
    if (!isMounted.current && initialValue) {
      setInitialValue(initialValue);
      isMounted.current = true;
    }
  }, [initialValue]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!isLoaded) return;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <ErrorCatcher errorID="Autocomplete">
      <Autocomplete
        sx={sx}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        forcePopupIcon={false}
        autoComplete
        includeInputInList
        filterSelectedOptions
        autoHighlight
        noOptionsText="Start typing to see options"
        disabled={disabled}
        openOnFocus={false}
        PaperComponent={OptionsContainer}
        value={value}
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onValueChange(newValue?.description || "");
        }}
        onInputChange={(event: React.SyntheticEvent, newInputValue: string) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <GiveInput
            {...params}
            label={label}
            onKeyDown={(e) => e.stopPropagation()}
            inputProps={{ ...params.inputProps }}
            {...inputProps}
            rightContent={<MagnifyingGlass size={20} />}
          />
        )}
        renderOption={(props, option: any) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ]),
          );

          return (
            <li {...props}>
              <GiveText variant="bodyS" color="primary">
                {parts.map((part: any, index: number) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{
                      ...(part.highlight && {
                        fontWeight: 500,
                      }),
                    }}
                  >
                    {part.text}
                  </Box>
                ))}
                {", "}
                {option.structured_formatting.secondary_text}
              </GiveText>
            </li>
          );
        }}
      />
    </ErrorCatcher>
  );
}

const OptionsContainer = styled(Paper)(({ theme }) => ({
  margin: "8px 0",
  marginTop: "8px",
  padding: "8px",
  background: "yellow",
  border: `1px solid ${theme.palette.border?.secondary}`,
  borderRadius: "16px",
  backgroundColor: theme.palette.surface?.["tertiary-transparent"],
  backdropFilter: "blur(15px)",
  boxShadow: "none",
}));

export default GiveMapsAutocomplete;
