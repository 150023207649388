import React, { memo, useEffect } from "react";
import { Box } from "@mui/material";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { GiveInput } from "./GiveInput";

export type GiveCustomAmountProps = {
  id?: string;
  min?: number;
  max?: number;
  bounded?: boolean;
  onBlur?: () => void;
  label?: string | React.ReactNode;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string | React.ReactNode;
  currency?: "usd";
  initialValue?: string;
  onChange?: (value: string) => void;
  isDirty?: boolean;
  value?: string;
};

export const getValueFromString = (value: string) =>
  parseFloat(value.replaceAll(",", ""));

const GiveCustomAmount = ({
  min = 1,
  max = 25000,
  onBlur,
  bounded = true,
  helperText,
  initialValue,
  onChange,
  value,
  ...props
}: GiveCustomAmountProps) => {
  useEffect(() => {
    if (initialValue) {
      if (onChange) onChange(initialValue);
    }
  }, []);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trueValue = getValueFromString(e.target.value);
    if (trueValue < min) {
      if (onChange) onChange(min + ".00");
    } else if (trueValue > max && bounded) {
      if (onChange) onChange(max + ".00");
    } else {
      if (onChange) onChange(e.target.value);
    }
  };

  const formatAmount = () => {
    if (value && !value?.includes(".")) if (onChange) onChange(value + ".00");
    if (onBlur) onBlur();
  };

  const isAllowed = (values: NumberFormatValues) => {
    if (!bounded) return true;
    const { value } = values;
    return +value >= 0 && +value <= max;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <NumberFormat
        {...props}
        currency={props?.currency?.toUpperCase()}
        customInput={GiveInput}
        value={value}
        thousandSeparator
        onBlur={formatAmount}
        allowNegative={false}
        decimalScale={2}
        isAllowed={isAllowed}
        fullWidth
        {...(typeof helperText === "string" && { helperText: helperText })}
        onChange={handleAmountChange}
        inputMode="numeric"
      />
      {typeof helperText !== "string" && helperText}
    </Box>
  );
};

export default memo(GiveCustomAmount);
