import { getJsonValue, isSecondVersion } from "../utils";
import { convertHexToRGB } from "@utils/theme";
import { ThemeMode, ThemeVersions } from "@theme/v2/types";
import { IconButtonProps } from "@mui/material";

declare module "@mui/material/IconButton" {
  interface IconButtonOwnProps {
    variant?: "filled" | "ghost";
    "data-color"?: string;
    version?: ThemeVersions;
  }

  interface IconButtonPropsColorOverrides {
    ghost: true;
    darken: true;
    solidBlack: true;
    solidWhite: true;
  }

  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}

const getFilledVariantColors = (mode: ThemeMode) => [
  {
    props: (props: IconButtonProps) => props.variant === "filled",
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken-5`,
      ),
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
      },
      "&:active": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
          0.5,
        ),
      },
    },
  },
  {
    props: (props: IconButtonProps) =>
      props.variant === "filled" && props["data-color"] === "solidBlack",
    style: {
      color: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
      backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.colour.buttons.default-hover`,
        ),
      },
      "&:active": {
        backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.primitive.neutral.0`),
          0.5,
        ),
      },
    },
  },
  {
    props: (props: IconButtonProps) =>
      props.variant === "filled" && props["data-color"] === "solidWhite",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
      color: getJsonValue(`tokens.${mode}.primitive.neutral.100`),
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.colour.surface.secondary`,
        ),
      },
      "&:active": {
        backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.tertiary`),
        color: getJsonValue(`tokens.${mode}.primitive.neutral.100`),
      },
    },
  },
  {
    props: (props: IconButtonProps) =>
      props.variant === "filled" && props["data-color"] === "darken",
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken-5`,
      ),
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
      },
      "&:active": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
          0.5,
        ),
      },
    },
  },
];

export const getCustomIconButtonStyle = (mode: ThemeMode) => {
  return {
    styleOverrides: {
      root: {
        padding: "6px",
        borderRadius: "8px",
        color: getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
        width: "fit-content",
        fontFamily:"Give Whyte",
        variants: [
          {
            props: (props: IconButtonProps) =>
              isSecondVersion(props.version || "one"),
            style: {
              padding: "6px",
              borderRadius: "8px",
              color: getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
              width: "fit-content",
            },
          },
          {
            props: (props: IconButtonProps) => props.variant === "ghost",
            style: {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: getJsonValue(
                  `tokens.${mode}.primitive.transparent.darken-5`,
                ),
              },
              "&:active": {
                backgroundColor: getJsonValue(
                  `tokens.${mode}.colour.surface.tertiary`,
                ),
                color: convertHexToRGB(
                  getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
                  0.5,
                ),
              },
            },
          },
          ...getFilledVariantColors(mode),
          {
            props: (props: IconButtonProps) =>
              props.disabled && props["data-color"] === "solidBlack" && isSecondVersion(props.version || "one"),
            style: {
              backgroundColor: `${convertHexToRGB(
                getJsonValue(`tokens.${mode}.colour.buttons.default`),
                0.5,
              )} !important`,
              color: `${convertHexToRGB(
                getJsonValue(`tokens.${mode}.primitive.neutral.0`),
                0.5,
              )} !important`,
            },
          },
          {
            props: (props: IconButtonProps) =>
              isSecondVersion(props.version || "one") && props.size === "large",
            style: {
              padding: "10px",
            },
          },
          {
            props: (props: IconButtonProps) => props.size === "extraSmall",
            style: {
              padding: "4px",
              borderRadius: "38px",
            },
          },
        ],
      },
    },
  };
};