import MasqueradeSnackbar from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Grid, styled } from "@mui/material";
import { ThemeWrapper } from "@theme/v2/Provider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProductsCustomerView from "./components/ProductsCustomerView";
import { useGetPublicForm } from "./components/hooks/useGetPublicForm";
import UnpublishedPage from "./components/products/UnpublishedPage";
import { TFormStatus, TFormStatusEnum } from "./components/products/types";
import PayBuilderFormProvider, {
  usePayBuilderForm,
} from "./provider/PayBuilderFormProvider";
import { ResponsiveViewSwitcher } from "./provider/Switcher";
import { Product_payload_type } from "./provider/provider.type";
import { generateFormData } from "./utils";
import { PayBuilderContext } from "./provider/PayBuilderContext";

function WithResetFormData({ data, children }: { children: any; data: any }) {
  const { methods } = usePayBuilderForm();
  useEffect(() => {
    if (data) {
      if (methods) {
        // needed just for preview.In the customer view, what form do we clear?
        methods.reset(generateFormData(data as Partial<Product_payload_type>));
      }
    }
  }, []);

  return children;
}

function PayBuilder() {
  const {
    data,
    isLoading,
    isAuthenticated,
    notFound,
    formId,
    isADBPaymentFormLoading,
  } = useGetPublicForm();

  const navigate = useNavigate();

  if (!isAuthenticated && !formId) {
    navigate("/login", { replace: true });
  }
  if (notFound) {
    return <UnpublishedPage />;
  }

  if ((Boolean(formId) && isLoading) || isADBPaymentFormLoading) {
    return <LoadingSpinner />; // TODO: PUT SKELETONS
  }

  return (
    <>
      <MasqueradeSnackbar formType={data?.typeName} show formId={formId} />
      <ThemeWrapper isNewThemeOnly>
        <PayBuilderContext displayStatus={data?.publishedStatus as TFormStatus}>
          <PayBuilderFormProvider>
            <WithResetFormData data={data}>
              {data?.publishedStatus === TFormStatusEnum.DRAFT ||
              data?.publishedStatus === TFormStatusEnum.PUBLIC ? (
                <ProductsCustomerView />
              ) : (
                <Container container>
                  <ResponsiveViewSwitcher>
                    <ResponsiveViewSwitcher.BuilderForm />
                    <ResponsiveViewSwitcher.FormPreview />
                  </ResponsiveViewSwitcher>
                </Container>
              )}
            </WithResetFormData>
          </PayBuilderFormProvider>
        </PayBuilderContext>
      </ThemeWrapper>
    </>
  );
}

export default PayBuilder;

const Container = styled(Grid)(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  overflow: "hidden",
  "& > div": {
    flex: 1,
  },
  "& > div:nth-child(2)": {
    maxWidth: "none",
  },
}));
