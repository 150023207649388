import React from "react";
import { Breadcrumbs } from "@mui/material";
import GiveLink from "@shared/Link/GiveLink";
import GiveText from "@shared/Text/GiveText";
import { BreadcrumbProps } from "./GiveBreadcrumbs.types";

const GiveBreadcrumb = ({ items, separator = "/" }: BreadcrumbProps) => {
  return (
    <Breadcrumbs separator={separator} aria-label="breadcrumb">
      {items?.map((item, index) =>
        item.path ? (
          <GiveLink key={index} link={item.path}>
            {item.label}
          </GiveLink>
        ) : (
          <GiveText key={index} color="secondary" variant="bodyS">
            {item.label}
          </GiveText>
        ),
      )}
    </Breadcrumbs>
  );
};

export default GiveBreadcrumb;
