import { Stack, useMediaQuery } from "@mui/material";
import GiveUploadItem from "@shared/FileUpload/GiveUploadItem";
import { useAppTheme } from "@theme/v2/Provider";
import { UploadStackProps } from "./types";
import { FilePreviewType } from "@shared/FilePreview/types";
import { fileFormatter } from "@shared/FilePreview/utils";
import useDocumentPreviewV2 from "@shared/FilePreview/hooks/useDocumentPreviewV2";

const GiveUploadStack = ({
  items,
  customStyles,
  isHideDelete,
}: UploadStackProps) => {
  const { palette, breakpoints } = useAppTheme();
  const isDesktop = useMediaQuery(breakpoints.up("v2_sm"));
  const merchantId = items[0]?.merchantId;

  const fileDataList = items?.map((item) => item.fileData) as FilePreviewType[];
  const documentList = fileDataList?.map(fileFormatter);

  // TODO: as this is a reusable component, it will be used for enterprises as well.
  // So, we need to receive the entityId as a prop.
  // We also need to need to check for isEnterprise.
  const { handlePreview } = useDocumentPreviewV2({
    entityId: merchantId,
    documentList: documentList || [],
  });

  return (
    <Stack
      direction="column"
      border={`1px solid ${palette.border?.primary}`}
      overflow="hidden"
      width={isDesktop ? "600px" : "100%"}
      borderRadius="12px"
      sx={{
        maxWidth: "100%",
        backgroundColor: palette.surface?.primary,
        "& > *:last-child": {
          borderBottom: "none",
        },
        ...customStyles,
      }}
    >
      {items.map((item, index) => (
        <GiveUploadItem
          key={index}
          {...item}
          isMobile={!isDesktop}
          isHideDelete={isHideDelete}
          handlePreview={handlePreview}
        />
      ))}
    </Stack>
  );
};

export default GiveUploadStack;
