import NiceModal from "@ebay/nice-modal-react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import GiveText from "@shared/Text/GiveText";
import { Stack } from "@mui/material";

const TermsOfServiceRebrandModal = NiceModal.create(() => {
  const { open, onClose } = useNiceModal();

  return (
    <GiveBaseModal
      open={open}
      title="GivePayments Terms of Service"
      width="600px"
      onClose={onClose}
      showFooter={false}
    >
      <TermsContent />
    </GiveBaseModal>
  );
});

export default TermsOfServiceRebrandModal;

const TermsContent = () => {
  return (
    <Stack spacing={3}>
      <GiveText variant="h4" fontWeight={300}>
        Terms of Service
      </GiveText>

      <GiveText variant="bodyS" color="secondary">
        GivePayments and its associates provide their services to you subject to
        the following conditions.
      </GiveText>
      <GiveText variant="bodyS" color="secondary">
        This agreement is executed electronically. By accepting, you are
        agreeing to the terms and conditions for use of the GivePayments
        services as set forth in this agreement. You understand that you will be
        bound by these terms and conditions. You warrant that you have read,
        understand, and agree to all of the terms of this agreement, that you
        are authorized to enter into this agreement, and that this agreement is
        legally binding on you. If you do not agree to be bound by this
        agreement you shall not participate in or use the GivePayments services,
        receive any rights from GivePayments, or access or use any materials of
        GivePayments.
      </GiveText>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Privacy
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          Please review our privacy policy, which also governs your visit to our
          website, to understand our practices.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Electronic Communications
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          When you visit GivePayments or send e-mails to us, you are
          communicating with us electronically. You consent to receive
          communications from us electronically. We will communicate with you by
          e-mail or by posting notices on this site. You agree that all
          agreements, notices, disclosures, and other communications that we
          provide to you electronically satisfy any legal requirement that such
          communications be in writing.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Copyright
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          All content included on this site, such as text, graphics, logos,
          button icons, images, audio clips, digital downloads, data
          compilations, and software, is the property of GivePayments or its
          content suppliers and protected by international copyright laws. The
          compilation of all content on this site is the exclusive property of
          GivePayments, with copyright authorship for this collection by
          GivePayments, and protected by international copyright laws.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Trade Marks
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          GivePayments&apos;s trademarks and trade dress may not be used in
          connection with any product or service that is not
          GivePayments&apos;s, in any manner that is likely to cause confusion
          among customers, or in any manner that disparages or discredits
          GivePayments. All other trademarks not owned by GivePayments or its
          subsidiaries that appear on this site are the property of their
          respective owners, who may or may not be affiliated with, connected
          to, or sponsored by GivePayments or its subsidiaries.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          License and Site Access
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          GivePayments grants you a limited license to access and make personal
          use of this site and not to download (other than page caching) or
          modify it, or any portion of it, except with express written consent
          of GivePayments. This license does not include any resale or
          commercial use of this site or its contents: any collection and use of
          any product listings, descriptions, or prices: any derivative use of
          this site or its contents: any downloading or copying of account
          information for the benefit of another merchant: or any use of data
          mining, robots, or similar data gathering and extraction tools. This
          site or any portion of this site may not be reproduced, duplicated,
          copied, sold, resold, visited, or otherwise exploited for any
          commercial purpose without express written consent of GivePayments.
          You may not frame or utilize framing techniques to enclose any
          trademark, logo, or other proprietary information (including images,
          text, page layout, or form) of GivePayments and our associates without
          express written consent. You may not use any meta tags or any other
          &apos;hidden text&apos; utilizing GivePayments&apos;s name or
          trademarks without the express written consent of GivePayments. Any
          unauthorized use terminates the permission or license granted by
          GivePayments. You are granted a limited, revocable, and nonexclusive
          right to create a hyperlink to the home page of GivePayments so long
          as the link does not portray GivePayments, its associates, or their
          products or services in a false, misleading, derogatory, or otherwise
          offensive matter. You may not use any GivePayments logo or other
          proprietary graphic or trademark as part of the link without express
          written permission.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Your Membership Account
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          if you use this site, you are responsible for maintaining the
          confidentiality of your account and password and for restricting
          access to your computer, and you agree to accept responsibility for
          all activities that occur under your account or password. If you are
          under 18, you may use our website only with involvement of a parent or
          guardian. GivePayments and its associates reserve the right to refuse
          service, terminate accounts, remove or edit content, or cancel orders
          in their sole discretion. Reviews, comments, emails, and other content
          visitors may post reviews, comments, and other content: and submit
          suggestions, ideas, comments, questions, or other information, so long
          as the content is not illegal, obscene, threatening, defamatory,
          invasive of privacy, infringing of intellectual property rights, or
          otherwise injurious to third parties or objectionable and does not
          consist of or contain software viruses, political campaigning,
          commercial solicitation, chain letters, mass mailings, or any form of
          &apos;spam.&apos; you may not use a false e-mail address, impersonate
          any person or entity, or otherwise mislead as to the origin of a card
          or other content. GivePayments reserves the right (but not the
          obligation) to remove or edit such content, but does not regularly
          review posted content. If you do post content or submit material, and
          unless we indicate otherwise, you grant GivePayments and its
          associates a nonexclusive, royalty-free, perpetual, irrevocable, and
          fully sublicensable right to use, reproduce, modify, adapt, publish,
          translate, create derivative works from, distribute, and display such
          content throughout the world in any media. You grant GivePayments and
          its associates and sublicensees the right to use the name that you
          submit in connection with such content, if they choose. You represent
          and warrant that you own or otherwise control all of the rights to the
          content that you post: that the content is accurate: that use of the
          content you supply does not violate this policy and will not cause
          injury to any person or entity: and that you will indemnify
          GivePayments or its associates for all claims resulting from content
          you supply. GivePayments has the right but not the obligation to
          monitor and edit or remove any activity or content. GivePayments takes
          no responsibility and assumes no liability for any content posted by
          you or any third party.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Product Descriptions
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          GivePayments and its associates attempt to be as accurate as possible.
          However, GivePayments does not warrant that product descriptions or
          other content of this site is accurate, complete, reliable, current,
          or error-free. If a product offered by GivePayments itself is not as
          described, your sole remedy is to return it in unused condition.
        </GiveText>
      </Stack>

      <GiveText variant="bodyS" color="secondary">
        Disclaimer of warranties and limitation of liability this site is
        provided by GivePayments on an &apos;as is&apos; and &apos;as
        available&apos; basis. GivePayments makes no representations or
        warranties of any kind, express or implied, as to the operation of this
        site or the information, content, materials, or products included on
        this site. You expressly agree that your use of this site is at your
        sole risk. To the full extent permissible by applicable law,
        GivePayments disclaims all warranties, express or implied, including,
        but not limited to, implied warranties of merchantability and fitness
        for a particular purpose. GivePayments does not warrant that this site,
        its servers, or e-mail sent from GivePayments are free of viruses or
        other harmful components. GivePayments will not be liable for any
        damages of any kind arising from the use of this site, including, but
        not limited to direct, indirect, incidental, punitive, and consequential
        damages. Certain state laws do not allow limitations on implied
        warranties or the exclusion or limitation of certain damages. If these
        laws apply to you, some or all of the above disclaimers, exclusions, or
        limitations may not apply to you, and you might have additional rights.
        Applicable law by visiting GivePayments, you agree that the laws of the
        state of define_state, define_country, without regard to principles of
        conflict of laws, will govern these conditions of use and any dispute of
        any sort that might arise between you and GivePayments or its
        associates.
      </GiveText>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Disputes
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          any dispute relating in any way to your visit to GivePayments or to
          products you purchase through GivePayments shall be submitted to
          confidential arbitration in define_state, define_country, except that,
          to the extent you have in any manner violated or threatened to violate
          GivePayments&apos;s intellectual property rights, GivePayments may
          seek injunctive or other appropriate relief in any state or federal
          court in the state of define_state, define_country, and you consent to
          exclusive jurisdiction and venue in such courts. Arbitration under
          this agreement shall be conducted under the rules then prevailing of
          the american arbitration association. The arbitrators award shall be
          binding and may be entered as a judgment in any court of competent
          jurisdiction. To the fullest extent permitted by applicable law, no
          arbitration under this agreement shall be joined to an arbitration
          involving any other party subject to this agreement, whether through
          class arbitration proceedings or otherwise.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Site Policies, Modification, and Severability
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          please review our other policies, such as our shipping and returns
          policy, posted on this site. These policies also govern your visit to
          GivePayments. We reserve the right to make changes to our site,
          policies, and these conditions of use at any time. If any of these
          conditions shall be deemed invalid, void, or for any reason
          unenforceable, that condition shall be deemed severable and shall not
          affect the validity and enforceability of any remaining condition.
        </GiveText>
      </Stack>

      <Stack>
        <GiveText variant="bodyS" fontWeight={500}>
          Questions
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          questions regarding our conditions of usage, privacy policy, or other
          policy related material can be directed to our support staff by
          visiting GivePayments customer support. Or you can email us at:
          support@GivePayments.com
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          payment will be processed by:
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          GivePayments
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          1730 E. Holly Ave
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          El Segundo, CA 90245
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          United States
        </GiveText>
      </Stack>
    </Stack>
  );
};
