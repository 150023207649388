import { Box, Stack } from "@mui/material";
import { HandCoins, Heart } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { addSizeToImage } from "@utils/image.helpers";
import ImagePlaceholder from "@assets/images/thumbnail-placeholder.png";
import pl from "@assets/images/widgetImagePlaceholder.png";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { QKEY_LIST_CART } from "@constants/queryKeys";
import Skeleton from "@components/animation/Skeleton";

interface DonateNowButtonProps {
  accentColor: string;
}

export const ColouredButton = styled(GiveButton)<DonateNowButtonProps>(
  ({ theme, accentColor }) => {
    return {
      flex: 1,
      borderRadius: "40px",
      padding: "16px",
      border: 0,
      backgroundColor: accentColor,
      "&.MuiButtonBase-root:hover": {
        bgcolor: `${accentColor}1A`,
      },
      color: theme?.palette.text?.invert,
      "&.Mui-disabled": {
        color: `${theme?.palette.text?.invert} !important`,
      },
    };
  },
);

export const TranslucentButton = styled(GiveButton)(({ theme }) => {
  return {
    flex: 1,
    borderRadius: "40px",
    padding: "16px",
    border: 0,
    backgroundColor: theme.palette.primitive?.transparent["darken-5"],
    "&.MuiButtonBase-root:hover": {
      bgcolor: theme.palette.primitive?.transparent["darken-5"],
    },
    color: theme?.palette.text?.primary,
    "&.Mui-disabled": {
      color: `${theme?.palette.text?.primary} !important`,
    },
    "&:hover": {
      backgroundColor: theme.palette.primitive?.transparent["darken-5"],
    },
  };
});

export const BlueBadge = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    width: "100%",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.primitive?.blue[50]}`,
    backgroundColor: theme.palette.primitive?.blue[10],
    color: theme.palette.primitive?.blue[100],
    padding: "5px 16px",
  };
});

interface DonorComponentProps {
  amount?: string;
  name?: string;
  isTopDonor?: boolean;
  when?: string;
  accentColor: string;
}

export const DonorComponent: React.FC<DonorComponentProps> = ({
  amount = "",
  name = "",
  isTopDonor = false,
  when = "",
  accentColor,
}) => {
  const theme = useAppTheme();
  return (
    <Stack gap="16px" alignItems="flex-end" flexDirection="row" my="8px">
      <IconContainer accentColor={accentColor} isTopDonor={isTopDonor}>
        {isTopDonor ? (
          <Heart fill={accentColor} size="20px" weight="fill" />
        ) : (
          <HandCoins fill={theme.palette.icon?.["icon-primary"]} size="20px" />
        )}
      </IconContainer>
      <Box>
        <GiveText color="primary" fontSize="16px">
          $ {amount}
        </GiveText>
        <GiveText fontSize="14px">
          {name} &middot; {when}{" "}
          {isTopDonor && (
            <>
              &middot;{" "}
              <span
                style={{
                  color: accentColor,
                }}
              >
                Top Donor
              </span>
            </>
          )}
        </GiveText>
      </Box>
    </Stack>
  );
};
interface IconContainerProp {
  isTopDonor: boolean;
  accentColor: string;
}

const IconContainer = styled(Stack)<IconContainerProp>(
  ({ theme, isTopDonor, accentColor }) => {
    return {
      width: "36px",
      alignItems: "center",
      justifyContent: "center",
      height: "36px",
      borderRadius: "50px",
      backgroundColor: isTopDonor
        ? `${accentColor}1A`
        : theme.palette.primitive?.transparent["darken-5"],
    };
  },
);

export const ImageComponents = ({
  url,
  isMobileView,
  fullWidth,
}: {
  url?: string | null;
  isMobileView?: boolean;
  fullWidth?: boolean;
}) => {
  const original = addSizeToImage(url || "", "original", ImagePlaceholder);

  return (
    <Stack
      sx={{
        width: isMobileView || fullWidth ? "100%" : "50%",
        height: "auto",
        minHeight: "302px",
      }}
    >
      {url ? (
        <StyledImage src={original} alt="Hero" />
      ) : (
        <StyledImage src={pl} alt="Hero" />
      )}
    </Stack>
  );
};
const StyledImage = styled("img")({
  maxWidth: "100%",
  borderRadius: "8px",
  objectFit: "cover",
  width: "100%",
  maxHeight: "302px",
  flex: 1,
  height: "100%",
});

export const ContentComponent = ({
  heading = "",
  description = "",
  isMobileView = false,
  fullWidth = false,
}) => {
  return (
    <TextContainer sx={{ width: isMobileView || fullWidth ? "100%" : "50%" }}>
      <GiveText variant="h2" color="primary">
        {heading || "Heading"}
      </GiveText>
      <GiveText variant="bodyS" color="secondary">
        {description}
      </GiveText>
    </TextContainer>
  );
};

const TextContainer = styled(Stack)({
  flex: 1,
  gap: "16px",
  width: "100%",
  overflow: "hidden",
  wordWrap: "break-word",
  textOverflow: "ellipsis",
});

export const LogoComponent = ({
  logo = "",
  isPreviewMode = false,
  handleClick,
}: {
  logo?: string;
  isPreviewMode?: boolean;
  handleClick?: () => void;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const queryClient = useQueryClient();

  const defaultHandleClick = async () => {
    await queryClient.invalidateQueries([QKEY_LIST_CART]);
    navigate(`/${id}`);
  };

  return (
    <Box
      sx={{
        background: `url(${logo + "/original"})`,
        backgroundSize: "contain",
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        height: "52px",
        width: "212px",
        ...(!isPreviewMode && {
          cursor: "pointer",
        }),
      }}
      onClick={handleClick || defaultHandleClick}
    />
  );
};

export const DonorsSkeleton = ({ count = 5 }: { count?: number }) => {
  return (
    <div>
      {" "}
      {Array(count)
        .fill(null)
        ?.map((i, idx) => (
          <Stack
            my="8px"
            alignItems="center"
            gap="16px"
            flexDirection="row"
            key={idx}
          >
            <Skeleton
              variant="circular"
              sx={{
                borderRadius: "50%",
              }}
              width="36px"
              height="36px"
            />
            <Stack gap="4px">
              <Skeleton
                width="100px"
                sx={{
                  borderRadius: "3px",
                }}
                height="22px"
                variant="rectangular"
              />
              <Skeleton
                sx={{
                  borderRadius: "3px",
                }}
                width="200px"
                height="22px"
                variant="rectangular"
              />
            </Stack>
          </Stack>
        ))}
    </div>
  );
};
