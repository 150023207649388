import { Box, FormControlLabel, Grid, Stack } from "@mui/material";
import PayFormSectionTitle from "features/Minibuilders/PaymentFormMinibuilder/PayFormSectionTitle";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import TabSection from "./TabSection";
import LocationSection from "./LocationSection";
import { ControlledDatePicker } from "./components/ControlledDatePicker";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { ControlledAutoCompleteTime } from "./components/Timers/ControlledAutoCompleteTime";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

function TimeInputs({ timeActiveTab, includeTime }: any) {
  if (includeTime) {
    if (timeActiveTab === "single") {
      return <ControlledAutoCompleteTime name="DateLocation.startsAtTime" />;
    } else {
      return (
        <Grid container gap={2} justifyContent="space-between">
          <Grid item sx={{ width: "48%" }}>
            <ControlledAutoCompleteTime name="DateLocation.startsAtTime" />
          </Grid>
          <Grid item sx={{ width: "48%" }}>
            <ControlledAutoCompleteTime name="DateLocation.endsAtTime" />
          </Grid>
        </Grid>
      );
    }
  }

  return null;
}

export const DateAndLocation = ({ isEdit }: { isEdit?: boolean }) => {
  const { methods } = usePayBuilderForm();
  const { setValue, watch, trigger } = methods;
  const { isMobileView } = useCustomThemeV2();

  const values = watch();

  return (
    <Box pb="20px">
      <PayFormSectionTitle isEdit={isEdit}>Date & Location</PayFormSectionTitle>
      <Stack gap="40px" pt="40px">
        <Stack gap="16px">
          <TabSection
            title="When is the event?"
            onClick={(e: any) => {
              setValue("DateLocation.timeActiveTab", e);
              setValue("DateLocation.startsAt", null);
              setValue("DateLocation.endsAt", null);
              setValue("DateLocation.includeTime", false);
              methods.clearErrors("DateLocation.startsAt");
              methods.clearErrors("DateLocation.endsAt");
            }}
            tabItems={timeTabs}
            selectedTab={values.DateLocation?.timeActiveTab}
          />
          <Grid
            id="time_section"
            container
            gap={2}
            justifyContent="space-between"
          >
            {values.DateLocation.timeActiveTab === "single" ? (
              <ControlledDatePicker name="DateLocation.startsAt" disablePast />
            ) : (
              <Grid container gap={2} justifyContent="space-between">
                <Grid item sx={{ width: isMobileView ? "100%" : "48%" }}>
                  <ControlledDatePicker
                    label="Start"
                    name="DateLocation.startsAt"
                    onChangeCustom={() => {
                      trigger("DateLocation.endsAt");
                    }}
                    disablePast
                  />
                </Grid>
                <Grid item sx={{ width: isMobileView ? "100%" : "48%" }}>
                  <ControlledDatePicker
                    label="End"
                    name="DateLocation.endsAt"
                    disablePast
                  />
                </Grid>
              </Grid>
            )}
            <FormControlLabel
              sx={{
                margin: 0,
                gap: "12px",
              }}
              label="Include Time"
              control={
                <GiveCheckbox
                  checked={watch("DateLocation.includeTime")}
                  onChange={(e) => {
                    setValue("DateLocation.includeTime", e.target.checked);
                  }}
                />
              }
            />
            <TimeInputs
              timeActiveTab={values.DateLocation.timeActiveTab}
              includeTime={values.DateLocation.includeTime}
            />
          </Grid>
        </Stack>
        <Stack gap="16px">
          <TabSection
            title="Where is the event?"
            tabItems={locationTabs}
            selectedTab={values.DateLocation.locationPosition}
            onClick={(e) => {
              setValue("DateLocation.locationPosition", e, {
                shouldValidate: true,
              });
            }}
          />
          <LocationSection
            locationPosition={values.DateLocation?.locationPosition}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const locationTabs = [
  {
    label: "On-site",
    value: "onsite",
  },
  {
    label: "Online",
    value: "online",
  },
];

const timeTabs = [
  {
    label: "On a day",
    value: "single",
  },
  {
    label: "Period",
    value: "range",
  },
];
