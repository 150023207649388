import CampaignCard from "@common/CampaignCard/CampaignCard";
import { CommonProductProps } from "@common/Campaigns/campaigns.types";
import NiceModal from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { useDeleteSingleProduct } from "@sections/PayBuilder/provider/useManageApi";
import { formatDate, parseAmount } from "@utils/index";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { campaignMapper } from "features/Minibuilders/PaymentFormMinibuilder/useCreateCampaignFn";
import { SAVE_MINI_BUILDER_DRAFT } from "modals/modal_names";

const PaymentFormListItem = ({
  data,
  hiddenCols,
  columns,
  openSidePanel,
  isSelected,
}: {
  hiddenCols?: number[];
  data: any;
  isSelected: boolean;
} & CommonProductProps) => {
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();
  const isDraft =
    data?.publishedStatus === "draft" &&
    isPayBuilderEnabled &&
    ["fundraiser", "standard", "event"]?.includes(data?.typeName);

  const others = [
    formatDate(data.createdAt),
    isDraft ? "" : parseAmount(data.sumTransactions / 100),
    isDraft ? "" : `${data.conversionRate}%`,
    isDraft ? "" : parseAmount(data.averageTransactions / 100),
    isDraft ? "" : `${data.totalValidPurchases}`,
    isDraft ? "" : `${data.visitors}`,
  ];

  const { mutate } = useDeleteSingleProduct();

  const handledOnClick = (e?: any) => {
    if (isDraft)
      return NiceModal.show(SAVE_MINI_BUILDER_DRAFT, {
        modalType: "editDraft",
        formType: campaignMapper[data?.typeName as keyof typeof campaignMapper] || "",
        onCancel: (cb?: () => void) => {
          mutate({
            onSuccessAction: () => cb && cb(),
            productId: data.id,
          });
        },
        openSidePanel,
        id: data?.id + "",
      });

    openSidePanel && openSidePanel();
  };
  return (
    <CampaignCard
      imageURL={data.imageURL}
      name={data.name}
      isDraft={isDraft}
      id={data.id}
      columns={columns}
      sx={{
        borderTop: `1px solid ${palette.neutral[10]}`,
      }}
      others={
        hiddenCols ? others.filter((_, i) => !hiddenCols.includes(i)) : others
      }
      openSidePanel={handledOnClick}
      isSelected={isSelected}
    />
  );
};

export default PaymentFormListItem;
