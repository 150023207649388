import * as React from "react";
import {
  TextFieldProps,
  TextField,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import { InputAdornment } from "@mui/material";
import GiveText from "@shared/Text/GiveText";

export type InputProps = TextFieldProps & {
  label?: string | React.ReactNode;
  error?: boolean;
  leftContent?: string | JSX.Element;
  rightContent?: string | JSX.Element;
  currency?: string;
  inputRef?: any;
  value?: any;
  displayCounter?: boolean;
  maxLength?: number;
  height?: string | number;
};

export const GiveInput: React.FC<InputProps> = ({
  label,
  error,
  leftContent,
  rightContent,
  currency,
  inputRef,
  displayCounter,
  maxLength,
  height,
  ...rest
}) => {
  const { palette } = useAppTheme();
  const showCounter =
    typeof rest?.value === "string" && displayCounter && maxLength;
  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiInputBase-root": {
          border: `1px solid ${palette.border?.primary}`,
          ...(!rest?.multiline && {
            maxHeight: height || "48px",
            height: height,
          }),
        },
      }}
    >
      <GiveTextField
        error={error}
        inputRef={inputRef}
        leftContent={leftContent}
        rightContent={rightContent}
        currency={currency}
        maxLength={maxLength}
        label={label}
        {...rest}
      />
      {showCounter && (
        <GiveText fontWeight={400} color="secondary" fontSize="12px">
          {typeof rest.value === "string" ? rest.value.length : 0}/{maxLength}
        </GiveText>
      )}
    </FormControl>
  );
};

export const GiveTextField: React.FC<InputProps> = ({
  error,
  inputRef,
  leftContent,
  rightContent,
  currency,
  maxLength,
  inputProps = {},
  label,
  helperText,
  ...rest
}) => {
  const { palette } = useAppTheme();

  return (
    <>
      {label && (
        <FormLabel
          error={error}
          disabled={rest.disabled}
          sx={{
            ...(error && { color: palette.primitive?.error }),
            color: palette.text.primary,
          }}
        >
          {label}
        </FormLabel>
      )}
      <TextField
        error={error}
        ref={inputRef}
        {...rest}
        helperText={helperText == null ? null : helperText?.toString()}
        InputProps={{
          ...(leftContent && {
            startAdornment: (
              <InputAdornment position="start">{leftContent}</InputAdornment>
            ),
          }),
          ...((rightContent || currency) && {
            endAdornment: (
              <InputAdornment position="end">
                {rightContent || currency}
              </InputAdornment>
            ),
          }),
          ...rest.InputProps,
        }}
        version="two"
        inputProps={{ maxLength, ...inputProps }}
      />
    </>
  );
};
