import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";
import { CREATE_PRE_BUILD_FORM_MODAL } from "modals/modal_names";

function ProductBanner({ backgroundUrl }: { backgroundUrl: string }) {
  return (
    <NewProductBanner
      title="Products"
      campaignType="standard"
      createModal={CREATE_PRE_BUILD_FORM_MODAL}
      analyticsType="standard"
      background={backgroundUrl}
      createCampaignTitle={"Create Payment Form"}
    />
  );
}

export default ProductBanner;
