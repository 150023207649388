import { TabNames } from "./hooks/useTabs";

export const getSelectedTab = (tab: string) => {
  switch (tab) {
    case TabNames.invited:
      return "invited";
    case TabNames.underwriting:
      return "underwriting";
    case TabNames.sponsor:
      return "sponsor";
    case TabNames.risk:
      return "risk";
    case TabNames.onboarding:
      return "onboarding";
    default:
      return "all";
  }
};
