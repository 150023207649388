import { Box, SxProps } from "@mui/material";
import { Minus, Plus } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";

interface Props {
  handleDecrement: () => void;
  handleIncrement: () => void;
  quantity: number;
  sx?: SxProps;
  disableAddition?: boolean;
}
const QuantityInput = ({
  handleDecrement,
  handleIncrement,
  quantity,
  sx,
  disableAddition,
}: Props) => {
  return (
    <StyledBox sx={{ ...sx }}>
      <StyledIcon
        variant="ghost"
        size="extraSmall"
        onClick={handleDecrement}
        Icon={Minus}
        data-testid="minus-button"
      />
      <GiveText data-testid="quantity-display-text" variant="bodyS">
        {quantity}
      </GiveText>
      <StyledIcon
        variant="ghost"
        size="extraSmall"
        onClick={handleIncrement}
        Icon={Plus}
        disabled={disableAddition}
        data-testid="plus-button"
      />
    </StyledBox>
  );
};

export default QuantityInput;

const StyledIcon = styled(GiveIconButton)({
  "&:hover": {
    background: "transparent !important",
  },
});

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primitive?.transparent["darken-5"],
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  bgcolor: "#f4f4f4",
  borderRadius: "24px",
  padding: "4px 16px",
  minWidth: "88px",
}));
