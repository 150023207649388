export interface AnimationAssets {
  desktop: string;
  mobile: string;
  fullscreen?: string;
}

interface AnimationAssetsObject {
  [key: string]: AnimationAssets;
}

export const animationAssets: AnimationAssetsObject = {
  animation1: {
    desktop: "animation1_video_desktop.mp4",
    mobile: "animation1_video_mobile.mp4",
    fullscreen: "Full-Background-Animation-01.mp4",
  },
  animation2: {
    desktop: "animation2_video_desktop.mp4",
    mobile: "animation2_video_mobile.mp4",
    fullscreen: "Full-Background-Animation-02.mp4",
  },
  animation3: {
    desktop: "animation3_video_desktop.mp4",
    mobile: "animation3_video_mobile.mp4",
    fullscreen: "Full-Background-Animation-03.mp4",
  },
  animation4: {
    desktop: "animation4_video_desktop.mp4",
    mobile: "animation4_video_mobile.mp4",
    fullscreen: "Full-Background-Animation-04.mp4",
  },
  animation5: {
    desktop: "animation5_video_desktop.mp4",
    mobile: "animation5_video_mobile.mp4",
    fullscreen: "animation5_video_desktop.mp4", // fallback animation
  },
};

export const DEFAULT_ANIMATION = animationAssets.animation1;

export const pageAnimations = {
  acquirerProcessing: "animation1",
  merchantManageMoney: "animation1",
  merchantRegistration: "animation1",
  emptyPaymentForm: "animation1",
  acquirerSettlement: "animation2",
  acquirerDisputes: "animation3",
  merchantPaymentForms: "animation3",
  transfers: "animation4",
  merchantCustomers: "animation4",
  acquirerProviders: "animation5",
  merchants: "animation3",
  welcome: "animation1",
  manageMoney: "animation1",
};

export type PageAnimationsType = keyof typeof pageAnimations;
