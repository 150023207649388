import { useCallback, useMemo } from "react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import { capitalizeEachWord, parseAmount } from "@utils/index";
import { TableColumnType } from "componentsV2/Table/Table.types";
import { format, formatDistanceToNow, fromUnixTime } from "date-fns";
import { QKEY_LIST_ACQUIRER_MERCHANTS } from "@constants/queryKeys";
import { selectMerchantTab } from "@redux/slices/enterprise/merchants";
import { useAppSelector } from "@redux/hooks";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { TAG_STATUSES_ENUM } from "@common/TextTag/types";
import GiveText from "@shared/Text/GiveText";
import { Box, Stack } from "@mui/material";
import MainInfo from "componentsV2/Table/MainInfo";
import StatusTag from "componentsV2/Table/StatusTag";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import RiskStatusChip, { TRiskLevel } from "componentsV2/Table/RiskStatusChip";
import GiveAssignButton from "@shared/AssignButton/GiveAssignButton";
import GiveUnderwritingScore from "@shared/UnderwritingScore/GiveUnderwritingScore";
import { checkPortals } from "@utils/routing";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { setOpeningOptions } from "@redux/slices/merchantPreview";
import { useDispatch } from "react-redux";
import { AssigneeInfos } from "@shared/AssignButton/GiveAssignButton.types";
import useWatchlist from "./useWatchList";
import BadgeTooltipContent from "../components/BadgeTooltipContent";
import OnboardingScore from "../OnboardingScore";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import { getSelectedTab } from "../utils";
import RiskLevelTag from "../components/RiskLevelTag";

const isMerchantInvited = (status?: string): boolean => {
  return (
    status === "sent" ||
    status === "read" ||
    status === "delivered" ||
    status === "invited"
  );
};

const getMerchantStatus = (
  rowData: MerchantData,
  showInviteStatus?: boolean,
) => {
  const sponsorStatus = rowData?.sponsorStatusDisplayName || "";
  const ownerMembershipStatus = rowData?.ownerMembershipStatus;
  const statusDisplayName = rowData?.statusDisplayName;

  const ownerInviteStatusDisplayName = rowData?.ownerInviteStatusDisplayName;
  // for the "Invited" tab show the specific invitation status
  if (
    showInviteStatus &&
    rowData?.ownerMembershipStatus === "invited" &&
    ownerInviteStatusDisplayName
  ) {
    return ownerInviteStatusDisplayName;
  }

  if (isMerchantInvited(ownerMembershipStatus)) {
    return "Invite sent";
  }

  if (statusDisplayName === "Pre Approved") {
    return "Underwriting";
  }

  if (
    statusDisplayName === "MSP Issue" &&
    sponsorStatus === "Ready for Review"
  ) {
    return "Ready for Review";
  }

  if (["Pre-approval", "Auto-approval"].includes(statusDisplayName)) {
    return sponsorStatus;
  }

  return statusDisplayName;
};

export const useGetMerchantsTableColumns = () => {
  const dispatch = useDispatch();
  const { isAcquirerPortal, isEnterprisePortal } = checkPortals();
  const { isWideView, isTabletView, isMobileView } = useCustomThemeV2();
  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();
  const { risk_monitoring, merchant_underwriting } = useEnterprisePermissions();
  const { addMerchantToWatchlist, removeMerchantFromWatchlist } = useWatchlist(
    false,
    QKEY_LIST_ACQUIRER_MERCHANTS,
  );

  const tab = useAppSelector(selectMerchantTab);
  const selectedTab = getSelectedTab(tab);
  const isInvitedTabSelected = selectedTab === "invited";
  const isRiskTabSelected = selectedTab === "risk";
  const isOnboardingSelected = selectedTab === "onboarding";
  const isSponsorTabSelected = selectedTab === "sponsor";
  const merchantColWidth = isInvitedTabSelected ? 1.8 : isTabletView ? 2.5 : 4;
  const getQuery = (status: TAG_STATUSES_ENUM) => {
    if (status === TAG_STATUSES_ENUM.INVITE) {
      return ``;
    }
    if (isEnterprisePortal) {
      return `members?filter=(roleName:"owner",roleName:"admin")`;
    }
    if (!isAcquirerPortal) {
      return ``;
    }
    if (
      status === TAG_STATUSES_ENUM.UNDERWRITING ||
      status === TAG_STATUSES_ENUM.READY_FOR_REVIEW ||
      status === TAG_STATUSES_ENUM.DECLINED
    ) {
      return `members?filter=(roleName:"uw_associate",roleName:"uw_executive")`;
    }

    if (
      status === TAG_STATUSES_ENUM.APPROVED ||
      status === TAG_STATUSES_ENUM.SUSPENDED
    ) {
      return `members?filter=(roleName:"risk_manager")`;
    }
  };
  const addToWatchlistHandler = (row?: MerchantData) => {
    if (!row) return;
    addMerchantToWatchlist(row?.accID, row?.name);
  };

  const isWarnedMerchant = (row: MerchantData) => {
    if (isInvitedTabSelected) return false;
    if (!row?.isProfileCompleted) return true;
    const isOfacMatch = ["possible_match", "confirmed_match"].includes(
      row?.entityOfac?.lastCheckStatusName,
    );

    const isRiskLevel =
      row?.riskLevelLabel && row?.riskLevelLabel !== "low" && risk_monitoring;

    return isRiskLevel || isOfacMatch;
  };

  const handleClickScore = useCallback(() => {
    dispatch(setOpeningOptions({ underwriter: true }));
  }, []);

  const signupDateCol = useMemo(() => {
    return {
      key: "createdAt,-accID",
      sortable: true,
      title: "Signup Date",
      columnWidth: isOnboardingSelected ? 1 : 1.3,
      renderColumn: (row: MerchantData) => {
        return (
          <GiveText variant="bodyS">
            {row?.createdAt
              ? format(row?.createdAt * 1000, "MMM dd, yyyy")
              : ""}
          </GiveText>
        );
      },
    };
  }, [tab]);

  const categoryRiskCol = useMemo(() => {
    return {
      key: "merchantRiskStatusSortOrder,-accID",
      sortable: true,
      title: "Risk Category",
      columnWidth: isSponsorTabSelected ? 1 : 1.3,
      renderColumn: (row: MerchantData) => {
        const isRiskLevel = (value: any): value is TRiskLevel => {
          return ["normal", "high", "restricted"].includes(value);
        };
        return (
          <GiveTooltip
            color="default"
            disableHoverListener={
              !row?.categoryCode ||
              !row?.categoryCodeTitle ||
              !row?.merchantRiskStatus
            }
            title={
              <GiveText color="default" variant="bodyS">
                {row?.categoryCode}- {row?.categoryCodeTitle}
              </GiveText>
            }
            alignment="start"
          >
            <RiskStatusChip
              riskLevelName={
                isRiskLevel(row?.merchantRiskStatus)
                  ? row?.merchantRiskStatus
                  : null
              }
            />
          </GiveTooltip>
        );
      },
    };
  }, []);

  const processedCol = useMemo(() => {
    return {
      key: "totalProcessed,-accID",
      sortable: true,
      title: "Processed (USD)",
      headerPosition: "right",
      columnWidth: "140px",
      renderColumn: (row: MerchantData) => {
        const revenue = parseAmount(row?.totalProcessed / 100);
        return (
          <Box display="flex" width="100%" justifyContent="flex-end">
            <GiveText variant="bodyS">{revenue}</GiveText>
          </Box>
        );
      },
    } as TableColumnType;
  }, []);

  const statusCol = useMemo(() => {
    return {
      key: `${
        isInvitedTabSelected
          ? "ownerInviteStatusDisplayName"
          : "statusSortOrder"
      },-accID`,
      sortable: true,
      title: "Status",
      columnWidth: isMobileView ? "114px" : "140px",
      columnType: "status-round" as const,
      renderColumn: (row: MerchantData) => {
        const status = getMerchantStatus(
          row,
          isInvitedTabSelected,
        ) as TAG_STATUSES_ENUM;
        const statusData = {
          [TAG_STATUSES_ENUM.APPROVED]: {
            name: `${row?.approvedByFirstName} ${row?.approvedByLastName}`,
            updatedAt: Number(row?.approvedAt || 0),
          },
          [TAG_STATUSES_ENUM.SUSPENDED]: {
            name: `${row?.suspendedByFirstName} ${row?.suspendedByLastName}`,
            updatedAt: Number(row?.suspendedAt || 0),
          },
          [TAG_STATUSES_ENUM.DECLINED]: {
            name: `${row?.declinedByFirstName} ${row?.declinedByLastName}`,
            updatedAt: Number(row?.declinedAt || 0),
          },
          [TAG_STATUSES_ENUM.INVITE]: {
            updatedAt: Number(row?.OwnerInviteLastSentAt || 0),
          },
        } as Record<TAG_STATUSES_ENUM, { name?: string; updatedAt: number }>;

        return (
          <StatusTag
            statusDisplayName={status}
            updatedBy={statusData[status]?.name}
            updatedAt={statusData[status]?.updatedAt}
          />
        );
      },
    };
  }, [tab, isMobileView]);

  const assignmentCol = useMemo(() => {
    const getAssigneeType = () => {
      if (isRiskTabSelected) return "risk";
      if (isOnboardingSelected && isAcquirerPortal) return "sales";
      else return undefined;
    };
    return {
      key: "assignment",
      sortable: false,
      title: "Assignee",
      columnWidth: isRiskTabSelected ? 1.2 : 1,
      columnType: "assignment" as const,
      renderColumn: (row: MerchantData) => {
        const status = getMerchantStatus(
          row,
          isInvitedTabSelected,
        ) as TAG_STATUSES_ENUM;
        return (
          <GiveTooltip
            color="default"
            title={row?.underwriterName || row?.underwriterEmail}
            disableHoverListener={!row?.underwriterID}
            alignment="start"
          >
            <GiveAssignButton
              data={row as AssigneeInfos}
              disabled={false}
              showCaretIcon={false}
              handleClick={() => {
                // this is useless we are already calling inside the GiveAssignButton
                // => queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_STATS);
              }}
              type={getAssigneeType()}
              query={getQuery(status)}
            />
          </GiveTooltip>
        );
      },
    };
  }, [tab]);

  const underwritingScoreCol = useMemo(() => {
    return {
      key: "underwritingChallengeScorePercentage,-accID",
      sortable: true,
      title: "Underwriting Score",
      columnWidth: !isWideView ? 1 : 1.6,
      columnType: isWideView ? "text" : "empty",
      renderColumn: (row: MerchantData) => {
        return (
          <Box width={isWideView ? "140px" : "auto"}>
            <GiveUnderwritingScore
              statusDisplayName={row?.statusDisplayName}
              score={row?.underwritingChallengeScorePercentage}
              canProcessMoney={row?.canProcessMoney}
              canTransferMoney={row?.canTransferMoney}
              pendingScore={row?.underwritingPendingChallengesPercentage}
              pendingChallenges={row?.underwritingPendingChallengesCount}
              merchant_underwriting={merchant_underwriting}
              isViewUnderwritingAllowed={isViewUnderwritingAllowed}
              handleClick={handleClickScore}
              hideIcons
              alwaysDisplayProgressBars
            />
          </Box>
        );
      },
    };
  }, [isWideView]);

  const onboardingScoreCol = useMemo(() => {
    return {
      key: "completedChallengesCount,-accID",
      sortable: false,
      title: "Onboarding Progress",
      columnWidth: 1,
      columnType: isWideView ? "text" : "empty",
      renderColumn: (row: MerchantData) => {
        const { onboardingChallengesCount, completedChallengesCount } =
          row || {};
        const percentage =
          ((completedChallengesCount || 0) * 100) /
          (onboardingChallengesCount || 0);
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="12px"
          >
            <GiveText
              variant={isWideView ? "bodyS" : "bodyXS"}
              color={!completedChallengesCount ? "tertiary" : "primary"}
            >
              {completedChallengesCount}/{onboardingChallengesCount}
            </GiveText>
            <Box width={isWideView ? "115px" : "auto"}>
              <OnboardingScore score={percentage} />
            </Box>
          </Stack>
        );
      },
    };
  }, [isWideView]);

  const emailCol = useMemo(() => {
    return {
      key: "email",
      sortable: false,
      title: "Email",
      columnWidth: 2.1,
      renderColumn: (row: MerchantData) => {
        return (
          <GiveTruncateText lineClamp={1} variant="bodyS">
            {row?.ownerEmail}
          </GiveTruncateText>
        );
      },
    };
  }, []);

  const inviteCol = useMemo(() => {
    return {
      key: "ownerInviteLastSentAt,-accID",
      sortable: true,
      title: "Invited",
      columnWidth: 0.7,
      renderColumn: (row: MerchantData) => {
        return (
          <GiveText variant="bodyS">
            {row?.ownerInviteLastSentAt
              ? format(row?.ownerInviteLastSentAt * 1000, "MMM dd, yyyy")
              : ""}
          </GiveText>
        );
      },
    };
  }, []);

  const mccCol = useMemo(() => {
    return {
      key: "categoryCode,-accID",
      sortable: false,
      title: "MCC",
      columnWidth: 1.3,
      renderColumn: (row: MerchantData) => {
        return (
          <Stack gap="4px">
            <GiveTruncateText lineClamp={1} variant="bodyS">
              {row?.categoryCodeTitle}
            </GiveTruncateText>
            <GiveText variant="bodyXS" color="secondary">
              {row?.categoryCode}
            </GiveText>
          </Stack>
        );
      },
    };
  }, []);

  const recommendationCol = useMemo(() => {
    return {
      key: "lastInternalApprovalAt,-accID",
      sortable: true,
      title: "Recommendation",
      columnWidth: 1.2,
      renderColumn: (row: MerchantData) => {
        const statusName = row?.lastInternalApprovalTypeDisplayName ?? "";
        const date = row?.lastInternalApprovalAt ?? 0;
        return (
          <Stack gap="4px">
            <GiveText variant="bodyS">
              {capitalizeEachWord(statusName, "-")}
            </GiveText>
            <GiveText variant="bodyXS" color="secondary">
              {format(date * 1000, "MMM dd, yyyy")}
            </GiveText>
          </Stack>
        );
      },
    };
  }, []);

  const sponsorStatusCol = useMemo(() => {
    return {
      key: "sponsorStatusSortOrder,-accID",
      sortable: isWideView,
      title: "Sponsor Status",
      columnType: "status-round" as const,
      columnWidth: "170px",
      renderColumn: (row: MerchantData) => {
        const sponsorStatusDisplayName = row?.sponsorStatusDisplayName || "";
        const status =
          !!sponsorStatusDisplayName &&
          !["Approved", "Declined", "Pending"].includes(
            sponsorStatusDisplayName,
          )
            ? "Ready for Review"
            : sponsorStatusDisplayName;
        return <StatusTag statusDisplayName={status} disableTooltip />;
      },
    };
  }, [isWideView]);

  const ageEscalationCol = useMemo(() => {
    return {
      key: "lastEscalationAt,-accID",
      sortable: true,
      title: "Age of Escalation",
      columnWidth: 1.1,
      renderColumn: (row: MerchantData) => {
        const risk = row?.riskLevelLabel || "low";

        const parsedDate =
          risk !== "low" && row?.lastEscalationAt
            ? formatDistanceToNow(fromUnixTime(row?.lastEscalationAt), {
                addSuffix: true,
              })
            : "-";

        return <GiveText variant="bodyS">{parsedDate}</GiveText>;
      },
    };
  }, []);

  const riskLevelCol = useMemo(() => {
    return {
      key: "riskSortOrder,-accID",
      sortable: true,
      title: "Risk Level",
      columnWidth: "144px",
      columnType: "risk-level" as const,
      renderColumn: (row: MerchantData) => {
        if (row?.statusName === "suspended")
          return (
            <StatusTag
              statusDisplayName={TAG_STATUSES_ENUM.SUSPENDED}
              updatedBy={`${row?.suspendedByFirstName} ${row?.suspendedByLastName}`}
              updatedAt={Number(row?.suspendedAt || 0)}
            />
          );
        return <RiskLevelTag row={row} />;
      },
    };
  }, []);

  const extraCols = useMemo(() => {
    switch (selectedTab) {
      case "underwriting":
        return {
          desktop: [
            signupDateCol,
            assignmentCol,
            categoryRiskCol,
            underwritingScoreCol,
            statusCol,
          ],
          mobile: [underwritingScoreCol],
        };

      case "risk":
        return {
          desktop: [
            assignmentCol,
            categoryRiskCol,
            ageEscalationCol,
            riskLevelCol,
          ],
          mobile: [riskLevelCol],
        };

      case "invited":
        return {
          desktop: [emailCol, inviteCol, statusCol],
          mobile: [statusCol],
        };

      case "sponsor":
        return {
          desktop: [
            mccCol,
            categoryRiskCol,
            recommendationCol,
            sponsorStatusCol,
          ],
          mobile: [sponsorStatusCol],
        };

      case "onboarding":
        return {
          desktop: [assignmentCol, signupDateCol, onboardingScoreCol],
          mobile: [onboardingScoreCol],
        };

      default:
        return {
          desktop: [signupDateCol, categoryRiskCol, processedCol, statusCol],
          mobile: [statusCol],
        };
    }
  }, [
    selectedTab,
    signupDateCol,
    assignmentCol,
    categoryRiskCol,
    underwritingScoreCol,
    processedCol,
    statusCol,
  ]);

  const columns: TableColumnType[] = useMemo(
    () => [
      {
        key: "name",
        sortable: isWideView,
        columnType: "merchant-first",
        title: "Merchant",
        columnWidth: !isWideView ? 3 : merchantColWidth,
        hasCheckbox: isSponsorTabSelected,
        renderColumn: (row: MerchantData) => {
          return (
            <MainInfo
              row={row}
              disabled={false}
              isSponsorTable={isSponsorTabSelected}
              removeFromWatchlistHandler={() =>
                removeMerchantFromWatchlist(row?.accID, row?.name)
              }
              addToWatchlistHandler={() => addToWatchlistHandler(row)}
              isAcquirerPortal={isAcquirerPortal}
              badgeTooltipProps={
                row?.hasUnrepliedActivityMessages && row?.lastMessageActivity
                  ? {
                      color: "light",
                      title: (
                        <BadgeTooltipContent data={row?.lastMessageActivity} />
                      ),
                    }
                  : undefined
              }
              isWarnedMerchant={isWarnedMerchant(row)}
              thumbnailType="merchant"
              showCanManageMoney={selectedTab === "risk" && isWideView}
            />
          );
        },
      },
      ...(isWideView ? extraCols.desktop : extraCols.mobile),
    ],
    [isWideView, isAcquirerPortal, extraCols, selectedTab],
  );

  const defaultSort = getDefaultSort(selectedTab);

  return {
    removeMerchantFromWatchlist,
    addToWatchlistHandler,
    isWarnedMerchant,
    columns,
    defaultSort,
    selectedTabStatus: {
      isAllTabSelected: selectedTab === "all",
      isInvitedTabSelected,
      isRiskTabSelected,
      isOnboardingSelected,
      isSponsorTabSelected,
      isUnderwritingTabSelected: selectedTab === "underwriting",
    },
  };
};

const getDefaultSort = (tab: string) => {
  switch (tab) {
    case "sponsor":
      return "lastInternalApprovalAt,-accID";
    case "invited":
      return "ownerInviteLastSentAt,-accID";
    case "all":
    case "onboarding":
    case "risk":
    case "underwriting":
    default:
      return "createdAt,-accID";
  }
};
