export const AUTH_PATHS = {
  LOGIN: "login",
  SIGNUP: "signup",
  MERCHANT: "merchant",
  CUSTOMER: "customer",
  RESET_PASSWORD: "reset-password",
  FORGOT_PASSWORD: "forgot-password",
  CHANGE_PASSWORD: "change-password/:id",
  SET_PASSWORD: "set-password/:id",
  SUCCESFUL_CHANGE_PASSWORD: "successful-change-password",
  VALIDATE_ACCOUNT: "validate/:token",
  CONFIRM_EMAIL: "confirm-email",
  SESSION_EXPIRED: "session-expired",
  LINK_EXPIRED: "link-expired",
  INVITE_USER_LINK: "invites",
};

export const MERCHANT_PATHS = {
  DASHBOARD: "dashboard",
  MANAGE_MONEY: "manage-money",
  PAYMENT_FORMS: "payment-forms",
  PAYMENT_FORMS_ID: ":id",
  MANAGE_MONEY_ID: ":id",
  CUSTOMERS: "customers",
  CUSTOMER_ID: ":id",
  FUNDRAISERS: "fundraisers",
  FUNDRAISERS_ID: ":id",
  NEW_ADVANCED_BUILDER: "new-advanced-builder",
  SETTINGS: "settings",
  EVENTS: "events",
  STANDARD: "standard",
  EVENTS_ID: ":id",
  BUSINESS: "business",
  MANAGE_TEAM: "manage-team",
  MEDIA_LIBRARY: "media-library",
  INVOICES: "invoices",
  INVOICES_ID: ":id",
  SWEEPSTAKES: "sweepstakes",
  SWEEPSTAKES_ID: ":id",
  MEMBERSHIP: "memberships",
  MEMBERSHIP_ID: ":id",
  WELCOME: "welcome",
};

export const ENTERPRISE_PATHS = {
  WELCOME: "welcome",
  PROVIDER: "provider",
  MERCHANTS: "merchants",
  TRANSACTIONS: "transactions",
  TRANSACTIONS_ID: "transactions/:id",
  PROCESSING: "processing",
  PROCESSING_ID: ":id",
  CUSTOMERS: "customers",
  SETTINGS: "settings",
  BUSINESS_DETAILS: "business-details",
  MANAGE_TEAM: "manage-team",
  THEME_BRANDING: "theme-branding",
  DEVELOPER_API: "developer",
  TRANSFERS: "transfers",
};

export const ERROR_PATHS = {
  NOTFOUND: "/404",
};

export const ACQUIRER_PATHS = {
  ANALYTICS: "analytics",
  PROCESSING: "processing",
  PROCESSING_ID: ":id",
  PROVIDERS: "providers",
  SETTINGS: "settings",
  ENTERPRISE: ":id",
  MERCHANTS: "merchants",
  TRANSACTIONS_ID: "transactions/:id",
  ACQUIRER: "acquirer",
  BUSINESS_DETAILS: "business-details",
  MANAGE_TEAM: "manage-team",
  PROVIDER_RESTORED: "/acquirer/provider-restored/:enterpriseName",
  SETTLEMENT: "settlements",
  SETTLEMENT_ID: ":id",
  FEATURE_FLAGS: "feature-flags",
  DISPUTES: "disputes",
  TRANSFERS: "transfers",
};

export const PUBLIC_FORM_PATHS = {
  EVENTS: "events/:id",
  SWEEPSTAKES: "sweepstakes/:id",
  INVOICES: "invoices/:id",
  FUNDRAISERS: "fundraisers/:id",
  MEMBERSHIPS: "memberships/:id",
  PRODUCT: ":id",
  PAYMENT_FORMS: "payment-forms/:id",
  PAY_PRODUCT_BUILDER: `pay_product_builder`,
  PUBLIC_PRODUCT: `pay_product_builder/:id`,
  CHECKOUT: ":id/checkout",
};

export const POLICY_PATHS = {
  PRIVACY: "privacy",
  TERMS_OF_SERVICE: "terms_of_service",
  REFUND_POLICY: "refund-policy",
  CANCEL_MEMBERSHIP: "cancel-membership",
};
