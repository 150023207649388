import { Button } from "@common/Button";
import { Text, TruncateText } from "@common/Text";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";
import { Box, Stack, StackProps, styled } from "@mui/material";
import { palette } from "@palette";
import { Pencil } from "@phosphor-icons/react";

interface InfoDisplayProps extends StackProps {
  items: {
    Joined: string;
    "Full name": string;
    Email: string;
    "Job Title": string;
    Employer: string;
  };
  onClickEdit: () => void;
  isEditAllowed?: boolean;
}

function InfoDisplay({
  items,
  onClickEdit,
  isEditAllowed,
  ...rest
}: InfoDisplayProps) {
  return (
    <Stack {...rest} gap="4px" mb={4}>
      {Object.keys(items).map((key, index) => {
        const value = items[key as keyof InfoDisplayProps["items"]];
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                backgroundColor: "inherit",
                padding: "4px 8px",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StyledText lineClamp={1}>{key}</StyledText>
              <StyledText isValue lineClamp={1}>
                {value}
              </StyledText>
            </Box>
            {index === 0 && (
              <Button
                background="tertiary"
                size="medium"
                startIcon={<Pencil />}
                onClick={onClickEdit}
                disabled={!isEditAllowed}
                tooltipProps={{
                  message: EDIT_DENY_MESSAGE,
                  show: !isEditAllowed,
                }}
              >
                <Text fontWeight="book" color={palette.neutral[90]}>
                  Edit Profile
                </Text>
              </Button>
            )}
          </Box>
        );
      })}
    </Stack>
  );
}
export default InfoDisplay;

const StyledText = styled(TruncateText, {
  shouldForwardProp: (prop) => prop !== "isValue",
})<{ isValue?: boolean }>(({ theme, isValue }) => ({
  color: isValue ? theme.palette.neutral["600"] : theme.palette.neutral["400"],
  fontWeight: isValue ? 350 : 300,
  lineHeight: "16.8px",

  ...(isValue && {
    minHeight: "16.8px",
  }),
}));
