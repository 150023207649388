import {
  QKEY_LIST_EVENTS,
  QKEY_LIST_FUNDRAISERS,
  QKEY_LIST_INVOICES,
  QKEY_LIST_MEMBERSHIPS,
  QKEY_LIST_PAYMENT_FORMS,
  QKEY_LIST_PRODUCTS,
  QKEY_LIST_SWEEPSTAKES,
} from "@constants/queryKeys";
import { useGetCurrentMerchantId } from "@hooks/common";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { checkPortals } from "@utils/routing";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { customInstance } from "..";
import { buildMerchantEndpoints } from "../utils.api";

export type ProductParams = {
  queryString?: string;
  page?: number;
  sorting?: string;
  searchQuery?: string;
  filter?: string;
  memberStatus?: string;
  tab?: string;
};

export const mapTypeToQueryKey = {
  event: QKEY_LIST_EVENTS,
  fundraiser: QKEY_LIST_FUNDRAISERS,
  invoice: QKEY_LIST_INVOICES,
  membership: QKEY_LIST_MEMBERSHIPS,
  sweepstake: QKEY_LIST_SWEEPSTAKES,
  "payment-form": QKEY_LIST_PAYMENT_FORMS,
  standard: QKEY_LIST_PRODUCTS,
};

const getData = (
  type: string,
  { queryString, page, sorting, searchQuery }: ProductParams,
  signal: AbortSignal | undefined,
) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  const dynamicType = type !== "payment-form" ? `typeName:"${type}"` : "";
  return customInstance({
    url: buildMerchantEndpoints(
      `products?filter=${dynamicType}${queryString}&sort=${
        sorting || "-id"
      }${search}&page=${page}&max=${ROWS_PER_PAGE}`,
    ),
    method: "GET",
    signal,
  });
};

export const useQueryFactory =
  (type: keyof typeof mapTypeToQueryKey) =>
  (
    params: ProductParams,
    options: Omit<
      UseQueryOptions<any, any, any, any>,
      "queryKey" | "queryFn"
    > = {},
  ) => {
    const { isTablesOptimizationsEnabled } = useGetFeatureFlagValues();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    return useQuery(
      [
        mapTypeToQueryKey[type],
        params.queryString,
        params.page,
        params.sorting,
        params.searchQuery,

        // type
      ],
      async ({ signal }) => {
        const data = await getData(type, params, signal);
        return data;
      },
      {
        retry: 1,
        ...options,
        ...(isTablesOptimizationsEnabled && {
          keepPreviousData: true,
          staleTime: 1000 * 60 * 0.5,
          cacheTime: 1000 * 60 * 5,
          refetchOnReconnect: false,
          refetchOnMount: false,
          initialData: () => {
            return queryClient.getQueryData([
              mapTypeToQueryKey[type],
              params.queryString,
              params.page,
              params.sorting,
              params.searchQuery,

              // type
            ]);
          },
        }),
        onError(err: any) {
          if (err.not_authorized) {
            dispatch(
              updatePermissions({
                [`campaign_${type}`]: true,
              }),
            );
          } else {
            if (options.onError) {
              options.onError(err);
            }
          }
        },
      },
    );
  };

export const useGetProductsStatsFactory = (id?: number, getAll?: boolean) => {
  const dispatch = useAppDispatch();
  const { merchantId } = useGetCurrentMerchantId();
  const { isMerchantPortal } = checkPortals();

  return useQuery(
    [`get-campaign-stats`, id, isMerchantPortal],
    async () => {
      const url = buildMerchantEndpoints(
        !getAll ? `product-types/${id}/stats` : `product-types/stats`,
        merchantId,
      );

      const data = await customInstance({
        url,
        method: "GET",
      });
      return data;
    },
    {
      enabled: (!!id || !!getAll) && Boolean(merchantId) && isMerchantPortal,
      refetchOnWindowFocus: false,
      retry: 1,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              product_stats: true,
            }),
          );
        }
      },
    },
  );
};

export const useGetTransactionsFactory =
  (path: string, queryKey: string) =>
  <T>(
    {
      page,
      sorting,
      queryString,
      searchQuery,
      filter,
      ...rest
    }: ProductParams & T,
    options: Omit<
      UseQueryOptions<any, any, any, any>,
      "queryKey" | "queryFn"
    > = {},
  ) => {
    const searchParams = new URLSearchParams(document.location.search);
    let merchantId: any = searchParams.get("merchant");
    merchantId = merchantId ? parseInt(merchantId) : null;
    return useQuery(
      [
        queryKey,
        page,
        sorting,
        queryString,
        searchQuery,
        filter,
        ...Object.values(rest),
      ],
      async () => {
        const sortQuery = sorting ? `sort=${sorting}` : "";
        let basePath = `${path}?${sortQuery}&page=${page}&max=${ROWS_PER_PAGE}`;

        if (queryString) {
          basePath += `&filter=${queryString}`;
        }

        if (searchQuery) {
          basePath += `&q="${searchQuery}"`;
        }
        if (filter) {
          basePath += `&filter=${filter}`;
        }

        const data = await customInstance({
          url: buildMerchantEndpoints(basePath, merchantId),
          method: "GET",
        });
        return data;
      },
      options,
    );
  };
