import {
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
  MERCHANT_PORTAL_MODIFY_RECURRING_MODAL,
  MERCHANT_PORTAL_VIEW_TRANSACTION_MODAL,
  MERCHANT_VIEW_CUSTOMER,
} from "modals/modal_names";
import { shouldBeHidden } from "@constants/constants";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

export type MenuActionType = {
  title: string;
  onClick: (...args: any) => void;
  hidden?: boolean;
};

function getSourceAccount(row?: any) {
  return {
    ...row?.sourceAccount.user,
    fullName: row?.sorceAccountFullName,
    card: row?.sourceMethod?.method,
  };
}

export type CampaignType =
  | "fundraiser"
  | "event"
  | "invoice"
  | "payment-form"
  | "membership"
  | "sweepstake"
  | "standard";

const useReportMenuActions = (
  selectedRow: any,
  handleOpen: (...args: any) => void,
  campaignType: CampaignType,
  isFirst?: boolean,
  isLast?: boolean,
  setSelectedRow?: React.Dispatch<any>,
) => {
  const isChargeback = selectedRow?.status === "Chargeback";
  const isRefund = selectedRow?.status === "Refunded";

  const isRefundAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.REFUND),
    operation: OPERATIONS.CREATE,
  });

  const isReceiptAllowed = true;
  // on BE is always enabled
  // const isReceiptAllowed = useAccessControl({
  //   resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.RECEIPT),
  //   operation: OPERATIONS.CREATE,
  // });

  const menuActions = [
    {
      title: "Refund transaction",
      hidden: shouldBeHidden.refund || selectedRow?.hideRefund,
      disabled: !isRefundAllowed,
      onClick: () =>
        handleOpen(MERCHANT_PORTAL_REFUND_MODAL, {
          sourceAccount: getSourceAccount(selectedRow),
          ids: {
            transactionIDs: [selectedRow.id],
          },
          isFirst,
          isLast,
          setSelectedRow,
        }),
    },
    {
      title: "Resend receipt",
      disabled: !isReceiptAllowed,
      onClick: () => {
        handleOpen(MERCHANT_PORTAL_SEND_RECEIPT_MODAL, {
          transactionID: selectedRow.id,
          emails: [
            !isChargeback && !isRefund
              ? selectedRow?.sourceAccount?.user?.email
              : selectedRow?.destinationAccount?.user?.email,
          ],
          sourceAccount: getSourceAccount(selectedRow),
          customerID:
            !isChargeback && !isRefund
              ? selectedRow?.destinationAccount.id
              : selectedRow?.sourceAccount.id,
        });
      },
    },
    ...(campaignType !== "event" && campaignType !== "sweepstake"
      ? [
          {
            title: "Modify Recurring",
            hidden: !selectedRow?.orderRecurringItemID,
            onClick: () =>
              handleOpen(MERCHANT_PORTAL_MODIFY_RECURRING_MODAL, {
                orderRecurringItemID: selectedRow.orderRecurringItemID,
                sourceAccount: getSourceAccount(selectedRow),
              }),
          },
        ]
      : []),
    {
      title: "View transaction",
      onClick: () =>
        handleOpen(MERCHANT_PORTAL_VIEW_TRANSACTION_MODAL, {
          id: selectedRow.id,
        }),
    },
    {
      title: "View customer",
      onClick: () => {
        handleOpen(MERCHANT_VIEW_CUSTOMER, {
          id: selectedRow.customer.id,
          merchantId:
            !isChargeback && !isRefund
              ? selectedRow?.destinationAccount?.id
              : selectedRow?.sourceAccount?.id,
        });
      },
    },
  ];

  return { menuActions };
};

export default useReportMenuActions;
