// translation
import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";

import { CREATE_SWEEPSTAKES_MODAL } from "modals/modal_names";
import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";

const NewSweepstakesBanner = ({ backgroundUrl }: { backgroundUrl: string }) => {
  const { t } = useTranslation(namespaces.pages.sweepstakes);

  return (
    <NewProductBanner
      title={t("sweepstakesCapital")}
      createModal={CREATE_SWEEPSTAKES_MODAL}
      analyticsType="sweepstakes"
      campaignType="event"
      background={backgroundUrl}
      createCampaignTitle={t("createSweepstake")}
    />
  );
};

export default NewSweepstakesBanner;
