import {
  getProcessingAmount,
  SelectorOption,
} from "@common/TableFilters/utils";
import { TransactionDateType } from "@pages/ManageMoney/types";
import { FilterObjectTypes } from "types/utils.types";

export enum QUERYOBJECTKEYS_PROVIDERS_FILTERS {
  signupDate = "signupDate",
  createdAt = "createdAt",
  enterpriseStatus = "enterpriseStatus",
  businessClassification = "businessClassification",
  watchlist = "watchlist", //this not yet
  Processed = "totalProcessed ",
  Transactions = "transactionsCount",
  approved_merchants = "approvedMerchants",
  underwriterEmail = "underwriterEmail",
  underwritingChallengeScorePercentage = "underwritingScorePercentage",
}

export const defaultValuesProvidersFilter = {
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.signupDate]: {
    dateName: "createdAt",
    endDate: undefined,
    label: undefined,
    startDate: undefined,
    val: undefined,
  } as TransactionDateType,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.createdAt]: "",
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.enterpriseStatus]:
    [] as Array<FilterObjectTypes>,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.businessClassification]:
    [] as Array<FilterObjectTypes>,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.Processed]: {} as FilterObjectTypes,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.Transactions]: {} as FilterObjectTypes,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.approved_merchants]:
    {} as FilterObjectTypes,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.watchlist]: "" as
    | boolean
    | string
    | undefined,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.underwritingChallengeScorePercentage]:
    "" as string | undefined,
  [QUERYOBJECTKEYS_PROVIDERS_FILTERS.underwriterEmail]: [] as SelectorOption[],
};
export type DefaultValuesTypeProviderFilters =
  typeof defaultValuesProvidersFilter;
export const UnderwritingScoreValuesPercentage = {
  belowEighty: "underwritingScorePercentage:<80",
  overEighty: "underwritingScorePercentage:>80",
};
export const enterpriseStatusArray = [
  {
    value: "Pre Approved",
    label: "Underwriting",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Suspended",
    label: "Suspended",
  },
  {
    value: "Declined",
    label: "Declined",
  },
];
export const businessClassificationType = [
  {
    value: "ISO (Independent Sales Organizations)",
    label: "ISO",
  },
  {
    value: "PSP (Payment Service Provider)",
    label: "PSP",
  },
  {
    value: "Fiscal Sponsor",
    label: "Fiscal Sponsor",
  },
  {
    value: "Reseller",
    label: "Reseller",
  },
  {
    value: "Payment Facilitator",
    label: "Payment Facilitator",
  },
  {
    value: "Merchant Service Provider",
    label: "Merchant Service Provider",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const getProcessedArray = (prefix: string) => [
  {
    label: "0 USD",
    value: getProcessingAmount({
      amount: 0,
      modifier: "is equal to or less than",
      prefix,
    }),
  },
  {
    label: "1 - 5000 USD",
    value: getProcessingAmount({
      amount: 100,
      secondAmount: 500000,
      modifier: "is between",
      prefix,
    }),
  },
  {
    label: "> 5000 USD",
    value: getProcessingAmount({
      amount: 500000,
      modifier: "is equal to or greater than",
      prefix,
    }),
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const getTransactionsArray = (prefix: string) => [
  {
    label: "0",
    value: getProcessingAmount({
      amount: 0,
      modifier: "is equal to or less than",
      prefix,
    }),
  },
  {
    label: "1 - 5000",
    value: getProcessingAmount({
      amount: 1,
      secondAmount: 5000,
      modifier: "is between",
      prefix,
    }),
  },
  {
    label: "> 5000",
    value: getProcessingAmount({
      amount: 5000,
      modifier: "is equal to or greater than",
      prefix,
    }),
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const getApprovedMerchantsArray = (prefix: string) => [
  {
    label: "0",
    value: getProcessingAmount({
      amount: 0,
      modifier: "is equal to or less than",
      prefix,
    }),
  },
  {
    label: "1 - 100",
    value: getProcessingAmount({
      amount: 1,
      secondAmount: 100,
      modifier: "is between",
      prefix,
    }),
  },
  {
    label: "> 100",
    value: getProcessingAmount({
      amount: 100,
      modifier: "is equal to or greater than",
      prefix,
    }),
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const QFORM_QUERY_KEY = "current-form";