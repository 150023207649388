import { InputAdornment } from "@mui/material";
import {
  CheckCircle,
  Icon,
  Prohibit,
  WarningCircle,
} from "@phosphor-icons/react";
import GiveSelect from "@shared/GiveInputs/GiveSelect";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import { formatString } from "@utils/helpers";
import { capitalizeFirstLetter } from "@utils/index";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useFetchMerchantRisk from "../../hooks/useFetchMerchantRisk";
import useUpdateMerchant from "../../hooks/useUpdateMerchant";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { CustomToolTip as PermissionTooltip } from "componentsV2/Table/CustomTooltip";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { GIVE_CONFIRMATION_POP_UP } from "modals/modal_names";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";

type RiskLevel = "normal" | "high" | "restricted";

type TSelectOption = {
  label: string;
  value: string;
  IconLeft: Icon;
  checkedIconType: "Check";
  isSelected: boolean;
};

const riskLevelConstants: Record<
  RiskLevel,
  { color: string | undefined; Icon: Icon }
> = {
  normal: {
    color: undefined,
    Icon: CheckCircle,
  },
  high: {
    color: undefined,
    Icon: WarningCircle,
  },
  restricted: {
    color: undefined,
    Icon: Prohibit,
  },
};

interface Props {
  setLocalRiskValue: Dispatch<SetStateAction<string>>;
  localRiskValue: string;
}

const MerchantRiskCategoryInput = ({
  localRiskValue,
  setLocalRiskValue,
}: Props) => {
  const { data } = useMerchantSidePanelContext();
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const { categoryCodeID, category, categoryCodeName, merchantRiskStatus } =
    data?.merchantInfo || {};
  const { remove } = useModal(GIVE_CONFIRMATION_POP_UP);

  const { updateMerchantMutation } = useUpdateMerchant();
  const { mutate, isLoading: isUpdateLoading } = updateMerchantMutation;
  const { data: categoriesData, isLoading } = useFetchMerchantRisk();
  const { modify_merchant } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();
  const [selectOptions, setSelectOptions] = useState<Array<TSelectOption>>([]);

  const getRiskLevelColor = (riskLevel: RiskLevel): string | undefined => {
    switch (riskLevel) {
      case "normal":
        return palette?.primitive?.success?.[50];
      case "high":
        return palette?.filled?.orange;
      case "restricted":
        return palette?.primitive?.error?.[50];
      default:
        return undefined;
    }
  };

  const getInputValueColor = (label: string | undefined) => {
    const riskKey = (label?.toLowerCase() as RiskLevel) || "normal";
    return {
      color: getRiskLevelColor(riskKey),
      Icon: riskLevelConstants[riskKey].Icon,
    };
  };

  const Icon = getInputValueColor(merchantRiskStatus).Icon;
  const isDisabled = !categoryCodeID || isLoading || isUpdateLoading;

  const hasEditMerchantPermission = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const allowedEditMerchant = isEnterprisePortal
    ? hasEditMerchantPermission && modify_merchant
    : hasEditMerchantPermission;

  const handleSelectChange = (e: any) => {
    const value = e?.target?.value;
    if (value && value !== merchantRiskStatus) {
      NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
        modalType: "change-mcc",
        title: "Change MCC",
        description: (
          <>
            The risk level for MCC{" "}
            <strong>
              {category} - {categoryCodeName}
            </strong>{" "}
            is currently set to{" "}
            <strong>{capitalizeFirstLetter(merchantRiskStatus)}</strong>. Are
            you sure you want to change it to{" "}
            <strong>{capitalizeFirstLetter(value)}</strong>? This change will
            apply only to this merchant.
          </>
        ),
        actions: {
          handleSuccess: {
            onClick: () => {
              setLocalRiskValue(value);
              mutate({ merchantRiskStatus: `${value}` });
              remove();
            },
          },
          handleCancel: {
            onClick: remove,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (categoriesData?.data) {
      const newOptions = categoriesData.data.map((category: any) => ({
        label: capitalizeFirstLetter(category?.name),
        value: category?.name,
        IconLeft: getInputValueColor(category?.name).Icon,
        checkedIconType: "Check",
        isSelected:
          formatString(category?.name) ===
          formatString(merchantRiskStatus || ""),
      })) as Array<TSelectOption>;
      setSelectOptions(newOptions);
    }
  }, [categoriesData, merchantRiskStatus]);

  return (
    <PermissionTooltip
      showToolTip={!allowedEditMerchant}
      message={ACTION_DENY_MESSAGE}
      placement="top"
      alignment="end"
    >
      <GiveSelect
        disabled={isDisabled || !allowedEditMerchant}
        label="Merchant Risk Category"
        options={selectOptions}
        value={localRiskValue}
        inputValueColor={getInputValueColor(localRiskValue).color}
        useContextualMenu
        withIconOffset={false}
        onChange={handleSelectChange}
        {...(!isMobileView
          ? {
              contextualMenuProps: {
                color: "transparent",
                texture: "blurred",
                anchorOrigin: {
                  vertical: -20,
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              },
            }
          : {})}
        InputProps={{
          startAdornment:
            isDisabled || !merchantRiskStatus ? null : (
              <InputAdornment sx={{ ml: "16px" }} position="start">
                <Icon
                  size={20}
                  fill={
                    getInputValueColor(localRiskValue || merchantRiskStatus)
                      .color
                  }
                />
              </InputAdornment>
            ),
        }}
      />
    </PermissionTooltip>
  );
};

export default MerchantRiskCategoryInput;
