import { BankAccountTagType } from "@common/Tag/BankAccountTag";
import { Stack } from "@mui/material";
import { DotsThree } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import GiveBAStatusChip from "./GiveBAStatusChip";

type TBankAccountHeader = {
  accountName?: string;
  status?: BankAccountTagType;
  isPlaid?: boolean;
  onOpenMenu?: (event: React.MouseEvent<HTMLElement>, id: number) => void;
  accountId: number;
};

const BankAccountHeader = ({
  accountName,
  status,
  isPlaid,
  onOpenMenu,
  accountId,
}: TBankAccountHeader) => {
  const headerDescription = isPlaid
    ? "Connected With Plaid"
    : "Manually Uploaded";
  return (
    <Stack
      direction="row"
      flexGrow={1}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      gap={2}
    >
      <Stack direction="column" gap="8px" alignItems="left" overflow="hidden">
        <GiveText color="primary">{accountName}</GiveText>
        <GiveText color="secondary" variant="bodyXS">{headerDescription}</GiveText>
      </Stack>
      <Stack
        display="flex"
        direction="row"
        gap="8px"
        alignItems="center"
        justifyContent="center"
      >
        {onOpenMenu && (
          <GiveIconButton
            sx={{ margin: "0px !important" }}
            variant="ghost"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onOpenMenu(e, accountId);
            }}
            Icon={DotsThree}
          />
        )}
        <GiveBAStatusChip accountStatus={status} />
      </Stack>
    </Stack>
  );
};

export default BankAccountHeader;
