import { showMessage } from "@common/Toast";
import {
  QKEY_LIST_PAYMENT_FORMS,
  QKEY_LIST_PRODUCTS,
} from "@constants/queryKeys";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { donorModalType } from "../types";
import moment from "moment";
import { parseAmount } from "@utils/index";
import { buildMerchantEndpoints } from "@services/api/utils.api";

export function useDeleteSingleProduct() {
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    ({
      productId,
    }: {
      onSuccessAction?: () => void;
      productId: string | number;
    }) => {
      return customInstance({
        url: `/merchants/${merchantId}/products/${productId}`,
        method: "DELETE",
      });
    },
    {
      onSuccess(data, variables) {
        variables?.onSuccessAction && variables.onSuccessAction();
        showMessage("Success", "product deleted successfully");
        queryClient.refetchQueries(QKEY_LIST_PAYMENT_FORMS);
        queryClient.refetchQueries(QKEY_LIST_PRODUCTS);
      },
      onError() {
        showMessage("Error", "Unable to delete the product");
      },
    },
  );
  return { mutate };
}

export function useGetDonorsList({
  id,
  max = 20,
  typeState,
}: {
  id?: string | number;
  max?: number;
  typeState?: donorModalType;
}) {
  const {
    data,
    fetchNextPage: originalFetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isError,
    isLoading,
  } = useInfiniteQuery(
    ["get_donors_list_by_id", id, typeState, max],
    async ({ pageParam = 1 }) => {
      const sortType =
        typeState === "top_donors" ? "-sumTransaction" : "-createdAt";
      const response = await customInstance({
        url: `/products/${id}/transactions?sort=${sortType}&page=${pageParam}&max=${max}`,
      });

      const data = response?.data || [];
      return {
        data: data?.map((donor: any) => {
          return {
            id: donor?.id,
            name: donor?.isCustomerAnonymous
              ? "Anonymous"
              : `${donor?.transactionCustomer?.firstName} ${donor?.transactionCustomer?.lastName}`,

            when: moment.unix(donor?.createdAt).fromNow(),
            isTopDonor: donor?.isTopDonor || false,
            amount: parseAmount(donor?.sumTransaction),
          };
        }),
        total: response.total,
        currentPage: pageParam,
        hasNextPage: pageParam * max < response.total, // Determine if more pages exist
      };
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage ? lastPage.currentPage + 1 : undefined,
      enabled: !!id,
    },
  );
  const fetchNextPage = async () => {
    if (hasNextPage && !isFetchingNextPage) {
      await originalFetchNextPage();
    }
  };

  return {
    donors: data?.pages.flatMap((page) => page.data) || [],
    total: data?.pages[0]?.total || 0,
    isFetching,
    isFetchingNextPage,
    isError,
    fetchNextPage,
    hasNextPage,
    isLoading,
  };
}

export const useGetFundraiserVariants = (id: string) => {
  return useQuery("fundraiser-variants", async () => {
    const data = await customInstance({
      url: buildMerchantEndpoints(`products/${id}/variants`),
      method: "GET",
    });
    return data;
  });
};
