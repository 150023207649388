import { Stack } from "@mui/material";
import { DividerText } from "./components";
import { Select } from "@common/Select";
import useChangeRole from "../hooks/useChangeRole";
import CheckIcon from "@assets/icons/RebrandedIcons/CheckIcon";
import { palette } from "@palette";
import { SelectProps } from "@common/Select/Select";
import { memo, useEffect } from "react";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { MemberRoleName } from "@customTypes/team.member";
import { useRoles } from "@services/api/accounts";

type ChangeRoleProps = {
  role: MemberRoleName;
  updateRole: (newRole: MemberRoleName) => void;
  id: number;
  isCurrentUser: boolean;
  isOwner: boolean;
  isEditAllowed: boolean;
};

function ChangeRole({
  role,
  updateRole,
  id,
  isCurrentUser,
  isOwner,
  isEditAllowed,
}: ChangeRoleProps) {
  const { title, setTitle, onSubmit } = useChangeRole(role, id, updateRole);
  const isDisabled = isOwner || isCurrentUser || !isEditAllowed;
  const { data, getDefaultRole } = useRoles();
  const filteredData = data?.data?.map((userRole) => ({
    ...userRole,
    disabled: userRole?.value === "owner",
    endIcon:
      userRole.value === title && !isOwner ? (
        <CheckIcon fill={palette.black[300]} />
      ) : undefined,
  }));
  useEffect(() => {
    setTitle(getDefaultRole(role));
  }, [role]);

  return (
    <Stack direction="column" gap={2} alignItems="stretch">
      <DividerText>Change Role</DividerText>
      <CustomToolTip showToolTip={!isEditAllowed} message={EDIT_DENY_MESSAGE}>
        <ChooseRoleSelect
          options={filteredData}
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newRole = getDefaultRole(event.target.value);
            setTitle(newRole);
            onSubmit(newRole);
          }}
          disabled={isDisabled}
        />
      </CustomToolTip>
    </Stack>
  );
}

export const ChooseRoleSelect = ({ ...props }: SelectProps) => {
  return (
    <Select
      placeholder="Member"
      label="Role"
      shouldDisplayIcon
      {...props}
      sx={{
        "& .MuiSelect-outlined.MuiInputBase-input": {
          "& div > svg": {
            display: "none",
          },
        },
      }}
      selectItemProps={{
        sx: {
          "& div": {
            justifyContent: "space-between",
            padding: "4px 8px",
            fontWeight: 350,
            borderRadius: "4px",
          },
          "&:hover": {
            backgroundColor: palette.liftedWhite[100],
          },
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            padding: "4px",
            borderRadius: "8px",
            background: "#FAFAFA",
            boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.15)",
          },
        },
        sx: {
          "& .MuiList-root": {
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          },
        },
      }}
    />
  );
};

export default memo(ChangeRole);
