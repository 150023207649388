import { Stack } from "@mui/material";

import { QFORM_QUERY_KEY } from "@pages/AcquirerPortal/Enterprises/Modal/constants";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import StyleWarningMessage from "features/Minibuilders/PaymentFormMinibuilder/StyleWarningMessage";
import { FC } from "react";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { useQueryObserver } from "./hooks/useQueryObserver";
import PublicUrlShare from "./PublicURLShare/PublicURLShare";
import { TQueryDataLaunchStepPaymentFormKey } from "./types";

const LaunchStep: FC<any> = () => {
  const { palette } = useAppTheme();
  const { queryData } =
    useQueryObserver<TQueryDataLaunchStepPaymentFormKey>(QFORM_QUERY_KEY);
  const { mutate } = usePayBuilderForm();

  return (
    <Stack gap={2}>
      <Stack gap={1.5}>
        <GiveText
          fontSize="28px"
          sx={{ fontWeight: 271, color: palette.primitive?.neutral[90] }}
        >
          Launch your products
        </GiveText>
        <GiveText
          fontSize="14px"
          sx={{
            fontWeight: 400,
            color: palette.primitive?.neutral[60],
          }}
        >
          Your Product form is ready to go! You can share this via the link
          below or embed this directly into your own page.
        </GiveText>
      </Stack>

      {typeof queryData?.product?.id !== "number" && (
        <StyleWarningMessage
          title="Almost There! Launch Your Form."
          strokeIconColor={palette.primitive?.warning[50]}
          sx={{
            border: `1px solid  ${palette.primitive?.warning[50]}`,
            backgroundColor: palette.primitive?.warning[10],
            fontSize: "14px",
            alignItems: "flex-start",
            marginX: "0px",
          }}
          description="To generate your shareable link, you'll need to either publish your form now or save it as a draft to work on later."
          renderAction={() => (
            <GiveText
              onClick={() => {
                mutate({
                  shouldNavigate: false,
                });
              }}
              sx={{
                color: palette.primitive?.warning[100],
                fontSize: "14px",
                cursor: "pointer",
                width: "fit-content",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Save as Draft
            </GiveText>
          )}
        />
      )}

      <PublicUrlShare variant="large" />
    </Stack>
  );
};

export default LaunchStep;
