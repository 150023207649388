type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const ThreadsIcon = ({
  width = 49,
  height = 48,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="24" fill="black" />
      <path
        d="M29.025 23.2328C28.9342 23.1893 28.8427 23.148 28.7497 23.1075C28.5877 20.121 26.9557 18.411 24.2152 18.3938C24.2025 18.3938 24.1905 18.3938 24.1777 18.3938C22.539 18.3938 21.1755 19.0935 20.3362 20.3663L21.843 21.3998C22.47 20.4488 23.4532 20.2463 24.1777 20.2463C24.186 20.2463 24.1942 20.2463 24.2025 20.2463C25.1047 20.2523 25.7857 20.514 26.226 21.0263C26.547 21.399 26.7615 21.9135 26.8672 22.563C26.067 22.4273 25.2022 22.3853 24.2775 22.4385C21.672 22.5885 19.9972 24.108 20.1097 26.2193C20.1667 27.2903 20.7007 28.2113 21.612 28.8135C22.383 29.322 23.3752 29.571 24.4072 29.5148C25.77 29.4398 26.8387 28.92 27.5842 27.9698C28.1505 27.2475 28.509 26.3123 28.6672 25.134C29.3167 25.5263 29.7975 26.0415 30.0637 26.6618C30.516 27.7155 30.5422 29.4473 29.1292 30.8588C27.891 32.0955 26.403 32.631 24.1537 32.6475C21.6592 32.6288 19.7722 31.8293 18.5452 30.2693C17.3962 28.809 16.803 26.7 16.7805 24C16.803 21.3008 17.3962 19.191 18.5452 17.7308C19.7722 16.1715 21.6585 15.3713 24.1537 15.3525C26.6662 15.3713 28.5862 16.1753 29.8597 17.742C30.4845 18.5108 30.9555 19.4768 31.2652 20.6033L33.0315 20.1323C32.655 18.7455 32.0632 17.55 31.2577 16.5593C29.625 14.55 27.237 13.521 24.1597 13.4993H24.1477C21.0772 13.5203 18.7155 14.5538 17.1292 16.5705C15.7177 18.3653 14.9895 20.862 14.9647 23.9918V23.9993V24.0068C14.9895 27.1365 15.7177 29.634 17.1292 31.428C18.7155 33.4448 21.0772 34.4783 24.1477 34.4993H24.1597C26.8897 34.4805 28.8142 33.7658 30.3997 32.1818C32.4735 30.1095 32.4112 27.5123 31.728 25.9178C31.2375 24.774 30.3022 23.8455 29.0242 23.232L29.025 23.2328ZM24.3112 27.6645C23.1697 27.729 21.9832 27.216 21.9247 26.1188C21.8812 25.305 22.5037 24.3968 24.3817 24.288C24.597 24.2753 24.8077 24.2693 25.0147 24.2693C25.6965 24.2693 26.3347 24.3353 26.9145 24.462C26.6985 27.1635 25.4295 27.603 24.3112 27.6645Z"
        fill="white"
      />
    </svg>
  );
};

export default ThreadsIcon;
