import { Axios, AxiosInstance, AxiosRequestConfig } from "axios";
import { axiosInstance } from "../index";
import { customInstance } from "../index";
import { UseQueryOptions, useQuery } from "react-query";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { ProductParams, useQueryFactory } from "./queryFactory";
import { buildMerchantEndpoints } from "../utils.api";
import { TCampaignType } from "features/Products/types";

export const addEvent = (query: any) => {
  return customInstance({
    url: "merchants/2/customers",
    method: "POST",
    data: query,
  });
};

export const getEvents = ({
  queryString,
  page,
  sorting,
  searchQuery,
}: ProductParams) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  return customInstance({
    url: buildMerchantEndpoints(
      `products?filter=typeName:"event"${queryString}&sort=${
        sorting ?? "-id"
      }${search}&page=${page}&max=${ROWS_PER_PAGE}`,
    ),
    method: "GET",
  });
};

export const useGetEvents = useQueryFactory("event");
export const useGetProducts = useQueryFactory("standard");

export const useGetProductsByType = (type: TCampaignType) =>
  useQueryFactory(type);

export const getProductById = (id: string) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}?filter=typeName:"event"`),
    method: "GET",
  });
};

export const getProductVariants = (id: string) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}/variants`),
    method: "GET",
  });
};

export const useGetEventVariants = (id: string) => {
  return useQuery("event-variants", async () => {
    const data = await getProductVariants(id);
    return data;
  });
};

export const useGetProductById = (id: string) => {
  return useQuery(
    ["get-event-by-id", id],
    async () => {
      const data = await getProductById(id);
      return data;
    },
    { cacheTime: 0, refetchOnWindowFocus: false },
  );
};
