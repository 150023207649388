import * as React from "react";
import { Box } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import {
  Check,
  PaperPlaneTilt,
  Sparkle,
  Storefront,
  Trash,
  WarningCircle,
  X,
} from "@phosphor-icons/react";
import { PopupIconTypeProps } from "./GivePopupTypes";

export const Icon = ({
  type,
}: {
  type: PopupIconTypeProps;
}): React.ReactElement => {
  const { palette } = useAppTheme();

  const getIconStyles = (type: PopupIconTypeProps) => {
    switch (type) {
      case "success":
        return {
          background: palette.gradient["ocean-blue"]?.highlight,
          iconColor: palette.primitive?.blue[100],
          IconComponent: Check,
        };
      case "success-regular":
        return {
          background: palette.primitive?.success[25],
          iconColor: palette.primitive?.success[50],
          IconComponent: Check,
        };
      case "destructive":
        return {
          background: palette.gradient["citrus-peel"]?.highlight,
          iconColor: palette.primitive?.error[50],
          IconComponent: Trash,
        };
      case "destructive-v2":
        return {
          background: palette.gradient["citrus-peel"]?.highlight,
          iconColor: palette.primitive?.error[50],
          IconComponent: Storefront,
        };
      case "decline":
        return {
          background: palette.gradient["citrus-peel"]?.highlight,
          iconColor: palette.primitive?.error[100],
          IconComponent: X,
        };
      case "warning":
        return {
          background: palette.gradient["citrus-peel"]?.highlight,
          iconColor: palette.primitive?.error[100],
          IconComponent: WarningCircle,
        };
      case "notification":
        return {
          background: palette.gradient["ocean-blue"]?.highlight,
          iconColor: palette.primitive?.blue[100],
          IconComponent: PaperPlaneTilt,
        };
      case "advanced-builder":
        return {
          background: palette.gradient["ocean-blue"]?.highlight,
          iconColor: palette.primitive?.blue[100],
          IconComponent: Sparkle,
        };
      default:
        return {
          background: palette.surface?.tertiary,
          iconColor: palette.icon?.["icon-primary"],
          IconComponent: Check,
        };
    }
  };

  const { background, iconColor, IconComponent } = getIconStyles(type);

  return (
    <IconBox background={background}>
      <IconComponent size={20} color={iconColor} />
    </IconBox>
  );
};

export const IconBox = ({
  background,
  children,
}: {
  background?: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        background,
        borderRadius: "50%",
        height: "48px",
        width: "48px",
        flex: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};
