import { QKEY_LIST_CART } from "@constants/queryKeys";
import NiceModal from "@ebay/nice-modal-react";
import useBackListener from "@hooks/common/router/useBackListener";
import { Box, Grid, Stack } from "@mui/material";
import { ArrowLeft, Check } from "@phosphor-icons/react";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import PublicURLShareModal from "@sections/PayBuilder/LaunchStep/PublicURLShare/PublicURLShareModal";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import {
  ColouredButton,
  LogoComponent,
} from "@sections/PayBuilder/View/fundraiser/components/Atom.component";
import GiveBreadcrumb from "@shared/Breadcrumbs/GiveBreadcrumb";
import { BreadcrumbItem } from "@shared/Breadcrumbs/GiveBreadcrumbs.types";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

type Address = {
  userFullName: string;
  street: string;
  fullAddress: string;
  phoneNumber?: string;
};

type NextDonation = {
  date: string;
  recurringType: string;
  amount: number;
  cardInfos: string;
};

export type PaymentSuccessInfo = {
  orderNumber: string;
  thankYouMessage: string;
  contactEmail: string;
  contactPhoneNumber: string;
  paymentType: string;
  cardNumber: string;
  deliveryAddress: Address;
  billingAddress: Address;
  nextDonation: NextDonation;
};

const PaymentSuccess = ({
  paymentSuccessInfo,
  breadcrumbItems,
  eventSuccessDetails,
}: {
  paymentSuccessInfo: PaymentSuccessInfo;
  breadcrumbItems?: BreadcrumbItem[];
  eventSuccessDetails?: any;
}) => {
  const { parsedValues } = usePayBuilderForm();
  const { accentColor } = parsedValues;
  const { isMobileView } = useCustomThemeV2();
  const { id } = useParams();

  const queryClient = useQueryClient();
  useBackListener(() => queryClient.invalidateQueries([QKEY_LIST_CART]));

  const { isFundraiser, isEvent } = useCheckFormType();

  const {
    orderNumber,
    thankYouMessage,
    contactEmail,
    contactPhoneNumber,
    paymentType,
    cardNumber,
    deliveryAddress,
    billingAddress,
    nextDonation,
  } = paymentSuccessInfo;
  const { userFullName, street, fullAddress, phoneNumber } = deliveryAddress;
  const {
    userFullName: billingUserFullName,
    street: billingStreet,
    fullAddress: billingFullAddress,
  } = billingAddress;

  const { date, recurringType, amount, cardInfos } = nextDonation;
  const hasDeliveryAddress = Boolean(
    userFullName.trim() || street || fullAddress.trim() !== ", ,",
  );

  const hasBillingAddress = Boolean(
    billingUserFullName.trim() ||
      billingStreet ||
      billingFullAddress.trim() !== ", ,",
  );

  const hasNextDonation = Boolean(date && recurringType);

  const eventSection = [
    {
      items: [
        {
          title: "Date",
          content: [{ field: eventSuccessDetails?.date }],
          hidden: false,
        },
        {
          title: "Location",
          content: [{ field: eventSuccessDetails?.location }],
          hidden: false,
        },
      ],
    },
  ];
  const sections = [
    {
      items: [
        {
          title: "Contact",
          content: [
            { field: contactEmail },
            { field: contactPhoneNumber, hidden: contactPhoneNumber === "1" },
          ],
          hidden: false,
        },
        {
          title: "Payment",
          content: [{ field: paymentType }, { field: cardNumber }],
          hidden: false,
        },
      ],
    },
    {
      items: [
        {
          title: "Delivery Address",
          content: [
            { field: userFullName },
            { field: street },
            { field: fullAddress },
            { field: phoneNumber, hidden: contactPhoneNumber === "1" },
          ],
          hidden: !hasDeliveryAddress,
        },
        {
          title: "Billing Address",
          content: [
            { field: billingUserFullName },
            { field: billingStreet },
            { field: billingFullAddress },
          ],
          hidden: !hasBillingAddress || isFundraiser,
        },
        {
          title: "Next Donation",
          content: [
            { field: date },
            { field: recurringType },
            { field: amount },
            { field: cardInfos },
          ],
          hidden: !isFundraiser || !hasNextDonation,
        },
      ],
    },
  ];

  return (
    <Stack sx={{ padding: isMobileView ? "20px" : "56px 56px 32px 0px" }}>
      {!isMobileView && parsedValues.logo && (
        <LogoComponent logo={parsedValues.logo} isPreviewMode={false} />
      )}
      <Box paddingTop="48px">
        <GiveBreadcrumb items={breadcrumbItems} />
      </Box>
      <Stack
        sx={{
          paddingBottom: "32px",
          paddingTop: "48px",
          borderBottom: `1px solid ${accentColor}`,
        }}
        spacing={2}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack
            sx={{
              backgroundColor: `${accentColor}1A`,
              width: 40,
              height: 40,
              padding: "8px",
              borderRadius: "20px",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Check size={24} color={accentColor} />
          </Stack>
          <GiveText variant="h3">
            {isFundraiser ? "Donation Sent" : "Payment Successful"}
          </GiveText>
        </Stack>
        {!isFundraiser && (
          <GiveText variant="bodyS">Order Number: {orderNumber}</GiveText>
        )}
        <GiveText variant="bodyS">{thankYouMessage}</GiveText>
        {isFundraiser && (
          <Box display="flex" justifyContent="start">
            <ColouredButton
              label="Share our Fundraiser"
              variant="filled"
              size="large"
              sx={{ flex: "none" }}
              accentColor={accentColor}
              onClick={() => {
                NiceModal.show(PublicURLShareModal, { idFromProps: id + "" });
              }}
            />
          </Box>
        )}
      </Stack>
      {isEvent && (
        <Stack
          sx={{
            paddingBottom: "20px",
            paddingTop: "20px",
            borderBottom: `1px solid ${accentColor}`,
          }}
          gap="20px"
        >
          <GiveText variant="bodyM">Event Details</GiveText>
          <Grid container gap="24px">
            <SectionList isMobileView={isMobileView} sections={eventSection} />
          </Grid>
        </Stack>
      )}

      <GiveText variant="bodyL" marginTop="32px" marginBottom="8px">
        {isFundraiser ? "Donation Details" : "Order Details"}
      </GiveText>
      <Grid container gap="24px">
        <SectionList isMobileView={isMobileView} sections={sections} />
      </Grid>
    </Stack>
  );
};

export default PaymentSuccess;

interface SectionItem {
  title: string;
  content: {
    field: any;
    hidden?: boolean;
  }[];
  hidden: boolean;
}

interface Section {
  items: SectionItem[];
}

interface SectionListProps {
  sections: Section[];
  isMobileView: boolean;
}

const SectionList = ({ sections, isMobileView }: SectionListProps) => {
  return (
    <>
      {sections.map((section, index) => (
        <Grid
          key={`section-${index}`}
          container
          direction={isMobileView ? "column" : "row"}
          spacing="12px"
        >
          {section.items
            .filter((item) => !item.hidden)
            .map((item, itemIndex) => (
              <Grid key={`item-${itemIndex}`} item md={6}>
                <GiveText variant="bodyS" fontWeight={600} marginBottom="4px">
                  {item.title}
                </GiveText>
                {item.content
                  .filter(
                    (contentItem) => contentItem.field && !contentItem?.hidden,
                  )
                  .map((element, textIndex) => (
                    <GiveText
                      key={`text-${textIndex}`}
                      variant="bodyS"
                      sx={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {element.field}
                    </GiveText>
                  ))}
              </Grid>
            ))}
        </Grid>
      ))}
    </>
  );
};
