import { Collapse, Stack } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import ChallengesCategories from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/ChallengesCategories";
import ChallengeCategoriesBottom from "./ChallengeCategoriesBottom";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";

type TChallengeCategoriesProps = {
  allChallengesCompleted: boolean;
  merchantId: number;
  merchantName: string;
  show: boolean;
};

const UnderwritingChallengeCategories = ({
  allChallengesCompleted,
  merchantId,
  merchantName,
  show,
}: TChallengeCategoriesProps) => {
  const { palette } = useAppTheme();
  const { merchant_underwriting_statuses, agreement_signing } =
    useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();
  const { data, isOnboardingView } = useMerchantSidePanelContext();
  const isRFRMerchant = data?.status?.sponsorStatusName === "ready_for_review";

  const isHidden =
    (!(merchant_underwriting_statuses || agreement_signing) &&
      isEnterprisePortal) ||
    isRFRMerchant ||
    isOnboardingView;

  return (
    <Collapse in={show} timeout={500} unmountOnExit>
      <Stack
        borderTop={`1px solid ${palette.surface?.tertiary}`}
        paddingTop={"16px"}
        gap={3}
      >
        <ChallengesCategories />
        {!isHidden && (
          <ChallengeCategoriesBottom
            isAllCompleted={allChallengesCompleted}
            merchantId={merchantId}
            merchantName={merchantName}
          />
        )}
      </Stack>
    </Collapse>
  );
};

export default UnderwritingChallengeCategories;
