import { useAppTheme } from "@theme/v2/Provider";
import { Stack } from "@mui/material";
import AcceptPaymentsOn from "@assets/iconsV2/AcceptPaymentsOn";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { ArrowCircleUpRight, CreditCard } from "@phosphor-icons/react";

type CanManageMoneyProps = {
  canProcess: boolean;
  canTransfer: boolean;
};

const CanManageMoney = ({ canProcess, canTransfer }: CanManageMoneyProps) => {
  const { palette } = useAppTheme();

  const ProcessIcon = canProcess ? AcceptPaymentsOn : CreditCard;
  return (
    <Stack direction="row" gap="12px">
      <GiveTooltip
        title={canProcess ? "Can accept payments" : "Can't accept payments"}
        color="default"
        placement="top"
      >
        <ProcessIcon
          size={18}
          color={
            canProcess ? palette.primitive?.success[50] : palette.text.tertiary
          }
        />
      </GiveTooltip>
      <GiveTooltip
        title={canTransfer ? "Can send money" : "Can't send money"}
        color="default"
        placement="top"
      >
        <ArrowCircleUpRight
          size={18}
          fill={
            canTransfer ? palette.primitive?.success[50] : palette.text.tertiary
          }
        />
      </GiveTooltip>
    </Stack>
  );
};

export default CanManageMoney;
