import placeholder from "@assets/images/Placeholder.png";
import { Text } from "@common/Text";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { Divider, LinearProgress, Stack } from "@mui/material";
import { palette } from "@palette";
import React, { useCallback, useMemo } from "react";
import { BODY_DATA } from "./types";
import { Ticket } from "./useCampaignModal";
interface Props extends BODY_DATA {
  data?: any;
}

// TODO: integrate real data
export const CampaignInventoryItems = (props: Props) => {
  return (
    <Stack spacing={3}>
      <Text color={palette.neutral[80]} fontSize="18px">
        Inventory
      </Text>
      {props.variants?.map((variant) => {
        return <InventoryItem key={variant.id} {...variant} />;
      })}
    </Stack>
  );
};

type InventoryItemProps = Ticket;

export const InventoryItem = ({
  inventory,
  name,
  numSold,
  price,
  imageURL,
}: InventoryItemProps) => {
  const percentage = useMemo(
    () => (numSold / inventory) * 100,
    [numSold, inventory],
  );
  const formatPrice = useCallback(
    (cents: number): string => {
      if (cents === 0) {
        return "0";
      }

      const dollars = cents / 100;
      return dollars.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    [price],
  );
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <img
            style={{
              width: "64px",
              height: "64px",
              borderRadius: 3,
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = placeholder;
            }}
            src={imageURL ? addSizeToImage(imageURL, "small") : placeholder}
          />
          <Stack justifyContent="space-around">
            <Text
              color={palette.neutral[90]}
              fontSize="18px"
              fontWeight="book"
              lineHeight="21.6px"
            >
              {name}
            </Text>
            <LinearProgress
              variant="determinate"
              value={percentage}
              sx={{
                height: 4,
                width: 210,
                borderRadius: 4,
                backgroundColor: "#ECECE9",
                "& .MuiLinearProgress-bar": {
                  background:
                    "linear-gradient(223.56deg, #72ECF0 0%, #528DDF 100%)",
                },
              }}
            />
            <Stack direction="row" width="210px" justifyContent="space-between">
              <SecondaryText>Sold {numSold}</SecondaryText>
              <SecondaryText>Available {inventory}</SecondaryText>
            </Stack>
          </Stack>
        </Stack>
        <Stack alignItems="flex-end" mt={0.5} spacing={0.5}>
          <Text
            color={palette.neutral[90]}
            fontSize="18px"
            lineHeight="21.6px"
            fontWeight="book"
          >
            {formatPrice(price)} USD
          </Text>
          <SecondaryText>per ticket</SecondaryText>
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

const SecondaryText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text color={palette.neutral[80]} lineHeight="16.8px" fontWeight="book">
      {children}
    </Text>
  );
};
