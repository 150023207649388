import React from "react";
import { Icon, IconProps } from "@phosphor-icons/react";

const ControlMode = ({
  color = "#292928",
  width = 20,
  height = 20,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.875C2.55025 2.875 1.375 4.05025 1.375 5.5V13.5C1.375 14.9497 2.55025 16.125 4 16.125H5C5.34518 16.125 5.625 15.8452 5.625 15.5C5.625 15.1548 5.34518 14.875 5 14.875H4C3.24061 14.875 2.625 14.2594 2.625 13.5V5.5C2.625 4.74061 3.24061 4.125 4 4.125H16C16.7594 4.125 17.375 4.74061 17.375 5.5V13.5C17.375 14.2594 16.7594 14.875 16 14.875H14.75C14.4048 14.875 14.125 15.1548 14.125 15.5C14.125 15.8452 14.4048 16.125 14.75 16.125H16C17.4497 16.125 18.625 14.9497 18.625 13.5V5.5C18.625 4.05025 17.4497 2.875 16 2.875H4ZM11.25 14.9999C11.25 15.6903 10.6904 16.2499 10 16.2499C9.30967 16.2499 8.75003 15.6903 8.75003 14.9999C8.75003 14.3096 9.30967 13.7499 10 13.7499C10.6904 13.7499 11.25 14.3096 11.25 14.9999ZM12.5 14.9999C12.5 16.3807 11.3807 17.4999 10 17.4999C8.61932 17.4999 7.50003 16.3807 7.50003 14.9999C7.50003 13.6192 8.61932 12.4999 10 12.4999C11.3807 12.4999 12.5 13.6192 12.5 14.9999Z"
        fill={color}
      />
    </svg>
  );
};

export default ControlMode;
