import { QueryKey, useQueryClient } from "react-query";

export const useQueryObserver = <TData = unknown,>(queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  const queryData = queryClient.getQueryData<TData>(queryKey);

  return {
    queryData,
    refetch: () => queryClient.invalidateQueries(queryKey),
    remove: () => queryClient.removeQueries({queryKey: queryKey})
  };
};
