import { styled, useAppTheme } from "@theme/v2/Provider";
import { Icon } from "@phosphor-icons/react";
import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { GiveTooltipProps } from "@shared/Tooltip/GiveTooltip.types";

interface IGiveLargeIconButton {
  variant?: "primary" | "secondary";
  Icon: Icon | JSX.ElementType;
  label: string;
  disabled?: boolean;
  tooltipProps?: Partial<GiveTooltipProps>;
  onClick?: () => void;
}

const GiveLargeIconButton = ({
  variant = "primary",
  Icon,
  label,
  disabled,
  tooltipProps,
  onClick,
}: IGiveLargeIconButton) => {
  const { palette } = useAppTheme()

  const iconColor = {
    primary: palette?.icon?.["remain-light"],
    secondary: palette?.primitive?.blue?.[100]
  }

  return (
    <Stack spacing={1.5} alignItems="center">
      <GiveTooltip
        disableHoverListener={tooltipProps?.disableHoverListener || true}
        title={tooltipProps?.title || ""}
        color={tooltipProps?.color || "default"}
        {...tooltipProps}
      >
        <StyledButton
          CustomIcon={<Icon size="36px" weight="light" fill={iconColor[variant]} />}
          size="large"
          onClick={onClick}
          disabled={disabled}
          isPrimary={variant === "primary"}
        />
      </GiveTooltip>
      <GiveText color="secondary" variant="bodyS">
        {label}
      </GiveText>
    </Stack>
  );
};

export default GiveLargeIconButton;

const StyledButton = styled(GiveIconButton, {
  shouldForwardProp: (prop) => prop !== "isPrimary",
})<{
  isPrimary: boolean;
}>(({ theme, isPrimary }) => ({
  height: 56,
  width: 56,
  borderRadius: "50%",

  "& > svg": {
    color: isPrimary
      ? theme.palette.icon?.["remain-light"]
      : theme.palette.primitive?.blue[100],
  },

  "&:hover": {
    "& > svg": {
      height: "32px",
      width: "32px",
    },
    "&::after": {
      backgroundImage: isPrimary
        ? theme.palette.gradient["ocean-blue"]?.default
        : theme.palette.gradient["ocean-blue"]?.highlight,

      animation: "rotate-gradient 2s linear infinite",
    },
  },

  position: "relative",
  overflow: "hidden",

  "&::after": {
    content: "''",
    display: "block",
    zIndex: -1,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: "50%",
    backgroundImage: isPrimary
      ? theme.palette.gradient["ocean-blue"]?.default
      : theme.palette.gradient["ocean-blue"]?.highlight,
  },

  "@keyframes rotate-gradient": {
    "100%": {
      transform: "rotate(-360deg)",
    },
  },
  zIndex: 9,
}));
