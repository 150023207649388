import { Stack } from "@mui/material";

import { MouseEvent } from "react";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import { TOwnerFile } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/types";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { DownloadSimple, Eye, Trash } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";

type Props = {
  type: TOwnerFile;
  downloadHandler: (
    documentData?: TMerchantDocument | undefined,
  ) => Promise<void>;
  deleteDocument?: () => void;
  previewDocument: () => void;
  disabled?: {
    download?: boolean;
    delete?: boolean;
    preview?: boolean;
  };
  hidden?: {
    download?: boolean;
    delete?: boolean;
    preview?: boolean;
  };
};

const IDUploaderActions = ({
  downloadHandler,
  deleteDocument,
  previewDocument,
  disabled,
  hidden,
  type,
}: Props) => {
  const { modify_merchant } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();

  const isHidden = isEnterprisePortal && !modify_merchant;

  const theme = useAppTheme();
  const actionToDo = (
    e: MouseEvent<HTMLDivElement>,
    action: () => void,
    disabled = false,
  ) => {
    e.stopPropagation();
    if (disabled) return;
    action();
  };
  return (
    <>
      <Stack
        className="pah-uploader-actions"
        direction="row"
        gap={0.5}
        alignItems="center"
      >
        {hidden?.preview || (
          <GiveIconButton
            data-testid="view-id-file-btn"
            variant="ghost"
            Icon={Eye}
            disabled={disabled?.preview}
            onClick={(e: any) =>
              actionToDo(e, previewDocument, disabled?.preview)
            }
            sx={{ backgroundColor: theme.palette.neutral.white }}
            hidden={hidden?.preview}
          />
        )}

        {!isHidden && (
          <>
            {hidden?.download || (
              <GiveIconButton
                data-testid="download-id-file-btn"
                variant="ghost"
                Icon={DownloadSimple}
                disabled={disabled?.download}
                onClick={(e: any) => {
                  actionToDo(e, downloadHandler, disabled?.download);
                }}
                sx={{ backgroundColor: theme.palette.neutral.white }}
                hidden={hidden?.download}
              />
            )}
            {hidden?.delete ||
              (type != "businessOwnerID" && deleteDocument && (
                <WithTooltipWrapper
                  hasTooltip={Boolean(disabled?.delete)}
                  tooltipProps={{
                    show: Boolean(disabled?.delete),
                    message: DELETE_DENY_MESSAGE,
                  }}
                >
                  <GiveIconButton
                    variant="ghost"
                    Icon={Trash}
                    disabled={disabled?.delete}
                    onClick={(e: any) => {
                      actionToDo(e, deleteDocument, disabled?.delete);
                    }}
                    sx={{ backgroundColor: theme.palette.neutral.white }}
                    hidden={true}
                  />
                </WithTooltipWrapper>
              ))}
          </>
        )}
      </Stack>
    </>
  );
};

export default IDUploaderActions;
