import React from "react";

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  TooltipProps,
  styled,
} from "@mui/material";
import { Tooltip } from "@common/Tooltip";

interface StyleIconButtonProps extends MuiButtonProps {
  toolTipText?: string;
  disabled?: boolean;
  children: React.ReactElement;
  tooltipProps?: TooltipProps;
}
/**
 * FOR ICON BUTTON ALSO USING BUTTON OF MUI
 */

export interface IStyleIconButtonProps extends MuiButtonProps {
  toolTipText?: string;
  disabled?: boolean;
  children: React.ReactElement;
  tooltipProps?: TooltipProps;
}

const IconWrapper = styled("span")(() => ({
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
}));

export const IconButton: React.FC<MuiButtonProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <MuiButton className={`CustomIconButton ${className}`} {...props}>
      <IconWrapper>{children}</IconWrapper>
    </MuiButton>
  );
};

export const StyledIconButton: React.FC<IStyleIconButtonProps> = ({
  toolTipText,
  tooltipProps,
  ...rest
}) => {
  const renderContent = <BaseIconButton {...rest} />;

  if (toolTipText) {
    return (
      <Tooltip title={toolTipText} {...tooltipProps}>
        {renderContent}
      </Tooltip>
    );
  }

  return renderContent;
};

const BaseIconButton = styled(IconButton)(({ theme }) => ({
  border: "none",
  boxShadow: "none",
  backgroundColor: "transparent",
  background: "none",
  "&:hover": {
    backgroundColor: "transparent",
    background: "none",
  },
  "&:disabled": {
    boxShadow: "none",
  },
  "&:hover path": {
    fill: theme?.palette?.neutral?.[100],
    transition: "fill 250ms ease",
  },
}));
