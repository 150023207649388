import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useMerchantDataPreview } from "@components/Merchants/MerchantPreview/hooks/useMerchantDataPreview";
import { useMasquerade, useWatchlist } from "@hooks/enterprise-api/merchants";
import { IButtonComponent } from "@shared/SidePanel/SidePanelAtoms";
import { checkPortals } from "@utils/routing";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useState } from "react";
import ChangelogButton from "../components/ChangelogButton";
import ConversationsButton from "../components/ConversationsButton";
import { useNotifications } from "../WithRepository/Challenges/hooks/useNotifications";
import {  MIN_ALLOWED_SCREEN_SIZE_FOR_DOUBLE_PANELS } from "@shared/SidePanel/GiveSidePanel";
import { useMediaQuery } from "@mui/material";
import { useListTopics } from "features/Minibuilders/Conversations/hooks/useListTopics";
import { calculateTotalUnreadSum } from "features/Minibuilders/Conversations/utils/functions";

type TMerchantDoublePanelNew = "merchantFile" | "agreement" | "challenges" | "";

type Props = {
  id: number;
  setSelectedRow?: (newIdx: string | number) => void;
};

export default function useMerchantSidePanel({ id, setSelectedRow }: Props) {
  const { open, onClose } = useNiceModal();
  const { isAcquirerPortal } = checkPortals();
  const { data, merchant, isLoading, isRefetching } = useMerchantDataPreview({
    id,
  });
  const { masqueradeHandler } = useMasquerade();
  const isSinglePanelSize = useMediaQuery(`(max-width:${MIN_ALLOWED_SCREEN_SIZE_FOR_DOUBLE_PANELS}px)`);
  const [contentHistory, setContentHistory] = useState<string>("");
  // This firstPanelContent state is mainly used for showing content details in the first panel
  const [firstPanelContent, setFirstPanelContent] = useState("");
  const [secondPanelContent, setSecondPanelContent] =
    useState<TMerchantDoublePanelNew>("");
  const { addMerchantToWatchlist, removeMerchantFromWatchlist } =
    useWatchlist();
  const { isOpen, handleOpenConversationsModal } = useConversationsModal();
  const { closeNotifyMerchantModal } = useNotifications();
  const isCollapsed = contentHistory !== "" && firstPanelContent === ""
  const isDoublePanel = !!secondPanelContent && !isSinglePanelSize && !isCollapsed;

  const {
    data:topicData,
  } = useListTopics({ merchantId: merchant?.accID });

  const { totalUnread } = calculateTotalUnreadSum(
    topicData?.data,
  );


  const Buttons: IButtonComponent = {
    conversations: (
      <ConversationsButton
        isLoading={isLoading}
        totalUnread={totalUnread}
        isOpen={isOpen}
        handleOpenConversationsModal={handleOpenConversationsModal}
        isDoublePanel={isDoublePanel}
      />
    ),
    changelog: <ChangelogButton isDoublePanel={isDoublePanel} />,
  };

  const handleOpenMerchantFile = () => {
    setSecondPanelContent("merchantFile");
  };

  const handleOpenAgreementSnapshot = () => {
    setSecondPanelContent("agreement");
  };

  const handleLoadNextMerchant = () => {
    if (setSelectedRow) setSelectedRow("next");
  };

  const handleLoadPrevMerchant = () => {
    if (setSelectedRow) setSelectedRow("prev");
  };

  const renderButton = (e: keyof IButtonComponent) => Buttons[e];

  const handleControlMode = () => {
    masqueradeHandler(
      isAcquirerPortal ? "acquirer" : "provider",
      {
        ...merchant,
        nextRoute: "/merchant",
      },
      onClose,
    );
  };

  const handleAddToWatchList = () => {
    if (data.header.watchlist) {
      removeMerchantFromWatchlist(id, data.header.name);
    } else {
      addMerchantToWatchlist(id, data.header.name);
    }
  };

  const handleCloseAll = () => {
    onClose();
    setContentHistory("");
    setFirstPanelContent("");
    setSecondPanelContent("");
    closeNotifyMerchantModal();
  };

  const handleClose = (
    event?: any,
    reason?: "backdropClick" | "escapeKeyDown",
  ) => {
    if (secondPanelContent && !firstPanelContent && !reason) {
      setContentHistory("default");
      return;
    }
    handleCloseAll();
  };

  return {
    data,
    merchant,
    isLoading,
    isRefetching,
    open,
    onClose: handleClose,
    handleCloseAll,
    renderButton,
    contentHistory,
    setContentHistory,
    firstPanelContent,
    setFirstPanelContent,
    secondPanelContent,
    setSecondPanelContent,
    isSinglePanelSize,
    handlers: {
      handleOpenAgreementSnapshot,
      handleOpenMerchantFile,
      handleNext: handleLoadNextMerchant,
      handlePrevious: handleLoadPrevMerchant,
      handleControlMode,
      handleAddToWatchList,
    },
  };
}
