import { Text, TruncateText } from "@common/Text";
import { Stack } from "@mui/material";
import { Box, Grid } from "@mui/material";
import React, { useMemo, useState } from "react";
import { MoreActionButton } from "./MoreActionButton";
import { AmountText } from "../ExpandedRow_V2/atoms";
import { palette } from "@palette";
import { InformationsComponent } from "./InformationsComponent";
import { makeStyles } from "@mui/styles";
import { ITextProps } from "@common/Text/Text";
import { ActionsMenu } from "./ActionsMenu";
import {
  MERCHANT_PORTAL_MODIFY_RECURRING_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
} from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { shouldBeHidden } from "@constants/constants";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const Value = (props: ITextProps) => {
  return (
    <Text paddingLeft={6} color={palette.neutral[90]} {...props}>
      {props.children}
    </Text>
  );
};

const columnData = (data: any, isPayBuilderEnabled: boolean) => ({
  paymentFormName: {
    title: "Payment form",
    value: <Value>{data.product?.name}</Value>,
  },
  type: {
    title: "Type",
    value: (
      <Value textTransform="capitalize">
        {data.product?.type === "standard" && isPayBuilderEnabled
          ? "Product"
          : data.product?.type}
      </Value>
    ),
  },
  recurrence: {
    title: "Recurrence",
    value: <Value textTransform="capitalize">{data.recurringInterval}</Value>,
  },
  itemName: {
    title: "Item",
    value: <Value textTransform="capitalize">{data.product?.type}</Value>,
  },
  price: {
    title: "Price (USD)",
    value: (
      <AmountText
        injectedStyle={{
          textAlign: "left",
          paddingLeft: "48px",
          color: palette.neutral[90],
          width: "100%",
        }}
        key="price"
        value={data.unitPrice / 100}
      />
    ),
  },
});

export const Purchases = ({
  items,
  sourceAccount,
  transactionStatus,
  hideRefund,
}: {
  items: any[];
  sourceAccount: any;
  transactionStatus: string;
  hideRefund?: boolean;
}) => {
  //if the FF isPayBuilderEnabled on the UI we have to show Product insted of Standard
  //TODO cleanup
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idx, setIdx] = useState(-1);

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
    setIdx(-1);
  };

  const hasModifyRecurringPermission = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.USER,
      RESOURCE_BASE.RECURRING_ITEM,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const actions = useMemo(() => {
    const item = items[idx];
    if (idx > -1) {
      return [
        {
          title: "Refund",
          hidden:
            shouldBeHidden.refund ||
            Boolean(item.reversalTransactionType) ||
            hideRefund ||
            item.disallowReversalRequests,
          disabled: Boolean(item.reversalTransactionType),
          onClick: () => {
            NiceModal.show(MERCHANT_PORTAL_REFUND_MODAL, {
              sourceAccount,
              ids: {
                transactionItemIDs: [item.id],
              },
            });
          },
        },
        {
          title: "Modify Recurring",
          disabled:
            !item.orderRecurringItemID ||
            transactionStatus === "Voided" ||
            !hasModifyRecurringPermission,
          hidden: false,
          onClick: () => {
            NiceModal.show(MERCHANT_PORTAL_MODIFY_RECURRING_MODAL, {
              orderRecurringItemID: item.orderRecurringItemID,
              sourceAccount,
              merchantId: item?.product?.accID,
            });
          },
          hasTooltip: !hasModifyRecurringPermission,
          tooltipProps: {
            show: !hasModifyRecurringPermission,
            message: ACTION_DENY_MESSAGE,
          },
        },
      ];
    }

    return [];
  }, [idx]);

  return (
    <div data-testid="purchases-list">
      {items.map((item, i: number) => {
        if (!item.product) return null;
        const {
          id,
          product: { name, type },
          price,
          quantity,
        } = item;
        const usedType =
          type === "standard" && isPayBuilderEnabled ? "Product" : type;
        return (
          <Item
            columnData={columnData(item, isPayBuilderEnabled)}
            key={id}
            name={name}
            price={price / 100}
            type={
              capitalizeFirstLetter(usedType) +
              ` ${quantity ? `x${quantity}` : ""}`
            }
            onOpenMenu={(event) => {
              event.stopPropagation();
              onOpenMenu(event);
              setIdx(i);
            }}
          />
        );
      })}
      <ActionsMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={actions}
      />
    </div>
  );
};

function Item({
  columnData,
  name,
  type,
  price,
  onOpenMenu,
}: {
  columnData: any;
  name: string;
  price: number;
  type: string;
  onOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Box
        paddingY={1}
        className={classes.itemWrapper}
        bgcolor={open ? "#F4F4F4" : ""}
        onClick={() => setOpen((p) => !p)}
        data-testid="purchases-item"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Stack>
            <Text color={palette.neutral[80]} data-testid="purchase-item-type">
              {type}
            </Text>
            <TruncateText
              lineClamp={2}
              color={palette.neutral[70]}
              textTransform="capitalize"
              data-testid="purchase-item-name"
              sx={{
                wordBreak: "break-all",
              }}
            >
              {name}
            </TruncateText>
          </Stack>
          <Grid display="flex" alignItems="center" gap={1}>
            <AmountText
              injectedStyle={{
                width: "fit-content",
              }}
              key="fees"
              value={price}
              showCurrency
            />
            <MoreActionButton
              onClick={onOpenMenu}
              testId="transaction-item-more-action-button"
            />
          </Grid>
        </Grid>
      </Box>
      {open && (
        <Grid
          className={classes.expandedSectionWrapper}
          container
          data-testid="purchases-item-info"
        >
          <InformationsComponent columnData={columnData} />
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles({
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "flex-start",
    cursor: "pointer",
    padding: "4px 8px",
    borderRadius: "5px",
    "&:hover": {
      background: "#F4F4F4",
    },
  },
  expandedSectionWrapper: {
    paddingTop: "8px",
    paddingBottom: "8px",
    gap: "8px",
    paddingLeft: "8px",
    display: "flex",
    flexDirection: "column",
  },
  expandBtn: {
    padding: "6px",
    borderRadius: "4px",
    "&:hover": {
      background: palette.neutral[20],
    },
  },
});
