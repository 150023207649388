import {
  DesktopDeviceIcon,
  MobileDeviceIcon,
  TabletDeviceIcon,
} from "@assets/icons/RebrandedIcons";
import { Box, Stack } from "@mui/material";
import { QFORM_QUERY_KEY } from "@pages/AcquirerPortal/Enterprises/Modal/constants";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled, useAppTheme } from "@theme/v2/Provider";
import React, { useState } from "react";
import { useQueryObserver } from "../LaunchStep/hooks/useQueryObserver";
import PublicUrlShare from "../LaunchStep/PublicURLShare/PublicURLShare";
import { TQueryDataLaunchStepPaymentFormKey } from "../LaunchStep/types";
import { useCart } from "../provider/CartContext";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import RightSideHeaderPart from "../RightSideHeaderPart";
import { scrollbarStyles } from "../utils";
import { CartCheckoutFooter } from "./products/CartCheckoutFooter";
import { TFormSourceEnum } from "./products/types";

type ScreenState = {
  isMobileView: boolean;
  isTabletView: boolean;
  isDesktopView: boolean;
  isMobileDevice: boolean;
  isSmallDesktop: boolean;
};

type PreviewWrapperProps = {
  children: React.ReactNode;
  previewText: string;
  background: string;
  source?: TFormSourceEnum;
};

const PreviewWrapper: React.FC<PreviewWrapperProps> = ({
  children,
  previewText,
  background,
  source = TFormSourceEnum.PREVIEW,
}) => {
  const { isMobileView } = useCustomThemeV2();
  const { isCartEmpty } = useCart();
  const { queryData } =
    useQueryObserver<TQueryDataLaunchStepPaymentFormKey>(QFORM_QUERY_KEY);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { palette } = useAppTheme();
  const {
    screenStates,
    manageScreenActions: { setDeskTopView, setTabletView, setMobileView },
    isLastStep,
    displayStatus,
    activeItem,
  } = usePayBuilderContext();

  const viewActions = [
    {
      Icon: DesktopDeviceIcon,
      onClick: setDeskTopView,
      isActive: screenStates.isDesktopView,
      name: "Desktop",
    },
    {
      Icon: TabletDeviceIcon,
      onClick: setTabletView,
      isActive: screenStates.isTabletView,
      name: "Tablet",
    },
    {
      Icon: MobileDeviceIcon,
      onClick: setMobileView,
      isActive: screenStates.isMobileDevice,
      name: "Mobile",
    },
  ];

  const renderViewActions = () => {
    return viewActions.map(({ Icon, onClick, isActive, name }, idx) => (
      <IconButton
        data-testid={`screen-resize-${name}`}
        onClick={onClick}
        key={idx}
      >
        <Icon
          color={
            isActive
              ? palette.primitive?.blue[100]
              : palette.icon?.["icon-secondary"]
          }
        />
      </IconButton>
    ));
  };

  return (
    <>
      <Stack height="auto" width="100%" p={isMobileView ? "4px" : "32px"}>
        {!isMobileView && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb="20px"
            sx={scrollbarStyles}
          >
            <Box>
              <GiveText>Preview</GiveText>
              <GiveText
                mt="4px"
                fontWeight={400}
                fontSize="14px"
                color="secondary"
              >
                {previewText}
              </GiveText>
            </Box>

            <RightSideHeaderPart
              renderViewActions={renderViewActions}
              source={source}
              shouldRenderShareButton={
                typeof queryData?.product?.id === "number"
              }
            />
          </Stack>
        )}
        {isMobileView ? (
          <Box
            sx={{
              background: background,
            }}
          >
            {children}
            {!isCartEmpty && activeItem.id !== "checkout_configuration" && (
              <Footer>
                <CartCheckoutFooter />
              </Footer>
            )}
          </Box>
        ) : (
          <ChildContainer
            screenStates={isMobileView ? undefined : screenStates}
            isCartEmpty={isCartEmpty}
            data-testid="preview-wrapper-container"
            sx={{
              background: background,
            }}
          >
            {children}
            {!isCartEmpty && activeItem.id !== "checkout_configuration" && (
              <Footer>
                <CartCheckoutFooter />
              </Footer>
            )}
          </ChildContainer>
        )}
      </Stack>
      {typeof queryData?.product?.id === "number" && (
        <PublicUrlShare
          variant="strict"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
    </>
  );
};

export default PreviewWrapper;

const Footer = styled(Box)(({ theme }) => {
  return {
    position: "sticky",
    width: "100%",
    height: "66px",
    background: "rgba(250, 248, 245, 0.7)",
    backdropFilter: "blur(7.5px)",
    bottom: 0,
    borderTop: `1px solid ${theme.palette.border?.primary}`,
    borderRadius: `0 0 ${theme.customs?.radius.medium}px`,
    display: "flex",
    padding: "10px",
  };
});

const ChildContainer = styled(Box)<{
  screenStates?: ScreenState;
  isCartEmpty: boolean;
}>(({ theme, screenStates, isCartEmpty }) => {
  const { isMobileView, isTabletView } = screenStates || {};

  if (isCartEmpty) {
    return {
      marginTop: "20px",
      boxShadow: `0px 4px 8px 0px ${theme.customs?.shadows.small}`,
      borderRadius: theme.customs?.radius.medium,
      width: isMobileView ? "390px" : isTabletView ? "768px" : "100%",
      margin: "0 auto",
      height: "auto",
      minHeight: "85dvh",
      transition: "width 0.3s ease-in-out",
      paddingTop: isMobileView ? "20px" : undefined,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }
  return {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    height: "86vh",
    overflowY: "auto",
    transition: "width 0.3s ease-in-out",
    borderRadius: theme.customs?.radius.medium,
    width: isMobileView ? "390px" : isTabletView ? "768px" : "100%",
    position: "relative",
    margin: "0 auto",
    // Scrollbar hiding styles
    "&::-webkit-scrollbar": {
      width: "0px",
      display: "none",
    },
    "&": {
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
    },
  };
});

const IconButton = styled("button")(({ theme }) => {
  return {
    all: "unset",
    cursor: "pointer",
    margin: "0 3px",
  };
});
