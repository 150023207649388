import { Stack } from "@mui/system";
import GiveText from "@shared/Text/GiveText";
import GiveUploadArea from "@shared/FileUpload/GiveUploadArea";
import { AcceptAllowedGeneralDocumentsTypes } from "@hooks/upload-api/uploadHooks";
import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ReportFormFields } from "./AddNewReportView";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveUploadStack from "@shared/FileUpload/GiveUploadStack";
import moment from "moment/moment";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { GiveUploadItemProps } from "@shared/FileUpload/types";

const ReportEvidenceUpload = () => {
  const { setValue, getValues } = useFormContext<ReportFormFields>();
  const { isMobileView } = useCustomThemeV2();
  const evidenceList: ReportFormFields["files"] = useWatch({
    name: "files",
  });
  const {
    globalName: { firstName, lastName },
  } = useAppSelector(selectUser);

  const handleSaveFile = (file: File) => {
    const id = Math.random().toString(36).substring(2, 9);
    const lastDotIndex = file.name.lastIndexOf(".") + 1;
    const fileType = file.name.substring(lastDotIndex);
    setValue("files", [
      ...getValues("files"),
      {
        file,
        id,
        updatedAt: moment.now(),
        fileName: file.name,
        fileURL: URL.createObjectURL(file),
        fileType: fileType,
      },
    ]);
  };

  const handleDeleteItem = (fileIndex: number) => {
    setValue(
      "files",
      getValues("files").filter((_, index) => index !== fileIndex),
    );
  };

  const itemData: GiveUploadItemProps[] = useMemo(() => {
    return evidenceList.map((item, index) => ({
      merchantId: 0,
      state: "uploaded",
      value: "MATCH Evidence",
      setValue: () => null,
      byMessage: `by ${firstName} ${lastName}`,
      dateMessage: moment(item.updatedAt).format("MMM DD YYYY, HH:mm"),
      fileData: {
        fileName: item.fileName,
        id: index,
        fileType: item.file.type,
        fileURL: item.fileURL,
        tag: "Match Evidence",
      },
      onDelete: () => handleDeleteItem(index),
      tagType: "form-field",
    }));
  }, [evidenceList]);

  return (
    <Stack gap={1} data-testid="match-upload-evidence">
      <GiveText variant="bodyS">Evidence</GiveText>
      <GiveUploadArea
        isMobile={isMobileView}
        disabled={false}
        uploadFunction={handleSaveFile}
        message="Up to 5MB each (.pdf, .png, .jpg, .jpeg, .webp)"
        accept={AcceptAllowedGeneralDocumentsTypes}
        maxFiles={5}
        multiple
      />
      {evidenceList.length > 0 && (
        <GiveUploadStack
          items={itemData}
          customStyles={{
            marginTop: "12px",
          }}
        />
      )}
    </Stack>
  );
};

export default ReportEvidenceUpload;
