import { useQuery } from "react-query";
import { customInstance } from "../../../services/api";

export const useGetBankFiles = ({
  enabled,
  bankAccountId,
  merchantId,
}: {
  enabled?: boolean;
  bankAccountId?: string | number;
  merchantId?: string | number | null;
}) => {
  return useQuery(
    ["get-bank-files", merchantId, bankAccountId],
    async () => {
      return await customInstance({
        url: `/accounts/${merchantId}/files?filter=%3BisUploaded%3Atrue%3BattTypeName%3A%22bank_account%22%3BbankAccountID%3A${bankAccountId}&sort=-updatedAt`,
      });
    },
    {
      enabled: enabled,
    },
  );
};
