import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import { Box, Stack, Grid } from "@mui/material";
import { ReactNode } from "react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveButton from "@shared/Button/GiveButton";
import { ISectionItem } from "../hooks/useGetSectionItems";
import { GiveSectionItem } from "../GiveSectionItem";
import { ArrowRight, PencilSimple, Warning } from "@phosphor-icons/react";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";
import { isFunction } from "lodash";
import GiveUploadStack from "@shared/FileUpload/GiveUploadStack";
import GiveText from "@shared/Text/GiveText";
import { GiveUploadItemProps } from "@shared/FileUpload/types";
import { PLAID_ACCOUNT_INFO } from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";

type DataItem = { title: string; value: string; hidden?: boolean };

type TBankAccountBody = {
  account: IMerchantBankAccount;
  onEdit?: (account: IMerchantBankAccount) => void;
  disabled?: boolean;
  documents: GiveUploadItemProps[];
};
const BankAccountBody = ({
  account,
  onEdit,
  disabled,
  documents,
}: TBankAccountBody) => {
  const items: DataItem[] = [
    {
      title: "Created",
      value: account?.created,
    },
    {
      title: "Account Type",
      value: account?.type,
    },
    {
      title: "Business Name on Account",
      value: account?.name,
    },
    {
      title: "Account Number",
      value: account?.accountNumber,
    },
    {
      title: "Routing Number",
      value: account?.routingNumber,
    },
    {
      title: "Bank Name",
      value: account?.bankName,
    },
  ];
  const {authPlaidInfo} = account || {}
  const handleViewPlaidInfo = () => {
    if (!authPlaidInfo) return;
    NiceModal.show(PLAID_ACCOUNT_INFO, { data: authPlaidInfo });
  };

  return (
    <Stack spacing={0.5} width={"100%"}>
      {documents?.length < 1 && <AlertItem />}
      {items
        .filter((entry) => !entry.hidden)
        .map((item, index) => (
          <DetailsItem key={index} item={item} highlight={index % 2 !== 0} />
        ))}
      <Stack paddingX="16px" gap="16px" marginBottom="8px !important">
        {documents?.length > 0 && <GiveUploadStack items={documents} />}
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="10px"
          paddingX="16px"
        >
          <CustomButton
            lable="Edit"
            onClick={isFunction(onEdit) ? () => onEdit(account) : undefined}
            endIcon={<PencilSimple size={14} />}
            disabled={disabled}
          />
          {account?.plaid && authPlaidInfo && (
            <CustomButton
              lable="Plaid Information"
              onClick={handleViewPlaidInfo}
              endIcon={<ArrowRight size={18} />}
              disabled={disabled}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const CustomButton = ({
  lable,
  onClick,
  endIcon,
  disabled = false,
}: {
  lable: string;
  onClick?: () => void;
  endIcon: ReactNode;
  disabled?: boolean;
}) => (
  <GiveTooltip
    width="compact"
    alignment="left"
    title={EDIT_DENY_MESSAGE}
    color="default"
    disableHoverListener={!disabled}
  >
    <GiveButton
      sx={{ width: "100%", border: "none" }}
      disabled={disabled}
      onClick={onClick}
      label={lable}
      endIcon={endIcon}
      variant="filled"
      color="light"
      size="small"
    />
  </GiveTooltip>
);
const DetailsItem = ({
  item,
  highlight,
}: {
  item: ISectionItem;
  highlight: boolean;
}) => {
  return (
    <Box padding="16px 16px 0px 32px">
      <CustomGridContainer container spacing={2} highlight={highlight}>
        <GiveSectionItem item={item} />
      </CustomGridContainer>
    </Box>
  );
};

const AlertItem = () => {
  const { palette } = useAppTheme();
  return (
    <AlertContainer>
      <Warning color={palette.primitive?.warning[100]} size={24} />
      <GiveText color="warning">
        A bank statement needs to be uploaded.
      </GiveText>
    </AlertContainer>
  );
};

const AlertContainer = styled(Box)(({ theme }) => ({
  boxSizing: "content-box",
  padding: "12px",
  margin: "0 16px 10px 16px !important",
  gap: "12px",
  maxHeight: "36px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: theme.palette.primitive?.warning[10],
}));

const CustomGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "highlight",
})<{ highlight: boolean }>(({ highlight, theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: highlight ? "inherit" : theme.palette.surface?.secondary,
}));

export default BankAccountBody;
