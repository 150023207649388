import EmailIcon from "@assets/icons/Share/EmailIcon";
import EmbedIcon from "@assets/icons/Share/EmbedIcon";
import FacebookIcon from "@assets/icons/Share/FacebookIcon";
import LinkedinIcon from "@assets/icons/Share/LinkedinIcon";
import ThreadsIcon from "@assets/icons/Share/ThreadsIcon";
import TwitterXIcon from "@assets/icons/Share/TwitterXIcon";
import WhatsappIcon from "@assets/icons/Share/WhatsappIcon";
import useCheckEnvironment from "@hooks/common/useCheckEnvironment";
import { Box } from "@mui/material";
import { QFORM_QUERY_KEY } from "@pages/AcquirerPortal/Enterprises/Modal/constants";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useQueryObserver } from "../hooks/useQueryObserver";
import { TQueryDataLaunchStepPaymentFormKey } from "../types";
import { EmbedShareButton, ThreadsShareButton } from "./AdditionalButtons";
import LargeVariant from "./LargeVariant";
import type { PublicUrlShareProps, TButtonsMap } from "./PublicShare.types";
import StrictVariant from "./StrictVariant";
import StylessVariant from "./StylessVariant";

enum VariantsEnum {
  LARGE = "large",
  STRICT = "strict",
  BODY_LESS = "styless", // without any background or whatsover
}

const PublicUrlShare = <T extends "large" | "strict" | "styless">({
  variant,
  anchorEl,
  setAnchorEl,
  handleClose,
  idFromProps,
  query,
}: PublicUrlShareProps<T> & { idFromProps?: string; query?: string }) => {
  const { queryData } =
    useQueryObserver<TQueryDataLaunchStepPaymentFormKey>(QFORM_QUERY_KEY);
  const { id: idInUrl } = useParams<{ id: string }>();

  const id = useMemo(() => {
    switch (true) {
      case typeof queryData?.product.id === "number":
        return `${queryData.product.id}`;
      case !!idInUrl:
        return idInUrl;
      case typeof idFromProps === "string":
        return idFromProps;
      default:
        return "";
    }
  }, [queryData?.product.id, idFromProps, idInUrl]);

  const { platformBaseUrl } = useCheckEnvironment();

  const url = `${platformBaseUrl}/${id}${
    query ? `?sharedProductId=${query}` : ""
  }`;

  const ButtonsMap = useMemo(
    () => [
      {
        Component: () => (
          <EmbedShareButton
            url={url}
            productId={id + "" || ""}
            handleClose={handleClose}
          >
            <EmbedIcon />
          </EmbedShareButton>
        ),
        Icon: () => <></>,
        id: "embed",
        hidden: variant === "strict",
      },
      {
        Component: EmailShareButton,
        Icon: EmailIcon,
        id: "email",
        hidden: false,
      },
      {
        Component: FacebookShareButton,
        Icon: FacebookIcon,
        id: "facebook",
        hidden: false,
      },
      {
        Component: LinkedinShareButton,
        Icon: LinkedinIcon,
        id: "linkedin",
        hidden: false,
      },
      {
        Component: TwitterShareButton,
        Icon: TwitterXIcon,
        id: "x",
        hidden: false,
      },
      {
        Component: WhatsappShareButton,
        Icon: WhatsappIcon,
        id: "whatsapp",
        hidden: false,
      },
      {
        Component: ThreadsShareButton,
        Icon: ThreadsIcon,
        id: "threads",
        hidden: false,
      },
    ],
    [],
  );

  const component = useMemo(() => {
    const SocialButtons = ButtonsMap as TButtonsMap;
    switch (true) {
      case variant === VariantsEnum.LARGE:
        return (
          <Box
            sx={{
              ...(!id && {
                filter: "blur(3px)",
                pointerEvents: "none",
              }),
            }}
          >
            <LargeVariant ButtonsMap={ButtonsMap as TButtonsMap} url={url} />
          </Box>
        );
      case variant === VariantsEnum.STRICT:
        return (
          <StrictVariant
            ButtonsMap={SocialButtons}
            url={url}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        );
      case variant === "styless":
        return <StylessVariant ButtonsMap={SocialButtons} url={url} />;
      default:
        return <LargeVariant ButtonsMap={SocialButtons} url={url} />;
    }
  }, [url, anchorEl]);

  return component;
};

export default PublicUrlShare;
