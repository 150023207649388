import SuspenseLayout from "@components/Layout/SuspenseLayout";
import ChangePassword from "@pages/Login/ChangePassword";
import { Privacy } from "@pages/Privacy";
import { ACQUIRER_PATHS, AUTH_PATHS, POLICY_PATHS } from "./paths";
import publicFormRoutes from "./PublicFormRoutes";
import { TermsOfService } from "@pages/TermsOfService";
import EnterpriseRestoredPage from "@pages/AcquirerPortal/EnterpriseRestoredPage/EnterpriseRestoredPage";
import ExpiredPasswordReset from "@pages/Login/ExpiredPasswordReset";
import InviteMembers from "@pages/Login/InviteMembers";
import RefundPolicy from "@pages/RefundPolicy/RefundPolicy";
import { ThemeWrapper } from "@theme/v2/Provider";
import CancelMembership from "@pages/CancelMembership/CancelMembership";

export const unprotectedRoutes = [
  {
    path: "public-form",
    element: <SuspenseLayout />,
    children: publicFormRoutes,
  },
  {
    path: "/",
    element: <SuspenseLayout />,
    children: publicFormRoutes,
  },
  {
    path: AUTH_PATHS.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },
  {
    path: AUTH_PATHS.SET_PASSWORD,
    element: <ChangePassword isToS />,
  },
  {
    path: POLICY_PATHS.PRIVACY,
    element: <Privacy />,
  },
  {
    path: POLICY_PATHS.TERMS_OF_SERVICE,
    element: <TermsOfService />,
  },
  {
    path: ACQUIRER_PATHS.PROVIDER_RESTORED,
    element: <EnterpriseRestoredPage />,
  },
  {
    path: AUTH_PATHS.LINK_EXPIRED,
    element: <ExpiredPasswordReset />,
  },
  { path: AUTH_PATHS.INVITE_USER_LINK, element: <InviteMembers /> },
  {
    path: POLICY_PATHS.REFUND_POLICY,
    element: (
      <ThemeWrapper>
        <RefundPolicy />
      </ThemeWrapper>
    ),
  },
  {
    path: POLICY_PATHS.CANCEL_MEMBERSHIP,
    element: (
      <ThemeWrapper>
        <CancelMembership />
      </ThemeWrapper>
    ),
  },
  // {
  //   path: "/",
  //   element: <SuspenseLayout />,
  //   children: [
  //     {
  //       path: "404",
  //       element: <NotFoundResourcePage />,
  //     },
  //   ],
  // },
];
