import { showMessage } from "@common/Toast";
import NiceModal from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { ADVANCED_BUILDER_ERROR_MODAL } from "modals/modal_names";
import { useMutation, useQueryClient } from "react-query";
import { useColors } from "../api";
import { savePaymentForm } from "../api/builderApi";
import { headingsSignal, mobileHeadingsSignal } from "../consts/headings";
import {
  formUpdatedAt,
  globalButtonStyles,
  isBuilderError,
  isDraftCreated,
  isDraftEnabled,
  isPublishEnabled,
  isPublished,
  structuredTreeSignal,
} from "../signals";
import { flattenNodeList } from "../utils";

const useSavePaymentForm = ({ modal, productId, productTitle, theme }: any) => {
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();
  const { colorsGroupedLists } = useColors({ withGradients: false });

  const { mutate, isLoading } = useMutation(({ data }: any) =>
    savePaymentForm(data, merchantId, productId),
  );

  function createHeadingsDefaultStyles(
    headingsSignal: {
      [key in string]: any;
    },
    mobileStr?: string,
  ) {
    const styles: { [key in string]: any } = {};

    for (const key in headingsSignal) {
      const formattedKey =
        key !== "body"
          ? `${key}Default${mobileStr || ""}Styles`
          : `pDefault${mobileStr || ""}Styles`;

      const fontWeight = headingsSignal[key].fontWeight.value?.name;

      styles[formattedKey] = {
        fontSize: headingsSignal[key].fontSize.value.toString(),
        lineHeight: headingsSignal[key].lineHeight.value.toString(),
        letterSpacing: headingsSignal[key].letterSpacing.value.toString(),
        fontFamily: headingsSignal[key].fontFamily.value?.name,
        fontWeight: fontWeight.charAt(0).toLowerCase() + fontWeight.slice(1),
        color: headingsSignal[key].textColor.value?.name,

        height: "",
        width: "",
        borderRadius: "",
        backgroundColor: "",
        background: "",
        webkitBackgroundClip: "",
        webkitTextFillColor: "",
        backgroundClip: "",
        textFillColor: "",
      };
    }

    return styles;
  }

  const parseColorsToPayload = (color: "general" | "custom") => {
    return colorsGroupedLists?.get(color)?.map((color: any) => {
      return {
        ...color,
        colorUsage: "",
      };
    });
  };

  const generatePayload = () => {
    return {
      html: flattenNodeList(structuredTreeSignal.value, true),
      globalColors: parseColorsToPayload("general"),
      customColors: parseColorsToPayload("custom"),
      ...createHeadingsDefaultStyles(headingsSignal),
      ...createHeadingsDefaultStyles(mobileHeadingsSignal, "Mobile"),
      buttonDefaultStyles: {
        padding: globalButtonStyles.padding.value,
        fontSize: globalButtonStyles.fontSize.value,
        borderRadius: globalButtonStyles.borderRadius.value,
        backgroundColor: globalButtonStyles.backgroundColor.value?.name,
        color: globalButtonStyles.textColor.value?.name,

        height: "",
        width: "",
        fontFamily: "",
        fontWeight: "",
        lineHeight: "",
        letterSpacing: "",
        background: "",
        webkitBackgroundClip: "",
        webkitTextFillColor: "",
        backgroundClip: "",
        textFillColor: "",
      },
    };
  };

  const handleRefetch = () => {
    queryClient.refetchQueries(["get-builder-data", merchantId, productId]);
  };

  const handleSaveClick = (isFirstTime?: boolean, onSuccessCB?: () => void) => {
    mutate(
      { data: generatePayload() },
      {
        onError: () => {
          showMessage(
            "Error",
            "Please try again later",
            true,
            "Save Draft failed",
          );

          NiceModal.show(ADVANCED_BUILDER_ERROR_MODAL, {
            theme,
            handleRefetch,
          });

          isBuilderError.value = true;
          modal?.hide();
        },
        onSuccess: async (res) => {
          isDraftCreated.value = (() => {
            if (formUpdatedAt.value === 0 && Boolean(res?.updatedAt))
              return true;
            else if (
              formUpdatedAt.value !== 0 &&
              formUpdatedAt.value < res?.updatedAt
            )
              return true;
            else return false;
          })();
          isPublished.value = Boolean(res?.productID > 0);

          formUpdatedAt.value = res?.updatedAt || 0;

          if (isFirstTime) {
            handlePublish();
            return;
          }

          isDraftEnabled.value = !isDraftCreated.value;
          isPublishEnabled.value = isDraftCreated.value || !isPublished.value;

          showMessage(
            "Success",
            `Your payment form ${productTitle} has been saved`,
            true,
            "Draft Saved",
          );
          modal?.hide();
          onSuccessCB && onSuccessCB();
        },
      },
    );
  };

  const handlePublish = () => {
    mutate(
      { data: { ...generatePayload(), publish: true } },
      {
        onError: () => {
          showMessage(
            "Error",
            "Please try again later",
            true,
            "Publish Payment Form failed",
          );
          modal?.hide();
        },
        onSuccess: async (res) => {
          isPublished.value = Boolean(res?.snapshot);
          isDraftCreated.value = !isPublished.value;

          isDraftEnabled.value = !isDraftCreated.value;
          isPublishEnabled.value = isDraftCreated.value || !isPublished.value;

          showMessage(
            "Success",
            `Payment Form “${productTitle}” has been published and ready to accept payments`,
            true,
            "Payment Form Published",
          );

          modal?.hide();
        },
      },
    );
  };

  return {
    isLoading,
    handleSaveClick,
    handleRefetch,
    handlePublish,
  };
};

export default useSavePaymentForm;
