import { Stack } from "@mui/material";
import { useMemo } from "react";
import SectionHeader from "./SectionHeader";
import { Grid } from "@mui/material";
import { GiveInput, InputProps } from "@shared/GiveInputs/GiveInput";
import { Controller, useFormContext } from "react-hook-form";
import GiveSelect, { GiveSelectProps } from "@shared/GiveInputs/GiveSelect";
import { getCountryNames, getUSNames } from "@utils/country_dial_codes";
import {
  HFGiveTelephone,
  TelInputProps,
} from "@shared/HFInputs/HFGiveTelephone/HFGiveTelephone";
import { Package } from "@phosphor-icons/react";
import { ProvinceField, ProvinceFieldProps } from "./ProvinceField";
import ZipField from "./ZipField";

const gridItemProps = {
  xs: 12,
  pt: "20px",
  pr: "16px",
};

const Delivery = ({
  isMobileView,
  isDisabledFields,
}: {
  isMobileView: boolean;
  isDisabledFields?: boolean;
}) => {
  const methods = useFormContext();

  const formFileds = useMemo(() => {
    return [
      {
        Component: (props: GiveSelectProps) => <GiveSelect {...props} />,
        label: "Country/Region",
        name: "deliveryAddress.country",
        options: getCountryNames().map((country) => ({
          value: country,
          label: country,
        })),
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "First Name",
        name: "deliveryAddress.firstName",
        md: 6,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Last Name",
        name: "deliveryAddress.lastName",
        md: 6,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Address",
        name: "deliveryAddress.address",
        md: 12,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "Apartment, Suite etc",
        name: "deliveryAddress.apartment",
        md: 12,
      },
      {
        Component: (props: InputProps) => <GiveInput {...props} />,
        label: "City",
        name: "deliveryAddress.city",
        md: 4,
      },
      {
        Component: (props: ProvinceFieldProps) => <ProvinceField {...props} />,
        name: "deliveryAddress.province",
        options: getUSNames().map((state) => ({
          value: state,
          label: state,
        })),
        md: 4,
      },
      {
        Component: (props: InputProps) => <ZipField {...props} />,
        label: "Zip Code",
        name: "deliveryAddress.zipCode",
        md: 4,
      },
      {
        Component: (
          props: TelInputProps & {
            name: string;
          },
        ) => <HFGiveTelephone {...props} />,
        label: "Delivery Phone Number",
        name: "deliveryAddress.phoneNumber",
        md: 12,
      },
    ];
  }, []);

  return (
    <Stack spacing="20px">
      <SectionHeader title="Delivery" icon={<Package size={24} />} />
      <Grid container spacing="20px">
        {formFileds.map(({ Component, name, label, md, options }, index) => {
          return (
            <Grid
              key={index}
              {...gridItemProps}
              xs={12}
              md={isMobileView ? 12 : md}
            >
              <Controller
                control={methods.control}
                name={name as any}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const baseProps = {
                    name,
                    value,
                    label,
                    onChange,
                    helperText: error?.message,
                  };

                  if (options) {
                    return (
                      <Component
                        {...baseProps}
                        options={options}
                        isUS={
                          methods.watch("deliveryAddress.country") ===
                          "United States"
                        }
                        disabled={isDisabledFields}
                        error={Boolean(error)}
                      />
                    );
                  }

                  return (
                    <Component
                      {...baseProps}
                      fullWidth
                      disabled={isDisabledFields}
                      error={Boolean(error)}
                    />
                  );
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default Delivery;
