import { Stack } from "@mui/system";
import { Icon as StatusIcon } from "@shared/Popup/GivePopupIcons";
import React, { useMemo, useState } from "react";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveUploadStack from "@shared/FileUpload/GiveUploadStack";
import { FileText } from "@phosphor-icons/react";
import { ReportType } from "@components/Merchants/MerchantPreview/MATCH/types";
import { statusOptions } from "./ReportStatusSelector";
import { Skeleton } from "@mui/material";
import { useGetSingleMATCHReport } from "@components/Merchants/MerchantPreview/MATCH/hooks/useMATCHReports";
import moment from "moment";
import { GiveUploadItemProps } from "@shared/FileUpload/types";

const MAX_TEXT_LENGTH = 1301; // Value calculated from mockups, can be changed

interface IComponentProps {
  reportDetailsViewData: ReportType;
  merchantID: number;
}

type EvidenceFileItem = {
  fileName: string;
  fileType: string;
  fileURL: string;
  id: number;
  updatedAt: number;
  userFullName: string;
  isUploaded: boolean;
};

const ReportDetailsView = ({
  reportDetailsViewData,
  merchantID,
}: IComponentProps) => {
  const { statusName, findings, ID } = reportDetailsViewData;
  const isLargeDescription = findings.length > MAX_TEXT_LENGTH;
  const [showAllFindings, setShowAllFindings] = useState(!isLargeDescription);
  const { data: extraData, isLoading } = useGetSingleMATCHReport(
    merchantID,
    ID,
  );

  const evidenceList: GiveUploadItemProps[] = useMemo(() => {
    if (!extraData?.files) return [];

    return extraData.files.map((item: EvidenceFileItem) => ({
      merchantId: merchantID,
      state: item.isUploaded ? "uploaded" : "",
      value: "MATCH Evidence",
      setValue: () => null,
      byMessage: `by ${item.userFullName}`,
      dateMessage: moment(item.updatedAt * 1000).format("MMM DD YYYY, HH:mm"),
      fileData: {
        fileName: item.fileName,
        id: item.id,
        fileType: item.fileType,
        fileURL: item.fileURL,
        tag: "Match Evidence",
      },
      tagType: "form-field",
    }));
  }, [extraData]);

  const toggleFindingsTextLength = () => setShowAllFindings((curr) => !curr);

  return (
    <Stack gap={2.5}>
      <StyledStatusContainerStack>
        <StatusIcon
          type={statusName === "clear" ? "success-regular" : "warning"}
        />
        <GiveText variant="h5">{statusOptions[statusName].label}</GiveText>
      </StyledStatusContainerStack>
      <Stack gap={1.5}>
        <GiveText variant="bodyS">Findings</GiveText>
        <GiveText variant="bodyS" color="secondary">
          {showAllFindings ? findings : findings.slice(0, MAX_TEXT_LENGTH + 1)}
        </GiveText>
        {isLargeDescription && (
          <ReadText
            variant="bodyS"
            color="link"
            onClick={toggleFindingsTextLength}
          >
            Read {showAllFindings ? "Less" : "More"}
          </ReadText>
        )}
      </Stack>
      <Stack gap={1.5}>
        <GiveText variant="bodyS">Evidence</GiveText>
        {isLoading ? (
          <StyledEvidenceLoadingSkeleton variant="rectangular" />
        ) : evidenceList.length ? (
          <GiveUploadStack items={evidenceList} isHideDelete />
        ) : (
          <NoEvidenceAttached />
        )}
      </Stack>
    </Stack>
  );
};

const NoEvidenceAttached = () => {
  const theme = useAppTheme();
  return (
    <Stack justifyContent="center" alignItems="center" gap={2.5}>
      <StyledIconBox>
        <FileText
          width={24}
          height={24}
          color={theme.palette.icon?.["icon-primary"]}
        />
      </StyledIconBox>
      <GiveText variant="bodyS" color="secondary">
        No evidence document was attached
      </GiveText>
    </Stack>
  );
};

const StyledIconBox = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 56,
  height: 56,
  borderRadius: "100%",
  backgroundColor: theme.palette.primitive?.transparent["darken-5"],
}));

const StyledStatusContainerStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.border?.primary}`,
}));

const StyledEvidenceLoadingSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  height: 200,
  borderRadius: theme.spacing(1.5),
}));

const ReadText = styled(GiveText)({ cursor: "pointer", width: "fit-content" });

export default ReportDetailsView;
