import Table from "componentsV2/Table/Table";
import { useGetProductTableColumns } from "../hooks/useGetProductTableColumns";
import { useGetProductList } from "../hooks/useGetProductList";
import { TCampaignType } from "../types";
import { CAMPAIGN_DETAILS_MODAL } from "modals/modal_names";
import { useOpenCampaignPanel } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { useCallback, useMemo } from "react";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { useHandleScroll } from "componentsV2/Table/hooks/useHandleScroll";
import ProductBanner from "../ProductBanner";
import ProductActions from "../ProductActions";
import {
  CREATE_EVENT_MODAL,
  CREATE_FUNDRAISER_MODAL,
  CREATE_INVOICE_MODAL,
  CREATE_MEMBERSHIP_MODAL,
  CREATE_PAYMENT_FORM_MODAL,
  CREATE_SWEEPSTAKES_MODAL,
} from "modals/modal_names";

type Props = {
  type: TCampaignType;
};

const ProductListTable = ({ type }: Props) => {
  const { onScroll } = useHandleScroll();

  const { columns } = useGetProductTableColumns({ type });
  const {
    allRows,
    isLoading,
    page,
    setPage,
    setPageDispatcher,
    totalRows,
    queryKey,
    searchQuery,
    hasFilters,
    handleResetFilters,
    handleSearch,
  } = useGetProductList({ type });
  const numberOfPages = Math.ceil(Number(totalRows ?? 0) / ROWS_PER_PAGE);
  const { title, modal: createProductModal } = components[type];

  const loadMore = useCallback(() => {
    if (!isLoading && page < numberOfPages) {
      setPage();
    }
  }, [isLoading, page, numberOfPages]);

  const { selectedRowIdx, setSelectedRowIdx } = useOpenCampaignPanel({
    allRows,
    isLoading,
    page,
    setPage: setPageDispatcher,
    totalRows,
  });

  const handleResetSelectedRow = () => {
    setSelectedRowIdx(-1);
  };

  const Head = useMemo(
    () => (
      <>
        <ProductBanner title={title} />
        <ProductActions
          type={type}
          handleSearch={handleSearch}
          handleResetSelectedRow={handleResetSelectedRow}
        />
      </>
    ),
    [totalRows, type, createProductModal, title],
  );

  return (
    <Table
      allRows={allRows}
      columns={columns}
      isLoading={isLoading}
      openModal={CAMPAIGN_DETAILS_MODAL}
      page={page}
      setPage={setPage}
      setPageDispatcher={setPageDispatcher}
      totalRows={totalRows}
      selectedRowIdx={selectedRowIdx}
      setSelectedRowIdx={setSelectedRowIdx}
      type="products"
      loadMore={loadMore}
      enabledInfiniteScroll
      queryKey={queryKey}
      onScroll={onScroll}
      hasFilters={hasFilters}
      handleResetFilters={handleResetFilters}
      searchQuery={searchQuery}
      Head={Head}
      handleSearchChange={handleSearch}
    />
  );
};

type TObjectValues = {
  modal: string;
  title: string;
};

const components: Record<TCampaignType, TObjectValues> = {
  "payment-form": {
    modal: CREATE_PAYMENT_FORM_MODAL,
    title: "Products",
  },
  fundraiser: {
    modal: CREATE_FUNDRAISER_MODAL,
    title: "Fundraisers",
  },
  event: {
    modal: CREATE_EVENT_MODAL,
    title: "Events",
  },
  invoice: {
    modal: CREATE_INVOICE_MODAL,
    title: "Invoices",
  },
  membership: {
    modal: CREATE_MEMBERSHIP_MODAL,
    title: "Memberships",
  },
  sweepstake: {
    modal: CREATE_SWEEPSTAKES_MODAL,
    title: "Sweepstakes",
  },
  standard: {
    modal: CREATE_PAYMENT_FORM_MODAL,
    title: "Products",
  },
};

export default ProductListTable;
