import { useAppSelector } from "@redux/hooks";
import { selectAuth } from "@redux/slices/auth/auth";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { Item_Layout } from "../provider/provider.type";
import ProductPageContainer from "./products/ProductPageContainer";
import UnpublishedPage from "./products/UnpublishedPage";

const ProductPreviewView = () => {
  const isAuthenticated = useAppSelector(selectAuth);
  const { displayStatus } = usePayBuilderContext();
  const { methods } = usePayBuilderForm();
  const {
    Style: { itemLayout },
    Items,
    About: { heading, description, assetPosition },
  } = methods.watch();
  const header = { heading, description, assetPosition };

  const displayedItems = Items?.filter((item) => item?.display);

  if (displayStatus === "draft" && !isAuthenticated) {
    return <UnpublishedPage />;
  }

  return (
    <ProductPageContainer
      displayStatus={displayStatus}
      header={header}
      isPreview
      items={displayedItems}
      itemLayout={itemLayout as Item_Layout}
    />
  );
};
export default ProductPreviewView;
