import { TDocument } from "@common/FilePreview/types";
import { FILE_PREVIEW_MODAL_V2 } from "modals/modal_names";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FilePreviewType } from "../types";
import { useEffect } from "react";
import { fileFormatter } from "../utils";

type Props = {
  documentList?: TDocument[];
  entityId?: number;
};

const useDocumentPreviewV2 = ({ documentList, entityId }: Props) => {
  const { visible, args, show, remove } = useModal(FILE_PREVIEW_MODAL_V2);
  const documentsCount = documentList?.length || 0;

  const handlePreview = (fileData?: FilePreviewType) => {
    if (!fileData) return;
    const file = fileFormatter(fileData);
    NiceModal.show(FILE_PREVIEW_MODAL_V2, {
      file,
      documentList: documentList || [file],
      entityId,
    });
  };

  useEffect(() => {
    if (visible) {
      show({ ...args, documentList });
    }

    return () => {
      if (visible) remove();
    };
  }, [documentsCount]);

  return {
    handlePreview,
  };
};

export default useDocumentPreviewV2;
