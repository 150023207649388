import { OPERATIONS } from "@constants/permissions";

export const getAccessOperation = (method: string) => {
  switch (method) {
    case "send":
    case "add":
    case "create":
      return OPERATIONS.CREATE;
    case "read":
      return OPERATIONS.READ;
    case "list":
      return OPERATIONS.LIST;
    case "delete":
      return OPERATIONS.DELETE;
    case "link":
      return OPERATIONS.LINK;
    case "unlink":
      return OPERATIONS.UNLINK;
    case "submit":
      return OPERATIONS.SUBMIT;
    case "cancel":
      return OPERATIONS.CANCEL;
    case "export":
      return OPERATIONS.EXPORT;
    case "manage":
      return OPERATIONS.MANAGE;
    case "block":
      return OPERATIONS.BLOCK;
    case "change":
      return OPERATIONS.CHANGE;
    default:
      return OPERATIONS.UPDATE;
  }
};

export const composePermission = (...args: string[]) => args.join(":");
