import { ClockCounterwiseIcon } from "@assets/icons/RebrandedIcons";
import { StyledIconButton } from "@common/IconButton";
import { SIDE_PANEL_BUTTON_OFFSET } from "@constants/constants";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NEW_BASE_PANEL_WIDTH } from "@shared/SidePanel/GiveSidePanel";
import { useChangelogModal } from "features/ChangeLog/hooks/useChanglelogModal";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useChangelogData } from "features/ChangeLog/hooks/useChangelogData";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { calculateTotalUnreadSum } from "features/Minibuilders/Conversations/utils/functions";
import { useListTopics } from "features/Minibuilders/Conversations/hooks/useListTopics";
import { FormProvider } from "react-hook-form";
import ChangelogModal from "features/ChangeLog/ChangelogModal";
import { useEffect } from "react";

type Props = {
  isOpenConversations?: boolean;
  isDoublePanel?: boolean;
  isLoadingConversation?: boolean;
};

const ChangelogButton = ({
  isOpenConversations,
  isDoublePanel = false,
  isLoadingConversation,
}: Props) => {
  const {
    merchant,
    data: {
      status: { sponsorStatusName },
    },
  } = useMerchantSidePanelContext();
  const { isMobileView } = useCustomThemeV2();
  const { isOpen, handleOpenChangelogModal } = useChangelogModal();

  const isMerchantApproved = sponsorStatusName === "approved";

  const styles = useStyles({
    isOpen: Boolean(isOpen || isOpenConversations),
    isMobileView,
    isDoublePanel,
  });

  useEffect(() => {
    return () => {
      handleOpenChangelogModal(false);
    };
  }, []);

  const {
    data,
    isLoading,
    handleLoadMore,
    isMoreData,
    filtersForm,
    isFiltersApplied,
    showMoreDataAvailable,
  } = useChangelogData({
    id: merchant?.accID,
    enabled: isMerchantApproved,
    isOpen,
  });

  const { data: topicData, isLoading: isTopicDataLoading } = useListTopics({
    merchantId: merchant?.accID,
    enabled: isMobileView && isMerchantApproved,
  });

  const { totalUnread } = calculateTotalUnreadSum(topicData?.data);

  if (!isMerchantApproved) return null;

  if (isOpen) {
    return (
      <FormProvider {...filtersForm}>
        <ChangelogModal
          data={data}
          isLoading={isLoading}
          headerActions={{
            handleOpenChangelogModal,
          }}
          isMerchantApproved
          isLoadingConversation={isLoadingConversation || isTopicDataLoading}
          totalUnread={totalUnread}
          handleLoadMore={handleLoadMore}
          merchantId={merchant?.accID}
          isMoreData={isMoreData}
          isFiltersApplied={isFiltersApplied}
          showMoreDataAvailable={showMoreDataAvailable}
        />
      </FormProvider>
    );
  }

  return (
    <StyledIconButton
      id="changelogButton"
      className={styles.buttonStyles}
      onClick={() => handleOpenChangelogModal(!isMobileView ? true : !isOpen)}
      disabled={isLoading}
      sx={{
        position: "relative",
        borderRadius: "50%",
      }}
      data-testid="changelog-button"
    >
      <Box className={styles.containerStyles}>
        <ClockCounterwiseIcon width="30px" height="30px" />
      </Box>
    </StyledIconButton>
  );
};

export default ChangelogButton;

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyles: ({
    isMobileView,
    isOpen,
    isDoublePanel,
  }: {
    isMobileView: boolean;
    isOpen: boolean;
    isDoublePanel: boolean;
  }) => {
    return {
      position: isMobileView ? (isOpen ? "relative" : "fixed") : "fixed",
      bottom: isMobileView ? (isOpen ? "initial" : "90px") : "90px",
      left: isMobileView
        ? isOpen
          ? "0px"
          : "initial"
        : `calc(100% - ${
            (isDoublePanel ? NEW_BASE_PANEL_WIDTH * 2 : NEW_BASE_PANEL_WIDTH) +
            SIDE_PANEL_BUTTON_OFFSET
          }px)`,
      right: isMobileView ? "20px" : "70px",
      ...(isMobileView && { top: isOpen ? "0px" : "initial" }),
      boxShadow: isMobileView
        ? "0px 2.625px 13.125px 0px rgba(2, 2, 2, 0.15)"
        : "initial",
      backgroundColor: theme.palette.neutral.white,
      zIndex: 3,
    };
  },

  containerStyles: {
    borderRadius: "50%",
    padding: "10px 10px 4px 10px",
    backgroundColor: theme.palette.neutral.white,
  },
}));
