import { useCallback } from "react";
import { ChallengesSecondPanelWrapper } from "../WithRepository/Challenges/ChallengesSecondPanel";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";

// This hook can be used to get the content details for the first panel
const useGetContentDetails = () => {
  const {
    openChallenge,
    firstPanelContent,
    setFirstPanelContent,
    setContentHistory,
    handleCloseAll,
    isSinglePanelSize,
    secondPanelContent,
  } = useMerchantSidePanelContext();

  const handleClose = () => {
    // Here we are storing the current first panel content in the history
    // so that we can use it to navigate back to the same panel

    if (isSinglePanelSize || !secondPanelContent) {
      return handleCloseAll();
    }
    setContentHistory(firstPanelContent);
    setFirstPanelContent("");
  };

  const handleBack = () => {
    setFirstPanelContent("");
  };

  const getContentDetails = useCallback(() => {
    switch (firstPanelContent) {
      case "challenges":
        return (
          <ChallengesSecondPanelWrapper
            handleClose={handleClose}
            handleBack={handleBack}
            challengeIdentifier={openChallenge?.identifier || ""}
            challengeDisplayName={openChallenge?.displayName || ""}
          />
        );
      default:
        return <></>;
    }
  }, [firstPanelContent, secondPanelContent]);

  return { contentDetails: getContentDetails() };
};

export default useGetContentDetails;
