import { Grid, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveLink from "@shared/Link/GiveLink";
import { ArrowsClockwise } from "@phosphor-icons/react";
import GiveSwitch from "@shared/Switch/GiveSwitch";
import { styled } from "@theme/v2/Provider";
import { useStatusReview } from "@components/Merchants/MerchantPreview/hooks/useStatusReview";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import moment from "moment/moment";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import useUpdateMerchant from "features/Merchants/MerchantSidePanel/hooks/useUpdateMerchant";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { ArrowPlacement } from "@shared/Tooltip/GiveTooltip.types";

const AccountStatusSettings = () => {
  const { data } = useMerchantSidePanelContext();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const isUpdateMspAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.MSP),
    operation: OPERATIONS.SUBMIT,
  });

  const {
    methods,
    handleChangeProcessing,
    handleChangeTransfer,
    isMerchantApproved,
  } = useStatusReview({
    data: data.status,
    name: data?.merchantInfo?.merchantName,
  });
  const { handleResyncMSP, isReSyncMSPLoading } = useUpdateMerchant();
  const { watch } = methods;
  const { process, transfer } = watch();

  const { merchant_underwriting } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();

  const isHide = !merchant_underwriting && isEnterprisePortal;

  const isToggleHidden =
    !isMerchantApproved ||
    !isUpdateUnderwriterAllowed ||
    data?.status.statusName === "suspended" ||
    isHide;

  const ableToTransferDisabled =
    isMerchantApproved && !data?.status?.allowedToTransfer;

  const lastMSPSyncAt = data?.msp?.lastMSPSyncAt;
  const lastSyncDate =
    lastMSPSyncAt &&
    moment.unix(Number(lastMSPSyncAt)).format("MM/DD/YYYY - HH:mm");

  const resyncDisabled =
    isReSyncMSPLoading || !data?.msp?.canSyncMSPData || !isUpdateMspAllowed;

  return (
    <ContainerStack>
      <Grid container spacing={2}>
        <GridItemRow
          title="Able to process"
          content={
            <RowToggle
              checked={process}
              onChange={handleChangeProcessing}
              hide={isToggleHidden}
            />
          }
        />
        <GridItemRow
          title="Able to transfer"
          content={
            <RowToggle
              checked={transfer}
              onChange={handleChangeTransfer}
              hide={isToggleHidden}
              disabled={ableToTransferDisabled}
              disabledTooltipMessage="Money transfers are disabled from the bank account section"
            />
          }
        />
        <GridItemRow
          title="Last sync"
          content={
            <Stack gap={0.5}>
              <GiveText variant="bodyS">{lastSyncDate}</GiveText>
              {!isHide && (
                <GiveLink
                  component="button"
                  color="secondary"
                  onClick={handleResyncMSP}
                  disabled={resyncDisabled}
                  Icon={ArrowsClockwise}
                >
                  Re-sync MSP
                </GiveLink>
              )}
            </Stack>
          }
        />
      </Grid>
    </ContainerStack>
  );
};

const RowToggle = ({
  onChange,
  checked,
  disabled = false,
  disabledTooltipMessage,
  hide,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  disabledTooltipMessage?: string;
  hide: boolean;
}) => {
  if (hide) {
    return <GiveText variant="bodyS">{checked ? "Yes" : "No"}</GiveText>;
  }

  return (
    <GiveTooltip
      color="default"
      title={disabledTooltipMessage}
      disableHoverListener={!disabled || !disabledTooltipMessage}
      customTooltipStyles={{ width: "fit-content" }}
      arrowPlacement={ArrowPlacement.TOP}
    >
      <Stack direction="row" gap={1}>
        <GiveSwitch onChange={onChange} checked={checked} disabled={disabled} />
        <GiveText variant="bodyS">{checked ? "Yes" : "No"}</GiveText>
      </Stack>
    </GiveTooltip>
  );
};

const GridItemRow = ({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode;
}) => {
  return (
    <>
      <Grid item xs={6}>
        <GiveText variant="bodyS" color="secondary">
          {title}
        </GiveText>
      </Grid>
      <Grid item xs={6}>
        {content}
      </Grid>
    </>
  );
};

const ContainerStack = styled(Stack)(({ theme }) => ({
  borderRadius: "16px",
  padding: "16px",
  gap: "16px",
  backgroundColor: theme.palette?.primitive?.transparent?.["darken-5"],
}));

export default AccountStatusSettings;
