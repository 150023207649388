import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectReplies,
  setConversationsGeneralSearchQueryString,
} from "@redux/slices/conversations";
import { useCallback, useEffect, useMemo } from "react";

const replacePatterns = (input: string): string => {
  const userMentionRegex = /@\[__([^[\]]{1,100})__\]\(user:([\s\S()]{1,50})\)/g;
  const userMentionReplacement = (w: unknown, custom?: boolean) =>
    `<mark class="${!custom ? "mention" : "mention-custom"}">${w}</mark>`;

  const hashtagRegex =
    /#\[__([^[\]]{1,100})__\]\(hashtag:__([\s\S()]{1,50})__\)/g;

  const hashtagReplacement = '<span class="hashtag">#$1</span>';
  const updatedInput = input
    .replace(userMentionRegex, (match, p1, p2): string => {
      if (p1.startsWith('"')) {
        return userMentionReplacement(p1, true);
      } else {
        return userMentionReplacement(`@${p1}`);
      }
    })
    .replace(hashtagRegex, hashtagReplacement);
  return updatedInput;
};

export const useClickableHashtags = (body: string) => {
  const dispatch = useAppDispatch();
  const { isRepliesOpen } = useAppSelector(selectReplies);

  const computedBody = useMemo(() => {
    return replacePatterns(body);
  }, [body]);

  const handleClick = useCallback(
    (ev: MouseEvent) => {
      const target = ev.target as HTMLSpanElement;
      const content = target.textContent?.replace(/#|\(.*?\)/g, "") || "";
      dispatch(
        setConversationsGeneralSearchQueryString({
          queryKey: isRepliesOpen ? "Replies" : "Threads",
          queryString: content,
        }),
      );
    },
    [body],
  );

  useEffect(() => {
    const spans: NodeListOf<HTMLSpanElement> = document.querySelectorAll(
      ".comment-markup .hashtag",
    );
    if (spans) {
      spans.forEach((span) => {
        span.addEventListener("click", handleClick);
      });
    }

    return () => {
      spans.forEach((span) => {
        span.removeEventListener("click", handleClick);
      });
    };
  }, [computedBody]);

  return {
    computedBody,
  };
};
