import { customInstance } from "..";
import { buildMerchantEndpoints } from "../utils.api";

interface IParams {
  filter?: string;
  productTypeName: string;
}

export const productTypeIDs = {
  fundraisers: 6,
  events: 5,
  invoices: 3,
  memberships: 4,
  sweepstakes: 2,
  "payment-forms": 1,
  standard: 1,
};

// TODO: at this point we are not using this endpoint anymore
// So, it should be checked if it's needed or not
export const getCampaignStats = (params: IParams) => {
  const productTypeId =
    productTypeIDs[params.productTypeName as keyof typeof productTypeIDs];
  const baseURL = `product-types/${productTypeId}/stats`;
  let URL = baseURL;
  if (params?.filter) URL += `?${params.filter}`;

  return customInstance({
    url: buildMerchantEndpoints(URL),
    method: "GET",
  });
};
