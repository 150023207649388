import React, { useRef, useState } from "react";
import GiveChip, { IChipProps } from "@shared/Chip/GiveChip";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { useAppTheme } from "@theme/v2/Provider";
import { ContextualMenuOptionProps } from "@shared/ContextualMenu/ContextualMenu.types";
import GiveText from "@shared/Text/GiveText";
import { CaretDown } from "@phosphor-icons/react";
import { Stack } from "@mui/material";

interface IComponentProps {
  options: ContextualMenuOptionProps[];
  chipProps: IChipProps;
  canOpen?: boolean;
  label: string;
  menuWidth?: number;
}

const ChipMenu = ({
  options,
  chipProps,
  canOpen,
  label,
  menuWidth = 200,
}: IComponentProps) => {
  const theme = useAppTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const chipRef = useRef(null);

  const handleOpenMenu = () => {
    setAnchorEl(chipRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <GiveChip
        onClick={canOpen ? handleOpenMenu : undefined}
        elementRef={chipRef}
        label={
          <Stack direction="row" gap={0.5} alignItems="center">
            <GiveText variant="bodyXS" sx={{ color: "currentcolor" }}>
              {label}
            </GiveText>
            {canOpen && (
              <CaretDown
                size={16}
                style={{
                  transform: `rotate(${anchorEl ? -180 : 0}deg)`,
                  transition: "transform 0.1s ease",
                }}
              />
            )}
          </Stack>
        }
        {...chipProps}
      />
      <ContextualMenu
        anchorEl={anchorEl}
        color="primary"
        texture="solid"
        horizontalOrigin="left"
        options={options}
        menuWidth={menuWidth}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: `${theme.palette?.surface?.tertiary} !important`,
              border: `0.5px solid ${theme.palette?.border?.secondary}`,
            },
          },
        }}
        sx={{
          zIndex: 10000,
        }}
        handleClose={handleCloseMenu}
      />
    </>
  );
};

export default ChipMenu;
