import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { MediaItem } from "@sections/PayBuilder/provider/provider.type";
import ImageBox from "@sections/PayBuilder/components/ImageBox";
import GiveText from "@shared/Text/GiveText";

export const LogoUploader = () => {
  const { methods } = usePayBuilderForm();

  const handleSelect = (item?: MediaItem) => {
    methods.setValue("Style.logo", item?.URL || "");
  };
  return (
    <>
      <GiveText mb="12px" color="primary" fontSize="14px">
        Add Logo (.png)
      </GiveText>
      <ImageBox
        imageUrl={methods.watch("Style.logo")}
        width="100%"
        handleSelect={handleSelect}
        filterPNGOnly
      />
    </>
  );
};
