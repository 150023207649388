import { AvatarGroup, Stack } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react/dist/ssr";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";

type Props = {
  title: string;
  messageImages: string[];
  commentsCount: number;
  lastComment: string;
  onClick: () => void;
};

const ChallengeDiscussion = ({
  title,
  messageImages,
  commentsCount,
  lastComment,
  onClick,
}: Props) => {
  return (
    <Container onClick={onClick}>
      <Stack gap="8px">
        <Stack gap="16px" direction="row" alignItems="center">
          <GiveText>{title}</GiveText>
          <StyledAvatarGroup
            renderSurplus={(surplus) => (
              <GiveText variant="bodyS" color="secondary">
                {surplus.toString()[0]}
              </GiveText>
            )}
            max={4}
          >
            {messageImages?.map((img, idx) => (
              <GiveAvatar sx={{ zIndex: -idx }} key={idx} imageUrl={img} />
            ))}
          </StyledAvatarGroup>
        </Stack>
        <Stack gap="16px" direction="row" alignItems="center">
          <GiveText variant="bodyS" color="secondary">
            {commentsCount} comments
          </GiveText>
          {lastComment && (
            <GiveText variant="bodyS" color="secondary">
              Last comment: {lastComment}
            </GiveText>
          )}
        </Stack>
      </Stack>
      <CaretRight />
    </Container>
  );
};

export default ChallengeDiscussion;

const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  "& .MuiAvatar-root": {
    zIndex: 100,
    width: "24px",
    height: "24px",
    "&.MuiAvatarGroup-avatar": {
      backgroundColor: "transparent",
    },
    "& .MuiTypography-root": {
      color: theme.palette.text?.primary,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
    },
  },
}));

const Container = styled(Stack)(({ theme }) => ({
  boxShadow: "none",
  border: `1px solid ${theme.palette.border?.primary}`,
  borderRadius: "20px",
  padding: "20px",
  cursor: "pointer",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));
