import { showMessage } from "@common/Toast";
import { useAddToCart } from "@hooks/merchant-api/cart";
import { getErrorParamName } from "@hooks/merchant-api/cart/useCheckout";
import { useGetCardInfos } from "@hooks/merchant-api/cart/useGetCardInfos";
import { PaymentSuccessInfo } from "@pages/Checkout/components/PaymentSuccess";
import useCheckFormType from "@sections/PayBuilder/components/hooks/useCheckFormType";
import { useIsEnabled } from "@sections/PayBuilder/components/hooks/useHelpers";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { customInstance } from "@services/api";
import { ErrorCodeEnum } from "@services/api/api.constant";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { getCountryCode } from "@utils/country_dial_codes";
import { convertPhoneNumber } from "@utils/date.helpers";
import { AxiosError } from "axios";
import { fromUnixTime } from "date-fns";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

const formatAddress = ({
  firstName,
  lastName,
  address,
  apartment,
  city,
  province,
  country,
  zipCode,
}: {
  [key: string]: string;
}) => ({
  name: `${firstName} ${lastName}`,
  line1: address,
  line2: apartment,
  city,
  state: province,
  country: getCountryCode(country),
  zip: zipCode,
});

const useCheckoutProcess = (
  onPaymentSuccess?: (data: PaymentSuccessInfo) => void,
  onPaymentDeclined?: () => void,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const shouldCreateCart = useRef<boolean>(false);
  const { cartItems, addToCart } = useCart();
  const { methods } = usePayBuilderForm();
  const isDeliveryEnabled = methods.watch().Checkout.delivery.render;
  const isEnabled = useIsEnabled();

  const formId = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { addToCartHandler, refetchCart, refetchProduct } = useAddToCart({
    disableFetchCart: true,
    newCartItems: cartItems,
    showModal: false,
  });
  const { isDesktopView } = useCustomThemeV2();

  const { handleCardNumberCheck } = useGetCardInfos();

  const queryClient = useQueryClient();

  const cartCheckoutMutation = useMutation(async (query: any) => {
    return customInstance({
      url: `/cart/checkout `,
      method: "POST",
      data: query,
    });
  });

  const { isFundraiser } = useCheckFormType();

  const handleCheckout = async (data: any) => {
    const cardObject = await handleCardNumberCheck(data?.payment?.cardNumber);

    const { payment, email, phoneNumber, deliveryAddress } = data;
    const {
      billingAddress,
      useDeliveryAddress,
      nameOnCard,
      expirationDate,
      cvv,
      cardNumber,
    } = payment;
    const [firstName, lastName] = nameOnCard.split(" ");
    const [expMonth, expYearShort] = expirationDate?.split("/") || [];
    const delivAddress = isDeliveryEnabled
      ? formatAddress(deliveryAddress)
      : null;
    const billAddress = useDeliveryAddress
      ? delivAddress
      : formatAddress(billingAddress);

    const customData = {
      user: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phoneNumber ? convertPhoneNumber(phoneNumber) : "",
        ...(!isFundraiser && {
          shippingAddress: delivAddress,
          billingAddress: { ...billAddress },
        }),
      },
      paymentMethodParams: {
        paymentMethodID: null,
        paymentMethod: {
          card: {
            address: { ...billAddress },
            cvv: cvv,
            cardholderName: nameOnCard,
            number: cardNumber?.replaceAll(" ", ""),
            expiryMonth: parseInt(expMonth),
            expiryYear: 2000 + parseInt(expYearShort),
            isDebit: cardObject?.binInfo?.cardType === "DEBIT",
          },
          isDefault: false,
        },
      },
      ...(!isFundraiser && {
        items: cartItems.reduce((acc, curr) => {
          if (!isNaN(Number(curr.id))) {
            acc.push(parseInt(curr.id));
          }
          return acc;
        }, [] as Array<number>),
      }),
      ...(isFundraiser && {
        isCustomerAnonymous: data.Donations.isAnonymous,
      }),
      sourceTypeName: "givesync_ui",
      passFees: !data.customerCoversFees,
    };
    /**
     * if the card is declined, we need to recreate the cart, otherwise BE will return error "The requested order was not found"
     */
    setIsLoading(true);

    if (shouldCreateCart.current && isEnabled) {
      await Promise.all([refetchProduct(), refetchCart()]);
      await addToCartHandler();
      shouldCreateCart.current = false;
    }
    isFundraiser && (await addToCartHandler());
    cartCheckoutMutation.mutate(customData, {
      onError: (error) => {
        setIsLoading(false);
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.message;

        const redirectErrorMessage =
          "Unexpected error detected. You will be redirected to the homepage in 5 seconds. Please try again. We apologize for the inconvenience!";

        if (
          axiosError.status === ErrorCodeEnum.BAD_INPUT &&
          (axiosError.response?.data as any)?.message.toLowerCase() ===
            redirectErrorMessage.toLowerCase()
        ) {
          showMessage(
            "Error",
            redirectErrorMessage,
            isDesktopView,
            undefined,
            false,
          );

          setTimeout(() => {
            navigate(`/${formId.id}`);
          }, 5000);
        }

        if ((error as any)?.response?.data.input) {
          showMessage(
            "Error",
            (error as any)?.response?.data.input[0].message ||
              `${getErrorParamName(
                (error as any)?.response?.data.input[0].param,
              )} is not valid`,
            isDesktopView,
          );
        } else {
          showMessage("Error", errorMessage);
        }
      },
      onSuccess: (res: any) => {
        if (
          res?.transactionProcessingState === "declined" ||
          !res?.transactionProcessingState
        ) {
          onPaymentDeclined?.();
          shouldCreateCart.current = true;
          setIsLoading(false);
          return;
        }

        queryClient.refetchQueries("find-sweepstake-by-id");
        queryClient.refetchQueries("find-membership-by-id");
        queryClient.refetchQueries("find-event-by-id");
        setIsLoading(false);
        const {
          customerEmail,
          customerPhoneNumber,
          binInfo,
          paymentMethod,
          items,
        } = res;
        const { cardType, cardBrand } = binInfo;
        const { card } = paymentMethod;

        const billingAddressToUse =
          useDeliveryAddress && isDeliveryEnabled
            ? deliveryAddress
            : billingAddress;
        onPaymentSuccess &&
          onPaymentSuccess({
            orderNumber: items[0].orderID,
            thankYouMessage: items[0].successMessage,
            contactEmail: customerEmail,
            contactPhoneNumber: customerPhoneNumber,
            paymentType: `${cardType} - ${cardBrand}`,
            cardNumber: `************${card.last4}`,
            deliveryAddress: {
              userFullName: `${deliveryAddress.firstName} ${deliveryAddress.lastName}`,
              street: deliveryAddress.address,
              fullAddress: `${deliveryAddress.city}, ${deliveryAddress.province}, ${deliveryAddress.zipCode}`,
              phoneNumber: deliveryAddress.phoneNumber,
            },
            billingAddress: {
              userFullName: `${billingAddressToUse.firstName} ${billingAddressToUse.lastName}`,
              street: billingAddressToUse.address,
              fullAddress: [
                billingAddressToUse.city,
                billingAddressToUse.province,
                billingAddressToUse.zipCode,
              ]
                .filter(Boolean)
                .join(","),
            },
            nextDonation: {
              date: fromUnixTime(items[0].nextAt).toLocaleDateString(),
              recurringType:
                items[0].recurringFrequency &&
                `Recurring ${items[0].recurringIntervalName}`,
              amount: Number(items[0].productVariantPrice),
              cardInfos: `${cardBrand} ****${card.last4}`,
            },
          });
      },
    });
  };

  return {
    handleCheckout,
    isLoading,
  };
};

export default useCheckoutProcess;
