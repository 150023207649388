import BackgroundVideoWrapper from "@common/BackgroundVideoWrapper";
import { Button } from "@common/Button";
import NewStatCard from "@common/StatCard/NewStatCard";
import { BACKGROUND_VIDEO_PRIMARY } from "@constants/constants";
import { CREATE_DENY_MESSAGE } from "@constants/permissions";
import { styled } from "@mui/material";
import { palette } from "@palette";
import { isMobile } from "@utils/index";
import {
  CampaignMapperValues,
  useCreateCampaignFn,
} from "features/Minibuilders/PaymentFormMinibuilder/useCreateCampaignFn";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";
import { CAMPAIGN_ANALYTICS_PANEL } from "modals/modal_names";
import useNewProductBanner from "./hooks";
import ProductBannerSkeleton from "./ProductBannerSkeleton";

const MIN_HEIGHT_BANNER = isMobile ? 260 : 238;

export type TProductTypeSection =
  | "fundraisers"
  | "events"
  | "invoices"
  | "memberships"
  | "sweepstakes"
  | "manage-money"
  | "payment-forms"
  | "customers"
  | "standard";

export interface Props {
  createCampaignTitle?: string;
  title: string;
  totalRows?: number;
  createModal?: string;
  background?: string;
  stats?: any[];
  mainStat?: any;
  statsIsFetching?: boolean;
  actionsComponent?: JSX.Element;
  analyticsType?: TProductTypeSection;
  AnalyticsIcon?: JSX.Element;
  analitycsModal?: string;
  hasEmptyData?: boolean;
  bannerHeight?: number;
  campaignType?: CampaignMapperValues;
}

export const NewProductBanner = ({
  createCampaignTitle,
  createModal,
  statsIsFetching,
  actionsComponent,
  background,
  analyticsType,
  analitycsModal = CAMPAIGN_ANALYTICS_PANEL,
  hasEmptyData = false,
  bannerHeight,
  campaignType,
  ...props
}: Props) => {
  const { isLoading, classes, mainStat, parseStats, onOpenAnalytics } =
    useNewProductBanner({ hasEmptyData, analitycsModal, analyticsType });

  const { isAddProductAllowed } = useProductPermission();
  const { createCampaignFn, canCampaignUseNewBuilder } = useCreateCampaignFn();

  const type = campaignType?.toLowerCase() || props?.title?.toLowerCase();
  return (
    <ProductBannerSkeleton
      loading={statsIsFetching || isLoading}
      height={bannerHeight || MIN_HEIGHT_BANNER}
    >
      <CustomBackgroundVideo
        videoProps={{
          className: classes.videoStyles,
        }}
        videoSrc={background || BACKGROUND_VIDEO_PRIMARY}
        sx={{
          minHeight: bannerHeight || MIN_HEIGHT_BANNER,
        }}
      >
        <NewStatCard
          stats={parseStats}
          mainStat={mainStat}
          {...props}
          onOpenAnalytics={onOpenAnalytics}
          actions={
            actionsComponent ? (
              actionsComponent
            ) : (
              <Button
                disabled={!isAddProductAllowed}
                background="primary"
                size="medium"
                onClick={() =>
                  createModal &&
                  createCampaignFn(
                    createModal,
                    type as keyof typeof canCampaignUseNewBuilder,
                  )
                }
                tooltipProps={{
                  show: !isAddProductAllowed,
                  message: CREATE_DENY_MESSAGE,
                }}
              >
                {createCampaignTitle}
              </Button>
            )
          }
        />
      </CustomBackgroundVideo>
    </ProductBannerSkeleton>
  );
};

export const CustomBackgroundVideo = styled(BackgroundVideoWrapper)(
  ({ theme }) => ({
    borderRadius: "24px",
    minHeight: MIN_HEIGHT_BANNER,
    padding: "32px",
    background: palette.neutral.white,

    [theme.breakpoints.down("new_sm_md")]: {
      padding: "20px 15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      borderRadius: "12px",
    },
  }),
);
