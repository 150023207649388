import { GiveInput, InputProps } from "@shared/GiveInputs/GiveInput";
import GiveSelect, { GiveSelectProps } from "@shared/GiveInputs/GiveSelect";

export type ProvinceFieldProps = InputProps & {
  isUS: boolean;
  options: GiveSelectProps["options"];
  name?: string;
};

export const ProvinceField = ({
  isUS,
  options,
  name,
  onChange,
  label,
  ...rest
}: ProvinceFieldProps) => {
  const handleSelectChange = (e: { target: { value: string | number } }) => {
    onChange?.({
      target: {
        value: e.target.value.toString(),
      },
    } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
  };

  if (isUS)
    return (
      <GiveSelect
        label="State"
        name={name}
        options={options}
        onChange={handleSelectChange}
        {...rest}
      />
    );

  return (
    <GiveInput label="Province" name={name} onChange={onChange} {...rest} />
  );
};
