import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useCallback, useState } from "react";
import { selectQueryString, setSearchQueryByKey } from "@redux/slices/search";

type Props = {
  queryKey?: string;
};

const useTableSearch = ({ queryKey }: Props) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");

  const searchQuery = useAppSelector((state) =>
    queryKey ? selectQueryString(state, queryKey) : undefined,
  );

  const searchQueryDispatcher = useCallback(
    (value: string) => {
      dispatch(
        setSearchQueryByKey({
          queryKey: queryKey || "",
          params: {
            value,
          },
        }),
      );
    },
    [queryKey],
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearch(value);
      searchQueryDispatcher(value);
    },
    [setSearch, searchQueryDispatcher],
  );

  return {
    search,
    handleSearchChange,
    searchQuery,
  };
};

export default useTableSearch;
