import { Button } from "@common/Button";
import { Button_V2Props } from "@common/Button/Button";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { Stack, StackProps, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  receiverName: string;
  senderName: string;
  isSendingToProvider?: boolean;
};

const PreviewInvitationModalCard = ({
  receiverName,
  senderName,
  isSendingToProvider,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const name = isSendingToProvider ? receiverName : senderName;
  return (
    <Container isMobileView={isMobileView}>
      <CustomText fontSize="32px">
        Hello{receiverName && !isSendingToProvider ? ` ${receiverName},` : ","}
      </CustomText>
      <CustomText>
        You have been invited as a primary account holder of the{" "}
        {isSendingToProvider ? "Provider" : "merchant"} account for{" "}
        <strong>{name}</strong>.
      </CustomText>
      <CustomText>
        {`Click the confirmation link bellow to accept the invitation and gain
        access to the `}
        <strong>{name}</strong>
        {` ${isSendingToProvider ? "Provider" : "merchant"} website.`}
      </CustomText>
      <InviteButton>Accept the invite</InviteButton>
      <CustomText>This link expires in 72 hours.</CustomText>
      <CustomText>
        Thank you, <br /> The Give Team
      </CustomText>
    </Container>
  );
};

export default PreviewInvitationModalCard;

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})<StackProps & { isMobileView: boolean }>(({ isMobileView }) => ({
  gap: "24px",
  backgroundColor: palette.neutral.white,
  borderRadius: "50px",
  padding: isMobileView ? "40px 24px" : "56px",
  boxShadow:
    "0 20px 40px 0 rgba(0, 0, 0, 0.05),0 2px 3px 0 rgba(0, 0, 0, 0.1);",
}));

const InviteButton = styled(Button)<Button_V2Props>(() => ({
  fontWeight: "400",
  fontSize: "18px",
  pointerEvents: "none",
  width: "186px",
  margin: "0 auto",
  textWrap: "nowrap",
  background: "primary",
}));

const CustomText = styled(Text)<ITextProps & { fontSize?: string }>(
  ({ fontSize }) => ({
    fontSize: fontSize || "16px",
    fontWeight: "400",
    color: palette.neutral[100],
    lineHeight: "100%",
  }),
);
