import { AMOUNTS_REGION } from "@constants/constants";
import { addMinutes, format } from "date-fns";
import moment from "moment";
import { matchPath } from "react-router-dom";

export const MenuUniqueIds = {
  paint0: "p_00",
  paint1: "p_01",
  paint2: "p_02",
  paint3: "p_03",
  paint4: "p_04",
  paint5: "p_05",
  paint6: "p_06",
  paint7: "p_07",
  paint8: "p_08",
  paint9: "p_09",
};

export const toDeFormat = (n?: number) => {
  if (n === null || n === undefined) return "";
  return Number(n).toLocaleString("de-DE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const toEnFormat = (n?: number) => {
  if (n === null || n === undefined) return "";
  return Number(n).toLocaleString("en-EN", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const toEnFormatWithDecimal = (n?: number | string) => {
  if (n === null || n === undefined) return "";
  const [wholePart, FraconPart] = `${n}`.split(".");
  return [Number(wholePart).toLocaleString("en-US"), FraconPart]
    .filter(Boolean)
    .join(".");
};

export const truncateString = (str?: string) => {
  if (str === null || str === undefined) return "";
  return str.length > 14 ? str.substring(0, 15) + "..." : str;
};

export const truncateStringLength = (str: string, limit: number) => {
  if (str === null || str === undefined) return "";
  return str.length > limit ? str.substring(0, limit) + " ..." : str;
};

export const truncateNumber = (n?: number) => {
  if (n === null || n === undefined) return "";
  return n.toString().length > 14
    ? n.toString().substring(0, 15) + "..."
    : n.toString();
};

export const getActive = (path: string, pathname: string): boolean => {
  return path ? !!matchPath({ path, end: false }, pathname) : false;
};

export const getActiveItem = (path: string, pathname: string): boolean => {
  const arr = pathname.split("/");
  return arr[arr.length - 1] === path.trim();
};

export const getComponentActive = (component: JSX.Element): boolean => {
  return component ? true : false;
};

export const isAllActive = (arr: { [key: string]: boolean }) =>
  Object.values(arr).every((v) => v === true);

export const isAllArrayDisabled = (arr: { [key: string]: any[] }) =>
  Object.values(arr).every((value) => value.length < 1);

export const convertTimestamp = (timestamp: number): [string, string] => {
  const dateObj = new Date(timestamp * 1000);
  const dateStr = dateObj.toISOString().substr(0, 10);
  const timeStr = dateObj.toTimeString().substr(0, 8);
  return [dateStr, timeStr];
};
export const formatDate = (unixTimestamp: number): string => {
  if (!Number.isFinite(unixTimestamp)) {
    return "";
  }
  const date = new Date(unixTimestamp * 1000);
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.format(date);
  const day = date.getDate();
  const suffix = getNumberSuffix(day);
  const year = date.getFullYear();
  return formattedDate.replace(/\d{1,2}/, `$&${suffix}`);
};

export const formatTimestampWithTimezone = (date: number) => {
  return format(new Date(date * 1000), "MMMM dd, yyyy, hh:mm:ss a 'UTC' xxx");
};

export const getNumberSuffix = (day: number): string => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function hexToRgba(hexCode: string, opacity = 1) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexCode) === false) {
    throw new Error("Bad Hex");
  }

  let hex = hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity < 0 || opacity >= 1) {
    opacity = 1;
  }
  return `rgba(${r},${g},${b},${opacity})`;
}

export const replaceCloudfront = (url: string) => {
  if (!url) {
    return url;
  }

  return url.replace(
    "https://d3ssfzdknds1vv.cloudfront.net",
    "https://staging-givepayments-media-upload.s3.us-west-1.amazonaws.com",
  );
};

export const toUTCDateFormat = (date: number) => {
  const updatedDate = new Date(date * 1000);

  return format(
    addMinutes(updatedDate, updatedDate.getTimezoneOffset()),
    "PPpp 'UTC'",
  );
};

type Image = {
  id: number;
  index: number;
  URL: string;
  label: string;
  createdAt: number;
};

export const convertImagesForViewer = (images: Image[], size = "original") =>
  images?.map((image: Image, index: number) => {
    // server is taking some time(around 10-30s) to scale image to small size(so fetching  /small or /thumb gives error)
    // so for recently uploaded image -> show original one
    const isUploadedRecently =
      image.createdAt + 30 > new Date().getTime() / 1000;
    const urlPostfix = isUploadedRecently ? `/original` : `/${size}`;

    return {
      id: image.id,
      index: index,
      src: image.URL + urlPostfix,
      alt: image.label,
    };
  });

export function detectMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export const isMobile = detectMobile();

type Property<T> = {
  [key: string]: T;
};

export const createPropertyOnChange = (
  dirtyField: unknown,
  key: string,
  value: unknown | Property<unknown>,
) => {
  if (!dirtyField) {
    return {};
  }

  return { [key]: value };
};

export const progressPercentageValue = (
  currentCount: number,
  maxCount: number,
): number => {
  return Math.round((currentCount * 100) / maxCount);
};

export const formatCardNumber = (cardNumber: string) => {
  const cleanedNumber = cardNumber.replace(/\D/g, "");

  let formattedNumber = "";

  const cardType = getCardType(cleanedNumber);
  const currentValue = cleanedNumber;

  if (cardType === "AMEX") {
    formattedNumber = currentValue.replace(
      /^(\d{4})(\d{0,7})(\d{0,4}).*$/,
      function (_, group1, group2, group3) {
        if (group2 === "") {
          return currentValue;
        } else if (group3 === "") {
          return `${group1} ${group2}`;
        } else {
          return `${group1} ${group2} ${group3}`;
        }
      },
    );
  } else {
    formattedNumber = currentValue.replace(
      /^(\d{4})(\d{0,4})(\d{0,4})(\d{0,4}).*$/,
      function (_, group1, group2, group3, group4) {
        if (group2 === "") {
          return currentValue;
        } else if (group3 === "") {
          return `${group1} ${group2}`;
        } else if (group4 === "") {
          return `${group1} ${group2} ${group3}`;
        } else {
          return `${group1} ${group2} ${group3} ${group4}`;
        }
      },
    );
  }

  return {
    formattedNumber,
    cardType,
  };
};

function getCardType(cardNumber: string) {
  const amexPattern = /^3[47]/;
  const visaPattern = /^4/;
  const mastercardPattern = /^5[1-5]/;
  const discoverPattern =
    /^65[4-9]|64[4-9]|6011|(622(?:12|1[3-9]|[2-8]|9[01]|92[0-5]))/;

  if (amexPattern.test(cardNumber)) {
    return "AMEX";
  } else if (visaPattern.test(cardNumber)) {
    return "Visa";
  } else if (mastercardPattern.test(cardNumber)) {
    return "Mastercard";
  } else if (discoverPattern.test(cardNumber)) {
    return "Discover";
  } else {
    return "Other";
  }
}

export const getValueFromString = (value: string) => {
  const float = parseFloat(value.replaceAll(",", "")).toFixed(2);
  return float;
};

export function convertToString(input: number): string {
  return input.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const sanitizer = (str: string) =>
  str === "one_time" ? "One-Time" : capitalizeFirstLetter(str);

export const capitalizeCardType = (str: string) => {
  return str?.replace(/\b(\w+)\b/g, function (match) {
    return capitalizeFirstLetter(match);
  });
};

export function capitalizeEachWord(str: string, divider = " "): string {
  const words = str.split(divider);
  const capitalizedWords = words.map((word) => capitalizeFirstLetter(word));

  return capitalizedWords.join(divider);
}

export const formatTaxID = (value: string) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 3) return currentValue;
  if (cvLength < 11)
    return `${currentValue.slice(0, 2)}-${currentValue.slice(2, 10)}`;
};

export const partiallyMaskString = (
  input: string,
  visibleCharactersFromStart: number,
  visibleCharactersFromEnd: number,
  maskCharacter = "*",
): string => {
  if (
    visibleCharactersFromStart >= input.length ||
    visibleCharactersFromEnd >= input.length ||
    visibleCharactersFromStart + visibleCharactersFromEnd >= input.length
  ) {
    const vcfs = 2,
      vcfe = 2;
    const maskedCharacterCount = Math.max(input.length - vcfs - vcfe, 0);
    const maskedPart = maskCharacter.repeat(maskedCharacterCount);
    return input.slice(0, vcfs) + maskedPart + input.slice(-vcfe);
  }

  const visiblePartFromStart = input.slice(0, visibleCharactersFromStart);
  const visiblePartFromEnd = input.slice(-visibleCharactersFromEnd);

  const maskedCharacterCount =
    input.length - visibleCharactersFromStart - visibleCharactersFromEnd;
  const maskedPart = maskCharacter.repeat(maskedCharacterCount);

  return visiblePartFromStart + maskedPart + visiblePartFromEnd;
};

export const formatVariantValue = (value: string | number): number => {
  const newValue =
    typeof value === "string" ? getValueFromString(value) : value;
  const integerValue = `${newValue}`.replace(".", "");
  return parseInt(integerValue);
};

export const roundToNearestTenth = (x: number) => {
  // round up or down to the nearest tenth the number which decimal part contains hudrends or more
  //Example: 1.23 => 1.2; 1.27 => 1.3

  return Math.round(x * 10) / 10;
};

export function parseAmount(amount?: number | null | string) {
  if (!amount) return "0.00";

  if (typeof amount === "string" && isNaN(Number(amount))) return amount;

  return Number(amount).toLocaleString(AMOUNTS_REGION, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const parseAmountWithoutDecimals = (amount?: number | null | string) => {
  return parseAmount(amount)?.slice(0, -3);
};

export const sleep = (duration: number) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const encodeString = (description: string) =>
  btoa(decodeURI(encodeURIComponent(description)));

export const getSettledPromise = (promise: PromiseSettledResult<any>) => {
  return promise?.status === "fulfilled" ? promise?.value : null;
};

export function bytesToSize(bytes: number, decimals = 1) {
  let sizeString = "";
  if (!Number(bytes)) {
    sizeString = "0B";
  } else {
    const kbToBytes = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));
    sizeString = `${parseFloat(
      (bytes / Math.pow(kbToBytes, index)).toFixed(dm),
    )}${sizes[index]}`;
  }
  return {
    sizeString,
  };
}

export const formatFromUnixWithFormat = (date: number, format: string) =>
  moment(new Date(date * 1000)).format(format);

export const isValidObject = (obj: any) =>
  Object.values(obj).every((val) => val);

export function rgbaToHex(rgba: any, ignoreAlpha?: boolean) {
  // Extract the individual RGB values and alpha from the RGBA string
  const [r, g, b, a] = rgba.match(/\d+(\.\d+)?/g);

  // Convert the RGB values to hexadecimal
  const rHex = Math.round(parseFloat(r)).toString(16).padStart(2, "0");
  const gHex = Math.round(parseFloat(g)).toString(16).padStart(2, "0");
  const bHex = Math.round(parseFloat(b)).toString(16).padStart(2, "0");

  // Convert alpha to hexadecimal (if needed)
  const aHex = a
    ? Math.round(parseFloat(a) * 255)
        .toString(16)
        .padStart(2, "0")
    : "";

  // Construct the hexadecimal color value
  const hexColor = ignoreAlpha
    ? `#${rHex}${gHex}${bHex}`.toUpperCase()
    : `#${rHex}${gHex}${bHex}${aHex}`.toUpperCase();

  return hexColor;
}

export const getUrlExtension = (url?: string) =>
  url?.split(/[#?]/)?.[0]?.split(".")?.pop()?.trim() || "";

export const getSubString = (
  startChar: string,
  endChar: string,
  str: string,
) => {
  const startIndex = str?.indexOf(startChar);
  const endIndex = endChar
    ? str?.indexOf(endChar, startIndex + 1)
    : str?.length + 1;

  if (startIndex !== -1 && endIndex !== -1) {
    return str?.substring(startIndex + 1, endIndex);
  } else {
    return "";
  }
};

export const getIndefiniteArticle = (word: string) => {
  const vowels = ["a", "e", "i", "o", "u"];
  const firstLetter = word?.[0]?.toLowerCase();

  return vowels?.includes(firstLetter) ? "an" : "a";
};

export const normalizeBetweenTwoRanges = (
  value: number,
  oldRange: number[],
  newRange: number[],
) => {
  const [minOldValue, maxOldValue] = oldRange;
  const [minNewValue, maxNewValue] = newRange;
  return (
    minNewValue +
    ((value - minOldValue) * (maxNewValue - minNewValue)) /
      (maxOldValue - minOldValue)
  );
};

export const getNumberFromString = (text: string) => {
  const id = text.match(/\d+/);
  if (!id?.[0]) return null;

  const integer = parseInt(id[0]);
  if (Number.isNaN(integer)) return null;
  return integer;
};

export function capitalizeFirstLetter(str: string) {
  if (!str) return "";
  const lower = str.toLowerCase();
  return lower[0].toUpperCase() + lower.slice(1);
}

export function millisecondsToHHMMSS(
  milliseconds: number,
  isLongCooldownActive: boolean,
) {
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);

  if (isLongCooldownActive) {
    return [
      String(hours).padStart(2, "0"),
      String(minutes).padStart(2, "0"),
      String(seconds).padStart(2, "0"),
    ].join(":");
  }

  return [
    String(minutes).padStart(2, "0"),
    String(seconds).padStart(2, "0"),
  ].join(":");
}
