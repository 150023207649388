import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import { memo } from "react";
import GiveCollapsableComponent from "../businessOwners/GiveCollapsableComponent";
import { useGetBankFiles } from "@hooks/merchant-api/BankAccounts/useGetBankFiles";
import { parseDocumentsList } from "../components/DocumentsSection";
import BankAccountBody from "./GiveBankAccountItemBody";
import BankAccountHeader from "./GiveBankAccountItemHeader";
import useDeleteDocument from "../hooks/useDeleteDocument";
import { useQueryClient } from "react-query";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "../../constants";

interface Props {
  account: IMerchantBankAccount;
  onEdit?: (account: IMerchantBankAccount) => void;
  onOpenMenu?: (event: React.MouseEvent<HTMLElement>, id: number) => void;
  disabled?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  merchantId: number;
}

const GiveBankAccountItem = ({
  account,
  onOpenMenu,
  onEdit,
  isFirst,
  isLast,
  merchantId,
}: Props) => {
  const queryClient = useQueryClient();
  const { deleteDocument } = useDeleteDocument();
  const handleDeleteDocument = (fileName: string, fileId: number) => {
    deleteDocument(merchantId, { id: fileId, fileName }, () =>
      queryClient.refetchQueries(
        MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET_BANK_FILES,
      ),
    );
  };
  const { data } = useGetBankFiles({
    bankAccountId: account?.id,
    enabled: !!account?.id && !!merchantId,
    merchantId,
  });
  const parsedDocuments =
    data?.data?.length > 0
      ? parseDocumentsList({
          documents: data.data,
          id: merchantId,
          handleDeleteDocument: handleDeleteDocument,
        })
      : [];
  return (
    <GiveCollapsableComponent
      isFirst={isFirst}
      isLast={isLast}
      title={
        <BankAccountHeader
          accountName={account?.name}
          status={account?.status}
          isPlaid={account?.plaid}
          onOpenMenu={onOpenMenu}
          accountId={account.id}
        />
      }
      withExpandIcon={true}
    >
      <BankAccountBody
        account={account}
        onEdit={onEdit}
        documents={parsedDocuments}
      />
    </GiveCollapsableComponent>
  );
};

export default memo(GiveBankAccountItem);
