import useHighlightWord from "@common/FilePreview/hooks/useHighlightWord";
import useInputFieldFocusListener from "@common/FilePreview/hooks/useInputFieldFocusListener";
import { Stack } from "@mui/material";
import { CaretDown, CaretUp, MagnifyingGlass, X } from "@phosphor-icons/react";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { debounce } from "lodash";
import React, { useRef, useState } from "react";

type SearchSubHeaderPropsV2 = {
  onClose: () => void;
  onSearch: (newValue: string) => void;
  highlights: NodeListOf<HTMLElement> | null;
  onFocus: (isFocused: boolean) => void;
  isDisabled?: boolean;
};

const SearchSubHeaderV2 = ({
  onClose,
  onSearch,
  highlights,
  onFocus,
  isDisabled,
}: SearchSubHeaderPropsV2) => {
  const [value, setValue] = useState<string>("");
  const { highlightIndex, onNext, onPrev } = useHighlightWord(highlights);
  const theme = useAppTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedSearch = useRef(
    debounce((value: string) => onSearch(value), 200),
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedSearch.current.cancel();
    debouncedSearch.current(newValue);
  };

  useInputFieldFocusListener({ inputRef, onFocus, autofocus: true });

  const highlightsTrackerProps = {
    visible: !!value,
    current: highlights?.length ? highlightIndex + 1 : 0,
    total: highlights?.length || 0,
  };

  return (
    <Container
      onClick={(event) => event.stopPropagation()}
      data-testid="search-container"
    >
      <GiveInput
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        placeholder="Find in document"
        rightContent={<HighlightTracker {...highlightsTrackerProps} />}
        leftContent={
          <MagnifyingGlass
            size={20}
            color={theme.palette.text?.["secondary"]}
          />
        }
        autoFocus
      />
      <Stack direction="row" gap="4px" alignItems="center">
        <GiveIconButton
          Icon={CaretDown}
          size="small"
          variant="ghost"
          onClick={onPrev}
          disabled={isDisabled}
        />
        <GiveIconButton
          Icon={CaretUp}
          size="small"
          variant="ghost"
          onClick={onNext}
          disabled={isDisabled}
        />
      </Stack>
      <GiveIconButton Icon={X} size="small" variant="ghost" onClick={onClose} />
    </Container>
  );
};

export default SearchSubHeaderV2;

const Container = styled(Stack)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: "16px",
  zIndex: 1050,
  padding: "8px",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  borderRadius: "16px",
  backgroundColor: theme.palette.surface?.["secondary-transparent"],
  backdropFilter: "blur(15px)",
  WebkitBackdropFilter: "blur(15px)",

  "& .MuiTextField-root": {
    width: "270px",
    height: "48px",
    "& .MuiInputAdornment-positionEnd": {
      width: "35px",
    },
  },

  [theme.breakpoints.down("sm")]: {
    right: "8px",
    "& .MuiTextField-root": {
      width: "200px",
    },
  },
}));

const HighlightTracker = ({
  visible,
  current,
  total,
}: {
  visible: boolean;
  current: number;
  total: number;
}) => {
  if (!visible) return <></>;
  return (
    <GiveText variant="bodyS" color="secondary">
      {current}/{total}
    </GiveText>
  );
};
