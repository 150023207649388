import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { ThemeWrapper, styled } from "@theme/v2/Provider";
import { BaseMainContainer } from "./components/BaseMainContainer";
import { MobileLeftPanelMainHeader } from "./components/MobileLeftPanelMainHeader";
import { usePayBuilderContext } from "./provider/PayBuilderContext";
import { usePayBuilderForm } from "./provider/PayBuilderFormProvider";
import PreviewWrapper from "./components/PreviewWrapper";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { getTextColorsBasedOnBackground } from "./utils";
import useCombineThemes from "@theme/v2/hooks/useCombineThemes";
import { rgbToHex } from "@mui/material";
import ProductPreviewView from "./components/ProductPreviewView";
import CheckoutFormPreview from "./Checkout/CheckoutFormPreview";

function FormPreview() {
  const { isMobileView, isTabletView, isSmallDesktop } = useCustomThemeV2();

  const isMobileOrTablet = isTabletView || isMobileView;
  const { previewStep } = usePayBuilderContext();
  const { methods } = usePayBuilderForm();

  const { isMasqueradeMode } = useMasqueradeReducer();
  const PREVIEW_WRAPPER_TEXT =
    "As you create your form, this preview will show how it will appear to others";
  return (
    <Container
      isMasqueradeMode={isMasqueradeMode}
      xs={isMobileView ? 12 : undefined}
      sm={isTabletView ? 12 : undefined}
      md={isSmallDesktop ? 8 : undefined}
      lg={8}
      item
    >
      {isMobileOrTablet && <MobileLeftPanelMainHeader />}

      <PreviewWrapper
        background={methods.watch().Style.background}
        previewText={PREVIEW_WRAPPER_TEXT}
      >
        <ActiveViewWithCustomTheme previewStep={previewStep} />
      </PreviewWrapper>
    </Container>
  );
}
type ContainerProps = {
  isMasqueradeMode?: boolean;
};

const ActiveView = ({
  type,
}: {
  type: "ProductPreviewView" | "CheckoutFormPreview";
}) => {
  switch (type) {
    case "ProductPreviewView":
      return <ProductPreviewView />;
    case "CheckoutFormPreview":
      return <CheckoutFormPreview />;
    default:
      return null;
  }
};

const ActiveViewWithCustomTheme = ({
  previewStep,
}: {
  previewStep: "ProductPreviewView" | "CheckoutFormPreview";
}) => {
  //The same has to be done for Cutomer view(public payment form)
  const { methods } = usePayBuilderForm();
  const { luminance } = getTextColorsBasedOnBackground(
    rgbToHex(methods.watch().Style.background).toUpperCase(),
  );

  const combineThemes = useCombineThemes(
    luminance < 0.5 ? "dark" : "light",
    false,
  );

  return (
    <ThemeWrapper combineThemesIOC={combineThemes}>
      <ActiveView type={previewStep} />
    </ThemeWrapper>
  );
};

export const DarkWithCustomTheme = ({ children }: { children: any }) => {
  //The same has to be done for Cutomer view(public payment form)
  const { methods } = usePayBuilderForm();
  const { luminance } = getTextColorsBasedOnBackground(
    rgbToHex(methods.watch().Style.background).toUpperCase(),
  );

  const combineThemes = useCombineThemes(
    luminance < 0.5 ? "dark" : "light",
    false,
  );

  return (
    <ThemeWrapper combineThemesIOC={combineThemes}>{children}</ThemeWrapper>
  );
};

const Container = styled(BaseMainContainer)<ContainerProps>(
  ({ theme, isMasqueradeMode }) => ({
    width: "100%",
    maxHeight: "100%",
    height: "100vh",
    overflowY: "auto",
    alignItems: "center",
    paddingTop: isMasqueradeMode ? "35px" : undefined,
    maxWidth: "100%",

    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  }),
);

export default FormPreview;
