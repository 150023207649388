export const getAnimationConfig = (height: number) => {
  return {
    x: 0,
    y: 0,
    opacity: 1,
    height,
    from: {
      opacity: 0,
      transform: `translateY(15px)`,
      height: 0,
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
      height,
    },
    config: { mass: 1, tension: 200, friction: 50 },
    delay: 1,
  };
};
