import { useDynamicTheme } from "@theme/hooks/useDynamicTheme";
import { useEffect, useState } from "react";
import { QueryKey, useQueryClient } from "react-query";

export const useCachedList = <T>(queryKey: QueryKey, enabledCache = false) => {
  const client = useQueryClient();
  const { isWideView } = useDynamicTheme();
  const shouldEnabledCache = enabledCache || !isWideView;

  const [allData, setAllData] = useState<T[]>([]);

  const updateData = () => {
    const caches = client.getQueryCache().findAll(queryKey);
    const newData = caches.reduce((acc, v) => {
      if (v?.state?.data) {
        const iter = (v.state.data as any)?.data ?? [];
        return [...acc, ...(iter as [])];
      }
      return acc;
    }, []);
    setAllData(newData);
  };

  useEffect(() => {
    if (shouldEnabledCache) {
      const unsubscribe = client.getQueryCache().subscribe(updateData);

      return () => {
        unsubscribe();
      };
    }
  }, [client, shouldEnabledCache]);

  const invalidateCache = () => {
    client.removeQueries([queryKey]);
  };

  return { allData, invalidateCache };
};
