import { Text } from "@common/Text";
import { Stack, Box } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { PageInputProps } from "./types";
import PageInput from "./PageInput";

const desktopStyle = {
  color: {
    primary: palette.black[100],
    secondary: palette.gray[300],
  },
  lineHeight: "14px",
  fontSize: "14px",
};

const mobileStyle = {
  color: {
    primary: palette.neutral.white,
    secondary: palette.gray[100],
  },
  lineHeight: "16px",
  fontSize: "16px",
};

const getStyle = (isDesktop: boolean) =>
  isDesktop ? desktopStyle : mobileStyle;

const PaginationBar = (props: Omit<PageInputProps, "style">) => {
  const { isDesktopView } = useCustomTheme();
  const { numPages } = props;

  const style = getStyle(isDesktopView);
  const { color, ...rest } = style;

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      paddingBlock="12px"
      data-testid="preview-paginator"
    >
      <Text color={color["primary"]} {...rest}>
        Page
      </Text>
      <Box sx={{ display: "inline-flex", alignItems: "center", gap: "4px" }}>
        <PageInput {...props} style={style} />
        <Text color={color["primary"]} {...rest}>
          /
        </Text>
        <Text color={color["secondary"]} {...rest} paddingInline="6px">
          {numPages || "-"}
        </Text>
      </Box>
    </Stack>
  );
};

export default PaginationBar;
