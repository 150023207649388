import ProductHero from "./ProductHero";
import FundraiserHero from "./FundraiserHero";
import useCheckFormType from "../../hooks/useCheckFormType";
import React from "react";

function HeroSelector({
  isPreview,
  ADBContent,
}: {
  isPreview?: boolean;
  ADBContent?: React.ReactElement;
}) {
  const { isFundraiser } = useCheckFormType();

  if (isFundraiser)
    return <FundraiserHero isPreview={isPreview} ADBContent={ADBContent} />;

  return <ProductHero />;
}

export default HeroSelector;
