import { BoxProps } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/material";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { useMemo } from "react";

interface Props {
  handleClick?: () => void;
  disabled?: boolean;
  score: number;
  pendingScore?: number;
  simple?: boolean;
}

export default function CircularScore({
  handleClick,
  disabled,
  score,
  pendingScore,
  simple = false,
}: Props) {
  const { palette } = useAppTheme();

  const colors = useMemo(() => {
    const isLowScore = !score || score < 80;
    return {
      background: palette.surface?.tertiary,
      active: isLowScore
        ? simple
          ? palette.icon?.["icon-secondary"]
          : palette.icon?.["icon-primary"]
        : palette.primitive?.success[50],
      circle: isLowScore
        ? palette.icon?.["icon-secondary"]
        : palette.icon?.["remain-light"],
      pending: isLowScore ? palette.primitive?.neutral[40] : "transparent",
    };
  }, [score]);

  const renderCircularProgress = (
    value: number | undefined,
    color: string | undefined,
    zIndex: number,
  ) => (
    <CircularProgress
      variant="determinate"
      value={value}
      size={24}
      thickness={7}
      sx={{
        color,
        zIndex,
        position: "absolute",
        "& .MuiCircularProgress-circle": {
          strokeLinecap: "round",
        },
      }}
    />
  );
  return (
    <CircleContainer onClick={handleClick} disabled={disabled}>
      {renderCircularProgress(100, colors.background, 1)}
      {renderCircularProgress(score, colors.active, 2)}
      {!!pendingScore &&
        pendingScore > 0 &&
        renderCircularProgress(score + pendingScore, colors.pending, 1)}
      {!simple && <ProgressCircle bg={colors.circle} top="4px" zIndex={2} />}
    </CircleContainer>
  );
}

const ProgressCircle = styled(Box)<
  BoxProps & {
    bg: string | undefined;
    right?: string;
    top?: string;
    zIndex?: number;
  }
>(({ bg, top = "11px", zIndex }) => ({
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  background: bg || "#E9E9E9",
  position: "absolute",

  top,
  ...(zIndex && { zIndex }),
  maxWidth: "100%",
  right: "calc(100% - 6px)",
}));

const CircleContainer = styled(Box)<{ disabled?: boolean }>(({ disabled }) => ({
  position: "relative",
  width: "24px",
  height: "24px",
  paddingTop: "1px",
  ...(disabled && { pointerEvents: "none" }),
}));
