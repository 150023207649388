import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TMerchantFees } from "../data.types";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import { Button } from "@common/Button";
import { useEffect, useMemo } from "react";
import { useUpdateMerchantInfo } from "../hooks/useUpdateMerchantInfo";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import { RHFCustomIntegerInput } from "@common/CustomIntegerInput";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { getFeesValidationSchema } from "@utils/validation.helpers";
import { calculateFeePerTransaction } from "../components/MerchantFees";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

type FormInputs = {
  credit_card_fee: string;
  amex_credit_card_fee: string;
  debit_card_fee: string;
};

interface ModalProps {
  id: number;
  data: TMerchantFees;
}

const EditFees = NiceModal.create(({ id, data }: ModalProps) => {
  const modal = useModal();
  const open = modal.visible;
  const { isMobileView } = useCustomTheme();

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const { handleSubmit, isLoading } = useUpdateMerchantInfo(id);

  const schema = getFeesValidationSchema(-100, 1000);

  const defaultValues = {
    credit_card_fee: "",
    amex_credit_card_fee: "",
    debit_card_fee: "",
  };

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    formState: { isDirty },
  } = methods;
  const values = watch();

  useEffect(() => {
    reset({
      credit_card_fee: data.creditCardFee.value.toString(),
      amex_credit_card_fee: data.amexCreditCardFee.value.toString(),
      debit_card_fee: data.debitCardFee.value.toString(),
    });
  }, [id, data]);

  const handleCancel = () => {
    reset();
    modal.hide();
  };

  const onSubmit: SubmitHandler<FormInputs> = (data: any) => {
    handleSubmit("fees", data, handleCancel);
  };

  const creditCardFeePerTransaction = useMemo(() => {
    return calculateFeePerTransaction({
      baselineFixed: data.creditCardFee.baselineFixed,
      baselinePercent: data.creditCardFee.baselinePercent,
      adjustmentFixed: data.creditCardFee.adjustmentFixed,
      adjustmentPercent: +values.credit_card_fee, //Using this value so the fee is updated dynamically
    });
  }, [values.credit_card_fee, data.creditCardFee]);

  const debitCardFeePerTransaction = useMemo(() => {
    return calculateFeePerTransaction({
      baselineFixed: data.debitCardFee.baselineFixed,
      baselinePercent: data.debitCardFee.baselinePercent,
      adjustmentFixed: data.debitCardFee.adjustmentFixed,
      adjustmentPercent: +values.debit_card_fee, //Using this value so the fee is updated dynamically
    });
  }, [values.debit_card_fee, data.debitCardFee]);

  const amexCreditCardFeePerTransaction = useMemo(() => {
    return calculateFeePerTransaction({
      baselineFixed: data.amexCreditCardFee.baselineFixed,
      baselinePercent: data.amexCreditCardFee.baselinePercent,
      adjustmentFixed: data.amexCreditCardFee.adjustmentFixed,
      adjustmentPercent: +values.amex_credit_card_fee, //Using this value so the fee is updated dynamically
    });
  }, [values.amex_credit_card_fee, data.amexCreditCardFee]);

  const inputList = [
    {
      input: (
        <FeeInput
          label="Credit Card Fee"
          name="credit_card_fee"
          feePerTransaction={creditCardFeePerTransaction}
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      input: (
        <FeeInput
          label="Debit Card Fee"
          name="debit_card_fee"
          feePerTransaction={debitCardFeePerTransaction}
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      input: (
        <FeeInput
          label="AMEX Credit Card Fee"
          name="amex_credit_card_fee"
          feePerTransaction={amexCreditCardFeePerTransaction}
          disabled={!isEditAllowed}
        />
      ),
    },
  ];

  return (
    <EditMerchantBaseModal
      title="Edit Merchant Fees"
      open={open}
      handleCancel={handleCancel}
      actionsSx={{
        justifyContent: "flex-end",
      }}
      actions={
        <>
          <Button
            size="medium"
            background="tertiary"
            onClick={handleCancel}
            disabled={isLoading}
            sx={{ padding: "8px 24px" }}
          >
            {isMobileView ? "Discard changes" : "Cancel"}
          </Button>
          <Button
            size="medium"
            background="primary"
            type="submit"
            form="edit-fees"
            disabled={!isDirty || isLoading}
            sx={{ padding: "8px 24px" }}
          >
            {isMobileView ? "Save" : "Save Changes"}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          id="edit-fees"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            {inputList.map(({ input }, index) => (
              <Grid key={index} item xs={12}>
                <FadeUpWrapper delay={100 * (index + 1)}>{input}</FadeUpWrapper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </FormProvider>
    </EditMerchantBaseModal>
  );
});

export const FeeInput = ({
  label,
  name,
  feePerTransaction,
  disabled = false,
}: {
  label: string;
  name: string;
  feePerTransaction: string;
  disabled?: boolean;
}) => {
  return (
    <Stack direction="column" gap={1}>
      <Text color={palette.black[100]} fontWeight="book" lineHeight="16.8px">
        {label}
      </Text>
      <Box>
        <RHFCustomIntegerInput
          name={name}
          placeholder="Enter Percent Fee in BPS"
          endIcon="BPS"
          width="100%"
          disabled={disabled}
          max={9999}
          sx={{
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              minHeight: "48px",
            },
          }}
        />
        <Text
          padding="8px 0"
          color={palette.black[100]}
          lineHeight="16.8px"
          fontWeight="book"
        >
          <Box
            component="span"
            fontWeight={300}
            color={palette.gray[300]}
            marginRight="8px"
          >
            Fee per transaction
          </Box>
          {feePerTransaction} / transaction
        </Text>
      </Box>
    </Stack>
  );
};

export default EditFees;
