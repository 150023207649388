import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { BaseMainContainer } from "@sections/PayBuilder/components/BaseMainContainer";
import { StackRow } from "@sections/PayBuilder/components/StackRow";
import Stepper from "@sections/PayBuilder/components/Stepper";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";
import PanelActions from "./PanelActions";
import StyleWarningMessage from "./StyleWarningMessage";
import NiceModal from "@ebay/nice-modal-react";
import { SAVE_AND_CLOSE_MODAL } from "modals/modal_names";

const PaymentFormModalContent: FC<{
  id: string;
  handleClose?: (closeDouble?: boolean) => void;
  isFromADB: boolean;
}> = ({ id, handleClose, isFromADB }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const saveId = location.state?.id;
  useEffect(() => {
    if (Number(id) === Number(saveId)) {
      return;
    } else {
      navigate(location.pathname, {
        state: id ? { id } : undefined,
        replace: true,
      });
    }
  }, [saveId, id]);

  const { isMobileView } = useCustomThemeV2();
  const { activeItem, activeStepIndex, formStep } = usePayBuilderContext();
  const { isPaymentFormInfosLoading, mutate } = usePayBuilderForm();

  const propsMapper = {
    isEdit: true,
    isFromADB,
  };

  const onHandleClose = () => {
    NiceModal.hide(SAVE_AND_CLOSE_MODAL);
    handleClose?.(false);
  };
  const ActiveView = formStep[activeStepIndex];

  const handleClosePanel = () => {
    NiceModal.show(SAVE_AND_CLOSE_MODAL, {
      mutate: ({ handleSuccessCB }: { handleSuccessCB?: () => void }) => {
        mutate({
          handleSuccessCB: () => {
            onHandleClose();
            handleSuccessCB?.();
          },
          isSaveDb: true,
        });
      },
      customClose: onHandleClose,
    });
  };
  return (
    <Container xs={12} sm={12} item>
      {isPaymentFormInfosLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ModalTitle
            padding="16px"
            title="Edit Payment Form"
            showDivider
            onClose={isMobileView ? handleClosePanel : undefined}
          />
          {isFromADB && <StyleWarningMessage />}
          <StackRow sx={isMobileView ? { paddingBottom: 0 } : {}}>
            <Stepper isLaunchVisible={false} />
          </StackRow>
          {isMobileView && (
            <StackRow sx={{ paddingTop: 0, paddingLeft: "22px" }}>
              {<GiveText>{activeItem.label}</GiveText>}
            </StackRow>
          )}
          <StackRow sx={{ overflowY: "auto", flexGrow: 1 }}>
            <ActiveView {...propsMapper} />
          </StackRow>

          <StackRow
            container
            alignItems="center"
            gap={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",

              borderTop: "1px solid #E5E5E3",
            }}
          >
            <PanelActions handleClose={handleClose} />
          </StackRow>
        </>
      )}
    </Container>
  );
};

const Container = styled(BaseMainContainer)(({ theme }) => {
  return {
    flex: 1,
    borderRight: `1px solid ${theme.palette.border?.primary} `,
    height: "100%",
  };
});

export default PaymentFormModalContent;
