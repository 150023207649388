import GiveBaseModal from "@shared/modals/GiveBaseModal";
import GiveText from "@shared/Text/GiveText";
import { useState } from "react";
import { Modals_types } from "./modal.types";
import DefaultMode from "./DefaultMode";
import UploadSignature from "./UploadSignature";
import Sign from "./Sign";
import Generate from "./Generate";
import { Box, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { isEmpty } from "lodash";
import { styled, useAppTheme } from "@theme/v2/Provider";
import moment from "moment";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { DownloadSimple, Eye, Trash } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import useDocumentPreviewV2 from "@shared/FilePreview/hooks/useDocumentPreviewV2";

interface Props {
  open: boolean;
  onClose: () => void;
  uploadSignature: (e: File) => void;
}

function SignAgreementModal({ open, onClose, uploadSignature }: Props) {
  const [modalOpened, setModalOpen] = useState<Modals_types>("default");
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const { title, subtitle } = modalInfo[modalOpened];

  const Components = {
    default: <DefaultMode setModalOpen={setModalOpen} />,
    upload: <UploadSignature setFileToUpload={setFileToUpload} />,
    sign: <Sign setFileToUpload={setFileToUpload} />,
    generate: <Generate setFileToUpload={setFileToUpload} />,
  };
  const handleRemoveFile = () => setFileToUpload(null);
  const handleReset = () => {
    setModalOpen("default");
    handleRemoveFile();
    onClose();
  };

  const handleUpload = () => {
    if (fileToUpload === null) return;
    uploadSignature(fileToUpload);
    handleReset();
  };

  return (
    <GiveBaseModal
      open={open}
      title={title}
      onClose={handleReset}
      showFooter={modalOpened !== "default"}
      width="731px"
      buttons={
        <Stack alignItems="center" gap="32px" flexDirection="row">
          <GiveButton
            onClick={() => {
              setFileToUpload(null);
              setModalOpen("default");
            }}
            size="large"
            variant="ghost"
            label="Back"
          />
          <GiveButton
            disabled={fileToUpload === null}
            size="large"
            variant="filled"
            label="Sign"
            onClick={handleUpload}
            sx={{
              border: "none",
            }}
          />
        </Stack>
      }
    >
      <>
        <GiveText variant="bodyS" color="secondary">
          {subtitle}
        </GiveText>
        {Components[modalOpened]}
        {modalOpened === "upload" && !isEmpty(fileToUpload) && (
          <DocumentSection
            fileToUpload={fileToUpload}
            handleRemoveFile={handleRemoveFile}
          />
        )}
      </>
    </GiveBaseModal>
  );
}

export default SignAgreementModal;

const DocumentSection = ({
  fileToUpload,
  handleRemoveFile,
}: {
  fileToUpload: File;
  handleRemoveFile: () => void;
}) => {
  const { globalName } = useAppSelector(selectUser);
  const { handlePreview } = useDocumentPreviewV2({});
  const { palette } = useAppTheme();
  const handleFileDownload = () => {
    if (fileToUpload) {
      const url = URL.createObjectURL(fileToUpload);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileToUpload.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      alert("No file uploaded to download!");
    }
  };

  const Icons = [
    {
      Icon: Eye,
      onClick: () => {
        handlePreview({
          id: 0,
          fileName: fileToUpload.name,
          fileURL: URL.createObjectURL(fileToUpload),
          fileType: fileToUpload.type,
          tag: "",
        });
      },
      color: palette.icon?.["icon-primary"],
    },
    {
      Icon: DownloadSimple,
      onClick: handleFileDownload,
      color: palette.icon?.["icon-primary"],
    },
    {
      Icon: Trash,
      onClick: handleRemoveFile,
      color: palette.primitive?.error[50],
    },
  ];
  return (
    <CardContainer
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Stack mb="8px" gap="12px" flexDirection="row" alignItems="center">
          <GiveText fontWeight={400} color="primary" variant="bodyS">
            {fileToUpload?.name}
          </GiveText>
          <ChipText variant="bodyXS" color="secondary">
            Merchant Upload
          </ChipText>
        </Stack>
        <GiveText variant="bodyXS" color="primary">
          by {globalName?.firstName} {globalName.lastName}{" "}
          <GiveText variant="bodyXS" color="secondary" component="span">
            {moment().format("MMMM D YYYY, HH:mm")}
          </GiveText>{" "}
        </GiveText>
      </Box>
      <Stack gap="6px" alignItems="center" flexDirection="row">
        {Icons.map(({ Icon, onClick, color }, idx) => {
          return (
            <StyledButton
              onClick={onClick}
              size="medium"
              Icon={Icon}
              color={color}
              key={idx}
            />
          );
        })}
      </Stack>
    </CardContainer>
  );
};

const CardContainer = styled(Stack)(({ theme }) => ({
  padding: "12px",
  border: `1px solid ${theme.palette.border?.secondary}`,
  marginTop: "20px",
  borderRadius: "12px",
}));

const StyledButton = styled(GiveIconButton)(({ theme }) => ({
  background: "transparent",
}));

const ChipText = styled(GiveText)(({ theme }) => ({
  padding: "6px 12px",
  backgroundColor: theme.palette.surface?.secondary,
  borderRadius: "4px",
}));
const modalInfo = {
  default: {
    title: "Sign Agreement",
    subtitle: "We need your signature to finalize the agreement.",
  },
  upload: {
    title: "Upload Signature",
    subtitle:
      "Submit your signature in either JPG or PNG format for seamless and secure document authentication.",
  },
  sign: {
    title: "Sign Agreement",
    subtitle:
      "Easily endorse agreements by simply using your mouse or trackpad to sign.",
  },
  generate: {
    title: "Generate",
    subtitle:
      "Craft your signature effortlessly using your name for a personalized and distinctive touch.",
  },
};
