import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { showMessage } from "@common/Toast";
import { MAX_TEXT_LENGTH } from "@constants/constants";
import RESOURCE_BASE, {
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { Trash } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import HFGiveCustomAmount from "@shared/HFInputs/HFGiveCustomAmount/HFGiveCustomAmount";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import HFGiveIntegerInput from "@shared/HFInputs/HFGiveInput/HFGiveIntegerInput";
import HFGiveSelect from "@shared/HFInputs/HFGiveSelect/HFGiveSelect";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import GiveMotionWrapper from "@shared/Motion/GiveMotionWrapper";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { PaymentRecurringType, ProductItemType } from "../types";
import ImageBox from "../components/ImageBox";
import { MediaItem } from "../provider/provider.type";
import useCheckFormType from "../components/hooks/useCheckFormType";

const defaultValues = {
  thumbnail: "",
  amount: "",
  title: "",
  description: "",
  in_stock: null,
  display: false,
  paymentType: "once",
};

const AMOUNT_ERROR_MESSAGE = "Must be above 1 USD";

type FormInputs = {
  thumbnail: string;
  amount: string;
  title: string;
  in_stock?: number | null;
  display?: boolean;
  description: string | null;
  paymentType: string;
};

type Props = {
  item?: ProductItemType;
  hasUniqueTitle?: (title: string, id?: string) => boolean;
  onClose?: (item?: ProductItemType) => void;
  handleDelete: () => void;
};

const AddProductItemModal = NiceModal.create(
  ({ item, hasUniqueTitle, onClose, handleDelete }: Props) => {
    const { open, onClose: hideModal } = useNiceModal();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isMobileView } = useCustomThemeV2();
    const { isEvent } = useCheckFormType();
    const isUpdateAllowed = useAccessControl({
      resource: composePermission(
        RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.PRODUCT,
        RESOURCE_BASE.AMOUNT,
      ),
      operation: OPERATIONS.UPDATE,
    });

    const canEdit = (!!item && isUpdateAllowed) || !item;

    const isAddImageAllowed = useAccessControl({
      resource: RESOURCE_BASE.MEDIA_ITEM,
      operation: OPERATIONS.CREATE,
      withPortal: true,
    });

    const schema = Yup.object().shape({
      title: Yup.string()
        .trim()
        .max(100, "Name can not contain more than 100 characters")
        .required("This field is required"),
      amount: Yup.string()
        .required(AMOUNT_ERROR_MESSAGE)
        .test(AMOUNT_ERROR_MESSAGE, (value) => {
          if (!value) return false;
          const valueToNumber = parseFloat(value);
          if (Number.isNaN(valueToNumber)) return false;
          return valueToNumber >= 1;
        }),
      in_stock: Yup.number()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        }),
      display: Yup.boolean(),
      thumbnail: Yup.mixed<File | string>(),
      description: Yup.string()
        .nullable()
        .max(
          MAX_TEXT_LENGTH,
          `Description can not contain more than ${MAX_TEXT_LENGTH} characters`,
        ),
    });

    const methods = useForm<FormInputs>({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      setValue,
      reset,
      formState: { dirtyFields, isValid },
    } = methods;

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
      if (hasUniqueTitle && !hasUniqueTitle(data.title, item?.id)) {
        showMessage("Error", "An element with the same name already exists");
        return;
      }
      setIsLoading(true);

      const amount: ProductItemType = {
        id: item ? item.id : uniqueId(),
        title: dirtyFields.title || !item ? data.title : item.title,
        amount: dirtyFields.amount || !item ? data.amount : item.amount,
        description:
          dirtyFields.description || !item
            ? data.description ?? ""
            : item.description,
        thumbnail:
          dirtyFields.thumbnail || !item ? data?.thumbnail : item?.thumbnail,
        in_stock: data?.in_stock,
        paymentType: data?.paymentType as PaymentRecurringType,
        ...(!item && {
          display: true,
        }),
      };
      setIsLoading(false);
      hideModal();

      if (onClose) onClose(amount);
    };

    useEffect(() => {
      if (item) {
        reset({
          thumbnail: item?.thumbnail || "",
          amount: item.amount,
          in_stock: item.in_stock,
          title: item.title,
          description: item.description || "",
          paymentType: item.paymentType,
        });
      }

      if (!item) {
        reset(defaultValues);
      }
    }, [item, open]);
    const handleSelect = (item?: MediaItem) => {
      setValue("thumbnail", item?.URL || "", { shouldDirty: true });
    };
    const nodesList = [
      {
        node: (
          <WithTooltipWrapper
            hasTooltip={!isAddImageAllowed}
            tooltipProps={{
              show: !isAddImageAllowed,
              message: UPLOAD_DENY_MESSAGE,
            }}
          >
            <>
              <ImageBox
                imageUrl={methods.watch("thumbnail")}
                handleSelect={handleSelect}
              />
            </>
          </WithTooltipWrapper>
        ),
      },
      {
        node: (
          <HFGiveInput
            label="Name"
            name="title"
            placeholder="name"
            maxLength={100}
            disabled={!canEdit}
          />
        ),
      },
      {
        node: (
          <HFGiveCustomAmount
            name="amount"
            label="Price"
            placeholder="Price"
            currency="usd"
            disabled={!canEdit}
          />
        ),
        xs: isEvent ? 12 : 6,
      },
      {
        node: (
          <HFGiveSelect
            label="One Time or Recurring"
            placeholder="One Time or Recurring"
            name="paymentType"
            options={recurringOptions}
            useContextualMenu
            contextualMenuProps={
              !isMobileView
                ? {
                    color: "tertiary",
                    texture: "blurred",
                    menuWidth: 278,
                  }
                : {}
            }
            disabled={!canEdit}
          />
        ),
        xs: 6,
        hidden: isEvent,
      },
      {
        node: (
          <HFGiveInput
            label="Description (Optional)"
            name="description"
            placeholder="description"
            multiline
            rows={8}
            disabled={!canEdit}
            maxLength={MAX_TEXT_LENGTH}
            displayCounter
          />
        ),
      },
      {
        node: (
          <HFGiveIntegerInput
            label="Stock (Optional)"
            name="in_stock"
            placeholder="Stock"
            disabled={!canEdit}
            max={Number.MAX_SAFE_INTEGER}
          />
        ),
        animate: false,
      },
    ];

    const title = `${item ? "Edit" : "Add"} ${isEvent ? "Ticket" : "Item"}`;

    return (
      <GiveBaseModal
        open={open}
        title={title}
        width="640px"
        height="95%"
        onClose={hideModal}
        customDialogStyle={{
          zIndex: 1201,
          "& .MuiDialogActions-root .MuiButton-root": {
            width: "unset",
          },
        }}
        footerLeftContent={
          item && handleDelete ? (
            <GiveButton
              label="Delete"
              variant="ghost"
              color="destructive"
              size="large"
              startIcon={<Trash size={18} />}
              onClick={handleDelete}
            />
          ) : null
        }
        buttons={
          <Stack gap="12px" flexDirection="row">
            <GiveButton
              onClick={hideModal}
              label="Cancel"
              variant="ghost"
              size="large"
            />
            <GiveButton
              label={item ? "Save Changes" : "Add"}
              variant="filled"
              size="large"
              disabled={isLoading || !isValid}
              form="create-product-item"
              onClick={methods.handleSubmit(onSubmit)}
              sx={{ whiteSpace: "nowrap" }}
            />
          </Stack>
        }
      >
        <FormProvider {...methods}>
          <Grid
            container
            component="form"
            spacing={3}
            id="create-product-item"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {nodesList.map(({ node, xs, hidden, animate = true }, index) => (
              <Grid
                item
                xs={xs || 12}
                key={index}
                sx={{ ...(hidden && { display: "none" }) }}
              >
                {animate ? (
                  <GiveMotionWrapper delay={50 * (index + 1)}>
                    {node}
                  </GiveMotionWrapper>
                ) : (
                  node
                )}
              </Grid>
            ))}
          </Grid>
        </FormProvider>
      </GiveBaseModal>
    );
  },
);

export const recurringOptions = [
  { value: "once", label: "One Time" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "yearly", label: "Yearly" },
];

export default AddProductItemModal;
