import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import ImagesStacker from "features/Minibuilders/Conversations/utils/ImagesStacker";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import ChallengeLayout from "./ChallengeLayout";
import { TChallengeCategory } from "./types";
import { useGetChallenges } from "./useChallengesRepository";
import { iconMapper } from "./utils";
import { ChatsCircle } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { useAppDispatch } from "@redux/hooks";
import { setSearchQueryByKey } from "@redux/slices/search";
import { useEffect } from "react";

const ChallengesCategories = () => {
  const {
    setFirstPanelContent,
    setOpenChallenge,
    isLoadingUnderwriting,
    setIsLoadingUnderwriting,
    isOnboardingView,
  } = useMerchantSidePanelContext();
  const filter = isOnboardingView
    ? `filter=%3B(name:"merchant_onboarding")&`
    : "";
  const { data, isLoading } = useGetChallenges(filter);
  const { palette } = useAppTheme();
  const { openConversationHandler } = useConversationsModal();
  const dispatch = useAppDispatch();

  //if at this point we are still loading underwriting, set it to false
  useEffect(() => {
    if (!isLoading && isLoadingUnderwriting) setIsLoadingUnderwriting(false);
  }, [isLoading, isLoadingUnderwriting]);

  if (isLoading) return <></>;

  return (
    <Stack gap={1.5}>
      <GiveText
        fontSize="14px"
        sx={{
          color: palette.primitive?.neutral[60],
        }}
      >
        Challenge Categories
      </GiveText>
      <Stack gap={1}>
        {data.challengesCategoriesData?.data.map(
          (challenge: TChallengeCategory, id) => {
            const isOnboardingComplete =
              challenge.name === "merchant_onboarding" && !isOnboardingView;
            const total =
              (challenge.totalChallenges || 0) - (challenge.totalEmpty || 0);
            const isIncomplete =
              challenge.totalClosed !== total && !isOnboardingComplete;
            const status = isIncomplete ? "In Progress" : "Approved";
            return (
              <ChallengeLayout
                onClick={() => {
                  setOpenChallenge({
                    identifier: challenge.name,
                    displayName: challenge.displayName,
                  });
                  dispatch(
                    setSearchQueryByKey({
                      queryKey: "items_all_challenges",
                      params: {
                        value: "",
                      },
                    }),
                  );
                  setFirstPanelContent("challenges");
                }}
                icon={<>{iconMapper(challenge.name)}</>}
                attachConversations={
                  <Stack direction="row" alignItems="center">
                    <ImagesStacker data={[]} />
                    <GiveIconButton
                      Icon={ChatsCircle}
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        openConversationHandler({
                          id: 0,
                          name: challenge.displayName,
                          paths: [],
                        });
                      }}
                    />
                  </Stack>
                }
                main={
                  <Stack>
                    <GiveText
                      fontSize="14px"
                      sx={{
                        color: palette.primitive?.neutral[90],
                      }}
                    >
                      {challenge.displayName}
                    </GiveText>
                    <GiveText
                      fontSize="14px"
                      sx={{
                        color: isIncomplete
                          ? palette.primitive?.neutral[60]
                          : palette.primitive?.success[50],
                      }}
                    >
                      {challenge.totalClosed + "/" + total} Completed
                    </GiveText>
                  </Stack>
                }
                end={
                  <Box
                    sx={{
                      backgroundColor: !isIncomplete
                        ? palette.primitive?.success[25]
                        : palette.primitive?.neutral[10],
                      padding: "4px 8px",
                      borderRadius: "8px",
                    }}
                  >
                    <GiveText
                      fontSize="12px"
                      color={isIncomplete ? "primary" : "success1"}
                    >
                      {status}
                    </GiveText>
                  </Box>
                }
                key={id}
              />
            );
          },
        )}
      </Stack>
    </Stack>
  );
};

export default ChallengesCategories;
