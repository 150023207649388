import { CaretDown } from "@assets/icons";
import { RHFInput } from "@common/Input";
import Selector from "@common/TableFilters/Selector";
import { TRiskLevelName } from "@common/Tag/RiskStatusChip";
import { Text, TruncateText } from "@common/Text";
import useGetEnterpriseCategoryCodes from "@hooks/enterprise-api/merchants/useGetEnterpriseCategoryCodes";
import { InputAdornment, Stack } from "@mui/material";
import { palette } from "@palette";
import { capitalizeFirstLetter } from "@utils/index";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { TCategoriSelectorWithSearch, TCategory } from "./types";


const CategoriSelectorWithSearch = ({
  width,
  fullWidth = false,
  name,
  placeholder,
  label,
  providerId,
  isAcquirer,
  disabled,
}: TCategoriSelectorWithSearch) => {
  const { setValue,watch } = useFormContext();
  const [searchValue, setSearchValue] = useState("");
  const value = watch(name);
  

  const {
    data: fetchedCategories,
    isLoading
  } = useGetEnterpriseCategoryCodes({ id:providerId, searchKey:searchValue});

  const selectedCategory = useMemo(
    () =>
      fetchedCategories?.data?.find(
        (category: TCategory) => category?.categoryCodes?.code === value || category?.categoryCodes?.id === value,
      ),
    [fetchedCategories,value],
  );
  const selectedValue = selectedCategory?.categoryCodes.code ?`${selectedCategory?.categoryCodes.code} - ${selectedCategory?.categoryCodes.title}` : ""


  const debouncedSetSearchValue = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 300),
    [],
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const categoriesOptions = useMemo(() => {
    return fetchedCategories?.data?.map((category: TCategory) => ({
      id: category.categoryCodes.id,
      value: category.categoryCodes.id,
      label: `${category.categoryCodes.code} - ${category.categoryCodes.title}`,
      riskStatus: category.categoryCodes.merchantRiskStatus,
    }));
  }, [fetchedCategories]);

  const onChange = (value: any) => {
    setValue(name, value.value, {
      shouldDirty: true,
    });
  };
  return (
    <Selector
      options={categoriesOptions}
      value={value}
      searchedWord={selectedValue}
      loading={isLoading}
      handleChange={(val) => onChange(val)}
      handleSearch={debouncedSetSearchValue}
      width={width}
      isOpen={setIsOpen}
      multiSelect
      closeOnClick
      showApplyAction={false}
      fullWidth={fullWidth}
      renderCustomElement={(option) => {
        const {
          item: { label, riskStatus },
        } = option;
        return (
          <Stack padding="4px 8px" gap="4px">
            <TruncateText
              flex={1}
              height="40px"
              lineClamp={1}
              
            >
              {label}
            </TruncateText>
            <ItemDescription riskStatus={riskStatus as TRiskLevelName} />
          </Stack>
        );
      }}
      renderCustomParent={({ onClick }) => (
        <RHFInput
          value={selectedValue}
          disabled={(isAcquirer && !providerId) || disabled}
          onClick={onClick}
          placeholder={placeholder}
          name={name}
          label={label}
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment sx={{ cursor: "pointer" }} position="end">
                <CaretDown rotate={isOpen ? 180 : 0} />
              </InputAdornment>
            ),
          }}
        />
      )}
      searchBarProps={{
        placeholder: "Search",
        customDeleteIcon: <CustomDeleteIcon />,
      }}
    />
  );
};

export default CategoriSelectorWithSearch;

const CustomDeleteIcon = () => {
  return (
    <Text
      sx={{
        color: palette.accent[3],
        marginRight: "15px",
        fontFamily: "Give Whyte",
        fontSize: "14px",
        fontWeight: 350,
        lineHeight: "16.8px",
      }}
    >
      Clear
    </Text>
  );
};

export const ItemDescription = ({ riskStatus }: { riskStatus: TRiskLevelName }) => {
 
  if (!riskStatus ) {
    return;
  }

  const color = {
    normal:palette.tag.success.text,
    high:  palette.filled.orange,
    restricted:  palette.filled.red, 
  };


  return (
    <Text
      sx={{
        color: color[riskStatus],
        fontFamily: "Give Whyte",
        fontSize: "12px",
        fontWeight: 350,
        lineHeight: "14.4px",
      }}
    >
      {capitalizeFirstLetter(riskStatus)}
    </Text>
  );
};
