import WebsiteInput from "@common/BusinessProfileInputs/WebsiteInput";
import { RHFCheckbox } from "@common/Checkbox";
import { RHFInput, RHFTelInput } from "@common/Input";
import { generateNameLabelPlaceholder } from "@components/Signup/Forms/SignupOrganizationDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import { urlSchema } from "@utils/validation.helpers";
import { matchIsValidTel } from "mui-tel-input";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "../form.components";
import { TBusinessStepsCommons } from "./BusinessProfileSetup";
import { DynamicReturnType } from "./helpers/refineData";
import usePercentageUpdate from "./hooks/usePercentageUpdate";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";
import { SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";

type FormInputs = {
  name: string;
  servicePhoneNumber: string;
  purpose: string;
  websiteURL: string;
  serviceCountriesOutUSCanada: boolean;
  countriesServicedOutside: string;
  permalink: string;
};

interface IProps extends TBusinessStepsCommons {
  data: DynamicReturnType["enterpriseInfo"];
}

function EnterpriseInfo({
  submitHandler,
  handleBack,
  statusBar,
  updateStatusBar,
  data,
  isSubmitting,
}: IProps) {
  const methods = useForm<FormInputs>({
    mode: "onChange",
    resolver: yupResolver(enterpriseInfoSchema),
    defaultValues: data,
  });

  const {
    watch,
    handleSubmit,
    formState: { dirtyFields, isDirty },
  } = methods;
  const values = watch();

  usePercentageUpdate<FormInputs>(
    values,
    dirtyFields,
    enterpriseInfoSchema,
    updateStatusBar,
  );

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.ENTERPRISE,
    operation: OPERATIONS.UPDATE,
  });

  const displayName = data.class.name
    ? generateNameLabelPlaceholder(data.class.name)
    : "Provider";

  const nodeList: Array<{
    node: JSX.Element;
    hidden?: boolean;
  }> = [
    {
      node: (
        <RHFInput
          name="name"
          label={`${displayName} Name`}
          placeholder="The name of your provider"
          fullWidth
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      node: (
        <WebsiteInput
          fullWidth
          name="permalink"
          label="Permalink"
          inputPrefix="https://"
          inputSuffix=".givepayments.com"
          maxLength={SLUG_MAX_CHARACTER_LENGTH}
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      node: (
        <RHFTelInput
          label={`Provider Phone Number`}
          name="servicePhoneNumber"
          fullWidth
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      node: (
        <WebsiteInput
          name="websiteURL"
          label={"Website URL"}
          placeholder="example.com"
          inputPrefix="https://"
          disabled={!isEditAllowed}
          fullWidth
        />
      ),
    },
    {
      node: (
        <RHFInput
          name="purpose"
          label="Purpose & Mission"
          placeholder="Purpose & Mission"
          multiline
          rows={6}
          fullWidth
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      node: (
        <RHFCheckbox
          name="serviceCountriesOutUSCanada"
          label="My Business services countries outside of the USA/Canada."
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      node: (
        <RHFInput
          name="countriesServicedOutside"
          label="Countries Serviced Outside USA/Canada"
          placeholder="Please enter the countries serviced outside of USA/Canada."
          multiline
          rows={6}
          fullWidth
          disabled={!isEditAllowed}
        />
      ),
      hidden: !values?.serviceCountriesOutUSCanada,
    },
  ];

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    submitHandler("enterpriseInfo", data, {
      makeApiCall: isDirty,
      dirtyFields,
    });
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        flexGrow={1}
        id="enterprise-info-form"
        display="flex"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ProfileSetupFormContainer>
          <Stack
            direction="column"
            gap={4}
            alignItems="flex-start"
            height="min-content"
          >
            <ProfileSetupFormTitle title={`${displayName} Info`} />
            <Grid container rowSpacing="12px">
              {gridItemsRenderer(nodeList, {
                show: !isEditAllowed,
                message: EDIT_DENY_MESSAGE,
              })}
            </Grid>
          </Stack>
          <ProfileSetupFormActions
            secondaryAction={{
              onClick: handleBack,
            }}
            primaryAction={{
              disabled: statusBar < 100 || isSubmitting,
              children: "Done",
              onClick: () => onSubmit(values),
            }}
          />
        </ProfileSetupFormContainer>
      </Box>
    </FormProvider>
  );
}

export default EnterpriseInfo;

const CustomPhoneSchema = Yup.string()
  .required("A phone is required")
  .when({
    is: (exists: string) => Boolean(exists) && exists !== "+1",
    then: (schema) =>
      schema.test(
        "is-valid-number",
        "Please enter a valid phone number",
        function (value) {
          const phoneNumber = value as string;
          const isPhoneValid = matchIsValidTel(phoneNumber);

          if (isPhoneValid) {
            this.createError({
              message: "Plese enter a valid phone Number",
              path: "servicePhoneNumber",
            });
          }
          return matchIsValidTel(phoneNumber);
        },
      ),
  })
  .when({
    is: (exists: string) => {
      return Boolean(exists) && exists === "+1";
    },
    then: (schema) =>
      schema.test(
        "is-empty-number",
        "Please enter a phone number",
        function (value, ctx) {
          const phoneNumber = value as string;

          return phoneNumber.length > 2 && matchIsValidTel(phoneNumber);
        },
      ),
  });

const enterpriseInfoSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  permalink: Yup.string().required(),
  purpose: Yup.string(),
  servicePhoneNumber: CustomPhoneSchema,
  websiteURL: urlSchema({ isRequired: true }),
  serviceCountriesOutUSCanada: Yup.boolean(),
  countriesServicedOutside: Yup.string(),
});
