import React, { useEffect, useState } from "react";
import { useGetMerchants } from "@services/api/merchants";
import { useAppSelector } from "@redux/hooks";
import { QKEY_LIST_ACQUIRER_MERCHANTS } from "@constants/queryKeys";
import { selectQueryString } from "@redux/slices/search";

export const useInfiniteMerchants = ({disableStats = false}: {disableStats?: boolean}) => {
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_ACQUIRER_MERCHANTS),
  );

  const { data: pageData, isLoading } = useGetMerchants(
    {
      queryString: "",
      page: data.length > 20 && searchQuery ? 1 : page,
      searchQuery,
    },
    {
      refetchOnWindowFocus: false,
    },
    "",
    disableStats,
  );

  const BOTTOM_OFFSET_PX = 7;

  useEffect(() => {
    if ((data.length === 0 && pageData?.data) || searchQuery || (searchQuery && !pageData?.data)) {
      setPage(1);
      setData(pageData?.data || []);
    } else if (data.length <= 20 && !searchQuery && page === 1) {
      setData(pageData?.data || []);
    } else if (data.length > 0) {
      setData((prev) => [...prev, ...(pageData?.data || [])]);
    }
  }, [pageData, searchQuery]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (isLoading) return;
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const isNearBottom =
      scrollHeight - (scrollTop + clientHeight) <= BOTTOM_OFFSET_PX;
    if (isNearBottom && pageData?.total > data.length) {
      setPage((prev) => prev + 1);
    }
  };
  return { handleScroll, data, isLoading };
};
