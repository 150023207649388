import { showMessage } from "@common/Toast";
import { useEffect, useState } from "react";
import {
  TChangeFileDirection,
  TDocument,
  TFileNavigation,
} from "@common/FilePreview/types";
import {
  isValidExtension,
  sanitizeList,
} from "@common/FilePreview/utils/fileList.utils";

const useDocumentListV2 = (
  list: TDocument[],
  file?: TDocument,
  handleClose?: () => void,
) => {
  const [documentList, setDocumentList] = useState<TDocument[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentFile, setCurrentFile] = useState<TDocument | null>(
    file || null,
  );

  useEffect(() => {
    const filteredList = sanitizeList(list);

    if (filteredList.length === 0) {
      handleClose?.();
      showMessage(
        "Error",
        "The provided list does not contain any supported file",
      );
    }

    if (file?.type && !isValidExtension(file.type)) {
      handleClose?.();
      showMessage(
        "Error",
        "The preview for the provided file is not supported",
      );
    }

    setDocumentList(filteredList);
    if (file !== undefined) {
      const index = getFileIndex(file, filteredList);
      const fileIndex = index !== -1 ? index : 0;

      setCurrentIndex(fileIndex);
      setCurrentFile(filteredList[fileIndex]);
    }
  }, [list]);

  const handleFileChange: TChangeFileDirection = (direction, deleteFile) => {
    if (!currentFile) return;

    const index = getFileIndex(currentFile, documentList);
    if (
      (index === 0 && direction === "prev") ||
      (index === documentList.length - 1 && direction === "next")
    )
      return;

    const newIndex = direction === "prev" ? index - 1 : index + 1;
    setCurrentFile(documentList[newIndex]);
    setCurrentIndex(newIndex);

    if (deleteFile) {
      updatePreviewList(deleteFile, documentList[newIndex]);
    }
  };

  const updatePreviewList = (
    file: TDocument | null,
    nextFile: TDocument | null,
  ) => {
    if (!file?.id) return documentList;
    const newList = documentList.filter((item) => item.id !== file?.id);
    setDocumentList(newList);
    nextFile && setCurrentIndex(getFileIndex(nextFile, newList));
    return newList;
  };

  const displayNavigation =
    !currentFile || (!!currentFile && currentIndex !== -1);
  const hasPrevElements = currentIndex > 0;
  const hasNextElements = currentIndex < documentList.length - 1;

  const navigation: TFileNavigation = {
    showPrev: displayNavigation && hasPrevElements,
    showNext: displayNavigation && hasNextElements,
    changeFile: handleFileChange,
    handleClose: handleClose,
  };

  return {
    currentFile,
    currentIndex,
    navigation,
  };
};

export default useDocumentListV2;

const getFileIndex = (file?: TDocument, list?: TDocument[]) =>
  list?.findIndex(
    (f) =>
      f.name === file?.name && f.type === file?.type && f.URL === file?.URL,
  ) ?? -1;
