import { Grid } from "@mui/material";
import { Item_Layout } from "@sections/PayBuilder/provider/provider.type";
import { ProductItemType } from "@sections/PayBuilder/types";
import { useMemo } from "react";
import ProductCard from "./ProductCard";
import { TFormStatus } from "./types";

interface ProductsProps {
  items: ProductItemType[];
  displayStatus: TFormStatus;
  itemLayout: Item_Layout;
  isMobileView?: boolean;
  accentColor?: string;
  isFromADB?: boolean;
}

const ProductList = ({
  displayStatus,
  items: Items,
  itemLayout,
  isMobileView,
  accentColor,
  isFromADB,
}: ProductsProps) => {
  const cardWidth = isMobileView
    ? "repeat(auto-fill, minmax(171px, 1fr))"
    : "repeat(auto-fill, minmax(260px, 1fr))";
  const listWidth = isMobileView ? "repeat(1, 1fr)" : "repeat(2, 1fr)";

  const sortedProducts = useMemo(() => {
    return [...Items]
      ?.filter((x) => x.display)
      ?.sort((a, b) => {
        const aInStock = a.in_stock ?? 1;
        const bInStock = b.in_stock ?? 1;

        if (aInStock === 0 && bInStock !== 0) return -1;
        if (aInStock !== 0 && bInStock === 0) return 1;
        return 0;
      });
  }, [Items]);

  return (
    <Grid
      container
      sx={{
        display: "grid",
        gap: "16px",
        gridTemplateColumns: itemLayout === "list" ? listWidth : cardWidth,
        paddingBottom: "16px",
      }}
    >
      {sortedProducts?.map((product) => (
        <ProductCard
          key={product.id}
          isMobileView={isMobileView}
          product={product}
          paymentType={product.paymentType}
          displayStatus={displayStatus}
          itemLayout={itemLayout}
          accentColor={accentColor || ""}
          isFromADB={isFromADB}
        />
      ))}
    </Grid>
  );
};

export default ProductList;
