import { PortalRootContainer } from "@components/Layout/Layout";
import { ProductScreenNotAuthorizedWrapper } from "@components/Product/commons/ProductScreenNotAuthorizedWrapper";
import NiceModal from "@ebay/nice-modal-react";
import { CREATE_PRE_BUILD_FORM_MODAL } from "modals/modal_names";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BackgroundAnimation from "@shared/Banner/BackgroundAnimation";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import WithEmptyStatesCampaigns from "@containers/WithEmptyStates";
import ProductListTable from "./table/ProductListTable";
import { useAppTheme } from "@theme/v2/Provider";
import { TCampaignType } from "./types";
import GiveMotionWrapper from "@shared/Motion/GiveMotionWrapper";

const ProductList = ({ type }: { type: TCampaignType }) => {
  const { isRebrandingTableEnabled } = useGetFeatureFlagValues();
  const { palette } = useAppTheme();

  const { state } = useLocation();
  const isNewProduct = state?.newProduct || false;

  useEffect(() => {
    if (isNewProduct && type === "payment-form")
      NiceModal.show(CREATE_PRE_BUILD_FORM_MODAL);
  }, [isNewProduct]);

  if (!isRebrandingTableEnabled)
    return <WithEmptyStatesCampaigns type={type} />;

  return (
    <ProductScreenNotAuthorizedWrapper type={type}>
      <PortalRootContainer
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            gap: "0px !important",
          },
          backgroundColor: palette.surface?.primary,
        })}
      >
        <BackgroundAnimation page="merchantPaymentForms" />
        <GiveMotionWrapper
          delay={30}
          containerProps={{ sx: { height: "100%", zIndex: 2 } }}
          customStyle={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ProductListTable type={type} />
        </GiveMotionWrapper>
      </PortalRootContainer>
    </ProductScreenNotAuthorizedWrapper>
  );
};

export default ProductList;
