import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { customInstance } from "@services/api";
import { AxiosError } from "axios";
import React, { lazy, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

const useProduct = (id?: string) => {
  if (!id) return { data: undefined, isLoading: false, error: null };

  return useQuery<any, AxiosError>(
    "find-product-by-id",
    async () => {
      const product = await customInstance({
        url: `products/${id}`,
        method: "GET",
      });
      return product;
    },
    { cacheTime: 0, refetchOnWindowFocus: false, enabled: !isNaN(Number(id)) },
  );
};

const componentsMap = {
  event: lazy(
    () => import("@components/Events/PublicPaymentForm/EventsPublicForm"),
  ),
  fundraiser: lazy(
    () =>
      import("@components/Fundraisers/PublicPaymentForm/FundraisersPublicForm"),
  ),
  invoice: lazy(
    () => import("@components/Invoices/PublicPaymentForm/InvoicesPublicForm"),
  ),
  membership: lazy(
    () =>
      import("@components/Memberships/PublicPaymentForm/MembershipsPublicForm"),
  ),
  sweepstake: lazy(
    () =>
      import("@components/Sweepstakes/PublicPaymentForm/SweepstakesPublicForm"),
  ),
  standard: lazy(
    () =>
      import(
        "@components/PaymentForms/PublicPaymentForm/PaymentFormsPublicForm"
      ),
  ),
} as const;

type productTypes =
  | "event"
  | "fundraiser"
  | "invoice"
  | "membership"
  | "sweepstake";

const ProductPublicForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, error } = useProduct(id);

  useEffect(() => {
    if (isNaN(Number(id))) {
      navigate("/");
      return;
    }
    if (error?.response?.status === 404) {
      location.replace("https://www.givepayments.com");
    }
  }, [error]);

  const PublicFormComponent = useMemo(() => {
    const element = componentsMap[data?.typeName as productTypes];
    if (element) {
      return element;
    }
    return null;
  }, [data?.typeName]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return PublicFormComponent ? (
    <React.Suspense fallback={<LoadingSpinner />}>
      <PublicFormComponent title={data?.typeName} />
    </React.Suspense>
  ) : null;
};

export default ProductPublicForm;
