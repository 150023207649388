import { Button } from "@common/Button";
import { Image } from "@common/StyledImage/Image";
import { Text } from "@common/Text";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { Stack } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import ChatEditor from "./ChatEditor";
import { FormProvider } from "react-hook-form";
import { useCreateMessage } from "../hooks/useCreateMessage";
import { TMentionedUser } from "../utils/functions";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";

type Props = {
  mentionedUser?: TMentionedUser;
  merchantId: number;
  defaultMessage?: string;
  shouldHideMentionIcon?: boolean;
  onMessageSubmit?: (...props: any[]) => void;
  handleOpenConversationsModal: (open?: boolean) => void;
};

const ChatAction = ({
  mentionedUser,
  merchantId,
  defaultMessage,
  shouldHideMentionIcon = true,
  onMessageSubmit,
  handleOpenConversationsModal,
}: Props) => {
  const { img } = useAppSelector(selectUser);

  const image = img
    ? addSizeToImage(img.replace("/thumb", ""), "small")
    : AvatarPlaceholder;
  const { onSubmit, methods, watch } = useCreateMessage({
    merchantId,
    onMessageSubmit,
    handleOpenConversationsModal,
  });

  const values = watch();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, console.log)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            borderTop: "1px solid #FFF",
            boxShadow: "0px -2px 10px 0px rgba(76, 76, 76, 0.10)",
            padding: `8px 0 8px 16px`,
            borderRadius: "unset",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            sx={{ width: "100%" }}
            gap={2}
          >
            <Image
              src={image}
              sx={{
                borderRadius: "80px",
                height: 32,
                width: 32,
              }}
            />
            <ChatEditor
              name="conversationInput"
              mentionedUser={mentionedUser}
              onSubmit={onSubmit}
              defaultMessage={defaultMessage}
              shouldHideMentionIcon={shouldHideMentionIcon}
            />
          </Stack>

          {values.conversationInput && (
            <Button
              background="tertiary"
              sx={{
                cursor: "pointer",
                borderRadius: "unset",
                minWidth: 0,
                minHeight: 0,
                height: "auto",
                boxSizing: "border-box",
                padding: "0 16px 0 0",
              }}
              tabIndex={0}
              type="submit"
            >
              <Text color="#326EC5" fontSize={14}>
                Post
              </Text>
            </Button>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ChatAction;
