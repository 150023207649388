import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveAssignButton from "@shared/AssignButton/GiveAssignButton";
import SectionCardBase from "../../../../../shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveDivider from "@shared/GiveDivider/GiveDivider";

import { styled } from "@theme/v2/Provider";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import MCCInput from "./MCCInput";
import MerchantRiskCategoryInput from "./MerchantRiskCategoryInput";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import GiveLink from "@shared/Link/GiveLink";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useState } from "react";

interface Props {
  type?: "Underwriter" | "Risk Analyst" | "Assignee";
}

function UnderwriterRiskAnalystAssignCard({ type = "Underwriter" }: Props) {
  const { data, id, isOnboardingView } = useMerchantSidePanelContext();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isEnterprisePortal, isAcquirerPortal } = checkPortals();
  const { openConversationHandler } = useConversationsModal();
  const { isMobileView } = useCustomThemeV2();
  const [localRiskValue, setLocalRiskValue] = useState(
    data?.merchantInfo?.merchantRiskStatus,
  );

  // We are hiding the top section if merchant_underwriting is false and we are in enterprise portal
  const isHide = !merchant_underwriting && isEnterprisePortal;

  const {
    underwriterName,
    underwriterImageURL,
    underwriterEmail,
    assignee,
    underwriterSetByAcquirer,
  } = data?.status || {};

  const tag = underwriterName || underwriterEmail;

  const handleMessage = () => {
    openConversationHandler({
      id,
      name: `General`,
      paths: [],
      defaultMessage: `@[__${tag}__](user:__${tag}__) `,
    });
  };

  const hideAssignButton = underwriterSetByAcquirer && !isAcquirerPortal;

  const AssignButton = () => (
    <GiveAssignButton
      title="Assign"
      type={isOnboardingView ? "sales" : undefined}
      isOnlyName
      data={{
        accID: id,
        underwriterName,
        underwriterImageURL,
        underwriterEmail,
        underwriterID: assignee || undefined,
        underwriterSetByAcquirer,
      }}
      customContextualMenuPaperStyle={{
        mt: "-48px",
      }}
    />
  );

  const EmailAndMessageSection = () => {
    return (
      <Box display="flex">
        {!isMobileView && !hideAssignButton && <Box width="50%" />}
        <Stack
          width={isMobileView || hideAssignButton ? "auto" : "50%"}
          spacing={1}
          mt={hideAssignButton && !isMobileView ? 0 : 1}
        >
          {underwriterName && isMobileView && !hideAssignButton && (
            <AssignButtonTextContainer>
              <AssignButton />
            </AssignButtonTextContainer>
          )}
          <CustomText variant="bodyS" color="secondary">
            {underwriterEmail}
          </CustomText>
          {assignee && (
            <GiveLink
              component="button"
              color="secondary"
              onClick={handleMessage}
              sx={{ fontSize: 16 }}
            >
              Message
            </GiveLink>
          )}
        </Stack>
      </Box>
    );
  };

  return (
    <SectionCardBase>
      {!isHide && (
        <>
          <Box
            display="flex"
            flexDirection={isMobileView && hideAssignButton ? "column" : "row"}
          >
            <GiveText
              color="secondary"
              variant="bodyS"
              flexGrow={1}
              width={isMobileView ? "auto" : "50%"}
            >
              {type}
            </GiveText>
            {hideAssignButton ? (
              <EmailAndMessageSection />
            ) : (
              <AssignButtonAvatarContainer>
                <AssignButton />
              </AssignButtonAvatarContainer>
            )}
          </Box>
          {!hideAssignButton && <EmailAndMessageSection />}
          <GiveDivider />
        </>
      )}
      <Box
        mt={isHide ? "0" : "16px"}
        display="flex"
        flexDirection="column"
        gap="16px"
      >
        <MCCInput setLocalRiskValue={setLocalRiskValue} />
        <MerchantRiskCategoryInput
          localRiskValue={localRiskValue}
          setLocalRiskValue={setLocalRiskValue}
        />
      </Box>
    </SectionCardBase>
  );
}

export default UnderwriterRiskAnalystAssignCard;

const AssignButtonAvatarContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("v2_sm")]: {
    width: "auto",
    "& p, & .caret-down-icon": {
      display: "none",
    },
  },
}));

const AssignButtonTextContainer = styled(Box)(() => ({
  "& .MuiAvatar-root": {
    display: "none",
  },
  "& p": {
    marginLeft: 0,
  },
  "& .caret-down-icon": {
    visibility: "visible",
  },
}));

const CustomText = styled(GiveText)(() => {
  return {
    wordBreak: "break-word",
    whiteSpace: "normal",
    overflowWrap: "break-word",
  };
});
