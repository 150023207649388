import { deleteDocumentAPI } from "@components/Settings/Business/Documents/utils";
import NiceModal from "@ebay/nice-modal-react";
import { GIVE_CONFIRMATION_POP_UP } from "modals/modal_names";

const useDeleteDocument = () => {
  const deleteDocument = (
    merchantID: number,
    document: { fileName: string; id: number },
    onEnd?: () => void,
    hideModal?: boolean,
  ) => {
    const { fileName, id } = document;
    const deleteHandler = () => {
      deleteDocumentAPI(merchantID, id, onEnd, true);
    };
    if (hideModal) {
      deleteHandler();
    } else {
      NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
        modalType: "delete",
        title: "Delete Document Confirmation",
        description: (
          <>
            Are you sure you want to delete <strong>{fileName}</strong>? This
            document will be permanently deleted.
          </>
        ),
        actions: {
          handleSuccess: { onClick: deleteHandler },
        },
      });
    }
  };
  return { deleteDocument };
};
export default useDeleteDocument;
