import { MERCHANT_PATHS } from "@routes/paths";
import { checkPortals } from "@utils/routing";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";

export const useHandleMerchantDefaultRoute = () => {
  const { isMerchantPortal } = checkPortals();
  const { data } = useGetMerchantById({ enabled: isMerchantPortal });
  return {
    merchantDefaultRoute:
      data?.sponsorStatusName === "approved"
        ? MERCHANT_PATHS.DASHBOARD
        : MERCHANT_PATHS.WELCOME,
  };
};
