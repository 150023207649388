import { Box, CircularProgress } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useCart } from "../provider/CartContext";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { SaveAndCloseBtn } from "./SaveAndCloseBtn";
import { useEffect } from "react";

export const LeftPanelActions = () => {
  const { isMobileView, isTabletView } = useCustomThemeV2();
  const {
    activeStepIndex,
    isLastStep,
    goToStep,
    isNextDisabled,
    setLastStepCompleted,
  } = usePayBuilderContext();
  const isMobileOrTablet = isTabletView || isMobileView;
  const { clearCart } = useCart();
  const { mutate, isLoading, methods } = usePayBuilderForm();

  const {
    formState: { errors, isValid },
    watch,
  } = methods;
  const values = watch();

  const isPublished =
    methods.watch("publishedStatus") === "public" && isLastStep;

  const isDisabled = isNextDisabled({ values, errors, isLoading, isValid });

  useEffect(() => {
    if (isDisabled) {
      setLastStepCompleted(activeStepIndex);
    }
  }, [isDisabled]);

  return (
    <>
      {!isMobileOrTablet && <SaveAndCloseBtn />}
      <GiveButton
        size="large"
        sx={{
          marginLeft: isMobileOrTablet ? 0 : "auto",
          visibility: activeStepIndex !== 0 ? "visible" : "hidden",
        }}
        variant="ghost"
        label="Back"
        onClick={() => goToStep({ offSet: -1 })}
      />

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GiveButton
          disabled={isDisabled || isPublished}
          size="large"
          label={isLastStep ? "Publish" : "Next"}
          onClick={() => {
            if (isLastStep) clearCart();
            mutate({
              handleSuccessCB: () => !isLastStep && goToStep({ offSet: 1 }),
              publish: true,
            });
          }}
          sx={{
            marginLeft: isMobileOrTablet ? "auto" : 0,
          }}
        />
        {isLoading && (
          <CircularProgress
            style={{
              position: "absolute",
            }}
          />
        )}
      </Box>
    </>
  );
};
