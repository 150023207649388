import { Box } from "@mui/material";
import { CaretDown, X } from "@phosphor-icons/react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import GiveMerchantTableActions from "@shared/MerchantsProviders/GiveMerchantTableActions";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { getDaysInMonth, isBefore, isSameDay, subDays } from "date-fns";
import HFGiveSelect from "@shared/HFInputs/HFGiveSelect/HFGiveSelect";
import GiveText from "@shared/Text/GiveText";
import GiveButton from "@shared/Button/GiveButton";
import FilterDateRenderer from "./FilterDateRenderer";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import {
  addDateFilter,
  disableDateFilter,
  selectFilters,
} from "@redux/slices/fundraisers";
import { Stack } from "@mui/material";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { capitalize } from "lodash";
import { selectQueryString, setSearchQueryByKey } from "@redux/slices/search";
import { mapTypeToQueryKey } from "@services/api/products/queryFactory";
import { TCampaignType } from "./types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type IFormValues = {
  modifier: string;
  count: number;
  dayMonthYear: string;
  startDate: number | Date;
  endDate: number | Date;
  date: number | Date;
};

type Props = {
  type: TCampaignType;
  handleSearch: (value: string) => void;
  handleResetSelectedRow: () => void;
};

const ProductActions = ({
  type,
  handleSearch,
  handleResetSelectedRow,
}: Props) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const queryKey = mapTypeToQueryKey[type];
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, queryKey),
  );

  const schema = Yup.object({
    count: Yup.number()
      .max(3000, "")
      .transform((value) => (isNaN(value) || !value ? null : value))
      .required("Count is required")
      .nullable(),
    date: Yup.date()
      .required("Date is required")
      .nullable()
      .typeError("Insert a valid ${type}"),
    startDate: Yup.date()
      .required("Start date is required")
      .typeError("Insert a valid ${type}")
      .nullable()
      .test("isBefore-or-same-as-end-date", "", function (value: any) {
        const { endDate }: any = this.parent;
        return isSameDay(value, endDate) || isBefore(value, endDate);
      }),
    endDate: Yup.date()
      .required("End date is required")
      .typeError("Insert a valid ${type}")
      .nullable()
      .test("is-greater-or-equal-than-start-date", "", function (value: any) {
        const { startDate } = this.parent;
        return isSameDay(startDate, value) || isBefore(startDate, value);
      }),
  });

  const NOW = useRef(new Date());
  const oneMonthAgo = useRef(subDays(new Date(), getDaysInMonth(NOW.current)));

  const defaultValues = {
    modifier: listCreatedFilter[0].value,
    dayMonthYear: "days",
    count: 7,
    startDate: oneMonthAgo.current,
    endDate: NOW.current,
    date: NOW.current,
  };

  const methods = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const { watch, reset } = methods;

  const isDateFilterApplied = filters?.created?.length > 0;
  const filterByText = isDateFilterApplied
    ? filters?.created?.map((text) => capitalize(text?.toString()))?.join(" ")
    : "";

  const values = watch();

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisableFilter = (
    e?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();

    setAnchorEl(null);
    handleResetSelectedRow();
    dispatch(disableDateFilter());
  };

  const onSubmit: SubmitHandler<IFormValues> = (formValues) => {
    setAnchorEl(null);
    dispatch(addDateFilter(formValues as any));
    handleResetSelectedRow();
  };

  const actions = [
    {
      label: (
        <>
          Filter by Date{isDateFilterApplied ? " : " : ""}
          {filterByText && (
            <span
              style={{ color: palette?.text?.secondary, marginLeft: "4px" }}
            >
              {filterByText}
            </span>
          )}
        </>
      ),
      handleAction: handleOpenMenu,
      endIcon: isDateFilterApplied ? (
        <GiveIconButton
          variant="ghost"
          Icon={X}
          onClick={handleDisableFilter}
          sx={{ padding: "2px", borderRadius: "50%" }}
        />
      ) : (
        <CaretDown size={18} />
      ),
      sx: {
        height: "42px",
      },
    },
  ];

  useEffect(() => {
    reset({
      ...defaultValues,
      modifier: values.modifier,
    });
  }, [values.modifier]);

  useEffect(() => {
    return () => {
      dispatch(disableDateFilter());
    };
  }, []);

  return (
    <Container>
      <GiveMerchantTableActions
        actions={actions}
        search={searchQuery}
        onSearchChange={handleSearch}
        searchOnEnter
      />
      <ContextualMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        options={[]}
        color={isMobileView ? "primary" : "transparent"}
        texture={isMobileView ? "solid" : "blurred"}
        horizontalOrigin="left"
        customContent={
          <Stack
            spacing={1.5}
            padding="20px 16px"
            width={isMobileView ? "100%" : "314px"}
          >
            <GiveText variant="bodyS" color="secondary">
              Filter by created
            </GiveText>
            <FormProvider {...methods}>
              <Box
                component="form"
                id="date-filter"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <HFGiveSelect
                  name="modifier"
                  options={listCreatedFilter}
                  fullWidth
                />
                <FilterDateRenderer value={values.modifier} />
              </Box>
            </FormProvider>
            <GiveButton
              type="submit"
              form="date-filter"
              variant="filled"
              size="large"
              fullWidth
              label="Apply"
            />
          </Stack>
        }
      />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  margin: "50px 0 12px",
  "& div": {
    justifyContent: "flex-start",
  },
  "& .MuiFormControl-root": {
    width: "320px",
  },
  [theme.breakpoints.down("v2_sm")]: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    margin: "16px 0 16px",
  },
}));

const listCreatedFilter = [
  { value: "in the last", label: "In the Last" },
  { value: "is between", label: "Between" },
];

export default ProductActions;
