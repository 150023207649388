import { Stack, SxProps } from "@mui/material";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { CalendarBlank, GlobeSimple } from "@phosphor-icons/react";
import { MapPin } from "@phosphor-icons/react";
import {
  formatMapAddress,
  formatPaymentFormDate,
} from "@sections/PayBuilder/utils";
import { convertHexToRGB } from "@utils/theme";

interface Props {
  startDate: string | null;
  endDate: string | null;
  includeTime: boolean;
  locationURL: string | null;
  locations: any;
  tabsDetails: any;
  accentColor?: string;
  timers: any;
  sx?: SxProps;
}

const EventDateLocation = ({
  startDate,
  endDate,
  includeTime,
  locationURL,
  locations,
  tabsDetails,
  accentColor = "inherit",
  timers,
  sx,
}: Props) => {
  const { isManualAddress, locationPosition } = tabsDetails;
  const address = isManualAddress
    ? formatMapAddress(locations.locationAddress)
    : locations.locationShortAddress;
  return (
    <DateContainer {...(sx ? { sx } : {})}>
      {(startDate || endDate) && (
        <Stack direction="row" gap="11px" alignItems="center">
          <IconBox
            Icon={CalendarBlank}
            size="extraSmall"
            accentColor={accentColor}
          />
          <GiveText variant="bodyS" color="secondary">
            {formatPaymentFormDate(startDate, includeTime, timers.startsAtTime)}{" "}
            {endDate &&
              `to ${formatPaymentFormDate(
                endDate,
                includeTime,
                timers.endsAtTime,
              )}`}
          </GiveText>
        </Stack>
      )}
      <Stack direction="row" gap="11px" alignItems="center">
        {locationURL && locationPosition === "online" && (
          <>
            <IconBox
              Icon={GlobeSimple}
              size="extraSmall"
              accentColor={accentColor}
            />
            <GiveText variant="bodyS" color="secondary">
              {locationURL}
            </GiveText>
          </>
        )}
        {address && locationPosition === "onsite" && (
          <>
            <IconBox
              Icon={MapPin}
              size="extraSmall"
              accentColor={accentColor}
            />
            <GiveText variant="bodyS" color="secondary">
              {address}
            </GiveText>
          </>
        )}
      </Stack>
    </DateContainer>
  );
};

export default EventDateLocation;

const DateContainer = styled(Stack)({
  paddingBottom: "24px",
  gap: "8px",
});

const IconBox = styled(GiveIconButton, {
  shouldForwardProp: (prop) => prop !== "accentColor",
})<{ accentColor: string }>(({ accentColor }) => ({
  width: "32px",
  height: "32px",
  background: convertHexToRGB(accentColor, 0.1),
  color: accentColor,
}));
